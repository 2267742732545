import { handleActions } from 'redux-actions';

import { createCapitalValueReducer } from './create';
import { listCapitalValueReducer } from './list';
import { removeCapitalValueReducer } from './remove';

export { createCapitalValueAction } from './create';
export { listCapitalValueAction } from './list';
export { removeCapitalValueAction } from './remove';

const initialState = {
  isFetching: false,
  statusCode: null,
  list: [],
};

const combinedReducers = {
  ...createCapitalValueReducer,
  ...listCapitalValueReducer,
  ...removeCapitalValueReducer,
};

export const capitalValues = handleActions(combinedReducers, initialState);
