import addTrade from './add-trade';
import getTrade from './get-trade';
import sellTrade from './sell-trade';

export const addCash = (trade) => addTrade(trade, 'cash');
export const addEcd = (trade) => addTrade(trade, 'ecds');
export const addCp = (trade) => addTrade(trade, 'cps');
export const addTermDeposit = (trade) => addTrade(trade, 'term-deposits');
export const addTermAnnuity = (trade) => addTrade(trade, 'term-annuities');
export const buyBonds = (trade) => addTrade(trade, 'bonds');
export const buyFrn = (trade) => addTrade(trade, 'frns');
export const buyFrtd = (trade) => addTrade(trade, 'frtds');
export const count = (trade) => getTrade(trade, 'count');
export const details = (id, trade) => getTrade(trade, `${id}`);
export const maturing = (trade) => getTrade(trade, 'maturing');
export const sellBonds = (trade) => sellTrade(trade, 'bonds');
export const sellEcd = (trade) => sellTrade(trade, 'ecds', 'ecd');
export const sellCp = (trade) => sellTrade(trade, 'cps', 'cp');
export const sellFrn = (trade) => sellTrade(trade, 'frns');
export const summary = (trade) => getTrade(trade, 'summary');

export { default as codes } from './codes';
export { default as csv } from './csv';
export { default as list } from './list';
export { default as redeem } from './redeem';
export { default as confirmRedeem } from './confirm-redeem';
export { default as reinvest } from './reinvest';
export { default as trades } from './trades';
export { default as updateCash } from './update-cash';
