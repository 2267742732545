import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Money } from '../../../../../money';
import { DateWithTimeZone } from '../../../../../../date';
import { getInstrumentCodeDescription, getIsinYieldOrMarginDescription } from '../../../bond-frn-descriptions';
import { toNumberFormat } from '../../../../../../format-numbers';

import './trade-info.scss';

export const TradeInfo = ({ trade, totalHolding, currency }) => {
  const isFrnTrade = trade.instrumentCode === 'FRN';

  return (
    <React.Fragment>
      <dl className="highlight">
        <div>
          <FormattedMessage tagName="dt" id="holdingIssuer" />
          <dd>{trade.issuer.name}</dd>
        </div>
      </dl>
      <dl className="highlight">
        <div>
          <FormattedMessage tagName="dt" id="type" />
          <dd>{getInstrumentCodeDescription(trade.instrumentCode)}</dd>
        </div>
        <div>
          <FormattedMessage tagName="dt" id="holdingIsins" />
          <dd>{trade.instrumentDetails.isin}</dd>
        </div>
        <div>
          <FormattedMessage tagName="dt" id="holdingMaturityDate" />
          <dd>
            <DateWithTimeZone value={trade.maturityDate} />
          </dd>
        </div>
        <div>
          <FormattedMessage tagName="dt" id="faceValue" values={{ currency }} />
          <dd>
            <Money value={totalHolding} fraction={0} />
          </dd>
        </div>
        <div>
          <dt>{getIsinYieldOrMarginDescription(trade.instrumentCode)}</dt>
          <dd>{toNumberFormat({ value: isFrnTrade ? trade.instrumentDetails.frnIssueMargin : trade.coupon })}</dd>
        </div>
      </dl>
    </React.Fragment>
  );
};

TradeInfo.propTypes = {
  trade: PropTypes.shape().isRequired,
  totalHolding: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
