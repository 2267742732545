import {
  FETCH_OUTGOING_RFQS_REQUEST,
  FETCH_OUTGOING_RFQS_SUCCESS,
  FETCH_OUTGOING_RFQS_FAILURE,
  FETCH_OUTGOING_QUOTES_REQUEST,
  FETCH_OUTGOING_QUOTES_SUCCESS,
  FETCH_OUTGOING_QUOTES_FAILURE,
  FILTER_RFQS_HISTORY,
  CLEAR_RFQS_HISTORY_FILTER,
} from '../../../actions/reports/outgoing-rfqs/rfqs-history';

const INITIAL_STATE = {
  initialized: false,
  didInvalidate: false,
  isFetching: false,
  isFetchingQuote: false,
  outgoingRfqs: [],
  error: null,
  filter: {},
  quotes: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_OUTGOING_RFQS_REQUEST:
      return { ...state, didInvalidate: false, isFetching: true };
    case FETCH_OUTGOING_RFQS_SUCCESS:
      return {
        ...state,
        initialized: true,
        didInvalidate: false,
        isFetching: false,
        outgoingRfqs: action.payload,
      };
    case FETCH_OUTGOING_RFQS_FAILURE:
      return {
        ...state,
        didInvalidate: true,
        error: action.error,
        isFetching: false,
      };
    case FILTER_RFQS_HISTORY:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };
    case FETCH_OUTGOING_QUOTES_REQUEST:
      return { ...state, didInvalidate: false, isFetchingQuotes: true };
    case FETCH_OUTGOING_QUOTES_SUCCESS:
      return {
        ...state,
        didInvalidate: false,
        isFetchingQuotes: false,
        quotes: action.payload.quotes,
        uuid: action.payload.uuid,
      };
    case FETCH_OUTGOING_QUOTES_FAILURE:
      return {
        ...state,
        didInvalidate: true,
        error: action.error,
        isFetchingQuotes: false,
        quotes: {},
        uuid: undefined,
      };
    case CLEAR_RFQS_HISTORY_FILTER:
      return {
        ...state,
        filter: {},
      };
    default:
      return state;
  }
};
