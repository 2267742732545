import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  getDecimalSeparatorForLocale,
  getThousandSeparatorForLocale,
} from '../../format-numbers';

class PercentageInputComponent extends Component {
  constructor(props) {
    super(props);
    this.decimalSeparator = getDecimalSeparatorForLocale(props.intl.locale);
    this.thousandSeparator = getThousandSeparatorForLocale(props.intl.locale);
    this.thousandRegexp = new RegExp(`\\${this.thousandSeparator}`, 'g');

    this.state = {
      value: props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateValue(this.props.value);
    }
  }

  onBlur(inputValue) {
    const { onBlur } = this.props;
    const value = this.limitToPrecision(inputValue);
    onBlur && onBlur(value);
  }

  getLocaleOptions(input) {
    const { decimalPrecision } = this.props;
    const [, fraction = ''] = input.split(this.decimalSeparator);

    return {
      minimumFractionDigits: Math.min(decimalPrecision, fraction.length),
      maximumFractionDigits: decimalPrecision,
    };
  }

  isNotValidNumber(input) {
    return input.endsWith(this.decimalSeparator) || input === '-' || input === this.props.prefix;
  }

  prefixString(value) {
    return this.props.prefix ? `${this.props.prefix}${value}` : value;
  }

  trimNumberToPrecision(input) {
    const [integer, fraction = ''] = input.split(this.decimalSeparator);
    if (fraction.length === 0) {
      return input;
    }
    const { decimalPrecision } = this.props;
    const fractionSlice = fraction.slice(0, Math.min(fraction.length, decimalPrecision));

    return `${integer}${this.decimalSeparator}${fractionSlice}`;
  }

  limitToRange(value) {
    const { min, max, prefix } = this.props;
    const unformatted = value.replace(prefix, '').replace(this.thousandRegexp, '').replace(this.decimalSeparator, '.');
    const parsedValue = parseFloat(unformatted);
    if (min !== undefined && parsedValue < min) {
      return min;
    }

    if (max !== undefined && parsedValue > max) {
      return max;
    }

    return Number.isNaN(parsedValue) ? '' : parsedValue;
  }

  limitToPrecision(input) {
    if (this.isNotValidNumber(input)) {
      return input;
    }
    const {
      intl: { locale },
    } = this.props;
    const output = this.limitToRange(this.trimNumberToPrecision(input));
    const limitedValue = output.toLocaleString(locale, this.getLocaleOptions(input));

    return this.prefixString(limitedValue);
  }

  updateValue(e, inputValue) {
    let receivedValue;
    if (typeof e === 'string') {
      receivedValue = e;
    } else {
      receivedValue = inputValue || (e && e.target && e.target.value);
    }

    if (receivedValue === undefined) {
      return;
    }

    const value = this.limitToPrecision(receivedValue);

    this.setState({ value });

    if (e.target) {
      e.target.value = value; // eslint-disable-line no-param-reassign
    }

    const { onChange } = this.props;
    onChange && onChange(e);
  }

  render() {
    const { inputRef, ...inputProps } = this.props;
    delete inputProps.decimalPrecision;

    return (
      <input
        className="form-control"
        {...inputProps}
        ref={inputRef}
        onChange={(e) => this.updateValue(e, e.target.value)}
        value={this.state.value}
      />
    );
  }
}

PercentageInputComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func,
  inputRef: PropTypes.shape(),
  onBlur: PropTypes.func,
  decimalPrecision: PropTypes.number,
  prefix: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

export const PercentageInput = injectIntl(PercentageInputComponent);

export default PercentageInput;
