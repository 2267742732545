import { createActionThunk } from 'redux-thunk-actions';

import confirmTwoFactor from '../../api/login/confirm-two-factor';
import { actions } from './actions';

export const confirmTwoFactorAction = createActionThunk(actions.confirmTwoFactor, (params) => confirmTwoFactor(params));
export const cancelTwoFactorCodeAction = createActionThunk(actions.cancelTwoFactorCode, () => null);

export const confirmTwoFactorReducer = {
  [confirmTwoFactorAction.STARTED]: (state) => ({
    ...state,
    authenticating: true,
  }),
  [confirmTwoFactorAction.SUCCEEDED]: (state) => ({
    ...state,
    pending: false,
  }),
  [confirmTwoFactorAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
    statusCode: payload.response.status,
  }),
  [confirmTwoFactorAction.ENDED]: (state) => ({
    ...state,
    authenticating: false,
    error: null,
    statusCode: null,
  }),
  [cancelTwoFactorCodeAction.SUCCEEDED]: (state) => ({
    ...state,
    authenticating: false,
    token: null,
    pending: false,
    twoFactorAuthEnabled: false,
  }),
};
