import '../default-row.scss';
import './row.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getFormattedNumberOrEmpty, rateFormat } from '../../../format-numbers';

const Row = ({ isOwnAdi, rating, quotes }) => (
  <tr className={`incoming-quotes-row ${isOwnAdi ? 'your-adi-row' : ''}`}>
    <td>{isOwnAdi ? <FormattedMessage id="dashboard.dialog.thisAdi" /> : rating}</td>
    {getRowQuotes(quotes)}
  </tr>
);

const getRowQuotes = (quotes) =>
  quotes &&
  quotes.map((quote) => (
    <td>
      <span className={classNames({ 'accepted-quote': quote.accepted, 'best-coupon': quote.isBestCoupon })}>
        {getFormattedNumberOrEmpty({ value: quote.bankCoupon, format: rateFormat })}
      </span>
    </td>
  ));

Row.propTypes = {
  isOwnAdi: PropTypes.bool.isRequired,
  rating: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Row;
