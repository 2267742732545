import axios from 'axios';

export async function update(capitalValue) {
  const { id, ...capitalValueUpdateDate } = capitalValue;
  const { data } = await axios({
    method: 'put',
    url: `/capital-basis/${id}`,
    data: capitalValueUpdateDate,
  });

  return data;
}
