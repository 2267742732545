import axios from 'axios';

export const sendBuyBondsQuote = async ({ rfqEntityId, data }) =>
  axios({
    method: 'put',
    url: `/rfqs/buy-bonds/${rfqEntityId}/quotes`,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
