import './download-select.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Glyphicon } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';

import { Select } from '../common';
import { Loadable, types } from '../common/loadable';

class DownloadSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedDownloadOption: props.selectedValue };
  }

  onChange = (selectedDownloadOption) => {
    this.setState({ selectedDownloadOption });
  };

  onClick = () => {
    this.props.onDownloadClick(this.state.selectedDownloadOption);
  };

  render = () => {
    const { options, isDownloading } = this.props;
    const { selectedDownloadOption } = this.state;

    return (
      <Row className="download-select-container" alignItemsEnd>
        <Column className="download-select">
          <p className="grey-color text-ellipsis">
            <FormattedMessage tagName="span" id="download.label" />
          </p>
          <Select
            isLoading={isDownloading}
            isClearable={false}
            options={options}
            value={selectedDownloadOption}
            onChange={this.onChange}
          />
        </Column>
        <div className="download-button">
          <button className="btn btn-default download-csv-link" disabled={isDownloading} onClick={this.onClick}>
            <Loadable isLoading={isDownloading} type={types.ballScaleRipple} size="sm">
              <Glyphicon glyph="download-alt" />
            </Loadable>
          </button>
        </div>
      </Row>
    );
  };
}
DownloadSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.shape(),
  onDownloadClick: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool,
};

export default DownloadSelect;
