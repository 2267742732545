import './bank-dashboard.scss';

import React, { Component } from 'react';

import classNames from 'classnames';
import clone from 'lodash.clonedeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { mutate } from 'swr';

import { fetchRatesheets } from '../../../actions/ratesheets';
import { isAdmin } from '../../../actions/session-selector';
import { maturityActions } from '../../../api/holding/codes';
import {
  CP,
  ECD,
  getInstrumentCode,
  TD,
} from '../../../api/holding/instrument-codes';
import {
  actOnBehalf,
  createTradeOnBehalf,
  downloadMaturingTradesCSV,
  redeem,
} from '../../../api/issuer-trades';
import { postIssuerTrades } from '../../../api/issuer-trades/create';
import { buildMaturingTradesList } from '../../../api/issuer-trades/list-maturing-trades';
import { segmentCodes } from '../../../api/issuers/codes';
import { deleteOffer } from '../../../api/offers';
import { createOffer } from '../../../api/offers/create';
import rooms from '../../../api/socket/rooms';
import {
  Column,
  ConfirmDialog,
  DatePicker,
  InputSearch,
  renderSelect,
  ResultsPresenter,
  Row,
  Tooltip,
} from '../../../components/common';
import DownloadSelect from '../../../components/download/DownloadSelect';
import includeSocket, {
  socketEvents,
} from '../../../components/hoc/include-socket';
import { withNavigate } from '../../../components/hoc/with-router-properties';
import {
  addYears,
  formatToShortDate,
  formatToShortDateWithoutTz,
  sameDayAsToday,
  startOfDay,
  today,
} from '../../../date';
import { getDashboardSummary } from '../../../ducks/dashboard/selectors';
import {
  addOfferToIssuerTrade,
  filterMaturingTrades,
  listMaturingTrades,
  listTenantsByIssuerTradesAction as listTenantsByIssuerTrades,
  updateIssuerTrade,
} from '../../../ducks/issuer-trades';
import { getFilteredIssuers } from '../../../ducks/issuer-trades/selectors';
import * as statuses from '../../../ducks/issuer-trades/statuses';
import { getBankRatesheet } from '../../../ducks/ratesheet/selectors';
import toFloat from '../../../parse-float';
import { routes } from '../../../routes';
import { getMoneySymbol } from '../../money';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../../toast/toast';
import { AddFundsDialog, initialFormValues } from './AddFundsDialog';
import { columns, presenter } from './maturing-records-presenter';
import { MaturingTradesHeader } from './MaturingTradesHeader';
import { MaturingTradesTransactionsChart } from './MaturingTradesTransactionsChart/MaturingTradesTransactionsChart';
import { OneDayRolloverDialog } from './OneDayRolloverDialog';
import { ReinvestOnBehalfDialog } from './ReinvestOnBehalfDialog';
import { SendOfferDialog } from './SendOfferDialog';
import { UpdateRatesheetDialog } from './UpdateRatesheetDialog';
import { ViewOfferDialog } from './ViewOfferDialog';

const mapStateToProps = (state) => ({
  issuerTrades: state.issuerTrades,
  currency: state.session.user.currency,
  tenantRole: state.session.user.tenantRole,
  globalRole: state.session.user.globalRole,
  bankRatesheet: getBankRatesheet(state),
  issuerTradesList: getFilteredIssuers(state.issuerTrades),
  dashboardSummary: getDashboardSummary(state),
  userIsAdmin: isAdmin(state),
});

const mapDispatchToProps = {
  listMaturingTrades,
  filterMaturingTrades,
  addOfferToIssuerTrade,
  updateIssuerTrade,
  listTenantsByIssuerTrades,
  fetchRatesheets,
};

const datePickerOptions = {
  startDate: today().toDate(),
};

const buildOfferDetails = ({ tradeSelected }) => ({
  accruedInterest: tradeSelected.interests.current.expected,
  interestPaid: tradeSelected.interestPaid,
  maturityDate: tradeSelected.maturityDate,
  principal: tradeSelected.principal,
  status: tradeSelected.status,
  instrumentCode: tradeSelected.instrumentCode,
});

export class MaturingTradesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOfferDialog: false,
      showAddFundsModal: false,
      showReinvestModal: false,
      isSavingOneDayRollover: false,
      isViewingOffer: false,
      isDownloadingCsv: false,
      isConfirmingRedeem: false,
      showRedeemConfirmation: false,
      showUpdateRatesheetDialog: false,
      tradeSelected: undefined,
      isCreatingOffer: false,
      isCancellingOffer: false,
      showCancelOfferDialog: false,
      maturingUntil: props.maturingUntil || new Date(),
    };
  }

  componentDidMount() {
    this.props.fetchRatesheets();

    this.listMaturingTrades();

    this.props.listTenantsByIssuerTrades();

    const listenedEvents = [socketEvents.offersUpdated, socketEvents.rfqUpdated];

    listenedEvents.forEach((event) =>
      this.props.on(event, () => {
        this.listMaturingTrades({ showLoading: false });

        mutate(buildMaturingTradesList());
      }),
    );
  }

  listMaturingTrades = (params) =>
    this.props.listMaturingTrades({ to: formatToShortDateWithoutTz(this.state.maturingUntil), ...params });

  onToChange = (maturingUntil) => {
    this.setState({ maturingUntil }, this.listMaturingTrades);
  };

  downloadTrades = async (selectedValue) => {
    this.setState({ isDownloadingCsv: true });

    const todayDate = formatToShortDate(startOfDay(today()));
    const endOfTime = formatToShortDate(addYears(today(), 200));

    const period = {
      from: todayDate,
      to: selectedValue === 'all' ? endOfTime : todayDate,
    };

    const filePrefix =
      selectedValue === 'all' ? `all_future_maturing_trades_${todayDate}` : `maturing_trades_${todayDate}`;

    try {
      await downloadMaturingTradesCSV(period, filePrefix);
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    } finally {
      this.setState({ isDownloadingCsv: false });
    }
  };

  buildSegmentsOptions = () =>
    segmentCodes.map(({ label, code: value }) => ({
      label: this.props.intl.formatMessage({ id: label }),
      value,
    }));

  buildStatusesOptions = () =>
    Object.values(statuses).map((status) => ({
      label: this.props.intl.formatMessage({ id: status }),
      value: status,
    }));

  buildInstrumentCodeOptions = () =>
    [TD, ECD, CP].map((instrumentCode) => ({
      label: this.props.intl.formatMessage({
        id: instrumentCode.toLowerCase(),
      }),
      value: instrumentCode,
    }));

  renderMaturitiesFilter = () => {
    const {
      issuerTrades: { filter },
    } = this.props;

    return (
      <Row className="maturities-filter" wrap>
        <Row flex={1} contentBetween>
          <Column className="datepicker-to filter-item">
            <FormattedMessage tagName="span" id="maturingUntil" />
            <DatePicker options={datePickerOptions} onChange={this.onToChange} value={this.state.maturingUntil} />
          </Column>
          {renderSelect({
            name: 'instrumentCode',
            label: 'dashboard.assetClass',
            isClearable: true,
            defaultValue: filter.instrumentCode,
            onDropdownFilterChange: (property, value) => this.filterMaturingTrades({ property, value }),
            options: this.buildInstrumentCodeOptions(),
            className: 'filter-item',
          })}
          {renderSelect({
            name: 'status',
            label: 'status',
            isClearable: true,
            defaultValue: filter.status,
            onDropdownFilterChange: (property, value) => this.filterMaturingTrades({ property, value }),
            options: this.buildStatusesOptions(),
            className: 'filter-item',
          })}
          {renderSelect({
            name: 'segment',
            label: 'segment',
            isClearable: true,
            defaultValue: filter.segment,
            onDropdownFilterChange: (property, value) => this.filterMaturingTrades({ property, value }),
            options: this.buildSegmentsOptions(),
            className: 'filter-item',
          })}
          <div className="customer-filter filter-item">
            <p className="grey-color text-ellipsis">
              <FormattedMessage id="customer" />
            </p>
            <InputSearch
              onChange={this.filterCustomerName}
              defaultValue={filter.customer}
              onClear={() =>
                this.filterMaturingTrades({
                  property: 'customer',
                  value: undefined,
                })
              }
            />
          </div>
          <Button onClick={() => this.props.filterMaturingTrades({})}>
            <FormattedMessage id="reset" />
          </Button>
        </Row>
        <Row flex={1} alignItemsEnd contentEnd>
          {this.renderUpdateRatesheetSection()}
          {this.renderDownloadSelect()}
        </Row>
      </Row>
    );
  };

  renderUpdateRatesheetSection = () => {
    const { bankRatesheet, userIsAdmin } = this.props;

    const latestTermDepositAvailable = bankRatesheet.TD && bankRatesheet.TD.find(({ available }) => available);

    if (!latestTermDepositAvailable) {
      return null;
    }

    const latestTermDepositUpdatedAt = moment(latestTermDepositAvailable.updatedAt);

    return (
      userIsAdmin && (
        <Row>
          <Tooltip
            id="updateRatesheetButton"
            tooltipComponent={
              <FormattedMessage
                id="ratesheetLastUpdatedAtWithValues"
                values={{
                  value: `${latestTermDepositUpdatedAt.fromNow()} at ${latestTermDepositUpdatedAt.format('LT')}`,
                }}
              />
            }
          >
            <Button onClick={() => this.setState({ showUpdateRatesheetDialog: true })}>
              {!sameDayAsToday(latestTermDepositAvailable.updatedAt) && (
                <i role="img" aria-label="stale ratesheet" className="icon fa fa-exclamation-triangle" />
              )}
              <FormattedMessage id="updateRatesheet" />
            </Button>
          </Tooltip>
        </Row>
      )
    );
  };

  filterCustomerName = (event) =>
    this.filterMaturingTrades({
      property: 'customer',
      value: event.target.value,
    });

  filterMaturingTrades = ({ property, value }) =>
    this.props.filterMaturingTrades({
      ...this.props.issuerTrades.filter,
      [property]: value,
    });

  onSortColumnOrder = ({ sort: by }) => {
    const {
      issuerTrades: { filter },
    } = this.props;

    let order;

    if (filter.sort && filter.sort.by === by) {
      order = filter.sort.order === 'desc' ? 'asc' : 'desc';
    }

    this.filterMaturingTrades({
      property: 'sort',
      value: { by, order: order || 'asc' },
    });
  };

  onSendEmailCancel = () => this.setState({ tradeSelected: undefined, showOfferDialog: false });

  openDetails = (tradeSelected) => this.setState({ tradeSelected, isViewingOffer: true });

  onClose = () => this.setState({ isViewingOffer: false });

  onSendOfferClickFromDetails = () => this.setState({ showOfferDialog: true, isViewingOffer: false });

  openOfferActionDialog = (tradeSelected) => {
    this.setState({ showOfferDialog: true, tradeSelected });
  };

  renderDownloadSelect = () => {
    const downloadSelectOptions = [
      { value: 'today', label: <FormattedMessage id="download.csv" /> },
      { value: 'all', label: <FormattedMessage id="download.csvAll" /> },
    ];

    return (
      <DownloadSelect
        isDownloading={this.state.isDownloadingCsv}
        options={downloadSelectOptions}
        selectedValue={downloadSelectOptions[0]}
        onDownloadClick={this.downloadTrades}
      />
    );
  };

  buildOfferPayload = (customRatesheet) => {
    const {
      tradeSelected: { id: tradeId, customerTenant },
    } = this.state;

    let ratesheet;

    if (customRatesheet.rates.some((rate) => rate.isCustom)) {
      ratesheet = {
        rates: customRatesheet.rates.map(({ tenor, rate, isCustom }) => ({
          tenor,
          rate,
          isCustom,
        })),
        isCustom: true,
      };
    }

    return {
      tradeId,
      investorTenantId: customerTenant.id,
      ratesheet,
    };
  };

  onSendOffer = async (customRatesheet) => {
    const offerPayload = this.buildOfferPayload(customRatesheet);

    try {
      this.setState({ isCreatingOffer: true });

      const createdOffer = await createOffer(offerPayload);

      this.props.addOfferToIssuerTrade(createdOffer);

      this.listMaturingTrades({ showLoading: false });

      showToastMessage(this.props.intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);

      this.props.emit(socketEvents.offersUpdated, {
        tenants: [this.state.tradeSelected.customerTenant.domain],
        room: rooms.rfq,
      });

      this.setState({
        tradeSelected: undefined,
        showOfferDialog: false,
      });
    } catch (error) {
      showToastMessage(
        this.props.intl.formatMessage({
          id: 'anErrorHasOccurredPleaseTryAgain',
        }),
      );
    } finally {
      this.setState({ isCreatingOffer: false });
    }
  };

  buildReinvestmentPayload = (bid) => {
    const { customMaturityDate, selectedOffer, accruedInterest, interestPaid = 'Z', ...reinvest } = bid;
    const { unit, time, rate } = selectedOffer;

    delete reinvest.tenorFromRatesheet;

    return {
      ...reinvest,
      interestPaid,
      customMaturityDate,
      accruedInterest: accruedInterest || undefined,
      unitOfTime: unit,
      amountOfTime: +time,
      rate,
    };
  };

  updateOutgoingRfqs = () =>
    this.props.emit(socketEvents.rfqUpdated, {
      tenants: [this.state.tradeSelected.customerTenant.domain],
      room: rooms.rfq,
    });

  onReinvestmentConfirm = async ({ bid }) => {
    try {
      await actOnBehalf(
        this.buildActOnBehalfPayload({
          operation: 'reinvest',
          payload: this.buildReinvestmentPayload(bid),
        }),
      );
      const { tradeSelected } = this.state;

      const tradeSelectedWithOffer = clone(tradeSelected);
      tradeSelectedWithOffer.status = statuses.reinvestedOnBehalf;
      tradeSelectedWithOffer.maturityAction = maturityActions.reinvested;
      tradeSelectedWithOffer.maturityActionConfirmed = true;

      this.updateOutgoingRfqs();

      showToastMessage(this.props.intl.formatMessage({ id: 'reinvestSuccessfully' }), MessageType.SUCCESS);

      this.setState({ tradeSelected: tradeSelectedWithOffer });
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    }
  };

  onOneDayRolloverClick = (tradeSelected) => this.setState({ showOneDayRolloverModal: true, tradeSelected });

  onReinvestClick = (tradeSelected) => this.setState({ showReinvestModal: true, tradeSelected });

  onAddFundsConfirm = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      const { tenantId, principal, coupon, callableDate, purchaseDate, maturityDate, ...body } = values;

      await createTradeOnBehalf({
        tenantId,
        body: {
          ...body,
          callableDate: callableDate && formatToShortDateWithoutTz(callableDate),
          purchaseDate: formatToShortDateWithoutTz(purchaseDate),
          maturityDate: formatToShortDateWithoutTz(maturityDate),
          principal: toFloat(principal),
          coupon: toFloat(coupon),
        },
      });

      this.listMaturingTrades();

      showToastMessage(this.props.intl.formatMessage({ id: 'tradeCreatedSuccessfully' }), MessageType.SUCCESS);

      this.setState({ showAddFundsModal: false }, () => resetForm({ values: { ...initialFormValues } }));
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    } finally {
      setSubmitting(false);
    }
  };

  onAddFundsClick = () => this.setState({ showAddFundsModal: true });

  onAddFundsHide = () => this.setState({ showAddFundsModal: false });

  onReinvestHideClick = () => {
    const {
      tradeSelected: { status, maturityAction },
    } = this.state;

    if (status === statuses.reinvestedOnBehalf && maturityAction === maturityActions.reinvested) {
      this.listMaturingTrades({ showLoading: false });
    }

    this.setState({ tradeSelected: undefined, showReinvestModal: false });
  };

  onOneDayRolloverCancel = () => this.setState({ tradeSelected: undefined, showOneDayRolloverModal: false });

  onOneDayRolloverConfirm = async (payload) => {
    try {
      this.setState({ isSavingOneDayRollover: true });

      await postIssuerTrades(this.buildRolloverPostPayload(payload));

      this.updateOutgoingRfqs();

      showToastMessage(this.props.intl.formatMessage({ id: 'oneDayRolloverSuccessful' }), MessageType.SUCCESS);

      this.listMaturingTrades({ showLoading: false });

      this.setState({
        tradeSelected: undefined,
        showOneDayRolloverModal: false,
      });
    } catch (error) {
      showToastMessage(
        this.props.intl.formatMessage({
          id: 'anErrorHasOccurredPleaseTryAgain',
        }),
      );
    } finally {
      this.setState({ isSavingOneDayRollover: false });
    }
  };

  buildRolloverPostPayload = ({ coupon, bankReference }) => {
    const {
      tradeSelected: { id: tradeId, customerTenant },
    } = this.state;

    return {
      operation: 'rollover',
      tradeId,
      tenantId: customerTenant.id,
      coupon: toFloat(coupon),
      bankReference,
    };
  };

  openRedeemActionDialog = (tradeSelected) => this.setState({ tradeSelected, showRedeemConfirmation: true });

  openUpdateRatesheetDialog = (tradeSelected) => this.setState({ tradeSelected, showUpdateRatesheetDialog: true });

  showUpdateRatesheetDialog = () => this.setState({ showUpdateRatesheetDialog: true });

  onUpdateRatesheetDialogHide = () => this.setState({ showUpdateRatesheetDialog: false });

  onRatesheetUpdated = () =>
    this.setState({ showUpdateRatesheetDialog: false }, () => this.listMaturingTrades({ showLoading: false }));

  onRedeemConfirm = async () => {
    try {
      this.setState({ isConfirmingRedeem: true });

      await redeem(this.buildActOnBehalfPayload({ operation: 'redeem' }));

      this.updateOutgoingRfqs();

      showToastMessage(this.props.intl.formatMessage({ id: 'redeemSuccessfully' }), MessageType.SUCCESS);

      this.listMaturingTrades({ showLoading: false });

      this.setState({
        tradeSelected: undefined,
        showRedeemConfirmation: false,
      });
    } catch (error) {
      showToastMessage(
        this.props.intl.formatMessage({
          id: 'anErrorHasOccurredPleaseTryAgain',
        }),
      );
    } finally {
      this.setState({ isConfirmingRedeem: false });
    }
  };

  buildActOnBehalfPayload = ({ operation, payload }) => {
    const {
      tradeSelected: {
        id: tradeId,
        customerTenant: { id: tenantId },
      },
    } = this.state;

    const body = { operation, payload: { ...payload, tradeId } };

    return { tenantId, body };
  };

  onRedeemCancel = () => this.setState({ tradeSelected: undefined, showRedeemConfirmation: false });

  goToRfq = ({ uuid }) => this.props.navigate(routes.incoming.currentDetails.replace(':uuid', uuid));

  openCancelOngoingActionDialog = (tradeSelected) => {
    this.setState({ tradeSelected, showCancelOfferDialog: true });
  };

  onConfirmToCancelOngoingOffer = async () => {
    const {
      tradeSelected: {
        offer: { id: offerId },
      },
    } = this.state;

    try {
      this.setState({ isCancellingOffer: true });

      await deleteOffer(offerId);

      showToastMessage(
        this.props.intl.formatMessage({
          id: 'cancelOngoingOfferSuccessfully',
        }),
        MessageType.SUCCESS,
      );

      this.listMaturingTrades({ showLoading: false });

      this.setState({ tradeSelected: undefined, showCancelOfferDialog: false });
    } catch (error) {
      showToastMessage(
        this.props.intl.formatMessage({
          id: 'anErrorHasOccurredPleaseTryAgain',
        }),
      );
    } finally {
      this.setState({ isCancellingOffer: false });
    }
  };

  onCloseCancelOngoingOfferDialog = () => this.setState({ tradeSelected: undefined, showCancelOfferDialog: false });

  getRatesheetBySegmentCode = () => {
    const { tradeSelected } = this.state;

    if (!tradeSelected) {
      return undefined;
    }

    const { bankRatesheet } = this.props;

    const ratesheet = bankRatesheet[getInstrumentCode(tradeSelected.instrumentCode)];

    if (!ratesheet) {
      return undefined;
    }

    return ratesheet.find(({ segmentCode }) => segmentCode === tradeSelected.customerTenant.segmentCode);
  };

  isAdmin = () => this.props.tenantRole === 'admin' || this.props.globalRole === 'admin';

  render() {
    const {
      isViewingOffer,
      showAddFundsModal,
      showOneDayRolloverModal,
      showOfferDialog,
      isCreatingOffer,
      isSavingOneDayRollover,
      isConfirmingRedeem,
      showRedeemConfirmation,
      showUpdateRatesheetDialog,
      tradeSelected,
      showReinvestModal,
      isCancellingOffer,
      showCancelOfferDialog,
      maturingUntil,
    } = this.state;

    const { issuerTrades, issuerTradesList, currency, dashboardSummary } = this.props;
    const { showLoading, isFetching, tenants, error, filter } = issuerTrades;

    const moneySymbol = getMoneySymbol({ currency, short: true });

    const isLoading = showLoading && isFetching;

    const presenterBuilderMetadata = {
      isFetching: isLoading,
      noRecords: !issuerTradesList || !issuerTradesList.length,
      recordsPresenter: {
        data: issuerTradesList,
        presenter,
        columns: columns(moneySymbol),
        onSortColumnOrder: this.onSortColumnOrder,
        highlightedColumn: filter.sort && `${filter.sort.order === 'desc' ? '-' : ''}${filter.sort.by}`,
        actions: {
          openDetails: this.openDetails,
          openOfferActionDialog: this.openOfferActionDialog,
          onOneDayRolloverClick: this.onOneDayRolloverClick,
          onReinvestClick: this.onReinvestClick,
          openRedeemActionDialog: this.openRedeemActionDialog,
          goToRfq: this.goToRfq,
          openUpdateRatesheetDialog: this.openUpdateRatesheetDialog,
          openCancelOngoingActionDialog: this.openCancelOngoingActionDialog,
        },
        options: {
          isAdmin: this.isAdmin(),
          maturingUntil,
        },
      },
      filter: {
        component: this.renderMaturitiesFilter(),
      },
      hasError: !!error,
    };

    const ratesheet = this.getRatesheetBySegmentCode();

    return (
      <div
        role="region"
        aria-label="maturing trades section"
        className={classNames('maturities', { 'is-loading': showLoading })}
      >
        <MaturingTradesHeader
          isLoading={issuerTrades.showLoading && issuerTrades.isFetching}
          summary={dashboardSummary}
          onAddFundsClick={this.onAddFundsClick}
        />
        <Column>
          <MaturingTradesTransactionsChart issuerTrades={issuerTrades.list} />
          <ResultsPresenter {...presenterBuilderMetadata} />
        </Column>
        <AddFundsDialog
          tenants={tenants}
          show={showAddFundsModal}
          onCancel={this.onAddFundsHide}
          onConfirm={this.onAddFundsConfirm}
        />
        <UpdateRatesheetDialog
          onRatesheetUpdated={this.onRatesheetUpdated}
          show={showUpdateRatesheetDialog}
          onHide={this.onUpdateRatesheetDialogHide}
        />
        {tradeSelected && (
          <React.Fragment>
            <ViewOfferDialog
              open={isViewingOffer}
              onClose={this.onClose}
              tradeSelected={tradeSelected}
              bankRatesheet={ratesheet}
              onSendOffer={this.onSendOfferClickFromDetails}
              onCancelOffer={this.onConfirmToCancelOngoingOffer}
            />
            {ratesheet && (
              <SendOfferDialog
                show={showOfferDialog}
                isCreatingOffer={isCreatingOffer}
                onUpdateRatesheetClick={this.showUpdateRatesheetDialog}
                canUpdateRatesheet={this.isAdmin()}
                ratesheet={ratesheet}
                onConfirm={this.onSendOffer}
                onCancel={this.onSendEmailCancel}
                tradeSelected={tradeSelected}
              />
            )}
            <OneDayRolloverDialog
              isConfirming={isSavingOneDayRollover}
              show={showOneDayRolloverModal}
              tradeSelected={tradeSelected}
              onConfirm={this.onOneDayRolloverConfirm}
              onCancel={this.onOneDayRolloverCancel}
            />
            {ratesheet && (
              <ReinvestOnBehalfDialog
                show={showReinvestModal}
                offerDetails={buildOfferDetails({ tradeSelected })}
                onHide={this.onReinvestHideClick}
                tradeSelected={tradeSelected}
                ratesheet={ratesheet}
                onConfirm={this.onReinvestmentConfirm}
                onReject={this.onOneDayRolloverCancel}
              />
            )}
            <ConfirmDialog
              data-testid="confirm-redeem-dialog"
              isConfirming={isConfirmingRedeem}
              show={showRedeemConfirmation}
              titleId="confirmRedeemOnBehalfTitle"
              contentId="confirmRedeemOnBehalfContent"
              onConfirm={this.onRedeemConfirm}
              onCancel={this.onRedeemCancel}
            />
            <ConfirmDialog
              data-testid="cancel-ongoing-offer-dialog"
              isConfirming={isCancellingOffer}
              show={showCancelOfferDialog}
              titleId="cancelOfferTitle"
              contentId="cancelOfferContent"
              onConfirm={this.onConfirmToCancelOngoingOffer}
              onCancel={this.onCloseCancelOngoingOfferDialog}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

MaturingTradesComponent.propTypes = {
  issuerTrades: PropTypes.shape().isRequired,
  dashboardSummary: PropTypes.shape().isRequired,
  issuerTradesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  bankRatesheet: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
  listMaturingTrades: PropTypes.func.isRequired,
  on: PropTypes.func,
  emit: PropTypes.func,
  addOfferToIssuerTrade: PropTypes.func.isRequired,
  filterMaturingTrades: PropTypes.func.isRequired,
  updateIssuerTrade: PropTypes.func.isRequired,
  fetchRatesheets: PropTypes.func.isRequired,
  listTenantsByIssuerTrades: PropTypes.func.isRequired,
  tenantRole: PropTypes.string.isRequired,
  globalRole: PropTypes.string,
  maturingUntil: PropTypes.string,
  userIsAdmin: PropTypes.bool,
};

MaturingTradesComponent.defaultProps = {
  globalRole: '',
};

export const MaturingTrades = compose(
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.offers] }),
  injectIntl,
  withNavigate,
)(MaturingTradesComponent);
