import classNames from 'classnames';
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';

export const columns = {
  isCompliant: { label: 'compliant', className: 'is-compliant' },
  label: { label: 'maturityLabel' },
  amountInvested: { label: 'complianceAmountInvested' },
  investmentSharePct: { label: 'complianceInvestmentSharePct' },
  deficit: { label: 'offLimit' },
  minLimitPct: { label: 'complianceMinLimitPct' },
  maxLimitPct: { label: 'complianceMaxLimitPct' },
  amountAvailable: { label: 'complianceAmountAvailable' },
};

export const presenter = (data, index) => {
  const compliantClass = {
    green: data.isCompliant,
    red: !data.isCompliant,
  };
  const offLimit = {
    red: data.offLimit !== 0,
  };

  return (
    <tr key={index}>
      <td className={classNames('is-compliant', compliantClass)}>
        <Glyphicon glyph={data.isCompliant ? 'ok' : 'remove'} />
      </td>
      <td>{data.label}</td>
      <td className={classNames({ red: !data.isCompliant })}>
        <FormattedNumber
          value={data.amountInvested}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td className={classNames({ red: !data.isCompliant })}>
        <FormattedNumber
          value={data.investmentSharePct}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td className={classNames(offLimit)}>
        <FormattedNumber
          value={data.offLimit}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td>
        <FormattedNumber
          value={data.minLimitPct}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td>
        <FormattedNumber
          value={data.maxLimitPct}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td className={classNames({ red: !data.isCompliant })}>
        <FormattedNumber
          value={data.amountAvailable}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
    </tr>
  );
};
