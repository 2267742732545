import './confirm-redemption.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const ConfirmRedemption = ({ values, firstParagraph }) => (
  <div className="confirm-redemption">
    <div className="message">
      <FormattedMessage id={firstParagraph} values={values} />
    </div>
  </div>
);

ConfirmRedemption.propTypes = {
  values: PropTypes.shape(),
  firstParagraph: PropTypes.string,
};

ConfirmRedemption.defaultProps = {
  firstParagraph: 'needToConfirmFirstParagraph',
};

export default ConfirmRedemption;
