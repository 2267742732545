export function getCurrencyData(currency) {
  return currencies[currency] || currency.AUD;
}

const currencies = {
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbolNative: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'Australian dollars',
  },
  BRL: {
    symbol: 'R$',
    name: 'Brazilian Real',
    symbolNative: 'R$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'Brazilian reals',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbolNative: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'Canadian dollars',
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
    symbolNative: 'CHF ',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'swiss francs',
  },
  CNH: {
    symbol: '¥',
    name: 'Chinese Yuan',
    symbolNative: '¥',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'yuans',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbolNative: '€',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'euros',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbolNative: '£',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'British pounds sterling',
  },
  HKD: {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    symbolNative: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'Hong Kong dollars',
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbolNative: '￥',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    namePlural: 'Japanese yen',
  },
  NZD: {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    symbolNative: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'New Zealand dollars',
  },
  SGD: {
    symbol: 'S$',
    name: 'Singapore Dollar',
    symbolNative: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'Singapore dollars',
  },
  USD: {
    symbol: 'US$',
    name: 'US Dollar',
    symbolNative: '$',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    namePlural: 'US dollars',
  },
};
