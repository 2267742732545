import { createSelector } from 'reselect';

const rfqsHistoryFilter = (state) => state.incomingRfqsReport.filter;
const incomingRfqs = (state) => state.incomingRfqsReport.incomingRfqs;

export const filteredRfqsSelector = createSelector([rfqsHistoryFilter, incomingRfqs], (filter, rfqs) => {
  let filteredRfqs = rfqs;

  if (Object.keys(filter).length === 0) {
    return rfqs;
  }

  Object.keys(filter).forEach((key) => {
    const value = filter[key];
    filteredRfqs = value === 'all' ? filteredRfqs : filterBy(key, value, filteredRfqs);
  });

  return filteredRfqs;
});

const filterBy = (filter, value, rfqs) => rfqs.filter((rfq) => rfq[filter] && rfq[filter] === value);
