import Valida from 'valida';
import { validationErrorKeys } from '../../../validation/error-keys';
import { positiveValue, maxFloat } from '../../../parse-float';
import { purchaseValidator, afterJSONDateValidator } from '../../../actions/helper/validation';

export default {
  sellingDate: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.date },
    { validator: purchaseValidator, errorMsg: validationErrorKeys.sellGreaterThanToday },
    {
      validator: afterJSONDateValidator,
      afterDateFormField: 'ecd',
      afterDateFieldKey: 'purchaseDate',
    },
  ],
  sellingPrice: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue },
    { validator: maxFloat },
  ],
};
