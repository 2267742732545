import buildInitialValues from '../base/initial-values';
import { toDateWithZeroTime } from '../../../date';

export default function() {
  const initialValues = buildInitialValues();

  return {
    ...initialValues,
    callableDate: toDateWithZeroTime(),
    interestPaidCode: 'Z',
  };
}
