import './default-panel.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'react-loaders';

import { PanelPresenter } from '../../components/common';
import Row from '../../components/dashboard/adi-trades/row';
import Table from '../../components/dashboard/adi-trades/table';
import Top from '../../components/dashboard/adi-trades/top';
import { formatDate } from '../../date';
import { invertOperationForCounterparty } from '../rfqs/buy-sell-bond-rfqs/invert-operation-for-counterparty';

export default class Panel extends Component {
  getSummaryData = (adiTrades) =>
    adiTrades.reduce(
      (acc, trade) => {
        if (trade.operation === 'BUY') {
          acc.buyCount += 1; // eslint-disable-line no-param-reassign
          acc.buyPrincipal += trade.principal; // eslint-disable-line no-param-reassign
        } else {
          acc.sellCount += 1; // eslint-disable-line no-param-reassign
          acc.sellPrincipal += trade.principal; // eslint-disable-line no-param-reassign
        }

        return acc;
      },
      {
        buyCount: 0,
        sellCount: 0,
        buyPrincipal: 0,
        sellPrincipal: 0,
      },
    );

  renderLoader = () => <Loader size="sm" type="line-scale-pulse-out" />;

  renderData = (rows, showSettlementDate) => {
    const data = rows.length ? (
      <Table rows={rows} showSettlementDate={showSettlementDate} />
    ) : (
      <div className="no-data">
        <FormattedMessage id="rfqNoRecords" />
      </div>
    );

    return data;
  };

  render() {
    const { adiTrades, initialized, showSettlementDate } = this.props;
    const summaryData = this.getSummaryData(adiTrades);
    const shortDateFormat = 'DD/MM/YYYY';
    const capitalize = (text) => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

    const outgoingRfqsUrl = (uuid) => `/holdings/quotes/${uuid}`;

    const rows = adiTrades.length
      ? adiTrades
          .map((trade) => (
            <Row
              key={trade.uuid}
              operation={capitalize(trade.operation)}
              maturity={formatDate(trade.maturityDate, shortDateFormat)}
              instrumentCode={trade.instrumentCode}
              counterparty={trade.counterparty}
              currency={trade.currency}
              principal={trade.principal}
              coupon={trade.coupon}
              settlementDate={formatDate(trade.settlementDate, shortDateFormat)}
              showSettlementDate={showSettlementDate}
              actions={{
                viewSecurityRfq: () =>
                  this.props.showSecuritiesRfqDrawer({
                    uuid: trade.uuid,
                    operation: invertOperationForCounterparty(trade.operation),
                  }),
                viewSellQuotes: () => this.props.toggleRfqQuotesDialog(trade.uuid),
                viewBuyQuotes: () => {
                  window.location = outgoingRfqsUrl(trade.uuid);
                },
              }}
            />
          ))
          .sort((trade) => trade.timestamp)
      : [];

    return (
      <PanelPresenter className="panel-container">
        <Top summaryData={summaryData} />
        {initialized ? this.renderData(rows, showSettlementDate) : this.renderLoader()}
      </PanelPresenter>
    );
  }
}

Panel.propTypes = {
  adiTrades: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleRfqQuotesDialog: PropTypes.func.isRequired,
  showSecuritiesRfqDrawer: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
  showSettlementDate: PropTypes.bool.isRequired,
};
