import './update-ratesheet.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Column } from 'react-display-flex';
import { injectIntl } from 'react-intl';
import Loader from 'react-loaders';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as ratesheetActions from '../../actions/ratesheets';
import * as sessionActions from '../../actions/session';
import { Header } from '../../components/common';
import UpdateRatesheetPresenter from '../../components/ratesheets/ratesheet-update-presenter';
import { MessageType, showToastMessage } from '../toast/toast';
import { ToggleAutoQuoting } from './toggle-auto-quoting';

export class RatesheetsUpdateSheet extends Component {
  componentDidMount() {
    this.loadRatesheets();
  }

  componentDidUpdate() {
    const { actions, ratesheets } = this.props;

    if (ratesheets && ratesheets.updateData) {
      ratesheets.updateError
        ? showToastMessage(this.props.intl.formatMessage({ id: 'internalError.info' }))
        : this.handleUpdateRatesheetSuccess();

      this.loadRatesheets();

      actions.ratesheets.resetUpdate();
    }
  }

  handleUpdateRatesheetSuccess = () => {
    const { onRatesheetUpdated } = this.props;

    showToastMessage(this.props.intl.formatMessage({ id: 'successUpdate' }), MessageType.SUCCESS);

    onRatesheetUpdated && onRatesheetUpdated();
  };

  loadRatesheets = () => {
    this.props.actions.ratesheets.fetchRatesheets();
  };

  updateRatesheets =
    (instrumentCode) =>
    ({ rawData, columnHeaders }) => {
      const { actions } = this.props;
      const ratesheetsPayload = this.buildUpdatePayload({
        rawData,
        instrumentCode,
        columnHeaders,
      });

      actions.ratesheets.updateRatesheets(ratesheetsPayload);
    };

  buildUpdatePayload({ rawData, instrumentCode, columnHeaders }) {
    const ratesheets = rawData.map((segmentRow) => {
      const segmentCode = segmentRow.shift();
      const available = segmentRow.shift().toLowerCase() === 'yes';
      const rates = this.buildRates({ columnHeaders, rawRates: segmentRow });

      const ratesheet = {
        segmentCode,
        available,
        rates,
        instrumentCode,
      };

      return ratesheet;
    });

    return ratesheets;
  }

  tenorsStartIndex = 2;

  buildRates = ({ columnHeaders, rawRates }) =>
    rawRates.reduce((compiledRates, rateValue, index) => {
      compiledRates[columnHeaders[index + this.tenorsStartIndex]] = rateValue !== null ? +rateValue : null; // eslint-disable-line no-param-reassign

      return compiledRates;
    }, {});

  getPropsByInstrumentType = ({ subtitle, instrumentCode }) => {
    const { isFetching, data, isUpdating } = this.props.ratesheets;

    return {
      isFetching,
      isUpdating,
      data,
      onClickUpdateRatesheet: ({ rawData, columnHeaders }) =>
        this.updateRatesheets(instrumentCode)({ rawData, columnHeaders }),
      subtitle,
      instrumentCode,
    };
  };

  buildUpdateSheetPresenters = () =>
    this.props.ratesheets.data ? (
      Object.keys(this.props.ratesheets.data.instruments).map((instrumentCode) => (
        <UpdateRatesheetPresenter
          key={instrumentCode}
          {...this.getPropsByInstrumentType({
            subtitle: `${instrumentCode}RatesheetsSubtitle`,
            instrumentCode: instrumentCode.toUpperCase(),
          })}
        />
      ))
    ) : (
      <Column className="loading-container" alignItemsCenter justifyContentCenter>
        <Loader />
      </Column>
    );

  render() {
    return (
      <div aria-label="update ratesheet container" className="update-ratesheet-container">
        <Header titleId="updateRatesheetPageTitle">
          <ToggleAutoQuoting />
        </Header>
        {this.buildUpdateSheetPresenters()}
      </div>
    );
  }
}

RatesheetsUpdateSheet.propTypes = {
  actions: PropTypes.shape().isRequired,
  onRatesheetUpdated: PropTypes.func,
  ratesheets: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  ratesheets: state.ratesheets,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ratesheets: bindActionCreators(ratesheetActions, dispatch),
    session: bindActionCreators(sessionActions, dispatch),
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(RatesheetsUpdateSheet);
