import Valida from 'valida';
import { positiveValue, maxFloat } from '../../../parse-float';

export default {
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue },
    { validator: maxFloat },
  ],
  instrumentCode: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
  allocationCode: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
  interestPaidCode: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
};
