import './default-panel.scss';

import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'react-loaders';
import { compose } from 'redux';

import { loadableTypes, PanelPresenter } from '../../components/common';
import Row from '../../components/dashboard/incoming/row';
import Table from '../../components/dashboard/incoming/table';
import Top from '../../components/dashboard/incoming/top';
import {
  withNavigate,
  withParams,
} from '../../components/hoc/with-router-properties';
import { BuySellBondRfqsSendQuotesDrawer } from '../rfqs/buy-sell-bond-rfqs/BuySellBondRfqsSendQuotesDrawer';
import { SendQuoteDrawer } from './BankDashboard/SendQuotes/SendQuoteDrawer';

const requestBondRfqInstrumentCodes = ['BOND', 'FRN'];
const rfqsTab = '/dashboard/rfqs';

const Panel = ({ data, toggleRfqQuotesDialog, initialized, onQuoteUpdatedSuccess, params, navigate }) => {
  const isRfqClosed = (status) => !['ongoing', 'pending'].includes(status.toLowerCase());
  const [requestBondRfq, setRequestBondRfq] = useState(null);
  const [requestRfq, setRequestRfq] = useState(null);

  useEffect(() => {
    if (data && data.instruments && params.rfqUuid) {
      const rfq = data.instruments.find(({ uuid }) => uuid === params.rfqUuid);

      rfq && openRfqDrawer({ rfq });
    }
  }, [data]);

  const openRfqDrawer = ({ rfq }) => {
    setRequestRfq(rfq);
    navigate(`${rfqsTab}/${rfq.uuid}/quotes`, { replace: true });
  };

  const quote =
    ({ rfq }) =>
    () => {
      if (requestBondRfqInstrumentCodes.includes(rfq.assetClass)) {
        setRequestBondRfq(rfq);

        return;
      }

      setRequestBondRfq(null);
      openRfqDrawer({ rfq });
    };

  const onSendQuotesClose = useCallback(() => {
    setRequestRfq(null);
    navigate(rfqsTab, { replace: true });
  }, []);

  const view = ({ rfq }) => {
    if (requestBondRfqInstrumentCodes.includes(rfq.assetClass)) {
      return () => {
        setRequestBondRfq(rfq);
      };
    }

    return isRfqClosed(rfq.status) ? () => toggleRfqQuotesDialog(rfq.uuid) : () => openRfqDrawer({ rfq });
  };

  const rows = data.instruments.map((rfq) => (
    <Row
      key={rfq.uuid}
      assetClass={rfq.assetClass}
      status={rfq.status}
      cancelOption={rfq.cancelOption}
      customer={rfq.customer}
      createdBy={rfq.createdBy}
      canConfirmRfq={rfq.canConfirmRfq}
      createdByEmail={rfq.createdByEmail}
      createdByInitials={rfq.createdByInitials}
      customerCode={rfq.customerCode}
      currency={rfq.currency}
      principal={rfq.principal}
      settlementDate={rfq.settlementDate}
      operation={rfq.operation}
      actions={{
        quote: quote({ rfq }),
        view: view({ rfq }),
      }}
    />
  ));

  return (
    <PanelPresenter className="panel-container">
      <Top summary={data.summary} />
      {initialized ? renderData(rows) : renderLoader()}
      <BuySellBondRfqsSendQuotesDrawer
        rfq={requestBondRfq}
        onQuoteUpdatedSuccess={onQuoteUpdatedSuccess}
        onCloseDrawer={() => {
          setRequestBondRfq(null);
        }}
      />
      {requestRfq && <SendQuoteDrawer rfq={requestRfq} onClose={onSendQuotesClose} />}
    </PanelPresenter>
  );
};

const renderLoader = () => <Loader size="sm" type={loadableTypes.lineScalePulseOut} />;

const renderData = (rows) => {
  const data = rows.length ? (
    <Table rows={rows} />
  ) : (
    <div className="no-data">
      <FormattedMessage id="rfqNoRecords" />
    </div>
  );

  return data;
};

Panel.propTypes = {
  data: PropTypes.shape().isRequired,
  onQuoteUpdatedSuccess: PropTypes.func,
  toggleRfqQuotesDialog: PropTypes.func.isRequired,
  initialized: PropTypes.bool.isRequired,
};

export default compose(withParams, withNavigate)(Panel);
