import React from 'react';

import classNames from 'classnames';
import { Column, Row } from 'react-display-flex';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import Toggle from 'react-toggle';

import { Money } from '../../../containers/money';
import { toNumberFormat } from '../../../format-numbers';
import { Tooltip } from '../../common';

const ratingTypes = { short: 'short', long: 'long' };

export const issuerColumns = (currencySymbol) => ({
  actions: { label: 'rfqInclude' },
  instrumentCode: { label: 'rfqAdi', sort: 'shortName' },
  short: { label: 'rfqShort' },
  shortAmountAvailable: { label: 'customShortLimit', sort: 'shortAmountAvailable', values: { currencySymbol } },
  long: { label: 'rfqLong' },
  longAmountAvailable: { label: 'customLongLimit', sort: 'longAmountAvailable', values: { currencySymbol } },
});

export const issuersRecordsPresenter = (data, index, actions, options) => {
  const isCurrentTenantIssuer = data.id === options.tenantIssuerId;
  const shortAmountAvailable = getAmountAvailable({ data, options, type: ratingTypes.short });
  const longAmountAvailable = getAmountAvailable({ data, options, type: ratingTypes.long });
  const hasShortTerm = !options.longTermComplianceOnly;
  const ratesheetIssuer = options.tdRatesheets.find((ratesheet) => ratesheet.issuer.id === data.id);
  const issuerCanQuoteTD = !ratesheetIssuer || ratesheetIssuer.available;

  return (
    <tr key={index} className={classNames({ 'current-issuer-text': isCurrentTenantIssuer })}>
      {renderSelectButton({
        data,
        actions,
        isCurrentTenantIssuer,
        issuerCanQuoteTD,
        readOnlyIssuers: options.readOnlyIssuers,
      })}
      <td>{data.shortName}</td>
      {hasShortTerm && <td>{data.shortRating === 'Z' ? <FormattedMessage id="rfqUnrated" /> : data.shortRating}</td>}
      {hasShortTerm && (
        <Tooltip
          id="rfqRatingsLimitTooltip"
          tooltipComponent={renderMaturingTodayAmounts({
            shouldRender: data.id === options.reinvestmentIssuerId,
            amountAvailable: data.minimalAmountAvailable[ratingTypes.short],
            reinvestmentPrincipal: options.reinvestmentPrincipal,
          })}
        >
          <td className={classNames({ red: shortAmountAvailable < 0 })}>
            {shortAmountAvailable ? <Money value={shortAmountAvailable} /> : '-'}
          </td>
        </Tooltip>
      )}
      <td>{data.longRating === 'Z' ? <FormattedMessage id="rfqUnrated" /> : data.longRating}</td>
      <Tooltip
        id="rfqRatingsLimitTooltip"
        tooltipComponent={renderMaturingTodayAmounts({
          shouldRender: data.id === options.reinvestmentIssuerId,
          amountAvailable: data.minimalAmountAvailable[ratingTypes.long],
          reinvestmentPrincipal: options.reinvestmentPrincipal,
        })}
      >
        <td className={classNames({ red: longAmountAvailable < 0 })}>
          {longAmountAvailable ? <Money value={longAmountAvailable} /> : '-'}
        </td>
      </Tooltip>
    </tr>
  );
};

const renderMaturingTodayAmounts = ({ shouldRender, amountAvailable, reinvestmentPrincipal }) =>
  shouldRender ? (
    <Column>
      Amount Available: {toNumberFormat({ value: amountAvailable, maximumFractionDigits: 2 })}
      <br />
      Reinvestment: {toNumberFormat({ value: reinvestmentPrincipal, maximumFractionDigits: 2 })}
      <br />
      Total Available: {toNumberFormat({ value: amountAvailable + reinvestmentPrincipal, maximumFractionDigits: 2 })}
      <br />
    </Column>
  ) : null;

const getAmountAvailable = ({ data, options, type }) => {
  const amountAvailable = data.minimalAmountAvailable && data.minimalAmountAvailable[type];

  return data.id === options.reinvestmentIssuerId ? amountAvailable + options.reinvestmentPrincipal : amountAvailable;
};

// eslint-disable-next-line
function renderSelectButton({ data, actions, isCurrentTenantIssuer, issuerCanQuoteTD, readOnlyIssuers = [] }) {
  if (isCurrentTenantIssuer) {
    return <td key={data.id} />;
  }

  return issuerCanQuoteTD ? (
    <td className="actions-column">
      <Row element="label" alignItemsCenter>
        <Toggle
          disabled={readOnlyIssuers.includes(data.id)}
          checked={data.selected}
          icons={false}
          onChange={() => actions.onIssuerSelectChange(data)}
        />
        <FormattedMessage tagName="span" id={data.selected ? 'selected' : 'select'} />
      </Row>
    </td>
  ) : (
    <td className="actions-column issuer-cannot-quote">
      <Row alignItemsCenter>
        <Tooltip id="adiCurrentlyNotSeeking">
          <FaInfoCircle />
        </Tooltip>
        <FormattedMessage id="unavailable" />
      </Row>
    </td>
  );
}
