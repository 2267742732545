import axios from 'axios';

export const buildOutgoingRfqsDetailsUrl = ({ rfqUUID }) => `/rfqs/sell-bonds/${rfqUUID}`;

export async function listOutgoingRfqsDetails(url) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
