import React from 'react';

import { connect } from 'react-redux';
import { Route, Routes } from 'react-router';

import { getRootPath } from './actions/session-selector';
import { ContainerContent } from './components/common';
import { licences, roles } from './components/common/Protect';
import { RequireProtection } from './components/common/Protect/Protect';
import { NotFound } from './components/error';
import { global } from './components/hoc/check-route';
import {
  App,
  CapitalValue,
  ChangePassword,
  Dashboard,
  ForgotPasswordPage,
  HoldingsPage,
  HomePage,
  IncomingPage,
  IncomingRfqs,
  InvestorOffer,
  Login,
  QuotesPage,
  ResetPasswordPage,
  RfqPage,
  RfqsExecutionOnlyPage,
  ToolsPage,
  UpdateRatesheetPage,
  OnboardingPage,
} from './containers';
import { UnsolicitedRatesheet } from './containers/dashboard/BankDashboard/UnsolicitedRatesheet/UnsolicitedRatesheet';

export const routes = {
  admin: {
    fees: '/tools/fees.html',
    maturingEmail: '/tools/maturing-trades-email.html',
    maturingTrades: '/tools/maturing-trades-report.html',
    portfolioPerformance: '/tools/portfolio-performance.html',
    rfqs: '/tools/rfqs.html',
    updatePassword: '/tools/update-password.html',
  },
  portfolioRoot: {
    base: '/portfolio',
    holdings: '/portfolio/holdings',
    rfqs: '/portfolio/rfqs',
  },
  dashboard: '/dashboard',
  dashboardWithTab: '/dashboard/:tab',
  dashboardWithSecondaryTab: '/dashboard/:tab/:secondaryTab',
  dashboardSendQuotes: '/dashboard/rfqs/:rfqUuid/quotes',
  enterQuotes: '/enter-quotes/:uuid',
  holdings: {
    list: '/holdings',
    quotes: '/holdings/quotes/',
    quotesDetails: '/holdings/quotes/:uuid',
    rfq: '/holdings/rfq/:id',
    newRfq: '/holdings/rfq/new',
    rfqs: '/holdings/rfqs',
  },
  unsolicitedRatesheets: {
    investor: {
      base: '/unsolicited-ratesheets',
    },
  },
  incoming: {
    base: '/incoming-rfqs',
    current: '/incoming-rfqs/:secondaryTab',
    currentDetails: '/incoming-rfqs/current/:uuid',
    tabs: '/incoming-rfqs/:secondaryTab/:uuid',
    fallbackRfq: '/holdings/incoming-rfqs/:uuid',
  },
  portfolio: '/portfolio',
  portfolioPrimaryTab: '/portfolio/:primaryTab',
  portfolioPrimaryAndSecondaryTabs: '/portfolio/:primaryTab/:secondaryTab',
  rfqsRoot: '/rfqs',
  rfqs: '/rfqs/:secondaryTab',
  offers: {
    details: '/offers/:id',
  },
  maturity: '/maturity',
  capitalValue: '/capital-value',
  ratesheets: {
    update: '/ratesheets/update',
  },
  root: '/',
  trades: {
    sandbox: '/sandbox',
  },
  upload: {
    trade: '/upload/trade',
  },
  user: {
    changePassword: '/admin/user/change-password',
  },
  public: {
    forgotPassword: '/forgot-password',
    resetPassword: '/password-reset',
    login: '/login',
  },
  onboarding: {
    base: '/onboarding',
  },
};

const availableRootPaths = {
  holdings: '/',
  dashboard: '/dashboard',
  outgoingRfqs: '/portfolio/rfqs',
  changePassword: '/admin/user/change-password',
};

export const AllRoutesComponent = ({ rootPath }) => (
  <Routes>
    <Route element={<HoldingsPage />} />
    <Route path={routes.public.login} element={<Login />} />
    <Route path={routes.public.forgotPassword} element={<ForgotPasswordPage />} />
    <Route path={routes.public.resetPassword} element={<ResetPasswordPage />} />
    <Route path="/" element={<App />}>
      <Route
        path={rootPath === availableRootPaths.changePassword ? routes.root : routes.user.changePassword}
        element={<ChangePassword />}
      />
      {[rootPath === availableRootPaths.holdings ? routes.root : routes.holdings.list, routes.portfolio].map(
        (route, index) => (
          <Route
            key={`${route}-${index}`}
            path={route}
            element={
              <RequireProtection requiredTo={roles.viewer} licence={licences.portfolio}>
                <HomePage />
              </RequireProtection>
            }
          />
        ),
      )}

      {[routes.portfolio, routes.portfolioPrimaryTab, routes.portfolioPrimaryAndSecondaryTabs].map((route, index) => (
        <Route
          key={`${route}-${index}`}
          path={route}
          element={
            <RequireProtection requiredTo={roles.viewer} licence={licences.portfolio}>
              <HomePage />
            </RequireProtection>
          }
        />
      ))}
      {[
        rootPath === availableRootPaths.dashboard ? routes.root : routes.dashboard,
        routes.dashboardWithTab,
        routes.dashboardWithSecondaryTab,
        routes.dashboardSendQuotes,
      ].map((route, index) => (
        <Route
          key={`${route}-${index}`}
          path={route}
          element={
            <RequireProtection requiredTo={roles.finance} licence={licences.dashboard}>
              <Dashboard />
            </RequireProtection>
          }
        />
      ))}
      {[routes.unsolicitedRatesheets.investor.base].map((route, index) => (
        <Route
          key={`${route}-${index}`}
          path={route}
          element={
            <RequireProtection requiredTo={roles.finance} licence={licences.unsolicitedRatesheet}>
              <ContainerContent titleId="unsolicitedRatesheet">
                <UnsolicitedRatesheet />
              </ContainerContent>
            </RequireProtection>
          }
        />
      ))}
      <Route
        path={routes.ratesheets.update}
        element={
          <RequireProtection requiredTo={roles.admin} bankOnly>
            <UpdateRatesheetPage />
          </RequireProtection>
        }
      />
      <Route
        path={routes.onboarding.base}
        element={
          <RequireProtection requiredTo={roles.admin} bankOnly>
            <OnboardingPage />
          </RequireProtection>
        }
      />
      {[routes.holdings.quotes, routes.holdings.quotesDetails].map((route, index) => (
        <Route
          key={`${route}-${index}`}
          path={route}
          element={
            <RequireProtection requiredTo={roles.finance}>
              <QuotesPage />
            </RequireProtection>
          }
        />
      ))}
      <Route
        path={routes.holdings.rfq}
        element={
          <RequireProtection requiredTo={roles.finance}>
            <RfqPage />
          </RequireProtection>
        }
      />
      {[
        routes.incoming.tabs,
        routes.incoming.fallbackRfq,
        routes.incoming.base,
        routes.incoming.current,
        routes.incoming.currentDetails,
      ].map((route, index) => (
        <Route
          key={`${route}-${index}`}
          path={route}
          element={
            <RequireProtection requiredTo={roles.finance}>
              <IncomingPage />
            </RequireProtection>
          }
        />
      ))}
      <Route
        path={routes.capitalValue}
        element={
          <RequireProtection requiredTo={roles.admin}>
            <CapitalValue />
          </RequireProtection>
        }
      />
      <Route path="/tools/:iframeSrc" element={<ToolsPage />} />
      <Route path={routes.enterQuotes} element={global(IncomingRfqs)()} />
      {[rootPath === availableRootPaths.outgoingRfqs ? routes.root : routes.rfqs, routes.rfqs, routes.rfqs].map(
        (route, index) => (
          <Route
            key={`${route}-${index}`}
            path={route}
            element={
              <RequireProtection>
                <RfqsExecutionOnlyPage />
              </RequireProtection>
            }
          />
        ),
      )}
      <Route
        path={routes.offers.details}
        element={
          <RequireProtection requiredTo={roles.finance}>
            <InvestorOffer />
          </RequireProtection>
        }
      />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export const AllRoutes = connect((state) => ({ rootPath: getRootPath(state) }))(AllRoutesComponent);
