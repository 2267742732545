import Valida from 'valida';
import BaseSchema from '../base/schema';
import { positiveValue, maxFloat } from '../../../parse-float';
import { maturityDateValidator } from '../../../actions/helper/validation';

export default {
  ...BaseSchema,
  coupon: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue },
    { validator: maxFloat },
  ],
  maturityDate: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.date },
    { validator: maturityDateValidator },
  ],
  interestCompoundCode: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
  interestPaidCode: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
};
