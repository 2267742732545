import React, { useEffect, useLayoutEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { compose } from 'redux';

import {
  Column,
  hasAccess,
  MainTabs,
  Row,
  Tabs,
} from '../../../components/common';
import {
  withLocation,
  withNavigate,
  withParams,
} from '../../../components/hoc/with-router-properties';
import { scrollToRootTop } from '../../../scroll-to-top';

const getInitialKey = (tabs) => {
  const tabsPermitted = tabs.filter((tabItem) =>
    tabItem.requiredTo ? hasAccess(tabItem.user, tabItem.requiredTo, null, tabItem.licence) : true,
  );

  return tabsPermitted.length > 0 && tabsPermitted[0].key;
};

export const ContainerTabComponent = withParams(
  ({ tabKey, routeBase, params, summary, children, onLocationUpdate, location, navigate, tabs }) => {
    const tabParam = params && params.secondaryTab;
    const availableTabs = tabs.map((item) => item.key);
    const initialKey = availableTabs.includes(tabParam) ? params.secondaryTab : getInitialKey(tabs);

    useEffect(() => {
      onLocationUpdate(initialKey);
    }, []);

    const [currentTab, setCurrentTab] = useState(initialKey);

    const updateLocation = (tab) => {
      setCurrentTab(tab);
      onLocationUpdate(tab);
      navigate(`${routeBase}/${tabKey}/${tab}`);
    };

    useLayoutEffect(scrollToRootTop, [location.pathname]);

    return (
      <Column className="tab-container">
        {summary && (
          <Row className="summary-section" contentStart>
            {summary}
          </Row>
        )}
        <MainTabs activeKey={currentTab} secondary>
          <Row contentBetween alignItemsCenter className="tab-container-header">
            <Tabs items={tabs} onSelect={updateLocation} />
          </Row>
          <Tab.Content animation>{children}</Tab.Content>
        </MainTabs>
      </Column>
    );
  },
);

ContainerTabComponent.propTypes = {
  summary: PropTypes.shape(),
  children: PropTypes.node.isRequired,
  tabKey: PropTypes.string.isRequired,
  onLocationUpdate: PropTypes.func,
  routeBase: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ContainerTabComponent.defaultProps = {
  onLocationUpdate: () => {},
  routeBase: '',
};

export const ContainerTab = compose(withParams, withNavigate, withLocation)(ContainerTabComponent);
