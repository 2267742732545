import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { fullCouponFormat } from '../../../../format-numbers';
import { Money } from '../../../money';
import { ResponseItem } from './ResponseItem';
import { DateWithTimeZone } from '../../../../date';
import { Column, Tooltip } from '../../../../components/common';
import { tradePropTypes } from './trade-prop-types';

export const AcceptedResponse = ({ trade }) => {
  const {
    bids: [bid],
    users,
  } = trade.offer;

  const { accrued, additionalFunds, rate, maturityDate } = bid;
  const principalTotal = trade.principal + additionalFunds + accrued;

  return (
    <Column>
      {users && users.investorUser && (
        <ResponseItem
          label={<FormattedMessage id="acceptedBy" />}
          value={
            <Tooltip delayHide={2000} id="acceptedBy" tooltipComponent={users.investorUser.email}>
              <span>{users.investorUser.fullName}</span>
            </Tooltip>
          }
        />
      )}
      <ResponseItem label="Accepted rate" value={<FormattedNumber {...fullCouponFormat} value={rate} />} />
      <ResponseItem label="Maturity date" value={<DateWithTimeZone value={maturityDate} />} />
      <ResponseItem label="Rollover" value={<Money value={trade.principal} short />} />
      <ResponseItem label="Accrued" value={<Money value={accrued} short />} />
      <ResponseItem
        label={additionalFunds > 0 ? 'Additional funds' : 'Partial Redeem'}
        value={<Money value={Math.abs(additionalFunds)} short />}
      />
      <ResponseItem className="hilite" label="Total reinvested" value={<Money value={principalTotal} short />} />
    </Column>
  );
};

AcceptedResponse.propTypes = { trade: tradePropTypes };

AcceptedResponse.defaultProps = {
  trade: {},
};
