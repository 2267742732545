import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { connect } from 'formik';
import { arrayOf, func, node, shape, string } from 'prop-types';
import { Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import Toggle from 'react-toggle';

import { austraclear, RfqBankSelection, wbc } from './RfqBankSelection';

export const RfqBankSelectionHasAustraclearComponent = ({ formik, austraclearLabelId, ...props }) => {
  const { values, setFieldValue, touched, errors, setFieldTouched, handleChange } = formik;

  const [hasAustraclear, setHasAustraclear] = useState(values.hasAustraclear);

  useEffect(() => {
    setHasAustraclear(values.hasAustraclear);
  }, [values.hasAustraclear]);

  const onHasAustraclearToggled = () => (e) => {
    if (!e.target.checked && values.issuerCodes.includes(wbc)) {
      const nextSelectedIssuers = [...values.issuerCodes];

      nextSelectedIssuers.splice(values.issuerCodes.indexOf(wbc), 1);

      setFieldValue('issuerCodes', nextSelectedIssuers);
    }

    setFieldValue('hasAustraclear', e.target.checked);
  };

  return (
    <React.Fragment>
      <Row className="has-austraclear-toggle" alignItemsCenter justifyContentEnd>
        <Toggle
          data-testid="has-austraclear-toggle"
          checked={hasAustraclear}
          onChange={onHasAustraclearToggled({ values, setFieldValue })}
        />
        <FormattedMessage id={austraclearLabelId} tagName="p" />
      </Row>
      <RfqBankSelection
        safeCustodyCode={hasAustraclear ? austraclear : null}
        className={classNames('validated-field', { 'field-error': touched.issuerCodes && errors.issuerCodes })}
        onSelectedIssuersChange={(nextSelectedIssuers) => {
          !touched.issuerCodes && setFieldTouched('issuerCodes');
          setFieldValue('issuerCodes', nextSelectedIssuers);
          handleChange('issuerCodes');
        }}
        {...props}
      >
        <Row className="error-message">{touched.issuerCodes && errors.issuerCodes}</Row>
      </RfqBankSelection>
    </React.Fragment>
  );
};

RfqBankSelectionHasAustraclearComponent.propTypes = {
  className: string,
  safeCustodyCode: string,
  austraclearLabelId: string,
  defaultSelectedIssuers: arrayOf(string),
  children: node,
  onSelectedIssuersChange: func,
  formik: shape(),
};

RfqBankSelectionHasAustraclearComponent.defaultProps = {
  defaultSelectedIssuers: [],
  austraclearLabelId: 'doYouHaveAustraclear',
};

export const RfqBankSelectionHasAustraclear = connect(RfqBankSelectionHasAustraclearComponent);
