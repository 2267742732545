import React from 'react';

import {
  Forbidden,
  InternalServerError,
  NotFound,
  Unauthorized,
} from './index';

export default {
  401: <Unauthorized />,
  403: <Forbidden />,
  404: <NotFound />,
  500: <InternalServerError />,
};
