import { createActionThunk } from 'redux-thunk-actions';

import { remove } from '../../api/capital-values';
import { actions } from './actions';

export const removeCapitalValueAction = createActionThunk(actions.removeCapitalValue, async (id) => {
  await remove(id);

  return id;
});

export const removeCapitalValueReducer = {
  [removeCapitalValueAction.SUCCEEDED]: (state, { payload: capitalBeingRemoved }) => {
    const nextState = { ...state, list: Array.from(state.list) };

    nextState.list.splice(nextState.list.findIndex(({ id }) => id === capitalBeingRemoved), 1);

    return nextState;
  },
  [removeCapitalValueAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [removeCapitalValueAction.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
    statusCode: null,
  }),
};
