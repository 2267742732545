import './select.scss';

import classNames from 'classnames';
import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';

export const Select = ({ defaultValue, className, allowCreate, ...props }) => {
  const ImperiumSelect = allowCreate ? ReactSelectCreatable : ReactSelect;

  return (
    <ImperiumSelect
      className={classNames('imperium-select-container', className)}
      classNamePrefix="my-react-select"
      defaultValue={getImperiumSelectValue({ options: props.options, value: defaultValue })}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
};

export const getImperiumSelectValue = ({ options, value }) => options?.find((option) => option.value === value);
