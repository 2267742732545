import React from 'react';

import { Col } from 'react-bootstrap';

import { buildDropdownFilters } from '../common';

export function elements({ filter, availableFilters, onDropdownFilterChange, intl }) {
  return buildDropdownFilters({ filter, availableFilters, onDropdownFilterChange, intl }).map((dropDownFilter, key) => (
    <Col key={key} xs={2} className="select-filter-container">
      {dropDownFilter}
    </Col>
  ));
}
