import Valida from 'valida';

export default async function asyncValidateBySchema (schema, values) {
  const cleanValues = sanitizeValues(values);
  const validationErrors = await validate(cleanValues, schema);

  if (validationErrors && !Object.keys(validationErrors).length) {
    return;
  }

  throw Object.keys(validationErrors).reduce((errors, property) => {
    const firstValidator = validationErrors[property][0];
    // eslint-disable-next-line no-param-reassign
    errors[property] = firstValidator.msg || firstValidator.validator;
    return errors;
  }, {});
}

function sanitizeValues (values) {
  const cleanValues = {};

  Object.keys(values).forEach(property => {
    cleanValues[property] = values[property] && (values[property].value || values[property]);
  });

  return cleanValues;
}

async function validate (data, schema) {
  const validationResult = await Valida.process(data, schema);

  return validationResult.invalidError().validationErrors;
}
