import { tradesSummaryFetchPayload } from '../actions/holding/holding';
import { UPDATE_PORTFOLIO_SELECTED_DATE } from '../actions/portfolio';

export const INITIAL_STATE = {
  hasInitiated: false,
  isFetching: false,
  didInvalidate: false,
  error: null,
  assets: null,
  filter: null,
};

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tradesSummaryFetchPayload.REQUEST:
      return {
        ...state,
        hasInitiated: true,
        isFetching: true,
        didInvalidate: true,
        error: null,
      };
    case tradesSummaryFetchPayload.SUCCESS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        assets: { ...action.summary },
        error: null,
      };
    case tradesSummaryFetchPayload.FAIL:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case UPDATE_PORTFOLIO_SELECTED_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
      };
    default:
      return state;
  }
}
