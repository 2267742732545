import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import classNames from 'classnames';
import { codes } from '../../../api/holding';
import { percentual } from '../../../format-numbers';
import { Loadable } from '../../common';
import { DateWithTimeZone } from '../../../date';

import './records-presenter.scss';

export const columns = {
  instrumentCode: { label: 'type' },
  principal: { label: 'principal', sort: 'principal' },
  paid: { label: 'holdingPaid', sort: 'interestPaidCode' },
  allocation: { label: 'holdingAllocation', sort: 'allocationCode' },
  settlementDate: { label: 'holdingSettlementDate', sort: 'settlementDate' },
  actions: { label: 'actions' },
};

export const presenter = (data, index, actions, options) => (
  <tr key={index}>
    <td className="td-1-space">{findLabelForInstrumentCode(data.instrumentCode)}</td>
    <td className="td-3-space">
      <FormattedNumber {...percentual} value={data.principal} />
    </td>
    <td className="td-2-space">
      <FormattedMessage id={`interestDescription.${data.interestPaidCode}`} />
    </td>
    <td className="allocation-code-column td-3-space">
      {data.allocationCode ? data.allocationCode.toUpperCase() : '-'}
    </td>
    {options.hasDefaultTPlusNDays && (
      <td className="td-1-space">{data.settlementDate ? <DateWithTimeZone value={data.settlementDate} /> : '-'}</td>
    )}
    <td className="td-2-space">{renderAction(actions, data)}</td>
  </tr>
);

const findLabelForInstrumentCode = (code) => {
  const filteredCode = codes.instrumentCodes.find((c) => c.code === code);
  if (filteredCode) {
    return filteredCode.label;
  }

  return code;
};

function renderActionContent(actions, data) {
  const isClosed = data.rfqStatus && data.rfqStatus === 'closed';
  const neutral = isClosed || data.quotesReceived === 0;
  const classes = classNames('btn-table btn-warning', { neutral });

  if (data.maturityAction === 'redeemed') {
    return <FormattedMessage id="rfqRedeemed" />;
  }

  if (data.rfqStatus || isClosed) {
    return (
      <Button onClick={() => performActionForData(actions, data)} className={classNames(classes, 'large')}>
        <FormattedMessage {...formattedActionsParameters(data)} />
      </Button>
    );
  }

  return (
    <div>
      <Button onClick={() => redeemAction(actions, data)} className={classNames(classes, 'redeem')}>
        <FormattedMessage id="redeem" />
      </Button>

      <Button onClick={() => performActionForData(actions, data)} className={classes}>
        <FormattedMessage {...formattedActionsParameters(data)} />
      </Button>
    </div>
  );
}

const renderAction = (actions, data) => (
  <Loadable isLoading={data.isLoading} size="sm">
    {renderActionContent(actions, data)}
  </Loadable>
);

function redeemAction(actions, data) {
  return actions.redeem(data);
}

function performActionForData(actions, data) {
  return data.rfqStatus ? actions.viewReceivedQuotes(data) : actions.reinvest(data);
}

function formattedActionsParameters(data) {
  const status = data.rfqStatus;

  if (!status) {
    return { id: 'reinvest' };
  }

  return status === 'closed'
    ? { id: 'rfqDealt' }
    : { id: 'rfqQuotesReceived', values: { quotes: data.quotesReceived } };
}
