import { startOfDay, decimalsDifference, daysInYear, newDate } from '../../date';
import ratingTypes from './rating-types';

export function ratingType (trade, asOf) {
  if (trade.instrumentCode === 'CASH') {
    return ratingTypes.short;
  }

  const maturityDate = startOfDay(trade.maturityDate);
  const startOfDayAsOf = asOf ? startOfDay(newDate(asOf)) : startOfDay();

  return decimalsDifference(maturityDate, startOfDayAsOf, 'days', false) >= daysInYear
    ? ratingTypes.long
    : ratingTypes.short;
}

export function isLongRating (trade, asOf) {
  return ratingType(trade, asOf) === ratingTypes.long;
}

export function isShortRating (trade) {
  return ratingType(trade) === ratingTypes.short;
}
