import React from 'react';
import Tooltip from './components/common/tooltip';

const getNameInitials = (name) => {
  const names = name.split(' ');
  const { 0: firstName, [names.length - 1]: lastName } = names;

  if (firstName === lastName) {
    return `${firstName[0]}`.toUpperCase();
  }

  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

export const renderInitialsTooltip = (name) =>
  name ? (
    <Tooltip id={name} tooltipComponent={name} delay={100} delayHide={1000}>
      <span className="name-initials">{getNameInitials(name)}</span>
    </Tooltip>
  ) : (
    '-'
  );
