import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import RatesheetsUpdate from '../../update-ratesheet/update-ratesheet';

export const UpdateRatesheetDialog = ({ show, onRatesheetUpdated, onHide }) => (
  <Modal show={show} className="update-ratesheet-dialog" onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>
        <FormattedMessage id="updateRatesheet" />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <RatesheetsUpdate onRatesheetUpdated={onRatesheetUpdated} />
    </Modal.Body>
  </Modal>
);

UpdateRatesheetDialog.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onRatesheetUpdated: PropTypes.func.isRequired,
};

UpdateRatesheetDialog.defaultProps = {
  show: false,
};
