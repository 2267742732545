import '../../components/common/form/form.scss';
import './forgot-and-reset.scss';

import React from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';

import { forgotPasswordApi } from '../../api/password/forgot-password';
import imperiumLogo from '../../assets/imperium-logo-light.svg';
import { ValidatedFormikField } from '../../components/common';
import { withNavigate } from '../../components/hoc/with-router-properties';
import { MessageType, showToastMessage } from '../toast/toast';

export const ForgotPassword = injectIntl((props) => {
  const format = (messageId) => props.intl.formatMessage({ id: messageId });

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(format('emailRequired')).email(format('invalidEmail')),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await forgotPasswordApi({ email: values.email, tenant: props.tenant });

      showToastMessage(format('emailSent'), MessageType.SUCCESS, {
        timeOut: 30000,
      });
      resetForm();
    } catch (error) {
      showToastMessage(format('anErrorHasOccurred'), MessageType.ERROR);
    }

    setSubmitting(false);
  };

  return (
    <div className="password-container">
      <img alt="Imperium Markets" src={imperiumLogo} />
      <div className="form-container">
        <h3>
          <FormattedMessage id="forgotPassword" />
        </h3>
        <h4 className="grey-color">
          <FormattedMessage id="forgotPasswordInstructions" />
        </h4>
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, values, touched, errors }) => (
            <Form>
              <fieldset disabled={isSubmitting}>
                <ValidatedFormikField
                  type="email"
                  id="email"
                  name="email"
                  labelId="email"
                  className="form-control"
                  data-testid="email"
                  value={values.email || ''}
                  touched={touched}
                  errors={errors}
                />
                <div className="actions-container">
                  <button type="submit" className="btn btn-default btn-solid-primary">
                    <FormattedMessage id="send" />
                  </button>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
      <button type="button" onClick={() => props.navigate('/')}>
        <FormattedMessage id="backToLoginPage" />
      </button>
    </div>
  );
});

ForgotPassword.propTypes = {
  tenant: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({ tenant: state.tenant });

export default compose(connect(mapStateToProps), withNavigate)(ForgotPassword);
