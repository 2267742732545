import React from 'react';

import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip as ReactTooltip } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

import { ImperiumIntlProvider } from '../../containers/intl-provider';

const Tooltip = ({
  children,
  id,
  tooltipValues,
  tooltipComponent,
  placement = 'top',
  delay = 100,
  delayHide = 500,
  intl,
  overlay,
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      overlay || (
        <ReactTooltip id={id}>
          <ImperiumIntlProvider>
            {tooltipComponent || <span>{intl.formatMessage({ id }, tooltipValues)}</span>}
          </ImperiumIntlProvider>
        </ReactTooltip>
      )
    }
    delay={delay}
    delayHide={delayHide}
  >
    {children}
  </OverlayTrigger>
);

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string,
  tooltipComponent: PropTypes.node,
  tooltipValues: PropTypes.shape(),
  placement: PropTypes.string,
  delay: PropTypes.number,
  delayHide: PropTypes.number,
  intl: PropTypes.shape(),
};

export default injectIntl(Tooltip);
