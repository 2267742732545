import { Component } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class InterceptorHandler extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.any,
  };

  componentDidMount() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error),
    );
  }

  render() {
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    error: state.error,
  };
}

export default connect(mapStateToProps)(InterceptorHandler);
