import buildForm from '../../common/form/form';
import { fields } from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

export default function buyFRNForm(onSave, currencySymbol) {
  return {
    title: 'addBuyFrnsOption',
    value: 'buyFrn',
    className: 'buy-frn-form',
    form: buildForm({
      fields: fields(currencySymbol),
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
