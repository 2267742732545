import './rfq-bank-selection.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { arrayOf, func, node, string } from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';

import { generateId } from '../../../../id-generator';

export const austraclear = 'AUSTRACLEAR';

export const wbc = 'WBC';

const defaultIssuerCodes = ['ANZ', 'CBA', 'NAB', wbc];

const defaultIssuerOptions = defaultIssuerCodes.map((issuerCode) => ({ label: issuerCode, value: issuerCode }));

export const RfqBankSelection = ({
  defaultSelectedIssuers,
  className,
  children,
  onSelectedIssuersChange,
  safeCustodyCode,
}) => {
  const [selectedIssuers, setSelectedIssuers] = useState(new Set(defaultSelectedIssuers));

  useEffect(() => {
    if (!defaultSelectedIssuers) {
      return;
    }

    setSelectedIssuers(new Set(defaultSelectedIssuers));
  }, [defaultSelectedIssuers]);

  return (
    <Column
      element="section"
      aria-label="rfq bond rfq bank selection"
      className={classNames('rfq-bank-selection', className)}
    >
      <Row justifyContentSpaceBetween>
        <FormattedMessage tagName="p" id="selectBank" />
        <FormattedMessage tagName="p" id="selectedBanks" values={{ length: selectedIssuers.size }} />
      </Row>
      <Row className="bank-options">
        {defaultIssuerOptions.map(({ value }) => {
          const id = `rfq-bank-selection-issuer-${value}-${generateId()}`;

          return (
            <Row key={value} justifyContentCenter alignItemsCenter>
              <input
                type="checkbox"
                name="issuerCodes"
                data-testid={id}
                value={value}
                onChange={({ target: { value: issuerCode, checked } }) => {
                  const nextSelectedIssuers = new Set(selectedIssuers);

                  if (checked) {
                    nextSelectedIssuers.add(issuerCode);
                  } else {
                    nextSelectedIssuers.delete(issuerCode);
                  }

                  setSelectedIssuers(nextSelectedIssuers);
                  onSelectedIssuersChange(Array.from(nextSelectedIssuers));
                }}
                id={id}
                disabled={safeCustodyCode !== austraclear && value === wbc}
                checked={selectedIssuers.has(value)}
              />
              <label htmlFor={id}>{value}</label>
            </Row>
          );
        })}
      </Row>
      {children}
    </Column>
  );
};

RfqBankSelection.propTypes = {
  className: string,
  safeCustodyCode: string,
  defaultSelectedIssuers: arrayOf(string),
  children: node,
  onSelectedIssuersChange: func,
};

RfqBankSelection.defaultProps = {
  defaultSelectedIssuers: [],
};
