import FileSaver from 'file-saver';

import { fetchOutgoingRfqsReport } from '../../../api/dashboard';
import * as rfqApiClient from '../../../api/holding/rfq';

export const FETCH_OUTGOING_RFQS_REQUEST = 'FETCH_OUTGOING_RFQS_REQUEST';
export const FETCH_OUTGOING_RFQS_SUCCESS = 'FETCH_OUTGOING_RFQS_SUCCESS';
export const FETCH_OUTGOING_RFQS_FAILURE = 'FETCH_OUTGOING_RFQS_FAILURE';

export const FETCH_OUTGOING_RFQS_CSV_REQUEST = 'FETCH_OUTGOING_RFQS_CSV_REQUEST';
export const FETCH_OUTGOING_RFQS_CSV_SUCCESS = 'FETCH_OUTGOING_RFQS_CSV_SUCCESS';
export const FETCH_OUTGOING_RFQS_CSV_FAILURE = 'FETCH_OUTGOING_RFQS_CSV_FAILURE';

export const FETCH_OUTGOING_QUOTES_REQUEST = 'FETCH_OUTGOING_QUOTES_REQUEST';
export const FETCH_OUTGOING_QUOTES_SUCCESS = 'FETCH_OUTGOING_QUOTES_SUCCESS';
export const FETCH_OUTGOING_QUOTES_FAILURE = 'FETCH_OUTGOING_QUOTES_FAILURE';

export const FILTER_RFQS_HISTORY = 'FILTER_RFQS_HISTORY';
export const CLEAR_RFQS_HISTORY_FILTER = 'CLEAR_RFQS_HISTORY_FILTER';

export function fetchRfqsHistory(params) {
  return async (dispatch) => {
    dispatch({ type: FETCH_OUTGOING_RFQS_REQUEST });
    try {
      const sortByCreatedAt = (rfqA, rfqB) => new Date(rfqB.createdAt) - new Date(rfqA.createdAt);
      const outgoingRfqs = (await fetchOutgoingRfqsReport(params)).list.sort(sortByCreatedAt);

      dispatch({ type: FETCH_OUTGOING_RFQS_SUCCESS, payload: outgoingRfqs });
    } catch (error) {
      dispatch({ type: FETCH_OUTGOING_RFQS_FAILURE, error: error.response || error });
    }
  };
}

export function fetchRfqsHistoryCsv(params) {
  return async (dispatch) => {
    dispatch({ type: FETCH_OUTGOING_RFQS_CSV_REQUEST });
    try {
      const csv = await fetchOutgoingRfqsReport({ ...params, csv: true });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, `outgoing_rfqs_${new Date().toISOString()}.csv`, true);
      dispatch({ type: FETCH_OUTGOING_RFQS_CSV_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_OUTGOING_RFQS_CSV_FAILURE, error: error.response || error });
    }
  };
}

export function fetchQuotes(uuid) {
  return async (dispatch) => {
    dispatch({ type: FETCH_OUTGOING_QUOTES_REQUEST });
    try {
      const quotes = await rfqApiClient.details({ uuid });

      dispatch({ type: FETCH_OUTGOING_QUOTES_SUCCESS, payload: { quotes } });
    } catch (error) {
      dispatch({ type: FETCH_OUTGOING_QUOTES_FAILURE, error: error.response || error });
    }
  };
}

export function filterRfqsHistory(filter) {
  return async (dispatch) => {
    if (Object.keys(filter).length) {
      dispatch({ type: FILTER_RFQS_HISTORY, filter });
    } else {
      dispatch({ type: CLEAR_RFQS_HISTORY_FILTER });
    }
  };
}
