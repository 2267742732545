import React from 'react';

import classNames from 'classnames';

import { ConfirmationPopover, Tooltip } from '../../../../../../components/common';
import { currencyDecimalPrecision, emptyCharacter, toNumberFormat } from '../../../../../../format-numbers';
import { Money } from '../../../../../money';
import { buildActionButtonPropertiesByQuote } from '../../../build-action-button-properties-by-quote';

export const buildAcceptQuotesColumns = ({ instrumentCode }) => ({
  party: { label: 'party' },
  cleanPrice: { label: 'cleanPrice' },
  grossPrice: { label: 'grossPrice' },
  isinYieldOrMargin: { label: 'isinYieldOrMargin', values: { instrumentCode } },
  actions: {},
});

export const acceptQuotePresenter = (
  { quoteEntityId, quoteStatus, issuerCode, cleanPrice, grossPrice, tradeMarginBasisPoints, isBestQuote },
  index,
  actions,
  { rfqStatus, confirmedAt, confirmedBy },
) => {
  const actionButtonProperties = buildActionButtonPropertiesByQuote({
    confirmedAt,
    confirmedBy,
    quoteStatus,
    quoteEntityId,
    actions,
    issuerCode,
    rfqStatus,
  });

  let actionColumn = <td />;

  if (quoteStatus && actionButtonProperties) {
    const { tooltipId, tooltipValues, ...buttonProperties } = actionButtonProperties;

    actionColumn = (
      <Tooltip id={tooltipId || buttonProperties.buttonLabelId} tooltipValues={tooltipValues}>
        <td className="action-column">
          <ConfirmationPopover
            className={classNames('btn-solid-primary', quoteStatus)}
            popoverClass="light"
            type="button"
            {...buttonProperties}
          />
        </td>
      </Tooltip>
    );
  }

  return (
    <tr key={index}>
      <td>{issuerCode}</td>
      <td>
        <Money emptyCharacter={emptyCharacter} value={cleanPrice} fraction={currencyDecimalPrecision} />
      </td>
      <td>
        <Money emptyCharacter={emptyCharacter} value={grossPrice} fraction={currencyDecimalPrecision} />
      </td>
      <td>
        <span className={classNames('quote-value', { 'best-quote': isBestQuote })} title={isBestQuote && 'Best quote'}>
          {toNumberFormat({ value: tradeMarginBasisPoints })}
        </span>
      </td>
      {actionColumn}
    </tr>
  );
};
