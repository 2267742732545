import axios from 'axios';

export default async function (uuid) {
  const url = `/dashboard/rfqs/${uuid}/quotes`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
