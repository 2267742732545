import React from 'react';
import PropTypes from 'prop-types';

import './pulse-blob.scss';

export const PulseBlob = ({ size }) => <div className={`blob blob-${size}`} />;

PulseBlob.propTypes = {
  size: PropTypes.string,
};

PulseBlob.defaultProps = {
  size: 'sm',
};
