import React, { Component } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ResultsPresenter } from '../../components/common';
import {
  unsettledColumns,
  unsettledPresenter,
} from '../../components/holding/records-presenter';
import { downloadFile } from '../../download-file';
import { listUnsettledTradesAction } from '../../ducks/unsettled/list';
import { track, unsettledOpened } from '../../event-tracker';
import { TradeNoteDialog } from '../holdings/TradeNoteDialog';
import { getMoneySymbol } from '../money';
import { CloseSellBondRfqDrawer } from '../rfqs/buy-sell-bond-rfqs/sell-bonds-rfqs/seller/close-sell-bond-rfq/CloseSellBondRfqDrawer';
import {
  MessageType,
  showResponseErrorMessage,
  showResponseErrorMessageWithUpload,
  showToastMessage,
} from '../toast/toast';

class Unsettled extends Component {
  componentDidMount() {
    track(unsettledOpened);

    this.props.listUnsettledTradesAction();
  }

  state = {
    showNoteAndRefDialog: false,
    selectedTrade: null,
    sellBondRfqEntityId: null,
  };

  downloadDocument = async ({ id, documentReferenceKey }) => {
    this.setState({ isDownloadingTradeId: id });

    try {
      const { data, headers } = await axios({
        headers: {
          Accept: 'application/octet-stream',
        },
        method: 'get',
        url: `/trades/${id}/documents/${documentReferenceKey}`,
      });

      const [, filename] = headers['content-disposition'].split('filename=');

      downloadFile({ filename, data });
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    } finally {
      this.setState({ isDownloadingTradeId: undefined });
    }
  };

  renderRecords = (unsettled, currency) => {
    const { list, isFetching } = unsettled;
    const moneySymbol = getMoneySymbol({ currency, short: true });

    const payload = {
      isFetching,
      noRecords: !list || !list.length,
      noRecordsMessageId: 'compliances.noUnsettledFound',
      recordsPresenter: {
        data: list,
        presenter: unsettledPresenter,
        columns: unsettledColumns(moneySymbol),
        actions: {
          showNoteAndReferenceDialog: (trade) => this.setState({ showNoteAndRefDialog: true, selectedTrade: trade }),
          viewSellBondRfq: (trade) => this.setState({ sellBondRfqEntityId: trade.sourceRfqUuid }),
          downloadDocument: this.downloadDocument,
        },
        options: { isDownloadingTradeId: this.state.isDownloadingTradeId },
      },
    };

    return <ResultsPresenter {...payload} />;
  };

  onConfirmDeletion = async ({ tradeId }) => {
    try {
      await axios.put(`/trades/${tradeId}`, {
        deleteDocument: true,
      });
    } catch (e) {
      showResponseErrorMessage({ intl: this.props.intl, error: e });
    }
  };

  updateNoteAndReference = async ({ tradeId, note, reference, bankReference, safeCustodyCode, ...rest }) => {
    try {
      await axios.put(`/trades/${tradeId}`, { note, reference, bankReference, safeCustodyCode, ...rest });
      this.setState({ showNoteAndRefDialog: false, selectedTrade: null });
      this.props.listUnsettledTradesAction();
      showToastMessage('Note and references saved', MessageType.SUCCESS);
    } catch (error) {
      showResponseErrorMessageWithUpload({ intl: this.props.intl, error, maxUploadSize: '10mb' });
    }
  };

  render() {
    const { unsettled, session } = this.props;
    const { showNoteAndRefDialog, selectedTrade, sellBondRfqEntityId } = this.state;

    return (
      <div className="holding-list">
        {this.renderRecords(unsettled, session.user.currency)}
        {selectedTrade && (
          <TradeNoteDialog
            show={showNoteAndRefDialog}
            trade={selectedTrade}
            onConfirm={({ tradeId, note, reference, bankReference, safeCustodyCode, document }) => {
              this.updateNoteAndReference({
                tradeId,
                note,
                reference,
                bankReference,
                safeCustodyCode,
                document,
              });
            }}
            onCancel={() => this.setState({ showNoteAndRefDialog: false, selectedTrade: null })}
            onConfirmDeletion={this.onConfirmDeletion}
          />
        )}
        <CloseSellBondRfqDrawer
          rfqUUID={sellBondRfqEntityId}
          onCloseDrawer={() => {
            this.setState({ sellBondRfqEntityId: null });
          }}
          currency={session.user.currency}
        />
      </div>
    );
  }
}

Unsettled.propTypes = {
  unsettled: PropTypes.shape().isRequired,
  listUnsettledTradesAction: PropTypes.func.isRequired,
  session: PropTypes.shape({
    user: PropTypes.shape({
      currency: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({ session: state.session, unsettled: state.unsettledTrades });

const mapDispatchToProps = { listUnsettledTradesAction };

export const UnsettledTrades = compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(Unsettled);
