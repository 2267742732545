import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Overlay, Popover as BootstrapPopover } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

export const Popover = injectIntl(
  ({
    animation,
    buttonLabelId,
    className,
    messageId,
    messageValues,
    disabled,
    placement,
    popoverClass,
    popoverContent,
    children,
    intl,
    container,
    ...props
  }) => {
    const [showPopover, setShowPopover] = useState(false);
    const [target, setTarget] = useState(null);

    const onPopoverHandlerClick = (e) => {
      setShowPopover(!showPopover);
      setTarget(e.target);
    };

    return (
      <React.Fragment>
        {children ? (
          children({
            onPopoverHandlerClick,
            disabled,
          })
        ) : (
          <Button
            className={classNames('btn-default', className)}
            disabled={disabled}
            onClick={onPopoverHandlerClick}
            {...props}
          >
            <FormattedMessage tagName="span" id={buttonLabelId} />
          </Button>
        )}
        <Overlay
          onHide={() => {
            setShowPopover(false);
          }}
          animation={animation}
          rootClose
          show={showPopover}
          trigger="focus"
          placement={placement}
          container={container || this}
          target={target}
          shouldUpdatePosition
        >
          <BootstrapPopover
            id={`confirmation-popup-${buttonLabelId}`}
            bsClass={classNames(popoverClass, 'popover')}
            title={messageId && intl.formatMessage({ id: messageId }, messageValues)}
          >
            <React.Fragment>{popoverContent}</React.Fragment>
          </BootstrapPopover>
        </Overlay>
      </React.Fragment>
    );
  },
);

Popover.propTypes = {
  animation: PropTypes.bool,
  buttonLabelId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.func,
  disabled: PropTypes.bool,
  messageId: PropTypes.string,
  messageValues: PropTypes.shape(),
  placement: PropTypes.string,
  popoverClass: PropTypes.string,
  popoverContent: PropTypes.node,
};

Popover.defaultProps = {
  animation: true,
  messageId: null,
  placement: 'top',
};
