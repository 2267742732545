import FileSaver from 'file-saver';
import axios from 'axios';

export const downloadCashBalance = async (tradeId) => {
  const csv = await fetchCsv(tradeId);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, `cash_balance_trade_${tradeId}_${new Date().toISOString()}.csv`, true);
};

const fetchCsv = async (tradeId) => {
  const accept = 'text/csv';

  const { data } = await axios({
    method: 'get',
    url: `/trades/cash/${tradeId}/balance`,
    headers: { accept },
  });

  return data;
};
