import React from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ConfirmationPopover } from '../../../components/common';
import { toNumberFormat } from '../../../format-numbers';

export const buildConfirmQuoteBuySellBondColumns = ({ instrumentCode }) => ({
  party: { label: 'party' },
  isinYieldOrMargin: { label: 'isinYieldOrMargin', values: { instrumentCode } },
  actions: {},
});

export const confirmQuoteBuySellBondPresenter = (
  { quoteStatus, issuerCode, yieldOrMargin, isOwnAdi },
  index,
  actions,
  options,
) => {
  const { canConfirmRfq, rfqStatus, operation } = options;
  const isCancelled = rfqStatus === 'cancelled';

  const actionButtonMessageId = ['expired', 'cancelled'].includes(rfqStatus) ? 'unavailable' : quoteStatus;

  return (
    <tr key={index}>
      <td>{issuerCode || `Bidder ${index + 1}`}</td>
      <td>{toNumberFormat({ value: yieldOrMargin })}</td>
      <td className="status-column">
        {canConfirmRfq && isOwnAdi ? (
          <ConfirmationPopover
            className={classNames('btn-solid-primary', quoteStatus)}
            popoverClass="light"
            type="button"
            onConfirm={actions.onConfirmRfqQuote}
            confirmationMessageId="buySellBondRfqConfirm"
            confirmationMessageValues={{ operation }}
            buttonLabelId="confirm"
          />
        ) : (
          <div className={classNames({ [quoteStatus]: !isCancelled && issuerCode && quoteStatus })}>
            <FormattedMessage id={actionButtonMessageId} />
          </div>
        )}
      </td>
    </tr>
  );
};
