import { createActionThunk } from 'redux-thunk-actions';

import { list } from '../../api/capital-values';
import { actions } from './actions';

export const listCapitalValueAction = createActionThunk(actions.getCapitalValues, (params) => list(params));

export const listCapitalValueReducer = {
  [listCapitalValueAction.STARTED]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [listCapitalValueAction.SUCCEEDED]: (state, action) => ({
    ...state,
    list: action.payload.list.sort((left, right) => new Date(right.asOfDate) - new Date(left.asOfDate)),
    isFetching: false,
  }),
  [listCapitalValueAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [listCapitalValueAction.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
    statusCode: null,
  }),
};
