import PropTypes from 'prop-types';

export const tradePropTypes = PropTypes.shape({
  status: PropTypes.string,
  principal: PropTypes.number.isRequired,
  offer: PropTypes.shape({
    status: PropTypes.string,
    bids: PropTypes.arrayOf(
      PropTypes.shape({
        accrued: PropTypes.number,
        additionalFunds: PropTypes.number,
        rate: PropTypes.number,
        maturityDate: PropTypes.string,
        status: PropTypes.string,
      }),
    ),
  }),
});
