import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { dispachResquestSuccess } from '../actions/error';
import { pushNotification } from '../actions/session';
import {
  Forbidden,
  InternalServerError,
  NotFound,
  Unauthorized,
} from '../components/error';
import { withLocation } from '../components/hoc/with-router-properties';

class ErrorHandler extends Component {
  componentDidUpdate({ error: nextError, location: nextLocation }) {
    const { dispatch, location } = this.props;

    if (location.pathname !== nextLocation.pathname) {
      dispatch(dispachResquestSuccess());
    }

    this.handleBadRequestError(nextError);
  }

  getError = (status) => {
    const errorsContent = {
      401: <Unauthorized />,
      403: <Forbidden />,
      404: <NotFound />,
    };

    return errorsContent[status] || <InternalServerError />;
  };

  handleBadRequestError = (nextError) => {
    const { dispatch, onError } = this.props;
    if (onError && nextError && nextError.status === 400) {
      const { data } = nextError;
      dispatch(pushNotification(`error-${data.error}`, 'danger'));
      onError();
    }
  };

  render() {
    const { children, error } = this.props;

    if (!error || [400, 500].includes(error.status)) {
      return children;
    }

    return this.getError(error.status);
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.shape(),
  onError: PropTypes.func,
};

export default compose(
  withLocation,
  connect(({ session }) => ({ session })),
)(ErrorHandler);
