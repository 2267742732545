import {
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_FAIL,
} from '../actions/session';


const initialState = {
  isFetching: false,
  didInvalidate: true,
  locale: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_SESSION_REQUEST: {
    return {
      ...state,
      isFetching: true,
      didInvalidate: false,
    };
  }
  case FETCH_SESSION_SUCCESS: {
    return {
      ...state,
      locale: action.locale,
      isFetching: false,
      didInvalidate: true,
    };
  }
  case FETCH_SESSION_FAIL: {
    return {
      ...state,
      isFetching: false,
      didInvalidate: true,
      error: action.error,
    };
  }
  default:
    return state;
  }
};
