import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row } from 'react-display-flex';
import { Button } from 'react-bootstrap';
import useSWR from 'swr';
import classNames from 'classnames';
import _ from 'lodash';

import { Column, ConfigurableTable, InputSearch, Tooltip } from '../../components/common';
import { renderSelect } from '../../components/common/dropdown-filter';
import { getImperiumSelectValue } from '../../components/common/Select/Select';
import { onboardingFetcher } from '../../api/onboarding/onboarding-fetcher';
import { getColumns, statusOptions } from './records-presenter';
import { CreateMultiProposedTenantDrawer } from './CreateMultiProposedTenantDrawer/CreateMultiProposedTenantDrawer';
import { CreateSingleProposedTenantDrawer } from './CreateSingleProposedTenantDrawer/CreateSingleProposedTenantDrawer';

import './onboarding.scss';

const statusFilterName = 'acceptance_status';
const initialFilters = { search: '', [statusFilterName]: '' };

const OnboardingComponent = ({ tenantDomain }) => {
  const [openMultiDrawer, setOpenMultiDrawer] = useState(false);
  const [openSingleDrawer, setOpenSingleDrawer] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const { data: totalizers, mutate: mutateTotalizers } = useSWR(
    [`proposed-tenants/${tenantDomain}/totalizers/`, filters],
    onboardingFetcher,
  );
  const { data: anzsciCodesResponse } = useSWR('/anzsci-codes/', onboardingFetcher);
  const { data: response, mutate } = useSWR([`/proposed-tenants/${tenantDomain}/`, filters], onboardingFetcher);
  const anzsciCodes = anzsciCodesResponse?.map(({ code, industry }) => ({
    value: code,
    label: `${code} - ${industry}`,
  }));

  const onCreateSuccess = () => {
    mutate();
    mutateTotalizers();
  };

  const searchTenants = _.debounce((value) => setFilters({ ...filters, search: value }), 1000);

  const payload = {
    isFetching: !response,
    noRecords: response && response.length === 0,
    tableSettingsId: 'onboarding',
    recordsPresenter: {
      data: response,
      columns: getColumns(),
      actions: {},
    },
    filter: {
      component: (
        <Row className="onboarding-container-filters" alignItemsEnd>
          {renderSelect({
            name: statusFilterName,
            label: 'status',
            className: 'form-group',
            onDropdownFilterChange: (filterName, value) => setFilters({ ...filters, [filterName]: value }),
            options: statusOptions,
            value: getImperiumSelectValue({ options: statusOptions, value: filters[statusFilterName] }),
          })}
          <InputSearch
            isLoading={false}
            defaultValue={filters.search}
            onChange={({ target: { value } }) => searchTenants(value)}
            onClear={() => setFilters({ ...filters, search: '' })}
          />
          <Tooltip id="cleanFilterTooltip">
            <Button className="reset-filter-button" onClick={() => setFilters(initialFilters)}>
              <FormattedMessage id="clear" />
            </Button>
          </Tooltip>
        </Row>
      ),
    },
  };

  return (
    <Column className="onboarding-container">
      <Row className="onboarding-container-header" justifyContentSpaceBetween>
        <Row className={classNames('totalizers', { 'is-loading': !totalizers })}>
          <Column>
            <label>
              <FormattedMessage id="inProgress" />
            </label>
            <label>{totalizers && totalizers.inProgress}</label>
          </Column>
          <Column>
            <label>
              <FormattedMessage id="approved" />
            </label>
            <label>{totalizers && totalizers.accepted}</label>
          </Column>
          <Column>
            <label>
              <FormattedMessage id="rejected" />
            </label>
            <label>{totalizers && totalizers.rejected}</label>
          </Column>
        </Row>
        <Row className="action-buttons">
          <Button onClick={() => setOpenSingleDrawer(true)}>
            <FormattedMessage id="addSingleCustomer" />
          </Button>
          <Button onClick={() => setOpenMultiDrawer(true)} className="btn-solid-primary">
            <FormattedMessage id="addMultipleCustomers" />
          </Button>
        </Row>
      </Row>
      <ConfigurableTable {...payload} />
      <CreateMultiProposedTenantDrawer
        tenantDomain={tenantDomain}
        open={openMultiDrawer}
        onToggle={() => setOpenMultiDrawer(!openMultiDrawer)}
        onSuccess={onCreateSuccess}
        anzsciCodes={anzsciCodes}
      />
      <CreateSingleProposedTenantDrawer
        tenantDomain={tenantDomain}
        open={openSingleDrawer}
        onToggle={() => setOpenSingleDrawer(!openSingleDrawer)}
        onSuccess={onCreateSuccess}
        anzsciCodes={anzsciCodes}
      />
    </Column>
  );
};

OnboardingComponent.propTypes = {
  tenantDomain: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  tenantDomain: state.tenant,
});

export const OnboardingPage = connect(mapStateToProps)(OnboardingComponent);
