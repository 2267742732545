export const defaultLimit = Number.MAX_SAFE_INTEGER;

export const calculateItems = (length, limit = defaultLimit) => Math.ceil(Number(length) / limit);

export const paginateItems = (items, pagination) => {
  const index = (pagination.activePage - 1) * pagination.limit;
  return items.slice(index, index + pagination.limit);
};

export const pagination = {
  activePage: 1,
  limit: defaultLimit,
  maxButtons: 5,
  items: 0,
  isFetching: false,
};

export default pagination;
