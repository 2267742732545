import './outgoing-rfqs-page.scss';

import React from 'react';

import { Col } from 'react-bootstrap';

import { RfqsTab } from '../home/Tabs/RfqsTab';

export const RfqsExecutionOnlyPage = () => (
  <Col className="rfqs-page-container">
    <RfqsTab routeBase="" />
  </Col>
);

RfqsExecutionOnlyPage.propTypes = {};
