import axios from 'axios';
import { isValidDate, formatToShortDate } from '../../date';

export default async function({ today, asOf, ...params } = {}) {
  const axiosDefaultAccept = axios.defaults.headers.common.Accept;

  const url = `/trades?${
    today ? 'today' : `query[asOf]=${asOf && isValidDate(asOf) ? asOf : formatToShortDate(new Date())}`
  }`;

  setAxiosAcceptHeader('text/csv');

  const text = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'text/csv',
    },
    params,
  });

  setAxiosAcceptHeader(axiosDefaultAccept);

  return text.data;
}

function setAxiosAcceptHeader(value) {
  Object.assign(axios.defaults.headers.common, { Accept: value });
}
