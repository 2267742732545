import './header.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row } from './Flex/Flex';

export const Header = ({ titleId, title, children }) => (
  <Row className="header-row" contentBetween alignItemsCenter>
    <h1 className="text-ellipsis">{titleId ? <FormattedMessage id={titleId} /> : { title }}</h1>
    <div>{children}</div>
  </Row>
);

Header.propTypes = {
  titleId: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};
