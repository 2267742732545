import axios from 'axios';

export async function updateOffer(offerId, { operation, payload }) {
  const { data } = await axios({
    method: 'put',
    url: `/offers/${offerId}/bids`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      operation,
      payload,
    },
  });

  return data;
}
