import axios from 'axios';

import userStore from '../user-store';
import { env } from '../config';

export const onboardingClientAxiosInstance = axios.create({
  baseURL: env.onboardingApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

onboardingClientAxiosInstance?.interceptors?.request?.use((config) => {
  const headers = { ...config.headers };
  const token = userStore.hasToken() && userStore.getToken();

  if (token) {
    headers.Authorization = token;
  }
  return { ...config, headers };
});
