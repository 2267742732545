import './button-toggle.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class ButtonToggle extends Component {
  static propTypes = {
    active: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
    onClick: PropTypes.func,
  };

  onClick(value) {
    this.props.onClick && this.props.onClick(value);
  }

  render() {
    const { items, active } = this.props;

    return (
      <div className="btn-group btn-toggle" data-toggle="buttons">
        {items.map(({ title, value }, index) => [
          <label
            key={value}
            onClick={() => this.onClick(value)}
            className={classnames('btn btn-default', {
              active: value === active,
            })}
            role="presentation"
          >
            <input type="radio" name="button-toggle" />
            <FormattedMessage id={title} />
          </label>,
          index === 0 ? (
            <label key="or-button" className="btn btn-default or">
              or
            </label>
          ) : null,
        ])}
      </div>
    );
  }
}
