import './quotes.scss';

import React, { Component } from 'react';

import classNames from 'classnames';
import { format } from 'd3-format';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as quotesActions from '../../actions/holding/rfq/quotes';
import * as rfqActions from '../../actions/holding/rfq/rfq';
import * as issuersActions from '../../actions/issuers';
import * as sessionActions from '../../actions/session';
import { hasLicences } from '../../actions/session-selector';
import { interests, tradeSources } from '../../api/holding/codes';
import { getInstrumentCode } from '../../api/holding/instrument-codes';
import rooms from '../../api/socket/rooms';
import capitalize from '../../capitalize';
import {
  Column,
  EmptyContent,
  Header,
  Loadable,
  PanelCollapsible,
  ResultsPresenter,
  Row,
  SkeletonTable,
  SummaryHighlight,
  SummaryItem,
  ValueHighlight,
} from '../../components/common';
import { licences } from '../../components/common/Protect/licences';
import errorHandler from '../../components/hoc/error-handler';
import includeSocket, { socketEvents } from '../../components/hoc/include-socket';
import withAppContext from '../../components/hoc/with-app-context';
import withConfirmDialog from '../../components/hoc/with-confirm-dialog';
import { withPreventNavigation } from '../../components/hoc/with-prevent-navigation';
import { withNavigate, withParams } from '../../components/hoc/with-router-properties';
import NeedToConfirm from '../../components/holding/quotes/need-to-confirm';
import * as receivedPresenter from '../../components/holding/quotes/records-presenter-received';
import * as waitingPresenter from '../../components/holding/quotes/records-presenter-waiting';
import { DateWithTimeZone, newDate, startOfDay } from '../../date';
import { nominateAction } from '../../ducks/rfq/quotes/nominate';
import { signOffAction } from '../../ducks/rfq/quotes/sign-off';
import { rfqUserActions } from '../../ducks/rfq/rfq-user-actions';
import { dateProps, dateTimeProps, timeProps } from '../../format-date';
import { number } from '../../format-numbers';
import { routes } from '../../routes';
import { AppContext } from '../app/AppContext';
import { addTradeFormByInstrumentCode } from '../holdings/AddTrade';
import { Money } from '../money';
import { MessageType, showToastMessage } from '../toast/toast';

const formatMoney = format(',.2f');

const mapStateToProps = (state) => ({
  quotes: state.quotes,
  issuers: state.issuers,
  tenant: state.tenant,
  currency: state.session.user.currency,
  user: state.session.user,
  quotesDuck: state.quotesDuck,
  hasPortfolioLicence: hasLicences(licences.portfolio)(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    quotes: bindActionCreators(quotesActions, dispatch),
    rfqs: bindActionCreators(rfqActions, dispatch),
    session: bindActionCreators(sessionActions, dispatch),
    issuers: bindActionCreators(issuersActions, dispatch),
    nominateQuote: bindActionCreators(nominateAction, dispatch),
    signOffQuote: bindActionCreators(signOffAction, dispatch),
  },
});

export class QuotesPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleIsSaved = this.handleIsSaved.bind(this);
  }

  componentDidMount() {
    const { actions, params } = this.props;

    actions.quotes.fetchRfqIfNeeded(params);

    this.confirmCancelDialog = {
      titleId: 'confirmCancelTitle',
      contentId: 'confirmCancelBody',
      confirmId: 'confirmCancelPositiveAnswer',
      cancelId: 'confirmCancelNegativeAnswer',
    };

    this.props.actions.issuers.fetchIssuers();

    this.props.on('server:rfq-updated', () => {
      showToastMessage(this.props.intl.formatMessage({ id: 'newQuotes' }), MessageType.INFO);
      actions.quotes.fetchRfqIfNeeded({ force: true });
    });
  }

  componentDidUpdate() {
    const { quotes, issuers, actions, quotesDuck, tenant: thisTenant, setPreventNavigation } = this.props;
    const { rfqs, isSaved, isFetching } = quotes;

    if (!isFetching && (quotesDuck.nominated || quotesDuck.signedOff)) {
      actions.quotes.fetchRfqIfNeeded({ force: true });

      const rfqIssuers = rfqs.find((rfq) => rfq.uuid === quotesDuck.uuid).issuers.map((issuer) => issuer.code);
      const tenants = issuers.data.filter((issuer) => rfqIssuers.includes(issuer.code)).map((issuer) => issuer.domain);
      tenants.push(thisTenant);

      actions.rfqs.updateSocketRfq(tenants);

      setPreventNavigation(false);
    }

    if (isSaved) {
      this.handleIsSaved();
    }

    if (quotes && quotes.error) {
      this.handleIsError(quotes.error);
    }
  }

  onQuoteSelectClick(uuid, quoteDetails, quoteByIssuer) {
    this.props.actions.quotes.selectQuote({
      quoteDetails,
      quoteByIssuer,
      uuid,
    });

    this.props.setPreventNavigation(true);
  }

  onAcceptClick(currentRfq) {
    const hasUserCreatedRfq = this.props.user.id === currentRfq.userId;
    const { uuid, userActionAllowed, nominatedMaturity, enforceSeparationOfDuties } = currentRfq;

    const selectedQuote =
      userActionAllowed === rfqUserActions.acceptNominated || userActionAllowed === rfqUserActions.signOffNominated
        ? nominatedMaturity
        : currentRfq.selectedQuote;

    const params = {
      uuid,
      hasUserCreatedRfq,
      selectedQuote,
      enforceSeparationOfDuties,
      userActionAllowed,
    };

    const onConfirm = this.onConfirmAcceptQuote.bind(this, params);

    this.props.onConfirmClick(onConfirm);
    this.props.toggleConfirmDialog({
      ...this.buildContentValues(params),
      ...this.getLeavingDialogContent(userActionAllowed),
    });
  }

  getLeavingDialogContent = (userActionAllowed) => {
    if (userActionAllowed === rfqUserActions.nominate) {
      return {
        contentId: 'rfqNominateConfirmation',
        titleId: 'rfqNominateTitle',
        confirmId: 'rfqNominateConfirm',
        cancelId: 'cancel',
      };
    }

    if (userActionAllowed === rfqUserActions.signOffNominated) {
      return {
        contentId: 'rfqSignOffConfirmation',
        titleId: 'rfqSignOffTitle',
        confirmId: 'rfqSignOffConfirm',
        cancelId: 'cancel',
      };
    }

    return { titleId: 'rfqAcceptConfirmTitle', contentId: 'rfqAcceptConfirmation' };
  };

  canUserAcceptQuote = (userActionAllowed) =>
    [rfqUserActions.accept, rfqUserActions.acceptNominated].includes(userActionAllowed);

  getAction = ({ userActionAllowed, userCanAcceptQuote }) => {
    const { actions } = this.props;

    if (userCanAcceptQuote) {
      return actions.quotes.accept;
    }

    return userActionAllowed === rfqUserActions.signOffNominated ? actions.signOffQuote : actions.nominateQuote;
  };

  async onConfirmAcceptQuote({ uuid, selectedQuote, userActionAllowed }) {
    const {
      quotes: { rfqs },
      issuers,
      tenant: thisTenant,
      hasPortfolioLicence,
      navigate,
      updateConfirmationProperties,
    } = this.props;

    const selectedRfq = rfqs.find((rfq) => rfq.uuid === uuid);
    const rfqIssuers = selectedRfq.issuers.map((issuer) => issuer.code);
    const tenants = issuers.data
      .filter((issuer) => rfqIssuers.includes(issuer.code))
      .map((issuer) => issuer.domain)
      .filter((domain) => domain);

    tenants.push(thisTenant);

    if (selectedRfq.issuerDomain && !tenants.includes(selectedRfq.issuerDomain)) {
      tenants.push(selectedRfq.issuerDomain);
    }

    const { id: quoteId, compliance } = selectedQuote;

    const userCanAcceptQuote = this.canUserAcceptQuote(userActionAllowed);
    const quoteAction = this.getAction({ userActionAllowed, userCanAcceptQuote });

    updateConfirmationProperties({ disabled: true, isConfirming: true });

    const hasComplianceBreach = compliance && selectedRfq.principal > compliance.amountAvailable;
    await quoteAction({ uuid, quoteId, tenants, hasComplianceBreach });

    if (userCanAcceptQuote) {
      this.props.emit(socketEvents.rfqUpdated, {
        tenants,
        room: rooms.rfq,
      });
      const pathname = hasPortfolioLicence ? routes.portfolioRoot.rfqs : routes.root;

      navigate(pathname);

      return;
    }

    this.props.toggleConfirmDialog({ disabled: false, isConfirming: false });
    this.setState({ selectedQuote });
  }

  onConfirm() {
    this.props.toggleConfirmDialog();
  }

  onCancelClick({ uuid, cancelOption, tradeId }) {
    const {
      actions,
      onConfirmClick,
      toggleConfirmDialog,
      issuers,
      tenant: thisTenant,
      quotes: { rfqs },
      hasPortfolioLicence,
    } = this.props;
    const { confirmCancelDialog } = this;

    const dealAwayContentBody = tradeId ? 'dealtAwayCancelBodyWithReinvestment' : 'dealtAwayCancelBody';

    const cancelDialogFirstParagraph = cancelOption === 'nothing-done' ? 'nothingDoneCancelBody' : dealAwayContentBody;

    const currentRfq = rfqs.find((rfq) => rfq.uuid === uuid);

    const onCancelHandler = async () => {
      const rfqIssuers = currentRfq.issuers.map((issuer) => issuer.code);
      const tenants = issuers.data.filter((issuer) => rfqIssuers.includes(issuer.code)).map((issuer) => issuer.domain);
      tenants.push(thisTenant);

      this.props.updateConfirmationProperties({ isConfirming: true });

      await actions.rfqs.cancel(uuid, cancelOption);

      actions.rfqs.updateSocketRfq(tenants);

      toggleConfirmDialog({
        ...confirmCancelDialog,
        isConfirming: false,
      });
    };

    const onAddNewTradeDealtOffClick = async () => {
      await onCancelHandler();

      const currentRfqInterestPaid = interests.find(({ code }) => code === currentRfq.interestPaidCode);
      const interestPaidCode = {
        value: currentRfqInterestPaid.code,
        label: this.props.intl.formatMessage({ id: currentRfqInterestPaid.label }),
      };

      const allocationCode = { value: currentRfq.allocationCode, label: currentRfq.allocationCode };

      this.props.appContext.setIsAddTradeOpen(true);
      this.props.appContext.setInitialAddTradeValues({
        currentForm: addTradeFormByInstrumentCode[getInstrumentCode(currentRfq.instrumentCode)],
        principal: currentRfq.principal.toString(),
        interestPaidCode,
        allocationCode,
        purchaseDate: startOfDay(currentRfq.settlementDate).toDate(),
        source: tradeSources.addTradeDealtAway,
      });
    };

    const shouldOpenAddTrade = cancelOption === 'dealt-away' && hasPortfolioLicence;

    const confirmCancelContent = (
      <NeedToConfirm
        firstParagraph={cancelDialogFirstParagraph}
        values={{
          extraContent:
            shouldOpenAddTrade &&
            '- The “Add Trade” form will pop up to allow you to enter the details of the new transaction',
        }}
      />
    );

    onConfirmClick(shouldOpenAddTrade ? onAddNewTradeDealtOffClick : onCancelHandler);

    toggleConfirmDialog({
      ...confirmCancelDialog,
      content: confirmCancelContent,
    });
  }

  getStatus = (rfq, isRfqClosed, isRfqCancelled) => {
    if (isRfqCancelled) {
      let cancelStatus = 'cancelled';

      if (rfq.cancelOption && rfq.cancelOption.length) {
        cancelStatus = rfq.cancelOption === 'nothing-done' ? 'nothingDoneRfq' : 'dealtAwayRfq';
      }

      return <FormattedMessage id={cancelStatus} />;
    }

    if (isRfqClosed) {
      return <FormattedMessage id="closed" />;
    }

    if (rfq.enforceSeparationOfDuties && Object.keys(rfq.nominatedMaturity).length) {
      return <FormattedMessage id="awaitingApproval" />;
    }

    return <FormattedMessage id="open" />;
  };

  onSaveSuccess = () => {
    const { actions, hasPortfolioLicence, navigate } = this.props;
    const { selectedQuote } = this.state;

    actions.quotes.reset();

    if (selectedQuote) {
      const pathname = hasPortfolioLicence ? routes.portfolioRoot.rfqs : routes.root;

      navigate(pathname, {
        search: `?issuerCode=${selectedQuote.issuerCode}`,
      });
    }
  };

  handleIsSaved() {
    const { setPreventNavigation } = this.props;

    setPreventNavigation(false);

    this.onSaveSuccess();
  }

  handleIsError(error) {
    const { actions } = this.props;

    actions.session.pushNotification(`rfqError-${error.data.error}`, 'danger');
    actions.quotes.reset();
  }

  buildContentValues({ uuid: selectedUuid, selectedQuote }) {
    const { quotes, currency, intl } = this.props;

    const rfq = quotes.rfqs.find(({ uuid }) => uuid === selectedUuid);
    const interestPaid = intl.formatMessage({ id: `interestDescription.${rfq.interestPaidCode}` });
    const maturityDate = intl.formatDate(newDate(selectedQuote.date), dateProps);
    const settlementDate = intl.formatDate(newDate(rfq.settlementDate), dateProps);
    const principal = intl.formatNumber(rfq.principal, number);
    const consideration = this.getConsiderationText(selectedQuote.consideration);
    const coupon = intl.formatNumber(selectedQuote.coupon, { minimumFractionDigits: 2, maximumFractionDigits: 4 });
    const adi = selectedQuote.adi || rfq.nominatedMaturity.issuerName;
    const titleSuffix = selectedQuote.compliance.hasChanceOfBreach ? ' - Compliance Breach' : '';
    const complianceBreachMessage = selectedQuote.compliance.hasChanceOfBreach
      ? `Warning: If you accept the quote by this ADI, you might be breaching compliance
      Compliance: ${selectedQuote.compliance.ratingName}
      Available: ${currency} ${formatMoney(selectedQuote.compliance.amountAvailable)}.`
      : '';

    return {
      titleValues: {
        titleSuffix,
      },
      contentValues: {
        adi,
        coupon,
        interestPaid,
        maturityDate,
        currency,
        principal,
        consideration,
        settlementDate,
        complianceBreachMessage,
      },
    };
  }

  getConsiderationText(consideration) {
    const { intl } = this.props;

    return consideration
      ? `
    - Consideration: ${intl.formatNumber(consideration, number)}`
      : '';
  }

  hasRfqs = ({ rfqs }) => rfqs && rfqs.length > 0;

  isRfqClosed = ({ startDate, endDate, rfqStatus }) => {
    const isOpenByDate = newDate().isBetween(newDate(startDate), newDate(endDate));

    return !isOpenByDate || rfqStatus === 'closed';
  };

  isRfqCancelled = ({ rfqStatus }) => rfqStatus === 'cancelled';

  areThereReceivedQuotes = ({ receivedQuotes }) => receivedQuotes && receivedQuotes.length > 0;

  buildQuotesPresenterPayloads(rfq, isRfqClosed, isRfqCancelled) {
    const payloads = {};
    const {
      receivedQuotes,
      isAccepting,
      isFetchingQuotes,
      waitingQuotes,
      uuid,
      userActionAllowed,
      nominatedMaturity,
      enforceSeparationOfDuties,
    } = rfq;
    const onQuoteSelectClick = this.onQuoteSelectClick.bind(this, uuid);
    const isFetching = !!(isFetchingQuotes || isAccepting);

    if (this.areThereReceivedQuotes(rfq)) {
      payloads.received = {
        isFetching,
        noRecords: !receivedQuotes || !receivedQuotes.length,
        recordsPresenter: {
          data: receivedQuotes.map((quote) => ({
            ...quote,
            isRfqClosed,
            isRfqCancelled,
            userActionAllowed,
          })),
          ...receivedPresenter,
          actions: {
            onQuoteSelectClick,
            getQuoteSelected: () => rfq.selectedQuote,
          },
          options: { nominatedMaturity, enforceSeparationOfDuties },
        },
        titleId: 'quotesReceived',
      };
    }

    if (waitingQuotes && waitingQuotes.length) {
      payloads.waiting = {
        isFetching,
        noRecords: !waitingQuotes || !waitingQuotes.length,
        recordsPresenter: {
          data: waitingQuotes,
          ...waitingPresenter,
        },
        titleId: isRfqClosed || isRfqCancelled ? 'notReceived' : 'rfqWaiting',
      };
    }

    return payloads;
  }

  buildSummary = (quotes) => {
    const initialSummary = {
      open: 0,
      closed: 0,
    };

    return quotes.rfqs
      ? quotes.rfqs.reduce((summary, rfq) => {
          // eslint-disable-next-line no-param-reassign
          summary[rfq.rfqStatus] += rfq.principal;

          return summary;
        }, initialSummary)
      : initialSummary;
  };

  renderTotalizers = (quotes) => {
    const summary = this.buildSummary(quotes);

    return (
      <div className="summary">
        <SummaryItem top={<FormattedMessage id="currency" />} middle={<Money long />} />
        <SummaryItem
          top={<FormattedMessage id="open" />}
          middle={<Money value={summary.open} short />}
          isFetching={quotes.isFetching}
        />
        <SummaryItem
          top={<FormattedMessage id="closed" />}
          middle={<Money value={summary.closed} short />}
          isFetching={quotes.isFetching}
        />
      </div>
    );
  };

  renderRfqs(quotes) {
    const { params } = this.props;
    const { rfqs, isFetching } = quotes;

    const fetchingRfqs = !rfqs && isFetching;

    return (
      <SkeletonTable isLoading={fetchingRfqs}>
        {rfqs && rfqs.length ? (
          rfqs.map((rfq) => {
            const isRfqClosed = this.isRfqClosed(rfq);
            const isRfqCancelled = this.isRfqCancelled(rfq);
            const header = this.renderPanelHeader(rfq, isRfqClosed, isRfqCancelled);
            const footer = this.renderPanelFooter(rfq, isRfqClosed, isRfqCancelled);
            const expanded = rfq.uuid === params.uuid;

            return (
              <Row key={rfq.uuid} className="quotes-rfqs-list">
                <PanelCollapsible
                  className={classNames({
                    'with-border green': !isRfqClosed && !isRfqCancelled,
                    'with-border red': isRfqCancelled,
                  })}
                  expanded={expanded}
                  header={header}
                  footer={footer}
                  isFetchingContent={rfq.isFetchingQuotes}
                  id={rfq.uuid}
                >
                  {this.renderQuotes(rfq, isRfqClosed, isRfqCancelled)}
                </PanelCollapsible>
              </Row>
            );
          })
        ) : (
          <EmptyContent messageId="noRecords" />
        )}
      </SkeletonTable>
    );
  }

  renderPanelHeader(rfq, isRfqClosed, isRfqCancelled) {
    return (
      <div className="panel-header">
        <SummaryHighlight
          top={<FormattedMessage id="status" />}
          middle={<div className="text-ellipsis">{this.getStatus(rfq, isRfqClosed, isRfqCancelled)}</div>}
        />
        <SummaryHighlight
          top={<FormattedMessage id="principal" />}
          middle={
            <div className="text-ellipsis">
              <Money value={rfq.principal} />
            </div>
          }
        />
        <SummaryHighlight
          top={<FormattedMessage id="createdAt" />}
          middle={<DateWithTimeZone value={rfq.requestedAt} {...timeProps} />}
        />
        <SummaryHighlight
          top={<FormattedMessage id="settlementDate" />}
          middle={<DateWithTimeZone value={rfq.settlementDate} />}
        />
        <SummaryHighlight
          top={<FormattedMessage id="holdingInstrumentCode" />}
          middle={<span>{rfq.instrumentCode}</span>}
        />
        <SummaryHighlight
          top={<FormattedMessage id="interestPaid" />}
          middle={
            <div className="text-ellipsis">
              <FormattedMessage id={`interestDescription.${rfq.interestPaidCode}`} />
            </div>
          }
        />
        <SummaryHighlight
          top={<FormattedMessage id="allocationCode" />}
          middle={<div className="text-ellipsis">{capitalize(rfq.allocationCode)}</div>}
        />
      </div>
    );
  }

  renderPanelFooter(rfq, isRfqClosed, isRfqCancelled) {
    const actionsFooter = this.renderActionFooter(rfq, isRfqClosed, isRfqCancelled);

    return (
      <Row className="footer-row" contentBetween>
        <Row alignItemsCenter>
          <ValueHighlight label="start">
            <DateWithTimeZone value={rfq.startDate} {...dateTimeProps} />
          </ValueHighlight>
          <ValueHighlight label="end">
            <DateWithTimeZone value={rfq.endDate} {...dateTimeProps} />
          </ValueHighlight>
        </Row>
        <Row alignItemsCenter>{actionsFooter}</Row>
      </Row>
    );
  }

  renderActionFooter(rfq, isRfqClosed, isRfqCancelled) {
    const {
      uuid,
      isAccepting,
      isFetching,
      selectedQuote,
      rfqStatus,
      enforceSeparationOfDuties,
      nominatedMaturity,
      userActionAllowed,
    } = rfq;

    const onCancelClick = this.onCancelClick.bind(this);
    const showCancel = rfqStatus === 'open';
    const showRfqAction = !['closed', 'cancelled'].includes(rfqStatus) && this.areThereReceivedQuotes(rfq);
    const isSelectedQuoteNominated = selectedQuote && nominatedMaturity && nominatedMaturity.id === selectedQuote.id;
    const isNominateAndHasBeenSignedOff =
      nominatedMaturity && nominatedMaturity.nominatedBy && nominatedMaturity.signedOffBy;

    if (isRfqClosed || isRfqCancelled) {
      return null;
    }

    const getButtonMessage = () => {
      if (isRfqClosed) return 'rfqClosed';

      if (isRfqCancelled) return 'rfqCancelled';

      if (!enforceSeparationOfDuties || userActionAllowed === rfqUserActions.none) return 'acceptSelectedQuote';

      if (userActionAllowed === rfqUserActions.acceptNominated) {
        return 'acceptNominatedQuote';
      }

      if (userActionAllowed === rfqUserActions.signOffNominated) {
        return 'signOffNominatedQuote';
      }

      return 'nominateSelectedQuote';
    };

    const getAcceptButtonDisabled = () => {
      if (isRfqCancelled || isRfqClosed || userActionAllowed === rfqUserActions.none) return true;

      if (userActionAllowed === rfqUserActions.accept) {
        return !selectedQuote;
      }

      if (userActionAllowed === rfqUserActions.nominate) {
        return !selectedQuote || isSelectedQuoteNominated || isNominateAndHasBeenSignedOff;
      }

      return false;
    };

    const isLoading = isFetching || isAccepting;

    return (
      <div className="action-footer-row">
        {showCancel && (
          <button
            type="button"
            className="cancel-button btn-accent btn btn-default"
            disabled={isLoading}
            onClick={() => onCancelClick({ uuid, cancelOption: 'nothing-done', tradeId: rfq.tradeId })}
          >
            <Loadable isLoading={isLoading} size="sm">
              <FormattedMessage id="nothingDoneRfq" />
            </Loadable>
          </button>
        )}
        {showCancel && (
          <button
            type="button"
            className="cancel-button btn-accent btn btn-default"
            disabled={isLoading}
            onClick={() => onCancelClick({ uuid, cancelOption: 'dealt-away', tradeId: rfq.tradeId })}
          >
            <Loadable isLoading={isLoading} size="sm">
              <FormattedMessage id="dealtAwayRfq" />
            </Loadable>
          </button>
        )}
        {showRfqAction && (
          <button
            type="button"
            className="btn-solid-primary btn btn-default"
            disabled={isLoading || getAcceptButtonDisabled()}
            onClick={() => this.onAcceptClick(rfq)}
          >
            <Loadable isLoading={isLoading} size="sm">
              <FormattedMessage id={getButtonMessage()} />
            </Loadable>
          </button>
        )}
      </div>
    );
  }

  renderQuotes(rfq, isRfqClosed, isRfqCancelled) {
    const { received, waiting } = this.buildQuotesPresenterPayloads(rfq, isRfqClosed, isRfqCancelled);

    return (
      <Column key={rfq.uuid}>
        {received && <ResultsPresenter {...received} />}
        {waiting && <ResultsPresenter {...waiting} />}
      </Column>
    );
  }

  render() {
    const { quotes } = this.props;

    return (
      <div className="quotes">
        <Header titleId="rfqQuotes" />
        {this.renderTotalizers(quotes)}
        {this.renderRfqs(quotes)}
      </div>
    );
  }
}

QuotesPageComponent.propTypes = {
  appContext: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  issuers: PropTypes.shape().isRequired,
  onConfirmClick: PropTypes.func,
  updateConfirmationProperties: PropTypes.func,
  initializeAddTrade: PropTypes.func,
  params: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
  hasPortfolioLicence: PropTypes.bool,
  on: PropTypes.func,
  setPreventNavigation: PropTypes.func,
  tenant: PropTypes.string.isRequired,
  toggleConfirmDialog: PropTypes.func,
  currency: PropTypes.string.isRequired,
  user: PropTypes.shape(),
  quotesDuck: PropTypes.shape(),
  emit: PropTypes.func,
};

QuotesPageComponent.contextType = AppContext;

export const QuotesPage = compose(
  withPreventNavigation(),
  withConfirmDialog(),
  includeSocket({ rooms: [rooms.rfq] }),
  withAppContext(),
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.quotes.error,
  })),
  withNavigate,
  withParams,
  injectIntl,
)(QuotesPageComponent);

export default QuotesPage;
