import { list as listTrades, summary as getSummary } from '../../api/holding';
import { group as listGroups, rule as listRulesByGroupId } from '../../api/compliances';
import { formatToShortDate, formatDate, today } from '../../date';
import { list as listIssuers } from '../../api/issuers';
import calculate from './calculator';
import calculateWeightedAverage from './weighted-average-calculator';

export const FETCH_SANDBOX_REQUEST = 'FETCH_SANDBOX_REQUEST';
export const FETCH_SANDBOX_SUCCESS = 'FETCH_SANDBOX_SUCCESS';
export const FETCH_SANDBOX_FAILURE = 'FETCH_SANDBOX_FAILURE';
export const UPDATE_SANDBOX_SUMMARY = 'UPDATE_SANDBOX_SUMMARY';

export function fetchSandbox() {
  return async (dispatch) => {
    dispatch({ type: FETCH_SANDBOX_REQUEST });
    try {
      const sandboxData = await fetchSandboxData();

      dispatch({ type: FETCH_SANDBOX_SUCCESS, payload: sandboxData });
      dispatch({ type: UPDATE_SANDBOX_SUMMARY, summary: sandboxData.summary });
    } catch (error) {
      dispatch({ type: FETCH_SANDBOX_FAILURE, error: error.response || error });
    }
  };
}

async function fetchSandboxData() {
  const tradeSummary = await getSummary();
  const longTermComplianceOnly = tradeSummary && tradeSummary.longTermComplianceOnly;
  const asOf = formatToShortDate(today());
  const counterpartyRules = await getComplianceRules('issuers');
  const creditRules = await getComplianceRules('ratings');
  const maturityRules = await getComplianceRules('maturities');
  const issuers = await listIssuers(asOf);
  const trades = reduceTrades(
    (await listTrades({ query: { asOf }, sort: 'maturityDate' })).trades,
    issuers.list,
    longTermComplianceOnly,
  );

  const payload = {
    trades,
    rules: {
      counterparty: counterpartyRules && counterpartyRules.list,
      creditRating: creditRules && creditRules.list,
      maturity: maturityRules && maturityRules.list,
    },
    longTermComplianceOnly,
    issuers: issuers && issuers.list,
  };

  const result = await calculate(payload);
  const weightedAverage = calculateWeightedAverage(trades);

  const summary = {
    actualWeightedAverageCoupon: weightedAverage.coupon,
    weightedAverageCoupon: weightedAverage.coupon,
    actualWeightedAverageDuration: weightedAverage.duration,
    weightedAverageDuration: weightedAverage.duration,
    actualPrincipalTotal: weightedAverage.principalTotal,
    principalTotal: weightedAverage.principalTotal,
  };

  return {
    ...payload,
    trades: result.trades,
    calculationSummary: result.summary,
    summary,
    longTermComplianceOnly,
  };
}

async function getComplianceRules(complianceType) {
  const complianceGroups = await listGroups({ complianceType });
  const groupId = complianceGroups.list.reverse().pop().id;
  const complianceRules = await listRulesByGroupId({ complianceType, groupId });

  return complianceRules;
}

function reduceTrades(trades, issuers, longTermComplianceOnly) {
  const shortDateFormat = 'DD/MM/YYYY';

  return trades.map((trade) => {
    const maturityDate = ['CASH', 'MGFUND'].includes(trade.instrumentCode)
      ? formatDate(today(), shortDateFormat)
      : formatDate(trade.maturityDate, shortDateFormat);

    return {
      id: trade.id,
      available: true,
      instrumentCode: trade.instrumentCode,
      principal: trade.principal,
      purchaseDate: formatDate(trade.purchaseDate, shortDateFormat),
      maturityDate,
      coupon: trade.coupon,
      issuerName: trade.issuer.name,
      issuerCode: trade.issuer.code,
      issuerId: trade.issuerId,
      bankingGroupId: trade.bankingGroupId,
      bankingGroupName: issuers.find((issuer) => issuer.bankingGroupId === trade.bankingGroupId).bankingGroupName,
      longTermComplianceOnly,
    };
  });
}

export function updateSummary(trades) {
  return async (dispatch) => {
    const weightedAverage = calculateWeightedAverage(trades);

    const summary = {
      weightedAverageCoupon: weightedAverage.coupon,
      weightedAverageDuration: weightedAverage.duration,
      principalTotal: weightedAverage.principalTotal,
    };

    dispatch({ type: UPDATE_SANDBOX_SUMMARY, summary });
  };
}
