import './index.scss';

import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as sessionActions from '../../actions/session';
import * as chartsPresenter from '../../components/allocation/chart-payload';
import * as recordsPresenter from '../../components/allocation/records-presenter';
import { ChartsPresenter, ResultsPresenter } from '../../components/common';
import errorHandler from '../../components/hoc/error-handler';

function mapStateToProps(state) {
  return {
    allocation: state.allocation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      session: bindActionCreators(sessionActions, dispatch),
    },
  };
}

export class Allocation extends Component {
  componentDidMount() {
    this.updateLocationQueryOnMount();
  }

  componentDidUpdate(nextProps) {
    this.updateLocationQuery(nextProps);
  }

  updateLocationQueryOnMount() {
    const { allocation } = this.props;

    if (!allocation.filter || !Object.keys(allocation.filter).length) {
      return;
    }

    this.props.actions.session.updateLocationQuery(allocation.filter);
  }

  updateLocationQuery(nextProps) {
    if (!this.isNextFilterDifferent(this.props, nextProps)) {
      return;
    }

    this.props.actions.session.updateLocationQuery(nextProps.allocation.filter);
  }

  isNextFilterDifferent = ({ allocation: { filter } }, { allocation: { filter: nextPropsFilter } }) =>
    nextPropsFilter && !isEqual(filter, nextPropsFilter);

  renderCharts = ({ allocation, isFetching }) => {
    const payload = {
      charts: [
        {
          id: 'allocation-chart',
          titleId: 'allocationChartTitle',
          isFetching,
          data: allocation && allocation.allocationCodes.slice().reverse(),
          type: 'horizontal-bar',
          ...chartsPresenter,
        },
      ],
    };

    return <ChartsPresenter {...payload} />;
  };

  renderRecords = ({ allocation, isFetching }) => {
    const payload = {
      isFetching,
      noRecords: !allocation || !allocation.allocationCodes.length,
      recordsPresenter: {
        data: allocation && allocation.allocationCodes,
        ...recordsPresenter,
      },
      titleId: 'allocation.resultsTitle',
    };

    return <ResultsPresenter {...payload} />;
  };

  render() {
    const { allocation } = this.props;

    return (
      <Row className="allocation-list">
        <Col xs={6}>{this.renderCharts(allocation)}</Col>
        <Col xs={6}>{this.renderRecords(allocation)}</Col>
      </Row>
    );
  }
}

Allocation.propTypes = {
  actions: PropTypes.shape().isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.allocation.error,
  })),
)(Allocation);
