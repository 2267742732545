import {
  UPDATE_PORTFOLIO_SELECTED_DATE,
  UPDATE_REFRESH_PORTFOLIO,
  TOGGLE_ADD_TRADE_SIDEBAR,
} from '../actions/portfolio';

import reducer, { onNextState } from './reducer';

export const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: true,
  filter: {},
  error: null,
  refresh: false,
  silentFetch: false,
  addTradeOpen: false,
};

function onUpdateSelectedDate(state, action) {
  return onNextState(state, {
    filter: {
      ...state.filter,
      date: action.date,
    },
    error: null,
  });
}

function onRefresh(state, { refresh, silentFetch }) {
  return onNextState(state, {
    refresh,
    silentFetch: !!silentFetch,
  });
}

const onToggleAddTrade = (state) =>
  onNextState(state, {
    addTradeOpen: !state.addTradeOpen,
  });

const actions = {
  [UPDATE_PORTFOLIO_SELECTED_DATE]: onUpdateSelectedDate,
  [UPDATE_REFRESH_PORTFOLIO]: onRefresh,
  [TOGGLE_ADD_TRADE_SIDEBAR]: onToggleAddTrade,
};

export default reducer(actions, INITIAL_STATE);
