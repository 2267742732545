import './sell-bond-rfq-status.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '../../../../components/common';
import { getBuyerBuySellBondRfqStatus } from './get-sell-bond-rfq-status';

export const BuyerBuySellBondRfqStatus = ({ rfq, verbose }) => {
  const status = getBuyerBuySellBondRfqStatus(rfq);

  const className = classNames('sell-bond-rfq-status', status, { verbose });

  return (
    <Tooltip id={status}>
      {verbose ? (
        <span className={className} aria-label="rfq status">
          <FormattedMessage id={status} />
        </span>
      ) : (
        <span aria-label="rfq status" className={className} />
      )}
    </Tooltip>
  );
};

BuyerBuySellBondRfqStatus.propTypes = {
  rfq: PropTypes.shape().isRequired,
  verbose: PropTypes.bool,
};
