import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { Row, Tooltip } from '../../../../components/common';
import { getPeriodIdAndValues, renderCustomMaturityDateTooltip } from '../../../../components/holding/quotes';
import { DateWithTimeZone, isSameDay, momentWithTimezone, weekdays } from '../../../../date';
import { SendQuoteCouponInput } from './SendQuoteCouponInput';

export const buildSendQuoteColumns = ({
  rfq,
  daysToShowMaturityDateWarning,
  timezone,
  autoFillRfqRatesheet,
  onQuoteChange,
  isRfqClosed,
  isRfqQuoted,
  isQuoteEmpty,
  enableQuoteEditing,
  quoteStatus,
  setMaturityCode,
}) => [
  {
    title: 'Tenor',
    dataIndex: 'code',
    key: 'code',
    render: (code) => {
      const periodLabel = getPeriodIdAndValues(code);

      return (
        <>
          {periodLabel ? (
            <FormattedMessage id={periodLabel.id} values={periodLabel.values} />
          ) : (
            <FormattedMessage id="inDate" />
          )}
        </>
      );
    },
  },
  {
    title: 'Maturity Date',
    dataIndex: 'date',
    key: 'date',
    render: (date, quote) => (
      <Row alignItemsCenter className="maturity-date">
        <DateWithTimeZone timezone={timezone} value={date} />
        <span>({weekdays[momentWithTimezone(date, timezone).weekday()]})</span>
        {quote.originalDate && !isSameDay(date, quote.originalDate) && (
          <Tooltip id={date} tooltipComponent={renderCustomMaturityDateTooltip(quote)}>
            <FaExclamationTriangle aria-label="warning custom maturity date" />
          </Tooltip>
        )}
      </Row>
    ),
  },
  {
    title: 'Days',
    dataIndex: 'difference',
    key: 'difference',
    render: (difference, { code, warnOriginalDateDifference }) => (
      <Row alignItemsCenter className="maturity-days">
        <FormattedMessage id="inDays" values={{ days: difference }} />
        {code === 'CUSTOM' && (
          <Tooltip id="warnCustomDate" tooltipComponent={<FormattedMessage id="warnCustomDateAutoFillDisabled" />}>
            <FaExclamationTriangle aria-label="warning auto-fill not available for custom dates" />
          </Tooltip>
        )}
        {warnOriginalDateDifference && (
          <Tooltip
            id="warnOriginalDateDifference"
            tooltipComponent={
              <FormattedMessage
                id="warnOriginalDateDifference"
                values={{
                  days: daysToShowMaturityDateWarning,
                  autoFillMessage: autoFillRfqRatesheet && 'Auto-fill will not be applied on this tenor.',
                }}
              />
            }
          >
            <FaExclamationTriangle aria-label="warning maturity date greater than original date" />
          </Tooltip>
        )}
      </Row>
    ),
  },
  {
    title: 'Coupon (%)',
    dataIndex: 'quote',
    key: 'quote',
    width: '25%',
    render: (quote, { code, codeWithinTenorRange }) => (
      <SendQuoteCouponInput
        quote={quote}
        code={code}
        codeWithinTenorRange={codeWithinTenorRange}
        rfq={rfq}
        onQuoteChange={onQuoteChange}
        isRfqClosed={isRfqClosed}
        isRfqQuoted={isRfqQuoted}
        isQuoteEmpty={isQuoteEmpty}
        enableQuoteEditing={enableQuoteEditing}
        quoteStatus={quoteStatus}
        setMaturityCode={setMaturityCode}
      />
    ),
  },
];

export const buildSendQuoteColumnsPreview = (props) => {
  const { timezone } = props;
  const columns = buildSendQuoteColumns({ ...props, isRfqClosed: true });

  const dateColumn = columns.find(({ dataIndex }) => dataIndex === 'date');
  dateColumn.render = (date) => <DateWithTimeZone timezone={timezone} value={date} />;

  return columns;
};
