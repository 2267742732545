import BaseFields from '../base/fields';
import * as holdingActions from '../../../actions/holding/save';

export const fields = (currencySymbol) => [
  { ...BaseFields.allocation, halfSize: true },
  { ...BaseFields.purchaseDate, halfSize: true },
  {
    name: 'principal',
    label: 'customHoldingPrincipal',
    componentType: 'currency',
    intlValues: { currencySymbol },
  },
  BaseFields.issuer,
  {
    ...BaseFields.coupon,
  },
  {
    name: 'interestPaidCode',
    label: 'holdingPaid',
    componentType: 'select',
    options: holdingActions.codes.interests,
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
