import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getInstrumentCode } from '../../../api/holding/instrument-codes';
import {
  DateWithTimeZone,
  Row,
  SummaryHighlight,
} from '../../../components/common';
import { emptyCharacter, percentual } from '../../../format-numbers';
import { Money } from '../../money';

export const DialogHeader = ({ tradeSelected }) => (
  <Row className="dialog-header" alignItemsCenter contentBetween>
    <SummaryHighlight
      top={<FormattedMessage id="holdingInstrumentCode" />}
      middle={tradeSelected.instrumentCode ? getInstrumentCode(tradeSelected.instrumentCode) : emptyCharacter}
    />
    <SummaryHighlight
      top={<FormattedMessage id="holdingMaturityDate" />}
      middle={tradeSelected.maturityDate ? <DateWithTimeZone value={tradeSelected.maturityDate} /> : emptyCharacter}
    />
    <SummaryHighlight
      top={<FormattedMessage id="coupon" />}
      middle={
        tradeSelected.coupon !== null ? (
          <FormattedNumber {...percentual} value={tradeSelected.coupon} />
        ) : (
          emptyCharacter
        )
      }
    />
    <SummaryHighlight
      top={<FormattedMessage id="principal" />}
      middle={
        tradeSelected.principal !== null ? <Money value={tradeSelected.principal} short fraction={2} /> : emptyCharacter
      }
    />
  </Row>
);

DialogHeader.propTypes = {
  tradeSelected: PropTypes.shape({
    coupon: PropTypes.number,
    instrumentCode: PropTypes.string,
    maturityDate: PropTypes.string,
    principal: PropTypes.number,
  }).isRequired,
};
