import { buildChartDefsByColors, buildChartFillsByColors } from '../../../../components/charts/build-linear-gradient';

export const maturingTradesTransactionsChartKeys = ['unactioned', 'sent', 'redeemed', 'addTradeOnBehalf', 'reinvested'];

export const maturingTradesTransactionsChartColors = {
  reinvested: 'var(--dashboard-status-accepted)',
  addTradeOnBehalf: 'var(--dashboard-status-new-fund)',
  sent: 'var(--dashboard-status-sent)',
  redeemed: 'var(--dashboard-status-not-interested)',
  unactioned: 'var(--dashboard-status-unactioned)',
};

export const commonMaturingTradesChartLegends = {
  toggleSerie: true,
  anchor: 'bottom',
  direction: 'row',
  itemWidth: 110,
  itemHeight: 20,
  itemSpacing: 10,
  effects: [
    {
      on: 'hover',
      style: {
        itemOpacity: 1,
      },
    },
  ],
};

export const maturingTradesTransactionsChartLegends = ({ intl }) => [
  {
    data: maturingTradesTransactionsChartKeys.map((id) => ({
      id,
      label: intl.formatMessage({ id }),
      color: maturingTradesTransactionsChartColors[id],
    })),
    ...commonMaturingTradesChartLegends,
    translateY: 50,
  },
];

export const maturingTradesTransactionsChartDefs = buildChartDefsByColors({
  colors: maturingTradesTransactionsChartColors,
});

export const maturingTradesTransactionsChartFill = buildChartFillsByColors({
  colors: maturingTradesTransactionsChartColors,
});
