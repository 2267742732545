import React from 'react';

import clone from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Row } from '../../../components/common';
import SummaryValueHighlight from '../../../components/holding/summary-value-highlight';
import { initialSummary } from '../../../ducks/issuer-trades/list';
import { shortenNumber } from '../../../format-numbers';

export const MaturingTradesHeader = ({ onAddFundsClick, isLoading, summary }) => (
  <Row className="bank-summary-header" alignItemsCenter contentBetween>
    <Row>
      <SummaryValueHighlight
        isFetching={isLoading}
        middle={summary.issuerTrades ? shortenNumber({ value: summary.issuerTrades.total.maturing }) : 0}
        bottom={<FormattedMessage tagName="span" id="maturingTrades" />}
      />
      <SummaryValueHighlight
        isFetching={isLoading}
        middle={summary.issuerTrades ? shortenNumber({ value: summary.issuerTrades.total.reinvested }) : 0}
        bottom={<FormattedMessage tagName="span" id="reinvested" />}
      />
      <SummaryValueHighlight
        isFetching={isLoading}
        middle={summary.issuerTrades ? shortenNumber({ value: summary.issuerTrades.total.redeemed }) : 0}
        bottom={<FormattedMessage tagName="span" id="redeemed" />}
      />
    </Row>
    <Row className="counters">
      {summary.issuerTrades &&
        Object.keys(summary.issuerTrades.counters).map((issuerTradeSummaryKey) => (
          <SummaryValueHighlight
            key={issuerTradeSummaryKey}
            isFetching={isLoading}
            middle={summary.issuerTrades.counters[issuerTradeSummaryKey]}
            bottom={<FormattedMessage tagName="span" id={issuerTradeSummaryKey} />}
          />
        ))}
      <Button className="btn-solid-primary" onClick={onAddFundsClick}>
        <FormattedMessage tagName="span" id="addFunds" />
      </Button>
    </Row>
  </Row>
);

MaturingTradesHeader.propTypes = {
  isLoading: PropTypes.bool,
  onAddFundsClick: PropTypes.func.isRequired,
  summary: PropTypes.shape({
    rfqs: PropTypes.shape(),
    issuerTrades: PropTypes.shape({
      counters: PropTypes.shape(),
      total: PropTypes.shape(),
    }).isRequired,
    unactioned: PropTypes.number,
  }),
};

MaturingTradesHeader.defaultProps = {
  summary: { issuerTrades: clone(initialSummary) },
  isLoading: false,
};
