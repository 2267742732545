import axios from 'axios';

import { buildHeaders } from '../../../index';

export const createSellBondsRfq = async function({ payload }) {
  const headers = buildHeaders();

  const { data } = await axios({
    url: '/rfqs/sell-bonds',
    method: 'post',
    headers,
    data: payload,
  });

  return data;
};
