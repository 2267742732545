import axios from 'axios';

export default async function ({ uuid }) {
  const { data } = await axios({
    method: 'get',
    url: `/rfqs/${uuid}/quotes`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return {
    uuid,
    ...data,
  };
}
