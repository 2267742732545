import './charts-presenter.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import chartsComponents from '../charts/charts-components';
import DownloadChartButton from '../download/download-chart-button';
import { calculateMaxYAxis } from './compliances/chart-payload';
import { EmptyContent } from './EmptyContent';
import { Row } from './Flex/Flex';
import Panel from './panel';
import { SkeletonChart } from './Skeletons';

class ChartsPresenter extends Component {
  buildPayloadSimple = (chart, intl) => this.buildPayload(chart, chart.series(chart.data, intl));

  buildPayloadAssignOptions(seriesByChart, options, chart) {
    const payload = this.buildPayload(chart, seriesByChart[chart.id]);

    payload.options = {
      ...payload.options,
      ...options,
    };

    return payload;
  }

  buildPayload = ({ id, data, xTicks, yTicks, tooltips, options, showLegend }, series = []) => ({
    id,
    xTicks: xTicks && xTicks(data),
    yTicks: yTicks && yTicks(data),
    tooltips: tooltips && tooltips(data),
    series,
    options: options ? options(series, data) : null,
    showLegend,
  });

  buildPayloadLinkedOptions(charts, intl) {
    const seriesByChart = this.buildSeriesByChart(charts, intl);

    const overallSeries = Object.keys(seriesByChart).reduce((series, chart) => series.concat(seriesByChart[chart]), []);

    const overallOptions = {
      maxYAxis: calculateMaxYAxis(overallSeries),
    };

    return this.buildPayloadAssignOptions.bind(this, seriesByChart, overallOptions);
  }

  buildSeriesByChart = (charts, intl) =>
    charts &&
    charts
      .filter((chart) => !chart.isFetching && chart.data)
      .reduce((seriesByChart, chart) => {
        // eslint-disable-next-line no-param-reassign
        seriesByChart[chart.id] = chart.series(chart.data, intl);

        return seriesByChart;
      }, {});

  renderChartComponent = (chart, payloadBuilder, intl) => {
    const payload = payloadBuilder(chart, intl);
    payload.state = 'default';

    return chartsComponents[chart.type](payload);
  };

  renderChart = (chart, payloadBuilder, intl) => {
    if (!chart.isFetching && (!chart.data || !chart.data.length)) {
      return (
        <div className="no-content">
          <EmptyContent messageId="noGraphRecords" />
        </div>
      );
    }

    return (
      <SkeletonChart isLoading={chart.isFetching}>
        {chart.data && this.renderChartComponent(chart, payloadBuilder, intl)}
      </SkeletonChart>
    );
  };

  renderCharts(charts, options) {
    const { intl } = this.props;

    const columnOptions = options && options.panelSizes && { ...options.panelSizes };
    const payloadBuilder =
      options && options.linkedOptions ? this.buildPayloadLinkedOptions(charts, intl) : this.buildPayloadSimple;

    return charts.map((chart) => {
      const chartInfo = { chart, payloadBuilder, intl };

      return (
        <Panel {...columnOptions} key={chart.id}>
          <Row className="title-container" alignItemsCenter contentBetween>
            <h4 className="chart-title">{intl.formatMessage({ id: chart.titleId })}</h4>
            {chart.data && chart.data.length > 0 && <DownloadChartButton chartInfo={chartInfo} />}
          </Row>
          {this.renderChart(chart, payloadBuilder, intl)}
        </Panel>
      );
    });
  }

  render() {
    const { charts, options } = this.props;

    return <div className="charts-presenter">{this.renderCharts(charts, options)}</div>;
  }
}

ChartsPresenter.propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      isFetching: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape()),
      series: PropTypes.func.isRequired,
      type: PropTypes.string.isRequired,
      xTicks: PropTypes.func,
      yTicks: PropTypes.func,
      options: PropTypes.func,
      sizes: PropTypes.shape(),
      showLegend: PropTypes.bool,
    }),
  ),
  options: PropTypes.shape({
    linkedOptions: PropTypes.bool,
    panelSizes: PropTypes.shape(),
  }),
};

export default injectIntl(ChartsPresenter);
