import Valida from 'valida';

import { maturityDateValidator } from '../../../actions/helper/validation';
import { maxFloat, positiveValue } from '../../../parse-float';
import baseSchema from '../base/schema';

export const schema = {
  ...baseSchema,
  coupon: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue },
    { validator: maxFloat },
  ],
  maturityDate: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.date },
    { validator: maturityDateValidator },
  ],
  interestCompoundCode: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  interestPaidCode: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
};
