import classnames from 'classnames';
import { format } from 'd3-format';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DateWithTimeZone } from '../../../date';
import { rfqUserActions } from '../../../ducks/rfq/rfq-user-actions';
import { timeProps } from '../../../format-date';
import { couponFormat } from '../../../format-numbers';
import { Tooltip } from '../../common';
import { commonHeaderPresenter } from './';

export const columns = {
  issuer: { label: 'rfqAdi' },
  receivedAt: { label: 'receivedAt' },
};

export const headerPresenter = commonHeaderPresenter;

const formatMoney = format(',.2f');

const couponBuilder = {
  pending: renderPendingCoupon,
  accepted: renderAcceptedCoupon,
  confirmed: renderAcceptedCoupon,
  rejected: renderClosedCoupon,
  closed: renderClosedCoupon,
};

export const presenter = (quote, index, actions, options) => {
  const selectedQuote = actions.getQuoteSelected();
  const [firstMaturity] = quote.maturities;
  const { nominatedMaturity, enforceSeparationOfDuties } = options;

  return (
    <tr key={index}>
      <td className="col-quote-issuer-adi">
        <label className="text-ellipsis">{`${quote.code} - ${quote.shortName}`}</label>
      </td>
      <td className="col-quote-received-at">
        <DateWithTimeZone value={firstMaturity.receivedAt} {...timeProps} />
      </td>
      {quote.maturities.map((maturity, key) => (
        <td className="col-quote-coupon text-center" key={maturity.id}>
          {renderCoupon({ actions, maturity, quote, selectedQuote, nominatedMaturity, enforceSeparationOfDuties })}
        </td>
      ))}
    </tr>
  );
};

function renderCoupon({ actions, maturity, quote, selectedQuote, nominatedMaturity, enforceSeparationOfDuties }) {
  if (maturity.coupon === null) {
    return renderInexistentCoupon(maturity);
  }

  const quoteWasNotNominated =
    enforceSeparationOfDuties &&
    nominatedMaturity &&
    nominatedMaturity.id !== maturity.id &&
    quote.userActionAllowed !== rfqUserActions.nominate;

  if (quote.isRfqCancelled || quoteWasNotNominated) {
    return couponBuilder.closed({ actions, maturity });
  }

  return couponBuilder[maturity.status]
    ? couponBuilder[maturity.status]({ actions, maturity, quote, selectedQuote, nominatedMaturity })
    : maturity.status;
}

function renderPendingCoupon({ actions, maturity, quote, selectedQuote, nominatedMaturity }) {
  const considerationTitle = maturity.consideration && `Consideration: ${formatMoney(maturity.consideration)}`;
  const nominatedTitle =
    nominatedMaturity && nominatedMaturity.nominatedBy && `Nominated by: ${nominatedMaturity.nominatedBy}`;

  const title =
    considerationTitle && nominatedTitle
      ? `${considerationTitle}\n${nominatedTitle}`
      : considerationTitle || nominatedTitle;

  const isNominateAndHasBeenSignedOff =
    nominatedMaturity &&
    nominatedMaturity.nominatedBy &&
    nominatedMaturity.signedOffBy &&
    maturity.id !== nominatedMaturity.id;

  return (
    <Tooltip id={`${maturity.id}`} tooltipComponent={buildComplianceTooltip(maturity)}>
      <label
        title={title}
        disabled={isNominateAndHasBeenSignedOff}
        onClick={(e) =>
          !isNominateAndHasBeenSignedOff && onSelectQuoteButtonClick(e, actions.onQuoteSelectClick, maturity, quote)
        }
        className={classnames('btn btn-table btn-default', {
          active: maturity.id === (selectedQuote && selectedQuote.id),
          'btn-success': maturity.isBestQuote,
          nominated: maturity.nominated && !selectedQuote,
        })}
      >
        <span>
          <FormattedNumber {...couponFormat} value={maturity.coupon} />
        </span>
        {maturity.compliance && maturity.compliance.hasChanceOfBreach && <FaExclamationTriangle />}
      </label>
    </Tooltip>
  );
}

const buildComplianceTooltip = ({
  rating,
  issuerCode,
  compliance: { amountAvailable, ratingName, hasChanceOfBreach },
}) => (
  <Column>
    <Row>Compliance: {ratingName}</Row>
    <Row>Issuer: {issuerCode}</Row>
    <Row>Rating: {rating}</Row>
    <Row>Available: {formatMoney(amountAvailable)}</Row>
    {hasChanceOfBreach && (
      <Row>
        <FormattedMessage id="possibleComplianceBreach" />
      </Row>
    )}
  </Column>
);

function renderAcceptedCoupon({ maturity }) {
  return (
    <label className="btn btn-table btn-default active accepted">
      <span>
        <FormattedNumber {...couponFormat} value={maturity.coupon} />
      </span>
    </label>
  );
}

function renderClosedCoupon({ maturity }) {
  return (
    <label className="btn btn-table rejected">
      <span>
        <FormattedNumber {...couponFormat} value={maturity.coupon} />
      </span>
    </label>
  );
}

function renderInexistentCoupon(maturity) {
  if (maturity.status === 'rejected') {
    return null;
  }

  return (
    <Button className="btn-table" disabled>
      {'-'}
    </Button>
  );
}

const onSelectQuoteButtonClick = (event, onQuoteSelectClick, quoteDetails, quoteByIssuer) => {
  onQuoteSelectClick(quoteDetails, quoteByIssuer);
  event.preventDefault();
};
