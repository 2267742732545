import * as statuses from '../../../../ducks/issuer-trades/statuses';
import { maturingTradesTransactionsChartKeys } from './maturing-trades-transactions-chart-settings';

export const maturingChartOverallKey = 'overall';

export const emptyMaturingTargetChartData = maturingTradesTransactionsChartKeys.reduce(
  (initialGraphData, maturingTradesTargetChartKey) => {
    /* eslint-disable-next-line no-param-reassign */
    initialGraphData[maturingTradesTargetChartKey] = 0;

    return initialGraphData;
  },
  {
    key: maturingChartOverallKey,
  },
);

const redeemedStatuses = [statuses.redeemed, statuses.redeemedOnBehalf];
const reinvestedStatuses = [statuses.reinvested, statuses.reinvestedOnBehalf];

export const buildMaturingTradesTransactionsChartData = ({ issuerTrades }) =>
  issuerTrades.length
    ? issuerTrades.reduce(
        (chartData, issuerTrade) => {
          /* eslint-disable no-param-reassign */
          if (redeemedStatuses.includes(issuerTrade.maturingStatus)) {
            chartData.redeemed += 1;

            return chartData;
          }

          if (reinvestedStatuses.includes(issuerTrade.maturingStatus)) {
            chartData.reinvested += 1;

            return chartData;
          }

          if (!maturingTradesTransactionsChartKeys.includes(issuerTrade.maturingStatus)) {
            return chartData;
          }

          chartData[issuerTrade.maturingStatus] += 1;

          /* eslint-enable no-param-reassign */
          return chartData;
        },
        { ...emptyMaturingTargetChartData },
      )
    : emptyMaturingTargetChartData;
