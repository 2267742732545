import buildForm from '../../common/form/form';
import { loadSellableCDs } from '../base/formatters';
import fields from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

export default function sellECDForm(onSave, intl) {
  const filter = { field: 'instrumentCode', value: 'ECD' };
  const ecds = fields.find((field) => field.name === 'ecd') || {};
  ecds.loadOptions = (data) => loadSellableCDs(data, filter, intl);

  return {
    title: 'addSellECDOption',
    value: 'sellEcd',
    className: 'sell-ecd-form',
    form: buildForm({
      fields,
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
