import '../../close-buy-sell-bond-rfq.scss';

import React, { useEffect } from 'react';

import classNames from 'classnames';
import propTypes from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  buyBondsAcceptQuote,
  buyBondsCancelRfq,
} from '../../../../../api/holding/rfq/buy-bonds';
import {
  ConfirmationPopover,
  DateWithTimeZone,
  ResultsPresenter,
} from '../../../../../components/common';
import {
  buyBondRfqAcceptQuoteClicked,
  buyBondRfqCancelRfqClicked,
  buyBondRfqInvestorViewBuyBondRfqDetails,
  track,
} from '../../../../../event-tracker';
import { toNumberFormat } from '../../../../../format-numbers';
import { Money } from '../../../../money';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../../../../toast/toast';
import {
  getInstrumentCodeDescription,
  getIsinYieldOrMarginDescription,
} from '../../bond-frn-descriptions';
import {
  acceptQuotePresenter,
  buildAcceptQuotesColumns,
} from './close-buy-bond-records-presenter';

export const CloseBuyBondRfq = injectIntl(({ rfq, isLoading, onChangeRfqSuccess, currency, intl }) => {
  useEffect(() => {
    if (!rfq.buyBondRfqEntityId) {
      return;
    }

    track(buyBondRfqInvestorViewBuyBondRfqDetails);
  }, [rfq.buyBondRfqEntityId]);

  const {
    instrumentCode,
    isin,
    isinIssuerName,
    settlementDate,
    faceValueToBuy,
    isinYieldOrMargin,
    isinMaturityDate,
    buyBondRfqEntityId,
    quotes = [],
    issuerCodes = [],
    confirmedAt,
    confirmedBy,
    rfqStatus,
    canCancelRfq,
    capitalValue,
    grossValue,
    hasAustraclear,
  } = rfq;

  const onAcceptQuote = async ({ quoteEntityId }) => {
    try {
      await buyBondsAcceptQuote({
        rfqEntityId: buyBondRfqEntityId,
        quoteEntityId,
      });

      track(buyBondRfqAcceptQuoteClicked);

      onChangeRfqSuccess && onChangeRfqSuccess();

      showToastMessage(intl.formatMessage({ id: 'quoteSentSuccessfully' }), MessageType.SUCCESS);
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    }
  };

  const onCancelRfq = async () => {
    try {
      await buyBondsCancelRfq({
        rfqEntityId: buyBondRfqEntityId,
      });

      track(buyBondRfqCancelRfqClicked);

      onChangeRfqSuccess && onChangeRfqSuccess();

      showToastMessage(intl.formatMessage({ id: 'rfqCancelled' }), MessageType.SUCCESS);
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    }
  };

  const data = issuerCodes.map((issuerCode) => ({
    issuerCode,
    ...quotes.find((quote) => quote.issuerCode === issuerCode),
  }));

  const payload = {
    isFetching: isLoading,
    noRecords: !data || !data.length,
    recordsPresenter: {
      ariaLabel: 'buy bond rfq quotes',
      data,
      presenter: acceptQuotePresenter,
      columns: buildAcceptQuotesColumns({ instrumentCode }),
      actions: {
        onAcceptQuote,
      },
      options: {
        confirmedAt,
        rfqStatus,
        confirmedBy,
      },
    },
  };

  return (
    <Column
      element="section"
      aria-label="close bond rfq quote container"
      className={classNames('close-buy-sell-bond-container', {
        'is-loading': isLoading,
      })}
    >
      <dl className="highlight">
        <div>
          <dt>Type</dt>
          <dd>{getInstrumentCodeDescription(instrumentCode)}</dd>
        </div>
        <div>
          <dt>ISIN</dt>
          <dd>{isin}</dd>
        </div>
        <div>
          <dt>Issuer</dt>
          <dd>{isinIssuerName}</dd>
        </div>
        <div>
          <dt>Maturity Date</dt>
          <dd>{isinMaturityDate && <DateWithTimeZone value={isinMaturityDate} />}</dd>
        </div>
        <div>
          <dt>{getIsinYieldOrMarginDescription(instrumentCode)}</dt>
          <dd>{toNumberFormat({ value: isinYieldOrMargin })}</dd>
        </div>
      </dl>
      <dl>
        <div>
          <dt>Face Value ({currency})</dt>
          <dd>
            <Money value={faceValueToBuy} />
          </dd>
        </div>
        {capitalValue && (
          <div>
            <dt>Capital Value ({currency})</dt>
            <dd>
              <Money value={capitalValue} />
            </dd>
          </div>
        )}
        {grossValue && (
          <div>
            <dt>Gross Value ({currency})</dt>
            <dd>
              <Money value={grossValue} />
            </dd>
          </div>
        )}
        <div>
          <dt>Settlement Date</dt>
          <dd>{settlementDate && <DateWithTimeZone value={settlementDate} />}</dd>
        </div>
        <div>
          <dt>Has Austraclear?</dt>
          <dd>{hasAustraclear ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</dd>
        </div>
      </dl>
      <Row element="section" className="highlight" justifyContentSpaceBetween alignItemsCenter>
        <h4>Quotes to Buy</h4>
        <FormattedMessage id="availableQuotes" values={{ count: quotes.length }} />
      </Row>
      <ResultsPresenter {...payload} />
      {canCancelRfq && (
        <ConfirmationPopover
          confirmationMessageId="cancelRfqNow"
          buttonLabelId="cancelRfq"
          popoverClass="light"
          className="cancel-button"
          onConfirm={onCancelRfq}
        />
      )}
    </Column>
  );
});

CloseBuyBondRfq.propTypes = {
  rfq: propTypes.shape(),
  isLoading: propTypes.bool,
  onChangeRfqSuccess: propTypes.func,
  currency: propTypes.string.isRequired,
};

CloseBuyBondRfq.defaultProps = {
  rfq: {},
};
