import axios from 'axios';

export default async function () {
  const { data } = await axios({
    method: 'get',
    url: '/dashboard/rfqs/outgoing',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
