import { Cookies } from 'react-cookie';

import config from '../../../config';

const cookieName = "imperium-walkthrough";

const options = { path: "/", domain: ".imperium.markets", secure: false };

if (config.env.nodeEnv === "production") {
  options.secure = true;
  options.sameSite = "lax";
}

class Store {
  cookie = new Cookies();

  add = (value) => {
    const items = this.get();
    items.add(value);
    this.cookie.set(cookieName, Array.from(items), options);
  };

  remove = (value) => {
    const items = this.get();
    items.delete(value);
    this.cookie.set(cookieName, Array.from(items), options);
  };

  get = () => new Set(this.cookie.get(cookieName));

  has = (name) => this.get().has(name);
}

export const walkthroughStore = Object.freeze(new Store());
