export const validationErrorKeys = {
  mustMatch: 'must-match',
  sellGreaterThanToday: 'sell-greater-than-today',
  repeatedPassword: 'new-password-repeated',
  tooManyFailedLoginAttempts: 'too-many-failed-login-attempts',
  invalidLoginOrPassword: 'invalid-login-or-password',
  accountUnusedTimeLimit: 'account-unused-time-limit',
  disabledUser: 'disabled-user',
  pendingUser: 'pending-user',
};
