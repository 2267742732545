import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Money } from '../../../../containers/money';
import { newDate } from '../../../../date';
import { renderInitialsTooltip } from '../../../../get-name-initials';
import { getFormattedNumberOrEmpty, rateFormat } from '../../../../format-numbers';
import capitalize from '../../../../capitalize';

const shortDateFormat = 'DD/MM/YYYY';

const Row = ({
  uuid,
  offerId,
  operation,
  date,
  status,
  cancelOption,
  principal,
  capitalValue,
  grossValue,
  assetClass,
  winningIssuer,
  winningTenor,
  winningMaturityDate,
  winningCoupon,
  createdBy,
  acceptedBy,
  confirmedBy,
  cancelledBy,
  nominatedBy,
  signedOffBy,
  viewDetailsAction,
  canViewDetails,
  settlementDate,
}) => {
  const presenterStatus = status === 'cancelled' && cancelOption ? cancelOption : status;
  const statusId = `dashboard.status.${presenterStatus}`;
  const isFrn = assetClass === 'FRN';

  return (
    <tr>
      <td>
        <FormattedMessage id={offerId ? 'offer' : 'rfq'} />
      </td>
      <td>{assetClass}</td>
      <td>{operation ? capitalize(operation, true) : '-'}</td>
      <td>{newDate(date).format(shortDateFormat)}</td>
      <td>{newDate(settlementDate).format(shortDateFormat)}</td>
      <td className={`status-${status}`}>{status ? <FormattedMessage id={statusId} /> : '-'}</td>
      <td>
        <Money value={principal} />
      </td>
      <td className="securities-column">
        <Money value={capitalValue} />
      </td>
      <td className="securities-column">
        <Money value={grossValue} />
      </td>
      <td>{winningIssuer || '-'}</td>
      <td>{winningTenor || '-'}</td>
      <td>{winningMaturityDate ? newDate(winningMaturityDate).format(shortDateFormat) : '-'}</td>
      <td>{getFormattedNumberOrEmpty({ value: winningCoupon, format: rateFormat, assetClass })}</td>
      <td>{renderInitialsTooltip(createdBy)}</td>
      <td>{renderInitialsTooltip(nominatedBy)}</td>
      <td>{renderInitialsTooltip(signedOffBy)}</td>
      <td>{renderInitialsTooltip(acceptedBy)}</td>
      <td>{renderInitialsTooltip(confirmedBy)}</td>
      <td>{renderInitialsTooltip(cancelledBy)}</td>
      <td className="action-cell">{canViewDetails && renderActionButton(viewDetailsAction, uuid)}</td>
    </tr>
  );
};

const renderActionButton = (viewDetailsAction, uuid) => (
  <Button onClick={() => viewDetailsAction(uuid)}>
    <FormattedMessage id="reports.rfqsHistory.viewQuotesButton" />
  </Button>
);

Row.propTypes = {
  uuid: PropTypes.string.isRequired,
  offerId: PropTypes.number,
  date: PropTypes.string.isRequired,
  status: PropTypes.string,
  operation: PropTypes.string,
  cancelOption: PropTypes.string,
  principal: PropTypes.number.isRequired,
  capitalValue: PropTypes.number,
  grossValue: PropTypes.number,
  assetClass: PropTypes.string.isRequired,
  winningIssuer: PropTypes.string,
  winningTenor: PropTypes.string,
  winningMaturityDate: PropTypes.string,
  winningCoupon: PropTypes.number,
  createdBy: PropTypes.string,
  acceptedBy: PropTypes.string,
  confirmedBy: PropTypes.string,
  cancelledBy: PropTypes.string,
  nominatedBy: PropTypes.string,
  signedOffBy: PropTypes.string,
  canViewDetails: PropTypes.bool.isRequired,
  viewDetailsAction: PropTypes.func.isRequired,
  settlementDate: PropTypes.string.isRequired,
};

Row.defaultProps = {
  cancelOption: null,
  winningIssuer: null,
  winningTenor: null,
  winningCoupon: null,
  winningMaturityDate: null,
  createdBy: null,
  acceptedBy: null,
  confirmedBy: null,
  signedOffBy: null,
  cancelledBy: null,
  nominatedBy: null,
};

export default Row;
