import React from 'react';

import { useAppContext } from '../../containers/app/AppContext';

export default function withAppContext() {
  return (WrappedComponent) => (props) => {
    const appContext = useAppContext();

    return <WrappedComponent {...props} appContext={appContext} />;
  };
}
