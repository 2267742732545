import React from 'react';

import classNames from 'classnames';

import toFloat from '../../../../../../parse-float';
import { CreateTradeFromRatePopoverButton } from './CreateTradeFromRatePopoverButton';

export const columns = ({ currencySymbol, intl }) => ({
  customer: { label: 'adi' },
  short: { label: 'term.short' },
  long: { label: 'term.long' },
  '1M': { label: '1M', sort: '1M' },
  '2M': { label: '2M', sort: '2M' },
  '3M': { label: '3M', sort: '3M' },
  '4M': { label: '4M', sort: '4M' },
  '5M': { label: '5M', sort: '5M' },
  '6M': { label: '6M', sort: '6M' },
  '7M': { label: '7M', sort: '7M' },
  '8M': { label: '8M', sort: '8M' },
  '9M': { label: '9M', sort: '9M' },
  '10M': { label: '10M', sort: '10M' },
  '11M': { label: '11M', sort: '11M' },
  '12M': { label: '12M', sort: '12M' },
  '2Y': { label: '2Y', sort: '2Y' },
  '3Y': { label: '3Y', sort: '3Y' },
  '4Y': { label: '4Y', sort: '4Y' },
  '5Y': { label: '5Y', sort: '5Y' },
});

export const presenter = (data, index, actions, { isAccepting, maturingSummary }) => {
  const { unsolicitedRatesheetEntityId, issuer, ratesheet, unsolicitedRatesheetMaxBankAmount } = data;

  return (
    <tr key={index}>
      <td>{issuer.name}</td>
      <td>{issuer.shortRating}</td>
      <td>{issuer.longRating}</td>
      {ratesheet.rates.map(({ tenor, rate, isBestQuote }) => (
        <td className={classNames('trade-from-rate-column', { 'is-best-quote': isBestQuote })} key={tenor}>
          <CreateTradeFromRatePopoverButton
            unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
            isAccepting={isAccepting}
            issuer={issuer}
            onCreateRfqClick={actions.onCreateRfqClick}
            onReinvestRfqClick={actions.onReinvestRfqClick}
            tenor={tenor}
            rate={rate}
            maturingSummary={maturingSummary}
            onSubmit={async ({ allocationCode, interestPaid, principal, additionalFunds, rootTradeId }) => {
              const reinvestmentProperties = rootTradeId && {
                additionalFunds: additionalFunds ? toFloat(additionalFunds) : 0,
                rootTradeId,
                principal: undefined,
              };

              return unsolicitedRatesheetEntityId
                ? actions.onAcceptBespokeRatesheetConfirm({
                    unsolicitedRatesheetEntityId,
                    allocationCode,
                    interestPaid,
                    rate,
                    principal: toFloat(principal),
                    tenor,
                    bankIssuerId: issuer.id,
                    ...reinvestmentProperties,
                  })
                : actions.onAcceptDefaultRatesheetConfirm({
                    allocationCode,
                    interestPaid,
                    rate,
                    principal: toFloat(principal),
                    tenor,
                    bankIssuerId: issuer.id,
                    ...reinvestmentProperties,
                  });
            }}
          />
        </td>
      ))}
    </tr>
  );
};
