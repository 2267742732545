import axios from 'axios';

export async function getOutstandingOffers() {
  const { data } = await axios({
    method: 'get',
    url: '/outstanding-offers',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
