import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SummaryItem } from '../../../components/common';
import { getOutgoingPanelData } from '../../../ducks/rfq/selectors';
import { Money } from '../../money';

const TotalizerComponent = ({ quotes, outgoing }) => {
  const {
    summary: { confirmed },
  } = outgoing;

  return (
    <SummaryItem
      className="summary-item"
      top={<FormattedMessage id="acceptedRfqs" />}
      middle={<Money value={confirmed.amount} short fraction={confirmed.amount > 0 ? 2 : 0} />}
      bottom={
        confirmed.count > 0 && (
          <FormattedMessage
            tagName="span"
            id="portfolio.filter-investments-number"
            values={{ length: confirmed.count }}
          />
        )
      }
      isLoading={quotes.isFetching}
    />
  );
};

TotalizerComponent.propTypes = {
  outgoing: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  outgoing: getOutgoingPanelData(state),
  quotes: state.quotes,
});

export const RfqsConfirmedTotalizer = connect(mapStateToProps)(TotalizerComponent);
