import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Column, Row } from 'react-display-flex';

import { Drawer, ValidatedFormikField, FormikSelectField, ConfirmationPopover } from '../../../components/common';
import {
  validationSchema,
  initialValues,
  segmentCodeOptions,
  ratesheetOptions,
} from './create-single-proposed-tenant-schema';
import { MessageType, showToastMessage } from '../../toast/toast';
import { createProposedTenant } from '../../../api/onboarding/create-proposed-tenant';

import './create-single-proposed-tenant-drawer.scss';

export const CreateSingleProposedTenantDrawer = injectIntl(
  ({ open, onToggle, tenantDomain, onSuccess, anzsciCodes, intl }) => {
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (proposedTenant, { setFieldError }) => {
      setIsLoading(true);

      const payload = {
        proposedTenants: [proposedTenant],
        domain: tenantDomain,
      };

      try {
        await createProposedTenant(tenantDomain, payload);

        showToastMessage(intl.formatMessage({ id: 'customerCreatedSuccessfully' }), MessageType.SUCCESS);
        onToggle();
        onSuccess && onSuccess();
      } catch ({ response: { data } }) {
        if (data.proposedTenants) {
          handleApiErrors(data.proposedTenants, setFieldError);
        }
        showToastMessage(intl.formatMessage({ id: 'customerCreatedError' }), MessageType.ERROR);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Drawer
        onClose={() => onToggle()}
        titleId="addSingleCustomer"
        className="light single-proposed-tenant"
        open={open}
        shouldCloseOnClickOutside={false}
        width="30vw"
      >
        {open && (
          <Formik initialValues={initialValues} validationSchema={validationSchema({ intl })} onSubmit={onSubmit}>
            {({ touched, errors, values, handleSubmit }) => (
              <Form className="single-proposed-tenant-form" data-testid="tenantForm">
                <Column>
                  <h4 className="form-legend">
                    <FormattedMessage id="customer" />
                  </h4>
                  <ValidatedFormikField
                    id="customerName"
                    name="customerName"
                    labelId="customerName"
                    data-testid="customerName"
                    value={values.customerName}
                    touched={touched}
                    errors={errors}
                  />
                  <Row>
                    <ValidatedFormikField
                      id="customerId"
                      name="customerId"
                      labelId="customerId"
                      data-testid="customerId"
                      value={values.customerId}
                      touched={touched}
                      errors={errors}
                    />
                    <ValidatedFormikField
                      id="abn"
                      name="abn"
                      labelId="abn"
                      data-testid="abn"
                      value={values.abn}
                      touched={touched}
                      errors={errors}
                    />
                  </Row>
                  <Row>
                    <ValidatedFormikField
                      id="segmentCode"
                      name="segmentCode"
                      labelId="segmentCode"
                      aria-label="segmentCode"
                      value={values.segmentCode}
                      component={FormikSelectField}
                      touched={touched}
                      errors={errors}
                      options={segmentCodeOptions}
                      isClearable={false}
                    />
                    <ValidatedFormikField
                      id="anzsciCode"
                      name="anzsciCode"
                      labelId="anzsciCode"
                      data-testid="anzsciCode"
                      aria-label="anzsciCode"
                      value={values.anzsciCode}
                      touched={touched}
                      errors={errors}
                      options={anzsciCodes}
                      component={FormikSelectField}
                    />
                  </Row>
                  <ValidatedFormikField
                    id="ratesheet"
                    name="ratesheet"
                    labelId="ratesheet"
                    aria-label="ratesheet"
                    value={values.ratesheet}
                    component={FormikSelectField}
                    touched={touched}
                    errors={errors}
                    options={ratesheetOptions}
                    isClearable={false}
                  />
                  <h4 className="form-legend">
                    <FormattedMessage id="entity" />
                  </h4>
                  <Row>
                    <ValidatedFormikField
                      id="firstName"
                      name="firstName"
                      labelId="name"
                      data-testid="firstName"
                      value={values.firstName}
                      touched={touched}
                      errors={errors}
                    />
                    <ValidatedFormikField
                      id="surname"
                      name="surname"
                      labelId="surname"
                      data-testid="surname"
                      value={values.surname}
                      touched={touched}
                      errors={errors}
                    />
                  </Row>
                  <ValidatedFormikField
                    id="email"
                    name="email"
                    labelId="email"
                    data-testid="email"
                    value={values.email}
                    touched={touched}
                    errors={errors}
                  />
                </Column>
                <ConfirmationPopover
                  className="btn-solid-primary"
                  onConfirm={handleSubmit}
                  isConfirmingFromOutside={isLoading}
                  confirmationMessageId="areYouSure"
                  buttonLabelId="save"
                  popoverClass="light drawer-popover"
                />
              </Form>
            )}
          </Formik>
        )}
      </Drawer>
    );
  },
);

CreateSingleProposedTenantDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  tenantDomain: PropTypes.string.isRequired,
  anzsciCodes: PropTypes.arrayOf(PropTypes.shape),
};

const handleApiErrors = (errors, setFieldError) => {
  const [apiErrors] = errors;
  Object.keys(apiErrors).map((key) => {
    const [errorMessage] = apiErrors[key];

    return setFieldError(key, errorMessage);
  });
};
