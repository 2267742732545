import Valida from 'valida';
import { validationErrorKeys } from '../../../validation/error-keys';

import { positiveValue, maxFloat, maximumValueFromJSON, rangeValue } from '../../../parse-float';
import { purchaseValidator } from '../../../actions/helper/validation';

export default {
  operation: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  isin: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  quantityToKeep: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue, allowZero: true },
    { validator: maxFloat },
    { validator: maximumValueFromJSON, formField: 'isin', fieldKey: 'amount' },
  ],
  sellingDate: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.date },
    { validator: purchaseValidator, errorMsg: validationErrorKeys.sellGreaterThanToday },
  ],
  cleanPrice: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: rangeValue, min: 70, max: 150 },
  ],
  grossPrice: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: rangeValue, min: 70, max: 150 },
  ],
};
