export const interests = [
  { code: 'M', label: 'monthly' },
  { code: 'Q', label: 'quarterly' },
  { code: 'S', label: 'semiAnnual' },
  { code: 'A', label: 'annual' },
  { code: 'Z', label: 'atMaturity' },
];

export const instrumentCodes = [
  { code: 'BOND', label: 'Bond' },
  { code: 'CASH', label: 'Cash' },
  { code: 'FRN', label: 'FRN' },
  { code: 'TD', label: 'TD' },
  { code: 'NCD', label: 'NCD' },
  { code: 'ECD', label: 'CD' },
  { code: 'CP', label: 'CP' },
  { code: 'TAN', label: 'TAN' },
  { code: 'OTHERASSET', label: 'Other' },
];

export const generalAllocationCode = { code: 'GENERAL', label: 'GENERAL' };

export const initialGeneralAllocationCode = {
  value: generalAllocationCode.code,
  label: generalAllocationCode.code,
};

export const allocations = [
  generalAllocationCode,
  { code: 'SEWER', label: 'sewer' },
  { code: 'WATER', label: 'water' },
];

export const maturityActions = {
  reinvested: 'reinvested',
  redeemed: 'redeemed',
};

export const tradeSources = {
  addTrade: 'add-trade',
  buyBondRfq: 'buy-bond-rfq',
  oneDayRollover: 'one-day-rollover',
  redemption: 'redemption',
  rfq: 'rfq',
  rolloverOffer: 'rollover-offer',
  rolloverOnBehalf: 'rollover-on-behalf',
  defaultUnsolicitedRatesheet: 'default-unsolicited-ratesheet',
  bespokeUnsolicitedRatesheet: 'bespoke-unsolicited-ratesheet',
  sellBondRfq: 'sell-bond-rfq',
  addTradeDealtAway: 'add-trade-dealt-away',
};

export const unsolicitedRatesheetSources = [
  tradeSources.defaultUnsolicitedRatesheet,
  tradeSources.bespokeUnsolicitedRatesheet,
];

export default {
  allocations,
  instrumentCodes,
  interests,
  maturityActions,
};

export const findLabelForInstrumentCode = (code) => {
  const filteredCode = instrumentCodes.find((instrumentCode) => instrumentCode.code === code);

  return filteredCode ? filteredCode.label : code;
};

export const safeCustodyOptions = [
  { label: 'ANZ', value: 'ANZ' },
  { label: 'CBA', value: 'CBA' },
  { label: 'NAB', value: 'NAB' },
  { label: 'Austraclear', value: 'AUSTRACLEAR' },
];

export const sortedRatings = [
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A-1+',
  'A-1',
  'A-2',
  'A-3',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-',
  'BB+',
  'BB',
  'BB-',
  'B+',
  'B',
  'B-',
  'CCC+',
  'CCC',
  'CCC-',
  'CC+',
  'CC',
  'CC-',
  'C+',
  'C',
  'C-',
  'D',
  'Z',
  'Unrated',
];

export function sortRatings(rating1, rating2) {
  // sanity check
  if (!rating1 || !rating2 || typeof rating1 !== 'string' || typeof rating2 !== 'string') {
    return 0;
  }

  const upper1 = rating1.toUpperCase().trim();
  const upper2 = rating2.toUpperCase().trim();

  if (upper1 === upper2) {
    return 0;
  }

  // if we don't have one of the ratings, do a simple alphabetical comparison
  if (sortedRatings.indexOf(upper1) === -1 || sortedRatings.indexOf(upper2) === -1) {
    return upper1 < upper2 ? -1 : 1;
  }

  // if we have the ratings in our list them compare them
  return sortedRatings.indexOf(upper1) < sortedRatings.indexOf(upper2) ? -1 : 1;
}
