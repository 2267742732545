import { createActionThunk } from 'redux-thunk-actions';

import { fetchIncoming, fetchOutgoing, fetchAdiTrades, fetchAnonymisedQuotes } from '../../api/dashboard';
import {
  fetchDashboard as fetchDashboardAction,
  fetchAnonymised as fetchAnonymisedDashboardAction,
  fetchIncomingDashboard as fetchIncomingDashboardAction,
  fetchOutgoingDashboard as fetchOutgoingDashboardAction,
} from './actions';

export const fetchDashboard = createActionThunk(fetchDashboardAction, async () => ({
  incomingRfqs: await fetchIncoming(),
  outgoingRfqs: await fetchOutgoing(),
  adiTrades: (await fetchAdiTrades()).list,
}));

export const fetchIncomingDashboard = createActionThunk(fetchIncomingDashboardAction, async () => ({
  incomingRfqs: await fetchIncoming(),
}));

export const fetchOutgoingDashboard = createActionThunk(fetchOutgoingDashboardAction, async () => ({
  outgoingRfqs: await fetchOutgoing(),
}));

export const fetchAnonymisedDashboard = createActionThunk(fetchAnonymisedDashboardAction, async (uuid) => ({
  anonymisedRfqQuotes: await fetchAnonymisedQuotes(uuid),
}));

export const dashboardReducer = {
  [fetchDashboard.STARTED]: (state) => ({
    ...state,
    error: null,
    isFetching: true,
  }),
  [fetchDashboard.SUCCEEDED]: (state, { payload: { incomingRfqs, outgoingRfqs, adiTrades } }) => ({
    ...state,
    initialized: true,
    incomingRfqs,
    outgoingRfqs,
    adiTrades,
    isFetching: false,
  }),
  [fetchDashboard.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [fetchDashboard.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    statusCode: null,
  }),
  [fetchAnonymisedDashboard.STARTED]: (state) => ({
    ...state,
    anonymised: { isFetching: true, didInvalidate: false },
  }),
  [fetchAnonymisedDashboard.SUCCEEDED]: (state, { payload: { anonymisedRfqQuotes } }) => ({
    ...state,
    anonymised: {
      isFetching: false,
      didInvalidate: false,
      rfqQuotes: anonymisedRfqQuotes,
    },
  }),
  [fetchAnonymisedDashboard.FAILED]: (state, { payload }) => ({
    ...state,
    anonymised: {
      isFetching: false,
      didInvalidate: true,
      error: payload,
    },
  }),
  [fetchAnonymisedDashboard.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    statusCode: null,
  }),
  [fetchIncomingDashboard.STARTED]: (state) => ({
    ...state,
    error: null,
    isFetching: true,
  }),
  [fetchIncomingDashboard.SUCCEEDED]: (state, { payload: { incomingRfqs } }) => ({
    ...state,
    initialized: true,
    incomingRfqs,
    isFetching: false,
  }),
  [fetchIncomingDashboard.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [fetchIncomingDashboard.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    statusCode: null,
  }),
  [fetchOutgoingDashboard.STARTED]: (state) => ({
    ...state,
    error: null,
    isFetching: true,
  }),
  [fetchOutgoingDashboard.SUCCEEDED]: (state, { payload: { outgoingRfqs } }) => ({
    ...state,
    initialized: true,
    outgoingRfqs,
    isFetching: false,
  }),
  [fetchOutgoingDashboard.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [fetchOutgoingDashboard.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    statusCode: null,
  }),
};
