import axios from 'axios';

export default async function(params) {
  const {
    data: { list: trades },
  } = await axios({
    method: 'get',
    url: '/trades',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return {
    trades,
  };
}
