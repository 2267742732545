import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { initialGeneralAllocationCode } from '../api/holding/codes';
import { getTradesSummary, getTradesSummaryUrl } from '../api/holding/summary';

const initialGeneralAllocationCodes = [initialGeneralAllocationCode];

export const useAllocationCodes = () => {
  const { data: summary } = useSWR(getTradesSummaryUrl, getTradesSummary);

  const [allocationCodes, setAllocationCodes] = useState(initialGeneralAllocationCodes);

  useEffect(() => {
    if (!summary?.allocationCodes?.length) {
      return;
    }

    setAllocationCodes(
      summary?.allocationCodes.map((allocation) => ({
        value: allocation.code,
        label: allocation.code,
      })),
    );
  }, [summary]);

  return {
    allocationCodes,
    setAllocationCodes,
  };
};
