import React from 'react';
import propTypes from 'prop-types';

import { SendBuyBondsRfqQuoteDrawer } from './buy-bonds-rfqs/send-buy-bond-rfq-quote/SendBuyBondsRfqQuoteDrawer';
import { SendSellBondsRfqQuoteDrawer } from './sell-bonds-rfqs/buyer/send-sell-bond-rfq-quote/SendSellBondsRfqQuoteDrawer';

export const BuySellBondRfqsSendQuotesDrawer = ({ rfq, onCloseDrawer, onQuoteUpdatedSuccess }) => {
  const Drawer = rfq && /buy/i.test(rfq.operation) ? SendBuyBondsRfqQuoteDrawer : SendSellBondsRfqQuoteDrawer;

  return (
    <Drawer rfqUUID={rfq && rfq.uuid} onQuoteUpdatedSuccess={onQuoteUpdatedSuccess} onCloseDrawer={onCloseDrawer} />
  );
};

BuySellBondRfqsSendQuotesDrawer.propTypes = {
  rfq: propTypes.shape({
    operation: propTypes.string,
    uuid: propTypes.string,
  }),
  onCloseDrawer: propTypes.func,
  onQuoteUpdatedSuccess: propTypes.func,
};
