export const UPDATE_PORTFOLIO_SELECTED_DATE = 'UPDATE_PORTFOLIO_SELECTED_DATE';
export const UPDATE_REFRESH_PORTFOLIO = 'UPDATE_REFRESH_PORTFOLIO';
export const TOGGLE_ADD_TRADE_SIDEBAR = 'TOGGLE_ADD_TRADE_SIDEBAR';

export const updateSelectedDate = (date) => ({ type: UPDATE_PORTFOLIO_SELECTED_DATE, date });

export const refreshPortfolio = ({ refresh, silentFetch }) => ({
  type: UPDATE_REFRESH_PORTFOLIO,
  refresh,
  silentFetch,
});

export const toggleAddTradeSidebar = () => ({ type: TOGGLE_ADD_TRADE_SIDEBAR });
