import LogRocket from 'logrocket';

import { env } from '../config';

const href = window.location.href.toLowerCase();
const blockedUrls = ['mebank.', '.qa.', '.local.'];

const shouldLog = !blockedUrls.some((url) => href.includes(url));

const responseSanitizer = (response) => {
  if (response.status < 400) {
    response.body = null;
  }

  return response;
};

const requestSanitizer = (request) => {
  request.body = null;

  if (request.headers.authorization) {
    request.headers.authorization = '';
  }

  if (request.headers.Authorization) {
    request.headers.Authorization = '';
  }

  return request;
};

if (shouldLog) {
  LogRocket.init(env.logRocketKey, {
    dom: {
      inputSanitizer: true,
    },
    network: {
      responseSanitizer,
      requestSanitizer,
    },
  });
}

export const eventAutoFillClicked = 'auto-fill-clicked';
