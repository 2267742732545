import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { Offer } from '../../Offer/Offer';
import { BankHeader } from '../../Offer/BankHeader';

import './reinvest-on-behalf-dialog.scss';

const buildBidDetails = ({ tradeSelected: { maturityDate, principal } }) => ({
  maturityDate,
  principal,
});

export const ReinvestOnBehalfDialog = ({ show, offerDetails, onConfirm, onHide, ratesheet, tradeSelected }) => (
  <Modal show={show} className="reinvest-on-behalf-dialog" onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>
        <FormattedMessage id="reinvestment" />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Offer
        bidDetails={buildBidDetails({ tradeSelected })}
        header={<BankHeader tradeSelected={tradeSelected} />}
        ratesheet={ratesheet}
        offerDetails={offerDetails}
        onConfirm={onConfirm}
      />
    </Modal.Body>
  </Modal>
);

ReinvestOnBehalfDialog.propTypes = {
  tradeSelected: PropTypes.shape().isRequired,
  offerDetails: PropTypes.shape().isRequired,
  ratesheet: PropTypes.shape().isRequired,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

ReinvestOnBehalfDialog.defaultProps = {
  show: false,
};
