import * as holdingActions from '../../../actions/holding/save';
import BaseFields from '../base/fields';

export const fields = ({ currencySymbol }) => [
  { ...BaseFields.allocation, halfSize: true },
  { ...BaseFields.purchaseDate, halfSize: true },
  {
    name: 'principal',
    label: 'customHoldingPrincipal',
    componentType: 'currency',
    intlValues: { currencySymbol },
  },
  {
    name: 'issuerId',
    label: 'holdingIssuer',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.termDepositIssuers,
  },
  {
    name: 'maturityDate',
    label: 'holdingMaturityDate',
    componentType: 'date',
    disableWeekends: false,
    disableHolidays: false,
  },
  {
    ...BaseFields.coupon,
  },
  {
    name: 'interestPaidCode',
    label: 'holdingPaid',
    componentType: 'select',
    options: holdingActions.codes.interests,
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
