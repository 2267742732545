import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-display-flex';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DateWithTimeZone, SummaryHighlight } from '../../components/common';
import { emptyCharacter, fullCouponFormat } from '../../format-numbers';

export const BondAndFrnHeader = ({ priceDate, yieldOrTradeMargin }) => (
  <Row className="dialog-header">
    <SummaryHighlight
      top={<FormattedMessage id="valuationDate" />}
      middle={priceDate ? <DateWithTimeZone value={priceDate} /> : emptyCharacter}
    />
    <SummaryHighlight
      top={<FormattedMessage id="yieldOrTradeMargin" />}
      middle={
        yieldOrTradeMargin ? <FormattedNumber {...fullCouponFormat} value={yieldOrTradeMargin} /> : emptyCharacter
      }
    />
  </Row>
);

BondAndFrnHeader.propTypes = {
  priceDate: PropTypes.string,
  yieldOrTradeMargin: PropTypes.number,
};
