import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import './skeleton-table.scss';

const renderRows = ({ rows, cols }) =>
  [...Array(rows)].map((_, rowIndex) => (
    // eslint-disable-next-line react/no-array-index-key
    <tr key={rowIndex}>
      {[...Array(cols)].map((__, colIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <td key={colIndex}>
          <div />
        </td>
      ))}
    </tr>
  ));

export const SkeletonTable = ({ children, isLoading, rows, cols }) =>
  isLoading ? (
    <Table className="skeleton-table" responsive striped hover>
      <tbody>{renderRows({ rows, cols })}</tbody>
    </Table>
  ) : (
    children
  );

SkeletonTable.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
};

SkeletonTable.defaultProps = {
  rows: 3,
  cols: 5,
};
