import { handleActions } from 'redux-actions';

import { dashboardReducer } from './list';

export { fetchDashboard, fetchAnonymisedDashboard, fetchIncomingDashboard, fetchOutgoingDashboard } from './list';

const initialState = {
  initialized: false,
  didInvalidate: false,
  isFetching: false,
  asOf: null,
  incomingRfqs: { instruments: [] },
  outgoingRfqs: { instruments: [] },
  adiTrades: [],
  anonymised: {
    rfqQuotes: {},
    isFetching: false,
    didInvalidate: false,
    error: null,
  },
  error: null,
};

const combinedReducers = {
  ...dashboardReducer,
};

export const dashboard = handleActions(combinedReducers, initialState);
