import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { fullCouponFormat } from '../../../../format-numbers';
import { Money } from '../../../money';
import { ResponseItem } from './ResponseItem';
import { DateWithTimeZone } from '../../../../date';
import { Column } from '../../../../components/common';
import { tradePropTypes } from './trade-prop-types';

export const SourceTradeDetails = ({ trade }) => {
  const { coupon, maturityDate } = trade;

  return (
    <Column data-testid="maturing-deal" className="response-container">
      <ResponseItem
        label={<FormattedMessage id="originalCoupon" />}
        value={<FormattedNumber {...fullCouponFormat} value={coupon} />}
      />
      <ResponseItem
        label={<FormattedMessage id="holdingMaturityDate" />}
        value={<DateWithTimeZone value={maturityDate} />}
      />
      <ResponseItem label={<FormattedMessage id="principal" />} value={<Money value={trade.principal} short />} />
    </Column>
  );
};

SourceTradeDetails.propTypes = { trade: tradePropTypes };
