import { difference, newDate } from '../../../../date';

export const getTenorByMaturityDate = ({ maturityDate, purchaseDate }) => {
  const tenorFromMaturityByPurchaseDate = Math.round(difference(newDate(maturityDate), newDate(purchaseDate)) / 30);

  if (tenorFromMaturityByPurchaseDate === 0) {
    return '1M';
  }

  if (tenorFromMaturityByPurchaseDate <= 12) {
    return `${tenorFromMaturityByPurchaseDate}M`;
  }

  if (tenorFromMaturityByPurchaseDate > 12 && tenorFromMaturityByPurchaseDate <= 15) {
    return '12M';
  }

  if (tenorFromMaturityByPurchaseDate > 15 && tenorFromMaturityByPurchaseDate <= 30) {
    return '2Y';
  }

  if (tenorFromMaturityByPurchaseDate > 30 && tenorFromMaturityByPurchaseDate <= 42) {
    return '3Y';
  }

  if (tenorFromMaturityByPurchaseDate > 42 && tenorFromMaturityByPurchaseDate <= 54) {
    return '4Y';
  }

  return '5Y';
};

export const sortMaturingTradesChartByTenor = (maturingTradesChartData) =>
  maturingTradesChartData.sort(
    ({ tenor: tenorLeft }, { tenor: tenorRight }) => allTenors.indexOf(tenorLeft) - allTenors.indexOf(tenorRight),
  );

export const allTenors = Array.from({ length: 12 })
  .map((_, index) => `${index + 1}M`)
  .concat(['2Y', '3Y', '4Y', '5Y']);
