import './offer.scss';

import React, { useContext, useEffect, useState } from 'react';

import propTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { setOfferDetails } from '../../actions/holding/rfq/rfq';
import { getUser } from '../../actions/session-selector';
import { TD } from '../../api/holding/instrument-codes';
import { redeem } from '../../api/holding/redeem';
import { details, update, updateOffer } from '../../api/offers';
import rooms from '../../api/socket/rooms';
import { getTenantSocket, socketEvents } from '../../api/socket/setup-socket';
import { ErrorByStatus, ErrorComponent } from '../../components/common';
import {
  withNavigate,
  withParams,
} from '../../components/hoc/with-router-properties';
import { setDefaultTheme, setTheme } from '../../components/theme';
import { isBeforeNow, newDate, sameDayAsToday } from '../../date';
import * as statuses from '../../ducks/issuer-trades/statuses';
import { useAxios } from '../../hooks/use-axios';
import { routes } from '../../routes';
import { AppContext } from '../app/AppContext';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../toast/toast';
import { AlternativeActionsModal } from './AlternativeActionsModal';
import { InvestorHeader } from './InvestorHeader';
import { Offer } from './Offer';
import { splitTenor } from './Tenors/TenorItem';

const openStatuses = [statuses.read, statuses.sent];

const isExpired = ({ id, status, expiresAt }) => id && openStatuses.includes(status) && isBeforeNow(expiresAt);

const isRatesheetUnavailable = ({ id, status, ratesheet }) => id && openStatuses.includes(status) && !ratesheet;

const operations = {
  accept: 'accept',
  reject: 'reject',
};

const setOfferTheme = ({ setLogo, offerDetails }) => {
  const bankSettings = offerDetails && offerDetails.bank && offerDetails.bank.settings;

  if (!bankSettings) {
    return;
  }

  setTheme(bankSettings);

  bankSettings.logo && setLogo(bankSettings.logo);
};

const getAcceptBidPayload = (bid) => {
  const { accruedInterest, additionalFunds, customMaturityDate, selectedOffer, maturityDate, interestPaid = 'Z' } = bid;
  const { unit, time, rate } = selectedOffer;

  const baseBidPayload = {
    customMaturityDate,
    maturityDate,
    interestPaid,
    unitOfTime: unit,
    amountOfTime: +time,
    rate,
  };

  if (accruedInterest > 0) {
    baseBidPayload.accrued = accruedInterest;
  }

  baseBidPayload.additionalFunds = additionalFunds;

  return baseBidPayload;
};

const buildBidDetails = ({ offerDetails }) => {
  const { principal, notionalPrincipal, instrumentCode, bids, maturityDate } = offerDetails;
  const offerDetailsToBid = {
    principal: instrumentCode === TD ? principal : notionalPrincipal,
  };

  if (offerDetails.status === statuses.accepted && bids) {
    const [{ tenor, rate, accrued, interestPaid, additionalFunds, maturityDate: selectedMaturityDate }] = bids;
    const { unit, time } = splitTenor(tenor);

    offerDetailsToBid.selectedOffer = {
      rate,
      tenor,
      unit,
      time,
      maturityDate: selectedMaturityDate,
    };

    offerDetailsToBid.additionalFunds = additionalFunds;
    offerDetailsToBid.accruedInterest = accrued;
    offerDetailsToBid.interestPaid = interestPaid;
  }

  return {
    ...offerDetailsToBid,
    maturityDate,
  };
};

export const InvestorOfferComponent = ({ params, setOfferDetailsInRfq, navigate, user, intl }) => {
  const { setIsLoading, setLogo } = useContext(AppContext);

  const [showAlternativeActionsModal, setShowAlternativeActionsModal] = useState(false);
  const socket = getTenantSocket();

  const { data: offerDetails, setData, error } = useAxios(() => details(params.id));

  useEffect(() => {
    setIsLoading(offerDetails.isLoading);
  }, [setIsLoading, offerDetails.isLoading]);

  const emitSocketUpdate = () =>
    socket.emit(`client:${socketEvents.offersUpdated}`, {
      tenants: [offerDetails.bank.tenantDomain],
      room: rooms.offers,
    });

  useEffect(() => {
    const updateStatus = async () => {
      if (!offerDetails.status || offerDetails.status !== statuses.sent) {
        return;
      }

      await update({ id: offerDetails.id, data: { status: statuses.read } });
      emitSocketUpdate();
    };

    updateStatus();
  }, [offerDetails]);

  useEffect(
    () => () => {
      setDefaultTheme();
      setLogo(undefined);
    },
    [],
  );

  useEffect(() => {
    if (!offerDetails) {
      return;
    }

    setOfferTheme({ setLogo, offerDetails });
  }, [setLogo, offerDetails]);

  if (error) {
    return <ErrorByStatus status={error.status} />;
  }

  if (isRatesheetUnavailable(offerDetails)) {
    return (
      <ErrorComponent
        message="noRatesheetAvailable"
        info="noRatesheetAvailableInfo"
        infoValues={{ sentBy: offerDetails.bank.sentBy }}
        showFooter={false}
      />
    );
  }

  if (isExpired(offerDetails)) {
    return (
      <ErrorComponent
        message="offerExpiredTitle"
        info="offerExpiredInfo"
        infoValues={{
          principal: offerDetails.principal,
          sentBy: offerDetails.bank.sentBy,
        }}
        showFooter={false}
      />
    );
  }

  const onConfirm = async ({ bid }) => {
    const acceptedBid = await updateOffer(params.id, {
      operation: operations.accept,
      payload: getAcceptBidPayload(bid),
    });

    emitSocketUpdate();

    setData({
      ...offerDetails,
      bids: [acceptedBid],
      status: statuses.accepted,
    });
  };

  const onAlternativeActionHandler = async ({ action, successMessage }) => {
    try {
      await action();

      showToastMessage(intl.formatMessage({ id: successMessage }), MessageType.SUCCESS);
      setShowAlternativeActionsModal(false);

      emitSocketUpdate();

      setData({ ...offerDetails, status: statuses.rejected });
    } catch (e) {
      showResponseErrorMessage({ intl, error: e });
    }
  };

  const onRedeemClick = async () =>
    onAlternativeActionHandler({
      action: () => redeem(offerDetails.tradeId, { maturityActionConfirmed: true }),
      successMessage: 'redeemSuccessfully',
    });

  const onRejectClick = async (payload) =>
    onAlternativeActionHandler({
      action: () => updateOffer(params.id, { operation: operations.reject, payload }),
      successMessage: 'rejectOfferSuccessfully',
    });

  const onStartAnRfq = async () => {
    setOfferDetailsInRfq({ offerDetails });

    navigate(routes.holdings.rfq.replace(':id', offerDetails.tradeId));
  };

  const getExtraActions = () => {
    const extraActions = [];
    const maturesToday = offerDetails.maturityDate && sameDayAsToday(newDate(offerDetails.maturityDate));
    const canStartRfq = user.isMultiBank && maturesToday;

    if (canStartRfq) {
      extraActions.push({
        buttonLabelId: 'startAnRfq',
        onClick: onStartAnRfq,
        confirmationMessageId: 'confirmStartRfq',
      });
    }

    if (maturesToday) {
      extraActions.push({
        buttonLabelId: 'redeem',
        onClick: onRedeemClick,
        confirmationMessageId: 'confirmRedeem',
      });
    }

    extraActions.push({
      buttonLabelId: 'doNotTakeOffer',
      onClick: () => setShowAlternativeActionsModal(true),
    });

    return extraActions;
  };

  return (
    <Offer
      className="investor-offer-container"
      header={<InvestorHeader offerDetails={offerDetails} isLoading={offerDetails.isLoading} />}
      bidDetails={buildBidDetails({ offerDetails })}
      offerDetails={offerDetails}
      ratesheet={offerDetails.ratesheet}
      isLoading={offerDetails.isLoading}
      onConfirm={onConfirm}
      extraActions={getExtraActions()}
      isInvestor
    >
      <AlternativeActionsModal
        show={showAlternativeActionsModal}
        onRejectClick={onRejectClick}
        onCancel={() => setShowAlternativeActionsModal(false)}
      />
    </Offer>
  );
};

InvestorOfferComponent.propTypes = {
  setOfferDetailsInRfq: propTypes.func.isRequired,
  user: propTypes.shape({
    isMultiBank: propTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = {
  setOfferDetailsInRfq: setOfferDetails,
};

export const InvestorOffer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withParams,
  withNavigate,
)(InvestorOfferComponent);
