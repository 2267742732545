import * as compliancesApiClient from '../../api/compliances';
import fetch from '../helper/fetch';

import { getDefaultDates } from '../../date';
import { buildFilter, validateAvailableFilters } from '../helper/filter';
export const FETCH_CREDIT = 'CREDIT';
export const UPDATE_CREDIT_QUALITY_FILTER = 'UPDATE_CREDIT_QUALITY_FILTER';
export const UPDATE_CREDIT_QUALITY_FILTERED_RATINGS = 'UPDATE_CREDIT_QUALITY_FILTERED_RATINGS';
export const UPDATE_CREDIT_QUALITY_PAGINATION = 'UPDATE_CREDIT_QUALITY_PAGINATION';
export const UPDATE_CREDIT_QUALITY_ROW_LIMIT = 'UPDATE_CREDIT_QUALITY_ROW_LIMIT';

const creditFetchAction = fetch(FETCH_CREDIT,
  shouldFetchCredit,
  async (params) => ({
    credit: await compliancesApiClient.credit({
      query: getDefaultDates(params && params.date),
      ...params,
    }),
    params,
  }),
);

export const creditFetchPayload = creditFetchAction.actionsTypes;

export function fetchCreditIfNeeded (params) {
  return creditFetchAction.fetchIfNeeded(params);
}

export function changePage (pagination) {
  return { type: UPDATE_CREDIT_QUALITY_PAGINATION, pagination };
}

export function changeRowLimit (limit) {
  const pagination = { limit, activePage: 1 };
  return { type: UPDATE_CREDIT_QUALITY_ROW_LIMIT, pagination };
}

export function updateFilter (filter) {
  return async (dispatch, getState) => {
    const { credit } = getState();

    const newFilter = buildFilter(filter, credit.filter);
    dispatch({ type: UPDATE_CREDIT_QUALITY_FILTER, newFilter });
  };
}

export function updateFilteredData (credit) {
  const filteredData = getFilteredRatings(credit, credit.filter);

  return ({ type: UPDATE_CREDIT_QUALITY_FILTERED_RATINGS, filteredData });
}

export function filterTerm (target, validFilter) {
  if (!validFilter) {
    return target.ratings;
  }

  return target.ratings.filter(rating => (
    Object.keys(validFilter).every(property => match(validFilter, rating, property))
  ));
}

export function resetFilter () {
  return async (dispatch, getState) => {
    const state = getState();

    const newFilter = {};

    if (state.credit.filter.date) {
      newFilter.date = state.credit.filter.date;
    }

    dispatch({ type: UPDATE_CREDIT_QUALITY_FILTER, newFilter });
  };
}

export const getFilteredRatings = ({ credit: { ratings }, availableFilters }, filter) => {
  const validFilter = {
    ...filter,
  };

  validateAvailableFilters(validFilter, availableFilters);

  const filteredData = ratings.filter(rating =>
    Object.keys(validFilter).every(property => (
      match(validFilter, rating, property)
    ))
  );

  return filteredData.map(rating => ({ ...rating }));
};

function match (filter, rating, property) {
  switch (property) {
    case 'searchText': {
      const searchTextRegex = new RegExp(filter[property], 'ig');
      return searchTextRegex.test(propertiesToMatchByText(rating));
    }
    case 'isCompliant':
      return rating[property] ? isTrueValue(filter[property]) : !isTrueValue(filter[property]);
    default:
      return rating[property] ? rating[property] === filter[property] : true;
  }
}

const isTrueValue = value => value && value === 'true';

const propertiesToMatchByText = issuer =>
  ['term', 'rating']
    .map(toMatch => issuer[toMatch]).join('|');

function shouldFetchCredit (params = {}, state) {
  if (state.credit.isFetching || state.credit.didInvalidate) {
    return false;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  return !state.credit.credit;
}

export function updateSelectedDate (baseDate) {
  return async (dispatch, getState) => {
    const state = getState();
    const filter = {
      query: getDefaultDates(baseDate),
      date: baseDate,
    };

    const newFilter = buildFilter(filter, state.credit.filter);

    dispatch({ type: UPDATE_CREDIT_QUALITY_FILTER, newFilter });

    creditFetchAction.fetchAsync(dispatch, state, filter);
  };
}
