/* global __REDUX_DEVTOOLS_EXTENSION__ */
import { applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';

import setupSocketIo from '../api/socket/setup-socket';
import { env } from '../config';
import { getTenantFromBaseUrl } from '../config/build-api-url';
import rootReducer from '../reducers';
import userStore from '../user-store';
import socketRedux from './middlewares/socket';

const baseUrl = window.location.hostname;
const maxRfqIssuerLimit = env.maxRfqIssuerLimit;

export default function configureStore() {
  const browserLocale = navigator.language || navigator.userLanguage;
  const token = userStore.getToken();
  const tenant = getTenantFromBaseUrl(baseUrl);
  const middlewareStore = setupStoreMiddleware({ socketServerUrl: env.socketServerUrl, baseUrl });

  const store = middlewareStore(rootReducer, {
    baseUrl,
    tenant,
    token,
    browserLocale,
    maxRfqIssuerLimit,
    rootPath: '/',
  });

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

function setupStoreMiddleware({ socketServerUrl, baseUrl }) {
  const socketIo = setupSocketIo(socketServerUrl, baseUrl);
  const socketMiddleware = socketRedux(socketIo);
  let storeMiddleMiddleware;

  const sharedMiddlewares = [thunkMiddleware, promiseMiddleware, socketMiddleware];

  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    storeMiddleMiddleware = compose(
      applyMiddleware(...sharedMiddlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    )(createStore);
  } else {
    storeMiddleMiddleware = applyMiddleware(...sharedMiddlewares)(createStore);
  }

  return storeMiddleMiddleware;
}
