import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tab } from 'react-bootstrap';

import './main-tabs.scss';

export const MainTabs = ({ children, secondary, activeKey, fixed }) => (
  <section className={classNames('light-tabs', { secondary, fixed })}>
    <Tab.Container id="main-pane-container" activeKey={activeKey} onSelect={() => {}}>
      <React.Fragment>{children}</React.Fragment>
    </Tab.Container>
  </section>
);

MainTabs.propTypes = {
  children: PropTypes.node,
  activeKey: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
};

MainTabs.defaultProps = {
  secondary: false,
  fixed: false,
};
