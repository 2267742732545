import './input-search.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormControl, FormGroup, Glyphicon, InputGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Loader } from 'react-loaders';

class InputSearch extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    defaultValue: PropTypes.string,
    isLoading: PropTypes.bool,
    shouldShowLoadingOnType: PropTypes.func,
    validateOnType: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || '',
      isLoading: false,
      hasError: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isLoading, defaultValue: value } = nextProps;

    if (isLoading !== this.state.isLoading) {
      this.setState({ isLoading });
    } else if (value !== this.state.value) {
      this.setState({ value });
    }
  }

  onClear = () => {
    this.setState({ value: '', hasError: false });
    this.props.onClear();
  };

  handleChange = (e) => {
    const { shouldShowLoadingOnType, validateOnType } = this.props;
    const isLoading = shouldShowLoadingOnType && shouldShowLoadingOnType(e.target.value);
    const hasError = validateOnType && validateOnType(e.target.value);

    this.setState({ value: e.target.value, isLoading, hasError });
    this.props.onChange(e);
  };

  renderAddon() {
    const { value } = this.state;
    if (this.state.isLoading) {
      return <Loader size="sm" type="ball-scale-ripple" />;
    } else if (value && value.length > 0) {
      return <Glyphicon onClick={this.onClear} glyph="remove" />;
    }

    return <Glyphicon glyph="search" />;
  }

  render() {
    return (
      <FormGroup className="input-search" validationState={this.state.hasError ? 'error' : null}>
        {this.props.labelId && (
          <p>
            <FormattedMessage id={this.props.labelId} />
          </p>
        )}
        <InputGroup>
          <FormControl
            type="text"
            value={this.state.value || ''}
            onChange={this.handleChange}
            placeholder={this.props.intl.formatMessage({ id: 'search' })}
          />
          <InputGroup.Addon>{this.renderAddon()}</InputGroup.Addon>
        </InputGroup>
      </FormGroup>
    );
  }
}

export default injectIntl(InputSearch);
