import './password-field.scss';

import React, { useState } from 'react';

import { Field } from 'formik';
import { Glyphicon } from 'react-bootstrap';

export const PasswordField = (props) => {
  const [showContent, setShowContent] = useState(false);
  const toggleVisibility = () => setShowContent(!showContent);

  return (
    <div className="password-field">
      <Field {...props} type={showContent ? 'text' : 'password'} />
      <button tabIndex="-1" type="button" onClick={toggleVisibility}>
        <Glyphicon glyph={showContent ? 'eye-close' : 'eye-open'} />
      </button>
    </div>
  );
};
