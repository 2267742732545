export const dateTimeProps = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const dateProps = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const timeProps = {
  hour: 'numeric',
  minute: 'numeric',
};
