import '../default-row.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { isBondOrFrn } from '../../../api/holding/instrument-codes';
import { Money } from '../../../containers/money';
import { DateWithTimeZone } from '../../../date';
import { emptyCharacter } from '../../../format-numbers';
import Tooltip from '../../common/tooltip';

const Row = ({
  assetClass,
  customer,
  customerCode,
  createdBy,
  createdByInitials,
  createdByEmail,
  status,
  cancelOption,
  principal,
  actions,
  currency,
  canConfirmRfq,
  settlementDate,
  operation,
}) => {
  const isPending = status === 'pending';
  const presenterStatus = status === 'cancelled' && cancelOption ? cancelOption : status;
  const statusId = `dashboard.status.${presenterStatus}`;

  return (
    <tr className={isPending ? 'pending-row' : 'default-row'}>
      <td>
        <div className={`status-bullet ${status}`} />
      </td>
      <td>
        <FormattedMessage id={statusId} />
      </td>
      <td>{assetClass}</td>
      <td>{operation ? <FormattedMessage id={`${operation}OperationCounterparty`} /> : emptyCharacter}</td>
      <td>{customer}</td>
      <td>
        <Tooltip
          id="created-by"
          tooltipComponent={<FormattedMessage id="rfqCreatedBy" values={{ createdBy, createdByEmail }} />}
        >
          <a target="new" className="white-link" title={createdBy} href={`mailto:${createdByEmail}`}>
            {createdByInitials}
          </a>
        </Tooltip>
      </td>
      <td>{currency}</td>
      <td>
        <Money value={principal} />
      </td>
      <td>
        <DateWithTimeZone value={settlementDate} />
      </td>
      <td className="action-cell">{renderActionButton({ canConfirmRfq, isPending, actions, status, assetClass })}</td>
    </tr>
  );
};

const getActionButtonMessageId = ({ isPending, canConfirmRfq, status, assetClass }) => {
  if (canConfirmRfq) return 'dashboard.action.confirm';

  if (status === 'ongoing' && isBondOrFrn(assetClass)) return 'dashboard.action.edit';

  return isPending ? 'dashboard.action.quote' : 'dashboard.action.view';
};

const renderActionButton = ({ canConfirmRfq, isPending, actions, status, assetClass }) => (
  <Button
    className={`action-button ${isPending ? 'pending-action-button' : 'default-action-button'}`}
    onClick={() => (isPending ? actions.quote() : actions.view())}
  >
    <FormattedMessage id={getActionButtonMessageId({ isPending, canConfirmRfq, status, assetClass })} />
  </Button>
);

Row.propTypes = {
  customer: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  createdByEmail: PropTypes.string.isRequired,
  createdByInitials: PropTypes.string.isRequired,
  operation: PropTypes.string,
  customerCode: PropTypes.string,
  status: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
  cancelOption: PropTypes.string,
  principal: PropTypes.number.isRequired,
  actions: PropTypes.shape().isRequired,
  currency: PropTypes.string.isRequired,
  canConfirmRfq: PropTypes.bool.isRequired,
  settlementDate: PropTypes.string.isRequired,
};

export default Row;
