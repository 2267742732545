import './index.scss';

import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as sessionActions from '../../actions/session';
import * as chartsPresenter from '../../components/allocation/chart-payload';
import * as recordsPresenter from '../../components/allocation/records-presenter';
import { ChartsPresenter, ResultsPresenter } from '../../components/common';
import errorHandler from '../../components/hoc/error-handler';

function mapStateToProps(state) {
  return {
    assets: state.assets,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      session: bindActionCreators(sessionActions, dispatch),
    },
  };
}

export class Assets extends Component {
  componentDidMount() {
    this.updateLocationQueryOnMount();
  }

  componentDidUpdate(nextProps) {
    this.updateLocationQuery(nextProps);
  }

  isNextFilterDifferent = ({ assets: { filter } }, { assets: { filter: nextPropsFilter } }) =>
    nextPropsFilter && !isEqual(filter, nextPropsFilter);

  updateLocationQueryOnMount() {
    const { assets } = this.props;

    if (!assets.filter || !Object.keys(assets.filter).length) {
      return;
    }

    this.props.actions.session.updateLocationQuery(assets.filter);
  }

  updateLocationQuery(nextProps) {
    if (!this.isNextFilterDifferent(this.props, nextProps)) {
      return;
    }

    this.props.actions.session.updateLocationQuery(nextProps.assets.filter);
  }

  renderCharts = ({ assets, isFetching }) => {
    const payload = {
      charts: [
        {
          id: 'assets-chart',
          titleId: 'assetsChartTitle',
          isFetching,
          data: assets && assets.instrumentCodes.slice().reverse(),
          type: 'horizontal-bar',
          ...chartsPresenter,
        },
      ],
    };

    return <ChartsPresenter {...payload} />;
  };

  renderRecords = ({ assets, isFetching }) => {
    const payload = {
      isFetching,
      noRecords: !assets || !assets.instrumentCodes.length,
      recordsPresenter: {
        data: assets && assets.instrumentCodes,
        ...recordsPresenter,
      },
      titleId: 'assets.resultsTitle',
    };

    return <ResultsPresenter {...payload} />;
  };

  render() {
    const { assets } = this.props;

    return (
      <Row className="assets-list">
        <Col xs={6}>{this.renderCharts(assets)}</Col>
        <Col xs={6}>{this.renderRecords(assets)}</Col>
      </Row>
    );
  }
}

Assets.propTypes = {
  actions: PropTypes.shape().isRequired,
  assets: PropTypes.shape().isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.assets.error,
  })),
)(Assets);
