import './tabs.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import useSWR from 'swr';

import { OutgoingRfqs, OutgoingRfqsHistory } from '../../';
import {
  buildListOutgoingSecuritiesRfqsUrl,
  listOutgoingSecuritiesRfqs,
} from '../../../api/holding/rfq/list-outgoing-securities-rfqs';
import { Protect, PulseBlob, roles } from '../../../components/common';
import { routes } from '../../../routes';
import { RedemptionsReport } from '../../reports/RedemptionsReports/RedemptionsReport';
import { OutgoingSecuritiesRfqs } from '../../rfqs/buy-sell-bond-rfqs/outgoing-securities-rfqs';
import { hasOpenOutgoingSecuritiesRfq } from '../../rfqs/buy-sell-bond-rfqs/outgoing-securities-rfqs/has-open-outgoing-securities-rfq';
import {
  MaturingTodayTotalizer,
  RfqsClosedTotalizer,
  RfqsConfirmedTotalizer,
  RfqsInProgressTotalizer,
} from '../Totalizers';
import { ContainerTab } from './ContainerTab';

const tabKey = 'rfqs';

const currentKey = 'current';
const securitiesKey = 'securities';
const historyKey = 'history';
const redemptions = 'redemptions';

const RfqsTabComponent = ({
  maturing,
  quotes,
  outgoingRfqsReport,
  onSecondaryTabChange,
  session: { user },
  tenantDomain,
  routeBase = routes.portfolioRoot.base,
}) => {
  const { data: securitiesData } = useSWR(buildListOutgoingSecuritiesRfqsUrl(), listOutgoingSecuritiesRfqs);

  const securitiesTab = {
    key: securitiesKey,
    component: (
      <React.Fragment>
        <FormattedMessage tagName="span" id="securities" />
        {hasOpenOutgoingSecuritiesRfq(securitiesData) && <PulseBlob />}
      </React.Fragment>
    ),
    href: 'securities',
    requiredTo: roles.finance,
    user,
  };

  const tabs = [
    {
      isLoading: maturing.isFetching || quotes.isFetching,
      key: currentKey,
      component: <FormattedMessage tagName="span" id="current" />,
      href: 'current',
      requiredTo: roles.finance,
      user,
    },
    securitiesTab,
    {
      isLoading: outgoingRfqsReport.isFetching,
      key: historyKey,
      component: <FormattedMessage tagName="span" id="history" />,
      href: 'history',
      requiredTo: roles.viewer,
      user,
    },
    {
      key: redemptions,
      href: redemptions,
      component: <FormattedMessage tagName="span" id="redemptions" />,
    },
  ];

  return (
    <ContainerTab
      tabKey={tabKey}
      routeBase={routeBase}
      summary={
        <React.Fragment>
          <MaturingTodayTotalizer highlightIcon hideButton showInvestmentsNumber />
          <RfqsInProgressTotalizer />
          <RfqsConfirmedTotalizer />
          <RfqsClosedTotalizer />
        </React.Fragment>
      }
      tabs={tabs}
      onLocationUpdate={onSecondaryTabChange}
    >
      <Tab.Pane key={currentKey} eventKey={currentKey} mountOnEnter>
        <Protect user={user} requiredTo={roles.finance}>
          <OutgoingRfqs />
        </Protect>
      </Tab.Pane>
      <Tab.Pane key={securitiesKey} eventKey={securitiesKey} mountOnEnter>
        <Protect user={user} requiredTo={roles.finance}>
          <OutgoingSecuritiesRfqs tenantDomain={tenantDomain} currency={user.currency} />
        </Protect>
      </Tab.Pane>
      <Tab.Pane key={historyKey} eventKey={historyKey} mountOnEnter>
        <Protect user={user} requiredTo={roles.viewer}>
          <OutgoingRfqsHistory />
        </Protect>
      </Tab.Pane>
      <Tab.Pane key={redemptions} eventKey={redemptions} mountOnEnter>
        <RedemptionsReport />
      </Tab.Pane>
    </ContainerTab>
  );
};

RfqsTabComponent.propTypes = {
  maturing: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  tenantDomain: PropTypes.string.isRequired,
  outgoingRfqsReport: PropTypes.shape().isRequired,
  onSecondaryTabChange: PropTypes.func,
  routeBase: PropTypes.string,
};

RfqsTabComponent.defaultProps = {
  onSecondaryTabChange: () => {},
};

const mapStateToProps = (state) => ({
  maturing: state.maturing,
  quotes: state.quotes,
  session: state.session,
  tenantDomain: state.tenant,
  outgoingRfqsReport: state.outgoingRfqsReport,
});

export const RfqsTab = connect(mapStateToProps)(RfqsTabComponent);

export default RfqsTab;
