import * as date from '../../date';

export default function ({ trades, maturityCompliances, filterDate }) {
  const totalAmountInvested = getTotalAmountInvested(trades);
  const tradesCount = trades.length;
  const maturityLimits = calculateMaturityLimits(trades, maturityCompliances, totalAmountInvested, filterDate);
  const isCompliant = maturityLimits.every(item => item.isCompliant);
  const maturityCompliance = {
    isCompliant,
    amountInvested: totalAmountInvested,
    tradesCount,
    maturityLimits,
  };

  maturityCompliance.maturityLimits.sort((a, b) => a.displayOrder > b.displayOrder);

  return maturityCompliance;
}

function getTotalAmountInvested (trades) {
  if (!trades || !trades.length) {
    return 0;
  }

  return trades.map(trade => trade.principal).reduce((prev, curr) => prev + curr);
}

function getOffLimitValue (totalAmountInvested, amountInvested, min, max) {
  const highLimit = (max / 100) * totalAmountInvested;
  const lowLimit = (min / 100) * totalAmountInvested;
  const invested = amountInvested;

  if (invested > highLimit) {
    return invested - highLimit;
  }

  if (invested < lowLimit) {
    return invested - lowLimit;
  }

  return 0;
}

function calculateMaturityLimits (trades, maturityRules, totalAmountInvested, filterDate) {
  const adjustedTrades = prepareTrades(trades, filterDate);

  const maturityLimits = maturityRules.map(rule => {
    const currentRuleTrades = getTradesByRule(adjustedTrades, rule, filterDate);
    const amountInvested = +(currentRuleTrades.reduce((total, trade) => total + trade.principal, 0.00));
    const investmentSharePct = totalAmountInvested === 0 ? 0 : (amountInvested / totalAmountInvested) * 100;
    const amountAvailable = ((rule.maxLimit / 100) * totalAmountInvested) - amountInvested;
    const isCompliant = investmentSharePct >= rule.minLimit && investmentSharePct <= rule.maxLimit;
    const tradesCount = currentRuleTrades.length;
    const offLimit = getOffLimitValue(totalAmountInvested, amountInvested, rule.minLimit, rule.maxLimit);

    return {
      amountAvailable,
      amountInvested,
      currentRuleTrades: currentRuleTrades.map(ruleTrade => ruleTrade.id),
      displayOrder: rule.displayOrder,
      investmentSharePct,
      isCompliant,
      label: rule.displayTextKey,
      maxLimitPct: rule.maxLimit,
      minLimitPct: rule.minLimit,
      offLimit,
      tradesCount,
    };
  });

  return maturityLimits;
}

function prepareTrades (trades, filterDate) {
  const adjustedTrades = trades.map(trade => {
    if (trade.instrumentCode === 'CASH') {
      const maturityDate = addDays(1, filterDate);

      return { ...trade, maturityDate };
    }

    return trade;
  });

  return adjustedTrades;
}

function getTradesByRule (trades, rule, filterDate) {
  return trades.filter(trade => {
    const startDate = date.newDate(addDays(rule.maturityDaysFrom, date.startOfDay(filterDate)));
    const endDate = date.newDate(addDays(rule.maturityDaysTo, date.endOfDay(filterDate)));
    const maturityDate = date.newDate(trade.maturityDate);
    return maturityDate.isSameOrAfter(startDate) && maturityDate.isSameOrBefore(endDate);
  });
}

const addDays = (days, rawDate) => date.newDate(rawDate || date.today()).add(days, 'days').format('YYYY-MM-DD');
