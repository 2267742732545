import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { ConfirmationPopover, Loadable } from '../';
import { percentual } from '../../../format-numbers';

export const columns = {
  issuer: { label: 'holdingIssuer', sort: 'issuerName' },
  rating: { label: 'holdingRating' },
  principal: { label: 'principal', sort: 'principal' },
  paid: { label: 'holdingPaid', sort: 'interestPaidCode' },
  coupon: { label: 'holdingCoupon', sort: 'coupon' },
  allocation: { label: 'holdingAllocation', sort: 'allocationCode' },
  actions: { label: null },
};

export const presenter = (data, index, actions, options) => (
  <tr key={index}>
    <td className="td-2-space">{data.issuer.name}</td>
    <td className="td-1-space">{String(data.rating).toUpperCase() === 'Z' ? 'Unrated' : data.rating}</td>
    <td className="td-1-space">
      <FormattedNumber {...percentual} value={data.principal} />
    </td>
    <td>
      <FormattedMessage tagName="span" id={`interestDescription.${data.interestPaidCode}`} />
    </td>
    <td className="td-1-space">
      <FormattedNumber {...percentual} value={data.coupon} />
    </td>
    <td className="allocation-code-column td-1-space">{data.allocationCode && data.allocationCode.toUpperCase()}</td>
    <td className="actions">
      <Loadable skeleton isLoading={data.isConfirming} size="sm">
        {renderActionContent(actions, data, options)}
      </Loadable>
    </td>
  </tr>
);

function renderActionContent(actions, data, options) {
  const isClosed = data.rfqStatus && data.rfqStatus === 'closed';
  const neutral = isClosed || data.quotesReceived === 0;
  const classes = classNames('btn-table', { neutral });

  if (data.maturityAction === 'redeemed') {
    return null;
  }

  if (!['CP', 'ECD', 'TD'].includes(data.instrumentCode)) {
    return <span>-</span>;
  }

  if (data.rfqStatus || isClosed) {
    return (
      <Row justifyContentEnd>
        <Button
          onClick={() => actions.viewReceivedQuotes(data)}
          className={classNames(classes, 'large', { secondary: neutral })}
        >
          <FormattedMessage tagName="span" {...formattedActionsParameters(data)} />
        </Button>
      </Row>
    );
  }

  return (
    <Row justifyContentEnd>
      <ConfirmationPopover
        buttonLabelId="redeem"
        confirmationMessageId="areYouSure"
        onConfirm={() => actions.redeem(data)}
        className={classNames(classes)}
        popoverClass="light"
      />
    </Row>
  );
}

function formattedActionsParameters(data) {
  const status = data.rfqStatus;

  return status === 'closed'
    ? { id: 'rfqDealt' }
    : { id: 'rfqQuotesReceived', values: { quotes: data.quotesReceived } };
}
