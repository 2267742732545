import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { usePrompt } from './use-blocker';

export const withLocation = (Component) => (props) => <Component {...props} location={useLocation()} />;

export const withNavigate = (Component) => (props) => <Component {...props} navigate={useNavigate()} />;

export const withParams = (Component) => (props) => <Component {...props} params={useParams()} />;

export const withSearchParams = (Component) => (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Component
      {...props}
      searchParams={searchParams}
      searchParamsEntries={Object.fromEntries([...searchParams])}
      setSearchParams={setSearchParams}
    />
  );
};

export const withNavigationPromptBlocker = (Component) => (props) =>
  <Component {...props} showLeavingPrompt={usePrompt} />;
