import './bank-dashboard.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { hasLicences } from '../../../actions/session-selector';
import { licences, Protect, roles, Tabs } from '../../../components/common';
import { withNavigate, withParams } from '../../../components/hoc/with-router-properties';
import { getDashboardSummary } from '../../../ducks/dashboard/selectors';
import { LicencedTradeHistory } from '../../Incoming/LicencedTradeHistory';
import { BlotterRfqsTab } from '../../Incoming/tabs/BlotterRfqsTab';
import { RfqsDashboardPanel } from '../RfqsDashboardPanel';
import { MaturingTrades } from './MaturingTrades';
import { UpdateRatesheetButton } from './SendQuotes/UpdateRatesheet';
import { UnsolicitedRatesheet } from './UnsolicitedRatesheet/UnsolicitedRatesheet';

const mapStateToProps = (state) => ({
  issuerTrades: state.issuerTrades,
  dashboardSummary: getDashboardSummary(state),
  user: state.session.user,
  hasUnsolicitedRatesheetLicence: hasLicences(licences.unsolicitedRatesheet)(state),
});

const rfqsKey = 'rfqs';
const maturingKey = 'maturing';
const historyKey = 'history';
const unsolicitedRatesheetKey = 'unsolicited-ratesheets';

export class BankDashboardComponent extends Component {
  constructor(props) {
    super(props);

    const { params } = props;

    this.state = {
      defaultKey:
        params && params.tab && [rfqsKey, maturingKey, historyKey, unsolicitedRatesheetKey].includes(params.tab)
          ? params.tab
          : rfqsKey,
    };
  }

  buildTab = ({ tabLabelId, count }) => (
    <React.Fragment>
      <FormattedMessage tagName="span" id={tabLabelId} />
      <span className="tag">{count > 0 && <span>{count}</span>}</span>
    </React.Fragment>
  );

  buildTabs = () => {
    const { dashboardSummary, hasUnsolicitedRatesheetLicence } = this.props;

    const tabs = [
      {
        key: rfqsKey,
        component: this.buildTab({
          tabLabelId: 'rfqs',
          count: dashboardSummary && dashboardSummary.rfqs.pending.count,
        }),
        href: 'rfqs',
      },
      {
        key: maturingKey,
        component: this.buildTab({
          tabLabelId: 'maturing',
          count: dashboardSummary && dashboardSummary.issuerTrades && dashboardSummary.issuerTrades.counters.unactioned,
        }),
        href: 'maturing',
      },
      {
        key: historyKey,
        component: this.buildTab({
          tabLabelId: 'history',
        }),
        href: 'history',
      },
    ];

    if (hasUnsolicitedRatesheetLicence) {
      tabs.splice(tabs.length - 1, 0, {
        key: unsolicitedRatesheetKey,
        component: this.buildTab({
          tabLabelId: 'unsolicitedRatesheet',
        }),
        href: 'unsolicited-ratesheet',
      });
    }

    return tabs;
  };

  updateLocation = (key) => {
    const { navigate } = this.props;

    navigate(
      {
        pathname: `/dashboard/${key}`,
      },
      { replace: true },
    );
  };

  render() {
    return (
      <section className="bank-dashboard-container">
        <Column>
          <Tab.Container id="rfq-dashboard-pane-container" defaultActiveKey={this.state.defaultKey}>
            <React.Fragment>
              <Row justifyContentSpaceBetween alignItemsCenter className="dashboard-container-header">
                <Tabs items={this.buildTabs()} onSelect={this.updateLocation} />
                <UpdateRatesheetButton tooltipPlacement="bottom" />
              </Row>
              <Row>
                <Tab.Content animation>
                  <Tab.Pane key="rfq-dashboard-pane" eventKey={rfqsKey}>
                    <Column>
                      <RfqsDashboardPanel />
                    </Column>
                  </Tab.Pane>
                  <Tab.Pane key="maturing" eventKey={maturingKey}>
                    <MaturingTrades />
                  </Tab.Pane>
                  <Protect user={this.props.user} requiredTo={roles.finance} licence={licences.unsolicitedRatesheet}>
                    <Tab.Pane key="unsolicited-ratesheet" eventKey={unsolicitedRatesheetKey}>
                      <UnsolicitedRatesheet />
                    </Tab.Pane>
                  </Protect>
                  <Tab.Pane key="history" eventKey={historyKey}>
                    <Protect
                      user={this.props.user}
                      requiredTo={roles.viewer}
                      licence={licences.rfqReporting}
                      errorComponent={<LicencedTradeHistory />}
                    >
                      <BlotterRfqsTab />
                    </Protect>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </React.Fragment>
          </Tab.Container>
        </Column>
      </section>
    );
  }
}

BankDashboardComponent.propTypes = {
  issuerTrades: PropTypes.shape().isRequired,
  dashboardSummary: PropTypes.shape().isRequired,
  user: PropTypes.shape(),
  hasUnsolicitedRatesheetLicence: PropTypes.bool,
};

export const BankDashboard = compose(connect(mapStateToProps), withParams, withNavigate)(BankDashboardComponent);
