import '../default-row.scss';
import './row.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Money } from '../../../containers/money';
import { DateWithTimeZone } from '../../../date';
import { emptyCharacter } from '../../../format-numbers';

const Row = ({
  assetClass,
  status,
  cancelOption,
  confirmed,
  principal,
  issuer,
  quotesReceived,
  quotesSent,
  actions,
  currency,
  settlementDate,
  showSettlementDate,
  operation,
}) => {
  const isPending = status === 'pending';
  const presenterStatus = status === 'cancelled' && cancelOption ? cancelOption : status;
  const statusId = `dashboard.status.${presenterStatus}`;

  return (
    <tr className={isPending ? 'pending-row' : 'default-row'}>
      <td>
        <div className={`status-bullet ${status}`} />
      </td>
      <td>
        <FormattedMessage id={statusId} />
        {status === 'closed' && !confirmed && (
          <span title="Trade not confirmed" className="not-confirmed-tag">
            <FormattedMessage id="dashboard.tag.notConfirmed" />
          </span>
        )}
      </td>
      <td>{assetClass}</td>
      <td>{operation ? <FormattedMessage id={`${operation}OperationCounterparty`} /> : emptyCharacter}</td>
      <td>{(issuer && issuer.name) || <FormattedMessage id="dashboard.new" />}</td>
      <td>{currency}</td>
      <td>
        <Money value={principal} />
      </td>
      {showSettlementDate && (
        <td>
          <DateWithTimeZone value={settlementDate} />
        </td>
      )}
      <td>{quotesSent}</td>
      <td>{quotesReceived}</td>
      <td className="action-cell">{renderActionButton(isPending, actions)}</td>
    </tr>
  );
};

const renderActionButton = (isPending, actions) => (
  <Button
    className={`action-button ${isPending ? 'pending-action-button' : 'default-action-button'}`}
    onClick={() => actions.view()}
  >
    <FormattedMessage id={isPending ? 'dashboard.action.selectQuote' : 'dashboard.action.view'} />
  </Button>
);

Row.propTypes = {
  actions: PropTypes.shape().isRequired,
  confirmed: PropTypes.bool.isRequired,
  issuer: PropTypes.shape({
    name: PropTypes.string,
    domain: PropTypes.string,
  }),
  principal: PropTypes.number.isRequired,
  quotesReceived: PropTypes.number.isRequired,
  quotesSent: PropTypes.number.isRequired,
  assetClass: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  cancelOption: PropTypes.string,
  currency: PropTypes.string.isRequired,
  settlementDate: PropTypes.string.isRequired,
  showSettlementDate: PropTypes.bool.isRequired,
  operation: PropTypes.string,
};

export default Row;
