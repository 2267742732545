import React from 'react';

import classNames from 'classnames';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '../../../../../../components/common';
import { Money } from '../../../../../money';

export const columns = ({ currencySymbol, isSelectAllChecked }) => ({
  select: { label: null, selectAll: true, isSelectAllChecked },
  customer: { label: 'dashboard.customer', sort: 'customerTenant.name' },
  customerType: { label: 'customerType' },
  segment: { label: 'segmentCode' },
  reinvested: { label: 'reinvested', className: 'text-right' },
  status: { label: 'status' },
  actions: { label: null },
});

const buildStatusColumn = (unsolicitedRatesheetCustomer) => {
  let status = {
    id: 'nothing-done',
  };

  if (unsolicitedRatesheetCustomer.trades) {
    status.id = 'unsolicited-ratesheet-status-created-trades';
    status.values = {
      count: unsolicitedRatesheetCustomer.trades.length,
    };
  }

  return (
    <span data-testid="status" className={classNames('unsolicited-ratesheet-status', status.id)}>
      <FormattedMessage tagName="span" {...status} />
    </span>
  );
};

export const presenter = (data, index, actions, { selectedCustomers, isLoadingTrades }) => {
  return (
    <tr
      onDoubleClick={() => {
        actions.onDetailsClick(data);
      }}
      key={index}
      className={classNames({ 'is-loading-trades': isLoadingTrades })}
    >
      <td className="select-checkbox">
        <Checkbox
          name={data.investorTenantId}
          checked={selectedCustomers.includes(data.investorTenantId)}
          onChange={actions.onCustomerCheckboxChange}
        />
      </td>
      <td>{data.name}</td>
      <td>{data.customerType && <FormattedMessage id={data.customerType} />}</td>
      <td>{data.segmentCode}</td>
      <td className="text-right">
        <Money value={data.trades?.reduce((reinvested, { principal }) => reinvested + principal, 0)} />
      </td>
      <td>{buildStatusColumn(data)}</td>
      <td className="actions">
        <DropdownButton
          id={`unsolicited-ratesheet-action-${index}`}
          pullRight
          noCaret
          data-testid="unsolicited-ratesheet-dropdown-action"
          title={<FaEllipsisV />}
        >
          <MenuItem onClick={() => actions.onDetailsClick(data)} data-testid="reinvest-button" eventkey={1}>
            <FormattedMessage tagName="span" id="details" />
          </MenuItem>
          <MenuItem
            disabled={selectedCustomers.length > 1}
            onClick={() => actions.onContextSendBespokeRatesheets(data)}
            data-testid="reinvest-button"
            eventkey={1}
          >
            <FormattedMessage tagName="span" id="sendBespokeRatesheets" />
          </MenuItem>
        </DropdownButton>
      </td>
    </tr>
  );
};
