import PropTypes from 'prop-types';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { TenorItem } from './TenorItem';
import { TenorPercentageItem } from './TenorPercentageItem';

export const EditableTenorItem = ({ className, defaultRate, rate, tenor, isCustom, onClick, onChange }) => {
  const [changedRate, setRate] = useState(rate !== null ? rate.toFixed(2) : rate);
  const [disabled, setIsDisabled] = useState(rate === null);

  useEffect(() => {
    setRate(rate);
  }, [rate]);

  return (
    <TenorItem
      className={cx({ 'is-dirty': !disabled && isCustom }, className)}
      tenor={tenor}
      rate={rate}
      onClick={onClick}
    >
      <i
        role="presentation"
        className={`icon disable-icon fa fa-eye${disabled ? '-slash' : ''}`}
        onClick={() => {
          if (disabled) {
            onChange({ tenor, rate: defaultRate });
          }

          setIsDisabled(!disabled);
        }}
      />
      <TenorPercentageItem
        disabled={disabled}
        onChange={onChange}
        changedRate={changedRate}
        setRate={setRate}
        tenor={tenor}
        rate={rate}
      />
    </TenorItem>
  );
};

EditableTenorItem.propTypes = {
  className: PropTypes.string,
  tenor: PropTypes.string.isRequired,
  rate: PropTypes.number,
  defaultRate: PropTypes.number,
  isCustom: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

EditableTenorItem.defaultProps = {
  className: '',
};
