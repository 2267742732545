import './table.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  EmptyContent,
  SkeletonTable,
} from '../../../common';
import Row from './row';

const Table = ({ rows, isLoading, includeEmbeddedFee }) => {
  if (!isLoading && !rows.length) {
    return <EmptyContent messageId="reports.rfqsHistory.noData" />;
  }

  return (
    <SkeletonTable isLoading={isLoading}>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="reports.rfqsHistory.source" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.customer" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.assetClass" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.operation" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.date" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.settlementDate" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.maturity" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.termDays" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.status" />
              </th>
              <th>
                <FormattedMessage id="currency" />
              </th>
              <th>
                <FormattedMessage id="isin" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.principal" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.quotedBy" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.coupon" />
              </th>
              {includeEmbeddedFee && (
                <th>
                  <FormattedMessage id="reports.rfqsHistory.quoteSent" />
                </th>
              )}
              {includeEmbeddedFee && (
                <th>
                  <FormattedMessage id="reports.rfqsHistory.basisPointFee" />
                </th>
              )}
              <th>
                <FormattedMessage id="reports.rfqsHistory.winningCoupon" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.nextBestCoupon" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.margin" />
              </th>
              <th>
                <FormattedMessage id="reports.rfqsHistory.viewQuotes" />
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </SkeletonTable>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(Row).isRequired,
  isLoading: PropTypes.bool.isRequired,
  includeEmbeddedFee: PropTypes.bool.isRequired,
};

export default Table;
