import './form.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Header, Panel } from '..';

const FormWrapper = ({ title, children, customClass }) => (
  <div className={classnames('form-section', customClass)}>
    {title && <Header titleId={title} />}
    <Row>
      <Col xs={12}>
        <Panel className="form">{children}</Panel>
      </Col>
    </Row>
  </div>
);

FormWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  customClass: PropTypes.string,
};

export default FormWrapper;
