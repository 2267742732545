import * as holdingApiClient from '../../api/holding';
import buildFetch from '../helper/fetch';
import requestHelper from '../helper/request';
import rooms from '../../api/socket/rooms';

const MATURING_HOLDINGS = 'MATURING_HOLDINGS';
const REDEEM_MATURING = 'REDEEM_MATURING';
const CONFIRM_REDEEM_MATURING = 'CONFIRM_REDEEM_MATURING';

const redeemRequestAction = requestHelper(REDEEM_MATURING, async (id, body) => ({
  id,
  trade: await holdingApiClient.redeem(id),
  socket: {
    event: 'client:rfq-updated',
    data: {
      tenants: body.tenants,
      room: rooms.rfq,
    },
  },
}));

const confirmRedeemRequestAction = requestHelper(CONFIRM_REDEEM_MATURING, async (id, body) => ({
  id,
  trade: await holdingApiClient.confirmRedeem(id, body),
  socket: {
    event: 'client:rfq-updated',
    data: {
      tenants: body.tenants,
      room: rooms.rfq,
    },
  },
}));

const maturingHoldingsFetchAction = buildFetch(MATURING_HOLDINGS, shouldFetch, fetchHoldings);

export const maturingHoldingsFetchPayload = maturingHoldingsFetchAction.actionsTypes;
export const redeemRequestActionPayload = redeemRequestAction.actionsTypes;
export const confirmRedeemRequestActionPayload = confirmRedeemRequestAction.actionsTypes;

const defaultParams = {
  maturity: 0,
  sort: '-principal',
};

async function fetchHoldings(params) {
  return {
    maturing: await holdingApiClient.list({
      ...defaultParams,
      ...params,
    }),
  };
}

export function redeem(trade, tenants) {
  return redeemRequestAction.request(trade.id, {
    id: trade.id,
    tenants,
  });
}

export function confirmRedeem(trade, tenants) {
  return confirmRedeemRequestAction.request(trade.id, {
    id: trade.id,
    note: trade.note,
    reference: trade.reference,
    bankReference: trade.bankReference,
    tenants,
  });
}

export function fetchIfNeeded(params) {
  return maturingHoldingsFetchAction.fetchIfNeeded(params);
}

// eslint-disable-next-line no-unused-vars
function shouldFetch(params = {}, state) {
  if (state.maturing.isFetching || state.maturing.didInvalidate) {
    return false;
  }

  if (state.portfolio.refresh || !state.maturing.maturing) {
    return true;
  }
  const maturingTrades = state.maturing.maturing.trades || [];

  return maturingTrades.length !== state.holding.maturing.todayCount;
}

export function fetch(params) {
  return maturingHoldingsFetchAction.fetch({
    ...defaultParams,
    ...params,
  });
}
