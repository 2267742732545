import axios from 'axios';

export default async function({ email, password }) {
  const { data } = await axios({
    method: 'post',
    url: '/auth',
    validateStatus: () => true,
    data: { email, password },
  });

  return data;
}
