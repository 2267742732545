import './download-chart-button.scss';

import React, { Component } from 'react';

import { toPng } from 'html-to-image';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import chartsComponents from '../charts/charts-components';
import { Loadable } from '../common';

class DownloadButton extends Component {
  static propTypes = {
    chartInfo: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDownloading: false,
    };
  }

  componentDidUpdate() {
    if (this.state.isDownloading) {
      const { chart } = this.props.chartInfo;
      this.downloadChart(chart.id);
    }
  }

  downloadChart = async (chartId) => {
    const chartWrapperId = `${chartId}_download-chart-wrapper`;

    const wrapper = document.getElementById(chartWrapperId);

    wrapper.classList.add('chart-download');

    const pngChart = await toPng(wrapper, { backgroundColor: 'transparent' });

    var link = document.createElement('a');
    link.download = `${chartId}_${new Date().toISOString()}.png`;
    link.href = pngChart;
    link.click();

    wrapper.classList.remove('chart-download');

    this.setState({ isDownloading: false });
  };

  renderChart() {
    if (!this.state.isDownloading) {
      return null;
    }

    const { chartInfo } = this.props;
    const { chart, payloadBuilder, intl } = chartInfo;

    const payload = payloadBuilder(chart, intl);
    payload.id = `${payload.id}_download`;
    payload.state = 'download';

    return <div className="chart-to-download">{chartsComponents[chart.type](payload)}</div>;
  }

  render() {
    return (
      <div className="download-chart-button">
        <button
          type="button"
          disabled={this.state.isDownloading}
          className="btn btn-default download-chart-button"
          onClick={() => this.setState({ isDownloading: true })}
        >
          <Loadable isLoading={this.state.isDownloading} size="sm">
            <FormattedMessage id="download.chart" />
          </Loadable>
        </button>
        {this.renderChart()}
      </div>
    );
  }
}

export default DownloadButton;
