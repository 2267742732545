import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '../../../../components/common';

export const ResponseItem = ({ label, value, className }) => (
  <Row className={classnames('response-item', className)} contentBetween>
    <p>{label}</p>
    <p>{value}</p>
  </Row>
);

ResponseItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  className: PropTypes.string,
};

ResponseItem.defaultProps = {
  className: undefined,
};
