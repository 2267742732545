import { createSelector } from 'reselect';
import orderBy from 'lodash.orderby';
import clone from 'lodash.clonedeep';

import { getIssuerTradesSummary } from '../issuer-trades/selectors';

const statusSortingOrder = {
  pending: 0,
  ongoing: 1,
  closed: 2,
  won: 3,
  lost: 4,
  cancelled: 5,
};

const sortInstrumentsByStatus = (instruments) => orderBy(instruments, (item) => statusSortingOrder[item.status]);

export const summaryInitialState = {
  cancelled: { amount: 0, count: 0 },
  closed: { amount: 0, count: 0 },
  ongoing: { amount: 0, count: 0 },
  pending: { amount: 0, count: 0 },
  won: { amount: 0, count: 0 },
  lost: { amount: 0, count: 0 },
};

const getPanelData = (rfqs) => {
  const initialState = {
    instruments: [],
    summary: clone(summaryInitialState),
  };

  const instrumentKeys = Object.keys(rfqs.instruments);
  const summaryKeys = Object.keys(initialState.summary);

  const mergedInstruments = instrumentKeys.reduce((acc, assetClass) => {
    const assetRfqs = rfqs.instruments[assetClass].list.map((rfq) => ({ ...rfq, assetClass }));

    summaryKeys.forEach((summaryKey) => {
      const summaryItem = acc.summary[summaryKey];
      const instrumentItem = rfqs.instruments[assetClass][summaryKey];

      summaryItem.amount += instrumentItem ? instrumentItem.amount : 0; // eslint-disable-line no-param-reassign
      summaryItem.count += instrumentItem ? instrumentItem.count : 0; // eslint-disable-line no-param-reassign
    });

    acc.instruments = [...acc.instruments, ...assetRfqs]; // eslint-disable-line no-param-reassign

    return acc;
  }, initialState);

  mergedInstruments.instruments = sortInstrumentsByStatus(mergedInstruments.instruments);

  return mergedInstruments;
};

const getIncomingRfqs = (state) => state.dashboard.incomingRfqs;

const getOutgoingRfqs = (state) => state.dashboard.outgoingRfqs;

export const getIncomingPanelData = createSelector([getIncomingRfqs], (rfqs) => getPanelData(rfqs));

export const getOutgoingPanelData = createSelector([getOutgoingRfqs], (rfqs) => getPanelData(rfqs));

const summaryKeys = ['cancelled', 'closed', 'ongoing', 'pending', 'won', 'lost'];

export const getDashboardSummary = createSelector(
  [getIncomingPanelData, getOutgoingPanelData, getIssuerTradesSummary],
  ({ summary: incomingSummary }, { summary: outgoingSummary }, issuerTradesSummary) => {
    const rfqsSummary = summaryKeys.reduce((summary, key) => {
      // eslint-disable-next-line no-param-reassign
      summary[key].count += summary[key].count + incomingSummary[key].count + outgoingSummary[key].count;

      return summary;
    }, clone(summaryInitialState));

    return {
      rfqs: rfqsSummary,
      issuerTrades: issuerTradesSummary,
    };
  },
);
