import axios from 'axios';

export const getTradesMaturingTodayUrl = ['/trades', 0];

export async function getTradesMaturingToday(url, maturity) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      maturity,
      sort: '-principal',
    },
  });

  return data && data.list;
}
