import axios from 'axios';

export const sendBespokeRatesheetToInvestorsUrl = '/unsolicited-ratesheets/issuers';

export async function sendBespokeRatesheetToInvestors(data) {
  await axios({
    method: 'post',
    url: sendBespokeRatesheetToInvestorsUrl,
    data,
  });
}
