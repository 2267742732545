import moment from 'moment';

import { isBusinessDay, newDate } from '../../../date';
import reducer, { onNextState } from '../../reducer';

export const customMaturityKey = 'CUSTOM';

const units = { M: 'months', Y: 'years' };

export const INITIAL_STATE = {
  interestPaidCode: null,
  issuers: [],
  ratesheetIssuers: [],
  isValid: false,
  maturities: [],
  principal: 0,
  tiers: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    ratesheetsTab: 0,
  },
  tradeId: null,
};

const actions = {
  UPDATE_MATURITY_TO_BE_QUOTED: updateMaturityToBeQuoted,
  UPDATE_FIELD: updateField,
  UPDATE_ISSUERS: updateIssuers,
  UPDATE_RATESHEET_ISSUERS: updateRatesheetIssuers,
  CLEAN_MATURITY_TO_BE_QUOTED: cleanMaturityToBeQuoted,
  MERGE_FIELDS: merge,
  CUSTOMIZE_MATURITIES: customizeMaturities,
  RESET: reset,
};

function updateMaturityToBeQuoted(state, action) {
  const nextState = buildNextMaturityToBeQuotedState(state, action);
  nextState.isValid = isRfqValid(nextState);

  return nextState;
}

function cleanMaturityToBeQuoted(state) {
  return onNextState(state, {
    maturities: [],
    isValid: false,
  });
}

function buildNextMaturityToBeQuotedState(state, { maturity, key, settlementDate }) {
  if (key === 'CUSTOM') {
    return buildNextStateWithCustomMaturity(state, maturity, key);
  }

  return buildNextStateWithMaturity(state, maturity, key, settlementDate);
}

function customizeMaturities(state, { customizedMaturities }) {
  const nextState = onNextState(state);

  Object.keys(customizedMaturities).forEach((maturityCode) => {
    const maturityIndex = getMaturityIndexByKey(nextState, maturityCode);
    const customizedMaturity = {
      maturityCode,
      date: customizedMaturities[maturityCode],
      originalDate: nextState.maturities[maturityIndex].originalDate,
    };

    if (!customizedMaturity.originalDate) {
      customizedMaturity.originalDate = state.maturities[maturityIndex].date;
    }

    nextState.maturities[maturityIndex] = customizedMaturity;
  });

  return nextState;
}

function buildMaturityNextState(state, key, includeAction) {
  const nextState = onNextState(state);
  const maturityIndex = getMaturityIndexByKey(nextState, key);
  if (maturityIndex > -1) {
    nextState.maturities.splice(maturityIndex, 1);

    return nextState;
  }

  includeAction(nextState, key);

  return nextState;
}

const getMaturityIndexByKey = (state, key) =>
  state.maturities && state.maturities.findIndex(({ maturityCode }) => maturityCode === key);

function buildNextStateWithCustomMaturity(state, maturity, key) {
  const nextState = onNextState(state);
  const isMaturityValid = moment(maturity).isValid();
  const maturityIndex = getMaturityIndexByKey(nextState, key);
  if (maturityIndex > -1) {
    !isMaturityValid
      ? nextState.maturities.splice(maturityIndex, 1)
      : (nextState.maturities[maturityIndex] = {
          maturityCode: key,
          date: maturity,
        });

    return nextState;
  }

  if (isMaturityValid) {
    nextState.maturities = [
      {
        maturityCode: key,
        date: maturity,
      },
    ];
  }

  return nextState;
}

const buildNextStateWithMaturity = (state, maturity, key, settlementDate) =>
  buildMaturityNextState(state, `${maturity}${key}`, (nextState, maturityCode) => {
    const maturityDate = newDate(settlementDate).add(maturity, units[key]);
    const businessDate = getBusinessDate(maturityDate);

    nextState.maturities.push({
      maturityCode,
      date: businessDate.toDate(),
    });
  });

function getBusinessDate(originalDate) {
  const getBusinessDateHandler = (action) => {
    const businessDate = newDate(originalDate);

    while (!isBusinessDay(businessDate)) {
      action(businessDate);
    }

    return businessDate;
  };

  const nextBusinessDate = getBusinessDateHandler((date) => date.add(1, 'days'));

  return originalDate.month() === nextBusinessDate.month()
    ? nextBusinessDate
    : getBusinessDateHandler((date) => date.subtract(1, 'days'));
}

function updateField(state, { name, value }) {
  return onNextState(state, {
    [name]: value,
  });
}

function merge(state, { mergeFrom }) {
  return onNextState(state, {
    ...mergeFrom,
  });
}

function reset() {
  return onNextState(INITIAL_STATE);
}

function updateRatesheetIssuers(state, action) {
  const { issuer, tier } = action;

  const issuerUpdateData = {
    isRatesheetsIssuer: true,
    issuer,
    state,
    tier,
  };

  if (hasSelectedIssuer(issuer.id, state.ratesheetIssuers)) {
    return removeIssuer({ ...issuerUpdateData, issuersType: 'ratesheetIssuers' });
  }

  return addIssuer(issuerUpdateData);
}

function updateIssuers(state, action) {
  const { issuer, tier } = action;

  const issuerUpdateData = {
    issuer,
    state,
    tier,
  };

  if (hasSelectedIssuer(issuer.id, state.issuers)) {
    return removeIssuer({ ...issuerUpdateData, issuersType: 'issuers' });
  }

  return addIssuer(issuerUpdateData);
}

function hasSelectedIssuer(issuerId, selectedIssuers) {
  return (
    (selectedIssuers && selectedIssuers.includes(issuerId)) || selectedIssuers.find((issuer) => issuer.id === issuerId)
  );
}

function removeIssuer({ issuer, state, tier, issuersType }) {
  const nextState = onNextState(state);
  const issuers = nextState[issuersType];
  const issuerIndex =
    issuers.indexOf(issuer.id) > -1 ? issuers.indexOf(issuer.id) : issuers.findIndex((item) => item.id === issuer.id);

  issuers.splice(issuerIndex, 1);
  nextState.tiers[tier]--;
  nextState.isValid = isRfqValid(nextState);

  return nextState;
}

function addIssuer({ issuer, state, tier, isRatesheetsIssuer }) {
  const nextState = onNextState(state);

  isRatesheetsIssuer
    ? nextState.ratesheetIssuers.push({ id: issuer.id, isDemo: issuer.type === 'demo' })
    : nextState.issuers.push(issuer.id);

  isRatesheetsIssuer ? (nextState.issuers = []) : (nextState.ratesheetIssuers = []);

  if (isRatesheetsIssuer) {
    nextState.tiers = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  }

  nextState.tiers[tier]++;
  nextState.isValid = isRfqValid(nextState);

  return nextState;
}

function isRfqValid(nextState) {
  return (nextState.maturities.length && (nextState.issuers.length || nextState.ratesheetIssuers.length)) > 0;
}

export default reducer(actions, INITIAL_STATE);
