const UPDATE_FIELD = 'UPDATE_FIELD';
const MERGE_FIELDS = 'MERGE_FIELDS';
const UPDATE_ISSUERS = 'UPDATE_ISSUERS';
const UPDATE_RATESHEET_ISSUERS = 'UPDATE_RATESHEET_ISSUERS';
const UPDATE_MATURITY_TO_BE_QUOTED = 'UPDATE_MATURITY_TO_BE_QUOTED';
const CLEAN_MATURITY_TO_BE_QUOTED = 'CLEAN_MATURITY_TO_BE_QUOTED';
const CUSTOMIZE_MATURITIES = 'CUSTOMIZE_MATURITIES';
const RESET = 'RESET';

export function updateMaturity(key, maturity, settlementDate) {
  return {
    type: UPDATE_MATURITY_TO_BE_QUOTED,
    key,
    maturity,
    settlementDate,
  };
}

export function merge(mergeFrom) {
  return {
    type: MERGE_FIELDS,
    mergeFrom,
  };
}

export function updateField(name, value) {
  return {
    type: UPDATE_FIELD,
    name,
    value,
  };
}

export function updateIssuers(tier, issuer) {
  return {
    type: UPDATE_ISSUERS,
    issuer,
    tier,
  };
}

export function updateRatesheetIssuers(tier, issuer) {
  return {
    type: UPDATE_RATESHEET_ISSUERS,
    issuer,
    tier,
  };
}

export function cleanMaturityToBeQuoted() {
  return {
    type: CLEAN_MATURITY_TO_BE_QUOTED,
  };
}

export function customizeMaturities(customizedMaturities) {
  return {
    type: CUSTOMIZE_MATURITIES,
    customizedMaturities,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}
