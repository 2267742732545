import {
  FETCH_INCOMING_RFQS_REQUEST,
  FETCH_INCOMING_RFQS_SUCCESS,
  FETCH_INCOMING_RFQS_FAILURE,
  FILTER_RFQS_HISTORY,
  CLEAR_RFQS_HISTORY_FILTER,
} from '../../../actions/reports/incoming-rfqs/rfqs-history';

const INITIAL_STATE = {
  initialized: false,
  didInvalidate: false,
  isFetching: false,
  incomingRfqs: [],
  summary: {
    avgWinMargin: null,
    avgLoseMargin: null,
    largestWinMargin: null,
    largestLoseMargin: null,
  },
  error: null,
  filter: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_INCOMING_RFQS_REQUEST:
      return { ...state, didInvalidate: false, isFetching: true };
    case FETCH_INCOMING_RFQS_SUCCESS:
      return {
        ...state,
        initialized: true,
        didInvalidate: false,
        isFetching: false,
        incomingRfqs: action.payload.list,
        summary: action.payload.summary,
      };
    case FETCH_INCOMING_RFQS_FAILURE:
      return {
        ...state,
        didInvalidate: true,
        error: action.error,
        isFetching: false,
      };
    case FILTER_RFQS_HISTORY:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };
    case CLEAR_RFQS_HISTORY_FILTER:
      return {
        ...state,
        filter: {},
      };
    default:
      return state;
  }
};
