import React from 'react';

import cx from 'classnames';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';

import { instrumentCodes, interests } from '../../../api/holding/codes';
import { CP, ECD, TD } from '../../../api/holding/instrument-codes';
import {
  Column,
  ConfirmDialog,
  DatePickerField,
  FormikCurrencyInput,
  FormikPercentageField,
  FormikSelectField,
  Row,
  ValidatedFormikField,
} from '../../../components/common';
import { isPast, newDate } from '../../../date';

const assetClassProps = {
  options: instrumentCodes
    .filter(({ code }) => [TD, CP, ECD].includes(code))
    .map(({ code: value, label }) => ({ value, label })),
};

const couponProps = { decimalPrecision: 4, min: -100, max: 100 };

export const initialFormValues = {
  allocationCode: 'GENERAL',
  bankReference: '',
  instrumentCode: TD,
  maturityDate: null,
  principal: '',
  purchaseDate: null,
  callableDate: null,
};

const dependentPurchaseDateFields = ['maturityDate', 'callableDate'];

export const AddFundsDialog = injectIntl(({ show, tenants, onConfirm, onCancel, intl }) => {
  const format = (messageId) => intl.formatMessage({ id: messageId });
  const buildValidationSchema = () =>
    Yup.object().shape({
      tenantId: Yup.number().required(format('required')),
      interestPaidCode: Yup.string().required(format('required')),
      purchaseDate: Yup.date().required(format('required')).typeError(format('required')).default(null),
      maturityDate: Yup.date().required(format('required')).typeError(format('required')).default(null),
      principal: Yup.string().required(format('required')),
      coupon: Yup.string().required(format('required')),
      instrumentCode: Yup.string().required(format('required')),
      callableDate: Yup.date().when('instrumentCode', {
        is: (instrumentCode) => [CP, ECD].includes(instrumentCode),
        then: Yup.date().required(format('required')),
        otherwise: Yup.date().nullable(),
      }),
    });

  const interestPaidProps = {
    options: interests.map(({ code: value, label }) => ({
      value,
      label: format(label),
    })),
  };

  return (
    <Formik validationSchema={buildValidationSchema()} onSubmit={onConfirm} initialValues={initialFormValues}>
      {({ errors, touched, isSubmitting, dirty, isValid, handleSubmit, values, resetForm, setFieldValue }) => (
        <ConfirmDialog
          modalClassName="add-new-funds-dialog"
          titleId="addFundsTitle"
          confirmId="add"
          cancelId="cancel"
          hasCancel
          disabled={isSubmitting || !dirty || !isValid}
          onCancel={() => {
            resetForm({ values: { ...initialFormValues } });
            onCancel();
          }}
          onConfirm={handleSubmit}
          show={show}
          isConfirming={isSubmitting}
        >
          <Column>
            <ValidatedFormikField
              name="tenantId"
              labelId="customer"
              component={FormikSelectField}
              touched={touched}
              errors={errors}
              isLoading={tenants.isFetching}
              options={tenants.list}
            />
            <Row>
              <ValidatedFormikField
                name="instrumentCode"
                labelId="holdingInstrumentCode"
                component={FormikSelectField}
                touched={touched}
                errors={errors}
                {...assetClassProps}
              />
              <ValidatedFormikField
                name="interestPaidCode"
                labelId="selectInterestPaid"
                component={FormikSelectField}
                touched={touched}
                errors={errors}
                {...interestPaidProps}
              />
            </Row>
            <Row>
              <ValidatedFormikField
                name="purchaseDate"
                labelId="holdingPurchaseDate"
                component={DatePickerField}
                touched={touched}
                errors={errors}
                onChange={(newPurchaseDate) => {
                  setFieldValue('purchaseDate', newPurchaseDate);

                  dependentPurchaseDateFields.forEach((field) => {
                    if (isPast(newDate(values.purchaseDate), values[field])) {
                      return;
                    }

                    setFieldValue(field, null);
                  });
                }}
              />
              <ValidatedFormikField
                name="maturityDate"
                labelId="holdingMaturityDate"
                component={(props) => <DatePickerField {...props} minDate={values.purchaseDate} />}
                touched={touched}
                errors={errors}
              />
              {[CP, ECD].includes(values.instrumentCode) && (
                <ValidatedFormikField
                  name="callableDate"
                  labelId="callableDate"
                  className={cx({ disabled: !values.maturityDate })}
                  component={(props) => (
                    <DatePickerField {...props} minDate={values.purchaseDate} maxDate={values.maturityDate} />
                  )}
                  touched={touched}
                  errors={errors}
                />
              )}
            </Row>
            <Row>
              <ValidatedFormikField
                name="principal"
                labelId="principal"
                component={FormikCurrencyInput}
                touched={touched}
                errors={errors}
              />
              <ValidatedFormikField
                name="coupon"
                labelId="holdingCoupon"
                component={FormikPercentageField}
                touched={touched}
                errors={errors}
                {...couponProps}
              />
            </Row>
            <ValidatedFormikField
              name="bankReference"
              labelId="holdingBankReference"
              touched={touched}
              errors={errors}
            />
          </Column>
        </ConfirmDialog>
      )}
    </Formik>
  );
});

AddFundsDialog.propTypes = {
  tenants: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool,
  }),
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

AddFundsDialog.defaultProps = {
  show: false,
  tenants: { list: [], isFetching: false },
};
