import React from 'react';
import { Button } from 'react-bootstrap';

import { commonHeaderPresenter } from '.';

export const columns = {
  issuer: { label: 'rfqAdi' },
};

export const headerPresenter = commonHeaderPresenter;

export const presenter = (quote, index) => (
  <tr key={index}>
    <td className="col-waiting-quote-issuer-adi">
      <label className="text-ellipsis" htmlFor={quote.code}>{`${quote.code} - ${quote.shortName}`}</label>
    </td>
    {quote.maturities.map((maturity) => (
      <td className="col-quote-coupon text-center" key={maturity.code}>
        <Button className="btn-table" disabled>
          -
        </Button>
      </td>
    ))}
  </tr>
);
