import React from 'react';

import propTypes from 'prop-types';
import useSWR from 'swr';

import {
  buildIncomingRfqsDetailsUrl,
  listIncomingRfqsDetails,
} from '../../../../../../api/holding/rfq/sell-bonds/list-sell-bonds-incoming-rfqs-details';
import imperiumLogo from '../../../../../../assets/grey-imperium.svg';
import { Drawer, ErrorComponent } from '../../../../../../components/common';
import { BuyerBuySellBondRfqStatus } from '../../../buy-sell-bond-rfq-status';
import { SendSellBondsRfqQuote } from './SendSellBondsQuote';

export const SendSellBondsRfqQuoteDrawer = ({ rfqUUID, onCloseDrawer, onQuoteUpdatedSuccess }) => {
  const { data: rfqDetails, error, mutate } = useSWR(
    rfqUUID && buildIncomingRfqsDetailsUrl({ rfqUUID }),
    listIncomingRfqsDetails,
  );

  const onDrawerSaveIssuerQuoteSuccess = () => {
    mutate();
    onQuoteUpdatedSuccess && onQuoteUpdatedSuccess();
    onCloseDrawer();
  };

  const drawerContent = error ? (
    <ErrorComponent message="sorryThereWasAnError" showFooter={false} />
  ) : (
    <SendSellBondsRfqQuote
      rfq={rfqDetails}
      isLoading={!rfqDetails}
      onQuoteUpdatedSuccess={onDrawerSaveIssuerQuoteSuccess}
    />
  );

  return (
    <Drawer
      titleId="quote"
      className="light save-issuer-quote-drawer"
      shouldCloseOnClickOutside={false}
      headerComponent={rfqDetails && <BuyerBuySellBondRfqStatus rfq={rfqDetails} verbose />}
      open={!!rfqUUID}
      onClose={onCloseDrawer}
      width="40vw"
      footer={<img alt="Imperium Markets" src={imperiumLogo} />}
    >
      {drawerContent}
    </Drawer>
  );
};

SendSellBondsRfqQuoteDrawer.propTypes = {
  rfqUUID: propTypes.string,
  onCloseDrawer: propTypes.func,
  onQuoteUpdatedSuccess: propTypes.func,
};
