import clone from 'lodash.clonedeep';

export default function(actions, initialState) {
  return reducer.bind(null, actions, initialState);
}

function reducer(actions, initialState, state, action) {
  return actions[action.type] ? actions[action.type](state || initialState, action) : state || initialState;
}

export const onNextState = (state, override) => {
  const nextState = clone(state);

  return {
    ...nextState,
    error: null,
    ...override,
  };
};

export const status = {
  initial: 'initial',
  isSaved: 'isSaved',
  isSaving: 'isSaving',
  hasError: 'hasError',
};
