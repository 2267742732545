import { format } from 'd3-format';

import palette from '../../theme/helpers/pallete.json';
import {
  buildLimits,
  buildValuesAboveMoneyAndPctLimits,
  buildValuesUnderMoneyAndPctLimits,
} from '../common/compliances/chart-payload';

const numberFormatter = format(',.2f');

export function series(data, intl) {
  return [
    {
      label: intl.formatMessage({ id: 'limits' }),
      data: buildLimits(data, 'maxLimitPct'),
      bars: { fillColor: palette['mid-grey'], barWidth: 0.6 },
    },
    {
      label: intl.formatMessage({ id: 'compliant' }),
      data: buildValuesUnderMoneyAndPctLimits(data, 'maxLimitPct'),
      bars: { fillColor: palette['light-orange'], barWidth: 0.4 },
    },
    {
      label: intl.formatMessage({ id: 'nonCompliant' }),
      data: buildValuesAboveMoneyAndPctLimits(data, 'maxLimitPct'),
      bars: { fillColor: palette.error, barWidth: 0.4 },
    },
  ];
}

export function xTicks(data) {
  return data.map((issuer, index) => [index, issuer.code]);
}

export const tooltips = (moneySymbol) => (data) =>
  data.map(
    (issuer) =>
      `
      <b>${issuer.name}</b><br/>
      <b>Amount invested:</b> ${moneySymbol}${numberFormatter(issuer.amountInvested)}<br/>
      <b>Max limit (%):</b> ${issuer.maxLimitPct}%<br/>
      <b>Max limit (${moneySymbol}):</b> ${issuer.maxMoneyLimit ? numberFormatter(issuer.maxMoneyLimit) : '-'}<br/>
      <b>Amount available (${moneySymbol}):</b> ${numberFormatter(issuer.amountAvailable)}<br/>
      ${
        issuer.amountMaturingToday
          ? `<b>Maturing Today (${moneySymbol}):</b> ${numberFormatter(issuer.amountMaturingToday)}<br/>`
          : ''
      }
      ${issuer.breakReason ? `<b>Breach reason:</b> ${issuer.breakReason} limit` : ''}
    `,
  );
