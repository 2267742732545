import './capital-value.scss';

import React, { PureComponent } from 'react';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { injectIntl } from 'react-intl';
import * as Yup from 'yup';

import {
  ConfirmationPopover,
  DatePickerField,
  FormikCurrencyInput,
  Row,
  ValidatedFormikField,
} from '../../components/common';
import toFloat from '../../parse-float';

class CapitalValueCard extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    isRemoving: PropTypes.bool,
  };

  static defaultProps = {
    isRemoving: false,
  };

  constructor(props) {
    super(props);

    this.datePickerOptions = {
      options: {
        endDate: new Date(),
      },
    };
  }

  capitalValueSchema = () => {
    const format = (messageId) => this.props.intl.formatMessage({ id: messageId });

    return Yup.object().shape({
      asOfDate: Yup.date().required(format('required')).typeError(format('required')),
      capitalValue: Yup.string().required(format('required')),
    });
  };

  onSubmit = (values, { setSubmitting, resetForm }) => {
    const { id, asOfDate, capitalValue } = values;
    this.props.onSubmit(
      {
        id,
        asOfDate,
        capitalValue: toFloat(capitalValue),
      },
      { setSubmitting, resetForm },
    );
  };

  isSaveDisabled = ({ isSubmitting, dirty, isValid }) => !(dirty && !isSubmitting && isValid);

  onCloseClick = () => this.props.onCloseClick(this.props.initialValues.id);

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validationSchema={this.capitalValueSchema()}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, isSubmitting, dirty, isValid, handleSubmit }) => (
          <Row className="grey-box" alignItemsCenter contentEvenly>
            <ValidatedFormikField
              name="asOfDate"
              labelId="inDate"
              component={DatePickerField}
              touched={touched}
              errors={errors}
              {...this.datePickerOptions}
            />
            <ValidatedFormikField
              name="capitalValue"
              labelId="capitalValue"
              component={FormikCurrencyInput}
              touched={touched}
              errors={errors}
            />

            <ConfirmationPopover
              buttonLabelId="save"
              disabled={this.isSaveDisabled({ isSubmitting, dirty, isValid }) || this.props.isRemoving}
              confirmationMessageId="areYouSure"
              onConfirm={handleSubmit}
              className="btn-solid-primary"
            />

            <ConfirmationPopover
              disabled={this.props.isRemoving}
              confirmationMessageId="areYouSure"
              onConfirm={this.onCloseClick}
            >
              {({ onPopoverHandlerClick }) => <FaTimes className="close-button" onClick={onPopoverHandlerClick} />}
            </ConfirmationPopover>
          </Row>
        )}
      </Formik>
    );
  }
}

export default injectIntl(CapitalValueCard);
