import '../../save-buy-sell-bond-rfq-quote.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import * as Yup from 'yup';

import { sendBuyBondsQuote } from '../../../../../api/holding/rfq/buy-bonds';
import { buyBondsConfirmRfq } from '../../../../../api/holding/rfq/buy-bonds/buy-bonds-confirm-rfq';
import rooms from '../../../../../api/socket/rooms';
import { socketEvents } from '../../../../../api/socket/setup-socket';
import {
  ConfirmationPopover,
  FormikCurrencyInput,
  ResultsPresenter,
  ValidatedFormikField,
} from '../../../../../components/common';
import { FormikResetFormHandler } from '../../../../../components/common/formik/FormikResetFormHandler';
import includeSocket from '../../../../../components/hoc/include-socket';
import { DateWithTimeZone } from '../../../../../date';
import {
  buyBondRfqBankViewBuyBondRfqDetails,
  buyBondRfqConfirmQuoteClicked,
  buyBondRfqNotInterested,
  buyBondRfqSentQuoteClicked,
  track,
} from '../../../../../event-tracker';
import {
  currencyDecimalPrecision,
  toNumberFormat,
} from '../../../../../format-numbers';
import toFloat from '../../../../../parse-float';
import { Money } from '../../../../money';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../../../../toast/toast';
import {
  getInstrumentCodeDescription,
  getIsinYieldOrMarginDescription,
} from '../../bond-frn-descriptions';
import {
  buildConfirmQuoteBuySellBondColumns,
  confirmQuoteBuySellBondPresenter,
} from '../../confirm-quote-buy-sell-bond-records-presenter';
import { RfqNotInterestedToggle } from '../../RfqNotInterestedToggle';

const buildFormSchema = (intl) =>
  Yup.object().shape({
    cleanPrice: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "requiredField" })),
    grossPrice: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "requiredField" })),
    yieldOrMargin: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "requiredField" })),
  });

const initialQuoteValues = {
  cleanPrice: "",
  grossPrice: "",
  yieldOrMargin: "",
};

const SendBuyBondsRfqQuoteComponent = ({
  rfq,
  isLoading,
  onQuoteUpdatedSuccess,
  emit,
  intl,
}) => {
  useEffect(() => {
    if (!rfq.buyBondRfqEntityId) {
      return;
    }

    track(buyBondRfqBankViewBuyBondRfqDetails);
  }, [rfq.buyBondRfqEntityId]);

  const {
    buyBondRfqEntityId,
    instrumentCode,
    isin,
    isinIssuerName,
    settlementDate,
    isinYieldOrMargin,
    faceValueToBuy,
    investorName,
    bankName,
    investorCurrency,
    isinMaturityDate,
    rfqStatus,
    operation,
    quotes = [],
    notifyDomains,
    confirmedAt,
    confirmedBy,
    canConfirmRfq,
    hasAustraclear,
  } = rfq;

  const emitRfqUpdatedSocketEvent = () => {
    emit(socketEvents.rfqUpdated, {
      tenants: notifyDomains,
      room: rooms.rfq,
    });
  };

  const onSendQuoteSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);

      const { cleanPrice, grossPrice, yieldOrMargin } = values;

      await sendBuyBondsQuote({
        rfqEntityId: buyBondRfqEntityId,
        data: {
          cleanPrice: toFloat(cleanPrice),
          grossPrice: toFloat(grossPrice),
          yieldOrMargin: +yieldOrMargin,
        },
      });

      track(buyBondRfqSentQuoteClicked);

      onQuoteUpdatedSuccess && onQuoteUpdatedSuccess();

      emitRfqUpdatedSocketEvent();

      showToastMessage(
        intl.formatMessage({ id: "quoteSentSuccessfully" }),
        MessageType.SUCCESS
      );
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setSubmitting(false);
    }
  };

  const onConfirmRfqQuote = async () => {
    try {
      await buyBondsConfirmRfq({
        rfqEntityId: buyBondRfqEntityId,
      });

      track(buyBondRfqConfirmQuoteClicked);

      onQuoteUpdatedSuccess && onQuoteUpdatedSuccess();

      emitRfqUpdatedSocketEvent();

      showToastMessage(
        intl.formatMessage({ id: "quoteConfirmedSuccessfully" }),
        MessageType.SUCCESS
      );
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    }
  };

  const isFormDisabled = rfqStatus !== "open";

  const issuerQuote =
    quotes.find((quote) => quote.isOwnAdi) || initialQuoteValues;

  const issuerQuoteInitialValues = {
    ...issuerQuote,
    yieldOrMargin: !issuerQuote.yieldOrMargin
      ? ""
      : issuerQuote.yieldOrMargin.toString(),
  };

  const presenterPayload = {
    isFetching: isLoading,
    noRecords: !quotes || !quotes.length,
    recordsPresenter: {
      ariaLabel: "buy bond rfq quotes",
      data: quotes,
      actions: { onConfirmRfqQuote },
      presenter: confirmQuoteBuySellBondPresenter,
      columns: buildConfirmQuoteBuySellBondColumns({ instrumentCode }),
      options: {
        rfqStatus,
        confirmedAt,
        confirmedBy,
        canConfirmRfq,
        operation,
      },
    },
  };

  const [isNotInterested, setIsNotInterested] = useState(false);

  return (
    <Column
      element="section"
      aria-label="request bond rfq quote container"
      className={classNames("save-buy-sell-bond-rfq-quote-container", {
        "is-loading": isLoading,
      })}
    >
      <dl className="highlight">
        <div>
          <dt>Type</dt>
          <dd>{getInstrumentCodeDescription(instrumentCode)}</dd>
        </div>
        <div>
          <dt>ISIN</dt>
          <dd>{isin}</dd>
        </div>
        <div>
          <dt>{getIsinYieldOrMarginDescription(instrumentCode)}</dt>
          <dd>{toNumberFormat({ value: isinYieldOrMargin })}</dd>
        </div>
        <div>
          <dt>Issuer</dt>
          <dd>{isinIssuerName}</dd>
        </div>
        <div>
          <dt>Maturity Date</dt>
          <dd>
            {isinMaturityDate && <DateWithTimeZone value={isinMaturityDate} />}
          </dd>
        </div>
        <div>
          <dt>Has Austraclear?</dt>
          <dd>
            {hasAustraclear ? (
              <FormattedMessage id="yes" />
            ) : (
              <FormattedMessage id="no" />
            )}
          </dd>
        </div>
      </dl>
      <dl>
        <div>
          <dt>Buyer</dt>
          <dd>{investorName}</dd>
        </div>
        <div>
          <dt>Seller</dt>
          <dd>{bankName}</dd>
        </div>
        <div>
          <dt>Operation</dt>
          <dd>
            {operation && (
              <FormattedMessage id={`${operation}OperationCounterparty`} />
            )}
          </dd>
        </div>
        <div>
          <dt>Face Value ({investorCurrency})</dt>
          <dd>
            <Money value={faceValueToBuy} />
          </dd>
        </div>
        <div>
          <dt>Currency</dt>
          <dd>{investorCurrency}</dd>
        </div>
        <div>
          <dt>Settlement Date</dt>
          <dd>
            {settlementDate && <DateWithTimeZone value={settlementDate} />}
          </dd>
        </div>
      </dl>
      {rfqStatus === "open" ? (
        <Formik
          enableReinitialize
          initialValues={issuerQuoteInitialValues}
          validationSchema={buildFormSchema(intl)}
          onSubmit={onSendQuoteSubmit}
        >
          {({ errors, touched, resetForm, isSubmitting, handleSubmit }) => (
            <Form>
              <Row alignItemsCenter justifyContentSpaceBetween>
                <p>
                  Please enter bid details as the seller of the above security.
                  <br />
                  Imperium platform fee: 1bp p.a.
                </p>
                <RfqNotInterestedToggle
                  onNotInterestedSuccess={() => {
                    emitRfqUpdatedSocketEvent();
                    onQuoteUpdatedSuccess && onQuoteUpdatedSuccess();
                  }}
                  onNotInterestedApiAction={async () => {
                    await sendBuyBondsQuote({
                      rfqEntityId: buyBondRfqEntityId,
                      data: {
                        notInterested: true,
                      },
                    });

                    track(buyBondRfqNotInterested);
                  }}
                  onNotInterestedToggled={setIsNotInterested}
                  quoteStatus={issuerQuote.quoteStatus}
                />
              </Row>
              <fieldset disabled={isFormDisabled || isNotInterested}>
                <FormikResetFormHandler
                  resetForm={resetForm}
                  dependencies={rfq.buyBondRfqEntityId}
                />
                <ValidatedFormikField
                  name="cleanPrice"
                  labelId="cleanPrice"
                  component={FormikCurrencyInput}
                  touched={touched}
                  errors={errors}
                  decimalScale={currencyDecimalPrecision}
                />

                <ValidatedFormikField
                  name="grossPrice"
                  labelId="grossPrice"
                  component={FormikCurrencyInput}
                  touched={touched}
                  errors={errors}
                  decimalScale={currencyDecimalPrecision}
                />
                <ValidatedFormikField
                  name="yieldOrMargin"
                  touched={touched}
                  errors={errors}
                  labelId={
                    instrumentCode === "FRN"
                      ? "tradeMarginBasisPoints"
                      : "yield"
                  }
                />

                <ConfirmationPopover
                  buttonLabelId="sendQuote"
                  className="btn-solid-primary"
                  popoverClass="light"
                  isConfirmingFromOutside={isSubmitting}
                  confirmationMessageId="sendQuoteNow"
                  disabled={isFormDisabled}
                  type="button"
                  onConfirm={handleSubmit}
                />
              </fieldset>
            </Form>
          )}
        </Formik>
      ) : (
        <ResultsPresenter {...presenterPayload} />
      )}
    </Column>
  );
};

SendBuyBondsRfqQuoteComponent.propTypes = {
  rfq: propTypes.shape(),
  isLoading: propTypes.bool,
  onQuoteUpdatedSuccess: propTypes.func,
  emit: propTypes.func,
};

SendBuyBondsRfqQuoteComponent.defaultProps = {
  rfq: {},
};

export const SendBuyBondsRfqQuote = compose(
  includeSocket({ rooms: [rooms.rfq] }),
  injectIntl
)(SendBuyBondsRfqQuoteComponent);
