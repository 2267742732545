import { handleActions } from 'redux-actions';

import { authenticationReducer } from './authenticate';
import { logoutReducer } from './logout';
import { confirmTwoFactorReducer } from './confirm-two-factor';

export const initialState = {
  authenticating: false,
  token: null,
  loggingOut: false,
  statusCode: null,
  loggedOut: false,
  pending: false,
  twoFactorAuthEnabled: false,
  error: null,
};

const combinedReducers = {
  ...authenticationReducer,
  ...confirmTwoFactorReducer,
  ...logoutReducer,
};

export const login = handleActions(combinedReducers, initialState);
