import './offer.scss';

import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import toKebabCase from 'lodash.kebabcase';
import propTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getUser } from '../../actions/session-selector';
import { Column, Loadable, Row } from '../../components/common';
import ConfirmDialog from '../../components/common/confirm-dialog';
import { formatToShortDate, getBusinessDate, getDateInTimeFrameFrom, today } from '../../date';
import toFloat from '../../parse-float';
import { getMoneySymbol } from '../money';
import { MessageType, showToastMessage } from '../toast/toast';
import { ConfirmOfferModal } from './ConfirmOfferModal';
import { OfferActions } from './OfferActions';
import { SelectTenor } from './SelectTenor';

const normalizeAdditionalFunds = (value) => (value && value !== '.' && value !== '-' ? toFloat(value) : 0);

export const initialBidState = {
  principal: 0,
  accruedInterest: 0,
  additionalFunds: 0,
  selectedOffer: undefined,
  interestPaid: 'Z',
  maturityDate: undefined,
};

const OfferComponent = injectIntl(
  ({
    className,
    children,
    extraActions,
    user,
    header,
    offerDetails,
    bidDetails,
    onConfirm,
    onReject,
    isLoading,
    ratesheet,
    isInvestor,
    intl,
  }) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [bid, setBid] = useState(initialBidState);

    useEffect(() => setBid({ ...bid, ...bidDetails }), [bidDetails]);

    const [confirming, setConfirming] = useState(false);
    const [showNotInterestedDialog, setShowNotInterestedDialog] = useState(false);
    const [isRejectOfferConfirming, setRejectOfferConfirming] = useState(false);

    const { currency } = user;
    const currencySymbol = getMoneySymbol({ currency, short: true });

    const onConfirmOffer = async () => {
      try {
        setConfirming(true);

        await onConfirm({ bid });

        setConfirming(false);
        setShowConfirmationDialog(false);
      } catch (e) {
        setConfirming(false);
      }
    };

    const onRejectOffer = async () => {
      try {
        setRejectOfferConfirming(true);

        await onReject({ bid });

        setBid({ ...initialBidState });

        setShowNotInterestedDialog(false);
        showToastMessage(intl.formatMessage({ id: 'offerRejected' }), MessageType.SUCCESS);
      } catch (e) {
        showToastMessage(intl.formatMessage({ id: 'anErrorHasOccurredPleaseTryAgain' }));
      }

      setRejectOfferConfirming(false);
    };

    const onTenorClick = ({ unit, time, rate, tenor }) => {
      const deselectOffer = bid.selectedOffer && bid.selectedOffer.tenor === tenor;

      if (deselectOffer) {
        setBid({ ...bid, selectedOffer: undefined });

        return;
      }

      const maturityDate = getBusinessDate({
        date: getDateInTimeFrameFrom(today(), time, unit),
      }).toDate();

      setBid({
        ...bid,
        selectedOffer: {
          unit,
          time,
          rate,
          tenor,
          maturityDate,
        },
        tenorFromRatesheet: {
          rate,
          maturityDate,
        },
        customMaturityDate: undefined,
        customCoupon: undefined,
      });
    };

    const onAccruedToggle = (shouldAddAccrued) =>
      setBid({
        ...bid,
        accruedInterest: shouldAddAccrued ? offerDetails.accruedInterest : 0,
      });

    const onAdditionalFundsChange = (value) => setBid({ ...bid, additionalFunds: normalizeAdditionalFunds(value) });

    const onAdjustRateChange = ({ customMaturityDate, customCoupon }) => {
      const maturityDate = customMaturityDate || bid.selectedOffer.maturityDate;
      const rate = customCoupon !== undefined ? customCoupon : bid.selectedOffer.rate;

      setBid({
        ...bid,
        selectedOffer: { ...bid.selectedOffer, maturityDate, rate },
        customMaturityDate: customMaturityDate ? formatToShortDate(customMaturityDate) : undefined,
        customCoupon: customCoupon !== undefined ? customCoupon : undefined,
      });
    };

    const onInterestPaidChange = (property, value) => setBid({ ...bid, [property]: value });

    const disclaimerMessageId =
      !isInvestor && ['sent', 'read'].includes(offerDetails.status) ? 'cancelOfferMessage' : '';

    return (
      <section aria-label="offer" className={cx('offer-container', toKebabCase(offerDetails.status), className)}>
        <Loadable isLoading={isInvestor && isLoading}>
          <Row>
            <Column className="offer-tenors" flex={1}>
              {header}
              <SelectTenor
                onClick={onTenorClick}
                selectedTenor={bid.selectedOffer && bid.selectedOffer.tenor}
                isLoading={isLoading}
                status={offerDetails.status}
                ratesheet={ratesheet}
              />
            </Column>
            <OfferActions
              bid={bid}
              currencySymbol={currencySymbol}
              extraActions={extraActions}
              initialAccrued={offerDetails.accruedInterest}
              instrumentCode={offerDetails.instrumentCode}
              isLoading={isLoading}
              maturityDate={bid.selectedOffer && bid.selectedOffer.maturityDate}
              onAccruedToggle={onAccruedToggle}
              onAdditionalFundsChange={onAdditionalFundsChange}
              onConfirmClick={() => setShowConfirmationDialog(true)}
              onInterestPaidChange={onInterestPaidChange}
              onNotInterestedClick={() => setShowNotInterestedDialog(true)}
              onAdjustRateChange={onAdjustRateChange}
              ratesheet={ratesheet}
              status={offerDetails.status}
              isInvestor={isInvestor}
            />
          </Row>
          <ConfirmOfferModal
            isConfirming={confirming}
            onCancel={() => setShowConfirmationDialog(false)}
            onConfirm={onConfirmOffer}
            show={showConfirmationDialog}
            bid={bid}
            disclaimerMessageId={disclaimerMessageId}
          />
          {onRejectOffer && (
            <ConfirmDialog
              titleId="notInterested"
              contentId="areYouSureThatYouAreNotInterested"
              hasCancel
              onCancel={() => setShowNotInterestedDialog(false)}
              onConfirm={onRejectOffer}
              show={showNotInterestedDialog}
              isConfirming={isRejectOfferConfirming}
            />
          )}
          {children}
        </Loadable>
      </section>
    );
  },
);

OfferComponent.propTypes = {
  className: propTypes.string,
  children: propTypes.node,
  bidDetails: propTypes.shape(),
  isLoading: propTypes.bool,
  header: propTypes.node,
  extraActions: propTypes.arrayOf(
    propTypes.shape({
      buttonLabelId: propTypes.string.isRequired,
      onClick: propTypes.func.isRequired,
    }),
  ),
  ratesheet: propTypes.shape({
    updatedAt: propTypes.string,
    rates: propTypes.arrayOf(
      propTypes.shape({
        rate: propTypes.number,
        tenor: propTypes.string,
      }),
    ),
  }),
  onConfirm: propTypes.func.isRequired,
  onReject: propTypes.func,
  initialBid: propTypes.shape({
    accruedInterest: propTypes.number,
    additionalFunds: propTypes.number,
    interestPaid: propTypes.string,
    principal: propTypes.number,
    selectedOffer: propTypes.shape({
      time: propTypes.string,
      unit: propTypes.string,
      rate: propTypes.number,
    }),
  }),
  offerDetails: propTypes.shape({
    accruedInterest: propTypes.number,
    additionalFunds: propTypes.number,
    interestPaid: propTypes.string,
    instrumentCode: propTypes.string,
    maturityDate: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
    status: propTypes.string,
    principal: propTypes.number,
    selectedOffer: propTypes.shape({
      time: propTypes.string,
      unit: propTypes.string,
      rate: propTypes.number,
    }),
    bids: propTypes.arrayOf(
      propTypes.shape({
        accrued: propTypes.number,
        additionalFunds: propTypes.number,
        rate: propTypes.number,
        maturityDate: propTypes.string,
        status: propTypes.string,
      }),
    ),
  }),
  user: propTypes.shape().isRequired,
  isInvestor: propTypes.bool,
};

OfferComponent.defaultProps = {
  bidDetails: undefined,
  className: '',
  header: undefined,
  initialBid: initialBidState,
  isLoading: false,
  offerDetails: {},
  onReject: undefined,
  ratesheet: undefined,
  isInvestor: false,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export const Offer = connect(mapStateToProps)(OfferComponent);
