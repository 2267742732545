import './results-presenter.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EmptyContent } from './EmptyContent';
import Filter from './filter';
import { Row } from './Flex/Flex';
import Records from './records';
import { SkeletonTable } from './Skeletons';

const ResultsPresenter = (props) => (
  <div className="results-presenter">
    <div className="results panel panel-filled">{renderResults(props)}</div>
  </div>
);

ResultsPresenter.propTypes = {
  filter: PropTypes.shape({
    filter: PropTypes.shape(),
    onDropdownFilterChange: PropTypes.func,
    availableFilters: PropTypes.shape(),
    onResetFilter: PropTypes.func,
  }),
};

const renderResults = (props) => (
  <div>
    {renderTitle(props)}
    {renderFilter(props)}
    {renderRecords(props)}
  </div>
);

const renderTitle = ({ titleId, title, titleComponent, badge }) =>
  (titleId || title) && (
    <Row contentBetween>
      <Row className="title-container">
        <h4 className="result-title">{titleId ? <FormattedMessage id={titleId} /> : <span>{title}</span>}</h4>
        {badge}
      </Row>
      {titleComponent && <div className="pull-right">{titleComponent}</div>}
    </Row>
  );

const renderFilter = ({ filter }) => filter && <div>{filter.component || <Filter {...filter} />}</div>;

const renderRecords = ({ isFetching, noRecords, recordsPresenter, noRecordsMessageId = 'noRecords', hasError }) => {
  if (hasError) {
    return <EmptyContent messageId="anErrorHasOccurredPleaseTryAgain" />;
  }

  if (!isFetching && noRecords) {
    return <EmptyContent messageId={noRecordsMessageId} data-testid="no-records-message" />;
  }

  return (
    <SkeletonTable isLoading={isFetching}>
      <Records {...recordsPresenter} />
    </SkeletonTable>
  );
};

renderRecords.propTypes = {
  isFetching: PropTypes.bool,
  noRecords: PropTypes.bool,
  hasError: PropTypes.bool,
  recordsPresenter: PropTypes.shape(),
  noRecordsMessageId: PropTypes.string,
};

export default ResultsPresenter;
