import hexToHsl from 'hex-to-hsl';

import { setTheme as setThemeByTameYourTheme } from '@tame-your-theme/css-style-declaration';

import defaultTheme from '../../containers/app/theme.scss';
import { colors } from './colors';

const getHueSaturationAndLightness = (value) => {
  const [hue, saturation, lightness] = hexToHsl(value);

  return {
    hue,
    saturation,
    lightness,
  };
};

export const setTheme = (overrideThemeVariables) => {
  const theme = {
    ...defaultTheme,
    ...overrideThemeVariables,
  };

  const variables = [
    { name: colors.themeHeaderColor, value: theme.themeHeaderColor },
    { name: colors.themeHeaderContrastColor, value: theme.themeHeaderContrastColor },
    { name: colors.themeNavColor, value: theme.themeNavColor },
    { name: colors.themeNavContrastColor, value: theme.themeNavContrastColor },
    { name: colors.themePrimaryColor, value: theme.themePrimaryColor },
    { name: colors.themeBackgroundMainColor, value: theme.themeBackgroundMainColor },
    { name: colors.themeBackgroundMainContrastColor, value: theme.themeBackgroundMainContrastColor },
    { name: colors.themeBackgroundLightColor, value: theme.themeBackgroundLightColor },
    { name: colors.themeBackgroundDarkColor, value: theme.themeBackgroundDarkColor },
    { name: colors.themePaperColor, value: theme.themePaperColor },
    { name: colors.themePaperContrastColor, value: theme.themePaperContrastColor },
    { name: colors.themePrimaryTextColor, value: theme.themePrimaryTextColor },
    { name: colors.themePrimaryContrastColor, value: theme.themePrimaryContrastColor },
    { name: colors.themeSecondaryTextColor, value: theme.themeSecondaryTextColor },
    {
      name: colors.themeBackgroundLightContrastColor,
      value: theme.themeBackgroundLightContrastColor,
    },
    {
      name: colors.themeBackgroundDarkContrastColor,
      value: theme.themeBackgroundDarkContrastColor,
    },
  ];

  setThemeByTameYourTheme({ variables, getHueSaturationAndLightness });
};

export const setDefaultTheme = () => setTheme(defaultTheme);
