import 'moment-timezone';

import React from 'react';

import Holidays from 'date-holidays';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

import config from './config';
import defaultTimezone from './time-zones';

const acceptableHolidayTypes = 'public|bank';
const dateHolidays = new Holidays(config.env.tenantRegion, config.env.countryState, {
  types: acceptableHolidayTypes,
});

let tenantTimezone = defaultTimezone;
export const defaultDateFormat = 'YYYY-MM-DD';

export const enAuShortDateFormat = 'DD/MM/YYYY';

export const enAuShortDateFormatWithTime = 'DD/MM/YYYY HH:mm';

export const daysInYear = 365;

export const today = () => momentWithTimezone();

export const newDate = (date) => momentWithTimezone(date);

export const isValidDate = (date) => moment(date).isValid();

export const toDateWithZeroTime = (date) => momentWithTimezone(date).startOf('day');

export const fromNow = (date) => momentWithTimezone(date).fromNow();

export const getDateInTimeFrameFrom = (fromDate = new Date(), amount = 1, timeFrame = 'days') =>
  momentWithTimezone(fromDate).add(amount, timeFrame);

export const addYears = (date, amount) => getDateInTimeFrameFrom(date, amount, 'years');

export const subtractMonths = (date, amount) => momentWithTimezone(date).subtract(amount, 'months');

export const addDays = (date, amount) => getDateInTimeFrameFrom(date, amount, 'days');

export const startOfDayFormat = (date = today()) => startOfDay(date).format();

export const endOfDayFormat = (date = today()) => endOfDay(date).format();

export function getFilterDate(month) {
  const todayDate = today();
  const currentYear = todayDate.year();
  const year = isMonthBiggerThanCurrent(month) ? currentYear - 1 : currentYear;

  return isCurrentMonth(month) ? todayDate : getEndOfMonth(month, year);
}

export function isCurrentMonth(month) {
  return month === today().month() + 1;
}

function isMonthBiggerThanCurrent(month) {
  return month > today().month() + 1;
}

export function getEndOfMonth(month, year) {
  const date = new Date(Date.UTC(year, month - 1, 1));

  return momentWithTimezone(date).endOf('month');
}

export const endOfRollingMonth = (date = today()) => {
  // this function returns either:
  // 1. end of day today, if today is the last day of the month, or
  // 2. end of the previous month, if today is not the last day of the month

  const dateEndOfMonth = newDate(date).endOf('month');

  if (isSameDay(date, dateEndOfMonth)) {
    return dateEndOfMonth.toDate();
  }

  return newDate(date).add(-1, 'month').endOf('month').toDate();
};

export function getDefaultDates(baseDate = today()) {
  const asOf = formatToShortDate(newDate(baseDate).startOf('day'));

  const query = {
    asOf,
  };

  return query;
}

export function sameDayAsToday(date) {
  return momentWithTimezone(date).isSame(today(), 'day');
}

export function isSameDay(date1, date2) {
  return newDate(date1).isSame(newDate(date2), 'day');
}

export function isSameMonth(date1, date2) {
  return newDate(date1).isSame(newDate(date2), 'month');
}

export function areSame(date1, date2) {
  return +momentWithTimezone(date1).toDate() === +momentWithTimezone(date2).toDate();
}

export function localizeDate(date, locale) {
  const localeData = moment.localeData(locale);
  const format = localeData.longDateFormat('L');

  return momentWithTimezone(date).format(format);
}

export function isBeforeNow(date) {
  return momentWithTimezone(date).isBefore(today());
}

export function isBeforeToday(date) {
  return momentWithTimezone(date).isBefore(today(), 'day');
}

export function isAfterToday(date) {
  return momentWithTimezone(date).isAfter(today(), 'day');
}

export function isAfter(date1, date2) {
  return moment(date1).isAfter(date2);
}

export const DateWithTimeZone = (props) => <FormattedDate timeZone={props.timezone || tenantTimezone} {...props} />;

DateWithTimeZone.propTypes = {
  timezone: PropTypes.string,
};

export function endOfDay(date) {
  return momentWithTimezone(date).endOf('day');
}

export function startOfDay(date) {
  return momentWithTimezone(date).startOf('day');
}

export function dateToTimezone(date, timeZone) {
  return formatToShortDate(momentWithTimezone(date, timeZone));
}

export function formatDate(date, format = defaultDateFormat) {
  return momentWithTimezone(date).format(format);
}

export function formatMaskedDate(date, mask, timeZone = tenantTimezone, format = defaultDateFormat) {
  return moment(date, mask).tz(timeZone).format(format);
}

export function formatToShortDate(date) {
  return momentWithTimezone(date).format(defaultDateFormat);
}

export function formatToShortDateWithoutTz(date) {
  return moment(date).format(defaultDateFormat);
}

export function setTenantTimezone(timezone) {
  tenantTimezone = timezone;
}

export function momentWithTimezone(date = moment(), timeZone = tenantTimezone) {
  return moment.tz(date, timeZone);
}

export function difference(date1, date2, unit = 'days') {
  const moment1 = moment(date1);
  const moment2 = moment(date2);

  return moment1.diff(moment2, unit);
}

export function isPast(date1, date2 = today()) {
  return moment(date1).isBefore(moment(date2));
}

export function decimalsDifference(dateOne, dateTwo, unit = 'days', withDecimals = true) {
  return newDate(dateOne).diff(newDate(dateTwo), unit, withDecimals);
}

export function from(date1, date2, suffix = true) {
  const moment1 = moment(date1);
  const moment2 = moment(date2);

  return moment1.from(moment2, suffix);
}

export const weekdays = moment.weekdays();

export const weekendDays = [0, 6];

export function addBusinessDays({ date, tPlusDays = 0 }) {
  let theDate = newDate(date);
  let addedBusinessDays = 0;

  while (tPlusDays > addedBusinessDays) {
    const nextDate = addDays(theDate, 1);

    if (isBusinessDay(nextDate)) {
      addedBusinessDays += 1;
    }

    theDate = nextDate;
  }

  return theDate;
}

export function getBusinessDate({ date }) {
  let theDate = endOfDay(date);
  let moveForward = true;

  while (!isBusinessDay(theDate)) {
    let nextDate = addDays(theDate, moveForward ? 1 : -1);

    if (!isSameMonth(theDate, nextDate)) {
      moveForward = false;
      nextDate = addDays(theDate, -1);
    }

    theDate = nextDate;
  }

  return theDate;
}

export function getPreviousBusinessDate({ date, tMinusDays = 0 }) {
  let theDate = newDate(date);
  let subtractedBusinessDays = tMinusDays;

  while (subtractedBusinessDays >= 0) {
    const nextDate = addDays(theDate, -1);

    if (isBusinessDay(nextDate)) {
      subtractedBusinessDays -= 1;
    }

    theDate = nextDate;
  }

  return theDate;
}

export function isWeekDay(date) {
  const theDate = newDate(date);
  const weekday = theDate.day();

  const sunday = 0;
  const saturday = 6;

  const isDayDuringTheWeek = weekday !== sunday && weekday !== saturday;

  return isDayDuringTheWeek;
}

export const isHoliday = (date) => dateHolidays.isHoliday(newDate(date).toDate());

export function isBusinessDay(date) {
  return isWeekDay(date) && !isHoliday(date);
}

export const getHolidaysInTheYear = (year) => dateHolidays.getHolidays(year).map(({ date }) => new Date(date));

export const holidaysInSurroundingYears = [-2, -1, 0, 1, 2, 3, 4, 5].flatMap((yearFactor) =>
  getHolidaysInTheYear(new Date().getFullYear() + yearFactor),
);
