import buildForm from '../../common/form/form';
import { fields } from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

export default function addTermDepositForm(onSave, currencySymbol) {
  return {
    title: 'addTermDepositOption',
    value: 'addTd',
    className: 'add-td-form',
    form: buildForm({
      fields: fields(currencySymbol),
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
