import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SummaryHighlight, Row } from '../../../../components/common';
import { tradePropTypes } from './trade-prop-types';

export const DetailsHeader = ({ trade }) => (
  <Row className="dialog-header" alignItemsCenter contentBetween>
    <SummaryHighlight top={<FormattedMessage id="holdingInstrumentCode" />} middle={trade.instrumentCode} />
    <SummaryHighlight top={<FormattedMessage id="customer" />} middle={trade.customerTenant.name} />
    <SummaryHighlight top={<FormattedMessage id="segment" />} middle={trade.customerTenant.segment} />
    {trade.offer && trade.offer.users && trade.offer.users.bankUser && (
      <SummaryHighlight top={<FormattedMessage id="sentBy" />} middle={trade.offer.users.bankUser.fullName} />
    )}
  </Row>
);

DetailsHeader.propTypes = {
  trade: tradePropTypes,
};
