import axios from 'axios';

export default async function (params, endpoint) {
  const { data } = await axios({
    method: 'get',
    url: `/trades/${endpoint}`,
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
}
