import * as uploadApiClient from '../api/upload';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAIL = 'UPDATE_FAIL';


export function uploadFile (file) {
  return async (dispatch) => {
    dispatch({ type: UPLOAD_FILE_REQUEST });

    try {
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        upload: await uploadApiClient.uploadFile(file),
      });
    } catch (error) {
      dispatch({
        type: UPLOAD_FILE_FAIL,
        error: error.response || error,
      });
    }
  };
}

export function update ({ id }) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_REQUEST });

    try {
      dispatch({
        type: UPDATE_SUCCESS,
        upload: await uploadApiClient.detail(id),
      });
    } catch (error) {
      dispatch({
        type: UPDATE_FAIL,
        error: error.response || error,
      });
    }
  };
}
