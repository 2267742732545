import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Glyphicon, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import Toggle from 'react-toggle';

import { interests } from '../../api/holding/codes';
import { getInstrumentCode, TD } from '../../api/holding/instrument-codes';
import upAndDownArrowsIcon from '../../assets/up-and-down-arrows-icon.svg';
import {
  Column,
  ConfirmationPopover,
  CurrencyInput,
  DateWithTimeZone,
  renderSelect,
  Row,
  Tooltip,
} from '../../components/common';
import { formatToShortDateWithoutTz } from '../../date';
import { isAcceptedOrReinvested } from '../../ducks/issuer-trades/list';
import { rejected } from '../../ducks/issuer-trades/statuses';
import {
  getFormattedNumberOrEmpty,
  number,
  percentual,
} from '../../format-numbers';
import { Money } from '../money';
import { CustomMaturityAndCouponModal } from './CustomMaturityAndCouponModal';

const isOpenStatus = (status) => !status || ['sent', 'read'].includes(status);

const getFundsOperation = (additionalFunds) => (additionalFunds > 0 ? 'additionalFunds' : 'partialRedeem');

const checkAdditionalFundsHasError = (principal, bid) => {
  const isLesserThanZero = principal <= 0;
  const isGreaterThanTheDoubleOfPrincipalAndAccruedSum = principal >= (bid.principal + bid.accruedInterest || 0) * 2;

  return isLesserThanZero || isGreaterThanTheDoubleOfPrincipalAndAccruedSum;
};

const isConfirmOfferDisabled = ({ additionalFundsHasError, bid, instrumentCode }) =>
  !bid.selectedOffer || additionalFundsHasError || (instrumentCode === TD && !bid.interestPaid);

export const OfferActions = injectIntl(
  ({
    currencySymbol,
    isLoading,
    bid,
    initialAccrued,
    status,
    instrumentCode,
    extraActions,
    onConfirmClick,
    onAdditionalFundsChange,
    onInterestPaidChange,
    onAdjustRateChange,
    onAccruedToggle,
    isInvestor,
    intl,
  }) => {
    const [shouldAddAccrued, setShouldAddAccrued] = useState(false);
    const [showSetTenorMaturityDate, setShowSetTenorMaturityDate] = useState(false);
    const [isAddingAdditionalFunds, setIsAddingAdditionalFunds] = useState(false);
    const [isPartialRedeem, setIsPartialRedeem] = useState(false);
    const principal = bid.principal + bid.accruedInterest + bid.additionalFunds;
    const rateChosenWithAdditionalFunds = !!bid.additionalFunds && isAcceptedOrReinvested(status);
    const additionalFundsHasError = checkAdditionalFundsHasError(principal, bid);

    const renderExtraActionButton = ({ buttonLabelId, confirmationMessageId, onClick }) =>
      confirmationMessageId ? (
        <ConfirmationPopover
          key={buttonLabelId}
          data-testid="confirm-button"
          buttonLabelId={buttonLabelId}
          confirmationMessageId={confirmationMessageId}
          onConfirm={onClick}
          className="extra-action"
        />
      ) : (
        <button
          key={buttonLabelId}
          data-testid={`extra-action-button-${buttonLabelId}`}
          type="button"
          onClick={onClick}
          className="extra-action"
        >
          <FormattedMessage tagName="span" id={buttonLabelId} />
        </button>
      );

    return (
      <div
        className={cx(`offer-actions ${instrumentCode && getInstrumentCode(instrumentCode).toLowerCase()}`, {
          active: instrumentCode === TD ? bid.selectedOffer && bid.interestPaid : bid.selectedOffer,
          rejected: status === rejected,
          'has-additional-funds': bid.additionalFunds !== 0,
        })}
      >
        <Column>
          <div className="upper-case">
            <FormattedMessage tagName="span" id="amountToReinvest" />
          </div>
          <Tooltip
            id="amount-to-reinvest"
            placement="bottom"
            tooltipComponent={<FormattedNumber {...number} value={principal} />}
          >
            <div
              className={cx('amount text-ellipsis', {
                'is-loading-skeleton': isLoading,
                'has-error': additionalFundsHasError,
              })}
            >
              <Money value={principal > 0 ? principal : 0} short />
            </div>
          </Tooltip>
          {instrumentCode === TD && (
            <Row className={cx({ 'is-loading-skeleton': isLoading })}>
              <Column flex={1}>
                <Row className="accrued" alignItemsCenter contentBetween>
                  <div>
                    <FormattedMessage tagName="span" id="offerAccrued" />
                    <Money value={initialAccrued} short />
                  </div>
                  {initialAccrued > 0 && isOpenStatus(status) && (
                    <Row alignItemsCenter contentCenter>
                      <FormattedMessage tagName="span" id={shouldAddAccrued ? 'added' : 'add'} />
                      <Toggle
                        data-testid="toggle-accrued"
                        checked={shouldAddAccrued}
                        icons={false}
                        onChange={({ target }) => {
                          const { checked } = target;

                          setShouldAddAccrued(checked);
                          onAccruedToggle(checked);
                        }}
                      />
                    </Row>
                  )}
                </Row>
              </Column>
            </Row>
          )}
        </Column>
        <hr />

        {status === rejected && (
          <Column className="rejected-offer">
            <div className="upper-case">
              <FormattedMessage tagName="span" id="optionSelected" />
            </div>
            <div className="option-selected">
              <FormattedMessage tagName="span" id="notInterested" />
            </div>
          </Column>
        )}

        <Column className="selected-offer">
          <div className="upper-case">
            <FormattedMessage tagName="span" id="selectedRate" />
          </div>
          <Row className={cx('rate', { selected: !!bid.selectedOffer })} contentEvenly>
            {bid.selectedOffer ? (
              <Row contentBetween alignItemsCenter onClick={() => setShowSetTenorMaturityDate(true)}>
                <Column
                  className={cx('maturity-date', {
                    'has-custom-rate': bid.customCoupon && +bid.customCoupon !== +bid.tenorFromRatesheet.rate,
                    'has-custom-maturity-date':
                      bid.customMaturityDate &&
                      bid.customMaturityDate !== formatToShortDateWithoutTz(bid.tenorFromRatesheet.maturityDate),
                  })}
                >
                  <div>
                    <FormattedMessage
                      tagName="span"
                      id={`offerTenor${bid.selectedOffer.unit}`}
                      values={{ count: bid.selectedOffer.time }}
                    />
                    /
                    <span>
                      <FormattedNumber {...percentual} value={bid.selectedOffer.rate} />%
                    </span>
                  </div>
                  <span>
                    <DateWithTimeZone value={bid.selectedOffer.maturityDate} />
                  </span>
                </Column>
                <Row className="edit" alignItemsCenter>
                  <Glyphicon glyph="pencil" />
                </Row>
              </Row>
            ) : (
              <FormattedMessage tagName="span" id="notSelected" />
            )}
          </Row>
          {instrumentCode === TD && (
            <React.Fragment>
              <div className="upper-case">
                <FormattedMessage tagName="span" id="interestPaid" />
              </div>
              <Column>
                {renderSelect({
                  defaultValue: bid.interestPaid,
                  name: 'interestPaid',
                  onDropdownFilterChange: onInterestPaidChange,
                  options: interests.map(({ code: value, label: id }) => ({
                    value,
                    label: intl.formatMessage({ id }),
                  })),
                  placeholder: intl.formatMessage({ id: 'selectInterestPaid' }),
                })}
              </Column>
            </React.Fragment>
          )}
        </Column>
        <hr />
        {isAddingAdditionalFunds || rateChosenWithAdditionalFunds ? (
          <Column className="additional-funds">
            <Row contentBetween alignItemsCenter>
              <div className="upper-case">
                <FormattedMessage
                  tagName="span"
                  id={isOpenStatus(bid.status) ? 'addOrRemoveFunds' : getFundsOperation(bid.additionalFunds)}
                />
              </div>
              <span
                role="button"
                className="fa-stack fa-sm"
                onKeyPress={() => {}}
                tabIndex={0}
                onClick={() => {
                  setIsAddingAdditionalFunds(false);
                  onAdditionalFundsChange(0);
                }}
              >
                <i className="fa fa-circle fa-stack-2x" />
                <i className="fa fa-times fa-stack-1x fa-inverse" />
              </span>
            </Row>
            <Row>
              <ToggleButtonGroup
                type="radio"
                name="amount-management"
                className="toggle-button-group"
                defaultValue="additionalFunds"
                justified
              >
                <ToggleButton
                  value="additionalFunds"
                  data-testid="additional-funds-toggle-option"
                  onClick={() => {
                    setIsPartialRedeem(false);
                    onAdditionalFundsChange(Math.abs(bid.additionalFunds));
                  }}
                >
                  <FormattedMessage tagName="span" id="additionalFunds" />
                </ToggleButton>
                <ToggleButton
                  value="partialRedeem"
                  data-testid="partial-redeem-toggle-option"
                  onClick={() => {
                    setIsPartialRedeem(true);
                    onAdditionalFundsChange(bid.additionalFunds * -1);
                  }}
                >
                  <FormattedMessage tagName="span" id="partialRedeem" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Row>
            <Column>
              <CurrencyInput
                className={cx('currency-input', {
                  'has-error': additionalFundsHasError,
                })}
                allowNegative={false}
                decimalScale={number.maximumFractionDigits}
                prefix={currencySymbol}
                value={bid.additionalFunds === 0 ? '' : bid.additionalFunds}
                autoFocus={!rateChosenWithAdditionalFunds}
                placeholder="insertValue"
                onChange={(event) => {
                  let { value } = event.target;

                  if (isPartialRedeem) {
                    value = `-${value}`;
                  }

                  onAdditionalFundsChange(value);
                }}
              />
              {additionalFundsHasError && (
                <div className="currency-input-error-message">
                  <FormattedMessage
                    tagName="span"
                    id="amountMustBeLowerThanPrincipal"
                    values={{
                      currencySymbol,
                      principal: getFormattedNumberOrEmpty({
                        value: shouldAddAccrued ? bid.principal + bid.accruedInterest : bid.principal,
                        format: '0,0.00',
                      }),
                    }}
                  />
                </div>
              )}
            </Column>
          </Column>
        ) : (
          <Row className="button-row" contentCenter alignItemsCenter>
            <button
              type="button"
              className="add-or-remove-funds-button"
              data-testid="enable-add-or-remove-funds-button"
              onClick={() => {
                onAdditionalFundsChange(0);
                setIsAddingAdditionalFunds(true);
              }}
            >
              <img alt="" src={upAndDownArrowsIcon} />
              <FormattedMessage tagName="span" id="addOrRemoveFunds" />
            </button>
          </Row>
        )}
        <hr />
        <Row className="confirm-reinvestment" contentCenter alignItemsCenter>
          <button
            type="button"
            data-testid="confirm-reinvestment-button"
            onClick={onConfirmClick}
            disabled={isConfirmOfferDisabled({
              additionalFundsHasError,
              bid,
              instrumentCode,
            })}
          >
            <FormattedMessage tagName="span" id="confirmReinvestment" />
          </button>
        </Row>
        {!isLoading && extraActions && extraActions.length > 0 && (
          <Row className="button-row extra-actions-row" contentCenter alignItemsCenter>
            {extraActions.map(({ buttonLabelId, confirmationMessageId, onClick }) =>
              renderExtraActionButton({
                buttonLabelId,
                confirmationMessageId,
                onClick,
              }),
            )}
          </Row>
        )}
        {bid.selectedOffer && (
          <CustomMaturityAndCouponModal
            show={showSetTenorMaturityDate}
            onConfirm={({ customMaturityDate, customCoupon }) => {
              onAdjustRateChange({ customMaturityDate, customCoupon });
              setShowSetTenorMaturityDate(false);
            }}
            bid={bid}
            onCancel={() => setShowSetTenorMaturityDate(false)}
            onHide={() => setShowSetTenorMaturityDate(false)}
            isInvestor={isInvestor}
          />
        )}
      </div>
    );
  },
);

OfferActions.propTypes = {
  isLoading: PropTypes.bool,
  isInvestor: PropTypes.bool,
  initialAccrued: PropTypes.number,
  status: PropTypes.string,
  instrumentCode: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
  extraActions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        buttonLabelId: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    ),
    PropTypes.bool,
  ]),
  bid: PropTypes.shape({
    accruedInterest: PropTypes.number,
    additionalFunds: PropTypes.number,
    interestPaid: PropTypes.string,
    principal: PropTypes.number,
    status: PropTypes.string,
    customCoupon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    customMaturityDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    selectedOffer: PropTypes.shape({
      time: PropTypes.string,
      unit: PropTypes.string,
      rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maturityDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
    tenorFromRatesheet: PropTypes.shape({
      rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maturityDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
  }),
  onConfirmClick: PropTypes.func.isRequired,
  onInterestPaidChange: PropTypes.func.isRequired,
  onAdjustRateChange: PropTypes.func.isRequired,
  onAccruedToggle: PropTypes.func.isRequired,
  onAdditionalFundsChange: PropTypes.func.isRequired,
};

OfferActions.defaultProps = {
  bid: {},
  initialAccrued: 0,
  status: undefined,
  instrumentCode: undefined,
  isLoading: false,
};
