import './notification.scss';

import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

class Notification extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    bsStyle: PropTypes.string,
    isVisible: PropTypes.bool,
    onNotificationDismiss: PropTypes.func,
    delay: PropTypes.number,
  };

  static defaultProps = {
    delay: 8000,
    isVisible: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: props.isVisible,
    };
  }

  componentDidMount() {
    this.initDismissTimer();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.isVisible === nextProps.isVisible) {
      return;
    }

    this.setState({ isVisible: nextProps.isVisible });
  }

  componentDidUpdate() {
    clearTimeout(this.dismissTimer);
    this.initDismissTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.dismissTimer);
    this.props.onNotificationDismiss && this.props.onNotificationDismiss();
  }

  initDismissTimer() {
    if (!this.props.delay) {
      return;
    }

    this.dismissTimer = setTimeout(this.handleAlertDismiss, this.props.delay);
  }

  handleAlertDismiss = () => {
    this.setState({ isVisible: false });
    this.props.onNotificationDismiss && this.props.onNotificationDismiss();
  };

  handleAlertShow() {
    this.setState({ isVisible: true });
  }

  render() {
    if (!this.state.isVisible) {
      return null;
    }

    const { bsStyle, className, children } = this.props;

    return (
      <div className="user-notification">
        <Alert
          bsStyle={bsStyle}
          className={classNames(className)}
          onDismiss={this.handleAlertDismiss}
        >
          {children}
        </Alert>
      </div>
    );
  }
}

export default Notification;
