import Valida from 'valida';
import { asAtValidator } from './validation';

const defaultSchema = {
  date: [
    { validator: asAtValidator },
  ],
};

export function buildFilter (filter, currentFilter) {
  const newFilter = {
    ...currentFilter,
    ...filter,
  };

  const allRegex = /^all$/ig;

  return Object.keys(newFilter).reduce((builtFilter, key) => {
    if (allRegex.test(builtFilter[key]) || builtFilter[key] === '') {
      delete builtFilter[key]; // eslint-disable-line no-param-reassign
    }

    return builtFilter;
  }, newFilter);
}

export function validaParams (params, schema = defaultSchema) {
  Valida.process(params, schema, (error, context) => {
    if (context.isValid()) { return; }

    Object.keys(context.errors())
      .forEach(invalidProperty =>
        delete params[invalidProperty]); // eslint-disable-line no-param-reassign
  });
}

export function validateAvailableFilters (params, availableFilters, schema = defaultSchema) {
  Object.keys(availableFilters).forEach(key => (
    schema[key] = [ // eslint-disable-line no-param-reassign
      { sanitizer: Valida.Sanitizer.lowerCase },
    ]
  ));

  validaParams(params, schema);
}

const holdingFilterKeys = ['allocationCode', 'instrumentCode', 'searchText', 'maturity'];

export const areAnyHoldingFiltersApplied = filter =>
  areAnyResultsFilterApplied(filter, holdingFilterKeys);

const areAnyResultsFilterApplied = (filter, keys) => filter
  && Object.keys(filter).some(key => keys.includes(key));
