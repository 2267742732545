import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

export const RadioItem = ({ className, labelId, onChange, value }) => (
  <label className={cx('radio-item radio-item-box', className)}>
    <FormattedMessage id={labelId} />
    <input type="radio" name="radio" onChange={() => onChange && onChange(value)} />
    <span className="circle" />
  </label>
);

RadioItem.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  labelId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
