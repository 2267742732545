import axios from 'axios';
import { formatToShortDate, newDate } from '../../date';

export default async function(params) {
  const { data } = await axios({
    method: 'get',
    routeType: 'global',
    url: '/isins',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
}

export const buildListIsins = ({ maturity } = { maturity: formatToShortDate(newDate()) }) => ['/isins', maturity];

export const listIsins = async (url, maturity) => {
  const { data } = await axios({
    method: 'get',
    routeType: 'global',
    url,
    params: {
      query: {
        maturity: {
          $gt: maturity,
        },
      },
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
};
