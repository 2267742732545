import '../../buy-bond-create-rfq.scss';

import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';

import { createBuyBondsRfq } from '../../../../../api/holding/rfq/buy-bonds/create-buy-bonds-rfq';
import rooms from '../../../../../api/socket/rooms';
import {
  ConfirmationPopover,
  DatePickerField,
  FormikCurrencyInput,
  ValidatedFormikField,
} from '../../../../../components/common';
import { FormikResetFormHandler } from '../../../../../components/common/formik/FormikResetFormHandler';
import includeSocket, { socketEvents } from '../../../../../components/hoc/include-socket';
import { addBusinessDays, DateWithTimeZone, formatToShortDateWithoutTz } from '../../../../../date';
import { buyBondRfqCreateRfqClicked, track } from '../../../../../event-tracker';
import {
  convertStringWithDelimiterToInt,
  emptyCharacter,
  toCompactCurrency,
  toNumberFormat,
} from '../../../../../format-numbers';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../toast/toast';
import { fromValueToNominalUnitQuantity } from '../../../rfq-nominal-values';
import {
  getInstrumentCodeDescription,
  getInstrumentCouponOrMargin,
  getIsinYieldOrMarginDescription,
} from '../../bond-frn-descriptions';
import { IsinsFormikField } from '../../IsinsFormikField';
import { RfqBankSelectionHasAustraclear } from '../../RfqBankSelection/RfqBankSelectionHasAustraclear';
import { buildBuyBondCreateRfqValidationSchema } from './create-rfq-schema';

export const buildBuyBondsCreateRfqInitialValues = () => ({
  quantityToBuy: '',
  safeCustodyCode: '',
  issuerCodes: [],
  settlementDate: addBusinessDays({ tPlusDays: 2 }).toDate(),
  isin: undefined,
  hasAustraclear: false,
});

export const BuyBondCreateRfqComponent = ({ emit, initialValues, onBuyBondCreateRfqSuccess, currency, intl }) => {
  const [loading, setLoading] = useState(false);
  const [selectedIsin, setSelectedIsin] = useState();

  const onSubmit = async ({ quantityToBuy, issuerCodes, isin, hasAustraclear, settlementDate }) => {
    setLoading(true);

    try {
      const { notifyDomains: tenants } = await createBuyBondsRfq({
        issuerCodes,
        isin,
        settlementDate: formatToShortDateWithoutTz(settlementDate),
        hasAustraclear,
        quantityToBuy: fromValueToNominalUnitQuantity({ value: quantityToBuy }),
      });

      emit(socketEvents.rfqUpdated, {
        tenants,
        room: rooms.rfq,
      });

      track(buyBondRfqCreateRfqClicked);

      showToastMessage(intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);

      onBuyBondCreateRfqSuccess && onBuyBondCreateRfqSuccess();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={buildBuyBondCreateRfqValidationSchema({ intl })}
      onSubmit={onSubmit}
    >
      {({ touched, errors, values, handleSubmit, resetForm }) => (
        <Form className="buy-sell-bonds-create-rfq-form">
          <FormikResetFormHandler
            resetForm={() => {
              resetForm();
              setSelectedIsin(undefined);
            }}
            dependencies={initialValues}
          />
          <Column className="form-group-item">
            <IsinsFormikField onChange={setSelectedIsin} />
          </Column>
          <dl className="highlight">
            <div>
              <FormattedMessage tagName="dt" id="holdingIssuer" />
              <dd>{selectedIsin ? selectedIsin.issuerName : emptyCharacter}</dd>
            </div>
            <div>
              <FormattedMessage tagName="dt" id="type" />
              <dd>{selectedIsin ? getInstrumentCodeDescription(selectedIsin.instrumentCode) : emptyCharacter}</dd>
            </div>
            <div>
              <FormattedMessage tagName="dt" id="holdingIsins" />
              <dd>{selectedIsin ? selectedIsin.isin : emptyCharacter}</dd>
            </div>
            <div>
              <FormattedMessage tagName="dt" id="holdingMaturityDate" />
              <dd>{selectedIsin ? <DateWithTimeZone value={selectedIsin.maturity} /> : emptyCharacter}</dd>
            </div>

            {selectedIsin && (
              <div>
                <dt>{getIsinYieldOrMarginDescription(selectedIsin.instrumentCode)}</dt>
                <dd>
                  {toNumberFormat({
                    value: getInstrumentCouponOrMargin(selectedIsin),
                  })}
                </dd>
              </div>
            )}
          </dl>
          <Row className="form-group-item">
            <ValidatedFormikField
              id="quantityToBuy"
              name="quantityToBuy"
              labelId="faceValueToBuy"
              labelParams={{ currency }}
              data-testid="quantityToBuy"
              value={values.quantityToBuy}
              component={FormikCurrencyInput}
              touched={touched}
              errors={errors}
            />
            <ValidatedFormikField
              id="settlementDate"
              name="settlementDate"
              labelId="standardSettlementDate"
              data-testid="settlementDate"
              value={values.settlementDate}
              component={(props) => <DatePickerField disableWeekends disableHolidays {...props} minDate={new Date()} />}
              touched={touched}
              errors={errors}
            />
          </Row>
          <RfqBankSelectionHasAustraclear
            defaultSelectedIssuers={values.issuerCodes.length ? values.issuerCodes : initialValues.issuerCodes}
          />
          <Column>
            <ConfirmationPopover
              className="btn-solid-primary"
              onConfirm={handleSubmit}
              isConfirmingFromOutside={loading}
              confirmationMessageId="buyBondCreateRfqConfirmation"
              confirmationMessageValues={{
                buyingValue: toCompactCurrency({
                  value: convertStringWithDelimiterToInt(values.quantityToBuy),
                }),
                issuerCodes: values.issuerCodes.join(', '),
                issuerCodesLength: values.issuerCodes.length,
              }}
              buttonLabelId="buyBondCreateRfq"
              popoverClass="light drawer-popover"
            />
          </Column>
        </Form>
      )}
    </Formik>
  );
};

BuyBondCreateRfqComponent.propTypes = {
  onBuyBondCreateRfqSuccess: PropTypes.func,
  currency: PropTypes.string.isRequired,
  initialValues: PropTypes.shape(),
  emit: PropTypes.func,
};

BuyBondCreateRfqComponent.defaultProps = {
  initialValues: buildBuyBondsCreateRfqInitialValues(),
};

export const BuyBondCreateRfq = compose(includeSocket({ rooms: [rooms.rfq] }), injectIntl)(BuyBondCreateRfqComponent);
