import { createSelector } from 'reselect';
import orderBy from 'lodash.orderby';
import * as statuses from './statuses';

const getIssuerTrades = (issuerTrades) => issuerTrades.list;

const getIssuerTradesFilter = (issuerTrades) => issuerTrades.filter;

const getIssuerTradesFilteredByStatus = createSelector([getIssuerTrades, getIssuerTradesFilter], (list, filter) =>
  list.filter((trade) => !filter.status || trade.status === filter.status),
);

const getIssuerTradesFilteredByCustomer = createSelector(
  [getIssuerTradesFilteredByStatus, getIssuerTradesFilter],
  (filteredByStatus, filter) =>
    filteredByStatus.filter(
      ({ customerTenant }) => !filter.customer || customerTenant.name.match(new RegExp(filter.customer, 'i')),
    ),
);

const getIssuerTradesFilteredByInstrumentCode = createSelector(
  [getIssuerTradesFilteredByCustomer, getIssuerTradesFilter],
  (filtered, filter) =>
    filtered.filter(({ instrumentCode }) => !filter.instrumentCode || instrumentCode === filter.instrumentCode),
);

export const getIssuerTradesSummary = (state) => state.issuerTrades.summary;

export const statusesWeight = {
  [statuses.unactioned]: 1,
  [statuses.rfqInProgress]: 2,
  [statuses.reinvested]: 3,
  [statuses.reinvestedOnBehalf]: 4,
  [statuses.addTradeOnBehalf]: 5,
  [statuses.sent]: 6,
  [statuses.read]: 7,
  [statuses.redeemed]: 8,
  [statuses.redeemedOnBehalf]: 9,
  [statuses.rejected]: 10,
};

const sortByStatus = ({ status }) => statusesWeight[status];

export const getFilteredIssuers = createSelector(
  [getIssuerTradesFilteredByInstrumentCode, getIssuerTradesFilter],
  (filteredByStatusAndCustomer, filter) => {
    const filteredIssuers = filteredByStatusAndCustomer.filter(
      ({ customerTenant }) => !filter.segment || customerTenant.segmentCode === filter.segment,
    );

    return filter.sort
      ? orderBy(filteredIssuers, filter.sort.by === 'status' ? sortByStatus : [filter.sort.by], [filter.sort.order])
      : filteredIssuers;
  },
);
