import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const CleanNavItem = ({ children, className }) => <div className={classNames(className)}>{children}</div>;

CleanNavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CleanNavItem;
