import React from 'react';

import propTypes from 'prop-types';
import useSWR from 'swr';

import {
  buildBuyBondsIncomingRfqsDetailsUrl,
  listBuyBondsIncomingRfqsDetails,
} from '../../../../../api/holding/rfq/buy-bonds/list-buy-bonds-incoming-rfqs-details';
import imperiumLogo from '../../../../../assets/grey-imperium.svg';
import { Drawer, ErrorComponent } from '../../../../../components/common';
import { BuyerBuySellBondRfqStatus } from '../../buy-sell-bond-rfq-status';
import { SendBuyBondsRfqQuote } from './SendBuyBondsRfqQuote';

export const SendBuyBondsRfqQuoteDrawer = ({ rfqUUID, onCloseDrawer, onQuoteUpdatedSuccess }) => {
  const { data: rfqDetails, error, mutate } = useSWR(
    rfqUUID && buildBuyBondsIncomingRfqsDetailsUrl({ rfqUUID }),
    listBuyBondsIncomingRfqsDetails,
  );

  const onDrawerSaveIssuerQuoteSuccess = () => {
    mutate();
    onQuoteUpdatedSuccess && onQuoteUpdatedSuccess();
    onCloseDrawer();
  };

  const drawerContent = error ? (
    <ErrorComponent message="sorryThereWasAnError" showFooter={false} />
  ) : (
    <SendBuyBondsRfqQuote
      rfq={rfqDetails}
      isLoading={!rfqDetails}
      onQuoteUpdatedSuccess={onDrawerSaveIssuerQuoteSuccess}
    />
  );

  return (
    <Drawer
      titleId="buyBondRfqSendQuotesTitle"
      className="light save-issuer-quote-drawer"
      shouldCloseOnClickOutside={false}
      headerComponent={rfqDetails && <BuyerBuySellBondRfqStatus rfq={rfqDetails} verbose />}
      open={!!rfqUUID}
      onClose={onCloseDrawer}
      width="40vw"
      footer={<img alt="Imperium Markets" src={imperiumLogo} />}
    >
      {drawerContent}
    </Drawer>
  );
};

SendBuyBondsRfqQuoteDrawer.propTypes = {
  rfqUUID: propTypes.string,
  onCloseDrawer: propTypes.func,
  onQuoteUpdatedSuccess: propTypes.func,
};
