import React from 'react';

export const statusOptions = [
  { label: 'All', value: '' },
  { label: 'In progress', value: 'IN_PROGRESS' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Rejected', value: 'REJECTED' },
];

export const ratesheetOptions = [
  { label: 'Default', value: 'DEFAULT' },
  { label: 'Bespoke', value: 'BESPOKE' },
];

export const getColumns = () => [
  {
    label: 'customerName',
    key: 'customerName',
    show: true,
  },
  {
    label: 'customerId',
    key: 'customerId',
    show: true,
    presenter: ({ customerId }) => <td>{customerId || '-'}</td>,
  },
  {
    label: 'abn',
    key: 'abn',
    show: true,
  },
  {
    label: 'anzsciCode',
    key: 'anzsciCode',
    show: true,
    presenter: ({ anzsciCode }) => <td>{anzsciCode || '-'}</td>,
  },
  {
    label: 'segmentCode',
    key: 'segmentCode',
    show: true,
  },
  {
    label: 'ratesheet',
    key: 'ratesheet',
    show: true,
    presenter: ({ ratesheet }) => <td>{getOptionLabel(ratesheet, ratesheetOptions)}</td>,
  },
  {
    label: 'status',
    key: 'acceptanceStatus',
    show: true,
    presenter: ({ acceptanceStatus }) => <td>{getOptionLabel(acceptanceStatus, statusOptions)}</td>,
  },
];

const getOptionLabel = (optionValue, options) => {
  const option = options.find(({ value }) => value === optionValue);

  return option && option.label;
};
