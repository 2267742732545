import clone from 'lodash.clonedeep';
import { createActionThunk } from 'redux-thunk-actions';

import { listMaturingTrades as getMaturingTrades } from '../../api/issuer-trades';
import { actions } from './actions';
import { tradeSources } from '../../api/holding/codes';

import * as statuses from './statuses';

export const listMaturingTrades = createActionThunk(actions.getMaturingTrades, ({ to }) => getMaturingTrades({ to }));
export const filterMaturingTrades = createActionThunk(actions.filterIssuerTradesEmail, (filter) => ({ filter }));
export const updateIssuerTrade = createActionThunk(actions.updateIssuerTrade, ({ id, ...props }) => ({ id, props }));
export const addOfferToIssuerTrade = createActionThunk(actions.addOfferToIssuerTrade, (createdOffer) => ({
  createdOffer,
}));

export const listMaturingTradesReducer = {
  [listMaturingTrades.STARTED]: (state, { payload: [{ showLoading }] }) => ({
    ...state,
    error: null,
    isFetching: true,
    showLoading: showLoading === undefined ? true : showLoading,
  }),
  [listMaturingTrades.SUCCEEDED]: (state, { payload }) => {
    const nextList = buildMaturingTrades(payload.list);

    return {
      ...state,
      list: nextList,
      summary: getSummary(nextList),
      isFetching: false,
      showLoading: false,
    };
  },
  [listMaturingTrades.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [listMaturingTrades.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    statusCode: null,
  }),
  [filterMaturingTrades.STARTED]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [filterMaturingTrades.SUCCEEDED]: (state, { payload: { filter } }) => ({
    ...state,
    filter,
    isFetching: false,
  }),
  [updateIssuerTrade.SUCCEEDED]: (state, { payload: { id, props } }) => {
    const nextList = clone(state.list);
    const relatedTradeOfferIndex = nextList.findIndex((issuerTrade) => issuerTrade.id === id);
    const trade = {
      ...clone(nextList[relatedTradeOfferIndex]),
      ...props,
    };

    nextList.splice(relatedTradeOfferIndex, 1, trade);

    return {
      ...state,
      summary: getSummary(nextList),
      list: nextList,
      isFetching: false,
    };
  },
  [addOfferToIssuerTrade.SUCCEEDED]: (state, { payload: { createdOffer } }) => {
    const nextList = clone(state.list);
    const relatedTradeOfferIndex = nextList.findIndex(
      ({ id, customerTenant: { id: tenantId } }) => createdOffer.tradeId === id && createdOffer.tenantId === tenantId,
    );
    const trade = clone(nextList[relatedTradeOfferIndex]);

    trade.offer = createdOffer;
    trade.status = createdOffer.status;

    nextList.splice(relatedTradeOfferIndex, 1, trade);

    return {
      ...state,
      summary: getSummary(nextList),
      list: nextList,
      isFetching: false,
    };
  },
};

export const buildMaturingTrades = (trades) => trades.map((trade) => ({ ...trade, status: trade.maturingStatus }));

export const initialCounters = {
  rfqInProgress: 0,
  unactioned: 0,
  oneDayRollover: 0,
  rejected: 0,
  redeemed: 0,
  reinvested: 0,
};

export const initialTotal = {
  maturing: 0,
  rejected: 0,
  reinvested: 0,
  redeemed: 0,
};

export const initialSummary = {
  counters: { ...initialCounters },
  total: { ...initialTotal },
};

const reinvestedStatuses = [statuses.reinvestedOnBehalf, statuses.reinvested];
const redeemedStatuses = [statuses.redeemedOnBehalf, statuses.redeemed];

/* eslint-disable no-param-reassign */
const getSummary = (trades) =>
  trades.reduce(
    (summary, trade) => {
      const { status, offer, isRfqClosed } = trade;
      const { counters } = summary;

      counters.oneDayRollover += status === statuses.oneDayRollover ? 1 : 0;
      counters.unactioned += status === statuses.unactioned ? 1 : 0;
      counters.rejected += status === statuses.rejected ? 1 : 0;
      counters.rfqInProgress += status === statuses.rfqInProgress && !isRfqClosed ? 1 : 0;
      counters.redeemed += redeemedStatuses.includes(status) ? 1 : 0;
      counters.reinvested += isAcceptedOrReinvested(status) ? 1 : 0;

      summary.total.maturing += trade.principal || 0;
      summary.total.redeemed += redeemedStatuses.includes(status) && trade.principal ? trade.principal : 0;
      summary.total.reinvested += getReinvestedPrincipal({ status, offer, trade }) || 0;
      summary.total.rejected += status === statuses.rejected && trade.principal ? trade.principal : 0;

      return summary;
    },
    {
      counters: {
        ...initialCounters,
      },
      total: {
        ...initialTotal,
      },
    },
  );
/* eslint-enable */

const getReinvestedPrincipal = ({ status, offer, trade }) => {
  if (!reinvestedStatuses.includes(status)) {
    return 0;
  }

  if (trade.source === tradeSources.rolloverOnBehalf || !offer) {
    return trade.principal;
  }

  const [bid] = offer.bids;
  const additionalFunds = bid.additionalFunds || 0;
  const accrued = bid.accrued || 0;

  return trade.principal + additionalFunds + accrued;
};

export const isAcceptedOrReinvested = (status) =>
  [statuses.accepted, statuses.reinvested, statuses.reinvestedOnBehalf].includes(status);
