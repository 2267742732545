import { tradesSummaryFetchPayload } from '../../actions/holding/holding';
import {
  isinsFetchActionPayload,
  issuersFetchActionPayload,
  saveHoldingFetchPayload,
  sellableHoldingsFetchActionPayload,
  termDepositIssuersFetchActionPayload,
} from '../../actions/holding/save';
import { initialGeneralAllocationCode } from '../../api/holding/codes';
import { isAfterToday, localizeDate, sameDayAsToday } from '../../date';

const WITHOUT_TRADES = 'withoutTrades';
const HAS_TRADES = 'hasTrades';
const SORT_ALPHABETICALLY_PROPERTY = 'shortName';
const termDepositIssuers = 'termDepositIssuers';

export const INITIAL_STATE = {
  isSaving: false,
  isSaved: false,
  isFetchingIssuers: false,
  error: null,
  issuers: null,
  [termDepositIssuers]: null,
  isins: null,
  allocationCodes: [initialGeneralAllocationCode],
  sellables: null,
};

const actions = {
  [saveHoldingFetchPayload.REQUEST]: onSaveRequest,
  [saveHoldingFetchPayload.SUCCESS]: onSaveSuccess,
  [saveHoldingFetchPayload.FAIL]: onSaveFail,
  [issuersFetchActionPayload.SUCCESS]: onFetchIssuersSuccess.bind(null, 'issuers'),
  [termDepositIssuersFetchActionPayload.SUCCESS]: onFetchIssuersSuccess.bind(null, termDepositIssuers),
  [isinsFetchActionPayload.SUCCESS]: onFetchIsinsSuccess,
  [tradesSummaryFetchPayload.SUCCESS]: onFetchSummary,
  [sellableHoldingsFetchActionPayload.SUCCESS]: onFetchSellableHoldingsSuccess,
  RESET_SAVE_HOLDING_DETAILS: onReset,
  PUSH_NOTIFICATION: onPushNotification,
  CLEAN_NOTIFICATION: onCleanNotification,
  UPDATE_LOCALE: onUpdateLocale,
};

export default function reducer(state = INITIAL_STATE, action) {
  return actions[action.type] ? actions[action.type](state, action) : state;
}

const onNextState = (state, rest) => ({
  ...state,
  error: null,
  ...rest,
});

function onSaveRequest(state) {
  return onNextState(state, {
    isSaving: true,
  });
}

function onSaveSuccess(state) {
  return onNextState(state, {
    isSaved: true,
    isSaving: false,
    notificationId: 'savedSuccessfuly',
  });
}

function onSaveFail(state, action) {
  return onNextState(state, {
    isSaved: false,
    isSaving: false,
    error: action.error,
  });
}

function onFetchIssuersSuccess(issuersProperty, state, action) {
  const tradeGroupProperty = (issuer) => (issuer.tradesCount === '0' ? WITHOUT_TRADES : HAS_TRADES);

  const groupedIssuers = groupIssuers(action.issuers.list, tradeGroupProperty);

  return onNextState(state, {
    [issuersProperty]: presenterIssuers(groupedIssuers),
  });
}

function onFetchIsinsSuccess(state, action) {
  const isins = action.isins.list;

  const hasBondType = ({ type }) => type === 'Fixed Interest Bonds' || type === 'Bond';

  const hasFrnType = ({ type }) => type === 'Floating Rate Notes' || type === 'FRN';

  const hasFrtdType = ({ type }) => type === 'Floating Rate TDs' || type === 'FRTD';

  const bondsIsins = isins.filter((isin) => hasBondType(isin));
  const frnIsins = isins.filter((isin) => hasFrnType(isin));
  const frtdIsins = isins.filter((isin) => hasFrtdType(isin));
  const notMaturedBondsIsins = bondsIsins.filter((isin) => checkNonMaturedDate(isin));
  const notMaturedFrnIsins = frnIsins.filter((isin) => checkNonMaturedDate(isin));
  const notMaturedFrtdIsins = frtdIsins.filter((isin) => checkNonMaturedDate(isin));

  return onNextState(state, {
    isins: {
      bonds: presenterIsins(notMaturedBondsIsins, state.locale),
      frns: presenterIsins(notMaturedFrnIsins, state.locale),
      frtds: presenterIsins(notMaturedFrtdIsins, state.locale),
    },
  });
}

function onFetchSellableHoldingsSuccess(state, action) {
  const sellables = action.sellables.trades;

  return onNextState(state, {
    sellables,
  });
}

function checkNonMaturedDate({ maturity }) {
  return isAfterToday(maturity) || sameDayAsToday(maturity);
}

const groupIssuers = (issuers, by) =>
  issuers.reduce((grouped, issuer) => {
    const property = by(issuer);
    if (!grouped[property]) {
      grouped[property] = []; // eslint-disable-line no-param-reassign
    }
    grouped[property].push(issuer);
    return grouped;
  }, {});

function presenterIssuers(groupedIssuers) {
  const sortByName = sortAlphabetically.bind(null, SORT_ALPHABETICALLY_PROPERTY);

  return [
    ...(groupedIssuers[HAS_TRADES] ? groupedIssuers[HAS_TRADES].sort(sortByName) : []),
    ...(groupedIssuers[WITHOUT_TRADES] ? groupedIssuers[WITHOUT_TRADES].sort(sortByName) : []),
  ].map((issuer) => ({
    value: issuer.id.toString(),
    label: issuer.shortName,
    domain: issuer.domain,
  }));
}

function presenterIsins(isins = [], locale = null) {
  return isins.map((isin) => {
    const value = isin.isin;
    const label = `${isin.isin} (${isin.issuer}) - ${localizeDate(isin.maturity, locale)}`;

    return { value, label };
  });
}

function onFetchSummary(state, action) {
  const sortByLabel = sortAlphabetically.bind(null, 'label');

  const allocationCodes = action.summary.allocationCodes.length
    ? action.summary.allocationCodes.map((allocation) => ({
        value: allocation.code,
        label: allocation.code,
      }))
    : INITIAL_STATE.allocationCodes;

  return {
    ...state,
    allocationCodes: allocationCodes.sort(sortByLabel),
  };
}

function onReset(state) {
  const sortByLabel = sortAlphabetically.bind(null, 'label');

  return {
    ...INITIAL_STATE,
    issuers: state.issuers,
    [termDepositIssuers]: state[termDepositIssuers],
    isins: state.isins,
    allocationCodes: state.allocationCodes
      .map((allocation) => ({
        value: allocation.value.toUpperCase(),
        label: allocation.label.toUpperCase(),
      }))
      .sort(sortByLabel),
  };
}

const sortAlphabetically = (property, prev, next) =>
  // eslint-disable-next-line no-nested-ternary
  prev[property] < next[property] ? -1 : prev[property] > next[property] ? 1 : 0;

function onPushNotification(state, { notificationId }) {
  return onNextState(state, {
    notificationId,
  });
}

function onCleanNotification(state) {
  return onNextState(state, {
    notificationId: null,
  });
}

function onUpdateLocale(state, action) {
  return onNextState(state, {
    locale: action.locale,
  });
}
