import './index.scss';

import React, { Component } from 'react';

import debounce from 'debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as creditActions from '../../actions/compliances/credit';
import * as sessionActions from '../../actions/session';
import { ChartsPresenter, ResultsPresenter } from '../../components/common';
import * as chartsPresenter from '../../components/credit-quality/chart-payload';
import * as filterPresenter from '../../components/credit-quality/filter-presenter';
import * as recordsPresenter from '../../components/credit-quality/records-presenter';
import errorHandler from '../../components/hoc/error-handler';
import { withSearchParams } from '../../components/hoc/with-router-properties';
import { getMoneySymbol } from '../money';

function mapStateToProps(state) {
  return {
    credit: state.credit,
    currency: state.session.user.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      credit: bindActionCreators(creditActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
    },
  };
}

export class CreditQuality extends Component {
  constructor(props) {
    super(props);
    this.onSearchChangeDebounced = debounce(this.onSearchChange, 500);
  }

  componentDidMount() {
    this.updateFilterByLocationOnMount();
    this.fetchCreditIfNeeded(this.props.credit.filter || this.props.searchParamsEntries);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.shouldUpdateLocationQuery(this.props, nextProps)) {
      return;
    }

    this.updateFilteredData(nextProps);
    this.props.actions.session.updateLocationQuery(nextProps.credit.filter);
  }

  onDropdownFilterChange = (type, value) => {
    this.props.actions.credit.updateFilter({ [type]: value });
  };

  onSearchChange = (searchText) => {
    this.props.actions.credit.updateFilter({ searchText });
  };

  onResetFilter = () => {
    this.props.actions.credit.resetFilter();
  };

  updateFilteredData(nextProps) {
    if (!nextProps.credit.credit || nextProps.credit.isFetching || nextProps.credit.filteredData) {
      return;
    }

    this.props.actions.credit.updateFilteredData(nextProps.credit);
  }

  shouldUpdateLocationQuery = ({ credit: { filter } }, { credit: { filter: nextPropsFilter } }) => {
    return nextPropsFilter && !isEqual(filter, nextPropsFilter);
  };

  updateFilterByLocationOnMount() {
    if (!Object.keys(this.props.searchParamsEntries).length) {
      return;
    }

    this.props.actions.credit.updateFilter(this.props.searchParamsEntries);
    this.props.actions.session.updateLocationQuery(this.props.searchParamsEntries);
  }

  filterRatings = (term, credit) => {
    return credit && credit[term]
      ? credit[term].ratings.filter((rating) => rating.tradesCount !== 0 || rating.maxLimitPct !== 0)
      : null;
  };

  fetchCreditIfNeeded(params) {
    this.props.actions.credit.fetchCreditIfNeeded(params);
  }

  renderRecords(creditReducer) {
    const { credit, isFetching, filter, availableFilters, filteredData } = creditReducer;

    const noRecords = !credit || !(filteredData || credit.ratings).length;
    const data = filteredData || (credit && credit.ratings);

    const payload = {
      isFetching,
      noRecords,
      recordsPresenter: {
        data: credit && data,
        ...recordsPresenter,
      },
      filter: {
        isFetching,
        filter,
        availableFilters,
        onDropdownFilterChange: this.onDropdownFilterChange,
        actions: {
          size: { xs: 2 },
          onSearchChange: this.onSearchChangeDebounced,
          onResetFilter: this.onResetFilter,
        },
        summary: {
          count: data && data.length,
          sum: data && data.reduce((sum, item) => sum + item.amountInvested, 0),
        },
        ...filterPresenter,
      },
      titleId: 'credit-quality.resultsTitle',
    };

    return <ResultsPresenter {...payload} />;
  }

  renderCharts({ credit, isFetching }, currency) {
    const longTermOnly = credit && credit.longTermComplianceOnly;
    const charts = [];

    const { series, xTicks, tooltips } = chartsPresenter;
    const moneySymbol = getMoneySymbol({ currency, short: true });

    if (!longTermOnly) {
      charts.push({
        id: 'credit-quality-short',
        titleId: 'credit-quality.shortHoldings',
        isFetching,
        data: this.filterRatings('short', credit),
        type: 'bar',
        series,
        xTicks,
        tooltips: tooltips(moneySymbol),
      });
    }
    charts.push({
      id: 'credit-quality-long',
      titleId: 'credit-quality.longHoldings',
      isFetching,
      data: this.filterRatings('long', credit),
      type: 'bar',
      series,
      xTicks,
      tooltips: tooltips(moneySymbol),
    });

    const payload = {
      charts,
      options: {
        linkedOptions: true,
      },
    };

    return <ChartsPresenter {...payload} />;
  }

  render() {
    const { credit, currency } = this.props;

    return (
      <div className="credit-quality">
        <Col>
          {this.renderCharts(credit, currency)}
          <Col>{this.renderRecords(credit)}</Col>
        </Col>
      </div>
    );
  }
}

CreditQuality.propTypes = {
  actions: PropTypes.shape().isRequired,
  credit: PropTypes.shape().isRequired,

  currency: PropTypes.string.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.credit.error,
  })),
  withSearchParams,
)(CreditQuality);
