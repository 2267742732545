import '../default-table.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Table = ({ rows, showSettlementDate }) => (
  <div className="table-responsive table-container">
    <table className="table table-striped">
      <thead>
        <tr>
          <th />
          <th>
            <FormattedMessage id="dashboard.adiTrades.operation" />
          </th>
          <th>
            <FormattedMessage id="dashboard.adiTrades.type" />
          </th>
          <th>
            <FormattedMessage id="dashboard.adiTrades.counterparty" />
          </th>
          <th>
            <FormattedMessage id="currencyShort" />
          </th>
          <th>
            <FormattedMessage id="dashboard.adiTrades.amount" />
          </th>
          {showSettlementDate && (
            <th>
              <FormattedMessage id="dashboard.adiTrades.settlementDate" />
            </th>
          )}
          <th>
            <FormattedMessage id="dashboard.adiTrades.rate" />
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  </div>
);

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showSettlementDate: PropTypes.bool.isRequired,
};

export default Table;
