export const licences = {
  dashboard: 'DASHBOARD',
  sandbox: 'SANDBOX',
  rfqReporting: 'RFQ_REPORTING',
  rfqReportingCsv: 'RFQ_REPORTING_CSV',
  reports: 'REPORTING_PORTFOLIO',
  icapAtvStp: 'ICAP_ATV_STP',
  gtreasuryAtvStp: 'GTREASURY_ATV_STP',
  issuerTrades: 'ISSUER_TRADES',
  autoQuoting: 'AUTO_QUOTE',
  unsolicitedRatesheet: 'UNSOLICITED_RATESHEET',
};

export default licences;
