export const REQUEST_FAIL = 'REQUEST_FAIL';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

export function dispachResquestError (error) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_FAIL,
      error: error,
    });
  };
}

export function dispachResquestSuccess () {
  return (dispatch) => {
    dispatch({
      type: REQUEST_SUCCESS,
    });
  };
}
