import { difference, formatMaskedDate, momentWithTimezone, newDate } from '../../date';

const DEFAULT_CASH_MATURITY = 1; // we consider Cash always mature 'tomorrow' for liquidity's sake

export default function calculateWeightedAverageDuration(trades, principalTotal, asAt) {
  const durationWeightedAverage = trades.reduce((sum, trade) => {
    const daysToMaturity =
      trade.instrumentCode === 'CASH' ? DEFAULT_CASH_MATURITY : calculateDaysToMaturity(trade.maturityDate, asAt);

    return sum + (trade.principal / principalTotal) * daysToMaturity;
  }, 0);

  return Math.ceil(durationWeightedAverage);
}

const calculateDaysToMaturity = (maturityDate, asAt) => {
  // sandbox sends date as DD/MM/YYYY, while API trades come as ISO already
  const maturityDateIso =
    maturityDate && maturityDate.indexOf('/') > 0
      ? formatMaskedDate(maturityDate, 'DD/MM/YYYY')
      : momentWithTimezone(maturityDate).startOf('day').toISOString();

  const todayIso = asAt
    ? newDate(asAt).startOf('day').toISOString()
    : momentWithTimezone().startOf('day').toISOString();

  // we always add "today" to WAM calculation, hence the "1 +"
  return 1 + difference(maturityDateIso, todayIso);
};
