import axios from 'axios';

export default async function fetchAdiTrades (params) {
  const { data } = await axios({
    method: 'get',
    url: '/dashboard/trades/adi',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
}
