import { createSelector } from 'reselect';

const currentTenant = (state) => state.session.user.tenants.find((tenant) => tenant.domain === state.tenant);
const currentUserTenants = (state) => state.session.user.tenants;
const currentCurrency = (state) => state.session.user.currency;

export const tenantCurrencies = createSelector(
  [currentTenant, currentUserTenants, currentCurrency],
  (tenant, tenants, currency) => ({
    currentTenant: tenant,
    tenants,
    currency,
  }),
);
