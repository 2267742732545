import axios from 'axios';

export const buildAcceptBespokeRatesheetToInvestorsUrl = (entityId) =>
  `/unsolicited-ratesheets/investors/${entityId}/accept-bespoke-unsolicited-ratesheet`;

export async function acceptBespokeUnsolicitedRatesheet({ unsolicitedRatesheetEntityId, ...data }) {
  return axios({
    method: 'post',
    url: buildAcceptBespokeRatesheetToInvestorsUrl(unsolicitedRatesheetEntityId),
    data,
  });
}
