import './dashboard.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as dashboardActions from '../../actions/dashboard';
import * as holdingActions from '../../actions/holding/holding';
import { fetchQuotes } from '../../actions/reports/outgoing-rfqs/rfqs-history';
import * as sessionActions from '../../actions/session';
import { licences as availableLicences } from '../../components/common/Protect';
import { BankDashboard } from './BankDashboard/BankDashboard';
import { RfqsDashboardPanel } from './RfqsDashboardPanel';

function mapStateToProps(state) {
  return {
    holding: state.holding,
    licences: state.session.user.licences,
    currency: state.session.user.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      holding: bindActionCreators(holdingActions, dispatch),
      dashboard: bindActionCreators(dashboardActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
      fetchOutgoingQuotes: bindActionCreators(fetchQuotes, dispatch),
    },
  };
}

export class Dashboard extends Component {
  componentDidMount() {
    this.props.actions.holding.fetchHoldingIfNeeded();
  }

  renderDashboard = () => (
    <div className="rfqs-container">
      <div className="header">
        <h1 className="big-title weight-600">
          <FormattedMessage id="dashboard.mainTitle" />
        </h1>
      </div>
      <RfqsDashboardPanel />
    </div>
  );

  render() {
    const shouldRenderBankDashboard = this.props.licences.includes(
      availableLicences.issuerTrades
    );

    return (
      <section className="dashboard-container">
        {shouldRenderBankDashboard ? <BankDashboard /> : this.renderDashboard()}
      </section>
    );
  }
}

Dashboard.propTypes = {
  actions: PropTypes.shape().isRequired,
  licences: PropTypes.arrayOf(PropTypes.string).isRequired,
  holding: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
