import React from 'react';

import {
  reinvested,
  rejected,
  redeemed,
  redeemedOnBehalf,
  reinvestedOnBehalf,
  oneDayRollover,
} from '../../../../ducks/issuer-trades/statuses';
import { AcceptedResponse } from './AcceptedResponse';
import { RejectedResponse } from './RejectedResponse';
import { RedeemedResponse } from './RedeemedResponse';
import { ReinvestedOnBehalfResponse } from './ReinvestedOnBehalfResponse';
import { OneDayRolloverResponse } from './OneDayRolloverResponse';
import { tradePropTypes } from './trade-prop-types';

import './dialog-response-item.scss';

export const IssuerTradeDetails = ({ trade }) => (
  <div data-testid="response" className="response-container">
    {responseBuilder({ trade })[trade.status]}
  </div>
);

const responseBuilder = ({ trade }) => ({
  [reinvested]: <AcceptedResponse trade={trade} />,
  [rejected]: <RejectedResponse trade={trade} />,
  [redeemed]: <RedeemedResponse trade={trade} />,
  [redeemedOnBehalf]: <RedeemedResponse trade={trade} />,
  [reinvestedOnBehalf]: <ReinvestedOnBehalfResponse trade={trade} />,
  [oneDayRollover]: <OneDayRolloverResponse trade={trade} />,
});

export const validResponsesKeys = [
  reinvested,
  rejected,
  redeemed,
  redeemedOnBehalf,
  reinvestedOnBehalf,
  oneDayRollover,
];

IssuerTradeDetails.propTypes = {
  trade: tradePropTypes,
};

IssuerTradeDetails.defaultProps = {
  trade: {},
};
