import { handleActions } from 'redux-actions';

import { listMaturingTradesReducer, initialSummary } from './list';
import { listTenantsByIssuerTradesReducer } from './list-tenants';

export {
  listMaturingTrades,
  listMaturingTradesReducer,
  filterMaturingTrades,
  addOfferToIssuerTrade,
  updateIssuerTrade,
} from './list';

export { listTenantsByIssuerTradesAction } from './list-tenants';

export const initialState = {
  tenants: {
    list: [],
    isFetching: false,
  },
  isFetching: false,
  showLoading: true,
  statusCode: null,
  filter: {
    sort: {
      by: 'maturityDate',
      order: 'asc',
    },
  },
  list: [],
  summary: initialSummary,
};

const combinedReducers = {
  ...listMaturingTradesReducer,
  ...listTenantsByIssuerTradesReducer,
};

export const issuerTrades = handleActions(combinedReducers, initialState);
