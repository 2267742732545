const UPDATE_RFQ_QUOTE = 'UPDATE_RFQ_QUOTE';
const RESET_RFQ_QUOTES_PAYLOAD = 'RESET_RFQ_QUOTES_PAYLOAD';

export function updateQuote ({ coupon, maturityCode, uuid }) {
  return {
    type: UPDATE_RFQ_QUOTE,
    coupon,
    maturityCode,
    uuid,
  };
}

export function reset (uuid) {
  return {
    type: RESET_RFQ_QUOTES_PAYLOAD,
    uuid,
  };
}
