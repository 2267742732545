import { createActionThunk } from 'redux-thunk-actions';

import { list } from '../../api/unsettled';
import { endOfDay } from '../../date';
import { actions } from './actions';

const prepareParams = () => ({
  query: { transactionDate: { $gt: endOfDay().format() } },
  sort: 'purchaseDate',
});

export const listUnsettledTradesAction = createActionThunk(actions.getUnsettledTrades, (params) =>
  list(prepareParams(params)),
);

export const listUnsettledTradesReducer = {
  [listUnsettledTradesAction.STARTED]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [listUnsettledTradesAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    list: payload.list,
    count: payload.list.length,
    isFetching: false,
  }),
  [listUnsettledTradesAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [listUnsettledTradesAction.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
  }),
};
