import './static-header.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Header = ({ items, user }) => (
  <div>
    <nav className="header navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        {renderMobileNavHeader(user)}
        <div id="navbar" className="navbar-collapse collapse">
          {renderNavHeader(user)}
          <ul className="nav navbar-nav">
            <li>{renderLogo(user)}</li>
          </ul>
          {renderUserInformation(user, items)}
        </div>
      </div>
    </nav>
  </div>
);

function onHamburguerClick() {
  document.body.classList.toggle('nav-toggle');
}

function shouldShowMenuOptions(user) {
  return user && user.globalRole !== 'bank';
}

function renderLogo(user) {
  if (!shouldShowMenuOptions(user)) {
    return <div className="logo" />;
  }

  return (
    <a className="upper-case title" href={'/'}>
      <div className="logo" />
    </a>
  );
}

function renderMobileNavHeader(user) {
  if (!shouldShowMenuOptions(user)) {
    return null;
  }

  return (
    <div className="navbar-header">
      <div id="mobile-menu">
        <div className="left-nav-toggle" onClick={onHamburguerClick}>
          <a href="#">
            <i className="glyphicon glyphicon-menu-hamburger" />
          </a>
        </div>
      </div>
    </div>
  );
}

function renderNavHeader(user) {
  if (!shouldShowMenuOptions(user)) {
    return null;
  }

  return (
    <div className="left-nav-toggle" onClick={onHamburguerClick}>
      <a href="#">
        <i className="glyphicon glyphicon-menu-hamburger" />
      </a>
    </div>
  );
}

function renderUserInformation(user, items) {
  if (!shouldShowMenuOptions(user)) {
    return null;
  }

  return (
    <ul className="nav navbar-nav navbar-right">
      <li className="header-dropdown">
        <a
          href="#"
          className="dropdown-toggle"
          id="user-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          role="button"
        >
          <span className="header-dropdown-name pull-left">{user.email}</span>

          <div className="header-dropdown-image circle pull-left">
            <span>{user.email[0]}</span>
          </div>

          <div className="header-dropdown-caret pull-left">
            <span className="caret" />
          </div>
        </a>
        {renderUserMenuInformation(user, items)}
      </li>
    </ul>
  );
}

function renderUserMenuInformation(user, items) {
  if (!shouldShowMenuOptions(user)) {
    return null;
  }

  return (
    <ul className="dropdown-menu menu" aria-labelledby="user-dropdown">
      {items.map((item) => (
        <li key={item.label}>
          <a href="#" onClick={item.onClick}>
            <FormattedMessage id={item.label} />
          </a>
        </li>
      ))}
    </ul>
  );
}

Header.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  user: PropTypes.object,
};

export const StaticHeader = Header;
