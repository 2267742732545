import FileSaver from 'file-saver';

export const fileTypes = {
  pdf: { mimeType: 'application/pdf', extension: 'pdf' },
  excel: { mimeType: 'application/vnd.ms-excel', extension: 'xlsx' },
};

export const saveFile = ({ content, name, fileType = 'pdf' }) => {
  const arrayBufferContent = convertBufferToArrayBuffer({ buffer: content });
  const blobFile = new Blob([arrayBufferContent], { type: fileTypes[fileType].mimeType });
  const fileName = `${name}.${fileTypes[fileType].extension}`;

  return FileSaver.saveAs(blobFile, fileName);
};

const convertBufferToArrayBuffer = ({ buffer }) => {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const bufferView = new Uint8Array(arrayBuffer);

  for (let index = 0; index < buffer.length; index += 1) {
    bufferView[index] = buffer[index];
  }

  return arrayBuffer;
};
