export default {
  unratedTier: 4,
};

export const segmentCodes = [
  { code: 'FUND', label: 'fund' },
  { code: 'BANK', label: 'bank' },
  { code: 'CORP', label: 'corp' },
  { code: 'LOCALGOV', label: 'localGov' },
];
