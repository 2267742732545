import { me, removeToken } from '../api/user';
import { updateUser } from '../api/user/update';

export const FETCH_SESSION_REQUEST = 'FETCH_SESSION_REQUEST';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAIL = 'FETCH_SESSION_FAIL';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const RESET_UPDATE_USER_DETAILS = 'RESET_UPDATE_USER_DETAILS';
export const UPDATE_LOCALE = 'UPDATE_LOCALE';
export const UPDATE_SESSION_LOCATION_QUERY = 'UPDATE_SESSION_LOCATION_QUERY';
export const RESET_SESSION_LOCATION_QUERY = 'RESET_SESSION_LOCATION_QUERY';
export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const CLEAN_NOTIFICATION = 'CLEAN_NOTIFICATION';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export function fetchSessionIfNeeded(params, token) {
  return (dispatch, getState) => {
    if (shouldFetchSession(getState())) {
      dispatch(fetchSession(params, token));
    }
  };
}

function shouldFetchSession({ session }) {
  if (!session) {
    return true;
  }

  if (session.isFetching) {
    return false;
  }

  return session.didInvalidate;
}

export function fetchSession(params, token) {
  return async (dispatch) => {
    dispatch({ type: FETCH_SESSION_REQUEST });
    try {
      const user = await me(params, token);
      dispatch({ type: FETCH_USER_SUCCESS, user });

      dispatch({ type: FETCH_SESSION_SUCCESS });
    } catch (error) {
      dispatch({
        type: FETCH_SESSION_FAIL,
        error: error.response || error,
      });
    }
  };
}

export function logout(token) {
  return async (dispatch) => {
    await removeToken(token);
    dispatch({ type: REMOVE_TOKEN });
  };
}

export function updateLocale(locale) {
  return {
    type: UPDATE_LOCALE,
    locale,
  };
}

export function updateLocationQuery(query) {
  return {
    type: UPDATE_SESSION_LOCATION_QUERY,
    query,
  };
}

export function resetLocationQuery() {
  return {
    type: RESET_SESSION_LOCATION_QUERY,
  };
}

export function pushNotification(notificationId, bsStyle = 'warning', notificationValues) {
  return { type: PUSH_NOTIFICATION, notificationId, bsStyle, notificationValues };
}

export function cleanNotification() {
  return { type: CLEAN_NOTIFICATION };
}

export function performUpdateUser(data) {
  return async (dispatch) => {
    dispatch(updateUserRequest());
    try {
      const action = await updateUser(data);
      dispatch(updateUserSession(action));
    } catch (error) {
      dispatch(updateUserFail(error.response || error));
    }
  };
}

export function updateUserRequest() {
  return { type: UPDATE_USER_REQUEST };
}

export function updateUserSession(user) {
  return { type: UPDATE_USER_SUCCESS, user };
}

export function updateUserFail(error) {
  return { type: UPDATE_USER_FAIL, error };
}

export function resetUpdateUser() {
  return { type: RESET_UPDATE_USER_DETAILS };
}
