import './container-content.scss';

import React from 'react';

import PropTypes from 'prop-types';

import { Header } from './header';

export const ContainerContent = ({ titleId, children }) => (
  <section className="container-content">
    <Header titleId={titleId} />
    {children}
  </section>
);

Header.propTypes = {
  titleId: PropTypes.string,
  children: PropTypes.node,
};
