import buildForm from '../../common/form/form';
import fields from './fields';
import schema from './schema';

export default function addNewRFQForm(onSave, initialValues) {
  return {
    title: 'addNewRFQOption',
    value: 'addNewRFQ',
    className: 'add-new-rfq-form',
    form: buildForm({
      fields,
      schema,
      initialValues,
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
