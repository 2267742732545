import FileSaver from 'file-saver';

import { fetchIncomingRfqsReport } from '../../../api/dashboard';

export const FETCH_INCOMING_RFQS_REQUEST = 'FETCH_INCOMING_RFQS_REQUEST';
export const FETCH_INCOMING_RFQS_SUCCESS = 'FETCH_INCOMING_RFQS_SUCCESS';
export const FETCH_INCOMING_RFQS_FAILURE = 'FETCH_INCOMING_RFQS_FAILURE';
export const FETCH_INCOMING_RFQS_CSV_REQUEST = 'FETCH_INCOMING_RFQS_CSV_REQUEST';
export const FETCH_INCOMING_RFQS_CSV_SUCCESS = 'FETCH_INCOMING_RFQS_CSV_SUCCESS';
export const FETCH_INCOMING_RFQS_CSV_FAILURE = 'FETCH_INCOMING_RFQS_CSV_FAILURE';
export const FILTER_RFQS_HISTORY = 'FILTER_RFQS_HISTORY';
export const CLEAR_RFQS_HISTORY_FILTER = 'CLEAR_RFQS_HISTORY_FILTER';

export function fetchRfqsHistory(params) {
  return async (dispatch) => {
    dispatch({ type: FETCH_INCOMING_RFQS_REQUEST });
    try {
      const incomingRfqsReport = await fetchIncomingRfqsReport(params);

      dispatch({ type: FETCH_INCOMING_RFQS_SUCCESS, payload: { ...incomingRfqsReport } });
    } catch (error) {
      dispatch({ type: FETCH_INCOMING_RFQS_FAILURE, error: error.response || error });
    }
  };
}

export function fetchRfqsHistoryCsv(params) {
  return async (dispatch) => {
    dispatch({ type: FETCH_INCOMING_RFQS_CSV_REQUEST });
    try {
      const csv = await fetchIncomingRfqsReport({ ...params, csv: true });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, `Sell_trades_${new Date().toISOString()}.csv`, true);
      dispatch({ type: FETCH_INCOMING_RFQS_CSV_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_INCOMING_RFQS_CSV_FAILURE, error: error.response || error });
    }
  };
}

export function filterRfqsHistory(filter) {
  return async (dispatch) => {
    if (Object.keys(filter).length) {
      dispatch({ type: FILTER_RFQS_HISTORY, filter });
    } else {
      dispatch({ type: CLEAR_RFQS_HISTORY_FILTER });
    }
  };
}
