import React from 'react';
import { bool, string, shape, number, oneOfType } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ErrorFooter } from './error-footer';

import './error.scss';

export const ErrorComponent = ({ title, message, messageValues, info, infoValues, showFooter, errorInfo }) => (
  <div className="error">
    {title && <h1>{title}</h1>}
    <h3>
      <FormattedMessage id={message} values={messageValues} />
    </h3>
    <hr />
    {info && (
      <p>
        <FormattedMessage id={info} values={infoValues} />
      </p>
    )}
    {showFooter && <ErrorFooter errorInfo={errorInfo} />}
  </div>
);

ErrorComponent.propTypes = {
  info: string,
  infoValues: shape(),
  message: string.isRequired,
  errorInfo: string,
  messageValues: shape(),
  showFooter: bool,
  title: oneOfType([string, number]),
};

ErrorComponent.defaultProps = {
  info: '',
  infoValues: undefined,
  messageValues: undefined,
  showFooter: true,
  title: '',
};
