import './trade.scss';

import React, { Component } from 'react';

import { format } from 'd3-format';
import PropTypes from 'prop-types';
import { Col, Glyphicon, Row } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import ReactInterval from 'react-interval';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as sessionActions from '../../actions/session';
import * as uploadActions from '../../actions/upload';
import userStore from '../../user-store';

function mapStateToProps(state) {
  return {
    upload: state.upload,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      upload: bindActionCreators(uploadActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
    },
  };
}

export class UploadTradePage extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    upload: PropTypes.object.isRequired,
  };

  componentDidUpdate() {
    const {
      actions,
      upload: { upload, isCreating },
    } = this.props;

    if (upload && !isCreating && upload.status === "done") {
      actions.session.pushNotification("upload.uploadSucceeded", "success");
    } else if (upload && !isCreating && upload.status === "failed") {
      actions.session.pushNotification("upload.uploadFailed", "danger");
    }
  }

  onDrop = (files) => {
    if (!this.isUploading()) {
      this.props.actions.upload.uploadFile(files[0]);
    }
  };

  isUploading() {
    const {
      upload: { upload },
    } = this.props;
    const isUploading = upload && ["new", "started"].includes(upload.status);

    return isUploading;
  }

  fetchUpload = () => {
    const {
      upload: { upload },
    } = this.props;
    this.props.actions.upload.update(upload);
  };

  formatByKey(key, value) {
    const currencyKeys = ["totalValue"];

    if (currencyKeys.includes(key)) {
      return format("$,.2f")(value);
    }

    return value;
  }

  renderHeader() {
    return (
      <Row className="header">
        <Col xs={12}>
          <h1 className="big-title weight-600">
            <FormattedMessage id={"upload.mainHeader"} />
          </h1>
        </Col>
      </Row>
    );
  }

  renderDropzoneText() {
    return (
      <div>
        <Glyphicon glyph="cloud-upload" />
        <span className="inner-text">
          <FormattedMessage id={"upload.dropzoneMessage"} />
        </span>
      </div>
    );
  }

  renderDropzone() {
    const isUploading = this.isUploading();
    return (
      // eslint-disable-line
      <div className="col-xs-12">
        <Dropzone
          className="dropzone"
          onDrop={this.onDrop}
          disableClick={isUploading}
        >
          <div className="message">
            {isUploading ? (
              <Loader type="ball-pulse" />
            ) : (
              this.renderDropzoneText()
            )}
          </div>
        </Dropzone>
      </div>
    );
  }

  renderErrors({ upload: { upload } }) {
    if (!upload || !upload.result || !upload.result.errors) {
      return;
    }
    // eslint-disable-next-line
    return (
      <div className="col-xs-12">
        <h5>
          <FormattedMessage id={"upload.errorsHeader"} />
        </h5>
        <table className="table table-bordered">
          <tbody>
            {this.renderError("header", upload.result.errors.on.header)}
            {this.renderError("exception", upload.result.errors.exception)}
            {upload.result.errors.on.line &&
              this.renderError(
                "Lines with error",
                upload.result.errors.on.line.length
              )}
          </tbody>
        </table>
      </div>
    );
  }

  renderError(type, error) {
    if (!error) {
      return;
    }

    // eslint-disable-next-line
    return (
      <tr>
        <td>{type}</td>
        <td>{error}</td>
      </tr>
    );
  }

  renderLineErrors({ upload: { upload } }) {
    if (
      !upload ||
      !upload.result ||
      !upload.result.errors ||
      !upload.result.errors.on.line
    ) {
      return;
    }

    // eslint-disable-next-line
    return (
      <div className="col-xs-12">
        <h5>
          <FormattedMessage id={"upload.linesErrorsHeader"} />
        </h5>
        <table className="table table-bordered">
          <thead>
            <td>
              <FormattedMessage id={"upload.lineNumber"} />
            </td>
            <td>
              <FormattedMessage id={"upload.errorsHeader"} />
            </td>
          </thead>
          <tbody>
            {upload.result.errors.on.line.map((line) => (
              <tr>
                <td>{line.line}</td>
                <td>
                  <ul>
                    {line.errors.map((error) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderResults({ upload: { upload } }) {
    if (!upload) {
      return;
    }

    if (!upload.result) {
      // eslint-disable-next-line
      return (
        <div className="col-xs-12 results">
          <h4>
            <FormattedMessage id={"upload.uploadingFileMessage"} />
          </h4>
        </div>
      );
    }
    // eslint-disable-next-line
    return (
      <div className="col-xs-12 results">
        <div>
          <h4>
            <FormattedMessage id={"upload.resultsHeader"} />
          </h4>
        </div>
        <table>
          <tbody>
            {Object.keys(upload.result)
              .filter((key) => key !== "errors")
              .map((key) => {
                const value = upload.result[key];

                return (
                  <tr>
                    <td className="key">
                      <FormattedMessage id={`upload.${key}`} />
                    </td>
                    <td className="value">{this.formatByKey(key, value)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }

  renderInterval({ upload: { upload } }) {
    const timeout = 3000;
    const enabled = !!upload && !upload.result;

    return (
      <ReactInterval {...{ timeout, enabled }} callback={this.fetchUpload} />
    );
  }

  render() {
    if (!userStore.hasToken()) {
      return <Loader type="ball-pulse" />;
    }

    return (
      <div className="upload-trades">
        {this.renderHeader()}

        <div className="upload-area panel panel-filled">
          <Row>
            <Col xs={12}>
              {this.renderDropzone(this.props)}
              {this.renderResults(this.props)}
              {this.renderErrors(this.props)}
              {this.renderLineErrors(this.props)}
              {this.renderInterval(this.props)}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadTradePage);
