import React from 'react';

import { arrayOf, shape } from 'prop-types';

import { Select } from '../../Select/Select';

export const FormikSelectField = ({ field, form: { setFieldValue, setFieldTouched }, options, onChange, ...props }) => (
  <Select
    {...field}
    {...props}
    options={options}
    value={options ? options.find((option) => option.value === field.value) || null : null}
    onChange={(input) => {
      onChange && onChange(input.value);
      setFieldValue(field.name, input.value);
    }}
    onBlur={() => setFieldTouched(field.name, true)}
  />
);

FormikSelectField.propTypes = {
  field: shape().isRequired,
  form: shape().isRequired,
  options: arrayOf(shape()),
};
