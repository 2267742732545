import './information-dropdown.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FaQuestion } from 'react-icons/fa';

export const InformationDropdown = ({ options }) => (
  <div className="dropdown-container" data-tour="information-button">
    <button type="button" className="dropdown-toggle dropdown-button" id="information-dropdown" data-toggle="dropdown">
      <FaQuestion />
    </button>
    <ul className="dropdown-menu menu" aria-labelledby="information-dropdown">
      {options.map(({ label, onClick }) => (
        <li key={label}>
          <button type="button" onClick={onClick}>
            {label}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

InformationDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};
