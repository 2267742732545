import {
  UPDATE_SANDBOX_SUMMARY,
} from '../actions/sandbox';

const INITIAL_STATE = {
  actualWeightedAverageCoupon: 0,
  weightedAverageCoupon: 0,
  actualWeightedAverageDuration: 0,
  weightedAverageDuration: 0,
  actualPrincipalTotal: 0,
  principalTotal: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SANDBOX_SUMMARY:
      return { ...state, ...action.summary };
    default:
      return state;
  }
};
