import React from 'react';

import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { DateWithTimeZone } from '../../../../date';
import { Money } from '../../../money';
import { SellerBuySellBondRfqStatus } from '../buy-sell-bond-rfq-status';

export const columns = {
  status: { label: 'status' },
  instrumentCode: { label: 'type', sort: 'instrumentCode' },
  operation: { label: 'operation', sort: 'operation' },
  isin: { label: 'isin', sort: 'isin' },
  issuer: { label: 'issuer' },
  faceValue: { label: 'securitiesFaceValue', sort: 'faceValue' },
  capitalValue: { label: 'capitalValue', sort: 'capitalValue' },
  grossValue: { label: 'grossValue', sort: 'grossValue' },
  rfqExpiresAt: { label: 'tradeDate', sort: 'rfqExpiresAt' },
  settlementDate: { label: 'settlementDate', sort: 'settlementDate' },
  quotesLength: { label: 'quotes', sort: 'quotesLength' },
  accepted: { label: 'accepted' },
  confirmed: { label: 'confirmed' },
  actions: {},
};

const getAcceptedQuoteIssuerQuote = ({ quotes }) => {
  if (!quotes) {
    return undefined;
  }
  const acceptedQuote = quotes.find(({ quoteStatus }) => quoteStatus === 'accepted');

  if (!acceptedQuote) {
    return undefined;
  }

  return acceptedQuote.issuerCode;
};

export const presenter = (rfq, index, actions) => (
  <tr key={`${rfq.sellBondRfqEntityId}-${index}`}>
    <td className="rfq-status-column">
      <SellerBuySellBondRfqStatus rfq={rfq} verbose />
    </td>
    <td>{rfq.instrumentCode}</td>
    <td className="capitalize">{rfq.operation}</td>
    <td>{rfq.isin}</td>
    <td>{rfq.isinIssuerCode}</td>
    <td>
      <Money value={rfq.faceValue} />
    </td>
    <td>
      <Money value={rfq.capitalValue} />
    </td>
    <td>
      <Money value={rfq.grossValue} />
    </td>
    <td>
      <DateWithTimeZone value={rfq.rfqExpiresAt} />
    </td>
    <td>
      <DateWithTimeZone value={rfq.settlementDate} />
    </td>
    <td>{rfq.quotesLength}</td>
    <td>{getAcceptedQuoteIssuerQuote(rfq)}</td>
    <td>{rfq.confirmedAt ? 'Yes' : 'No'}</td>
    <td className="action" align="right">
      <Button
        className="btn-default"
        onClick={() => actions.openCloseBondRfqDrawer({ uuid: rfq.rfqEntityId, operation: rfq.operation })}
      >
        <FormattedMessage id="view" />
      </Button>
    </td>
  </tr>
);
