import React from 'react';

import { connect } from 'formik';
import { func, shape } from 'prop-types';
import useSWR from 'swr';

import { isBondOrFrn } from '../../../api/holding/instrument-codes';
import { buildListIsins, listIsins } from '../../../api/isins/list';
import {
  FormikSelectField,
  ValidatedFormikField,
} from '../../../components/common';
import { enAuShortDateFormat, formatDate } from '../../../date';

const buildIsinsOptions = (isins) =>
  isins
    .filter(({ instrumentCode, missingIssuer }) => isBondOrFrn(instrumentCode) && !missingIssuer)
    .map(({ isin, issuer, issuerName, maturity }) => ({
      value: isin,
      label: `${isin} (${issuer} ${issuerName ? ` - ${issuerName} ` : ''}) - ${formatDate(
        maturity,
        enAuShortDateFormat,
      )}`,
    }));

const IsinsFormikFieldComponent = ({ formik, onChange, ...props }) => {
  const { data: isins } = useSWR(buildListIsins(), listIsins);

  const { touched, errors } = formik;

  return (
    <ValidatedFormikField
      name="isin"
      labelId="isin"
      component={FormikSelectField}
      touched={touched}
      errors={errors}
      isLoading={!isins}
      options={isins && buildIsinsOptions(isins.list)}
      isSearchable
      onChange={(selectedIsinCode) => {
        onChange(isins.list.find(({ isin }) => isin === selectedIsinCode));
      }}
      {...props}
    />
  );
};

IsinsFormikFieldComponent.propTypes = {
  formik: shape().isRequired,
  onChange: func.isRequired,
};

export const IsinsFormikField = connect(IsinsFormikFieldComponent);
