import 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import 'moment';
import 'numbro';
import 'pikaday';
import 'zeroclipboard';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import HotTable from 'react-handsontable';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Loader } from 'react-loaders';

import { sameDayAsToday } from '../../date';
import { track, updateRatesheetsOnRatesheetsPage } from '../../event-tracker';
import { Loadable, Row, Tooltip } from '../common';

export class RatesheetsTable extends Component {
  handleClick = () => {
    track(updateRatesheetsOnRatesheetsPage);

    this.props.onClickUpdateRatesheet({
      rawData: this.refs[this.props.subtitle].hotInstance.getData(),
      columnHeaders: this.props.columns.map(({ data: columnName }) => columnName),
    });
  };

  renderTableHeader() {
    const { data } = this.props;

    const updatedAt = data.length && data[0].updatedAt;

    return (
      <div className="header-options">
        <h4 className="table-header">
          {this.props.instrumentCode} - <FormattedMessage id={this.props.subtitle} />
        </h4>
        <Row alignItemsCenter contentCenter>
          {data.length && (
            <React.Fragment>
              {!sameDayAsToday(updatedAt) && (
                <Tooltip id="staleRatesheet">
                  <i className="icon fa fa-exclamation-triangle" />
                </Tooltip>
              )}
              <FormattedMessage
                id="lastUpdatedAtWithValues"
                values={{
                  value: updatedAt ? (
                    <FormattedDate
                      value={updatedAt}
                      day="numeric"
                      month="numeric"
                      year="numeric"
                      hour="numeric"
                      minute="numeric"
                    />
                  ) : (
                    'N/A'
                  ),
                }}
              />
            </React.Fragment>
          )}
          <Button
            className="btn-solid-primary btn btn-default"
            onClick={this.handleClick}
            disabled={this.props.isUpdating || false}
          >
            <FormattedMessage id="saveChangedRates" />
          </Button>
        </Row>
      </div>
    );
  }

  renderHotTable() {
    if (this.props.isFetching || this.props.isUpdating) {
      return <Loader type="ball-pulse" />;
    }

    return (
      <HotTable
        root={this.props.subtitle}
        rowHeaders
        readOnly
        manualColumnResize
        manualRowResize
        {...this.props}
        ref={this.props.subtitle}
      />
    );
  }

  render() {
    const { isFetching, isUpdating } = this.props;

    return (
      <div>
        {this.renderTableHeader()}
        <Loadable isLoading={isFetching || isUpdating}>{this.renderHotTable()}</Loadable>
      </div>
    );
  }
}

RatesheetsTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any),
  onClickUpdateRatesheet: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  subtitle: PropTypes.string.isRequired,
  instrumentCode: PropTypes.string.isRequired,
};

RatesheetsTable.defaultProps = {
  data: [{}],
};

export default RatesheetsTable;
