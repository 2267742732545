import React, { useRef } from 'react';

import classNames from 'classnames';
import { Formik } from 'formik';
import { bool, func, number, shape, string } from 'prop-types';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';

import { generalAllocationCode, interests } from '../../../../../../api/holding/codes';
import {
  ConfirmationPopover,
  FormikCurrencyInput,
  FormikSelectField,
  ValidatedFormikField,
} from '../../../../../../components/common';
import { getBusinessDate, getDateInTimeFrameFrom, today } from '../../../../../../date';
import { useAllocationCodes } from '../../../../../../hooks/use-allocation-codes';
import toFloat from '../../../../../../parse-float';
import { ImperiumIntlProvider } from '../../../../../intl-provider';
import { splitTenor } from '../../../../../Tenors/TenorItem';

export const buildInitialFormValues = ({ time, unit }) => ({
  principal: '',
  interestPaid: unit === 'M' && time <= 12 ? 'Z' : '',
  allocationCode: generalAllocationCode.code,
});

const buildValidationSchema = ({ intl }) =>
  Yup.object().shape({
    allocationCode: Yup.string().required(intl.formatMessage({ id: 'required' })),
    interestPaid: Yup.string().required(intl.formatMessage({ id: 'required' })),
    principal: Yup.string().required(intl.formatMessage({ id: 'required' })),
  });

export const UnsolicitedRatesheetOnBehalfPopover = injectIntl(
  ({ targetRef, show, tenor, rate, customer, onHide, onConfirm, unsolicitedRatesheetMaxBankAmount, intl }) => {
    const popoverOverlayContainer = useRef();

    const { time, unit } = splitTenor(tenor);

    const { allocationCodes, setAllocationCodes } = useAllocationCodes();

    const tenorMaturityDate = getBusinessDate({
      date: getDateInTimeFrameFrom(today(), time, unit),
    }).toDate();

    const onSubmit = async (values) => {
      const { principal, ...body } = values;

      await onConfirm({
        ...body,
        tenor,
        instrumentCode: 'TD',
        principal: toFloat(principal),
        rate: toFloat(rate),
      });
    };

    return (
      <React.Fragment>
        <Overlay
          trigger="click"
          placement="bottom"
          rootClose
          show={show}
          ref={popoverOverlayContainer}
          onHide={onHide}
          target={targetRef?.current}
        >
          <Popover
            id="unsolicited-ratesheet-create-trade-on-behalf-popover"
            className={classNames('unsolicited-ratesheet-create-trade-on-behalf light', { 'is-accepting': false })}
            title={
              <Column alignItemsStart>
                <FormattedMessage tagName="h4" id={`offerTenor${unit}`} values={{ count: time }} />
                <label>Customer: {customer.name}</label>
              </Column>
            }
          >
            <ImperiumIntlProvider>
              <Formik
                validationSchema={buildValidationSchema({ intl })}
                onSubmit={onSubmit}
                initialValues={buildInitialFormValues({ time, unit })}
              >
                {({ errors, touched, isSubmitting, dirty, isValid, handleSubmit, values }) => {
                  const isDisabled = isSubmitting || !dirty || !isValid;

                  return (
                    <Column element="section" role="region" aria-label="new funds form">
                      <Column>
                        <Row alignItemsStart>
                          <FormattedMessage
                            tagName="h3"
                            id="rate"
                            values={{ rate: rate.toFixed(2), b: (msg) => <b>{msg}</b> }}
                          />
                          <FormattedMessage
                            tagName="h3"
                            id="unsolicitedRatesheetMaturityDate"
                            values={{ maturityDate: tenorMaturityDate, b: (msg) => <b>{msg}</b> }}
                          />
                        </Row>
                        <ValidatedFormikField
                          name="interestPaid"
                          labelId="selectInterestPaid"
                          component={FormikSelectField}
                          touched={touched}
                          errors={errors}
                          options={interests.map(({ code: value, label: id }) => ({
                            value,
                            label: intl.formatMessage({ id }),
                          }))}
                        />
                        <ValidatedFormikField
                          name="allocationCode"
                          labelId="holdingAllocationCode"
                          allowCreate
                          onChange={(value) => {
                            if (allocationCodes.find((option) => option.value === value)) {
                              return;
                            }

                            setAllocationCodes([
                              ...allocationCodes,
                              {
                                value,
                                label: value,
                              },
                            ]);
                          }}
                          component={FormikSelectField}
                          touched={touched}
                          errors={errors}
                          options={allocationCodes}
                        />

                        <ValidatedFormikField
                          autoFocus
                          name="principal"
                          labelId="principal"
                          component={FormikCurrencyInput}
                          touched={touched}
                          errors={errors}
                        />
                      </Column>
                      <Row className="actions" justifyContentEnd>
                        <ConfirmationPopover
                          buttonLabelId="yes"
                          confirmationMessageId={
                            unsolicitedRatesheetMaxBankAmount > 0 &&
                            toFloat(values.principal) > unsolicitedRatesheetMaxBankAmount
                              ? 'unsolicitedRatesheetMaxAmountReachedIssuerAreYouSure'
                              : 'areYouSure'
                          }
                          disabled={isDisabled}
                          onConfirm={handleSubmit}
                          className="btn-solid-primary"
                          popoverClass="light"
                          isConfirmingFromOutside={isSubmitting}
                          container={popoverOverlayContainer?.current}
                        />
                        <Button onClick={onHide}>
                          <FormattedMessage id="cancel" />
                        </Button>
                      </Row>
                    </Column>
                  );
                }}
              </Formik>
            </ImperiumIntlProvider>
          </Popover>
        </Overlay>
      </React.Fragment>
    );
  },
);

UnsolicitedRatesheetOnBehalfPopover.defaultProps = {
  isAccepting: false,
};

UnsolicitedRatesheetOnBehalfPopover.propTypes = {
  onHide: func,
  onConfirm: func,
  tenor: string,
  targetRef: shape(),
  customer: shape(),
  isAccepting: bool,
  rate: number,
  intl: shape(),
};
