import { adiRfqsFetchActionPayload, sendQuotesRequestActionPayload } from '../../../actions/holding/rfq/enter-quotes';
import reducer, { onNextState } from '../../reducer';
import { sortByMaturityCode } from './quotes';

export const INITIAL_STATE = {
  error: null,
  isFetching: false,
  rfqs: null,
  isSaved: false,
};

const actions = {
  [adiRfqsFetchActionPayload.REQUEST]: onRequest,
  [adiRfqsFetchActionPayload.SUCCESS]: onListSuccess,
  [adiRfqsFetchActionPayload.FAIL]: onFail,
  [sendQuotesRequestActionPayload.REQUEST]: onSendQuotesRequest,
  [sendQuotesRequestActionPayload.SUCCESS]: onSendQuotesSuccess,
  [sendQuotesRequestActionPayload.FAIL]: onSendQuotesFail,
  RESET_ADIS_RFQ: onReset,
};

function onRequest(state) {
  return onNextState(state, {
    isFetching: true,
  });
}

function onListSuccess(state, { rfqs, uuid }) {
  return onNextState(state, {
    isFetching: false,
    rfqs: {
      ...rfqs,
      list: buildRfqsList(rfqs, uuid),
    },
  });
}

function buildRfqsList(rfqs, uuid) {
  const presentedRfqs = rfqs.list
    .map((rfq) => ({
      ...rfq,
      maturities: rfq.maturities.sort(sortByMaturityCode),
      expanded: rfq.uuid === uuid,
    }))
    .sort(sortById)
    .sort(sortByRfqStatus);

  if (uuid) {
    const rfqByUuidIndex = presentedRfqs.findIndex((rfq) => rfq.uuid === uuid);
    if (rfqByUuidIndex > -1) {
      const [rfqFromList] = presentedRfqs.splice(rfqByUuidIndex, 1);
      presentedRfqs.unshift(rfqFromList);
    }
  }

  return presentedRfqs;
}

export const rfqStatuses = {
  pending: '1',
  sent: '2',
  won: '3',
  lost: '4',
  cancelled: '5',
  closed: '6',
};

function sortById(rfqA, rfqB) {
  return rfqA.id > rfqB.id;
}

export function sortByRfqStatus(rfqA, rfqB) {
  const statusA = getRfqStatus(rfqA);
  const statusB = getRfqStatus(rfqB);

  return statusA < statusB ? -1 : 1;
}

export function getRfqStatus({ rfqStatus, maturities }) {
  const openRfq = rfqStatus === 'open';
  const pending =
    openRfq &&
    maturities.every(({ quoteStatus, quoteUpdatedAt }) => quoteStatus === 'pending' && quoteUpdatedAt === null) &&
    rfqStatuses.pending;
  const sent = openRfq && maturities.every(({ quoteUpdatedAt }) => quoteUpdatedAt !== null) && rfqStatuses.sent;
  const won =
    !!maturities.find(({ quoteStatus }) => quoteStatus === 'accepted' || quoteStatus === 'confirmed') &&
    rfqStatuses.won;
  const lost =
    !openRfq &&
    !!maturities.every(({ quoteStatus, quoteUpdatedAt }) => quoteStatus === 'rejected' || quoteUpdatedAt === null) &&
    rfqStatuses.lost;
  const cancelled = rfqStatus === 'cancelled' && rfqStatuses.cancelled;
  const closed = rfqStatus === 'closed' && rfqStatuses.closed;

  return pending || sent || won || lost || cancelled || closed;
}

function onFail(state, action) {
  return onNextState(state, {
    isFetching: false,
    error: action.error,
  });
}

function onSendQuotesRequest(state, { uuid }) {
  const nextState = onNextState(state);

  if (nextState.rfqs) {
    const rfqByUuid = nextState.rfqs.list.find((rfq) => rfq.uuid === uuid);
    rfqByUuid.isCreatingQuotes = true;
  }
  return nextState;
}

function onSendQuotesSuccess(state) {
  const nextState = onNextState(state);
  nextState.isSaved = true;
  return nextState;
}

function onSendQuotesFail(state, { error, uuid }) {
  const nextState = onNextState(state);
  const rfqByUuid = nextState.rfqs.list.find((rfq) => rfq.uuid === uuid);
  rfqByUuid.isCreatingQuotes = false;
  rfqByUuid.error = error;
  nextState.error = error;
  return nextState;
}

function onReset(state) {
  const nextState = onNextState(state);
  return {
    ...INITIAL_STATE,
    rfqs: nextState.rfqs,
  };
}

export default reducer(actions, INITIAL_STATE);
