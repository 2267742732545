import BaseFields from '../base/fields';

export default [
  {
    name: 'instrumentCode',
    label: 'holdingInstrumentCode',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.availableInstrumentCodes,
  },
  {
    name: 'principal',
    label: 'principal',
    componentType: 'currency',
  },
  BaseFields.allocation,
];

export const cdFields = [
  {
    name: 'instrumentCode',
    label: 'holdingInstrumentCode',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.availableInstrumentCodes,
  },
  {
    name: 'principal',
    label: 'securitiesFaceValue',
    componentType: 'currency',
  },
  BaseFields.allocation,
];
