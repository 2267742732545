import axios from 'axios';

export default async function (rfq) {
  const { data } = await axios({
    method: 'post',
    url: '/rfqs',
    data: rfq,
  });

  return data;
}
