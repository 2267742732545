import './login.scss';
import './material';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import logo from '../../assets/imperium-logo.png';
import { Loadable } from '../../components/common/loadable';
import { authenticationAction } from '../../ducks/authentication/authenticate';
import { cancelTwoFactorCodeAction, confirmTwoFactorAction } from '../../ducks/authentication/confirm-two-factor';
import { routes } from '../../routes';
import { validationErrorKeys } from '../../validation/error-keys';
import { MessageType, showToastMessage } from '../toast/toast';

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

const mapDispatchToProps = {
  authenticate: authenticationAction,
  confirmTwoFactorCode: confirmTwoFactorAction,
  cancelTwoFactorCode: cancelTwoFactorCodeAction,
};

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: undefined,
      password: '',
      twoFactorCode: undefined,
    };
  }

  componentDidUpdate() {
    const { login } = this.props;

    if (login.token && !login.pending) {
      const redirectUrl = this.getRedirectUrl();
      window.location = redirectUrl || routes.root;
    }

    if (login.statusCode === 401) {
      if (login.error === validationErrorKeys.tooManyFailedLoginAttempts) {
        showToastMessage(this.props.intl.formatMessage({ id: 'tooManyFailedLoginAttempts' }), MessageType.WARNING, {
          onclick: () => {
            document.location.href = '/forgot-password';
          },
        });

        return;
      }

      const messageType = login.error !== 'invalid-login-or-password' ? MessageType.WARNING : MessageType.ERROR;

      showToastMessage(
        this.props.intl.formatMessage({
          id: login.error || 'invalid-login-or-password',
        }),
        messageType,
      );
    }

    if (this.isTwoFactorCodeInvalid(login)) {
      showToastMessage(this.props.intl.formatMessage({ id: 'invalidTwoFactorCode' }), MessageType.ERROR);
    }
  }

  getRedirectUrl = () => {
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirectUrl');

    if (!redirectUrl) return null;

    // should match routes starting with slash (/) and
    // containing only letters, numbers, slash and dash (-).
    // Check https://regexper.com/#%5E%28%5C%2F%5BA-Za-z0-9%2F-%5D*%29%24
    const alphaNumericSlashAndDash = /^(\/[A-Za-z0-9/-]*)$/i;
    const isValidRedirectUrl = redirectUrl.match(alphaNumericSlashAndDash);

    return isValidRedirectUrl ? redirectUrl : null;
  };

  isTwoFactorCodeInvalid = (login) =>
    login.twoFactorAuthEnabled && (login.statusCode === 404 || login.statusCode === 400);

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleTwoFactorCodeChange = (e) => {
    this.setState({ twoFactorCode: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  renderEmailField = () => (
    <div id="emailField" className="mdl-textfield mdl-textfield--floating-label is-focused">
      <label id="lblEmail" className="mdl-textfield__label" htmlFor="email">
        Email
      </label>
      <input
        className="mdl-textfield__input"
        type="text"
        name="email"
        id="email"
        value={this.state.email}
        onChange={this.handleEmailChange}
      />
    </div>
  );

  renderLeftText = () => (
    <div className="text-wrapper">
      <p>Leading the</p>
      <p>Digital Transformation</p>
      <p>of Fixed Income Markets</p>
    </div>
  );

  renderImperiumLogo = () => <img alt="Imperium Logo" className="logo" role="presentation" src={logo} />;

  renderPasswordField = () => (
    <div id="passwordField" className="mdl-textfield mdl-textfield--floating-label">
      <label id="lblPassword" className="mdl-textfield__label" htmlFor="password">
        Password
      </label>
      <input
        type="password"
        className="mdl-textfield__input"
        name="password"
        id="password"
        value={this.state.password}
        onChange={this.handlePasswordChange}
      />
    </div>
  );

  renderEnterButton = () => (
    <button
      className="btn btn-min-width-md btn-default btn-block-login btn-rounded"
      onClick={(e) => {
        e.preventDefault();
        this.props.authenticate(this.state);
      }}
      name="loginButton"
      type="submit"
      disabled={this.props.login.authenticating}
    >
      <Loadable isLoading={this.props.login.authenticating || this.props.login.token} size="sm">
        Enter
      </Loadable>
    </button>
  );

  renderForgotPasswordLink = () => (
    <a className="base-black-color center-link forgot-password" href="/forgot-password">
      Forgot your password?
    </a>
  );

  renderTwoFactorForm = () => (
    <div className="two-factor-code-form">
      <FormattedMessage id="checkTwoFactorCodeEmail" />
      <div id="two-factor-code-wrapper" className="mdl-textfield mdl-textfield--floating-label is-focused">
        <label className="mdl-textfield__label" htmlFor="two-factor-code">
          Two Factor Code
        </label>
        <input
          className="mdl-textfield__input"
          type="number"
          name="two-factor-code"
          id="two-factor-code"
          value={this.state.twoFactorCode || ''}
          onChange={this.handleTwoFactorCodeChange}
        />
      </div>
      <button
        className="btn btn-min-width-md btn-default btn-block-login btn-rounded"
        onClick={this.onConfirmTwoFactorCode}
        name="confirm-two-factor"
        type="button"
        disabled={this.props.login.authenticating}
      >
        <Loadable isLoading={this.props.login.authenticating} size="sm">
          Submit
        </Loadable>
      </button>
      <div className="center-link">
        <button className="btn btn-link" type="button" onClick={this.onCancelTwoFactorCode}>
          Cancel
        </button>
      </div>
    </div>
  );

  onConfirmTwoFactorCode = () =>
    this.props.confirmTwoFactorCode({
      token: this.props.login.token,
      twoFactorCode: this.state.twoFactorCode,
    });

  onCancelTwoFactorCode = () =>
    this.setState(
      {
        email: undefined,
        password: '',
        twoFactorCode: undefined,
      },
      this.props.cancelTwoFactorCode,
    );

  renderLoginForm = () => (
    <form>
      {this.renderEmailField()}
      {this.renderPasswordField()}
      {this.renderEnterButton()}
      {this.renderForgotPasswordLink()}
    </form>
  );

  render() {
    const { login } = this.props;

    return (
      <div className="login-container">
        {this.renderLeftText()}
        <div className="form-wrapper">
          {this.renderImperiumLogo()}
          {login.twoFactorAuthEnabled ? this.renderTwoFactorForm() : this.renderLoginForm()}
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.shape({
    authenticating: PropTypes.bool,
    token: PropTypes.string,
    loggingOut: PropTypes.bool,
    statusCode: PropTypes.number,
    loggedOut: PropTypes.bool,
    pending: PropTypes.bool,
    twoFactorAuthEnabled: PropTypes.bool,
    error: PropTypes.shape(),
  }).isRequired,
  authenticate: PropTypes.func.isRequired,
  confirmTwoFactorCode: PropTypes.func.isRequired,
  cancelTwoFactorCode: PropTypes.func.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(Login);
