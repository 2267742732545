import axios from 'axios';

import { buildHeaders } from '../../../index';

export const createBuyBondsRfq = async (payload) => {
  const headers = buildHeaders();

  const { data } = await axios({
    url: '/rfqs/buy-bonds',
    method: 'post',
    headers,
    data: payload,
  });

  return data;
};
