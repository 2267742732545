import axios from 'axios';

export async function redeem(id, body) {
  const config = {
    method: 'put',
    url: `/trades/${id}/redeem`,
    data: body || {},
  };

  const { data } = await axios(config);

  return data;
}

export default redeem;
