const defaultHeader = {
  'Content-Type': 'application/json',
};

export function buildHeaders (headersToInclude) {
  return {
    ...defaultHeader,
    ...headersToInclude,
  };
}
