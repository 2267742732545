import axios from 'axios';

export default async function({ token, twoFactorCode }) {
  const { data } = await axios({
    method: 'post',
    url: '/auth/two-factor',
    data: { token, twoFactorCode },
  });

  return data;
}
