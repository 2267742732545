import React from 'react';
import { number } from 'prop-types';

import { Forbidden, InternalServerError, NotFound, Unauthorized } from '.';

export const ErrorByStatus = ({ status, ...props }) => {
  const errorsContent = {
    401: Unauthorized,
    403: Forbidden,
    404: NotFound,
  };

  const ErrorComponent = errorsContent[status] || InternalServerError;

  return <ErrorComponent {...props} />;
};

ErrorByStatus.propTypes = {
  status: number.isRequired,
};
