import React, { createContext, useContext, useState } from 'react';

import { node } from 'prop-types';

const initialSettings = {
  logo: undefined,
  isLoading: false,
  isAddTradeOpen: false,
  initialAddTradeValues: null,
};

export const AppContext = createContext({
  logo: undefined,
  isLoading: false,
  isAddTradeOpen: false,
  initialAddTradeValues: null,
  setLogo: () => {},
  setIsLoading: () => {},
  setInitialAddTradeValues: () => {},
});

export const AppContextProvider = ({ children }) => {
  const [logo, setLogo] = useState(initialSettings.logo);
  const [isLoading, setIsLoading] = useState(initialSettings.isLoading);
  const [isAddTradeOpen, setIsAddTradeOpen] = useState(initialSettings.isAddTradeOpen);
  const [initialAddTradeValues, setInitialAddTradeValues] = useState(initialSettings.initialAddTradeValues);

  return (
    <AppContext.Provider
      value={{
        logo,
        setLogo,
        isLoading,
        setIsLoading,
        isAddTradeOpen,
        initialAddTradeValues,
        setInitialAddTradeValues,
        setIsAddTradeOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: node.isRequired,
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
