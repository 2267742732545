import './empty-content.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row } from './Flex/Flex';
import blackLogo from '../../assets/icons/common/logo_black.png';

export const EmptyContent = ({ messageId, hideLogo, ...props }) => (
  <Row contentCenter alignItemsCenter className="show-message" {...props}>
    {!hideLogo && <img src={blackLogo} alt="Imperium Markets" className="logo" />}
    <FormattedMessage id={messageId} />
  </Row>
);

EmptyContent.propTypes = {
  messageId: PropTypes.string.isRequired,
  hideLogo: PropTypes.bool,
};
