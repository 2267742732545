import './refresh-button.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import refreshIcon from '../../../assets/icons/common/refresh_icon.png';
import { Column, Row } from '../Flex/Flex';

export const RefreshButton = injectIntl(
  ({ lastUpdate, onRefreshClick, intl }) => (
    <Row className="presenter-options" alignItemsCenter>
      {onRefreshClick && (
        <button
          type="button"
          onClick={onRefreshClick}
          className="primary-button refresh-button"
        >
          <img alt={intl.formatMessage({ id: "refresh" })} src={refreshIcon} />
        </button>
      )}
      {lastUpdate && (
        <Column className="updated-message">
          <FormattedMessage id="lastUpdated" />
          <span>{lastUpdate}</span>
        </Column>
      )}
    </Row>
  )
);

RefreshButton.propTypes = {
  lastUpdate: PropTypes.string,
  onRefreshClick: PropTypes.func,
};

RefreshButton.defaultProps = {
  lastUpdate: null,
  onRefreshClick: null,
};
