import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SummaryItem } from '../../../components/common';
import { getOutgoingPanelData } from '../../../ducks/rfq/selectors';
import { Money } from '../../money';

const TotalizerComponent = ({ outgoing, quotes }) => {
  const {
    summary: { opened },
  } = outgoing;

  return (
    <SummaryItem
      className="summary-item"
      top={<FormattedMessage id="inProgressRfqs" />}
      middle={<Money value={opened.amount} short fraction={opened.amount > 0 ? 2 : 0} />}
      bottom={
        opened.count > 0 && (
          <FormattedMessage tagName="span" id="portfolio.filter-investments-number" values={{ length: opened.count }} />
        )
      }
      isLoading={quotes.isFetching}
    />
  );
};

TotalizerComponent.propTypes = {
  outgoing: PropTypes.shape().isRequired,
  quotes: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  quotes: state.quotes,
  outgoing: getOutgoingPanelData(state),
});

export const RfqsInProgressTotalizer = connect(mapStateToProps, null)(TotalizerComponent);
