import React, { useState } from 'react';
import { Column, Row } from 'react-display-flex';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Range } from '../common';
import { startOfDay } from '../../date';

import './select-period.scss';

export const SelectPeriod = (props) => {
  const { children, disabled, onFilterClick } = props;

  const [from, setFrom] = useState(props.from || startOfDay().toDate());
  const [to, setTo] = useState();

  const isFilterDisabled = to && from > to;

  return (
    <Column className="select-period">
      <Row className="reports-tab-container-header">
        <FormattedMessage id="selectPeriod" />
      </Row>
      <Row className="datepicker-container" alignItemsCenter alignContentSpaceBetween>
        <Row alignItemsCenter>
          <div className="month-picker">
            <div className="monthly-report-selector">
              <Range from={from} to={to} onChangeFrom={setFrom} onChangeTo={setTo} roundedInputStyle />
            </div>
          </div>
          <button
            type="button"
            disabled={disabled || isFilterDisabled}
            className="btn btn-default"
            onClick={() => onFilterClick({ from, to })}
          >
            <FormattedMessage id="filter" />
          </button>
        </Row>
        {children}
      </Row>
    </Column>
  );
};

SelectPeriod.propTypes = {
  children: propTypes.node,
  disabled: propTypes.bool,
  from: propTypes.instanceOf(Date),
  onFilterClick: propTypes.func.isRequired,
};

SelectPeriod.defaultProps = {
  disabled: false,
};
