import { createActionThunk } from 'redux-thunk-actions';

import { signOff } from '../../../api/holding/rfq/sign-off';
import { actions } from './actions';

export const signOffAction = createActionThunk(actions.signOff, (params) => signOff(params));

export const signOffReducer = {
  [signOffAction.STARTED]: (state) => ({
    ...state,
    sendingRequest: true,
    hasComplianceBreach: state.hasComplianceBreach,
  }),
  [signOffAction.SUCCEEDED]: (state, { payload: { uuid, remainingSignOffs } }) => ({
    ...state,
    signedOff: true,
    uuid,
    remainingSignOffs,
  }),
  [signOffAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [signOffAction.ENDED]: (state) => ({
    ...state,
    sendingRequest: false,
    signedOff: false,
  }),
};
