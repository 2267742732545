import axios from 'axios';

export const getTradesSummaryUrl = '/trades/summary';

export async function getTradesSummary(url) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
