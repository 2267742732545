import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './LogRocket/LogRocket';
import './jquery-loader';

import React from 'react';

import reactGa from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import setupAxios from './api/setup-axios';
import { env } from './config';
import InterceptorHandler from './containers/interceptor-handler';
import { ImperiumIntlProvider } from './containers/intl-provider';
import { AllRoutes } from './routes';
import configureStore from './store/configure-store';

const baseUrl = window.location.hostname;

setupAxios(env.apiUrl, baseUrl);

const store = configureStore();

export const App = () => {
  reactGa.initialize(env.googleAnalyticsKey);

  return (
    <Provider store={store} key="provider">
      <InterceptorHandler>
        <ImperiumIntlProvider>
          <BrowserRouter>
            <AllRoutes rootPath="/" />
          </BrowserRouter>
        </ImperiumIntlProvider>
      </InterceptorHandler>
    </Provider>
  );
};
