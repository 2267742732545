import React from 'react';

import { IntlProvider } from 'react-intl';

import en from '../i18n/lang-en';

export const ImperiumIntlProvider = ({ children }) => (
  <IntlProvider messages={en} locale="en-Au">
    {children}
  </IntlProvider>
);
