import './validated-field.scss';

import React from 'react';

import cx from 'classnames';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Column, Row } from '../../';

export const ValidatedFormikField = ({
  component,
  touched,
  errors,
  name,
  labelId,
  labelParams,
  fieldInfoComponent,
  ...fieldProps
}) => (
  <Column className={cx('validated-field', touched[name] && errors[name] && 'field-error')}>
    <Row alignItemsCenter>
      <FormattedMessage tagName="span" id={labelId} values={labelParams} />
      {fieldInfoComponent}
    </Row>
    {component ? (
      <Field name={name} component={component} {...fieldProps} />
    ) : (
      <Field name={name} className="form-control" {...fieldProps} />
    )}
    <ErrorMessage className="error-message" name={name} component="div" />
  </Column>
);

ValidatedFormikField.propTypes = {
  touched: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  labelParams: PropTypes.shape(),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.any]),
  fieldInfoComponent: PropTypes.element,
};

ValidatedFormikField.defaultProps = {
  component: undefined,
  fieldInfoComponent: undefined,
};
