import axios from 'axios';
import FileSaver from 'file-saver';

import { formatToShortDate, newDate } from '../../date';

export const listMaturingTradesUrl = '/maturing-trades';

export const buildMaturingTradesList = ({ to } = { to: formatToShortDate(newDate()) }) => [listMaturingTradesUrl, to];

export async function getMaturingTradesUntilToday(url, to) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      to,
    },
  });

  return data;
}

export async function listMaturingTrades(params, { headers } = {}) {
  const { data } = await axios({
    method: 'get',
    url: listMaturingTradesUrl,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    params,
  });

  return data;
}

export async function downloadMaturingTradesCSV(params, filePrefix = 'Issuer_Trades') {
  const { data } = await axios({
    method: 'get',
    url: listMaturingTradesUrl,
    headers: {
      Accept: 'text/csv',
    },
    params,
  });

  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });

  FileSaver.saveAs(blob, `${filePrefix}_generated_at_${new Date().toISOString()}.csv`, true);
}
