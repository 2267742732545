import calculateCouponWeightedAverage from './coupon-average-calculator';
import calculateDurationWeightedAverage from './duration-average-calculator';

export default function calculateWeightedAverage(trades, asAt) {
  const couponWeightedAverage = calculateCouponWeightedAverage(trades);
  const { principalTotal } = couponWeightedAverage;

  const coupon = couponWeightedAverage.couponCalc / couponWeightedAverage.principalTotal;
  const duration = calculateDurationWeightedAverage(trades, principalTotal, asAt);

  return {
    coupon: coupon || 0,
    duration: duration || 0,
    principalTotal: principalTotal || 0,
  };
}
