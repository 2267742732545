export const TD = 'TD';
export const BOND = 'BOND';
export const CASH = 'CASH';
export const FRN = 'FRN';
export const NCD = 'NCD';
export const ECD = 'ECD';
export const CD = 'CD';
export const CP = 'CP';

export const getInstrumentCode = (instrumentCode) => (instrumentCode === ECD ? CD : instrumentCode);

export const isBondOrFrn = (instrumentCode) => ['BOND', 'FRN'].includes(instrumentCode);

export const instrumentCodeNames = {
  MGFUND: 'Managed Funds',
  CASH: 'Cash',
  BOND: 'Bonds',
  FRN: 'Floating Rate Notes',
  FRTD: 'Floating Rate TDs',
  CP: 'Commercial Paper',
};

export const getInstrumentNameByCode = (instrumentCode) => instrumentCodeNames[instrumentCode] || instrumentCode;
