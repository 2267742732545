import numbro from 'numbro';

export const numeric = 'numeric';
export const rateFormat = '0.0000';

export const emptyCharacter = '-';

export const percentual = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const number = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const numberWith = (fractionDigits = 2) => ({
  minimumFractionDigits: fractionDigits,
  maximumFractionDigits: fractionDigits,
});

export const couponFormat = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
};

export const fullCouponFormat = {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
};

export const currencyDecimalPrecision = 4;

export const percentageFieldFormat = { decimalPrecision: 4, min: -100, max: 100 };

export function getThousandSeparatorForLocale(locale) {
  return (1111).toLocaleString(locale).replace(/1/g, '') || ',';
}

export function getDecimalSeparatorForLocale(locale) {
  return (1.1).toLocaleString(locale).replace(/1/g, '') || '.';
}

export function parseLocaleNumber(stringNumber, locale) {
  const thousandSeparator = getThousandSeparatorForLocale(locale);
  const decimalSeparator = getDecimalSeparatorForLocale(locale);

  return parseFloat(
    stringNumber
      .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      .replace(new RegExp(`\\${decimalSeparator}`), '.'),
  );
}

export const shortenNumber = ({ value }) =>
  numbro(value)
    .format('0[.]000a')
    .toUpperCase();

export const formatToTwoDecimals = ({ value, format = '0.00' }) => numbro(value).format(format);

export const getFormattedNumberOrEmpty = ({ assetClass, value, format = '0.00', empty = '-' }) => {
  if (typeof value !== 'number') {
    return empty;
  }

  let unit = '';
  if (assetClass) {
    unit = assetClass === 'FRN' ? ' bp' : '%';
  }

  return (value === 0 ? '0.00' : formatToTwoDecimals({ value, format })) + unit;
};

export const isPercentageAllowed = ({ floatValue }) => floatValue >= -100 && floatValue <= 100;

export const convertStringWithDelimiterToInt = (value = '') => Number(String(value).replace(/,/g, ''));

export const toNumberFormat = ({
  locale = 'en-AU',
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 10,
  ...numberFormatProperties
}) =>
  typeof value !== 'number'
    ? emptyCharacter
    : new Intl.NumberFormat(locale, {
        minimumFractionDigits,
        maximumFractionDigits,
        ...numberFormatProperties,
      }).format(value);

export const toCompactCurrency = ({
  locale = 'en-AU',
  currency = 'AUD',
  value,
  minimumFractionDigits = 1,
  maximumFractionDigits = 2,
}) =>
  new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    notation: 'compact',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);

export const toCurrency = ({ value, locale = 'en-AU', currency = 'AUD', minimumFractionDigits = 0 }) =>
  new Intl.NumberFormat(locale, { currency, style: 'currency', minimumFractionDigits }).format(value);
