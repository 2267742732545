import React from 'react';

/* eslint-disable react/prop-types */
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getImperiumSelectValue, Select } from './Select/Select';

const optionsWithoutIntlFormat = ['allocationCode'];

export const buildDropdownFilters = ({ filter, availableFilters, onDropdownFilterChange, intl, isLoading, ...rest }) =>
  Object.keys(availableFilters).map((key) => {
    const options = optionsWithoutIntlFormat.includes(key)
      ? buildSimpleOptions(availableFilters[key], intl)
      : buildOptions(availableFilters[key], intl);

    const value = getDefaultValue(key, availableFilters[key], filter);

    return renderSelect({
      name: key,
      label: `filter.${key}`,
      onDropdownFilterChange,
      options,
      defaultValue: value,
      value: getImperiumSelectValue({ options, value }),
      isLoading,
      ...rest,
    });
  });

const getDefaultValue = (name, availableFilters, filter) =>
  filter && filter[name] && isValidFilter(availableFilters, filter[name]) ? filter[name].toLowerCase() : 'all';

const isValidFilter = (availableFilters, filter) =>
  availableFilters.map((valid) => valid.toLowerCase()).includes(filter.toLowerCase());

export const renderSelect = ({
  options,
  name,
  label,
  onDropdownFilterChange,
  disabled = false,
  className,
  labelClassName,
  placeholder,
  isLoading = false,
  ...rest
}) => (
  <div className={cx('select-filter', className)}>
    <div>
      {label && (
        <p className={cx('text-ellipsis', labelClassName)}>
          <FormattedMessage id={label} />
        </p>
      )}
      <Select
        name={name}
        options={options}
        onChange={(input) => {
          onDropdownFilterChange(name, input && input.value);
        }}
        isDisabled={disabled}
        placeholder={placeholder}
        isLoading={isLoading}
        {...rest}
      />
    </div>
  </div>
);

const buildOptions = (availableFilters, intl) =>
  availableFilters.map((option) => ({
    label: intl.formatMessage({
      id: `filter.${option}`,
      defaultMessage: option,
    }),
    value: option.toLowerCase(),
  }));

const buildSimpleOptions = (availableFilters, intl) =>
  availableFilters.map((option) => ({
    label: option,
    value: option.toLowerCase(),
  }));
