import axios from 'axios';

import { formatDate, startOfDay } from '../../date';

export const buildFilteredTradesUrl = ({ source }) => {
  const searchParams = new URLSearchParams([['query[purchaseDate][$gte]=', formatDate(startOfDay())]]);

  if (source) {
    searchParams.append('query[source]=', source);
  }

  return ['/trades', searchParams.toString()];
};

export async function getFilteredTrades(url, searchParams) {
  const { data } = await axios({
    method: 'get',
    url: `${url}?${searchParams}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data?.list;
}
