import './confirm-dialog.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ModalDialog = ({
  titleId,
  children,
  confirmId,
  confirmDisabled,
  cancelId,
  dialogClassName,
  onConfirm,
  onCancel,
  show,
}) => (
  <div className="static-modal">
    <Modal show={show} dialogClassName={dialogClassName} onHide={onCancel}>
      <Modal.Header closeButton={!!onCancel}>
        <Modal.Title>
          <FormattedMessage id={titleId} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>
          <FormattedMessage tagName="span" id={cancelId} />
        </Button>
        <Button disabled={confirmDisabled} onClick={onConfirm} className="btn-solid-primary btn-save">
          <FormattedMessage tagName="span" id={confirmId} />
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

const defaultDialogProperties = {
  cancelId: 'defaultCancelButton',
  confirmId: 'defaultConfirmButton',
  titleId: 'defaultConfirmTitle',
};

ModalDialog.defaultProps = defaultDialogProperties;

ModalDialog.propTypes = {
  cancelId: PropTypes.string,
  confirmId: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  children: PropTypes.node,
  dialogClassName: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  titleId: PropTypes.string,
  show: PropTypes.bool,
};

export default ModalDialog;
