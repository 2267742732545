import React, { Component } from 'react';

import ErrorHandler from '../../containers/error-handler';

export default function errorHandler(getError, onError) {
  return function(ComposedComponent) {
    // eslint-disable-next-line react/prefer-stateless-function
    return class extends Component {
      render() {
        return (
          <ErrorHandler {...this.props} {...getError(this.props)} onError={onError ? () => onError(this.props) : null}>
            <ComposedComponent {...this.props} />
          </ErrorHandler>
        );
      }
    };
  };
}
