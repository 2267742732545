import * as compliancesApiClient from '../../api/compliances';
import compliance from './common';
import fetch from '../helper/fetch';
import { getDefaultDates } from '../../date';

export const FETCH_MATURITY = 'MATURITY';
export const UPDATE_MATURITY_FILTER = 'UPDATE_MATURITY_FILTER';

const maturityFetchAction = compliance(fetch(FETCH_MATURITY,
  shouldFetchMaturity,
  async (params) => ({
    maturity: await compliancesApiClient.maturity({
      query: getDefaultDates(params && params.date),
      ...params,
    }),
    params,
  }),
), {
  compliance: 'maturity',
});

export const maturityFetchPayload = maturityFetchAction.actionsTypes;

export function fetchMaturityIfNeeded (params) {
  return maturityFetchAction.fetchIfNeeded(params);
}

function shouldFetchMaturity (params = {}, state) {
  if (state.maturity.isFetching || state.maturity.didInvalidate) {
    return false;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  return !state.maturity.maturity;
}

export function resetFilter () {
  return maturityFetchAction.resetFilter();
}

export function updateFilter (filter) {
  return async (dispatch, getState) => {
    maturityFetchAction.updateFilter(dispatch, getState(), filter);
  };
}

export function updateSelectedDate (date) {
  return async (dispatch, getState) => {
    maturityFetchAction.updateSelectedDate(dispatch, getState(), date);
  };
}

export function updateFilteredData ({ maturity, availableFilters, filter }) {
  return maturityFetchAction.updateFilteredData(maturity.maturityLimits, availableFilters, filter);
}
