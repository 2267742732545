import { createSelector } from 'reselect';
import clone from 'lodash.clonedeep';

const summaryInitialState = {
  opened: { amount: 0, count: 0 },
  confirmed: { amount: 0, count: 0 },
  closed: { amount: 0, count: 0 },
};

const isRfqOpened = (status) => status === 'open';
const isRfqConfirmed = (status, confirmed) => status === 'closed' && confirmed === false;
const isRfqClosed = (status, confirmed) => status === 'closed' && confirmed === true;

const getSummaryKey = (status, confirmed) =>
  (isRfqOpened(status) && 'opened') ||
  (isRfqConfirmed(status, confirmed) && 'confirmed') ||
  (isRfqClosed(status, confirmed) && 'closed');

const getPanelData = (rfqs) => {
  const initialState = {
    summary: clone(summaryInitialState),
  };

  const mergedInstruments = rfqs.reduce((state, rfq) => {
    const summaryKey = getSummaryKey(rfq.rfqStatus, rfq.rfqConfirmed);
    const summaryItem = state.summary[summaryKey];

    if (summaryItem) {
      summaryItem.amount += rfq.principal;
      summaryItem.count += 1;
    }

    return state;
  }, initialState);

  return mergedInstruments;
};

const getOutgoingRfqs = (state) => state.quotes.rfqs || [];

export const getOutgoingPanelData = createSelector([getOutgoingRfqs], getPanelData);
