import {
  counterpartyFetchPayload,
  UPDATE_COUNTERPARTY_PAGINATION,
  UPDATE_COUNTERPARTY_ROW_LIMIT,
} from '../../actions/compliances/counterparty';
import { UPDATE_PORTFOLIO_SELECTED_DATE } from '../../actions/portfolio';
import { sortRatings } from '../../api/holding/codes';
import { calculateItems, pagination } from '../common/pagination';

export const INITIAL_STATE = {
  hasInitiated: false,
  isFetching: false,
  didInvalidate: false,
  error: null,
  counterparty: null,
  pagination,
  filter: null,
  filteredData: null,
  availableFilters: {
    isCompliant: ['All', 'true', 'false'],
    term: ['All', 'Long', 'Short'],
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case counterpartyFetchPayload.REQUEST: {
      const silentFetch = action.state && action.state.portfolio && action.state.portfolio.silentFetch;

      return {
        ...state,
        hasInitiated: true,
        isFetching: !silentFetch,
        error: null,
      };
    }
    case counterpartyFetchPayload.SUCCESS:
      return onCounterpartySuccess(state, action);
    case counterpartyFetchPayload.FAIL:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case UPDATE_COUNTERPARTY_PAGINATION:
      return setCounterpartyPaginationOnNextState(state, action);
    case UPDATE_COUNTERPARTY_ROW_LIMIT:
      return setCounterpartyPaginationOnNextState(state, action);
    case counterpartyFetchPayload.UPDATE_FILTER:
      return {
        ...state,
        filteredData: null,
        filter: {
          ...action.newFilter,
        },
      };
    case UPDATE_PORTFOLIO_SELECTED_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
        error: null,
      };
    case counterpartyFetchPayload.UPDATE_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.filteredData,
        pagination: {
          ...state.pagination,
          activePage: 1,
          items: calculateItems(action.filteredData.length, state.pagination.limit),
        },
      };
    default:
      return state;
  }
}

function onCounterpartySuccess(state, action) {
  const counterparty = {
    ...action.counterparty,
    issuers: presenterIssuers(action.counterparty),
  };

  const availableFilters = { ...state.availableFilters };

  if (action.counterparty.longTermComplianceOnly) {
    delete availableFilters.term;
  }

  const newState = {
    ...state,
    isFetching: false,
    didInvalidate: false,
    counterparty,
    pagination: {
      ...state.pagination,
      activePage: 1,
      items: calculateItems(counterparty.issuers.length, state.pagination.limit),
    },
    error: null,
    availableFilters,
  };

  return newState;
}

function setCounterpartyPaginationOnNextState(state, action) {
  const nextPagination = {
    ...state.pagination,
    ...action.pagination,
  };

  if (state.pagination.limit !== nextPagination.limit) {
    const safeIssuers = state.filteredData || state.counterparty.issuers;
    nextPagination.items = calculateItems(safeIssuers.length, nextPagination.limit);
  }

  return {
    ...state,
    pagination: {
      ...nextPagination,
    },
  };
}

function presenterIssuers({ list }) {
  const presentedIssuers = list
    .filter((counterparty) => counterparty.amountInvested > 0)
    .reduce((presenterBankingGroups, bankingGroup) => {
      ['long', 'short'].forEach((term) => {
        if (bankingGroup[term]) {
          presenterBankingGroups.push({
            term,
            name: bankingGroup.name,
            code: bankingGroup.code,
            ...bankingGroup[term],
          });
        }
      });

      return presenterBankingGroups;
    }, []);

  return presentedIssuers.sort((issuerA, issuerB) => sortRatings(issuerA.rating, issuerB.rating));
}
