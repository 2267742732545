import './confirm-dialog.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { ConfirmationPopover, Loadable } from './';

const ConfirmDialog = ({
  titleId,
  content,
  contentId,
  contentValues,
  confirmId,
  cancelId,
  children,
  disabled,
  onConfirm,
  onCancel,
  onHide,
  hasCancel,
  centerLabels,
  modalClassName,
  show,
  titleValues,
  isConfirming,
  confirmationMessageId,
  dataTestId,
  footerButtonActions,
}) => (
  <div className="static-modal">
    <Modal
      show={show}
      className={classNames('confirm-dialog', modalClassName, { 'is-confirming': isConfirming })}
      onHide={onHide}
      data-testid={dataTestId}
      backdrop={isConfirming ? 'static' : true}
    >
      <Modal.Header closeButton={!!onHide}>
        <Modal.Title className={classNames({ 'center-labels': centerLabels })}>
          <FormattedMessage id={titleId} values={titleValues} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children || content || <FormattedMessage id={contentId} values={contentValues} />}</Modal.Body>
      <Modal.Footer className={classNames({ 'center-labels': centerLabels })}>
        {hasCancel && (
          <Button onClick={onCancel}>
            <FormattedMessage tagName="span" id={cancelId} />
          </Button>
        )}
        {footerButtonActions}
        {confirmationMessageId ? (
          <ConfirmationPopover
            animation={false}
            buttonLabelId={confirmId}
            className="btn-solid-primary btn-save"
            confirmationMessageId={confirmationMessageId}
            disabled={disabled}
            onConfirm={onConfirm}
          />
        ) : (
          onConfirm && (
            <Button
              data-testid="confirm-button"
              disabled={disabled}
              onClick={onConfirm}
              className="btn-solid-primary btn-save"
            >
              <Loadable isLoading={isConfirming} size="sm">
                <FormattedMessage id={confirmId} />
              </Loadable>
            </Button>
          )
        )}
      </Modal.Footer>
    </Modal>
  </div>
);

export const defaultDialogProperties = {
  cancelId: 'defaultCancelButton',
  centerLabels: false,
  confirmId: 'defaultConfirmButton',
  content: undefined,
  contentId: 'defaultConfirmContent',
  contentValues: undefined,
  isConfirming: false,
  disabled: false,
  hasCancel: true,
  modalClassName: '',
  titleId: 'defaultConfirmTitle',
  confirmationMessageId: undefined,
};

ConfirmDialog.defaultProps = defaultDialogProperties;

ConfirmDialog.propTypes = {
  cancelId: PropTypes.string,
  dataTestId: PropTypes.string,
  confirmId: PropTypes.string,
  contentId: PropTypes.string,
  content: PropTypes.node,
  footerButtonActions: PropTypes.arrayOf(PropTypes.node),
  confirmationMessageId: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  contentValues: PropTypes.shape(),
  titleValues: PropTypes.shape(),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  titleId: PropTypes.string,
  onHide: PropTypes.func,
  hasCancel: PropTypes.bool,
  modalClassName: PropTypes.string,
  centerLabels: PropTypes.bool,
  show: PropTypes.bool,
  isConfirming: PropTypes.bool,
};

export default ConfirmDialog;
