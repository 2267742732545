import { useEffect, useState } from 'react';

export const useAxios = (apiCall, { onSuccess } = {}) => {
  const [data, setData] = useState({ isLoading: true });
  const [shouldRefreshEffect, refreshEffect] = useState(undefined);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({ isLoading: true });
        const response = await apiCall();
        setData(response);
        setError(null);
        onSuccess && onSuccess(response);
      } catch (e) {
        setError(e.response || { error: e });
        setData({ isLoading: false });
      }
    };

    fetchData();
  }, [shouldRefreshEffect]);

  // refreshEffect passing an empty object is the way of refreshing the effect
  // as the second given argument is an array of list of observable variables
  return { data, error, setData, refresh: () => refreshEffect({}) };
};
