import {
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_FAIL,
  FETCH_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  RESET_UPDATE_USER_DETAILS,
  UPDATE_SESSION_LOCATION_QUERY,
  RESET_SESSION_LOCATION_QUERY,
  REMOVE_TOKEN,
  PUSH_NOTIFICATION,
  CLEAN_NOTIFICATION,
} from '../actions/session';

import defaultTimezone from '../time-zones';
import { setTenantTimezone, difference as diffDays } from '../date';
import { status } from './reducer';

const LOG_OUT_SUCCEEDED = 'LOG_OUT_SUCCEEDED';

export const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: true,
  data: null,
  error: null,
  location: null,
  query: null,
  user: null,
  notificationId: null,
  notificationValues: null,
  className: null,
  bsStyle: null,
  updateStatus: status.initial,
  updateError: null,
  loggingOut: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SESSION_REQUEST:
      return { ...INITIAL_STATE, isFetching: true, didInvalidate: false, rootPath: state.rootPath };

    case FETCH_SESSION_SUCCESS:
      return { ...state, isFetching: false };

    case FETCH_SESSION_FAIL:
      return { ...state, isFetching: false, error: action.error };

    case FETCH_USER_SUCCESS:
      return { ...state, user: userWithTenantTimezone(action.user) };

    case UPDATE_SESSION_LOCATION_QUERY:
      return { ...state, query: action.query };

    case RESET_SESSION_LOCATION_QUERY:
      return { ...state, query: null };

    case PUSH_NOTIFICATION:
      return {
        ...state,
        notificationId: action.notificationId,
        notificationValues: action.notificationValues,
        className: action.className,
        bsStyle: action.bsStyle,
        updateStatus: status.initial,
      };

    case CLEAN_NOTIFICATION:
      return { ...state, notificationId: null };

    case UPDATE_USER_REQUEST:
      return { ...state, updateStatus: status.isSaving };

    case UPDATE_USER_SUCCESS:
      return { ...state, updateStatus: status.isSaved, user: action.user };

    case UPDATE_USER_FAIL:
      return { ...state, updateStatus: status.hasError, updateError: action.error };

    case RESET_UPDATE_USER_DETAILS:
      return {
        ...state,
        updateError: null,
        updateStatus: status.initialState,
        issuers: state.issuers,
      };

    case REMOVE_TOKEN:
      return { ...state, loggingOut: true };

    case LOG_OUT_SUCCEEDED:
      return { ...state, user: null };
    default:
      return state;
  }
};

function userWithTenantTimezone(user) {
  if (!user.tenantTimezone) {
    user.tenantTimezone = defaultTimezone; // eslint-disable-line no-param-reassign
  }
  user.passwordExpiresDays = Math.floor(diffDays(user.passwordExpiresAt, new Date())); // eslint-disable-line no-param-reassign

  setTenantTimezone(user.tenantTimezone);

  return user;
}
