import {
  maturingHoldingsFetchPayload,
  redeemRequestActionPayload,
  confirmRedeemRequestActionPayload,
} from '../../actions/holding/maturing';

import reducer, { onNextState } from '../reducer';

export const INITIAL_STATE = {
  error: null,
  isFetching: false,
  maturing: null,
};

const actions = {
  [maturingHoldingsFetchPayload.REQUEST]: onSaveRequest,
  [maturingHoldingsFetchPayload.SUCCESS]: onSaveSuccess,
  [maturingHoldingsFetchPayload.FAIL]: onSaveFail,
  [redeemRequestActionPayload.REQUEST]: onRedeemRequest,
  [redeemRequestActionPayload.SUCCESS]: onRedeemSuccess,
  [redeemRequestActionPayload.FAIL]: onRedeemFail,
  [confirmRedeemRequestActionPayload.REQUEST]: onConfirmRedeemRequest,
  [confirmRedeemRequestActionPayload.SUCCESS]: onConfirmRedeemSuccess,
  [confirmRedeemRequestActionPayload.FAIL]: onConfirmRedeemFail,
};

function onSaveRequest(state, action) {
  return onNextState(state, {
    isFetching: !action.silentFetch,
  });
}

function onSaveSuccess(state, action) {
  return onNextState(state, {
    isFetching: false,
    maturing: action.maturing,
  });
}

function onSaveFail(state, action) {
  return onNextState(state, {
    isFetching: false,
    error: action.error,
  });
}

function onRedeemRequest(state, action) {
  const nextState = onNextState(state);
  const tradeDetails = nextState.maturing.trades.find((trade) => trade.id === action.id);

  tradeDetails.isLoading = true;

  return nextState;
}

function onRedeemSuccess(state, action) {
  const nextState = onNextState(state);
  const tradeDetails = nextState.maturing.trades.find((trade) => trade.id === action.id);

  tradeDetails.isLoading = false;
  tradeDetails.maturityAction = 'redeemed';
  nextState.isSaved = true;

  return nextState;
}

function onRedeemFail(state, action) {
  const nextState = onNextState(state);
  nextState.isSaved = false;
  nextState.isSaving = nextState.isSaved;
  nextState.isFetching = nextState.isSaved;

  const tradeDetails = nextState.maturing.trades.find((trade) => trade.id === action.id);
  tradeDetails.isLoading = nextState.isSaved;
  return nextState;
}

function onConfirmRedeemRequest(state, action) {
  const nextState = onNextState(state);
  const tradeDetails = nextState.maturing.trades.find((trade) => trade.id === action.id);

  tradeDetails.isConfirming = true;

  return nextState;
}

function onConfirmRedeemSuccess(state, action) {
  const nextState = onNextState(state);
  const tradeDetails = nextState.maturing.trades.find((trade) => trade.id === action.id);

  tradeDetails.isConfirming = false;
  tradeDetails.maturityActionConfirmed = true;

  return nextState;
}

function onConfirmRedeemFail(state, action) {
  const nextState = onNextState(state);
  const tradeDetails = nextState.maturing.trades.find((trade) => trade.id === action.id);
  tradeDetails.isConfirming = false;
  return nextState;
}

export default reducer(actions, INITIAL_STATE);
