import { enAuShortDateFormatWithTime, formatDate } from '../../../date';

export const buildActionButtonPropertiesByQuote = ({
  quoteStatus,
  quoteEntityId,
  actions,
  issuerCode,
  rfqStatus,
  confirmedAt,
  confirmedBy,
}) => {
  if (['expired', 'cancelled'].includes(rfqStatus)) {
    return {
      buttonLabelId: 'unavailable',
      disabled: true,
    };
  }

  if (quoteStatus === 'notInterested') {
    return {
      buttonLabelId: 'notInterested',
      disabled: true,
    };
  }

  if (quoteStatus === 'rejected') {
    return {
      buttonLabelId: 'rejected',
      disabled: true,
    };
  }

  if (quoteStatus === 'accepted') {
    return confirmedAt
      ? {
          buttonLabelId: 'confirmed',
          tooltipId: 'buySellBondRfqConfirmedBy',
          tooltipValues: { confirmedAt: formatDate(confirmedAt, enAuShortDateFormatWithTime), confirmedBy },
          className: 'confirmed',
          disabled: true,
        }
      : {
          buttonLabelId: 'waiting',
          className: 'waiting-confirmation',
          disabled: true,
        };
  }

  if (quoteStatus === 'pending') {
    return {
      buttonLabelId: 'accept',
      onConfirm: () => actions.onAcceptQuote({ quoteEntityId }),
      confirmationMessageId: 'acceptQuoteNow',
      confirmationMessageValues: { issuerCode },
    };
  }

  return null;
};
