import './view-offer-dialog.scss';

import React from 'react';

import classnames from 'classnames';
import toKebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import {
  Column,
  ConfirmationPopover,
  Drawer,
} from '../../../components/common';
import { collapseMotion } from '../../../components/common/css-motions';
import {
  reinvestedOnBehalf,
  rejected,
  sent,
} from '../../../ducks/issuer-trades/statuses';
import { Tenors } from '../../Offer/Tenors/Tenors';
import {
  DetailsHeader,
  IssuerTradeDetails,
  SourceTradeDetails,
  validResponsesKeys,
} from './details-builder';

const expandIcon = ({ isActive }) => (isActive ? <FaChevronCircleUp /> : <FaChevronCircleDown />);

const panelKeys = {
  maturingDeal: 'maturingDeal',
  response: 'response',
  ratesheet: 'ratesheet',
};

export const ViewOfferDialog = ({ bankRatesheet, open, onClose, tradeSelected, onSendOffer, onCancelOffer }) => {
  const { offer: { bids, status } = { bids: [] } } = tradeSelected;
  const [latestBid] = bids;
  const ratesheet = latestBid && latestBid.ratesheet ? latestBid.ratesheet : bankRatesheet;

  const renderFooter = {
    [rejected]: (
      <button className="btn btn-default" type="button" onClick={onSendOffer}>
        <FormattedMessage tagName="span" id="sendOffer" />
      </button>
    ),
    [sent]: (
      <ConfirmationPopover
        buttonLabelId="cancelOngoingOffer"
        confirmationMessageId="cancelOfferContent"
        type="button"
        onConfirm={onCancelOffer}
      />
    ),
  };

  return (
    <Drawer
      data-testid="view-offer-drawer"
      className="view-offer-drawer"
      open={open}
      onClose={onClose}
      titleId="issuerTradeDetails"
      headerComponent={
        <Column flex={1} alignItemsEnd>
          <span data-testid="status" className={classnames('dashboard-status', toKebabCase(tradeSelected.status))}>
            <FormattedMessage tagName="span" id={tradeSelected.status} />
          </span>
        </Column>
      }
      width="30vw"
      footer={renderFooter[tradeSelected.status]}
    >
      <DetailsHeader trade={tradeSelected} />
      <Column>
        <Collapse
          openMotion={collapseMotion}
          expandIcon={expandIcon}
          defaultActiveKey={[panelKeys.response, panelKeys.ratesheet]}
        >
          {validResponsesKeys.includes(tradeSelected.status) && (
            <Panel header={<FormattedMessage tagName="span" id="response" />} key={panelKeys.response}>
              <IssuerTradeDetails trade={tradeSelected} />
            </Panel>
          )}
          {tradeSelected.offer && (
            <Panel
              header={<FormattedMessage tagName="span" id={ratesheet.isCustom ? 'customRatesheet' : 'ratesheet'} />}
              key={panelKeys.ratesheet}
            >
              <Tenors
                data-testid="ratesheet"
                status={status}
                selectedTenor={latestBid && latestBid.tenor}
                readOnly
                ratesheet={ratesheet}
              />
            </Panel>
          )}
          <Panel header={<FormattedMessage tagName="span" id="maturingDeal" />} key={panelKeys.maturingDeal}>
            <SourceTradeDetails
              trade={
                tradeSelected.status === reinvestedOnBehalf && tradeSelected.sourceTrade
                  ? tradeSelected.sourceTrade
                  : tradeSelected
              }
            />
          </Panel>
        </Collapse>
      </Column>
    </Drawer>
  );
};

ViewOfferDialog.propTypes = {
  onSendOffer: PropTypes.func.isRequired,
  onCancelOffer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  tradeSelected: PropTypes.shape().isRequired,
  bankRatesheet: PropTypes.shape({
    available: PropTypes.bool,
    isFetching: PropTypes.bool,
    updatedAt: PropTypes.string,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
};

ViewOfferDialog.defaultProps = {
  open: false,
};
