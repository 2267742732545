import './confirm-offer-modal.scss';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Column, Loadable, Row } from '../../components/common';
import { percentual } from '../../format-numbers';
import { Money } from '../money';

export const ConfirmOfferModal = ({ bid, isConfirming, show, onConfirm, onCancel, disclaimerMessageId }) => (
  <Modal show={show} className={cx('confirm-offer-dialog', { 'is-loading': isConfirming })} onHide={onCancel}>
    <Modal.Header closeButton={!!onCancel}>
      <Modal.Title>
        <FormattedMessage tagName="span" id="confirmation" />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className={cx({ 'has-message-box': !!disclaimerMessageId })}>
      <Column>
        {disclaimerMessageId && (
          <Row data-testid="message-box" className="message-box" contentCenter>
            <FormattedMessage tagName="span" id={disclaimerMessageId} />
          </Row>
        )}
        <div className="amount">
          <Money value={bid.principal + bid.accruedInterest + bid.additionalFunds} short />
        </div>
        {bid.accruedInterest > 0 && (
          <Row data-testid="details-box" className="details-box" contentBetween>
            <FormattedMessage tagName="span" id="offerAccruedInterestAdded" />
            <Money value={bid.accruedInterest} short />
          </Row>
        )}
        {bid.interestPaid && (
          <Row data-testid="details-box" className="details-box" contentBetween>
            <FormattedMessage tagName="span" id="interestPaid" />
            <FormattedMessage tagName="span" id={`interestDescription.${bid.interestPaid}`} />
          </Row>
        )}
        {bid.selectedOffer && (
          <Row data-testid="details-box" className="details-box" contentBetween>
            <FormattedMessage tagName="span" id="selectedRate" />
            <Row>
              <div>
                <FormattedMessage
                  id={`offerTenor${bid.selectedOffer.unit}`}
                  values={{ count: bid.selectedOffer.time }}
                />
              </div>
              <div className="percentage">
                / <FormattedNumber {...percentual} value={bid.selectedOffer.rate} />%
              </div>
            </Row>
          </Row>
        )}
        {bid.additionalFunds !== 0 && (
          <Row data-testid="details-box" className="details-box" contentBetween>
            <FormattedMessage tagName="span" id={bid.additionalFunds > 0 ? 'additionalFunds' : 'partialRedeem'} />
            <Money value={Math.abs(bid.additionalFunds)} short />
          </Row>
        )}
      </Column>
    </Modal.Body>
    <Modal.Footer>
      <Column>
        <Button data-testid="confirm-button" className="btn-solid-primary" onClick={onConfirm}>
          <Loadable isLoading={isConfirming} size="sm">
            <FormattedMessage tagName="span" id="confirm" />
          </Loadable>
        </Button>

        <Button bsStyle="link" onClick={onCancel}>
          <FormattedMessage tagName="span" id="cancel" />
        </Button>
      </Column>
    </Modal.Footer>
  </Modal>
);

ConfirmOfferModal.propTypes = {
  bid: PropTypes.shape({
    accruedInterest: PropTypes.number,
    additionalFunds: PropTypes.number,
    interestPaid: PropTypes.string,
    principal: PropTypes.number,
    selectedOffer: PropTypes.shape(),
  }).isRequired,
  show: PropTypes.bool,
  isConfirming: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disclaimerMessageId: PropTypes.string,
};

ConfirmOfferModal.defaultProps = {
  show: false,
  isConfirming: false,
  disclaimerMessageId: '',
};
