import axios from 'axios';

import { buildHeaders } from '../';

export const updateTenantSettings = async (settings) => {
  const headers = buildHeaders();

  const { data } = await axios({
    method: 'put',
    url: '/tenants/settings',
    headers,
    data: {
      ...settings,
    },
  });

  return data;
};
