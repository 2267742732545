import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

const type = 'DraggableBodyRow';

export const DraggableRow = ({ index, moveRow, className, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return <tr ref={ref} className={`${className} ${isOver ? dropClassName : ''}`} {...restProps} />;
};

DraggableRow.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  moveRow: PropTypes.func,
  className: PropTypes.string,
};
