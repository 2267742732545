import axios from 'axios';

import { buildHeaders } from '../..';

export const acceptQuote = async (params) => {
  const headers = buildHeaders();

  const { data } = await axios({
    method: 'put',
    url: `/rfqs/${params.uuid}/quotes/${params.quoteId}/accept`,
    headers,
  });

  return data;
};

export default acceptQuote;
