import './alternative-actions-modal.scss';

import React, { useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, Modal } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Column,
  ConfirmationPopover,
  RadioGroup,
} from '../../components/common';

const reasons = [
  {
    value: 'compliancePolicyPreventsAcceptance',
    labelId: 'compliancePolicyPreventsAcceptance',
  },
  { value: 'complianceLimits', labelId: 'complianceLimits' },
  { value: 'waitingForMaturity', labelId: 'waitingForMaturity' },
  { value: 'intendToRedeem', labelId: 'intendToRedeem' },
  { value: 'other', labelId: 'other' },
];

export const AlternativeActionsModal = injectIntl(({ isConfirming, show, onRejectClick, onCancel, intl }) => {
  const [reason, setReason] = useState('other');
  const [investorMessage, setInvestorMessage] = useState();

  const onHide = () => {
    onCancel();
    setReason(undefined);
    setInvestorMessage(undefined);
  };

  return (
    <Modal
      data-testid="alternative-actions-modal"
      show={show}
      className={cx('alternative-actions-dialog', {
        'is-loading': isConfirming,
      })}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage tagName="span" id="doNotTakeOffer" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Column>
          <FormattedMessage tagName="span" id="reason" />
          <RadioGroup selectedItem={reason} items={reasons} onChange={(value) => setReason(value)} />
        </Column>
        <Column alignItemsStretch>
          <FormattedMessage tagName="span" values={{ small: (msg) => <small>{msg}</small> }} id="addANote" />
          <FormControl
            data-testid="investor-message-input"
            id="investorMessage"
            type="textarea"
            componentClass="textarea"
            placeholder={intl.formatMessage({ id: 'addYourNoteHere' })}
            rows="4"
            autoFocus
            onChange={(e) => setInvestorMessage(e.target.value)}
            value={investorMessage}
          />
          <ConfirmationPopover
            data-testid="confirm-button"
            buttonLabelId="sendResponse"
            disabled={!reason}
            confirmationMessageId="areYouSure"
            onConfirm={() => onRejectClick({ reason, investorMessage })}
            className="btn-solid-primary"
          />
        </Column>
      </Modal.Body>
    </Modal>
  );
});

AlternativeActionsModal.propTypes = {
  show: PropTypes.bool,
  isConfirming: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onRejectClick: PropTypes.func.isRequired,
};

AlternativeActionsModal.defaultProps = {
  show: false,
  isConfirming: false,
};
