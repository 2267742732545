import './table-settings-drawer.scss';

import React, { useCallback, useEffect, useState } from 'react';

import Table from 'rc-table';
import { Button } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaBars } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import Checkbox from '../../checkbox';
import { Drawer } from '../../Drawer/Drawer';
import { Column } from '../../Flex/Flex';
import { DraggableRow } from '../DraggableRow';

const components = {
  body: {
    row: DraggableRow,
  },
};

export const TableSettingsDrawer = ({ isOpen, setIsTableSettingsOpen, tableSettings, onSave }) => {
  const [updatedColumns, setUpdatedColumns] = useState([]);

  const columns = [
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      width: '10%',
      render: () => (
        <i>
          <FaBars />
        </i>
      ),
    },
    {
      title: 'Column',
      dataIndex: 'label',
      key: 'label',
      render: (id, { values }) => <FormattedMessage id={id} values={values} />,
    },
    {
      title: 'Select',
      dataIndex: 'key',
      key: 'key',
      width: '10%',
      render: (_, { key, show }) => <Checkbox name={key} checked={!!show} onChange={onCheckboxChange} />,
    },
  ];

  useEffect(() => {
    setUpdatedColumns(tableSettings);
  }, [tableSettings]);

  const onCheckboxChange = ({ target: { id, checked } }) => {
    const newState = updatedColumns.map((column) => (column.key === id ? { ...column, show: checked } : column));

    setUpdatedColumns(newState);
  };

  const onSaveButtonClick = () => {
    onSave(updatedColumns);
    setIsTableSettingsOpen(false);
  };

  const moveRow = useCallback(
    (dragIndex, dropIndex) => {
      const elementsUpdated = moveElementFromArray({
        array: updatedColumns,
        initialIndex: dragIndex,
        finalIndex: dropIndex,
      });

      setUpdatedColumns([...elementsUpdated]);
    },
    [updatedColumns, setUpdatedColumns],
  );

  const buildDataSource = () => updatedColumns.filter(({ label }) => !!label);

  return (
    <Drawer
      titleId="tableSettings"
      className="light table-settings"
      open={isOpen}
      shouldCloseOnClickOutside={false}
      onClose={() => setIsTableSettingsOpen(false)}
      width="30vw"
    >
      <Column className="table-settings-content">
        {updatedColumns?.length && (
          <DndProvider backend={HTML5Backend}>
            <Table
              className="light-table"
              rowKey="columnKey"
              columns={columns}
              data={buildDataSource()}
              components={components}
              onRow={(_, index) => ({
                index,
                moveRow,
              })}
            />
          </DndProvider>
        )}
        <Column flex={1}>
          <Button className="btn-solid-primary" onClick={onSaveButtonClick}>
            Save
          </Button>
        </Column>
      </Column>
    </Drawer>
  );
};

const moveElementFromArray = ({ array, initialIndex, finalIndex }) => {
  array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);

  return array;
};
