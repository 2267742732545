const socketIO = (socket) => () => (next) => (action) => {
  const { socket: socketMeta } = action;

  if (socketMeta && socketMeta.event) {
    socket.emit(socketMeta.event, socketMeta.data);
  }

  return next(action);
};

export default socketIO;
