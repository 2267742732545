import './ratesheet-update-presenter.scss';

import React, { Component } from 'react';

import numbro from 'numbro';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Loadable } from '../common';
import { RatesheetsTable } from './ratesheets-table';

const sheetLineHeight = 32;

class UpdateRatesheetPresenter extends Component {
  static propTypes = {
    data: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    onClickUpdateRatesheet: PropTypes.func.isRequired,
    subtitle: PropTypes.string.isRequired,
    instrumentCode: PropTypes.string.isRequired,
  };

  beforeChange = (changes) => {
    if (changes[0][1] === 'available') {
      return;
    }

    const cellValue = changes[0][3];

    const fourDecimalsValue = !cellValue || Number.isNaN(+cellValue) ? null : (+cellValue).toFixed(4);

    changes[0][3] = fourDecimalsValue; // eslint-disable-line
  };

  /* eslint-disable no-param-reassign, no-plusplus, no-continue */
  beforePaste = (data) => {
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
      for (let columnIndex = 0; columnIndex < data[rowIndex].length; columnIndex++) {
        const cellValue = data[rowIndex][columnIndex];

        if (!cellValue || Number.isNaN(+cellValue)) {
          data[rowIndex][columnIndex] = null;

          continue;
        }

        data[rowIndex][columnIndex] = (+cellValue).toFixed(4);
      }
    }
  };
  /* eslint-enable */

  customSimpleRenderer = (valueFormatter) => (instance, td, row, col, prop, value) => {
    const changedTd = td;

    changedTd.innerText = valueFormatter(value);
    changedTd.className = 'htCenter';

    return changedTd;
  };

  rateRenderer = this.customSimpleRenderer((value) => (value !== null ? numbro(value).format('0.0000') : value));

  twelveMonthColumns = [
    { data: 'segmentCode', className: 'htLeft', readOnly: true },
    {
      data: 'available',
      className: 'htCenter',
      editor: 'select',
      selectOptions: ['Yes', 'No'],
    },
    { data: '1M', renderer: this.rateRenderer },
    { data: '2M', renderer: this.rateRenderer },
    { data: '3M', renderer: this.rateRenderer },
    { data: '4M', renderer: this.rateRenderer },
    { data: '5M', renderer: this.rateRenderer },
    { data: '6M', renderer: this.rateRenderer },
    { data: '7M', renderer: this.rateRenderer },
    { data: '8M', renderer: this.rateRenderer },
    { data: '9M', renderer: this.rateRenderer },
    { data: '10M', renderer: this.rateRenderer },
    { data: '11M', renderer: this.rateRenderer },
    { data: '12M', renderer: this.rateRenderer },
  ];

  twoToFiveYearsColumns = [
    { data: '2Y', renderer: this.rateRenderer },
    { data: '3Y', renderer: this.rateRenderer },
    { data: '4Y', renderer: this.rateRenderer },
    { data: '5Y', renderer: this.rateRenderer },
  ];

  columns =
    this.props.instrumentCode.toUpperCase() === 'TD'
      ? this.twelveMonthColumns.concat(this.twoToFiveYearsColumns)
      : this.twelveMonthColumns;

  colNames = this.columns.map((column) => `${column.data}`);

  buildColHeaders = (index) => {
    const columnName = this.colNames[index];
    const isTenor = /^\d{1,2}\D{1}$/g.test(columnName);

    return isTenor ? columnName : this.props.intl.formatMessage({ id: columnName });
  };

  renderRecords = () => {
    const { isFetching, isUpdating, data } = this.props;

    if (isFetching || isUpdating) {
      return (
        <div className="text-center">
          <Loadable isLoading={isFetching || isUpdating} />
        </div>
      );
    }

    if (!data) {
      return (
        <div className="text-center no-records-message">
          <FormattedMessage id="noRecords" />
        </div>
      );
    }

    const currentInstrumentCode = this.props.instrumentCode.toUpperCase();

    const tableData = data.instruments[currentInstrumentCode].map((item) => ({
      ...item,
      ...item.rates,
    }));

    return (
      <RatesheetsTable
        colHeaders={this.buildColHeaders}
        columns={this.columns}
        data={tableData}
        height={sheetLineHeight * tableData.length}
        onClickUpdateRatesheet={this.props.onClickUpdateRatesheet}
        readOnly={false}
        rowHeaders={false}
        stretchH="all"
        isFetching={isFetching}
        isUpdating={isUpdating}
        beforeChange={this.beforeChange}
        beforePaste={this.beforePaste}
        subtitle={this.props.subtitle}
        instrumentCode={this.props.instrumentCode}
      />
    );
  };

  render() {
    return (
      <div id={this.props.instrumentCode.toLowerCase()} className="update-ratesheet-presenter">
        <div className="results panel panel-filled">
          <div>{this.renderRecords()}</div>
        </div>
      </div>
    );
  }
}

export default injectIntl(UpdateRatesheetPresenter);
