import './value-highlight.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ValueHighlight = (props) => {
  const { className, isFetching, label, children } = props;

  return (
    <div className={classNames('value-highlight', className, { 'is-loading': isFetching })}>
      <span className="light-primary-color">
        <FormattedMessage tagName="span" id={label} />
      </span>
      <span className="white-color">{children}</span>
    </div>
  );
};

ValueHighlight.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFetching: PropTypes.bool,
  label: PropTypes.string,
};

export default ValueHighlight;
