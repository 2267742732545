import './file-uploader.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FaCloudUploadAlt, FaFile, FaTrash } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { ConfirmationPopover } from '../ConfirmationPopover/ConfirmationPopover';

const maxAllowedSize = 10 * 1024 * 1024;
const maxFilenameLength = 40;

export const FileUploader = ({
  isDownloading,
  fileReferenceKey,
  isLoading,
  filename,
  onDownloadClick,
  onChange,
  onConfirmDeletion,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState();
  const [referenceKey, setReferenceKey] = useState(fileReferenceKey);

  useEffect(() => {
    setReferenceKey(fileReferenceKey);
  }, [fileReferenceKey]);

  const handleFileInput = ({ target }) => {
    const [file] = target.files;

    if (file.size > maxAllowedSize) {
      setError({ key: 'file-greater-than-max-allowed-size', values: { size: '10MB' } });
      target.value = '';

      return;
    }

    if (file.name.length > maxFilenameLength) {
      setError({ key: 'filename-greater-than-max', values: { characters: maxFilenameLength } });
      target.value = '';

      return;
    }

    setError(undefined);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      onChange({ content: reader.result, filename: encodeURIComponent(file.name) });
    };

    setSelectedFile(file);
  };

  const clearFile = () => {
    setReferenceKey(undefined);
    onChange(undefined);
    setSelectedFile(undefined);
    setError(undefined);
    onConfirmDeletion && onConfirmDeletion();
  };

  return (
    <Row
      className={classNames('file-uploader', {
        'is-loading': isLoading,
        'is-downloading': isDownloading,
        'has-error': error,
      })}
      alignItemsCenter
      justifyContentSpaceBetween
    >
      {referenceKey ? (
        <>
          <Button bsStyle="link" onClick={onDownloadClick}>
            <FaFile />
            <span className="text-ellipsis">{decodeURIComponent(filename)}</span>
          </Button>
          <ConfirmationPopover disabled={isLoading} confirmationMessageId="areYouSure" onConfirm={clearFile}>
            {({ onPopoverHandlerClick }) => <FaTrash onClick={onPopoverHandlerClick} />}
          </ConfirmationPopover>
        </>
      ) : (
        <>
          <input accept=".pdf" id="file-upload" type="file" onChange={handleFileInput} />
          <Row element="label" justifyContentSpaceBetween alignItemsCenter htmlFor="file-upload">
            <FaCloudUploadAlt />
            <FormattedMessage tagName="span" id="uploadFile" />
            {error && <FormattedMessage tagName="span" id={error.key} values={error.values} />}
          </Row>
          {selectedFile && (
            <>
              <span className="text-ellipsis">{selectedFile.name}</span>
              <ConfirmationPopover confirmationMessageId="areYouSure" onConfirm={clearFile}>
                {({ onPopoverHandlerClick }) => <FaTrash onClick={onPopoverHandlerClick} />}
              </ConfirmationPopover>
            </>
          )}
        </>
      )}
    </Row>
  );
};

FileUploader.propTypes = {
  className: string,
  onChange: func.isRequired,
  fileReferenceKey: string,
  filename: string,
  isLoading: bool,
  isDownloading: bool,
  onDownloadClick: func.isRequired,
  onConfirmDeletion: func,
};
