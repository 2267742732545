import { buildFilter, validateAvailableFilters } from '../helper/filter';
import { getDefaultDates, getFilterDate } from '../../date';

const constants = {
  maturity: 'MATURITY',
  counterparty: 'COUNTERPARTY',
  credit: 'CREDIT',
};

const updateFilterActionType = compliance => `UPDATE_${constants[compliance]}_FILTER`;
const updateFilteredDataActionType = compliance => `UPDATE_${constants[compliance]}_FILTERED_DATA`;

export function resetFilter (compliance) {
  return async (dispatch, getState) => {
    const state = getState();

    const newFilter = {};

    if (state[compliance].filter && state[compliance].filter.date) {
      newFilter.date = state[compliance].filter.date;
    }

    dispatch({ type: updateFilterActionType(compliance), newFilter });
  };
}

export function updateFilter (compliance, dispatch, state, filter) {
  const newFilter = buildFilter(filter, state[compliance].filter);

  dispatch({ type: updateFilterActionType(compliance), newFilter });
}

export function updateSelectedDate (compliance, dispatch, state, baseDate) {
  const datesFilter = {
    query: getDefaultDates(baseDate),
    date: baseDate,
  };

  const newFilter = buildFilter(datesFilter, state[compliance].filter);

  this.fetchAsync(dispatch, state, newFilter);

  dispatch({ type: updateFilterActionType(compliance), newFilter });
}

export function updateFilteredData (compliance, match, data, availableFilters, filter) {
  const filteredData = getFilteredData(match, data, availableFilters, filter);

  return { type: updateFilteredDataActionType(compliance), filteredData };
}

const getFilteredData = (match, data, availableFilters, filter) => {
  const validFilter = {
    ...filter,
  };

  validateAvailableFilters(validFilter, availableFilters);

  return data.filter(item =>
    Object.keys(validFilter).every(property =>
      match(validFilter, item, property)
    )
  ).map(item => ({
    ...item,
  }));
};

function commonMatch (filter, item, property) {
  switch (property) {
    case 'isCompliant':
      return item[property] ? isTrueValue(filter[property]) : !isTrueValue(filter[property]);
    default:
      return item[property] ? item[property] === filter[property] : true;
  }
}

const isTrueValue = value => value && value === 'true';

export default function (action, { compliance, match = commonMatch }) {
  return {
    ...action,
    actionsTypes: {
      ...action.actionsTypes,
      UPDATE_FILTER: updateFilterActionType(compliance),
      UPDATE_FILTERED_DATA: updateFilteredDataActionType(compliance),
    },
    resetFilter: resetFilter.bind(null, compliance),
    updateFilter: updateFilter.bind(null, compliance),
    updateFilteredData: updateFilteredData.bind(null, compliance, match),
    updateSelectedDate: updateSelectedDate.bind(action, compliance),
  };
}
