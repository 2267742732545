import axios from 'axios';

export const buyBondsConfirmRfq = async ({ rfqEntityId }) =>
  axios({
    method: 'put',
    url: `/rfqs/buy-bonds/${rfqEntityId}/quotes/confirm`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
