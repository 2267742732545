import PropTypes from 'prop-types';
import cx from 'classnames';
import React from 'react';

import { Column } from '../../../components/common';
import { TenorItem } from './TenorItem';
import { EditableTenorItem } from './EditableTenorItem';
import { isAcceptedOrReinvested } from '../../../ducks/issuer-trades/list';

import './tenors.scss';

const renderLoadingBoxes = ({ minimumTenorBoxes }) =>
  Array.from({ length: minimumTenorBoxes }).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Column key={index} className="tenor is-loading-skeleton" />
  ));

export const Tenors = ({
  ratesheet,
  status,
  selectedTenor,
  isLoading,
  disabled,
  readOnly,
  onClick,
  minimumTenorBoxes,
  onTenorItemChange,
  ...rest
}) => (
  <div className={cx('tenors-grid', { disabled, editable: !!onTenorItemChange, 'read-only': readOnly })} {...rest}>
    {isLoading
      ? renderLoadingBoxes({ minimumTenorBoxes })
      : ratesheet &&
        ratesheet.rates &&
        ratesheet.rates.map(({ tenor, rate, originalRate, isCustom }) => {
          const tenorClassName = cx({
            active: selectedTenor === tenor,
            winner: selectedTenor === tenor && isAcceptedOrReinvested(status),
            declined: selectedTenor && selectedTenor !== tenor,
          });

          const props = {
            key: tenor,
            className: tenorClassName,
            onClick,
            tenor,
            rate,
            isCustom,
          };

          return onTenorItemChange ? (
            <EditableTenorItem {...props} onChange={onTenorItemChange} defaultRate={originalRate} />
          ) : (
            <TenorItem {...props} />
          );
        })}
  </div>
);

Tenors.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  status: PropTypes.string,
  ratesheet: PropTypes.shape({
    updatedAt: PropTypes.string,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
  selectedTenor: PropTypes.string,
  minimumTenorBoxes: PropTypes.number,
  onClick: PropTypes.func,
  onTenorItemChange: PropTypes.func,
};

const defaultMinimumTenorBoxes = 16;

Tenors.defaultProps = {
  disabled: false,
  isLoading: false,
  minimumTenorBoxes: defaultMinimumTenorBoxes,
  ratesheet: undefined,
  selectedTenor: undefined,
  status: undefined,
};
