import Joyride, { STATUS, EVENTS } from 'react-joyride';
import PropTypes from 'prop-types';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FormattedMessage } from 'react-intl';

import { tours } from './steps';
import { walkthroughStore } from './store';

import defaultTheme from '../../../containers/app/theme.scss';

const styles = {
  options: {
    zIndex: 9999,
    primaryColor: defaultTheme.themePrimaryColor,
  },
  buttonNext: {
    color: defaultTheme.themePrimaryContrastColor,
    outline: 'none',
  },
  buttonBack: {
    color: defaultTheme.themePrimaryContrastColor,
  },
  tooltip: {
    fontSize: 14,
  },
};

const joyrideLocale = ['back', 'close', 'last', 'next', 'skip'].reduce(
  (locales, messageId) => ({
    ...locales,
    [messageId]: <FormattedMessage id={`${messageId}Tour`} />,
  }),
  {},
);

export const Tour = forwardRef(({ name, changeView }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [helpers, setGetHelpers] = useState({});

  useEffect(() => {
    setIsOpen(!walkthroughStore.has(name));
  }, []);

  useImperativeHandle(ref, () => ({
    onReset: () => {
      walkthroughStore.remove(name);
      helpers.reset(true);
      setIsOpen(true);
    },
  }));

  const callback = ({ status, type, step: { requiredTab } }) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      walkthroughStore.add(name);
      setIsOpen(false);
    }

    if (type === EVENTS.STEP_BEFORE && requiredTab) {
      changeView(requiredTab);
    }
  };

  const steps = tours[name].map((step) => ({
    ...step,
    content: <FormattedMessage id={step.content} values={{ breakline: <br /> }} />,
  }));

  return (
    <Joyride
      run={isOpen}
      callback={callback}
      getHelpers={(tourHelpers) => setGetHelpers(tourHelpers)}
      steps={steps}
      locale={joyrideLocale}
      styles={styles}
      disableScrolling
      disableScrollParentFix
      showProgress
      showSkipButton
      continuous
    />
  );
});

Tour.propTypes = {
  name: PropTypes.string.isRequired,
  changeView: PropTypes.func,
};
