import orderBy from 'lodash.orderby';
import { createSelector } from 'reselect';

import { sortedRatings } from '../../api/holding/codes';
import { defaultDateFormat, newDate, today } from '../../date';
import { getTradeRating } from '../../get-trade-rating';

const getTrades = ({ holding }) => (holding ? holding.trades : []);

const getTradesFilter = ({ filter }) => filter;

const sortByIssuerName = ({ issuer: { name } }) => name;

export const getFilteredTrades = createSelector(
  [getTrades, getTradesFilter],
  (list, filter) => {
    if (!filter) {
      return list;
    }

    let filteredList = list;

    if (filter.instrumentCode) {
      filteredList = filteredList.filter(
        ({ instrumentCode }) =>
          instrumentCode.toLowerCase() === filter.instrumentCode.toLowerCase()
      );
    }

    if (filter.allocationCode) {
      filteredList = filteredList.filter(
        ({ allocationCode }) =>
          allocationCode.toLowerCase() === filter.allocationCode.toLowerCase()
      );
    }

    if (filter.maturity >= 0) {
      filteredList = filteredList.filter(({ maturityDate }) => {
        const itemMaturityDate =
          newDate(maturityDate).format(defaultDateFormat);
        const todayDate = today().format(defaultDateFormat);

        return (
          newDate(itemMaturityDate).diff(newDate(todayDate), "days") <=
          filter.maturity
        );
      });
    }

    if (filter.searchText) {
      filteredList = filteredList.filter(({ issuer }) =>
        issuer.name.toLowerCase().includes(filter.searchText.toLowerCase())
      );
    }

    if (filter.sort) {
      return sortFilteredList({ filteredList, filterSort: filter.sort });
    }

    return filteredList;
  }
);

const sortFilteredList = ({ filteredList, filterSort }) => {
  const order = filterSort.startsWith("-") ? "desc" : "asc";
  const sort = filterSort.replace("-", "");

  if (sort === "issuerName") {
    return orderBy(filteredList, sortByIssuerName, [order]);
  }

  if (sort === "rating") {
    return filteredList.sort((tradeA, tradeB) => {
      const ratingA = getTradeRating(tradeA);
      const ratingB = getTradeRating(tradeB);
      const orderModifier = order === "desc" ? -1 : 1;

      return orderModifier * sortedRatings.indexOf(ratingA) <
        sortedRatings.indexOf(ratingB)
        ? -1
        : 1;
    });
  }

  return orderBy(filteredList, [sort], [order]);
};
