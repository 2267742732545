import buildInitialValues from '../base/initial-values';

export default function(instrumentCode) {
  const initialValues = buildInitialValues();

  return {
    ...initialValues,
    instrumentCode,
  };
}
