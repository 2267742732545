import { createSelector } from 'reselect';

const getAllOutgoingRfqsReport = (state) => state.outgoingRfqsReport.outgoingRfqs;

export const getOutgoingRfqHistoryOffers = createSelector(getAllOutgoingRfqsReport, (rows) =>
  rows.filter((row) => row.offerId),
);

export const getOutgoingRfqHistoryRfqs = createSelector(getAllOutgoingRfqsReport, (rows) =>
  rows.filter((row) => !row.offerId),
);
