import React from 'react';

import propTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrency } from '../../../actions/session-selector';
import { CloseBuyBondRfqDrawer } from './buy-bonds-rfqs/close-buy-bond-rfq/CloseBuyBondRfqDrawer';
import { CloseSellBondRfqDrawer } from './sell-bonds-rfqs/seller/close-sell-bond-rfq/CloseSellBondRfqDrawer';

export const BuySellBondRfqsCloseDrawerComponent = ({ rfq, onCloseDrawer, onChangeRfqSuccess, currency }) => {
  const Drawer = rfq && rfq.operation === 'buy' ? CloseBuyBondRfqDrawer : CloseSellBondRfqDrawer;

  return (
    <Drawer
      rfqUUID={rfq && rfq.uuid}
      onChangeRfqSuccess={onChangeRfqSuccess}
      onCloseDrawer={onCloseDrawer}
      currency={currency}
    />
  );
};

BuySellBondRfqsCloseDrawerComponent.propTypes = {
  rfq: propTypes.shape({
    operation: propTypes.string,
    uuid: propTypes.string,
  }),
  currency: propTypes.string,
  onCloseDrawer: propTypes.func,
  onChangeRfqSuccess: propTypes.func,
};

const mapStateToProps = (state) => ({
  currency: getCurrency(state),
});

export const BuySellBondRfqsCloseDrawer = connect(mapStateToProps)(BuySellBondRfqsCloseDrawerComponent);
