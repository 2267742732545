import * as compliancesApiClient from '../../api/compliances';
import compliance from './common';
import fetch from '../helper/fetch';
import { validateAvailableFilters } from '../helper/filter';
import { getDefaultDates } from '../../date';

export const FETCH_COUNTERPARTY = 'COUNTERPARTY';
export const UPDATE_COUNTERPARTY_PAGINATION = 'UPDATE_COUNTERPARTY_PAGINATION';
export const UPDATE_COUNTERPARTY_FILTER = 'UPDATE_COUNTERPARTY_FILTER';
export const UPDATE_COUNTERPARTY_ROW_LIMIT = 'UPDATE_COUNTERPARTY_ROW_LIMIT';
export const UPDATE_COUNTERPARTY_FILTERED_ISSUERS = 'UPDATE_COUNTERPARTY_FILTERED_ISSUERS';

const counterpartyFetchAction = compliance(fetch(FETCH_COUNTERPARTY,
  shouldFetchCounterparty,
  async (params) => ({
    counterparty: await compliancesApiClient.counterparty({
      query: getDefaultDates(params && params.date),
      ...params,
    }),
    params,
  }),
  validateCounterpartyParams,
), {
  compliance: 'counterparty',
  match,
});

export const counterpartyFetchPayload = counterpartyFetchAction.actionsTypes;

export function fetchCounterpartyIfNeeded (params) {
  return counterpartyFetchAction.fetchIfNeeded(params);
}

export function fetchCounterparty (params) {
  return counterpartyFetchAction.fetchIfNeeded(params);
}

function shouldFetchCounterparty (params = {}, state) {
  if (state.counterparty.isFetching || state.counterparty.didInvalidate) {
    return false;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  return !state.counterparty.counterparty;
}

function validateCounterpartyParams (params, { counterparty: { availableFilters } }) {
  if (!params) {
    return;
  }

  validateAvailableFilters(params, availableFilters);
}

export function changePage (pagination) {
  return { type: UPDATE_COUNTERPARTY_PAGINATION, pagination };
}

export function changeRowLimit (limit) {
  const pagination = { limit, activePage: 1 };
  return { type: UPDATE_COUNTERPARTY_ROW_LIMIT, pagination };
}

export function resetFilter () {
  return counterpartyFetchAction.resetFilter();
}

export function updateFilter (filter) {
  return async (dispatch, getState) => {
    counterpartyFetchAction.updateFilter(dispatch, getState(), filter);
  };
}

export function updateFilteredData ({ counterparty, availableFilters, filter }) {
  return counterpartyFetchAction.updateFilteredData(counterparty.issuers, availableFilters, filter);
}

export function updateSelectedDate (date) {
  return async (dispatch, getState) => {
    counterpartyFetchAction.updateSelectedDate(dispatch, getState(), date);
  };
}

function match (filter, issuer, property) {
  switch (property) {
    case 'searchText': {
      const searchTextRegex = new RegExp(filter[property], 'ig');
      return searchTextRegex.test(propertiesToMatchByText(issuer));
    }
    case 'isCompliant':
      return issuer[property] ? isTrueValue(filter[property]) : !isTrueValue(filter[property]);
    default:
      return issuer[property] ? issuer[property] === filter[property] : true;
  }
}

const isTrueValue = value => value && value === 'true';

const propertiesToMatchByText = issuer =>
  ['name', 'code', 'term', 'rating']
    .map(toMatch => issuer[toMatch]).join('|');
