import BaseFields from '../base/fields';

export default [
  {
    name: 'cp',
    label: 'holdingCP',
    componentType: 'async-select',
    jsonField: 'id',
  },
  {
    name: 'sellingDate',
    label: 'holdingSellingDate',
    componentType: 'date',
    disableWeekends: false,
    disableHolidays: false,
  },
  {
    name: 'sellingPrice',
    label: 'holdingSellingPrice',
    componentType: 'currency',
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
