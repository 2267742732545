import axios from 'axios';

export async function postIssuerTrades(body) {
  const { data } = await axios({
    method: 'post',
    url: '/issuer-trades',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  });

  return data;
}
