import {
  UPDATE_CREDIT_QUALITY_FILTER,
  UPDATE_CREDIT_QUALITY_FILTERED_RATINGS,
  UPDATE_CREDIT_QUALITY_PAGINATION,
  UPDATE_CREDIT_QUALITY_ROW_LIMIT,
  creditFetchPayload,
  getFilteredRatings,
} from '../../actions/compliances/credit';
import { UPDATE_PORTFOLIO_SELECTED_DATE } from '../../actions/portfolio';

import { pagination, calculateItems } from '../common/pagination';

export const INITIAL_STATE = {
  hasInitiated: false,
  isFetching: false,
  didInvalidate: false,
  error: null,
  credit: null,
  pagination,
  filter: {},
  ratings: [],
  availableFilters: {
    isCompliant: ['All', 'true', 'false'],
    term: ['All', 'Long', 'Short'],
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case creditFetchPayload.REQUEST: {
      const silentFetch = action.state && action.state.portfolio && action.state.portfolio.silentFetch;

      return {
        ...state,
        hasInitiated: true,
        isFetching: !silentFetch,
        didInvalidate: true,
        error: null,
      };
    }
    case creditFetchPayload.SUCCESS:
      return onCreditSuccess(state, action);
    case creditFetchPayload.FAIL:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case UPDATE_CREDIT_QUALITY_PAGINATION:
      return setCreditQualityPaginationOnNextState(state, action);
    case UPDATE_CREDIT_QUALITY_ROW_LIMIT:
      return setCreditQualityPaginationOnNextState(state, action);
    case UPDATE_CREDIT_QUALITY_FILTER:
      return {
        ...state,
        filteredData: null,
        filter: {
          ...action.newFilter,
        },
      };
    case UPDATE_CREDIT_QUALITY_FILTERED_RATINGS:
      return {
        ...state,
        filteredData: action.filteredData,
        pagination: {
          ...state.pagination,
          activePage: 1,
          items: calculateItems(action.filteredData.length, state.pagination.limit),
        },
      };
    case UPDATE_PORTFOLIO_SELECTED_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
        error: null,
      };
    default:
      return state;
  }
}

function onCreditSuccess(state, action) {
  const credit = {
    ...action.credit,
    ratings: presenterRatings(action),
  };

  const availableFilters = { ...state.availableFilters };

  if (action.credit.longTermComplianceOnly) {
    delete availableFilters.term;
  }

  const newState = {
    ...state,
    isFetching: false,
    didInvalidate: false,
    credit,
    pagination: {
      ...state.pagination,
      activePage: 1,
      items: calculateItems(credit.ratings.length, state.pagination.limit),
    },
    error: null,
    availableFilters,
  };

  handleFilter(newState);

  return newState;
}

/* eslint-disable no-param-reassign */
function handleFilter(newState) {
  const filteredData = getFilteredRatings(newState, newState.filter);

  newState.filteredData = filteredData;
  newState.pagination = {
    ...newState.pagination,
    items: calculateItems(filteredData.length, newState.pagination.limit),
  };
}
/* eslint-disable no-param-reassign */

function presenterRatings({ credit }) {
  let ratings = credit.long.ratings.map((rating) => ({ ...rating, term: 'long' }));
  if (credit.short) {
    ratings = ratings.concat(credit.short.ratings.map((rating) => ({ ...rating, term: 'short' })));
  }

  return ratings;
}

function setCreditQualityPaginationOnNextState(state, action) {
  const nextPagination = {
    ...state.pagination,
    ...action.pagination,
  };

  if (state.pagination.limit !== nextPagination.limit) {
    const safeRatings = state.filteredData || state.credit.ratings;
    nextPagination.items = calculateItems(safeRatings.length, nextPagination.limit);
  }

  return {
    ...state,
    pagination: {
      ...nextPagination,
    },
  };
}
