const fontSize = '1em';

export const nivoChartTheme = {
  labels: {
    text: {
      fontSize,
    },
  },
  axis: {
    ticks: {
      text: {
        fontSize,
        fill: 'var(--primary-text-color)',
      },
    },
  },
  legends: {
    text: {
      fontSize,
      fill: 'var(--primary-text-color)',
    },
  },
  tooltip: {
    container: {
      background: 'var(--background-light-color)',
      color: 'var(--background-light-contrast-color)',
    },
  },
};

export const defaultChartGradientOpacity = 0.8;
