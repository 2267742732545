import buildForm from '../../common/form/form';
import buildBaseInitialValues from '../base/initial-values';
import { fields } from './fields';
import { schema } from './schema';

export const addTermAnnuityForm = ({ onSave, currencySymbol }) => ({
  title: 'addTermAnnuityOption',
  value: 'addTermAnnuity',
  className: 'add-ta-form',
  save: onSave,
  form: buildForm({
    fields: fields({ currencySymbol }),
    schema,
    initialValues: buildBaseInitialValues(),
    destroyOnUnmount: false,
    enableReinitialize: true,
  }),
});
