import './skeleton-chart.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Column } from '../Flex/Flex';

export const SkeletonChart = ({ children, isLoading, rows }) =>
  isLoading ? (
    <Column className="skeleton-chart">
      {[...Array(rows)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} />
      ))}
    </Column>
  ) : (
    children
  );

SkeletonChart.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  rows: PropTypes.number,
};

SkeletonChart.defaultProps = {
  rows: 3,
};
