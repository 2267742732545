import './change-password.scss';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';

import { fetchSession } from '../../actions/session';
import { updateUser } from '../../api/user/update';
import { ValidatedFormikField } from '../../components/common';
import { PasswordField } from '../../components/common/formik/fields/PasswordField';
import { withNavigate } from '../../components/hoc/with-router-properties';
import { routes } from '../../routes';
import { validationErrorKeys } from '../../validation/error-keys';
import passwordComplexityTest from '../../validation/password-complexity';
import { MessageType, showToastMessage } from '../toast/toast';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const getValidationSchema = (format) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(format('currentPasswordIsRequired')),
    newPassword: Yup.string()
      .matches(passwordComplexityTest, format('minimumPasswordComplexityRequired'))
      .required(format('newPasswordIsRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], format('passwordsDoNotMatch'))
      .required(format('confirmPasswordIsRequired')),
  });

const ChangePasswordPage = (props) => {
  const format = (messageId) => props.intl.formatMessage({ id: messageId });
  const validationSchema = getValidationSchema(format);

  const onSubmit = async (data, { setSubmitting, resetForm }) => {
    const {
      session: {
        user: { id },
      },
    } = props;

    try {
      const { currentPassword, newPassword } = data;

      await updateUser({ id, currentPassword, newPassword });

      showToastMessage(format('changedPasswordSuccess'), MessageType.SUCCESS);
      resetForm();
      await props.actions.fetchSession();
      props.navigate(routes.root, { replace: true });
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error_message;

      const messageId =
        errorMessage === validationErrorKeys.repeatedPassword ? 'repeatedPassword' : 'currentPasswordError';
      showToastMessage(format(messageId), MessageType.ERROR);
    }
  };

  return (
    <div className="change-password">
      <h3>
        <FormattedMessage id="changePassword" />
      </h3>
      <div className="form-container">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, values, touched, errors }) => (
            <Form>
              <fieldset disabled={isSubmitting}>
                <ValidatedFormikField
                  id="currentPassword"
                  name="currentPassword"
                  labelId="currentPassword"
                  className="form-control"
                  data-testid="currentPassword"
                  value={values.currentPassword || ''}
                  component={PasswordField}
                  touched={touched}
                  errors={errors}
                />
                <ValidatedFormikField
                  id="newPassword"
                  name="newPassword"
                  labelId="newPassword"
                  className="form-control"
                  data-testid="newPassword"
                  value={values.newPassword || ''}
                  component={PasswordField}
                  touched={touched}
                  errors={errors}
                />
                <ValidatedFormikField
                  id="confirmPassword"
                  name="confirmPassword"
                  labelId="confirmPassword"
                  className="form-control"
                  data-testid="confirmPassword"
                  value={values.confirmPassword || ''}
                  component={PasswordField}
                  touched={touched}
                  errors={errors}
                />
                <div className="actions-container">
                  <button
                    type="submit"
                    className={classNames('btn primary-button', {
                      loading: isSubmitting,
                    })}
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="send" />
                  </button>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
      <button type="button" onClick={() => props.navigate(-1)}>
        <FormattedMessage id="goBack" />
      </button>
    </div>
  );
};

ChangePasswordPage.propTypes = {
  session: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({ session: state.session });

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchSession: () => dispatch(fetchSession()),
  },
});

export const ChangePassword = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withNavigate,
)(ChangePasswordPage);
