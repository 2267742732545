import { createActionThunk } from 'redux-thunk-actions';

import { getOutstandingOffers as getOutstandingOffersApi } from '../../api/offers/get-outstanding-offers';

export const getOutstandingOffersAction = createActionThunk('GET_OUTSTANDING_OFFERS', getOutstandingOffersApi);

export const outstandingOffersReducer = {
  [getOutstandingOffersAction.STARTED]: (state) => ({
    ...state,
    error: null,
    isFetching: true,
  }),
  [getOutstandingOffersAction.SUCCEEDED]: (state, { payload: list }) => ({
    ...state,
    list,
    isFetching: false,
  }),
  [getOutstandingOffersAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [getOutstandingOffersAction.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    statusCode: null,
  }),
};
