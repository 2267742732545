/* eslint-disable no-param-reassign */
export default function calculateWeightedAverageCoupon(trades) {
  const getNumber = (value) => (!Number.isNaN(+value) ? Number(value) : 0);
  const weightedAverage =
    trades.length &&
    trades.reduce(
      (summary, trade) => {
        const principal = getNumber(trade.principal);
        const coupon = getNumber(trade.coupon);
        summary.couponCalc += principal * coupon;
        summary.principalTotal += principal;
        summary.result = summary.couponCalc / summary.principalTotal;

        return summary;
      },
      {
        principalTotal: 0,
        couponCalc: 0,
        result: 0,
      },
    );

  return weightedAverage;
}
