import './capital-value.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { update } from '../../api/capital-values';
import { Column } from '../../components/common';
import { hasTenantCapitalValueEnabled } from '../../components/common/Protect/user-rules';
import { Forbidden } from '../../components/error/403-forbidden';
import withConfirmDialog from '../../components/hoc/with-confirm-dialog';
import { enAuShortDateFormat, formatDate } from '../../date';
import {
  createCapitalValueAction,
  listCapitalValueAction,
  removeCapitalValueAction,
} from '../../ducks/capital-values';
import { MessageType, showToastMessage } from '../toast/toast';
import CapitalValueCard from './CapitalValueCard';

const mapStateToProps = ({ capitalValues, session }) => ({
  capitalValues,
  session,
});

const mapDispatchToProps = {
  listCapitalValueAction,
  createCapitalValueAction,
  removeCapitalValueAction,
};

export class CapitalValue extends Component {
  static propTypes = {
    capitalValues: PropTypes.object.isRequired,
    listCapitalValueAction: PropTypes.func.isRequired,
    createCapitalValueAction: PropTypes.func.isRequired,
    removeCapitalValueAction: PropTypes.func.isRequired,
    toggleConfirmDialog: PropTypes.func,
    onConfirmClick: PropTypes.func,
    session: PropTypes.object,
  };

  state = {
    isAdding: false,
    idBeingRemoved: null,
  };

  componentDidMount() {
    this.props.listCapitalValueAction();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.hasError(nextProps)) {
      showToastMessage(this.props.intl.formatMessage({ id: 'anErrorHasOccurred' }));

      return;
    }

    if (this.hasAddedSuccessfully(nextProps)) {
      this.setAddingModeOff();
      showToastMessage(this.props.intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);

      return;
    }

    if (this.hasRemovedSuccessfully(nextProps)) {
      showToastMessage(this.props.intl.formatMessage({ id: 'deletedSuccessfully' }), MessageType.SUCCESS);
      this.setState({ idBeingRemoved: null });
    }
  }

  hasAddedSuccessfully = (nextProps) =>
    this.state.isAdding && nextProps.capitalValues.list.length > this.props.capitalValues.list.length;

  hasRemovedSuccessfully = (nextProps) =>
    this.state.idBeingRemoved && nextProps.capitalValues.list.length < this.props.capitalValues.list.length;

  hasError = (nextProps) => !this.props.capitalValues.error && nextProps.capitalValues.error;

  setAddingModeOn = () => this.setState({ isAdding: true });

  setAddingModeOff = () => this.setState({ isAdding: false });

  renderAddingSection = () =>
    this.state.isAdding ? (
      <CapitalValueCard
        onSubmit={this.onAddingCapitalValue}
        initialValues={{ asOfDate: new Date(), capitalValue: '' }}
        onCloseClick={this.setAddingModeOff}
      />
    ) : null;

  onAddingCapitalValue = (data, { setSubmitting }) => {
    this.props.createCapitalValueAction(data);

    setSubmitting(false);
  };

  removeCapitalValue = async (idBeingRemoved) => {
    const { capitalValues } = this.props;

    if (capitalValues.list.length === 1) {
      showToastMessage(this.props.intl.formatMessage({ id: 'mustHaveOneCapitalValue' }));

      return;
    }

    const { toggleConfirmDialog, onConfirmClick } = this.props;
    const { asOfDate, capitalValue } = capitalValues.list.find(({ id }) => id === idBeingRemoved);

    toggleConfirmDialog({
      contentValues: {
        asOfDate: formatDate(asOfDate, enAuShortDateFormat),
        capitalValue,
      },
    });

    onConfirmClick(async () => {
      this.setState({ idBeingRemoved });

      toggleConfirmDialog();

      this.props.removeCapitalValueAction(idBeingRemoved);
    });
  };

  onSubmit = async (data, { setSubmitting, resetForm }) => {
    try {
      await update(data);
      showToastMessage(this.props.intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);
    } catch (error) {
      showToastMessage(this.props.intl.formatMessage({ id: 'anErrorHasOccurred' }));
    }

    setSubmitting(false);
    resetForm({ values: { ...data } });
  };

  renderAddNewButton = () => (
    <Column className="add-button-wrapper" alignItemsCenter>
      <Button
        className="btn-solid-primary btn-add-another"
        onClick={this.setAddingModeOn}
        disabled={this.state.isAdding}
      >
        <FormattedMessage id="addNew" values={{ count: this.props.capitalValues.list.length }} />
      </Button>
    </Column>
  );

  render() {
    const {
      session: { user },
    } = this.props;

    if (!hasTenantCapitalValueEnabled(user)) {
      return <Forbidden />;
    }

    const cards = this.props.capitalValues.list.map(({ id, capitalValue, asOfDate }) => (
      <CapitalValueCard
        key={id}
        onSubmit={this.onSubmit}
        initialValues={{ id, capitalValue, asOfDate }}
        onCloseClick={this.removeCapitalValue}
        isRemoving={this.state.idBeingRemoved === id}
      />
    ));

    return (
      <Column className="capital-value-container" alignItemsCenter>
        <h1 className="container-title">
          <FormattedMessage id="updateCapitalValue" />
        </h1>
        <Column>
          {cards}
          {this.renderAddingSection()}
          {this.renderAddNewButton()}
        </Column>
      </Column>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfirmDialog({
    cancelId: 'defaultCancelButton',
    confirmId: 'defaultConfirmButton',
    contentId: 'confirmDeleteCapitalValue',
    titleId: 'defaultConfirmTitle',
  }),
  injectIntl,
)(CapitalValue);
