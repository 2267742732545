import * as issuersApiClient from '../api/issuers';

export const LIST_ISSUERS_REQUEST = 'LIST_ISSUERS_REQUEST';
export const LIST_ISSUERS_SUCCESS = 'LIST_ISSUERS_SUCCESS';
export const LIST_ISSUERS_FAILURE = 'LIST_ISSUERS_FAILURE';

export function fetchIssuers(filter) {
  return async (dispatch) => {
    dispatch({ type: LIST_ISSUERS_REQUEST });
    try {
      const response = await issuersApiClient.list(filter);
      dispatch({ type: LIST_ISSUERS_SUCCESS, payload: response.list });
    } catch (error) {
      dispatch({
        type: LIST_ISSUERS_FAILURE,
        error: error.response || error,
      });
    }
  };
}
function shouldFetchIssuers({ issuers }) {
  if (!issuers) {
    return true;
  }

  if (issuers.isFetching) {
    return false;
  }

  return issuers.didInvalidate;
}

export function fetchIssuersIfNeeded(filter) {
  return (dispatch, getState) => {
    if (shouldFetchIssuers(getState())) {
      dispatch(fetchIssuers(filter));
    }
  };
}
