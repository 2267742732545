import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { useIsMounted } from '../../../hooks/use-is-mounted';
import { Row } from '../Flex/Flex';
import { Loadable } from '../loadable';

export const ConfirmationPopover = injectIntl(
  ({
    animation,
    buttonLabelId,
    className,
    confirmationMessageId,
    confirmationMessageValues,
    disabled,
    onConfirm,
    placement,
    popoverClass,
    isConfirmingFromOutside,
    confirmLabelId,
    cancelLabelId,
    popoverContent,
    showActionButtons,
    children,
    intl,
    container,
    ...props
  }) => {
    const [showConfirmationPopover, setShowConfirmationPopover] = useState(false);
    const [target, setTarget] = useState(null);
    const [isConfirming, setIsConfirming] = useState(false);

    const isMounted = useIsMounted();

    const onConfirmClick = async () => {
      setIsConfirming(true);
      try {
        await onConfirm();

        if (isMounted.current) {
          setShowConfirmationPopover(false);
        }
      } finally {
        if (isMounted.current) {
          setIsConfirming(false);
        }
      }
    };

    const onPopoverHandlerClick = (e) => {
      setShowConfirmationPopover(!showConfirmationPopover);
      setTarget(e.target);
    };

    const isPopoverHandlerDisabled = disabled || isConfirmingFromOutside;

    return (
      <React.Fragment>
        {children ? (
          children({
            onPopoverHandlerClick,
            disabled: isPopoverHandlerDisabled,
          })
        ) : (
          <Button
            className={classNames('btn-default', className)}
            disabled={isPopoverHandlerDisabled}
            onClick={onPopoverHandlerClick}
            {...props}
          >
            <Loadable isLoading={isConfirmingFromOutside} size="sm">
              <FormattedMessage tagName="span" id={buttonLabelId} />
            </Loadable>
          </Button>
        )}
        <Overlay
          onHide={() => {
            setShowConfirmationPopover(false);
          }}
          animation={animation}
          rootClose
          show={showConfirmationPopover || isConfirmingFromOutside}
          trigger="focus"
          placement={placement}
          container={container || this}
          target={target}
          shouldUpdatePosition
        >
          <Popover
            id={`confirmation-popup-${buttonLabelId}`}
            bsClass={classNames(popoverClass, 'popover')}
            title={
              confirmationMessageId && intl.formatMessage({ id: confirmationMessageId }, confirmationMessageValues)
            }
          >
            <React.Fragment>
              {popoverContent}
              {showActionButtons && (
                <Row
                  className={classNames('confirmation-popover', {
                    'is-confirming': isConfirming || isConfirmingFromOutside,
                  })}
                >
                  <Button
                    data-testid="confirm-popover-button"
                    className="btn-default btn-solid-primary"
                    disabled={isConfirming || isConfirmingFromOutside || disabled}
                    onClick={!disabled ? onConfirmClick : undefined}
                  >
                    <Loadable isLoading={isConfirming || isConfirmingFromOutside} size="sm">
                      <FormattedMessage tagName="span" id={confirmLabelId} />
                    </Loadable>
                  </Button>
                  <Button
                    className="btn-default"
                    onClick={() => {
                      setShowConfirmationPopover(false);
                    }}
                  >
                    <FormattedMessage tagName="span" id={cancelLabelId} />
                  </Button>
                </Row>
              )}
            </React.Fragment>
          </Popover>
        </Overlay>
      </React.Fragment>
    );
  },
);

ConfirmationPopover.propTypes = {
  animation: PropTypes.bool,
  buttonLabelId: PropTypes.string,
  className: PropTypes.string,
  confirmLabelId: PropTypes.string,
  cancelLabelId: PropTypes.string,
  children: PropTypes.func,
  disabled: PropTypes.bool,
  isConfirmingFromOutside: PropTypes.bool,
  confirmationMessageId: PropTypes.string,
  confirmationMessageValues: PropTypes.shape(),
  placement: PropTypes.string,
  onConfirm: PropTypes.func,
  popoverClass: PropTypes.string,
  popoverContent: PropTypes.node,
  showActionButtons: PropTypes.bool,
};

ConfirmationPopover.defaultProps = {
  animation: true,
  isConfirmingFromOutside: false,
  confirmationMessageId: null,
  confirmLabelId: 'yes',
  cancelLabelId: 'cancel',
  placement: 'top',
  showActionButtons: true,
};
