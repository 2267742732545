import LogRocket from 'logrocket';

export const eventAutoFillClicked = 'auto-fill-clicked';

export const securitiesOpened = 'securities-opened';
export const unsettledOpened = 'unsettled-opened';

export const sellBondRfqRequestQuotesToSellMenuClicked = 'sell-bond-rfq-request-quotes-to-sell-menu-clicked';
export const sellBondRfqInvestorViewSellBondRfqDetails = 'sell-bond-rfq-investor-view-sell-bond-rfq-details';
export const sellBondRfqBankViewSellBondRfqDetails = 'sell-bond-rfq-bank-view-sell-bond-rfq-details';
export const sellBondRfqSentQuoteClicked = 'sell-bond-rfq-sent-quote-clicked';
export const sellBondRfqNotInterested = 'sell-bond-rfq-not-interested';
export const sellBondRfqAcceptQuoteClicked = 'sell-bond-rfq-accept-quote-clicked';
export const sellBondRfqConfirmQuoteClicked = 'sell-bond-rfq-confirm-quote-clicked';
export const sellBondRfqCancelRfqClicked = 'sell-bond-rfq-cancel-rfq-clicked';
export const sellBondRfqRequestQuotesClicked = 'sell-bond-rfq-request-quote-clicked';
export const sellBondRfqCreateRfqExecutionOnlyClicked = 'sell-bond-rfq-create-rfq-execution-only-clicked';

export const buyBondRfqCreateRfqClicked = 'buy-bond-rfq-create-rfq-clicked';
export const buyBondRfqNotInterested = 'buy-bond-rfq-not-interested';
export const buyBondRfqInvestorViewBuyBondRfqDetails = 'buy-bond-rfq-investor-view-buy-bond-rfq-details';
export const buyBondRfqBankViewBuyBondRfqDetails = 'buy-bond-rfq-bank-view-buy-bond-rfq-details';
export const buyBondRfqConfirmQuoteClicked = 'buy-bond-rfq-confirm-quote-clicked';
export const buyBondRfqSentQuoteClicked = 'buy-bond-rfq-sent-quote-clicked';
export const buyBondRfqAcceptQuoteClicked = 'buy-bond-rfq-accept-quote-clicked';
export const buyBondRfqCancelRfqClicked = 'buy-bond-rfq-cancel-rfq-clicked';

export const updateRatesheetsOnSendQuotesDrawer = 'update-ratesheets-on-send-quotes-drawer';
export const updateRatesheetsOnRatesheetsPage = 'update-ratesheets-on-ratesheets-page';

export const track = (eventName, eventProperties) => {
  LogRocket.track(eventName, eventProperties);
};
