import React from 'react';

import classNames from 'classnames';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { FaEllipsisV, FaFile } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { isBondOrFrn } from '../../../api/holding/instrument-codes';
import { Money } from '../../../containers/money';
import { DateWithTimeZone } from '../../../date';
import { fullCouponFormat } from '../../../format-numbers';
import { getTradeRating } from '../../../get-trade-rating';
import { findLabelForInstrumentCode } from './find-label-for-instrument-code';

export const unsettledColumns = (currencySymbol) => ({
  instrumentCode: { label: 'type' },
  issuer: { label: 'holdingIssuer' },
  operation: { label: 'operation' },
  rating: { label: 'holdingRating' },
  allocation: { label: 'holdingAllocation' },
  principal: { label: 'customHoldingPrincipal', values: { currencySymbol } },
  grossValue: { label: 'grossValueWithCurrency', values: { currencySymbol } },
  purchaseDate: { label: 'unsettledSettlementDate' },
  maturityDate: { label: 'holdingMaturityDate' },
  nextCouponDate: { label: 'holdingNextCouponDate' },
  coupon: { label: 'holdingCoupon' },
  paid: { label: 'holdingPaid' },
  menu: {},
});

const getOperation = ({ instrumentCode, source }) => {
  if (instrumentCode === 'TD') return '-';

  return source === 'sell-bond-rfq' ? 'Sell' : 'Buy';
};

export const unsettledPresenter = (data, index, actions, options) => {
  const reference = data.reference && `\nReference: ${data.reference}`;
  const title = `Trade ${data.id}\nIssuer ${data.issuerId}${reference || ''}`;
  const rating = getTradeRating(data);
  const showCoupon = data.coupon !== undefined && data.coupon !== null;

  return (
    <tr onDoubleClick={() => actions.showNoteAndReferenceDialog(data)} title={title} key={index}>
      <td>{findLabelForInstrumentCode(data.instrumentCode)}</td>
      <td>{data.issuer.name}</td>
      <td>{getOperation(data)}</td>
      <td>{String(rating).toUpperCase() === 'Z' ? 'Unrated' : rating}</td>
      <td className="allocation-code-column">{data.allocationCode.toUpperCase()}</td>
      <td>
        <Money value={data.sourceRfqFaceValue || data.principal} />
      </td>
      <td>
        <Money value={data.sourceRfqGrossValue} />
      </td>
      <td>
        <DateWithTimeZone value={data.transactionDate} />
      </td>
      <td>{data.maturityDate ? <DateWithTimeZone value={data.maturityDate} /> : '-'}</td>
      <td>{data.nextCouponDate ? <DateWithTimeZone value={data.nextCouponDate} /> : '-'}</td>
      <td>{showCoupon ? <FormattedNumber {...fullCouponFormat} value={data.coupon} /> : '-'}</td>
      <td>
        <FormattedMessage id={`interestDescription.${data.interestPaidCode}`} />
      </td>
      <td className="action-menu">
        {data.documentReferenceKey && (
          <FaFile
            className={classNames({ 'is-downloading': options?.isDownloadingTradeId === data.id })}
            onClick={() => actions.downloadDocument({ id: data.id, documentReferenceKey: data.documentReferenceKey })}
          />
        )}
        <DropdownButton id="actions" className="action-menu" pullRight noCaret bsStyle="link" title={<FaEllipsisV />}>
          <MenuItem onClick={() => actions.showNoteAndReferenceDialog(data)} event="2">
            Update trade note and references
          </MenuItem>

          {isBondOrFrn(data) && (
            <MenuItem onClick={() => actions.viewSellBondRfq(data)} event="2">
              View RFQ
            </MenuItem>
          )}
        </DropdownButton>
      </td>
    </tr>
  );
};
