import { Cookies } from 'react-cookie';

import config from './config';

const cookieName = "glasshouse-token";

const options = {
  path: "/",
  domain: ".imperium.markets",
  secure: false,
  sameSite: "none",
};

if (config.env.nodeEnv === "production") {
  options.secure = true;
  options.sameSite = "lax";
}

class TokenStore {
  constructor() {
    this.data = {};
    this.cookie = new Cookies();
  }

  setToken(value) {
    this.cookie.set(cookieName, value, options);
    if (!this.hasToken()) {
      window.sessionStorage.setItem(cookieName, value);
    }
  }

  getToken() {
    return (
      this.cookie.get(cookieName) || window.sessionStorage.getItem(cookieName)
    );
  }

  hasToken() {
    const token = this.getToken();

    return token && token !== "undefined";
  }

  removeToken() {
    this.cookie.remove(cookieName, options);
    window.sessionStorage.removeItem(cookieName);
  }
}

const instance = new TokenStore();
Object.freeze(instance);

export default instance;
