import './flex.scss';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Layout = ({
  className,
  children,
  alignItemsEnd,
  alignSelfEnd,
  alignSelfStart,
  alignItemsStart,
  alignItemsCenter,
  alignItemsStretch,
  contentStart,
  contentEnd,
  contentCenter,
  contentAround,
  contentEvenly,
  contentBetween,
  type,
  wrap,
  flex,
  style,
  ...rest
}) => {
  const mergedStyle = flex ? { ...style, flex } : style;

  return (
    <div
      style={mergedStyle}
      className={cx(
        'flex',
        type,
        className,
        { 'align-items-start': alignItemsStart },
        { 'align-items-center': alignItemsCenter },
        { 'align-items-end': alignItemsEnd },
        { 'align-self-end': alignSelfEnd },
        { 'align-self-start': alignSelfStart },
        { 'align-items-stretch': alignItemsStretch },
        { 'justify-content-start': contentStart },
        { 'justify-content-end': contentEnd },
        { 'justify-content-center': contentCenter },
        { 'justify-content-around': contentAround },
        { 'justify-content-evenly': contentEvenly },
        { 'justify-content-between': contentBetween },
        { wrap },
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  alignItemsEnd: PropTypes.bool,
  alignSelfEnd: PropTypes.bool,
  alignSelfStart: PropTypes.bool,
  alignItemsStart: PropTypes.bool,
  alignItemsCenter: PropTypes.bool,
  alignItemsStretch: PropTypes.bool,
  contentStart: PropTypes.bool,
  contentEnd: PropTypes.bool,
  contentCenter: PropTypes.bool,
  contentAround: PropTypes.bool,
  contentEvenly: PropTypes.bool,
  contentBetween: PropTypes.bool,
  type: PropTypes.string,
  wrap: PropTypes.bool,
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

export const Row = (props) => <Layout type="flex-row" {...props} />;

export const Column = (props) => <Layout type="flex-column" {...props} />;
