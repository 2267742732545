import { reset as resetForm } from 'redux-form';

import * as holdingApiClient from '../../api/holding';
import * as isinsApiClient from '../../api/isins';
import * as issuersApiClient from '../../api/issuers';
import fetch from '../helper/fetch';
import request from '../helper/request';
import { prepareHoldingParams } from './holding';

export const RESET_SAVE_HOLDING_DETAILS = 'RESET_SAVE_HOLDING_DETAILS';
const SAVE_HOLDING = 'SAVE_HOLDING';
const ISSUERS = 'ISSUERS';
const FETCH_TERM_DEPOSIT_ISSUERS = 'FETCH_TERM_DEPOSIT_ISSUERS';
const ISINS = 'ISINS';
const SELLABLE_HOLDINGS = 'SELLABLE_HOLDINGS';

const addCashRequestAction = request(SAVE_HOLDING, async (data) => ({
  cash: await holdingApiClient.addCash(data),
}));

const addEcdRequestAction = request(SAVE_HOLDING, async (data) => ({
  ecds: await holdingApiClient.addEcd(data),
}));

const addCpRequestAction = request(SAVE_HOLDING, async (data) => ({
  cps: await holdingApiClient.addCp(data),
}));

const saveHoldingRequestAction = request(SAVE_HOLDING, async (data) => ({
  holding: await holdingApiClient.addTermDeposit(data),
}));

const addTermAnnuityRequestAction = request(SAVE_HOLDING, async (termAnnuity) => ({
  termAnnuities: await holdingApiClient.addTermAnnuity(termAnnuity),
}));

const buyBondsRequestAction = request(SAVE_HOLDING, async (data) => ({
  bonds: await holdingApiClient.buyBonds(data),
}));

const buyFrnRequestAction = request(SAVE_HOLDING, async (data) => ({
  frn: await holdingApiClient.buyFrn(data),
}));

const buyFrtdRequestAction = request(SAVE_HOLDING, async (data) => ({
  frn: await holdingApiClient.buyFrtd(data),
}));

const sellBondsRequestAction = request(SAVE_HOLDING, async (data) => ({
  bonds: await holdingApiClient.sellBonds(data),
}));

const sellEcdRequestAction = request(SAVE_HOLDING, async (data) => ({
  ecd: await holdingApiClient.sellEcd(data),
}));

const sellCpRequestAction = request(SAVE_HOLDING, async (data) => ({
  ecd: await holdingApiClient.sellCp(data),
}));

const sellFrnRequestAction = request(SAVE_HOLDING, async (data) => ({
  bonds: await holdingApiClient.sellFrn(data),
}));

const updateCashRequestAction = request(SAVE_HOLDING, async (data) => ({
  bonds: await holdingApiClient.updateCash(data),
}));

const issuersFetchAction = fetch(ISSUERS, shouldFetchIssuers, async () => ({
  issuers: await issuersApiClient.list(),
}));

const termDepositIssuersFetchAction = fetch(
  FETCH_TERM_DEPOSIT_ISSUERS,
  (params, state) => !state.saveHolding.termDepositIssuers,
  async () => ({
    issuers: await issuersApiClient.list({
      instrumentCode: 'TD',
    }),
  }),
);

const isinsFetchAction = fetch(ISINS, shouldFetchIsins, async () => ({
  isins: await isinsApiClient.list(),
}));

const sellableHoldingsFetchAction = fetch(SELLABLE_HOLDINGS, shouldFetchSellables, async (params) => ({
  sellables: await holdingApiClient.list(prepareHoldingParams(params)),
}));

function shouldFetchIssuers(params, state) {
  return !state.saveHolding.issuers;
}

function shouldFetchIsins(params, state) {
  return !state.saveHolding.isins;
}

function shouldFetchSellables(params, state) {
  return !state.saveHolding.sellables;
}

export const saveHoldingFetchPayload = saveHoldingRequestAction.actionsTypes;
export const issuersFetchActionPayload = issuersFetchAction.actionsTypes;
export const termDepositIssuersFetchActionPayload = termDepositIssuersFetchAction.actionsTypes;
export const isinsFetchActionPayload = isinsFetchAction.actionsTypes;
export const sellableHoldingsFetchActionPayload = sellableHoldingsFetchAction.actionsTypes;

export function addTermDeposit(holding) {
  return saveHoldingRequestAction.request(holding);
}

export function addTermAnnuity(holding) {
  return addTermAnnuityRequestAction.request(holding);
}

export function addEcd(holding) {
  return addEcdRequestAction.request(holding);
}

export function addCp(holding) {
  return addCpRequestAction.request(holding);
}

export function buyBonds(bond) {
  return buyBondsRequestAction.request(bond);
}

export function buyFrn(frn) {
  return buyFrnRequestAction.request(frn);
}

export function buyFrtd(frtd) {
  return buyFrtdRequestAction.request(frtd);
}

export function sellBonds(bond) {
  return sellBondsRequestAction.request(bond);
}

export function sellEcd(ecd) {
  return sellEcdRequestAction.request(ecd);
}

export function sellCp(cp) {
  return sellCpRequestAction.request(cp);
}

export function sellFrn(frn) {
  return sellFrnRequestAction.request(frn);
}

export function updateCash(cash) {
  return updateCashRequestAction.request(cash);
}

export function addCash(cash) {
  return addCashRequestAction.request(cash);
}

export function fetchIssuersIfNeeded() {
  return issuersFetchAction.fetchIfNeeded();
}

export function fetchTermDepositIssuersIfNeeded() {
  return termDepositIssuersFetchAction.fetchIfNeeded();
}

export function fetchIsinsIfNeeded() {
  return isinsFetchAction.fetchIfNeeded();
}

export function fetchSellableHoldingsIfNeeded(params) {
  return sellableHoldingsFetchAction.fetchIfNeeded(params);
}

export function reset() {
  return async (dispatch) => {
    dispatch(resetForm('common-form-validation'));

    return dispatch({ type: RESET_SAVE_HOLDING_DETAILS });
  };
}

export const codes = holdingApiClient.trades;
