import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change } from 'redux-form';

import buildForm from '../../common/form/form';
import { loadUpdatableCash } from '../base/formatters';
import fields from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

const formName = "updateCashForm";

function mapStateToProps(state) {
  return {
    updateCashForm: state.form[formName],
    holding: state.saveHolding,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeField: (field, value) => dispatch(change(formName, field, value)),
    },
  };
}

let Form;

export class UpdateCashForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    fields: PropTypes.array,
    holding: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    schema: PropTypes.object,
    updateCashForm: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { intl } = this.props;
    const filter = { field: "instrumentCode", value: "CASH" };
    const holdingCash = fields.find((field) => field.name === "cashId") || {};
    holdingCash.loadOptions = (data) => loadUpdatableCash(data, filter, intl);

    Form = buildForm({
      fields,
      schema,
      initialValues: buildInitialValues(),
      formName,
      destroyOnUnmount: false,
      enableReinitialize: true,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleUpdateCoupon(this.props, nextProps);
  }

  getCouponValue(selectedCash, holding) {
    let selectedTradeCoupon;
    const selectedCashId = selectedCash ? JSON.parse(selectedCash).id : null;
    if (selectedCashId && holding && holding.sellables) {
      const selectedSellable = holding.sellables.find(
        (sellable) => sellable.rootTradeId === selectedCashId
      );
      if (selectedSellable) {
        selectedTradeCoupon = selectedSellable.coupon.toString();
      }
    }
    return selectedTradeCoupon;
  }

  handleUpdateCoupon(
    { updateCashForm: form },
    { updateCashForm: nextForm, holding }
  ) {
    if (
      this.formsHaveValues(form, nextForm) &&
      this.cashHasChanged(form, nextForm)
    ) {
      const selectedCashCoupon = this.getCouponValue(
        nextForm.values.cashId,
        holding
      );
      if (selectedCashCoupon) {
        this.props.actions.changeField("coupon", selectedCashCoupon);
      }
    }
  }

  formsHaveValues(form, nextForm) {
    return form && form.values && nextForm && nextForm.values;
  }

  cashHasChanged(form, nextForm) {
    if (this.cashIdHasValue(form)) {
      const cash = form.values.cashId;
      const nextCash = nextForm.values.cashId;
      return cash !== nextCash;
    }
    return false;
  }

  cashIdHasValue(form) {
    return form.values.cashId && form.values.cashId.value;
  }

  render() {
    return <Form {...this.props} />;
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(UpdateCashForm);
