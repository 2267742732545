import React from 'react';

import { NotFound } from '../error/404-not-found';

export const global = checkRouteType('global');

export default function checkRouteType(toCheck, ErrorComponent = NotFound) {
  const [routeType] = window.location.host.split('.');

  return (ComposedComponent) => (props) =>
    routeType === toCheck ? <ComposedComponent {...props} /> : <ErrorComponent />;
}
