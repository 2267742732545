import './chart-legend.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const validChartTypes = ['bars', 'lines', 'points'];

const ChartLegend = ({ data, direction }) => {
  const itemClass = classNames({
    'pull-left': direction !== 'vertical',
  });

  return (
    <div className="chart-legend">
      {getOrderedItems(getFilteredItems(data)).map((item, key) => (
        <div key={key} className={itemClass}>
          {renderLegendSymbol(item)}
          <span className="legend-label">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

function renderLegendSymbol(item) {
  const color = item.color || getFillColor(item);

  if (!item.legend) {
    return (
      <span className="square limit" style={{ color }}>
        &#9632;
      </span>
    );
  }

  switch (item.legend.symbol) {
    case 'dotted':
      return <span className="dotted limit" style={{ borderColor: color }}></span>;
    case 'square':
    default:
      return (
        <span className="square limit" style={{ color }}>
          &#9632;
        </span>
      );
  }
}

const getFillColor = (item) => item[validChartTypes.find((chartType) => item[chartType])].fillColor;

const getFilteredItems = (legends) =>
  legends.filter((item) => (item.legend && item.legend.show !== undefined ? item.legend.show : true));

const getOrderedItems = (legends) =>
  legends.sort((prev, next) => {
    if (prev.legend && prev.legend.order && !next.legend) {
      return -1;
    }
    if (!prev.legend && next.legend && next.legend.order) {
      return 1;
    }
    if (prev.legend && next.legend) {
      return prev.legend.order > next.legend.order;
    }
    return 0;
  });

ChartLegend.propTypes = {
  data: PropTypes.array.isRequired,
  direction: PropTypes.string,
};

export default ChartLegend;
