import React, { useEffect, useState } from 'react';

import PropTypes, { func } from 'prop-types';
import { Column, Row } from 'react-display-flex';
import useSWR from 'swr';

import {
  buildListOutgoingSecuritiesRfqsUrl,
  listOutgoingSecuritiesRfqs,
} from '../../../../api/holding/rfq/list-outgoing-securities-rfqs';
import rooms from '../../../../api/socket/rooms';
import {
  ErrorComponent,
  ResultsPresenter,
  SelectPeriod,
} from '../../../../components/common';
import includeSocket, {
  socketEvents,
} from '../../../../components/hoc/include-socket';
import { newDate, subtractMonths } from '../../../../date';
import { securitiesOpened, track } from '../../../../event-tracker';
import { BuySellBondRfqsCloseDrawer } from '../BuySellBondRfqsCloseDrawer';
import { columns, presenter } from './records-presenter';

export const OutgoingSecuritiesRfqsComponent = ({ on, off, emit, currency, tenantDomain }) => {
  useEffect(() => {
    track(securitiesOpened);
  }, []);

  useEffect(() => {
    on(socketEvents.rfqUpdated, mutate);

    return () => {
      off(socketEvents.rfqUpdated, mutate);
    };
  }, []);

  const [sortColumn, setSortColumn] = useState('createdAt');
  const [selectedRfq, setSelectedRfq] = useState(null);
  const [sortDirectionDesc, setSortDirectionDesc] = useState(true);

  const [from, setFrom] = useState(subtractMonths(newDate(), 1).toDate());
  const [to, setTo] = useState(undefined);

  const onFilterClick = (range) => {
    setFrom(range.from);
    setTo(range.to);
  };

  const { data, error, mutate, isValidating } = useSWR(
    buildListOutgoingSecuritiesRfqsUrl({ from, to }),
    listOutgoingSecuritiesRfqs,
  );

  if (error) {
    return <ErrorComponent message="sorryThereWasAnError" showFooter={false} />;
  }

  const applySort = (rfqs) => {
    if (!rfqs || !rfqs.length) return rfqs;

    const sorted = rfqs.sort((row1, row2) => (row1[sortColumn] < row2[sortColumn] ? -1 : 1));

    return sortDirectionDesc ? sorted.reverse() : sorted;
  };

  const onSortColumnOrder = ({ sort }) => {
    if (sort === sortColumn) {
      setSortDirectionDesc(!sortDirectionDesc);
    } else {
      setSortColumn(sort);
      setSortDirectionDesc(false);
    }
  };

  const list = data && data.list;

  const onChangeRfqSuccess = () => {
    mutate();

    tenantDomain &&
      emit(socketEvents.rfqUpdated, {
        tenants: [tenantDomain],
        room: rooms.rfq,
      });
  };

  const resultsPresenter = {
    recordsPresenter: {
      presenter,
      columns,
      onSortColumnOrder,
      ariaLabel: 'outgoing securities rfqs table',
      actions: {
        openCloseBondRfqDrawer: setSelectedRfq,
      },
      data: list && applySort(transformRfqsToTableItems(list)),
      highlightedColumn: `${sortDirectionDesc ? '-' : ''}${sortColumn}`,
    },
    noRecords: !list || !list.length,
    isFetching: !data,
  };

  return (
    <Column element="section" className="securities-rfqs" aria-label="outgoing securities rfqs container">
      <Row>
        <SelectPeriod from={from} onChangeFrom onFilterClick={onFilterClick} disabled={isValidating} />
      </Row>
      <ResultsPresenter {...resultsPresenter} />
      <BuySellBondRfqsCloseDrawer
        rfq={selectedRfq}
        onCloseDrawer={() => {
          setSelectedRfq(null);
        }}
        onChangeRfqSuccess={onChangeRfqSuccess}
        currency={currency}
      />
    </Column>
  );
};

export const OutgoingSecuritiesRfqs = includeSocket({ rooms: [rooms.rfq] })(OutgoingSecuritiesRfqsComponent);

const transformRfqsToTableItems = (rfqs) =>
  rfqs.map((rfq) => ({
    ...rfq,
    quotesLength: rfq.quotes.length,
  }));

OutgoingSecuritiesRfqsComponent.propTypes = {
  on: func,
  off: func,
  emit: func,
  currency: PropTypes.string.isRequired,
  tenantDomain: PropTypes.string,
};
