import BaseFields from '../base/fields';

export const fields = (currency) => [
  { ...BaseFields.allocation, halfSize: true },
  { ...BaseFields.purchaseDate, halfSize: true },
  {
    name: 'principal',
    label: 'faceValue',
    componentType: 'currency',
    intlValues: { currency },
  },
  BaseFields.issuer,
  {
    name: 'maturityDate',
    label: 'holdingMaturityDate',
    componentType: 'date',
    disableWeekends: false,
    disableHolidays: false,
  },
  {
    name: 'callableDate',
    label: 'callableDate',
    componentType: 'date',
    disableWeekends: false,
    disableHolidays: false,
  },
  {
    ...BaseFields.coupon,
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
