import './summary-highlight.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Loadable } from './loadable';

const SummaryHighlight = ({ className, top, middle, bottom, isFetching, onClick }) => (
  <div className={classNames('summary-highlight', className)} onClick={onClick}>
    {top && <p className="weight-600 light-primary-color text-ellipsis">{top}</p>}
    {
      <React.Fragment>
        <Loadable isLoading={isFetching}>{middle}</Loadable>
        {bottom && <div className="grey-color">{bottom}</div>}
      </React.Fragment>
    }
  </div>
);

SummaryHighlight.propTypes = {
  className: PropTypes.string,
  top: PropTypes.node,
  middle: PropTypes.node,
  bottom: PropTypes.node,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SummaryHighlight;
