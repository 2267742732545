import axios from 'axios';

export default async function (trade, endpoint, tradeField = 'isin') {
  const { data } = await axios({
    method: 'put',
    url: `/trades/${endpoint}/${trade[tradeField]}`,
    data: trade,
  });

  return data;
}
