import classNames from 'classnames';
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';

export const columns = {
  isCompliant: { label: 'compliant', className: 'is-compliant' },
  term: { label: 'term' },
  rating: { label: 'rating' },
  amountInvested: { label: 'complianceAmountInvested' },
  investmentSharePct: { label: 'complianceInvestmentSharePct' },
  maxLimitPct: { label: 'complianceMaxLimitPct' },
  amountAvailable: { label: 'complianceAmountAvailable' },
};

export const presenter = (rating, index) => {
  const compliantClass = {
    green: rating.isCompliant,
    red: !rating.isCompliant,
  };

  return (
    <tr key={index}>
      <td className={classNames('is-compliant', compliantClass)}>
        <Glyphicon glyph={rating.isCompliant ? 'ok' : 'remove'} />
      </td>
      <td>
        <FormattedMessage
          id={`term.${rating.term}`}
        />
      </td>
      <td>{(String(rating.rating).toUpperCase() === 'Z') ? 'Unrated' : rating.rating}</td>
      <td className={classNames({ red: !rating.isCompliant })}>
        <FormattedNumber
          value={rating.amountInvested}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td className={classNames({ red: !rating.isCompliant })}>
        <FormattedNumber
          value={rating.investmentSharePct}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td>
        <FormattedNumber
          value={rating.maxLimitPct}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
      <td className={classNames({ red: !rating.isCompliant })}>
        <FormattedNumber
          value={rating.amountAvailable}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      </td>
    </tr>
  );
};
