import UpdateCashForm from './form';

export default function updateCashForm(onSave, currencySymbol) {
  return {
    title: 'updateCashOption',
    value: 'updateCash',
    className: 'add-update-cash-form',
    form: UpdateCashForm,
    currencySymbol,
    save: onSave,
  };
}
