import { codes } from '../../../api/holding';

export const findLabelForInstrumentCode = (code) => {
  const filteredCode = codes.instrumentCodes.find((instrumentCode) => instrumentCode.code === code);

  if (filteredCode) {
    return filteredCode.label;
  }

  return code;
};
