import './filter.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { InputSearch, Tooltip } from './';

const Filter = (props) => {
  const { filter, actions } = props;
  const content = [
    <Col key="rightColumn" xs={12}>
      <Row>
        {renderElements(props)}
        {renderSearchText(filter, actions.onSearchChange)}
        {renderCleanFilter(filter, actions.onResetFilter)}
        {renderSummary(props)}
      </Row>
    </Col>,
  ];

  return <Row className="results-filter">{content}</Row>;
};

Filter.propTypes = {
  filter: PropTypes.object,
  elements: PropTypes.func,
  actions: PropTypes.shape({
    size: PropTypes.object,
    onSearchChange: PropTypes.func,
    onResetFilter: PropTypes.func,
  }),
  renderSummary: PropTypes.func,
};

const areAnyFiltersApplied = (filter) =>
  filter && Object.keys(filter).some((key) => key !== "date");

const renderElements = (props) => props.elements && props.elements(props);

const renderSearchText = (filter, onSearchChange) =>
  onSearchChange && (
    <Col xs={2}>
      <InputSearch
        isLoading={false}
        defaultValue={filter && filter.searchText}
        onChange={(event) => onSearchChange(event.target.value)}
        onClear={() => onSearchChange("")}
      />
    </Col>
  );

const renderSummary = (props) =>
  props.renderSummary && props.renderSummary(props);

const renderCleanFilter = (filter, onResetFilter) =>
  onResetFilter && (
    <div className="pull-left">
      <Tooltip id="cleanFilterTooltip">
        <Button
          disabled={!areAnyFiltersApplied(filter)}
          className="btn-default reset-filter-button"
          onClick={onResetFilter}
        >
          <FormattedMessage id="clear" />
        </Button>
      </Tooltip>
    </div>
  );

export default injectIntl(Filter);
