import './checkbox.scss';

import React from 'react';

import PropTypes from 'prop-types';

const Checkbox = ({ name, label, checked, onChange }) => (
  <div className="checkbox">
    <input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />
    <label htmlFor={name}>
      <span className="inner-text">{label}</span>
    </label>
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
