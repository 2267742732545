import React, { useState } from 'react';

import { bool, shape } from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { mutate } from 'swr';

import {
  hasLicences,
  isTenantExecutionOnly,
} from '../../actions/session-selector';
import { buildListOutgoingSecuritiesRfqsUrl } from '../../api/holding/rfq/list-outgoing-securities-rfqs';
import {
  buildBuyBondsCreateRfqInitialValues,
  BuyBondCreateRfq,
} from '../../containers/rfqs/buy-sell-bond-rfqs/buy-bonds-rfqs/create-rfq/BuyBondCreateRfq';
import {
  buildCreateRfqExecutionOnlyInitialValues,
  SellBondCreateRfqExecutionOnly,
} from '../../containers/rfqs/buy-sell-bond-rfqs/execution-only/create-rfq/SellBondCreateRfqExecutionOnly';
import { routes } from '../../routes';
import { Drawer, licences, Tooltip } from '../common';
import { withNavigate } from '../hoc/with-router-properties';

export const NewRfqButtonComponent = ({ hasBuyBondRfqLicence, isExecutionOnly, navigate, user }) => {
  const [buyBondsCreateRfqInitialValues, setBuyBondsCreateRfqInitialValues] = useState();
  const [sellBondsCreateRfqInitialValues, setSellBondsCreateRfqInitialValues] = useState();

  const isMac = navigator.platform.includes('Mac');

  const goToTdCdCpRfq = () => navigate(routes.holdings.newRfq);

  const newRfqTooltip = <span className="shortcut">{isMac ? '⎇⌘R' : 'Ctrl+alt+R'}</span>;

  return hasBuyBondRfqLicence ? (
    <React.Fragment>
      <Drawer
        titleId="buyBondRequestQuotesToBuy"
        className="light"
        open={!!buyBondsCreateRfqInitialValues}
        shouldCloseOnClickOutside={false}
        onClose={() => setBuyBondsCreateRfqInitialValues(null)}
        width="40vw"
      >
        <BuyBondCreateRfq
          initialValues={buildBuyBondsCreateRfqInitialValues()}
          onBuyBondCreateRfqSuccess={() => {
            setBuyBondsCreateRfqInitialValues(null);
            mutate(buildListOutgoingSecuritiesRfqsUrl());
          }}
          currency={user.currency}
        />
      </Drawer>
      <DropdownButton
        id="actions"
        className="btn primary-button"
        pullRight
        bsStyle="link"
        title={<FormattedMessage id="menu.newRfqShortcut" />}
      >
        <Tooltip id="new-rfq" tooltipComponent={newRfqTooltip} placement="left">
          <MenuItem onClick={goToTdCdCpRfq}>TD or CD</MenuItem>
        </Tooltip>
        <MenuItem onClick={() => setBuyBondsCreateRfqInitialValues(buildBuyBondsCreateRfqInitialValues())}>
          Buy Bond or FRN
        </MenuItem>
        {isExecutionOnly && (
          <React.Fragment>
            <MenuItem onClick={() => setSellBondsCreateRfqInitialValues(buildCreateRfqExecutionOnlyInitialValues())}>
              Sell Bond or FRN
            </MenuItem>
            <Drawer
              titleId="requestQuotesToSell"
              className="light"
              open={!!sellBondsCreateRfqInitialValues}
              shouldCloseOnClickOutside={false}
              onClose={() => setSellBondsCreateRfqInitialValues(null)}
              width="40vw"
            >
              <SellBondCreateRfqExecutionOnly
                initialValues={buildCreateRfqExecutionOnlyInitialValues()}
                onCreateRfqSuccess={() => {
                  setSellBondsCreateRfqInitialValues(null);
                  mutate(buildListOutgoingSecuritiesRfqsUrl());
                }}
                currency={user.currency}
              />
            </Drawer>
          </React.Fragment>
        )}
      </DropdownButton>
    </React.Fragment>
  ) : (
    <Tooltip id="new-rfq" tooltipComponent={newRfqTooltip} placement="bottom">
      <button className="btn primary-button" type="button" onClick={goToTdCdCpRfq}>
        <FormattedMessage id="menu.newRfqShortcut" />
      </button>
    </Tooltip>
  );
};

NewRfqButtonComponent.propTypes = {
  user: shape().isRequired,
  hasBuyBondRfqLicence: bool,
  isExecutionOnly: bool,
};

const mapStateToProps = (state) => ({
  user: state.session.user,
  hasBuyBondRfqLicence: hasLicences(licences.buyBondRfq)(state),
  isExecutionOnly: isTenantExecutionOnly(state),
});

export const NewRfqButton = compose(connect(mapStateToProps), withNavigate)(NewRfqButtonComponent);
