import './default-panel.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'react-loaders';

import { isBondOrFrn } from '../../api/holding/instrument-codes';
import { PanelPresenter } from '../../components/common';
import Row from '../../components/dashboard/outgoing/row';
import Table from '../../components/dashboard/outgoing/table';
import Top from '../../components/dashboard/outgoing/top';
import { BuySellBondRfqsCloseDrawer } from '../rfqs/buy-sell-bond-rfqs/BuySellBondRfqsCloseDrawer';

const Panel = ({
  data,
  toggleRfqQuotesDialog,
  initialized,
  currency,
  showSettlementDate,
  onCloseSellBondRfqSuccess,
}) => {
  const outgoingRfqsUrl = (uuid) => `/holdings/quotes/${uuid}`;
  const isRfqClosed = (status) => !['ongoing', 'pending'].includes(status.toLowerCase());

  const [outgoingRfq, setOutgoingRfq] = useState(null);

  const viewRfq = (rfq) => {
    if (isBondOrFrn(rfq.assetClass)) {
      setOutgoingRfq(rfq);

      return;
    }

    if (isRfqClosed(rfq.status)) {
      toggleRfqQuotesDialog(rfq.uuid);

      return;
    }
    window.location = outgoingRfqsUrl(rfq.uuid);
  };

  const rows = data.instruments.length
    ? data.instruments.map((rfq) => (
        <Row
          assetClass={rfq.assetClass.toUpperCase()}
          key={rfq.uuid}
          status={rfq.status}
          cancelOption={rfq.cancelOption}
          currency={currency}
          principal={rfq.principal}
          quotesReceived={rfq.quotesReceived}
          quotesSent={rfq.quotesSent}
          issuer={rfq.issuer}
          confirmed={rfq.confirmed}
          settlementDate={rfq.settlementDate}
          showSettlementDate={showSettlementDate}
          operation={rfq.operation}
          actions={{
            view: () => viewRfq(rfq),
          }}
        />
      ))
    : [];

  return (
    <PanelPresenter className="panel-container">
      <Top summary={data.summary} />
      {initialized ? renderData(rows, showSettlementDate) : renderLoader()}
      <BuySellBondRfqsCloseDrawer
        rfq={outgoingRfq}
        onChangeRfqSuccess={onCloseSellBondRfqSuccess}
        onCloseDrawer={() => {
          setOutgoingRfq(null);
        }}
        currency={currency}
      />
    </PanelPresenter>
  );
};

const renderLoader = () => <Loader size="sm" type="line-scale-pulse-out" />;

const renderData = (rows, showSettlementDate) => {
  const data = rows.length ? (
    <Table rows={rows} showSettlementDate={showSettlementDate} />
  ) : (
    <div className="no-data">
      <FormattedMessage id="rfqNoRecords" />
    </div>
  );

  return data;
};

Panel.propTypes = {
  data: PropTypes.shape(),
  initialized: PropTypes.bool.isRequired,
  toggleRfqQuotesDialog: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  showSettlementDate: PropTypes.bool.isRequired,
  onCloseSellBondRfqSuccess: PropTypes.func,
};
export default Panel;
