import {
  FETCH_RATESHEETS_REQUEST,
  FETCH_RATESHEETS_SUCCESS,
  FETCH_RATESHEETS_FAILURE,
  UPDATE_RATESHEETS_REQUEST,
  UPDATE_RATESHEETS_SUCCESS,
  UPDATE_RATESHEETS_FAILURE,
  RESET_UPDATE_DATA,
} from '../actions/ratesheets';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  data: null,
  error: null,
  isUpdating: false,
  updateError: false,
  updateData: null,
};

const initialRatesState = {
  '1M': 0,
  '2M': 0,
  '3M': 0,
  '4M': 0,
  '5M': 0,
  '6M': 0,
  '7M': 0,
  '8M': 0,
  '9M': 0,
  '10M': 0,
  '11M': 0,
  '12M': 0,
  '2Y': 0,
  '3Y': 0,
  '4Y': 0,
  '5Y': 0,
};

const ratesheetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RATESHEETS_REQUEST:
      return { ...state, isFetching: true, didInvalidate: false };
    case FETCH_RATESHEETS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: buildRatesheetPayload(action.payload),
      };
    case FETCH_RATESHEETS_FAILURE:
      return { ...state, isFetching: false, didInvalidate: true, error: action.error };
    case UPDATE_RATESHEETS_REQUEST:
      return { ...state, isUpdating: true, updateError: false };
    case UPDATE_RATESHEETS_SUCCESS:
      return { ...state, isUpdating: false, updateError: false, updateData: action.result };
    case UPDATE_RATESHEETS_FAILURE:
      return { ...state, isUpdating: false, updateError: true, updateData: action.error };
    case RESET_UPDATE_DATA:
      return { ...state, isUpdating: false, updateError: false, updateData: null };
    default:
      return state;
  }
};

export default ratesheetReducer;

const defaultSegmentCodes = ['CORP', 'FUND', 'BANK', 'LOCALGOV'];
const instrumentCodesWithRatesheet = ['TD', 'CD', 'CP'];

const buildRatesheetPayload = (ratesheets) => {
  const segmentCodes = Array.from(new Set([...defaultSegmentCodes, ...(ratesheets.segmentCodes || [])].sort()));
  const instruments = buildRatesheetsInstruments(ratesheets, segmentCodes);

  return {
    ...ratesheets,
    instruments,
  };
};

const buildRatesheetsInstruments = (ratesheets, segmentCodes) =>
  instrumentCodesWithRatesheet.reduce((instruments, instrumentCode) => {
    const ratesheetByInstrument = ratesheets.list.find((ratesheet) => ratesheet.instrumentCode === instrumentCode);
    const ratesheet = ratesheetByInstrument ? ratesheetByInstrument.ratesheet.map(humanizeRates) : [];

    instruments[instrumentCode] = addMissingSegmentsToUpdate({ ratesheet, segmentCodes });

    return instruments;
  }, {});

const addMissingSegmentsToUpdate = ({ ratesheet, segmentCodes }) =>
  segmentCodes.reduce((ratesheets, segmentCode) => {
    const segmentCodeRatesheet = ratesheet.find((rate) => rate.segmentCode === segmentCode);

    ratesheets.push(
      segmentCodeRatesheet ? segmentCodeRatesheet : { segmentCode, available: 'No', rates: initialRatesState },
    );

    return ratesheets;
  }, []);

const humanizeRates = (ratesheet) => ({
  ...ratesheet,
  available: ratesheet.available ? 'Yes' : 'No',
  rates: {
    ...initialRatesState,
    ...ratesheet.rates,
  },
});
