import axios from 'axios';

export async function update({ id, data: updateData }) {
  const { data } = await axios({
    method: 'put',
    url: `/offers/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: updateData,
  });

  return data;
}
