import { format } from 'd3-format';

import { buildValuesUnderLimit, buildValuesAboveLimit, buildLimits } from '../common/compliances/chart-payload';
import pallete from '../../theme/helpers/pallete.json';

const formater = format(',.2f');

export function series(data, intl) {
  return [
    {
      label: intl.formatMessage({ id: 'limits' }),
      data: buildLimits(data, 'maxLimitPct'),
      bars: { fillColor: pallete['mid-grey'], barWidth: 0.6 },
    },
    {
      label: intl.formatMessage({ id: 'compliant' }),
      data: buildValuesUnderLimit(data, 'maxLimitPct'),
      bars: { fillColor: pallete['light-orange'], barWidth: 0.4 },
    },
    {
      label: intl.formatMessage({ id: 'nonCompliant' }),
      data: buildValuesAboveLimit(data, 'maxLimitPct'),
      bars: { fillColor: pallete.error, barWidth: 0.4 },
    },
  ];
}

export function xTicks(data) {
  return data.map((rating, index) => [index, rating.rating.toLowerCase() === 'z' ? 'Unrated' : rating.rating]);
}

export function tooltips(moneySymbol) {
  return (data) =>
    data.map(
      (item) =>
        `
      <b>Rating: ${item.rating === 'Z' ? 'Unrated' : item.rating}</b><br/>
      <b>Amount invested:</b> ${moneySymbol}${formater(item.amountInvested)}<br/>
      <b>Max limit:</b> ${item.maxLimitPct}%<br/>
      <b>Amount available:</b> ${moneySymbol}${formater(item.amountAvailable)}
    `,
    );
}
