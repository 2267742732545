import { licences, roles } from '../../components/common/Protect';
import { routes } from '../../routes';

function sanitizeRoute(route) {
  const paramsUrlRegex = new RegExp(/\(\/:\w+\)/, 'g');

  return route.replace(paramsUrlRegex, '');
}

const startsWith = (locationPath, matchPath) => locationPath.startsWith(matchPath);

export function getMenuItems(rootPath) {
  const isExecutionOnly = rootPath === routes.portfolioRoot.rfqs;
  const menuItems = [
    {
      key: 'dashboard',
      eventKey: routes.root,
      isActive: (currentPath) => {
        const isDashboardRoot = rootPath === sanitizeRoute(routes.dashboard) && currentPath === routes.root;
        const startsWithDashboard = startsWith(currentPath, sanitizeRoute(routes.dashboard));

        return isDashboardRoot || startsWithDashboard;
      },
      label: 'menu.dashboard',
      requiredTo: roles.finance,
      licence: licences.dashboard,
      bankOnly: true,
    },
    {
      key: 'portfolio',
      eventKey: rootPath === routes.root ? routes.root : sanitizeRoute(routes.portfolio),
      isActive: (currentPath) => {
        const isPortfolioRoot = rootPath === routes.root && currentPath === routes.root;
        const startsWithPortfolio = startsWith(currentPath, sanitizeRoute(routes.portfolio));

        return isPortfolioRoot || startsWithPortfolio;
      },
      label: 'menu.portfolio',
      licence: licences.portfolio,
      requiredTo: roles.viewer,
    },
    {
      key: 'quotes',
      eventKey: sanitizeRoute(routes.holdings.quotes),
      isActive: (currentPath) => startsWith(currentPath, sanitizeRoute(routes.holdings.quotes)),
      label: 'menu.quotes',
      requiredTo: roles.finance,
    },
    {
      key: 'update-ratesheet',
      eventKey: routes.ratesheets.update,
      label: 'menu.update-ratesheet',
      requiredTo: roles.superUser,
      bankOnly: true,
    },
    {
      key: 'incoming-rfqs',
      eventKey: routes.incoming.base,
      isActive: (currentPath) => startsWith(currentPath, routes.incoming.base),
      label: 'menu.incoming-rfqs',
      requiredTo: roles.finance,
      bankOnly: true,
    },
    {
      key: 'onboarding',
      eventKey: routes.onboarding.base,
      isActive: (currentPath) => startsWith(currentPath, routes.onboarding.base),
      label: 'menu.onboarding',
      requiredTo: roles.admin,
      bankOnly: true,
    },
    {
      key: 'unsolicited-ratesheets',
      eventKey: sanitizeRoute(routes.unsolicitedRatesheets.investor.base),
      isActive: (currentPath) => startsWith(currentPath, sanitizeRoute(routes.unsolicitedRatesheets.investor.base)),
      label: 'menu.unsolicitedRatesheets',
      licence: licences.unsolicitedRatesheet,
      requiredTo: roles.finance,
      investorOnly: true,
    },
  ];

  if (isExecutionOnly) {
    menuItems.unshift({
      key: 'home',
      eventKey: routes.root,
      isActive: (currentPath) => currentPath === routes.root,
      label: 'menu.home',
    });
  }

  return [
    {
      name: 'Menu',
      items: menuItems,
    },
    {
      name: 'Admin',
      requiredTo: roles.business,
      items: [
        {
          key: 'admin-fees',
          eventKey: routes.admin.fees,
          label: 'menu.admin-fees',
          requiredTo: roles.business,
        },
        {
          key: 'admin-rfqs',
          eventKey: routes.admin.rfqs,
          label: 'menu.admin-rfqs',
          requiredTo: roles.business,
        },
        {
          key: 'admin-maturing-trades',
          eventKey: routes.admin.maturingTrades,
          label: 'menu.admin-maturing-trades',
          requiredTo: roles.business,
        },
        {
          key: 'admin-maturing-email',
          eventKey: routes.admin.maturingEmail,
          label: 'menu.admin-maturing-email',
          requiredTo: roles.business,
        },
        {
          key: 'admin-portfolio-performance',
          eventKey: routes.admin.portfolioPerformance,
          label: 'menu.admin-portfolio-performance',
          requiredTo: roles.business,
        },
        {
          key: 'admin-update-password',
          eventKey: routes.admin.updatePassword,
          label: 'menu.admin-update-password',
          requiredTo: roles.superUser,
        },
      ],
    },
  ];
}

export const getCapitalValueMenuItem = () => ({
  key: 'capital-value',
  eventKey: routes.capitalValue,
  label: 'menu.capitalValue',
  requiredTo: roles.admin,
});
