import './tabs.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'react-loaders';

import { CleanNavItem, hasAccess } from './';
import { Loadable, types } from './loadable';

const Tabs = ({ activeKey, onSelect, items, isLoading }) => (
  <div className="slide-tabs">
    <Nav bsStyle="tabs" activeKey={activeKey}>
      {renderItems(isLoading, items, onSelect)}
      <CleanNavItem className="slide" />
    </Nav>
  </div>
);

const renderItems = (isLoading, items, onSelect) =>
  shouldShowLoading(isLoading, items) ? (
    <NavItem eventKey="loading" data-toggle="tab">
      <Loader size="sm" type="ball-scale-ripple" />
    </NavItem>
  ) : (
    items.map((item) => renderItem(item, onSelect))
  );

const shouldShowLoading = (isLoading, items) => isLoading || !items.length;

Tabs.propTypes = {
  activeKey: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node,
      isCleanItem: PropTypes.bool,
      title: PropTypes.string,
      icon: PropTypes.string,
      isLoading: PropTypes.bool,
      hasError: PropTypes.bool,
    }),
  ),
  onSelect: PropTypes.func,
};

Tabs.defaultProps = {
  items: [],
};

const renderItemState = (icon, didInvalidate) =>
  didInvalidate ? (
    <div>
      <i className="icon fa fa-exclamation-triangle" />
    </div>
  ) : (
    <div className={`icon ${icon} neutral`} />
  );

const renderItem = (
  // eslint-disable-next-line react/prop-types
  { key, icon, component, isCleanItem, isLoading, hasError, title, user, requiredTo, licence, ...props },
  onSelect,
) => {
  const TabItem = isCleanItem ? CleanNavItem : NavItem;

  const tabContent = (
    <React.Fragment>
      <Loadable isLoading={isLoading} type={types.ballScaleRipple} size="sm">
        {renderItemState(icon || key, hasError)}
      </Loadable>
      <div className="item-container white-color">{component || <FormattedMessage tagName="span" id={title} />}</div>
    </React.Fragment>
  );

  const needsProtection = user && (requiredTo || licence);
  if (needsProtection && !hasAccess(user, requiredTo, null, licence)) return null;

  return (
    <TabItem key={key} eventKey={key} data-toggle="tab" onSelect={onSelect} data-tour={`${key}-tab`} {...props}>
      {tabContent}
    </TabItem>
  );
};

export default Tabs;
