import './detail.scss';

import React, { Component } from 'react';

import debounce from 'debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as counterpartyActions from '../../actions/compliances/counterparty';
import * as sessionActions from '../../actions/session';
import { ChartsPresenter, ResultsPresenter } from '../../components/common';
import * as chartsPresenter from '../../components/counterparty/chart-payload';
import * as filterPresenter from '../../components/counterparty/filter-presenter';
import * as recordsPresenter from '../../components/counterparty/records-presenter';
import errorHandler from '../../components/hoc/error-handler';
import { withSearchParams } from '../../components/hoc/with-router-properties';
import { paginateItems } from '../../reducers/common/pagination';
import { getMoneySymbol } from '../money';

function mapStateToProps(state) {
  return {
    counterparty: state.counterparty,
    credit: state.credit,
    maturity: state.maturity,
    currency: state.session.user.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      counterparty: bindActionCreators(counterpartyActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
    },
  };
}

export class Counterparty extends Component {
  constructor(props) {
    super(props);
    this.onSearchChangeDebounced = debounce(this.onSearchChange, 500);
  }

  componentDidMount() {
    this.updateFilterByLocationOnMount();
    this.fetchCounterpartyIfNeeded(this.props.counterparty.filter || this.props.searchParamsEntries);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateFilteredData(nextProps);
    this.updateLocationQuery(nextProps);
  }

  onDropdownFilterChange = (type, value) => {
    this.props.actions.counterparty.updateFilter({ [type]: value });
  };

  onSearchChange = (searchText) => {
    this.props.actions.counterparty.updateFilter({ searchText });
  };

  onResetFilter = () => {
    this.props.actions.counterparty.resetFilter();
  };

  fetchCounterpartyIfNeeded(params) {
    this.props.actions.counterparty.fetchCounterpartyIfNeeded(params);
  }

  updateLocationQuery(nextProps) {
    if (!this.shouldUpdateLocationQuery(this.props, nextProps)) {
      return;
    }

    this.props.actions.session.updateLocationQuery(nextProps.counterparty.filter);
  }

  updateFilteredData(nextProps) {
    if (
      !nextProps.counterparty.counterparty ||
      nextProps.counterparty.isFetching ||
      nextProps.counterparty.filteredData
    ) {
      return;
    }

    this.props.actions.counterparty.updateFilteredData(nextProps.counterparty);
  }

  filterIssuers = (counterparty, term) => {
    return (
      (counterparty &&
        counterparty.issuers &&
        counterparty.issuers.filter((issuer) => issuer.amountInvested > 0 && issuer.term === term)) ||
      []
    );
  };

  shouldUpdateLocationQuery = ({ counterparty: { filter } }, { counterparty: { filter: nextPropsFilter } }) => {
    return nextPropsFilter && !isEqual(filter, nextPropsFilter);
  };

  updateFilterByLocationOnMount() {
    if (!Object.keys(this.props.searchParamsEntries).length) {
      return;
    }

    this.props.actions.counterparty.updateFilter(this.props.searchParamsEntries);
    this.props.actions.session.updateLocationQuery(this.props.searchParamsEntries);
  }

  renderRecords(counterpartyState) {
    const { counterparty, isFetching, filter, availableFilters, filteredData, pagination } = counterpartyState;

    const noRecords = !counterparty || !(filteredData || counterparty.issuers).length;
    const data = filteredData || (counterparty && counterparty.issuers);

    const payload = {
      isFetching,
      noRecords,
      recordsPresenter: {
        data: counterparty && paginateItems(data, pagination),
        ...recordsPresenter,
      },
      pagination: {
        pagination,
      },
      filter: {
        filter,
        isFetching,
        availableFilters,
        onDropdownFilterChange: this.onDropdownFilterChange,
        actions: {
          size: { xs: 2 },
          onSearchChange: this.onSearchChangeDebounced,
          onResetFilter: this.onResetFilter,
        },
        summary: {
          count: data && new Set(data.map((item) => item.code)).size,
          sum: data && data.reduce((sum, item) => sum + item.amountInvested, 0),
        },
        ...filterPresenter,
      },
      titleId: 'counterparty.resultsTitle',
    };

    return <ResultsPresenter {...payload} />;
  }

  renderCharts({ counterparty, isFetching }, currency) {
    const longTermOnly = counterparty && counterparty.longTermComplianceOnly;
    const grandTotal = counterparty && counterparty.amountInvested;
    const charts = [];

    const { series, xTicks, tooltips } = chartsPresenter;
    const moneySymbol = getMoneySymbol({ currency, short: true });

    if (!longTermOnly) {
      charts.push({
        id: 'counterparty-short',
        titleId: 'counterparty.shortHoldings',
        isFetching,
        data: this.filterIssuers(counterparty, 'short').map((item) => ({
          ...item,
          grandTotal,
        })),
        type: 'bar',
        series,
        xTicks,
        tooltips: tooltips(moneySymbol),
      });
    }
    charts.push({
      id: 'counterparty-long',
      titleId: 'counterparty.longHoldings',
      isFetching,
      data: this.filterIssuers(counterparty, 'long').map((item) => ({
        ...item,
        grandTotal,
      })),
      type: 'bar',
      series,
      xTicks,
      tooltips: tooltips(moneySymbol),
    });

    const payload = {
      charts,
      options: {
        linkedOptions: true,
      },
    };

    return <ChartsPresenter {...payload} />;
  }

  render() {
    const { counterparty, currency } = this.props;

    return (
      <div className="counterparty-list">
        <Col>
          {this.renderCharts(counterparty, currency)}
          {this.renderRecords(counterparty)}
        </Col>
      </div>
    );
  }
}

Counterparty.propTypes = {
  actions: PropTypes.shape().isRequired,
  counterparty: PropTypes.shape().isRequired,

  currency: PropTypes.string.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.counterparty.error,
  })),
  withSearchParams,
)(Counterparty);
