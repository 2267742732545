import { fetchIncoming, fetchOutgoing, fetchAdiTrades, fetchAnonymisedQuotes } from '../../api/dashboard';

export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
export const FETCH_ANONYMISED_REQUEST = 'FETCH_ANONYMISED_REQUEST';
export const FETCH_ANONYMISED_SUCCESS = 'FETCH_ANONYMISED_SUCCESS';
export const FETCH_ANONYMISED_FAILURE = 'FETCH_ANONYMISED_FAILURE';

export function fetchDashboard() {
  return async (dispatch) => {
    dispatch({ type: FETCH_DASHBOARD_REQUEST });
    try {
      const incomingRfqs = await fetchIncoming();
      const outgoingRfqs = await fetchOutgoing();
      const adiTrades = (await fetchAdiTrades()).list;

      dispatch({ type: FETCH_DASHBOARD_SUCCESS, payload: { incomingRfqs, outgoingRfqs, adiTrades } });
    } catch (error) {
      dispatch({ type: FETCH_DASHBOARD_FAILURE, error: error.response || error });
    }
  };
}

export function fetchAnonymisedRfqQuotes(uuid) {
  return async (dispatch) => {
    dispatch({ type: FETCH_ANONYMISED_REQUEST });
    try {
      const anonymisedRfqQuotes = await fetchAnonymisedQuotes(uuid);

      dispatch({ type: FETCH_ANONYMISED_SUCCESS, payload: { anonymisedRfqQuotes } });
    } catch (error) {
      dispatch({ type: FETCH_ANONYMISED_FAILURE, error: error.response || error });
    }
  };
}
