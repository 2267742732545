import './send-ratesheet-dialog.scss';

import React, { useEffect, useState } from 'react';

import clone from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import { Column } from 'react-display-flex';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { ConfirmDialog, ErrorComponent } from '../../../../../../components/common';
import { newDate } from '../../../../../../date';
import { Tenors } from '../../../../../Tenors/Tenors';

const dialogProperties = {
  titleId: 'sendUnsolicitedRatesheetTitle',
  confirmId: 'send',
  cancelId: 'cancel',
  hasCancel: true,
};

const renderRatesheet = ({ ratesheet, onTenorItemChange }) =>
  ratesheet && ratesheet.available ? (
    <Tenors disabled={!onTenorItemChange} ratesheet={ratesheet} onTenorItemChange={onTenorItemChange} />
  ) : (
    <ErrorComponent
      info="bankNoRatesheetAvailableInfo"
      infoValues={{ segment: ratesheet.segmentCode }}
      message="noRatesheetAvailable"
      showFooter={false}
    />
  );

const onCustomRatesheetTenorChange = ({ originalRatesheet, customRatesheet, tenor, rate, setCustomRatesheet }) => {
  const nextRatesheet = clone(customRatesheet);

  const originalRate = originalRatesheet.rates.find((oldRate) => oldRate.tenor === tenor).rate;
  const nextRatesheetUpdatedIndex = customRatesheet.rates.findIndex((newRate) => newRate.tenor === tenor);

  const changedRate = {
    tenor,
    rate,
    isCustom: originalRate !== rate,
    originalRate,
  };

  nextRatesheet.rates[nextRatesheetUpdatedIndex] = changedRate;
  nextRatesheet.updatedAt = newDate().toDate();

  setCustomRatesheet(nextRatesheet);
};

export const SendRatesheetDialog = ({ show, isConfirming, ratesheet, onConfirm, onCancel }) => {
  const [customRatesheet, setCustomRatesheet] = useState(ratesheet);

  useEffect(() => {
    setCustomRatesheet(ratesheet);
  }, [ratesheet]);

  const onTenorItemChange = ({ tenor, rate }) =>
    onCustomRatesheetTenorChange({
      originalRatesheet: ratesheet,
      customRatesheet,
      tenor,
      rate,
      setCustomRatesheet,
    });

  return (
    <ConfirmDialog
      {...dialogProperties}
      dataTestId="send-bespoke-ratesheet-dialog"
      confirmationMessageId="sendBespokeUnsolicitedRatesheet"
      disabled={ratesheet.isFetching || !ratesheet.available}
      onCancel={onCancel}
      onHide={onCancel}
      onConfirm={() => onConfirm(customRatesheet)}
      show={show}
      isConfirming={isConfirming}
      modalClassName="send-unsolicited-ratesheet-dialog bank-dashboard-dialog"
      content={
        <Column>
          <FormattedMessage
            tagName="p"
            id="sendRatesheetLastUpdatedAt"
            values={{
              value: (
                <FormattedDate
                  value={ratesheet.updatedAt}
                  day="numeric"
                  month="numeric"
                  year="numeric"
                  hour="numeric"
                  minute="numeric"
                />
              ),
            }}
          />
          {renderRatesheet({
            ratesheet: customRatesheet,
            onTenorItemChange,
          })}
        </Column>
      }
    />
  );
};

SendRatesheetDialog.propTypes = {
  isCreatingUnsolicitedRatesheet: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  ratesheet: PropTypes.shape({
    available: PropTypes.bool,
    isFetching: PropTypes.bool,
    updatedAt: PropTypes.string,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
  show: PropTypes.bool,
};

SendRatesheetDialog.defaultProps = {
  show: false,
  isCreatingUnsolicitedRatesheet: false,
};
