import * as holdingApiClient from '../../../api/holding';
import * as rfqApiClient from '../../../api/holding/rfq';
import * as issuersApiClient from '../../../api/issuers';
import * as ratesheetsApiClient from '../../../api/ratesheets';
import rooms from '../../../api/socket/rooms';
import { me } from '../../../api/user';
import buildFetch from '../../helper/fetch';
import { buildFilter } from '../../helper/filter';
import request from '../../helper/request';

const RFQ_ISSUERS = 'RFQ_ISSUERS';
const RFQ_RATESHEETS = 'RFQ_RATESHEETS';
const RFQ_HOLDING_DETAILS = 'RFQ_HOLDING_DETAILS';
const RFQ_REINVEST = 'RFQ_REINVEST';
const UPDATE_SOCKET_RFQ = 'UPDATE_SOCKET_RFQ';
const RFQ_CANCEL = 'RFQ_CANCEL';
const USER_ME = 'USER_ME';
export const DEFAULT_SORT_COLUMN = 'shortName';
export const RESET_RFQ_SAVING_STATE = 'RESET_RFQ_SAVING_STATE';
export const SET_OFFER_DETAILS = 'SET_OFFER_DETAILS';
export const SET_RFQ_INITIAL_VALUES = 'SET_RFQ_INITIAL_VALUES';
export const RESET_RFQ = 'RESET_RFQ';
export const UPDATE_RFQ_ISSUER_SORT = 'UPDATE_RFQ_ISSUER_SORT';

export const tiers = {
  frequent: 'frequent',
  1: 'rfqMajorRated',
  2: 'rfqARated',
  3: 'rfqBRated',
  4: 'rfqUnrated',
  5: 'rfqDemoIssuers',
  ratesheetsTab: 'ratesheetsTab',
};

const rfqDetailsFetchAction = buildFetch(RFQ_HOLDING_DETAILS, shouldFetchHoldingDetails, fetchHoldingDetails);

const issuersFetchAction = buildFetch(RFQ_ISSUERS, shouldFetchIssuers, async (params) => ({
  issuers: await issuersApiClient.rfq(params),
}));

const ratesheetsFetchAction = buildFetch(RFQ_RATESHEETS, shouldFetchRatesheets, async () => ({
  ratesheets: await ratesheetsApiClient.list(),
}));

const meFetchAction = buildFetch(USER_ME, shouldFetchMe, async () => ({
  me: await me(),
}));

const reinvestRequestAction = request(RFQ_REINVEST, async (data, tenants) => ({
  cash: await holdingApiClient.reinvest(data),
  socket: {
    event: 'client:rfq-updated',
    data: {
      tenants,
      room: rooms.rfq,
    },
  },
}));

const updateSocketRfqAction = request(UPDATE_SOCKET_RFQ, async (tenants) => ({
  socket: {
    event: 'client:rfq-updated',
    data: {
      tenants,
      room: rooms.rfq,
    },
  },
}));

const cancelRfqAction = request(
  RFQ_CANCEL,
  async (uuid, cancelOption) => await rfqApiClient.cancel(uuid, cancelOption),
);

export const rfqDetailsFetchPayload = rfqDetailsFetchAction.actionsTypes;
export const issuersFetchActionPayload = issuersFetchAction.actionsTypes;
export const ratesheetsFetchActionPayload = ratesheetsFetchAction.actionsTypes;
export const meFetchActionPayload = meFetchAction.actionsTypes;
export const reinvestRequestActionPayload = reinvestRequestAction.actionsTypes;
export const updateSocketRfqPayload = updateSocketRfqAction.actionsTypes;
export const cancelRfqActionPayload = cancelRfqAction.actionsTypes;

async function fetchHoldingDetails(params) {
  return {
    details: await holdingApiClient.details(params.id, params),
  };
}

export function fetchIfNeeded(params) {
  return rfqDetailsFetchAction.fetchIfNeeded(params);
}

export function fetchIssuersIfNeeded(params) {
  return issuersFetchAction.fetchIfNeeded(params);
}

export function fetchRatesheetsIfNeeded(params) {
  return ratesheetsFetchAction.fetchIfNeeded(params);
}

export function fetchMeIfNeeded(params) {
  return meFetchAction.fetchIfNeeded(params);
}

function shouldFetchHoldingDetails(params = {}, state) {
  if (state.rfq.isFetching || state.rfq.didInvalidate) {
    return false;
  }

  if (state.rfq.refresh) {
    return true;
  }

  const { rfq: rfqDetails } = state.rfq;

  return !rfqDetails || (params.id && rfqDetails.id !== params.id);
}

function shouldFetchRatesheets(params = {}, { rfq }) {
  if ((params && params.force) || rfq.refresh) {
    return true;
  }

  if (rfq.isFetching || rfq.didInvalidate) {
    return false;
  }

  return !Object.keys(rfq.ratesheets).length;
}

function shouldFetchMe(params = {}, { rfq }) {
  if (params && params.force) {
    return true;
  }

  return !rfq.me;
}

function shouldFetchIssuers(params, { rfq }) {
  if (params && params.force && !rfq.issuers) {
    return true;
  }

  if (!rfq.holding || (rfq.issuers && rfq.issuers.isFetching)) {
    return false;
  }

  return !rfq.issuers;
}

export function fetch(params) {
  return rfqDetailsFetchAction.fetch({
    ...params,
  });
}

export function reinvest(rfq, tenants) {
  return reinvestRequestAction.request(rfq, tenants);
}

export function updateSocketRfq(tenants) {
  return updateSocketRfqAction.request(tenants);
}

export function cancel(uuid, cancelOption) {
  return cancelRfqAction.request(uuid, cancelOption);
}

export function reset() {
  return {
    type: RESET_RFQ,
  };
}

export function resetSavingState() {
  return {
    type: RESET_RFQ_SAVING_STATE,
  };
}

export function updateSortColumn(filter) {
  return async (dispatch, getState) => {
    const actualState = getState();
    const {
      rfq: { filter: stateFilter },
    } = actualState;

    if (shouldChangeSortOrder(stateFilter, filter)) {
      filter.sort = `-${filter.sort}`; // eslint-disable-line no-param-reassign
    }

    return updateFilter(filter, dispatch, actualState);
  };
}

const shouldChangeSortOrder = (currentFilter = {}, newFilter) =>
  newFilter && (!currentFilter.sort || currentFilter.sort === newFilter.sort);

export function updateFilter(filter, dispatch, state) {
  const newFilter = buildFilter(filter, state.rfq.filter);

  dispatch({ type: UPDATE_RFQ_ISSUER_SORT, newFilter });
}

export function setOfferDetails({ offerDetails }) {
  return {
    offerDetails,
    type: SET_OFFER_DETAILS,
  };
}

export const setRFQInitialValues = ({ initialValues }) => ({
  initialValues,
  type: SET_RFQ_INITIAL_VALUES,
});
