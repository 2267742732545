export const sellBondRfqStatuses = {
  open: 'open',
  closed: 'closed',
  won: 'won',
  lost: 'lost',
  cancelled: 'cancelled',
  expired: 'expired',
  waitingConfirmation: 'waiting-confirmation',
  pending: 'pending',
  ongoing: 'ongoing',
};

export const getSellerBuySellBondRfqStatus = (rfq) => {
  if (rfq.rfqStatus === sellBondRfqStatuses.open) {
    return rfq.quotes.length === 0 ? sellBondRfqStatuses.pending : sellBondRfqStatuses.ongoing;
  }

  if (rfq.rfqStatus === sellBondRfqStatuses.closed) {
    return rfq.confirmedAt ? sellBondRfqStatuses.closed : sellBondRfqStatuses.waitingConfirmation;
  }

  return sellBondRfqStatuses[rfq.rfqStatus];
};

export const getBuyerBuySellBondRfqStatus = (rfq) => {
  if (rfq.canConfirmRfq) return sellBondRfqStatuses.waitingConfirmation;

  if (rfq.rfqStatus === sellBondRfqStatuses.open) {
    return rfq.quotes.length === 0 ? sellBondRfqStatuses.pending : sellBondRfqStatuses.ongoing;
  }

  if (rfq.rfqStatus === sellBondRfqStatuses.closed) {
    const adiQuote = rfq.quotes.find(({ isOwnAdi }) => isOwnAdi);

    if (!adiQuote) {
      return sellBondRfqStatuses.lost;
    }

    return adiQuote.quoteStatus === 'accepted' ? sellBondRfqStatuses.won : sellBondRfqStatuses.lost;
  }

  return sellBondRfqStatuses[rfq.rfqStatus];
};
