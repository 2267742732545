import './blotter-rfqs-tab.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Row as FlexibleRow } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as rfqsHistoryActions from '../../../actions/reports/incoming-rfqs/rfqs-history';
import { filteredRfqsSelector } from '../../../actions/reports/incoming-rfqs/rfqs-history-selectors';
import { isGlobalAdmin as isGlobalAdminSelector } from '../../../actions/session-selector';
import { unsolicitedRatesheetSources } from '../../../api/holding/codes';
import rooms from '../../../api/socket/rooms';
import { Range, SummaryItem } from '../../../components/common';
import { renderSelect } from '../../../components/common/dropdown-filter';
import { licences as availableLicences } from '../../../components/common/Protect';
import { getImperiumSelectValue } from '../../../components/common/Select/Select';
import IncomingQuotesDialog from '../../../components/dashboard/incoming-quotes-dialog';
import { UnsolicitedRatesheetsSnapshot } from '../../../components/dashboard/unsolicited-ratesheets-snapshot/UnsolicitedRatesheetsSnapshot';
import includeSocket, { socketEvents } from '../../../components/hoc/include-socket';
import Row from '../../../components/reports/rfqs-history/incoming/row';
import Table from '../../../components/reports/rfqs-history/incoming/table';
import { formatToShortDateWithoutTz, today } from '../../../date';
import { fetchAnonymisedDashboard as fetchAnonymisedDashboardAction } from '../../../ducks/dashboard';
import { getFormattedNumberOrEmpty } from '../../../format-numbers';
import { BuySellBondRfqsSendQuotesDrawer } from '../../rfqs/buy-sell-bond-rfqs/BuySellBondRfqsSendQuotesDrawer';

const allOption = { value: 'all', label: 'All' };

const renderSummary = ({ summary, isFetching }) => (
  <div className="summary">
    {Object.keys(summary).map((name) => (
      <SummaryItem
        key={name}
        top={<FormattedMessage id={`reports.rfqsHistory.${name}`} />}
        middle={isFetching ? '' : getFormattedNumberOrEmpty({ value: summary[name] })}
        bottom={<FormattedMessage tagName="span" id="reports.rfqsHistory.basisPoints" />}
        isLoading={isFetching}
      />
    ))}
  </div>
);

renderSummary.propTypes = {
  summary: PropTypes.shape().isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export class BlotterRfqsComponent extends Component {
  constructor(props) {
    super(props);

    const oneMonthAgo = today().add(-1, 'month');

    this.state = {
      rfqQuotes: {},
      showRfqQuotesDialog: false,
      fromDate: oneMonthAgo.toDate(),
      toDate: new Date(),
      filter: {},
      selectedSecurityRfq: null,
      unsolicitedRatesheetTrade: null,
    };
  }

  componentDidMount() {
    const { fromDate, toDate } = this.state;

    this.props.actions.rfqsHistory.fetchRfqsHistory({
      fromDate: formatToShortDateWithoutTz(fromDate),
      toDate: formatToShortDateWithoutTz(toDate),
    });
    const listenedEvents = [socketEvents.offersUpdated, socketEvents.rfqUpdated];

    listenedEvents.forEach((event) => this.props.on(event, this.fetchRfqsHistory));
  }

  fetchRfqsHistory = () => {
    const { fromDate, toDate, filter } = this.state;

    this.props.actions.rfqsHistory.fetchRfqsHistory({
      fromDate: formatToShortDateWithoutTz(fromDate),
      toDate: formatToShortDateWithoutTz(toDate),
      ...filter,
    });
  };

  onCloseRfqQuotesDialog() {
    this.setState({
      showRfqQuotesDialog: false,
      rfqQuotes: {},
      selectedSecurityRfq: null,
    });
  }

  onChangeFromDate = (fromDate) => {
    this.setState({ fromDate });
  };

  onChangeToDate = (toDate) => {
    this.setState({ toDate });
  };

  onFilterChange(filterName, value) {
    const newFilter = {};
    newFilter[filterName] = value;

    const { fromDate, toDate, filter } = this.state;

    const fetchParams = { fromDate: formatToShortDateWithoutTz(fromDate), toDate: formatToShortDateWithoutTz(toDate) };

    this.setState({ filter: { ...filter, ...newFilter } }, () => {
      Object.keys(this.state.filter).forEach((filterKey) => {
        const filterValue = this.state.filter[filterKey];

        if (filterValue !== 'all') {
          fetchParams[filterKey] = filterValue;
        }
      });

      this.props.actions.rfqsHistory.filterRfqsHistory(this.state.filter);
    });
  }

  hasRfqWithEmbeddedFees() {
    const { incomingRfqsReportList } = this.props;

    return incomingRfqsReportList.some((rfq) => rfq.basisPointFee > 0);
  }

  onClearFilters = () =>
    this.setState({ filter: {} }, () => this.props.actions.rfqsHistory.filterRfqsHistory(this.state.filter));

  getRows() {
    const { incomingRfqsReportList } = this.props;

    return incomingRfqsReportList.map((incomingTrade, index) => {
      const canViewQuotes =
        ['expired', 'cancelled', 'won', 'lost'].includes(incomingTrade.status) ||
        unsolicitedRatesheetSources.includes(incomingTrade.source);

      return (
        <Row
          key={incomingTrade.uuid || `row${index}`}
          uuid={incomingTrade.uuid}
          source={incomingTrade.source}
          onBehalf={incomingTrade.onBehalf}
          customer={incomingTrade.counterparty}
          date={incomingTrade.createdAt}
          time={incomingTrade.createdAt}
          currency={incomingTrade.currency}
          principal={incomingTrade.principal}
          assetClass={incomingTrade.instrumentCode}
          tenor={incomingTrade.maturityDate}
          status={incomingTrade.status}
          cancelOption={incomingTrade.cancelOption}
          coupon={incomingTrade.coupon}
          bankCoupon={incomingTrade.bankCoupon}
          basisPointFee={incomingTrade.basisPointFee}
          isAutoQuote={incomingTrade.isAutoQuote}
          winningCoupon={incomingTrade.winningCoupon}
          nextBestCoupon={incomingTrade.nextBestCoupon}
          operation={incomingTrade.operation}
          quotedBy={incomingTrade.quotedBy}
          margin={incomingTrade.margin}
          viewQuotesAction={() => this.toggleRfqQuotesDialog(incomingTrade)}
          canViewQuotes={canViewQuotes}
          settlementDate={incomingTrade.settlementDate}
          isin={incomingTrade.isin}
          includeEmbeddedFee={this.hasRfqWithEmbeddedFees()}
        />
      );
    });
  }

  getStaticFilterOptions = () => {
    const buildOption = (option) => ({
      value: option.toLowerCase(),
      label: option,
    });

    const allItemsOption = allOption;

    const filterOptions = {
      status: [allItemsOption, buildOption('Won'), buildOption('Lost'), buildOption('Cancelled')],
    };

    return filterOptions;
  };

  getFilterOptions = ({ valueProperty, labelProperty }) => {
    const {
      incomingRfqsReport: { isFetching, incomingRfqs },
    } = this.props;

    return isFetching
      ? [allOption]
      : [allOption].concat(this.getValuesAndLabels({ incomingRfqs, valueProperty, labelProperty }));
  };

  toggleRfqQuotesDialog = async (incomingTrade) => {
    const { uuid, instrumentCode, operation, source } = incomingTrade;

    if (unsolicitedRatesheetSources.includes(source)) {
      this.setState({ unsolicitedRatesheetTrade: incomingTrade });

      return;
    }

    if (['BOND', 'FRN'].includes(instrumentCode)) {
      this.setState({ selectedSecurityRfq: { uuid, operation } });

      return;
    }

    await this.props.actions.fetchAnonymisedDashboard(uuid);
    this.setState({
      showRfqQuotesDialog: true,
      rfqQuotes: this.props.dashboard.anonymised.rfqQuotes,
    });
  };

  renderRfqQuotesDialog = () => (
    <IncomingQuotesDialog
      onClose={() => this.onCloseRfqQuotesDialog()}
      show={this.state.showRfqQuotesDialog}
      rfqQuotes={this.state.rfqQuotes}
    />
  );

  renderLoader = () => (
    <div className="no-data">
      <Loader type="ball-pulse" />
    </div>
  );

  renderFilters = () => {
    const { fromDate, toDate } = this.state;

    return (
      <FlexibleRow className="filters" alignItemsCenter justifyContentCenter>
        <Range from={fromDate} to={toDate} onChangeFrom={this.onChangeFromDate} onChangeTo={this.onChangeToDate} />

        <button
          type="button"
          className="toolbar-item toolbar-button btn btn-ghost-primary filter"
          onClick={this.fetchRfqsHistory}
        >
          <FormattedMessage id="reports.rfqsHistory.filterByDateButton" />
        </button>
        {this.renderExtraFilters()}
        <Button disabled={false} className="toolbar-button reset-filter-button" onClick={this.onClearFilters}>
          <FormattedMessage id="clear" />
        </Button>
      </FlexibleRow>
    );
  };

  getValuesAndLabels = ({ incomingRfqs, valueProperty, labelProperty }) =>
    incomingRfqs
      .reduce((list, rfq) => {
        if (!list.find(({ value }) => value === rfq[valueProperty])) {
          list.push({
            value: rfq[valueProperty],
            label: rfq[labelProperty || valueProperty],
          });
        }

        return list;
      }, [])
      .sort((item1, item2) => (item1.label < item2.label ? -1 : 1));

  renderExtraFilters = () => {
    const { intl } = this.props;
    const { filter, isFetching } = this.state;
    const filterOptions = this.getStaticFilterOptions();

    const counterpartyOptions = this.getFilterOptions({
      valueProperty: 'counterpartyId',
      labelProperty: 'counterparty',
    });
    const dealersOptions = this.getFilterOptions({ valueProperty: 'quotedBy' });
    const instrumentCodeOptions = this.getFilterOptions({
      valueProperty: 'instrumentCode',
    });

    const sourceOptions = this.getFilterOptions({
      valueProperty: 'source',
    }).map(({ value, label: id }) => ({
      value,
      label: intl.formatMessage({ id }),
    }));

    return (
      <React.Fragment>
        {renderSelect({
          name: 'source',
          label: 'reports.rfqsHistory.source',
          onDropdownFilterChange: (filterName, value) => this.onFilterChange(filterName, value),
          options: sourceOptions,
          isSearchable: true,
          isLoading: isFetching,
          value: getImperiumSelectValue({ options: sourceOptions, value: filter.source || 'all' }),
        })}
        {renderSelect({
          name: 'counterpartyId',
          label: 'reports.rfqsHistory.customer',
          onDropdownFilterChange: (filterName, value) => this.onFilterChange(filterName, value),
          options: counterpartyOptions,
          isSearchable: true,
          isLoading: isFetching,
          value: getImperiumSelectValue({ options: counterpartyOptions, value: filter.counterpartyId || 'all' }),
        })}
        {renderSelect({
          name: 'quotedBy',
          label: 'reports.rfqsHistory.dealer',
          onDropdownFilterChange: (filterName, value) => this.onFilterChange(filterName, value),
          options: dealersOptions,
          isSearchable: true,
          isLoading: isFetching,
          value: getImperiumSelectValue({ options: dealersOptions, value: filter.quotedBy || 'all' }),
        })}
        {renderSelect({
          name: 'status',
          label: 'reports.rfqsHistory.status',
          onDropdownFilterChange: (filterName, value) => this.onFilterChange(filterName, value),
          options: filterOptions.status,
          value: getImperiumSelectValue({ options: filterOptions.status, value: filter.status || 'all' }),
        })}
        {renderSelect({
          name: 'instrumentCode',
          label: 'reports.rfqsHistory.assetClass',
          onDropdownFilterChange: (filterName, value) => this.onFilterChange(filterName, value),
          options: instrumentCodeOptions,
          value: getImperiumSelectValue({ options: instrumentCodeOptions, value: filter.instrumentCode || 'all' }),
        })}
      </React.Fragment>
    );
  };

  renderToolbar = () => {
    const { fromDate, toDate, fetchParams } = this.state;
    const { incomingRfqs } = this.props.incomingRfqsReport;
    const downloadCsvEnabled =
      this.props.licences.includes(availableLicences.rfqReportingCsv) || this.props.isGlobalAdmin;

    return (
      <div className="toolbar-container">
        {this.renderFilters()}
        {downloadCsvEnabled && (
          <Button
            className="toolbar-item toolbar-button push-left"
            onClick={() =>
              this.props.actions.rfqsHistory.fetchRfqsHistoryCsv({
                ...fetchParams,
                fromDate: formatToShortDateWithoutTz(fromDate),
                toDate: formatToShortDateWithoutTz(toDate),
              })
            }
            disabled={!incomingRfqs || !incomingRfqs.length}
          >
            <FormattedMessage id="reports.rfqsHistory.downloadCsvButton" />
          </Button>
        )}
      </div>
    );
  };

  renderIncomingRfqs = () => {
    const { isFetching } = this.props.incomingRfqsReport;
    const rows = this.getRows();

    return (
      <div className="rfqs-history-panels">
        {this.renderToolbar()}
        <Table isLoading={isFetching} rows={rows} includeEmbeddedFee={this.hasRfqWithEmbeddedFees()} />
      </div>
    );
  };

  render() {
    const { unsolicitedRatesheetTrade, selectedSecurityRfq, fromDate, toDate } = this.state;

    return (
      <div className="blotter-rfq-container">
        {this.renderIncomingRfqs()}
        {this.renderRfqQuotesDialog()}
        {unsolicitedRatesheetTrade && (
          <UnsolicitedRatesheetsSnapshot
            onClose={() => this.setState({ unsolicitedRatesheetTrade: null })}
            show={!!unsolicitedRatesheetTrade}
            sourceEntityId={unsolicitedRatesheetTrade.sourceEntityId}
            investorTenantId={unsolicitedRatesheetTrade.counterpartyId}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        <BuySellBondRfqsSendQuotesDrawer
          rfq={selectedSecurityRfq}
          onCloseDrawer={() => this.setState({ selectedSecurityRfq: null })}
        />
      </div>
    );
  }
}

BlotterRfqsComponent.propTypes = {
  actions: PropTypes.shape().isRequired,
  dashboard: PropTypes.shape().isRequired,
  incomingRfqsReport: PropTypes.shape().isRequired,
  incomingRfqsReportList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  licences: PropTypes.arrayOf(PropTypes.string).isRequired,
  on: PropTypes.func,
  isGlobalAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  incomingRfqsReport: state.incomingRfqsReport,
  incomingRfqsReportList: filteredRfqsSelector(state),
  licences: state.session.user.licences,
  isGlobalAdmin: isGlobalAdminSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchAnonymisedDashboard: bindActionCreators(fetchAnonymisedDashboardAction, dispatch),
    rfqsHistory: bindActionCreators(rfqsHistoryActions, dispatch),
  },
});

export const BlotterRfqsTab = compose(
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.offers, rooms.rfq] }),
  injectIntl,
)(BlotterRfqsComponent);
