import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './error.scss';

export const ErrorFooter = ({ errorInfo }) => (
  <div>
    <p>
      <FormattedMessage id="contact.1" />
    </p>
    <p>
      <a href={`mailto:tradesupport@imperium.markets${errorInfo ? `?body=${encodeURI(errorInfo)}` : ''}`}>
        <FormattedMessage id="contact.2" />
      </a>
    </p>
    <div className="error-actions">
      <a
        href="#"
        className="btn btn-min-width-md btn-default"
        onClick={(e) => {
          e.preventDefault();
          window.history.back();
        }}
      >
        <FormattedMessage id="goBack" />
      </a>
    </div>
  </div>
);

ErrorFooter.propTypes = {
  errorInfo: string,
};

export default ErrorFooter;
