import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import Toggle from 'react-toggle';

export const columns = {
  actions: { label: 'rfqInclude' },
  adiName: { label: 'rfqAdi', sort: 'name' },
  short: { label: 'rfqShort' },
  long: { label: 'rfqLong' },
  '1M': { label: '1M', sort: '1M' },
  '2M': { label: '2M', sort: '2M' },
  '3M': { label: '3M', sort: '3M' },
  '4M': { label: '4M', sort: '4M' },
  '5M': { label: '5M', sort: '5M' },
  '6M': { label: '6M', sort: '6M' },
  '7M': { label: '7M', sort: '7M' },
  '8M': { label: '8M', sort: '8M' },
  '9M': { label: '9M', sort: '9M' },
  '10M': { label: '10M', sort: '10M' },
  '11M': { label: '11M', sort: '11M' },
  '12M': { label: '12M', sort: '12M' },
};

export const presenter = (data, index, actions, options) => {
  const isCurrentTenantIssuer = data.issuer.id === options.tenantIssuerId;

  return (
    <tr key={index} className={classNames({ 'current-issuer-text': isCurrentTenantIssuer })}>
      {renderSelectButton(data, actions, isCurrentTenantIssuer, options.readOnlyIssuers)}
      <td>{data.issuer.name}</td>
      <td>{data.issuer.shortRating === 'Z' ? <FormattedMessage id="rfqUnrated" /> : data.issuer.shortRating}</td>
      <td>{data.issuer.longRating === 'Z' ? <FormattedMessage id="rfqUnrated" /> : data.issuer.longRating}</td>
      {renderRates(data)}
    </tr>
  );
};

function renderSelectButton(data, actions, isCurrentTenantIssuer, readOnlyIssuers = []) {
  if (isCurrentTenantIssuer) {
    return <td key={data.issuer.id} />;
  }

  return data.available ? (
    <td className="actions-column">
      <Row element="label" alignItemsCenter>
        <Toggle
          disabled={readOnlyIssuers.includes(data.issuer.id)}
          checked={data.selected}
          icons={false}
          onChange={() => actions.onRatesheetIssuerSelectChange(data.issuer)}
        />
        <FormattedMessage tagName="span" id={data.selected ? 'selected' : 'select'} />
      </Row>
    </td>
  ) : (
    <td className="actions-column">
      <Button className="btn-red-disabled btn-table" disabled title="ADI is not currently seeking funding">
        <FormattedMessage id="select" />
      </Button>
    </td>
  );
}

function renderRates(data) {
  return Object.keys(data.rates).map((rateKey) => (
    <td key={rateKey}>{(data.available && data.rates[rateKey] > 0 && data.rates[rateKey].toFixed(2)) || null}</td>
  ));
}
