export const accepted = 'accepted';
export const reinvested = 'reinvested';
export const reinvestedOnBehalf = 'reinvestedOnBehalf';
export const addTradeOnBehalf = 'addTradeOnBehalf';
export const redeemedOnBehalf = 'redeemedOnBehalf';
export const unactioned = 'unactioned';
export const sent = 'sent';
export const read = 'read';
export const redeemed = 'redeemed';
export const rejected = 'rejected';
export const oneDayRollover = 'oneDayRollover';
export const rfqInProgress = 'rfqInProgress';
export const noRatesheet = 'noRatesheet';
export const cancelledRfq = 'cancelledRfq';
