import '../default-table.scss';
import './table.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '../../../date';
import Row from './row';

const Table = ({ rfqQuotes }) => {
  if (!rfqQuotes.list) {
    return <span />;
  }

  const quotesByDate = rfqQuotes.list.sort(byMaturityDate);
  const quotesWithBestCoupon = getBestCouponForQuotes(quotesByDate);
  const maturities = getMaturities(quotesWithBestCoupon);
  const quotes = getQuoteRows(quotesWithBestCoupon);

  return (
    <table className="outgoing-quotes-table table-striped">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="rfqAdi" />
          </th>
          <th>
            <FormattedMessage id="receivedAt" />
          </th>
          <th>
            <FormattedMessage id="reports.rfqsHistory.creditRating" />
          </th>
          {renderMaturityColumns(maturities)}
        </tr>
      </thead>
      <tbody>{renderRows(quotes)}</tbody>
    </table>
  );
};

const byMaturityDate = (firstQuote, secondQuote) =>
  new Date(firstQuote.maturityDate) - new Date(secondQuote.maturityDate);

const getBestCouponForQuotes = (quotes) => {
  if (!quotes.length) {
    return [];
  }

  const bestCouponForMaturityCodes = getBestCouponForMaturityCodes(quotes);

  return quotes.map((quote) =>
    bestCouponForMaturityCodes[quote.maturityCode] === quote.coupon ? { ...quote, isBestCoupon: true } : quote,
  );
};

const getBestCouponForMaturityCodes = (quotes) => {
  const bestCouponForMaturityCode = {};

  quotes.forEach((quote) => {
    if (!bestCouponForMaturityCode[quote.maturityCode]) {
      bestCouponForMaturityCode[quote.maturityCode] = quote.coupon;
    }

    if (quote.coupon > bestCouponForMaturityCode[quote.maturityCode]) {
      bestCouponForMaturityCode[quote.maturityCode] = quote.coupon;
    }
  });

  return bestCouponForMaturityCode;
};

const getMaturities = (rfqQuotes) =>
  rfqQuotes.reduce((acc, { maturityCode, maturityDate }) => {
    if (!acc.find((maturity) => maturity.maturityCode === maturityCode)) {
      acc.push({ maturityCode, maturityDate });
    }

    return acc;
  }, []);

const getQuoteRows = (rfqQuotes) =>
  rfqQuotes.reduce((quoteRows, quote) => {
    const quoteCell = {
      key: quote.id,
      coupon: quote.coupon,
      accepted: ['accepted', 'confirmed'].includes(quote.status),
      isBestCoupon: quote.isBestCoupon,
    };

    const { issuerName } = quote;

    quoteRows[issuerName]
      ? quoteRows[issuerName].quotes.push(quoteCell)
      : // eslint-disable-next-line no-param-reassign
        (quoteRows[issuerName] = {
          receivedAt: quote.receivedAt,
          rating: quote.rating,
          isAutoQuote: quote.isAutoQuote,
          quotes: [quoteCell],
        });

    return quoteRows;
  }, {});

const renderMaturityColumns = (maturities) => (maturities && maturities.map(getColumn)) || [];

const getColumn = (maturity) => (
  <th className="maturity-column" key={maturity.maturityDate}>
    {maturity.maturityCode}
    <br />
    {formatDate(maturity.maturityDate, 'DD/MM/YYYY')}
  </th>
);

const renderRows = (quotes) =>
  quotes &&
  Object.keys(quotes).map((issuerName) => {
    const row = quotes[issuerName];

    return (
      <Row
        key={issuerName}
        issuerName={issuerName}
        creditRating={row.rating}
        receivedAt={row.receivedAt}
        isAutoQuote={row.isAutoQuote}
        quotes={row.quotes}
      />
    );
  });

Table.propTypes = {
  rfqQuotes: PropTypes.shape().isRequired,
};

export default Table;
