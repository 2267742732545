import { createActionThunk } from 'redux-thunk-actions';

import { nominate } from '../../../api/holding/rfq/nominate';
import { actions } from './actions';

export const nominateAction = createActionThunk(actions.nominate, (params) => nominate(params));

export const nominateReducer = {
  [nominateAction.STARTED]: (state, { payload }) => ({
    ...state,
    nominating: true,
    uuid: payload[0].uuid,
    hasComplianceBreach: payload[0].hasComplianceBreach,
  }),
  [nominateAction.SUCCEEDED]: (state) => ({
    ...state,
    nominated: true,
  }),
  [nominateAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [nominateAction.ENDED]: (state) => ({
    ...state,
    nominating: false,
    nominated: false,
    uuid: '',
  }),
};
