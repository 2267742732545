import { createActionThunk } from 'redux-thunk-actions';

import { removeToken } from '../../api/user';
import { actions } from './actions';
import userStore from '../../user-store';

export const logoutAction = createActionThunk(actions.logout, () => removeToken(userStore.getToken()));

export const logoutReducer = {
  [logoutAction.STARTED]: (state) => ({
    ...state,
    loggingOut: true,
  }),
  [logoutAction.SUCCEEDED]: (state) => {
    userStore.removeToken();

    return {
      ...state,
      token: undefined,
      error: null,
      loggedOut: true,
    };
  },
  [logoutAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [logoutAction.ENDED]: (state) => ({
    ...state,
    loggingOut: false,
    loggedOut: false,
  }),
};
