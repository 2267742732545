import './request-quotes.scss';

import React, { useState } from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import Toggle from 'react-toggle';

import { safeCustodyOptions } from '../../../../../../api/holding/codes';
import { createSellBondsRfq } from '../../../../../../api/holding/rfq/sell-bonds';
import rooms from '../../../../../../api/socket/rooms';
import {
  Column,
  ConfirmationPopover,
  DatePickerField,
  FormikCurrencyInput,
  FormikSelectField,
  Row,
  Tooltip,
  ValidatedFormikField,
} from '../../../../../../components/common';
import { socketEvents } from '../../../../../../components/hoc/include-socket';
import { formatToShortDateWithoutTz, newDate } from '../../../../../../date';
import {
  sellBondRfqRequestQuotesClicked,
  track,
} from '../../../../../../event-tracker';
import {
  convertStringWithDelimiterToInt,
  toCompactCurrency,
  toCurrency,
} from '../../../../../../format-numbers';
import { ImperiumIntlProvider } from '../../../../../intl-provider';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../../../../../toast/toast';
import { nominalUnitPrice } from '../../../../rfq-nominal-values';
import { RfqBankSelection } from '../../../RfqBankSelection/RfqBankSelection';
import {
  initialValues,
  minParcel,
  thereafterDenominations,
  validationSchema,
} from './request-quotes-schema';
import { TradeInfo } from './TradeInfo';

const wbc = 'WBC';
const austraclear = 'AUSTRACLEAR';

export const RequestQuotes = injectIntl(({ trade, emit, onSuccess, currency, intl }) => {
  const [loading, setLoading] = useState(false);
  const [sellAll, setSellAll] = useState(false);
  const totalHolding = trade.instrumentDetails.quantity * nominalUnitPrice;

  const informationPopover = (
    <Popover id="information-popover" bsClass="light drawer-popover popover">
      <ImperiumIntlProvider>
        <Column>
          <Row>
            <FormattedMessage tagName="span" id="minParcelToSell" />: <b>{toCurrency({ value: minParcel })}</b>
          </Row>
          <Row>
            <FormattedMessage tagName="span" id="thereafterDenominations" />:{' '}
            <b>{toCurrency({ value: thereafterDenominations })}</b>
          </Row>
        </Column>
      </ImperiumIntlProvider>
    </Popover>
  );

  const onSubmit = async ({ settlementDate, ...formData }) => {
    setLoading(true);

    const payload = {
      ...formData,
      settlementDate: formatToShortDateWithoutTz(settlementDate),
      quantityToSell: convertStringWithDelimiterToInt(formData.quantityToSell) / nominalUnitPrice,
      tradeId: trade.id,
    };

    try {
      const { notifyDomains: tenants } = await createSellBondsRfq({
        payload,
      });

      emit(socketEvents.rfqUpdated, {
        tenants,
        room: rooms.rfq,
      });

      track(sellBondRfqRequestQuotesClicked);

      showToastMessage(intl.formatMessage({ id: 'quotesRequestedSuccessfully' }), MessageType.SUCCESS);

      onSuccess();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setLoading(false);
    }
  };

  const onSellAllChange = ({ setFieldValue, setFieldTouched, setFieldError }) => {
    if (!sellAll) {
      setFieldValue('quantityToSell', `${totalHolding}`);
      setFieldTouched('quantityToSell');
      setFieldError('quantityToSell');
    }

    setSellAll(!sellAll);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        safeCustodyCode: trade.safeCustodyCode,
      }}
      validationSchema={validationSchema({ sellAll, totalHolding, intl })}
      onSubmit={onSubmit}
    >
      {({ touched, errors, values, setFieldValue, setFieldTouched, handleSubmit, setFieldError, handleChange }) => (
        <React.Fragment>
          <TradeInfo trade={trade} totalHolding={totalHolding} currency={currency} />
          <Form className="request-quotes-form">
            <Column className="form-group-item">
              <ValidatedFormikField
                id="quantityToSell"
                name="quantityToSell"
                labelId="faceValueToSell"
                labelParams={{ currency }}
                data-testid="quantityToSell"
                disabled={sellAll}
                value={values.quantityToSell}
                component={FormikCurrencyInput}
                touched={touched}
                errors={errors}
                fieldInfoComponent={
                  <Row flex={1} alignItemsCenter contentBetween>
                    <Tooltip id="quantity-to-sell-information-button" overlay={informationPopover}>
                      <button type="button" className="btn btn-default information-button">
                        <FaQuestion />
                      </button>
                    </Tooltip>
                    <Row className="sell-all-toggle" alignItemsCenter>
                      <Toggle
                        checked={sellAll}
                        onChange={() =>
                          onSellAllChange({
                            setFieldValue,
                            setFieldTouched,
                            setFieldError,
                          })
                        }
                      />
                      <FormattedMessage tagName="span" id="sellAll" />
                    </Row>
                  </Row>
                }
              />
              <Row>
                <ValidatedFormikField
                  id="safeCustodyCode"
                  name="safeCustodyCode"
                  labelId="safeCustody"
                  data-testid="safeCustodyCode"
                  // value={values.safeCustodyCode || trade.safeCustodyCode}
                  component={FormikSelectField}
                  onChange={(value) => {
                    setFieldValue('safeCustodyCode', value);

                    value !== austraclear &&
                      setFieldValue(
                        'issuerCodes',
                        values.issuerCodes.filter((val) => val !== wbc),
                      );

                    handleChange('safeCustodyCode');
                  }}
                  touched={touched}
                  errors={errors}
                  options={safeCustodyOptions}
                  isClearable={false}
                />
                <ValidatedFormikField
                  id="settlementDate"
                  name="settlementDate"
                  labelId="standardSettlementDate"
                  data-testid="settlementDate"
                  value={values.settlementDate}
                  component={(props) => (
                    <DatePickerField {...props} startDate={newDate().toDate()} disableWeekends disableHolidays />
                  )}
                  touched={touched}
                  errors={errors}
                />
              </Row>
            </Column>
            <RfqBankSelection
              key="sell-bonds-rfq-bank-selection"
              defaultSelectedIssuers={values.issuerCodes}
              safeCustodyCode={values.safeCustodyCode}
              className={classNames('validated-field', {
                'field-error': touched.issuerCodes && errors.issuerCodes,
              })}
              onSelectedIssuersChange={(nextSelectedIssuers) => {
                !touched.issuerCodes && setFieldTouched('issuerCodes');
                setFieldValue('issuerCodes', nextSelectedIssuers);
                handleChange('issuerCodes');
              }}
            >
              <Row className="error-message">{touched.issuerCodes && errors.issuerCodes}</Row>
            </RfqBankSelection>
            <Column className="form-button">
              <ConfirmationPopover
                className="btn-solid-primary"
                onConfirm={handleSubmit}
                isConfirmingFromOutside={loading}
                confirmationMessageId="requestQuotesConfirmation"
                confirmationMessageValues={{
                  sellingValue: toCompactCurrency({
                    value: convertStringWithDelimiterToInt(values.quantityToSell),
                  }),
                  holdingValue: toCompactCurrency({ value: totalHolding }),
                  differenceValue: toCompactCurrency({
                    value: totalHolding - convertStringWithDelimiterToInt(values.quantityToSell),
                  }),
                }}
                buttonLabelId="tradeRequestQuotes"
                popoverClass="light drawer-popover"
              />
            </Column>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );
});

RequestQuotes.propTypes = {
  trade: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  emit: PropTypes.func,
};
