import './incoming-quotes-dialog.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Table from './table';

const IncomingQuotesDialog = ({ onClose, show, rfqQuotes }) => (
  <div className="static-modal">
    <Modal show={show} className="incoming-quotes-dialog">
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="dashboard.dialog.incomingRfqQuotes" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table rfqQuotes={rfqQuotes} />
      </Modal.Body>
      <Modal.Footer>
        {rfqQuotes.hasRfqReportingLicence || (
          <footer className="note">
            <FormattedMessage id="dashboard.dialog.anonymisedQuotesAvailableSubscribers" />
          </footer>
        )}
        {rfqQuotes.basisPointFee > 0 && (
          <footer className="note">
            <FormattedMessage
              id="dashboard.dialog.basisPointFeeDiscounted"
              values={{ basisPointFee: rfqQuotes.basisPointFee }}
            />
          </footer>
        )}
        <Button onClick={onClose} className="btn-solid-primary btn-save">
          <FormattedMessage id="close" />
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

IncomingQuotesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  rfqQuotes: PropTypes.shape(),
};

export default IncomingQuotesDialog;
