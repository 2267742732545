import { onboardingClientAxiosInstance } from '../onboarding-client';

export const createProposedTenant = async (domain, payload) => {
  const { data } = await onboardingClientAxiosInstance({
    method: 'post',
    url: `/proposed-tenants/${domain}/`,
    data: payload,
  });

  return data;
};
