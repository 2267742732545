import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCurrencyData } from './money-currencies';

function mapStateToProps(state) {
  return {
    currency: state.session.user.currency,
    locale: state.browserLocale,
  };
}

// eslint-disable-next-line react/prefer-stateless-function
class MoneyComponent extends Component {
  render() {
    const {
      className,
      value,
      currency,
      customCurrency,
      emptyCharacter,
      fraction,
      showSymbol,
      locale,
      short,
      long,
      hideZero,
    } = this.props;

    const currentCurrency = customCurrency || currency;
    const moneyCurrency = getCurrencyData(currentCurrency);

    const {
      minimumFractionDigits = fraction !== undefined ? fraction : moneyCurrency.minimumFractionDigits,
      maximumFractionDigits = fraction !== undefined ? fraction : moneyCurrency.maximumFractionDigits,
    } = this.props;

    const shortSymbol = short ? moneyCurrency.symbolNative : '';
    const longSymbol = long ? currentCurrency : '';
    const mainBrowserLocale = locale.split(',')[0];
    const formattedValue =
      value !== undefined &&
      value !== null &&
      formatter({
        locale: mainBrowserLocale,
        currentCurrency,
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(value);

    const symbol = showSymbol ? `${longSymbol}${shortSymbol}` : '';

    return (
      <span className={className}>
        {value === 0 && hideZero ? '-' : `${symbol}${formattedValue || emptyCharacter}`}
      </span>
    );
  }
}

const formatter = ({ locale, currency, minimumFractionDigits, maximumFractionDigits }) =>
  new Intl.NumberFormat(locale, {
    style: 'decimal',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });

export const getMoneySymbol = ({ currency, short, long }) => {
  const moneyCurrency = getCurrencyData(currency);
  const shortSymbol = short ? moneyCurrency.symbolNative : '';
  const longSymbol = long ? currency : '';

  return `${longSymbol}${shortSymbol}`;
};

MoneyComponent.propTypes = {
  currency: PropTypes.string.isRequired,
  customCurrency: PropTypes.string,
  locale: PropTypes.string.isRequired,
  emptyCharacter: PropTypes.string,
  value: PropTypes.number,
  fraction: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  className: PropTypes.string,
  short: PropTypes.bool,
  long: PropTypes.bool,
  showSymbol: PropTypes.bool,
  hideZero: PropTypes.bool,
};

MoneyComponent.defaultProps = {
  emptyCharacter: '',
  showSymbol: true,
};

export const Money = connect(mapStateToProps)(MoneyComponent);
