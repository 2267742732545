import './navigation.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { routes } from '../../routes';
import { Column } from '../common';
import { Protect } from '../common/Protect';

const Navigation = ({ activeKey, items, onMenuItemSelect, user, onLogout }) => (
  <aside id="navigation" className="navigation collapse-navbar">
    <Column>
      {items.map((menu) =>
        menu.requiredTo ? (
          <Protect key={menu.name} requiredTo={menu.requiredTo} user={user}>
            {buildNavSeparator(menu, activeKey, onMenuItemSelect, user)}
          </Protect>
        ) : (
          buildNavSeparator(menu, activeKey, onMenuItemSelect, user)
        ),
      )}
      {buildNavUser({ activeKey, onMenuItemSelect, onLogout })}
    </Column>
  </aside>
);

const buildNavSeparator = (menu, activeKey, onMenuItemSelect, user) => (
  <nav key={menu.name}>
    <p>{menu.name}</p>
    <Nav className="nav luna-nav" activeKey={activeKey}>
      {menu.items.map((item, index) => {
        const navItem = renderNavItem(activeKey, item, onMenuItemSelect, index);

        return item.requiredTo ? (
          <Protect
            bankOnly={item.bankOnly}
            investorOnly={item.investorOnly}
            key={item.key}
            licence={item.licence}
            requiredTo={item.requiredTo}
            user={user}
          >
            {navItem}
          </Protect>
        ) : (
          navItem
        );
      })}
    </Nav>
  </nav>
);

const buildNavUser = ({ activeKey, onMenuItemSelect, onLogout }) => {
  const onLogoutMenuItemSelect = (_, event) => onLogout(event);

  return (
    <nav>
      <p>
        <FormattedMessage id="user" />
      </p>
      <Nav className="nav luna-nav" activeKey={activeKey}>
        {renderNavItem(
          activeKey,
          { label: 'changePassword', eventKey: routes.user.changePassword },
          onMenuItemSelect,
          'changePassword',
        )}
        {renderNavItem(activeKey, { label: 'logout' }, onLogoutMenuItemSelect, 'logout')}
      </Nav>
    </nav>
  );
};

const renderNavItem = (activeKey, navItem, onMenuItemSelect, index) => (
  <NavItem
    key={index}
    active={navItem.isActive ? navItem.isActive(activeKey) : activeKey === navItem.eventKey}
    eventKey={navItem.eventKey}
    onSelect={onMenuItemSelect}
  >
    <FormattedMessage id={navItem.label} />
  </NavItem>
);

Navigation.propTypes = {
  activeKey: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          eventKey: PropTypes.string.isRequired,
          key: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          requiredRole: PropTypes.string,
          isActive: PropTypes.func,
        }),
      ),
    }),
  ),
  onMenuItemSelect: PropTypes.func,
  user: PropTypes.shape(),
  onLogout: PropTypes.func.isRequired,
};

export default Navigation;
