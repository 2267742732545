import React, { useEffect, useState } from 'react';

import propTypes from 'prop-types';
import { Row } from 'react-display-flex';
import { injectIntl } from 'react-intl';
import Toggle from 'react-toggle';

import { ConfirmationPopover } from '../../../components/common';
import { sellBondRfqNotInterested, track } from '../../../event-tracker';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../../toast/toast';

export const RfqNotInterestedToggle = injectIntl(
  ({ quoteStatus, onNotInterestedApiAction, onNotInterestedSuccess, onNotInterestedToggled, intl }) => {
    const [isSendingNotInterested, setIsSendingNotInterested] = useState(false);

    const [isNotInterested, setIsNotInterested] = useState(false);

    useEffect(() => {
      setIsNotInterested(quoteStatus === 'notInterested');
    }, [quoteStatus]);

    useEffect(() => {
      onNotInterestedToggled(isNotInterested);
    }, [onNotInterestedToggled, isNotInterested]);

    const onNotInterestedToggleChange = () => {
      setIsNotInterested(true);

      if (quoteStatus === 'notInterested') {
        return;
      }

      sendNotInterestedApiCall();
    };

    const sendNotInterestedApiCall = async () => {
      try {
        setIsSendingNotInterested(true);

        await onNotInterestedApiAction();

        track(sellBondRfqNotInterested);

        onNotInterestedSuccess();

        showToastMessage(intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);
      } catch (error) {
        showResponseErrorMessage({ intl, error });
        setIsNotInterested(false);
      } finally {
        setIsSendingNotInterested(false);
      }
    };

    return quoteStatus === 'notInterested' ? (
      <Row>
        <Toggle
          data-testid="not-interested-toggle"
          checked={isNotInterested}
          icons={false}
          onChange={(e) => setIsNotInterested(e.target.checked)}
        />
        <p>Not interested</p>
      </Row>
    ) : (
      <ConfirmationPopover
        buttonLabelId="notInterested"
        popoverClass="light not-interested-popover"
        confirmationMessageId="areYouSureConfirmNotInterested"
        confirmLabelId="ok"
        onConfirm={onNotInterestedToggleChange}
        placement="left"
        isConfirmingFromOutside={isSendingNotInterested}
      >
        {({ onPopoverHandlerClick, disabled }) => (
          <Row>
            <Toggle
              data-testid="not-interested-toggle"
              disabled={disabled}
              checked={isNotInterested}
              icons={false}
              onChange={onPopoverHandlerClick}
            />
            <p>Not interested</p>
          </Row>
        )}
      </ConfirmationPopover>
    );
  },
);

RfqNotInterestedToggle.propTypes = {
  quoteStatus: propTypes.string,
  onNotInterestedApiAction: propTypes.func,
  onNotInterestedSuccess: propTypes.func,
  onNotInterestedToggled: propTypes.func,
};
