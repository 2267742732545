import './form.scss';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import clone from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import { ConfirmationPopover, ValidatedField } from '../';
import { formatToShortDateWithoutTz } from '../../../date';
import toFloat from '../../../parse-float';
import FormWrapper from './form-wrapper';
import asyncValidateBySchema from './validate';

export default function form(
  { initialValues, schema, fields, formName = 'common-form-validation', ...reduxFormOptions } = {
    formName: 'common-form-validation',
  },
) {
  class CommonForm extends Component {
    componentDidUpdate = () => {
      if (!this.props.shouldResetForm) {
        return;
      }

      this.props.reset();
    };

    onSubmit = (data) => {
      const { onSubmit, resetForm } = this.props;

      window.scrollTo(0, 0);
      const normalizedData = this.getNormalizedData(data);
      onSubmit(normalizedData);

      if (resetForm) {
        resetForm();
      }
    };

    onFormKeyUp = ({ keyCode }) => {
      const isFormValid = this.isFormValid();

      if (!isFormValid || keyCode !== 13) {
        return;
      }

      ReactDOM.findDOMNode(this.submitButton).focus();
    };

    getNormalizedData = (data) => {
      let normalizedData = clone(data);

      normalizedData = normalizeSelectFields(fields, normalizedData);
      normalizedData = normalizeCurrencyFields(fields, normalizedData);
      normalizedData = normalizedPercentageFields(fields, normalizedData);
      normalizedData = normalizeRedutibleFields(fields, normalizedData);
      normalizedData = normalizeFieldsWithParentOject(fields, normalizedData);
      normalizedData = normalizeFieldsWithJSONValue(fields, normalizedData);
      normalizedData = normalizeDateFields(fields, normalizedData);

      return normalizedData;
    };

    isFormValid = () => {
      const { anyTouched, invalid } = this.props;

      return anyTouched && !invalid;
    };

    renderInputs = () => {
      const { data } = this.props;

      fields.forEach((field) => {
        // eslint-disable-next-line no-param-reassign
        field.intlValues = field.intlValues ? field.intlValues : '';

        if (field.componentType !== 'async-select') {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        field.options = field.loadOptions(data);
      });

      return (
        <Row>
          {fields.map((field, key) => (
            <Col key={field.name || key} xs={field.halfSize ? 6 : 12}>
              <ValidatedField {...field} />
            </Col>
          ))}
        </Row>
      );
    };

    renderFormActions = () => {
      const { handleSubmit, cancelLabel, onCancel, isSaving } = this.props;

      return (
        <Row>
          <Col xs={12}>
            <ConfirmationPopover
              isConfirmingFromOutside={isSaving}
              buttonLabelId="save"
              confirmationMessageId="areYouSure"
              type="button"
              className="btn-solid-primary btn-save"
              disabled={!this.isFormValid() || isSaving}
              onConfirm={handleSubmit((data) => {
                this.onSubmit(data);
              })}
              ref={(button) => {
                this.submitButton = button;
              }}
            />
            {onCancel && (
              <Button className="pull-right" bsStyle="link" onClick={onCancel}>
                <FormattedMessage id={cancelLabel || 'goBack'} />
              </Button>
            )}
          </Col>
        </Row>
      );
    };

    render() {
      const { title, customClass, hideActions } = this.props;

      return (
        <FormWrapper title={title} customClass={customClass}>
          <form onKeyUp={this.onFormKeyUp} onSubmit={this.onSubmit}>
            {this.renderInputs()}
            {!hideActions && this.renderFormActions()}
          </form>
        </FormWrapper>
      );
    }
  }

  CommonForm.propTypes = {
    data: PropTypes.shape(),
    title: PropTypes.string,
    customClass: PropTypes.string,
    cancelLabel: PropTypes.string,
    reset: PropTypes.func,
    destroy: PropTypes.func,
    onSubmitted: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isValidating: PropTypes.bool,
    isSaving: PropTypes.bool,
    isValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    shouldResetForm: PropTypes.bool,
    change: PropTypes.func,
    anyTouched: PropTypes.bool,
    invalid: PropTypes.bool,
    handleSubmit: PropTypes.func,
    resetForm: PropTypes.func,
    user: PropTypes.shape(),
    hideActions: PropTypes.bool,
  };

  CommonForm.defaultProps = {
    isSaving: false,
  };

  return buildReduxForm(initialValues, schema, formName, reduxFormOptions)(CommonForm);
}

function buildReduxForm(initialValues, schema, formName, reduxFormOptions) {
  const options = {
    form: formName,
    initialValues,
    ...reduxFormOptions,
  };

  if (schema) {
    options.asyncValidate = asyncValidateBySchema.bind(null, schema);
    options.asyncBlurFields = Object.keys(schema);
  }

  return reduxForm(options);
}

function normalizeSelectFields(fields, normalizedData) {
  const isSelectField = (componentType) => componentType === 'select' || componentType === 'async-select';

  const selectFields = fields.filter((field) => isSelectField(field.componentType)).map((field) => field.name);

  const sanitizeValue = (value) => value && (value.value || value);

  const newData = normalizedData;
  selectFields.forEach((propertyName) => {
    newData[propertyName] = sanitizeValue(normalizedData[propertyName]);
  });

  return newData;
}

function normalizeCurrencyFields(fields, normalizedData) {
  const currencyFields = fields.filter((field) => field.componentType === 'currency').map((field) => field.name);

  const newData = normalizedData;
  currencyFields.forEach((currencyField) => {
    newData[currencyField] = toFloat(normalizedData[currencyField]);
  });

  return newData;
}

function normalizedPercentageFields(fields, normalizedData) {
  const percentageFields = fields.filter((field) => field.componentType === 'percentage').map((field) => field.name);

  const newData = normalizedData;
  percentageFields.forEach((percentageField) => {
    const value = normalizedData[percentageField];
    const normalizedPercentage = value ? value.replace(/,/i, '.') : value;

    newData[percentageField] = normalizedPercentage;
  });

  return newData;
}

function normalizeRedutibleFields(fields, normalizedData) {
  const fieldsToBeReduced = fields.filter(
    (field) => field.reduceOperation !== undefined && field.reduceBy !== undefined,
  );

  const newData = normalizedData;
  fieldsToBeReduced.forEach((field) => {
    let reducedValue = normalizedData[field.name];

    switch (field.reduceOperation) {
      case 'divide':
        reducedValue /= field.reduceBy;
        break;
      case 'multiply':
        reducedValue *= field.reduceBy;
        break;
      case 'subtract':
        reducedValue -= field.reduceBy;
        break;
      default:
        reducedValue += field.reduceBy;
    }

    newData[field.name] = reducedValue;
  });

  return newData;
}

function normalizeFieldsWithParentOject(fields, normalizedData) {
  const fieldsWithParentObject = fields.filter((field) => field.parentObject !== undefined);

  const newData = normalizedData;
  fieldsWithParentObject.forEach((field) => {
    if (!normalizedData[field.parentObject]) {
      newData[field.parentObject] = {};
    }

    newData[field.parentObject][field.name] = normalizedData[field.name];
    delete newData[field.name];
  });

  return newData;
}

function normalizeFieldsWithJSONValue(fields, normalizedData) {
  const fieldsWithJSONValue = fields.filter((field) => field.jsonField !== undefined);

  const newData = normalizedData;
  fieldsWithJSONValue.forEach((field) => {
    const value = normalizedData[field.name];
    newData[field.name] = JSON.parse(value)[field.jsonField];
  });

  return newData;
}

function normalizeDateFields(fields, normalizedData) {
  const dateFields = fields.filter((field) => field.componentType === 'date').map((field) => field.name);

  const newData = normalizedData;
  dateFields.forEach((dateField) => {
    newData[dateField] = formatToShortDateWithoutTz(normalizedData[dateField]);
  });

  return newData;
}
