import axios from 'axios';

export default async function (params) {
  const { data } = await axios({
    method: 'get',
    url: '/ratesheets/me',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
}
