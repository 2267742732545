import '../default-top.scss';
import './top.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Summary = ({ summaryData }) => (
  <div className="summary-container adi-trades-summary">
    <p className="summary-title">
      <FormattedMessage id="dashboard.adiTrades.title" />
    </p>
    <ul className="counters-container">
      <li className="counter-item won">{summaryData.buyCount + summaryData.sellCount}</li>
    </ul>
  </div>
);

Summary.propTypes = {
  summaryData: PropTypes.shape().isRequired,
};

export default Summary;
