import {
  REQUEST_FAIL,
  REQUEST_SUCCESS,
} from '../actions/error';

const INITIAL_STATE = {
  error: {},
  didInvalidate: true,
};

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case REQUEST_FAIL:
    return {
      error: action.error,
    };
  case REQUEST_SUCCESS:
    return {
      ...INITIAL_STATE,
    };
  default:
    return state;
  }
}
