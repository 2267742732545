import axios from 'axios';

export const actOnBehalf = async ({ tenantId, body }) => {
  const { data } = await axios({
    method: 'put',
    url: `/tenants/${tenantId}/trades`,
    headers: { 'Content-Type': 'application/json' },
    data: body,
  });

  return data;
};
