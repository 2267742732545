import './licenced-feature.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Carousel } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { sendFeedbackEmail } from '../../api/feedback/send-feedback-email';
import { MessageType, showToastMessage } from '../../containers/toast/toast';
import { Column, Row } from '../common/Flex/Flex';

export const LicencedFeature = injectIntl(({ licenceName, messageId, titleId, buttonTextId, imageUrls, intl }) => {
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const sendLicenceEnquiry = async () => {
    setIsSendingMessage(true);
    const message = `ATT COMMERCIAL: Customer ${window.location.origin} is interested on licence ${licenceName}`;
    await sendFeedbackEmail(message);
    setIsSendingMessage(false);
    showToastMessage(intl.formatMessage({ id: 'messageSent' }), MessageType.SUCCESS);
  };

  const message = (
    <Row alignItemsCenter>
      <Column alignItemsStretch className="message">
        <h3>
          <FormattedMessage id={titleId} />
        </h3>
        <p className="message-text">
          <FormattedMessage className="message-text" id={messageId} />
        </p>
        <Button disabled={isSendingMessage} type="button" onClick={sendLicenceEnquiry}>
          <FormattedMessage id={buttonTextId} />
        </Button>
      </Column>
      {imageUrls && imageUrls.length && (
        <Column>
          <Carousel>
            {imageUrls.map((imageUrl) => (
              <Carousel.Item>
                <img src={imageUrl} alt="Screenshot for feature" />
              </Carousel.Item>
            ))}
          </Carousel>
        </Column>
      )}
    </Row>
  );

  return <div className="licenced-feature-container">{message}</div>;
});

LicencedFeature.propTypes = {
  licenceName: PropTypes.string.isRequired,
  titleId: PropTypes.string,
  messageId: PropTypes.string,
  buttonTextId: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
};

LicencedFeature.defaultProps = {
  titleId: 'defaultLicencedFeatureTitle',
  messageId: 'defaultLicencedFeatureMessage',
  buttonTextId: 'defaultLicenceRequestInfo',
};
