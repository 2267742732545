import io from 'socket.io-client';

import { getTenantFromBaseUrl } from '../../config/build-api-url';

let tenantSocket = null;

export const socketEvents = {
  offersUpdated: 'offers-updated',
  rfqUpdated: 'rfq-updated',
  leaveRoom: 'leave-room',
  enterRoom: 'enter-room',
};

export default function setupSocket(socketServerUrl, baseUrl) {
  const tenant = getTenantFromBaseUrl(baseUrl);
  const mainSocket = io.connect(socketServerUrl);

  mainSocket.emit('client:add-namespace', { namespace: tenant });

  tenantSocket = io.connect(`${socketServerUrl}/${tenant}`, { forceNew: true });

  return tenantSocket;
}

export function getTenantSocket() {
  return tenantSocket;
}
