import '../default-row.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Money } from '../../../containers/money';
import { getFormattedNumberOrEmpty } from '../../../format-numbers';
import { isBondOrFrn } from '../../../api/holding/instrument-codes';

const Row = ({
  operation,
  instrumentCode,
  counterparty,
  principal,
  coupon,
  actions,
  currency,
  settlementDate,
  showSettlementDate,
}) => (
  <tr>
    <td>
      <div className={`status-bullet ${operation.toLowerCase()}`} />
    </td>
    <td>{operation}</td>
    <td>{instrumentCode}</td>
    <td>{counterparty}</td>
    <td>{currency}</td>
    <td>
      <Money value={principal} />
    </td>
    {showSettlementDate && <td>{settlementDate}</td>}
    <td>{getFormattedNumberOrEmpty({ value: coupon })}</td>
    <td className="action-cell">{renderActionButton({ actions, operation, instrumentCode })}</td>
  </tr>
);

const renderActionButton = ({ actions, operation, instrumentCode }) => {
  let action;

  if (isBondOrFrn(instrumentCode)) {
    action = actions.viewSecurityRfq;
  } else {
    action = /sell/i.test(operation) ? actions.viewSellQuotes : actions.viewBuyQuotes;
  }

  return (
    <Button className="action-button" onClick={() => action()}>
      <FormattedMessage id="dashboard.action.view" />
    </Button>
  );
};

Row.propTypes = {
  actions: PropTypes.shape().isRequired,
  counterparty: PropTypes.string.isRequired,
  coupon: PropTypes.number.isRequired,
  instrumentCode: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  principal: PropTypes.number.isRequired,
  settlementDate: PropTypes.string.isRequired,
  showSettlementDate: PropTypes.bool.isRequired,
};

export default Row;
