import buildForm from '../../common/form/form';

import fields from './fields';
import schema from './schema';
import buildInitialValues from './initial-values';
import { loadSellableCDs } from '../base/formatters';

export default function sellECDForm(onSave, intl) {
  const filter = { field: 'instrumentCode', value: 'CP' };
  const cps = fields.find((field) => field.name === 'cp') || {};
  cps.loadOptions = (data) => loadSellableCDs(data, filter, intl);

  return {
    title: 'addSellCPOption',
    value: 'sellCp',
    className: 'sell-cp-form',
    form: buildForm({
      fields,
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
