import '../../../close-buy-sell-bond-rfq-drawer.scss';

import React, { useEffect } from 'react';

import propTypes from 'prop-types';
import useSWR from 'swr';

import { listOutgoingRfqsDetails } from '../../../../../../api/holding/rfq/sell-bonds';
import { buildOutgoingRfqsDetailsUrl } from '../../../../../../api/holding/rfq/sell-bonds/list-sell-bonds-outgoing-rfqs-details';
import rooms from '../../../../../../api/socket/rooms';
import imperiumLogo from '../../../../../../assets/grey-imperium.svg';
import { Drawer, ErrorComponent } from '../../../../../../components/common';
import includeSocket, {
  socketEvents,
} from '../../../../../../components/hoc/include-socket';
import { SellerBuySellBondRfqStatus } from '../../../buy-sell-bond-rfq-status';
import { CloseSellBondRfq } from './CloseSellBondRfq';

export const CloseSellBondRfqDrawerComponent = ({
  rfqUUID,
  onChangeRfqSuccess,
  onCloseDrawer,
  emit,
  on,
  off,
  currency,
}) => {
  const {
    data: rfqDetails,
    error: listOutgoingError,
    mutate: mutateSellBondDetails,
  } = useSWR(rfqUUID && buildOutgoingRfqsDetailsUrl({ rfqUUID }), listOutgoingRfqsDetails);

  useEffect(() => {
    on(socketEvents.rfqUpdated, mutateSellBondDetails);

    return () => {
      off(socketEvents.rfqUpdated, mutateSellBondDetails);
    };
  }, []);

  const onDrawerChangeRfqSuccess = () => {
    const { notifyDomains: tenants } = rfqDetails;

    emit(socketEvents.rfqUpdated, {
      tenants,
      room: rooms.rfq,
    });

    mutateSellBondDetails();

    onChangeRfqSuccess && onChangeRfqSuccess();
  };

  const drawerContent = listOutgoingError ? (
    <ErrorComponent message="sorryThereWasAnError" showFooter={false} />
  ) : (
    <CloseSellBondRfq
      rfq={rfqDetails}
      isLoading={!rfqDetails}
      onChangeRfqSuccess={onDrawerChangeRfqSuccess}
      currency={currency}
    />
  );

  return (
    <Drawer
      titleId="quote"
      className="light close-buy-sell-bond-rfq-drawer"
      shouldCloseOnClickOutside={false}
      headerComponent={rfqDetails && <SellerBuySellBondRfqStatus rfq={rfqDetails} verbose />}
      open={!!rfqUUID}
      onClose={onCloseDrawer}
      width="35vw"
      footer={<img alt="Imperium Markets" src={imperiumLogo} />}
    >
      {drawerContent}
    </Drawer>
  );
};

CloseSellBondRfqDrawerComponent.propTypes = {
  rfqUUID: propTypes.string,
  onCloseDrawer: propTypes.func,
  on: propTypes.func,
  off: propTypes.func,
  emit: propTypes.func,
  onChangeRfqSuccess: propTypes.func,
  currency: propTypes.string.isRequired,
};

export const CloseSellBondRfqDrawer = includeSocket({ rooms: [rooms.rfq] })(CloseSellBondRfqDrawerComponent);
