import './send-quote-history.scss';

import PropTypes from 'prop-types';
import Table from 'rc-table';
import React from 'react';
import { Column, Row } from 'react-display-flex';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { SkeletonTable, Tooltip, tourElements } from '../../../../components/common';
import { DateWithTimeZone, difference, enAuShortDateFormat, formatDate } from '../../../../date';
import { couponFormat, emptyCharacter } from '../../../../format-numbers';
import { Money } from '../../../money';

const oneMillionDivider = 1000000;

export const SendQuoteHistory = ({ rfq, isLoading, maturityCode, lastTradesLabelId, isHistoryByTenor }) => {
  const { marketData, timezone, requestedBy, segmentCode } = rfq;

  const customer = isHistoryByTenor
    ? filterWinningTenor({ quotes: marketData?.customerHistoryTrades, maturityCode })
    : marketData?.customerTrades;
  const customerSegment = isHistoryByTenor
    ? filterWinningTenor({ quotes: marketData?.customerHistorySegmentTrades, maturityCode })
    : marketData?.customerSegmentTrades;

  const emptyText = maturityCode ? `No quotes found for tenor ${maturityCode}` : 'No quotes found';
  const columns = buildCustomerTableColumns({ timezone });

  return (
    <Column className="history-data" data-tour={tourElements[lastTradesLabelId]}>
      <Column>
        <Row className="history-data-header">
          <span>{requestedBy}</span>
          <span>
            - <FormattedMessage id={lastTradesLabelId} />
          </span>
        </Row>
        <SkeletonTable isLoading={isLoading}>
          <Table
            className="light-table"
            columns={columns}
            data={rfq ? customer : []}
            rowKey="rfqDate"
            emptyText={emptyText}
          />
        </SkeletonTable>
      </Column>
      <Column>
        <Row className="history-data-header">
          <span>Industry: {segmentCode === 'LOCALGOV' ? 'Local Govt.' : segmentCode}</span>
          <span>
            - <FormattedMessage id={lastTradesLabelId} />
          </span>
        </Row>
        <SkeletonTable isLoading={isLoading}>
          <Table
            className="light-table"
            columns={columns}
            data={rfq ? customerSegment : []}
            rowKey="rfqDate"
            emptyText={emptyText}
          />
        </SkeletonTable>
      </Column>
    </Column>
  );
};

SendQuoteHistory.propTypes = {
  rfq: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  maturityCode: PropTypes.string.isRequired,
  lastTradesLabelId: PropTypes.string,
  isHistoryByTenor: PropTypes.bool,
};

SendQuoteHistory.defaultProps = {
  lastTradesLabelId: 'lastTrades',
};

const buildCustomerTableColumns = ({ timezone }) => [
  {
    title: 'Date',
    dataIndex: 'rfqDate',
    key: 'rfqDate',
    render: (date) => <DateWithTimeZone timezone={timezone} value={date} />,
  },
  {
    title: `Amount (M)`,
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => (
      <Money value={amount / oneMillionDivider} minimumFractionDigits={3} maximumFractionDigits={3} />
    ),
  },
  {
    title: 'Win. Tenor',
    dataIndex: 'winningTenor',
    key: 'winningTenor',
    render: (winningTenor, { roundedWinningTenorRange, rfqSettlementDate, winningMaturityDate }) =>
      renderCustomMaturityDate({ winningTenor, roundedWinningTenorRange, rfqSettlementDate, winningMaturityDate }),
  },
  {
    title: 'Your Rate %',
    dataIndex: 'bankRate',
    key: 'rate',
    render: (rate) => (rate || rate === 0 ? <FormattedNumber {...couponFormat} value={rate} /> : emptyCharacter),
  },
  {
    title: 'Win. Rate %',
    dataIndex: 'winningRate',
    key: 'winningRate',
    render: (rate, { rfqWon }) => {
      const rateNumber = <FormattedNumber {...couponFormat} value={rate} />;

      return rfqWon ? (
        <Tooltip id="rfqWonByAdi">
          <span className="won">{rateNumber}</span>
        </Tooltip>
      ) : (
        rateNumber
      );
    },
  },
  {
    title: 'Winner',
    dataIndex: 'winnerRating',
    key: 'winnerRating',
    render: (rating, { rfqWon }) => {
      if (rfqWon) {
        return '(Your ADI)';
      }

      return rating === 'Z' ? 'Unrated' : rating;
    },
  },
];

const renderCustomMaturityDate = ({ winningTenor, roundedWinningTenorRange, rfqSettlementDate, winningMaturityDate }) =>
  roundedWinningTenorRange ? (
    <Tooltip
      id="customMaturityDate"
      tooltipComponent={renderCustomMaturityDateTooltip({
        roundedWinningTenorRange,
        rfqSettlementDate,
        winningMaturityDate,
      })}
    >
      <Row alignItemsCenter className="custom-winning-tenor">
        {roundedWinningTenorRange || winningTenor} <FaExclamationTriangle />
      </Row>
    </Tooltip>
  ) : (
    winningTenor
  );

export const renderCustomMaturityDateTooltip = ({
  roundedWinningTenorRange,
  rfqSettlementDate,
  winningMaturityDate,
}) => (
  <Column>
    <Row>{roundedWinningTenorRange === 'CUSTOM' ? 'Custom' : `${roundedWinningTenorRange} Rounded`} Maturity Date</Row>
    <Row>Date: {formatDate(winningMaturityDate, enAuShortDateFormat)}</Row>
    <Row>Days: {difference(winningMaturityDate, rfqSettlementDate)}</Row>
  </Column>
);

const filterWinningTenor = ({ quotes, maturityCode }) =>
  quotes?.filter((trade) => (trade.roundedWinningTenorRange || trade.winningTenor) === maturityCode) || [];
