import axios from 'axios';

import { buildHeaders } from '../..';

export const nominate = async (params) => {
  const headers = buildHeaders();

  const { data } = await axios({
    method: 'put',
    url: `/rfqs/${params.uuid}/quotes/${params.quoteId}/nominate`,
    headers,
    data: { hasComplianceBreach: params.hasComplianceBreach },
  });

  return data;
};
