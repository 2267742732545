import reducer, { onNextState } from '../../reducer';

export const INITIAL_STATE = {
  rfqs: {},
};

const actions = {
  UPDATE_RFQ_QUOTE: updateRfqQuote,
  RESET_RFQ_QUOTES_PAYLOAD: reset,
};

function updateRfqQuote (state, { coupon, maturityCode, uuid }) {
  const nextState = onNextState(state);
  nextState.rfqs[uuid] = nextState.rfqs[uuid] || {};
  nextState.rfqs[uuid].maturities = nextState.rfqs[uuid].maturities || {};
  nextState.rfqs[uuid].maturities[maturityCode] = coupon;
  return nextState;
}

function reset (state, { uuid }) {
  const nextState = onNextState(state);
  nextState.rfqs[uuid] = null;
  return nextState;
}

export default reducer(actions, INITIAL_STATE);
