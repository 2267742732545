import axios from 'axios';

export default async function(id, body) {
  const config = {
    method: 'put',
    url: `/trades/${id}/confirm`,
    data: body,
  };

  const { data } = await axios(config);

  return data;
}
