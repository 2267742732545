import axios from 'axios';

export default async function(uuid, cancelOption) {
  const { data } = await axios({
    method: 'put',
    url: `/rfqs/${uuid}/cancel`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: { cancelOption },
  });

  return {
    uuid,
    cancelOption,
    ...data,
  };
}
