import './panel-presenter.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Panel from './panel';

const PanelPresenter = ({ children, className, titleId, titleValues }) => (
  <div className={classnames('panel-presenter', className)}>
    <Panel>
      {renderTitle(titleId, titleValues)}
      {children}
    </Panel>
  </div>
);

const renderTitle = (titleId, titleValues) =>
  titleId && (
    <Row>
      <Col xs={12}>
        <h4 className="result-title">
          <FormattedMessage id={titleId} values={titleValues} />
        </h4>
      </Col>
    </Row>
  );

PanelPresenter.propTypes = {
  titleId: PropTypes.string,
  titleValues: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PanelPresenter;
