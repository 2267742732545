import holding from './holding';
import maturing from './maturing';
import rfq from './rfq';
import saveHolding from './save';

export default {
  ...rfq,
  holding,
  maturing,
  saveHolding,
};
