import React from 'react';

import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Money } from '../../containers/money';
import { buildDropdownFilters, Row, ValueHighlight } from '../common';

export function elements({ availableFilters, filter, isFetching, onDropdownFilterChange, intl }) {
  return buildDropdownFilters({ filter, availableFilters, onDropdownFilterChange, intl, isFetching }).map(
    (dropDownFilter, index) => (
      <Col key={index} xs={2} className="select-filter-container">
        {dropDownFilter}
      </Col>
    ),
  );
}

export function renderSummary({ isFetching, summary }) {
  return (
    <Row className="counter-results" alignItemsCenter contentEnd>
      <ValueHighlight label="filterResultsValue" isFetching={isFetching}>
        <Money value={summary.sum} short />
      </ValueHighlight>
      <ValueHighlight label="issuers" isFetching={isFetching}>
        <FormattedMessage
          tagName="span"
          id="holding.filter-investments-number-short"
          values={{ length: summary.count }}
        />
      </ValueHighlight>
    </Row>
  );
}
