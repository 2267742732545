import * as Yup from 'yup';

import { addBusinessDays, formatToShortDate } from '../../../../../../date';

export const minParcel = 500000;
export const thereafterDenominations = 50000;

export const validationSchema = ({ sellAll, totalHolding, intl }) => {
  const requiredMessage = intl.formatMessage({ id: 'required' });
  let quantityToSell = Yup.number()
    .required(requiredMessage)
    .transform((_value, originalValue) => Number(originalValue.replace(/,/g, '')));

  if (!sellAll) {
    quantityToSell = quantityToSell
      .test(
        'minParcel',
        intl.formatMessage({ id: 'min-validation' }, { min: minParcel }),
        (value) => value >= minParcel,
      )
      .test(
        'thereafterDenominations',
        intl.formatMessage({ id: 'multiple-validation' }, { value: thereafterDenominations }),
        (value) => value % thereafterDenominations === 0,
      )
      .max(totalHolding, intl.formatMessage({ id: 'max-validation' }, { max: totalHolding }));
  }

  return Yup.object().shape({
    quantityToSell,
    safeCustodyCode: Yup.string().nullable().required(requiredMessage),
    issuerCodes: Yup.array(Yup.string()).required(requiredMessage).min(1, requiredMessage),
    settlementDate: Yup.string().required(requiredMessage),
  });
};

export const initialValues = {
  quantityToSell: '',
  safeCustodyCode: '',
  issuerCodes: [],
  settlementDate: formatToShortDate(addBusinessDays({ tPlusDays: 2 })),
};
