import './unsolicited-ratesheet-details.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import { Column } from 'react-display-flex';
import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSettings } from '../../../../../../actions/session-selector';
import { DateWithTimeZone, Drawer } from '../../../../../../components/common';
import { collapseMotion } from '../../../../../../components/common/css-motions';
import { couponFormat } from '../../../../../../format-numbers';
import { Money } from '../../../../../money';
import { Tenors } from '../../../../../Tenors/Tenors';
import { UnsolicitedRatesheetDetailsCurrentRatesheet } from './UnsolicitedRatesheetDetailsCurrentRatesheet';

const expandIcon = ({ isActive }) => (isActive ? <FaChevronCircleUp /> : <FaChevronCircleDown />);

export const UnsolicitedRatesheetDetailsComponent = injectIntl(
  ({
    customerCurrentRatesheet,
    onClose,
    selectedCustomer,
    unsolicitedRatesheetMaxBankAmount,
    onAddOnBehalfConfirm,
    onRatesheetUpdate,
    isUnsolicitedRatesheetLoading,
    onSendBespokeRatesheets,
    intl,
  }) => {
    const [selectedTenor, setSelectedTenor] = useState(undefined);

    return (
      <Drawer
        contentAriaLabel="unsolicited ratesheet details"
        className="unsolicited-ratesheet-details"
        open={!!selectedCustomer}
        onClose={() => {
          onClose();
          setSelectedTenor(undefined);
        }}
        titleId="unsolicitedRatesheetDetails"
        width="40vw"
      >
        <Column className="unsolicited-ratesheet-details-content">
          <UnsolicitedRatesheetDetailsCurrentRatesheet
            customerCurrentRatesheet={customerCurrentRatesheet}
            selectedCustomer={selectedCustomer}
            unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
            onAddOnBehalfConfirm={onAddOnBehalfConfirm}
            onRatesheetUpdate={onRatesheetUpdate}
            isUnsolicitedRatesheetLoading={isUnsolicitedRatesheetLoading}
            selectedTenor={selectedTenor}
            setSelectedTenor={setSelectedTenor}
            onSendBespokeRatesheets={({ investorTenantId }) => {
              onSendBespokeRatesheets({ investorTenantId });
              onClose();
            }}
          />
          {selectedCustomer?.trades?.length ? <FormattedMessage id="createdTrades" tagName="h3" /> : null}
          <section aria-label="created trades">
            <Collapse aria-label="test" openMotion={collapseMotion} expandIcon={expandIcon}>
              {selectedCustomer?.trades?.map((trade, index) => (
                <Panel
                  header={
                    <dl>
                      <Column>
                        <dt>
                          <FormattedMessage id="tenor" />
                        </dt>
                        <dd>{trade.tenor}</dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="principal" />
                        </dt>
                        <dd>
                          <Money value={trade.principal} />
                        </dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="createdAt" />
                        </dt>
                        <dd>
                          <DateWithTimeZone dateStyle="short" timeStyle="short" value={trade.createdAt} />
                        </dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="coupon" />
                        </dt>
                        <dd>
                          <FormattedNumber {...couponFormat} value={trade.bankRate} />
                        </dd>
                      </Column>
                      <Column>
                        <dt>
                          <FormattedMessage id="unsolicitedRatesheetType" />
                        </dt>
                        <dd>
                          <FormattedMessage id={trade.unsolicitedRatesheetType} />
                        </dd>
                      </Column>
                    </dl>
                  }
                  key={`${trade.unsolicitedRatesheetEntityId}${index}`}
                >
                  <dl>
                    <Column>
                      <dt>
                        <FormattedMessage id="interestPaid" />
                      </dt>
                      <dd>
                        <FormattedMessage id={`interestDescription.${trade.interestPaid}`} />
                      </dd>
                    </Column>
                    <Column>
                      <dt>
                        <FormattedMessage id="holdingMaturityDate" />
                      </dt>
                      <dd>
                        <DateWithTimeZone value={trade.maturityDate} />
                      </dd>
                    </Column>
                    {trade.bankUserId && (
                      <Column>
                        <dt>
                          <FormattedMessage id="unsolicitedRatesheetOnBehalf" />
                        </dt>
                        <dd>
                          <FormattedMessage id="yes" />
                        </dd>
                      </Column>
                    )}
                  </dl>
                  <Column>
                    <FormattedMessage tagName="h4" id="ratesheet" />
                    <Tenors selectedTenor={trade.tenor} readOnly ratesheet={trade.ratesheet} status="accepted" />
                  </Column>
                </Panel>
              ))}
            </Collapse>
          </section>
        </Column>
      </Drawer>
    );
  },
);

UnsolicitedRatesheetDetailsComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddOnBehalfConfirm: PropTypes.func,
  onRatesheetUpdate: PropTypes.func,
  onSendBespokeRatesheets: PropTypes.func,
  isUnsolicitedRatesheetLoading: PropTypes.bool,
  selectedCustomer: PropTypes.shape(),
  customerCurrentRatesheet: PropTypes.shape({
    type: PropTypes.string,
    ratesheet: PropTypes.shape(),
  }),
};

UnsolicitedRatesheetDetailsComponent.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => {
  const tenantSettings = getSettings(state);

  return {
    unsolicitedRatesheetMaxBankAmount: tenantSettings && tenantSettings.unsolicitedRatesheetMaxBankAmount,
  };
};

export const UnsolicitedRatesheetDetails = connect(mapStateToProps)(UnsolicitedRatesheetDetailsComponent);
