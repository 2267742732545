import { createActionThunk } from 'redux-thunk-actions';

import authenticate from '../../api/login/authenticate';
import userStore from '../../user-store';
import { actions } from './actions';

export const authenticationAction = createActionThunk(actions.authenticate, (params) => authenticate(params));

export const authenticationReducer = {
  [authenticationAction.STARTED]: (state) => ({
    ...state,
    authenticating: true,
  }),
  [authenticationAction.SUCCEEDED]: (state, action) => {
    if (action.payload.token) {
      const { token, status, twoFactorAuthEnabled } = action.payload;

      userStore.setToken(token);

      return {
        ...state,
        pending: status === 'pending',
        twoFactorAuthEnabled,
        token,
      };
    }

    return {
      ...state,
      error: action.payload.error_message,
      statusCode: action.payload.status_code,
    };
  },
  [authenticationAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [authenticationAction.ENDED]: (state) => ({
    ...state,
    authenticating: false,
    error: null,
    statusCode: null,
  }),
};
