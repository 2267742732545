window.$(function () {
  window.$(".mdl-textfield input, .mdl-textfield select").each(function (i, e) {
    if (this.value) {
      window.$(this).closest(".mdl-textfield").addClass("is-dirty");
    }
  });
});

window
  .$(document)
  .on(
    "keyup change blur input",
    ".mdl-textfield input, .mdl-textfield select",
    function (e) {
      checkDirty(e);
    }
  );

function checkDirty(e) {
  if (e.currentTarget.value && e.currentTarget.value.length > 0) {
    e.currentTarget.parentElement.classList.add("is-dirty");
  } else {
    e.currentTarget.parentElement.classList.remove("is-dirty");
  }
}

window.$(document).on("focus", ".mdl-textfield input", function () {
  this.parentElement.classList.add("is-focused");

  if (window.$("#errorMessage").text().length > 0) {
    window.$("#emailField").addClass("is-invalid");
    window.$("#passwordField").addClass("is-invalid");
  }
});

window.$(document).on("blur", ".mdl-textfield input", function () {
  this.parentElement.classList.remove("is-focused");
});
