import { handleActions } from 'redux-actions';

import { outstandingOffersReducer } from './outstanding-offers';

export { getOutstandingOffersAction } from './outstanding-offers';

const initialState = {
  isFetching: false,
  statusCode: null,
  list: [],
};

const combinedReducers = {
  ...outstandingOffersReducer,
};

export const offers = handleActions(combinedReducers, initialState);
