import {
  FETCH_TRADES_REQUEST,
  FETCH_TRADES_SUCCESS,
  FETCH_TRADES_FAILURE,
} from '../actions/trades';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: true,
  data: null,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRADES_REQUEST:
      return { ...INITIAL_STATE, isFetching: true, didInvalidate: false };
    case FETCH_TRADES_SUCCESS:
      return { ...INITIAL_STATE, isFetching: false, didInvalidate: false, data: action.payload };
    case FETCH_TRADES_FAILURE:
      return { ...INITIAL_STATE, isFetching: false, didInvalidate: true, error: action.error };
    default:
      return state;
  }
};
