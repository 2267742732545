import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormControl, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { ConfirmDialog, PercentageInput } from '../../../components/common';
import { DialogHeader } from './DialogHeader';

const dialogProperties = {
  titleId: 'oneDayRolloverTitle',
  confirmId: 'confirm',
  cancelId: 'cancel',
  hasCancel: true,
};

const initialState = {
  coupon: undefined,
  note: undefined,
  reference: undefined,
  bankReference: undefined,
};

export const OneDayRolloverDialog = ({ show, isConfirming, onConfirm, onCancel, tradeSelected }) => {
  const [oneDayRolloverPayload, setOneDayRolloverPayload] = useState({ ...initialState });

  return (
    <ConfirmDialog
      {...dialogProperties}
      onCancel={onCancel}
      onConfirm={() => onConfirm(oneDayRolloverPayload)}
      show={show}
      isConfirming={isConfirming}
      modalClassName="bank-dashboard-dialog"
      disabled={[undefined, ''].includes(oneDayRolloverPayload.coupon)}
    >
      <DialogHeader tradeSelected={tradeSelected} />
      <FormGroup>
        <label htmlFor="coupon">
          <FormattedMessage id="coupon" />
        </label>
        <PercentageInput
          id="coupon"
          autoFocus
          decimalPrecision={4}
          min={-100}
          max={100}
          value={oneDayRolloverPayload.coupon}
          onChange={(e) => {
            if (!e.target) {
              return;
            }

            const coupon = e.target.value;

            setOneDayRolloverPayload((prevState) => ({
              ...prevState,
              coupon,
            }));
          }}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="bank-reference">
          <FormattedMessage id="holdingBankReference" />
        </label>
        <FormControl
          id="bank-reference"
          type="text"
          onChange={(e) => {
            const bankReference = e.target.value;

            setOneDayRolloverPayload((prevState) => ({
              ...prevState,
              bankReference,
            }));
          }}
        />
      </FormGroup>
    </ConfirmDialog>
  );
};

OneDayRolloverDialog.propTypes = {
  tradeSelected: PropTypes.shape().isRequired,
  isConfirming: PropTypes.bool,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

OneDayRolloverDialog.defaultProps = {
  show: false,
  isConfirming: false,
};
