import validRatingTypes from './rating-types';
import { isLongRating } from './rating-type';

export default function({ trades, issuers, counterpartyCompliances, asOf, longTermComplianceOnly }) {
  const amountInvested = calculateAmountInvested(trades);
  const compliancesByIssuer = buildCompliancesByIssuer(issuers, counterpartyCompliances);

  validateIssuersComplianceRules(compliancesByIssuer);

  const issuersResult = issuers.reduce((result, issuer) => {
    const { long, short } = compliancesByIssuer.find((compliance) => compliance.id === issuer.id);
    const issuerResult = buildIssuerResult(
      issuer,
      amountInvested,
      long.compliance,
      short.compliance,
      longTermComplianceOnly,
    );
    const tradesFromBankingGroup = trades.filter(({ bankingGroupId }) => issuer.bankingGroupId === bankingGroupId);

    tradesFromBankingGroup.forEach((trade) => updateAmounts(issuerResult, trade, asOf, longTermComplianceOnly));

    result.push(issuerResult);

    return result;
  }, []);

  const isCompliant = issuersResult.every((item) => item.isCompliant);

  return {
    amountInvested,
    tradesCount: trades.length,
    issuers: issuersResult,
    isCompliant,
  };
}

function buildCompliancesByIssuer(issuers, counterpartyCompliances) {
  return issuers.map(({ code, id, longRating, shortRating }) => ({
    id,
    code,
    long: {
      rating: longRating,
      compliance: findCompliance(counterpartyCompliances, validRatingTypes.long, longRating),
    },
    short: {
      rating: shortRating,
      compliance: findCompliance(counterpartyCompliances, validRatingTypes.short, shortRating),
    },
  }));
}

function calculateAmountInvested(trades) {
  return trades.reduce((total, trade) => total + trade.principal, 0.0);
}

function findCompliance(counterpartyCompliances, validRatingType, issuerRating) {
  return counterpartyCompliances.find(
    (compliance) => compliance.ratingType === validRatingType && compliance.rating === issuerRating,
  );
}

function buildIssuerResult(issuer, amountInvested, longCompliance, shortCompliance, longTermComplianceOnly) {
  return {
    code: issuer.code,
    name: issuer.name,
    shortName: issuer.shortName,
    get amountInvested() {
      return this.long.amountInvested + this.short.amountInvested;
    },
    get investmentSharePct() {
      return calculateInvestmentSharePct(amountInvested, this);
    },
    get isCompliant() {
      return this.long.isCompliant && this.short.isCompliant;
    },
    get tradesCount() {
      return this.long.tradesCount + this.short.tradesCount;
    },
    long: {
      amountInvested: 0.0,
      maxLimitPct: longCompliance.maxLimit,
      rating: longCompliance.rating,
      tradesCount: 0,
      get amountAvailable() {
        return calculateAmountAvailable(amountInvested, this);
      },
      get investmentSharePct() {
        return calculateInvestmentSharePct(amountInvested, this);
      },
      get isCompliant() {
        return this.investmentSharePct <= this.maxLimitPct;
      },
    },
    short: {
      amountInvested: 0.0,
      maxLimitPct: shortCompliance.maxLimit,
      rating: shortCompliance.rating,
      tradesCount: 0,
      get amountAvailable() {
        return calculateAmountAvailable(amountInvested, this);
      },
      get investmentSharePct() {
        return calculateInvestmentSharePct(amountInvested, this);
      },
      get isCompliant() {
        return this.investmentSharePct <= this.maxLimitPct;
      },
    },
  };
}

function calculateAmountAvailable(amountInvested, data) {
  return (data.maxLimitPct / 100) * amountInvested - data.amountInvested;
}

function calculateInvestmentSharePct(amountInvested, data) {
  return (data.amountInvested / amountInvested) * 100;
}

function updateAmounts(issuerResult, trade, asOf, longTermComplianceOnly) {
  /* eslint-disable no-param-reassign*/
  if (longTermComplianceOnly || isLongRating(trade, asOf)) {
    issuerResult.long.amountInvested += trade.principal;
    issuerResult.long.tradesCount++;
  } else {
    issuerResult.short.amountInvested += trade.principal;
    issuerResult.short.tradesCount++;
  }
  /* eslint-enable no-param-reassign*/
  return issuerResult;
}

function validateIssuersComplianceRules(compliancesByIssuer) {
  const nonExistentErrorMessage = checkNonexistentRules(compliancesByIssuer);
  const issuersWithoutRatingsErrorMessage = checkIssuersWithoutRatings(compliancesByIssuer);

  if (!nonExistentErrorMessage && !issuersWithoutRatingsErrorMessage) {
    return;
  }
}

function checkNonexistentRules(compliancesByIssuer) {
  const getNonexistentCompliances = (compliances, ratingType) =>
    compliances
      .filter((compliance) => !compliance[ratingType].compliance && compliance[ratingType].rating)
      .map((compliance) => compliance[ratingType].rating);
  const nonexistentShortRating = getNonexistentCompliances(compliancesByIssuer, 'short');
  const nonexistentLongRating = getNonexistentCompliances(compliancesByIssuer, 'long');

  if (!nonexistentShortRating.length && !nonexistentLongRating.length) {
    return '';
  }

  return createNonexistentCompliancesErrorMessage(nonexistentShortRating, nonexistentLongRating);
}

function checkIssuersWithoutRatings(compliancesByIssuer) {
  const issuersWithoutRatings = (compliances, ratingType) =>
    compliances.filter((compliance) => !compliance[ratingType].rating).map(({ code }) => code);
  const issuersWithoutShortRatings = issuersWithoutRatings(compliancesByIssuer, 'short');
  const issuersWithoutLongRatings = issuersWithoutRatings(compliancesByIssuer, 'long');

  if (!issuersWithoutShortRatings.length && !issuersWithoutLongRatings.length) {
    return '';
  }

  return createIssuersWithoutRatingsErrorMessage(issuersWithoutShortRatings, issuersWithoutLongRatings);
}

function createIssuersWithoutRatingsErrorMessage(issuersWithoutShortRating, issuersWithoutLongRating) {
  return { missingRatings: { short: issuersWithoutShortRating, long: issuersWithoutLongRating } };
}

function createNonexistentCompliancesErrorMessage(nonexistentShortRating, nonexistentLongRating) {
  return { missingRules: { short: nonexistentShortRating, long: nonexistentLongRating } };
}
