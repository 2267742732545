import axios from 'axios';

export default async function(trade, endpoint) {
  const { data } = await axios({
    method: 'post',
    url: `/trades/${endpoint}`,
    data: trade,
  });

  return data;
}
