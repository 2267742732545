import BaseFields from '../base/fields';

export default [
  {
    name: 'cashId',
    label: 'holdingCash',
    componentType: 'async-select',
    jsonField: 'id',
  },
  {
    ...BaseFields.purchaseDate,
    name: 'operationDate',
    label: 'balanceUpdateDate',
    disableWeekends: false,
    disableHolidays: false,
  },
  {
    name: 'principal',
    label: 'principal',
    componentType: 'currency',
  },
  {
    ...BaseFields.coupon,
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
