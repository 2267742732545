import {
  addYears,
  enAuShortDateFormat,
  formatDate,
  isValidDate,
  newDate,
  toDateWithZeroTime,
} from '../../date';

const dateValidationCondition = (date) => !isValidDate(date);

export const maturityValidationCondition = (value) => isNaN(value) || value > 365 * 5;

export function asAtValidator(ctx, options, date, callback) {
  if (date === undefined || date === null) {
    return callback();
  }

  if (dateValidationCondition(date)) {
    return callback(null, { validator: 'date' });
  }

  return callback();
}

export function maturityValidator(ctx, options, value, callback) {
  if (value === undefined || value === null) {
    return callback();
  }

  if (maturityValidationCondition(value)) {
    return callback(null, { validator: 'maturity' });
  }

  return callback();
}

export function purchaseValidator(ctx, options, value, callback) {
  if (value === undefined || value === null) {
    return callback();
  }

  const validator = options.errorMsg || 'purchase-greater-than-today';

  const purchaseDate = toDateWithZeroTime(value);
  const currentDate = toDateWithZeroTime(new Date());

  if (purchaseDate > currentDate) {
    return callback(null, { validator });
  }

  return callback();
}

export function maturityDateValidator(ctx, options, value, callback) {
  if (value === undefined || value === null) {
    return callback();
  }

  const maturityDate = toDateWithZeroTime(value);
  const currentDate = toDateWithZeroTime(new Date());

  if (maturityDate < currentDate) {
    return callback(null, { validator: 'maturity-date-lesser-than-today' });
  }

  const maxRangeMaturityDate = addYears(currentDate, 5);

  if (maturityDate > maxRangeMaturityDate) {
    return callback(null, { validator: 'maturity-greather-than-five-years' });
  }

  return callback();
}

export function afterJSONDateValidator({ obj: form }, options, value, callback) {
  const { afterDateFormField, afterDateFieldKey } = options;
  if (!value || !afterDateFormField || !afterDateFieldKey || !form) {
    return callback();
  }

  try {
    const today = newDate();
    const currentFieldDate = toDateWithZeroTime(value);
    const afterDateJSONField = JSON.parse(form[afterDateFormField]);
    const afterDate = toDateWithZeroTime(afterDateJSONField[afterDateFieldKey]);

    if (currentFieldDate.isBefore(afterDate) || currentFieldDate.isAfter(today)) {
      return callback(null, {
        validator: {
          id: 'date-interval-validation',
          values: {
            before: formatDate(today, enAuShortDateFormat),
            after: formatDate(afterDate, enAuShortDateFormat),
          },
        },
      });
    }
  } catch (e) {
    return callback();
  }

  return callback();
}

export function dateInsideIntervalValidator(ctx, options, value, callback) {
  const { beforeDateField, afterDateField } = options;

  if (!value || !beforeDateField || !afterDateField || !ctx.obj) {
    return callback();
  }

  const before = ctx.obj[beforeDateField] && toDateWithZeroTime(ctx.obj[beforeDateField]);
  const now = toDateWithZeroTime(value);
  const after = ctx.obj[afterDateField] && toDateWithZeroTime(ctx.obj[afterDateField]);

  if (now.isAfter(before) || now.isBefore(after)) {
    return callback(null, {
      validator: {
        id: 'date-interval-validation',
        values: {
          before: formatDate(before, enAuShortDateFormat),
          after: formatDate(after, enAuShortDateFormat),
        },
      },
    });
  }

  return callback();
}
