import './header-filters.scss';

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as counterpartyActions from '../../actions/compliances/counterparty';
import * as creditActions from '../../actions/compliances/credit';
import * as maturityActions from '../../actions/compliances/maturity';
import * as holdingActions from '../../actions/holding/holding';
import { tenantCurrencies as tenantCurrenciesSelector } from '../../actions/holding/portfolio-currency-tenant-selectors';
import * as portfolioActions from '../../actions/portfolio';
import arrowDownIcon from '../../assets/icons/common/arrow_down_icon.png';
import arrowUpIcon from '../../assets/icons/common/arrow_up_icon.png';
import { withSearchParams } from '../../components/hoc/with-router-properties';
import { areSame, formatToShortDateWithoutTz, newDate } from '../../date';
import { Column, DatePicker, renderSelect, Row, tourElements } from '../common';

const Filters = ({ tenantCurrencies, actions, hideAsAt, searchParams, tenantDomain }) => {
  const date = searchParams.get('date') || new Date();

  const [asAtDate, setAsAtDate] = useState(newDate(date));
  const [selectedCurrency, setSelectedCurrency] = useState(tenantCurrencies.currentTenant.currency);
  const [selectedTenant, setSelectedTenant] = useState(tenantCurrencies.currentTenant.domain);

  const currencies = [...new Set(tenantCurrencies.tenants.map((tenant) => tenant.currency))].map((currency) => ({
    value: currency,
    label: currency,
  }));

  const tenants = tenantCurrencies.tenants
    .filter(({ currency }) => currency === selectedCurrency)
    .map((tenant) => ({ value: tenant.domain, label: tenant.name }));

  const showTenantSelector = !(currencies.length === 1 && tenants.length === 1);

  const renderCustomArrow = ({ isOpen }) => (
    <img alt="" src={isOpen ? arrowUpIcon : arrowDownIcon} className="arrow-icon" />
  );

  const redirectToSelectedTenant = ({ newDate, tenant }) => {
    const { href, search } = window.location;

    if (tenant === tenantDomain) {
      return;
    }

    const redirectTo = href.replace(tenantCurrencies.currentTenant.domain, tenant).replace(search, '');

    window.location.href = `${redirectTo}?date=${newDate}`;
  };

  const onCurrencyChange = (_, value) => {
    setSelectedCurrency(value);
    setSelectedTenant(null);

    const filteredTenants = tenantCurrencies.tenants.filter(({ currency }) => currency === value);

    if (filteredTenants.length > 1) {
      return;
    }
    const [newTenant] = filteredTenants;
    newTenant && setSelectedTenant(newTenant.domain);
  };

  const onTenantChange = (_, tenant) =>
    redirectToSelectedTenant({
      newDate: formatToShortDateWithoutTz(asAtDate),
      tenant,
    });

  const updatePortfolio = (changedDate) => {
    const formattedDate = formatToShortDateWithoutTz(newDate(changedDate));

    ['portfolio', 'holding', 'counterparty', 'credit', 'maturity'].forEach((action) =>
      actions[action].updateSelectedDate(formattedDate),
    );
  };

  const currentTenantName = tenants.length
    ? tenants.find((tenant) => tenant.value === selectedTenant)?.label
    : selectedTenant;

  return (
    <Row className="row header-filters">
      <Column className="currency-filter">
        {currencies.length > 1 ? (
          renderSelect({
            name: 'status',
            label: 'currency',
            isSearchable: true,
            arrowRenderer: renderCustomArrow,
            defaultValue: selectedCurrency,
            onDropdownFilterChange: onCurrencyChange,
            options: currencies,
            className: 'filter-item item',
          })
        ) : (
          <div className="item-empty">
            <FormattedMessage id="currency" />
            <p className="selected-label">{selectedCurrency}</p>
          </div>
        )}
      </Column>

      <Column className="tenant-filter">
        {showTenantSelector ? (
          renderSelect({
            name: 'status',
            label: 'tenant',
            isSearchable: true,
            defaultValue: selectedTenant,
            arrowRenderer: renderCustomArrow,
            onDropdownFilterChange: onTenantChange,
            options: tenants,
            className: 'filter-item item',
          })
        ) : (
          <div className="item-empty">
            <FormattedMessage id="tenant" />
            <p className="selected-label">{currentTenantName}</p>
          </div>
        )}
      </Column>

      {!hideAsAt && (
        <Column className="as-at-filter" data-tour={tourElements.asAt}>
          <DatePicker
            name="reports.rfqsHistory.fromDate"
            labelId="portfolio.asAt"
            value={asAtDate}
            onChange={(value) => {
              if (areSame(value, asAtDate)) return;

              setAsAtDate(value);

              updatePortfolio(value);
            }}
          />
        </Column>
      )}
    </Row>
  );
};

Filters.propTypes = {
  tenantCurrencies: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  hideAsAt: PropTypes.bool,
};

Filters.defaultProps = {
  hideAsAt: false,
};

const mapStateToProps = (state) => ({
  tenantCurrencies: tenantCurrenciesSelector(state),
  currency: state.session.user.currency,
  tenantDomain: state.tenant,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    counterparty: bindActionCreators(counterpartyActions, dispatch),
    credit: bindActionCreators(creditActions, dispatch),
    maturity: bindActionCreators(maturityActions, dispatch),
    holding: bindActionCreators(holdingActions, dispatch),
    portfolio: bindActionCreators(portfolioActions, dispatch),
  },
});

export const HeaderFilters = compose(connect(mapStateToProps, mapDispatchToProps), withSearchParams)(Filters);
