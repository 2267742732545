import axios from 'axios';

export const buildIssuerAcceptBespokeRatesheetToIssuersUrl = (entityId) =>
  `/unsolicited-ratesheets/issuers/${entityId}/accept-bespoke-unsolicited-ratesheet`;

export async function issuerAcceptBespokeUnsolicitedRatesheet({ unsolicitedRatesheetEntityId, ...data }) {
  return axios({
    method: 'post',
    url: buildIssuerAcceptBespokeRatesheetToIssuersUrl(unsolicitedRatesheetEntityId),
    data,
  });
}
