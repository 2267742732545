import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ResponseItem } from './ResponseItem';
import { Column, Tooltip } from '../../../../components/common';
import { tradePropTypes } from './trade-prop-types';

export const RejectedResponse = ({ trade }) => {
  const {
    bids: [bid],
    users,
  } = trade.offer;

  return (
    <Column>
      {users && users.investorUser && (
        <ResponseItem
          label={<FormattedMessage id="rejectedBy" />}
          value={
            <Tooltip delayHide={2000} id="sentBy" tooltipComponent={users.investorUser.email}>
              <span>{trade.offer.users.bankUser.fullName}</span>
            </Tooltip>
          }
        />
      )}
      <ResponseItem label={<FormattedMessage id="reason" />} value={<FormattedMessage id={bid.reason} />} />
      {bid.investorMessage && bid.investorMessage.trim() && (
        <ResponseItem label={<FormattedMessage id="investorMessage" />} value={bid.investorMessage} />
      )}
    </Column>
  );
};

RejectedResponse.propTypes = { trade: tradePropTypes };
