import '../default-top.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { hasLicences } from '../../../actions/session-selector';
import { UpdateRatesheetButton } from '../../../containers/dashboard/BankDashboard/SendQuotes/UpdateRatesheet';
import { licences } from '../../common';

const Summary = ({ summary, hasIssuerTradesLicence }) => (
  <div className="summary-container incoming">
    <p className="summary-title">
      <FormattedMessage id="dashboard.incomingRfqs" />
    </p>
    <Row alignItemsBaseline>
      {!hasIssuerTradesLicence && <UpdateRatesheetButton />}
      <ul className="counters-container">
        <li className={`counter-item ${summary.pending.count ? 'highlighted-pending' : 'pending'}`}>
          {summary.pending.count}
        </li>
        <li className="counter-item ongoing">{summary.ongoing.count}</li>
        <li className="counter-item won">{summary.won.count}</li>
        <li className="counter-item lost">{summary.lost.count}</li>
        <li className="counter-item cancelled">{summary.cancelled.count}</li>
      </ul>
    </Row>
  </div>
);

Summary.propTypes = {
  summary: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  hasIssuerTradesLicence: hasLicences(licences.issuerTrades)(state),
});

export default connect(mapStateToProps)(Summary);
