import Valida from 'valida';
import BaseSchema from '../base/schema';
import { positiveValue, maxFloat } from '../../../parse-float';

export default {
  ...BaseSchema,
  coupon: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue },
    { validator: maxFloat },
  ],
  interestPaidCode: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
  ],
};
