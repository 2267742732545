import clone from 'lodash.clonedeep';

import {
  DEFAULT_SORT_COLUMN,
  issuersFetchActionPayload,
  meFetchActionPayload,
  ratesheetsFetchActionPayload,
  reinvestRequestActionPayload,
  RESET_RFQ,
  RESET_RFQ_SAVING_STATE,
  rfqDetailsFetchPayload,
  SET_OFFER_DETAILS,
  SET_RFQ_INITIAL_VALUES,
  tiers,
} from '../../../actions/holding/rfq/rfq';
import { sortBy } from '../../../sort';
import reducer, { onNextState } from '../../reducer';

export const INITIAL_STATE = {
  error: null,
  isFetching: false,
  holding: null,
  offerDetails: null,
  initialValues: null,
  ratesheets: {},
  issuers: null,
  isSaved: false,
  filter: {
    sort: DEFAULT_SORT_COLUMN,
  },
  me: null,
};

const frequentIssuersListLimit = 12;

const actions = {
  [rfqDetailsFetchPayload.REQUEST]: onRequest,
  [rfqDetailsFetchPayload.SUCCESS]: onDetailsSuccess,
  [rfqDetailsFetchPayload.FAIL]: onFail,
  [issuersFetchActionPayload.REQUEST]: onIssuersRequest,
  [issuersFetchActionPayload.SUCCESS]: onIssuersSuccess,
  [issuersFetchActionPayload.FAIL]: onIssuersFail,
  [ratesheetsFetchActionPayload.REQUEST]: onRatesheetsRequest,
  [ratesheetsFetchActionPayload.SUCCESS]: onRatesheetsSuccess,
  [ratesheetsFetchActionPayload.FAIL]: onRatesheetsFail,
  [reinvestRequestActionPayload.REQUEST]: onRequest,
  [reinvestRequestActionPayload.SUCCESS]: onReinvestSuccess,
  [reinvestRequestActionPayload.FAIL]: onFail,
  [SET_OFFER_DETAILS]: setOfferDetails,
  [SET_RFQ_INITIAL_VALUES]: setInitialValues,
  [RESET_RFQ_SAVING_STATE]: onResetSavingState,
  [RESET_RFQ]: onReset,
  UPDATE_RFQ_ISSUER_SORT: updateRfqIssuerSort,
  [meFetchActionPayload.REQUEST]: onMeRequest,
  [meFetchActionPayload.SUCCESS]: onMeSuccess,
  [meFetchActionPayload.FAIL]: onMeFail,
};

function onRequest(state) {
  return onNextState(state, {
    isFetching: true,
  });
}

function onDetailsSuccess(state, action) {
  return onNextState(state, {
    isFetching: false,
    holding: action.details,
    filter: {
      ...state.filter,
    },
  });
}

function onFail(state, action) {
  return onNextState(state, {
    isFetching: false,
    error: action.error,
  });
}

function onIssuersRequest(state) {
  return onNextState(state, {
    issuers: {
      isFetching: true,
    },
  });
}

function onIssuersSuccess(state, action) {
  const issuersList = issuersPresenter({
    issuers: action.issuers.list,
    hasCompliances: action.issuers.hasCompliances,
  });

  return onNextState(state, {
    issuers: {
      list: sortIssuersListBy(issuersList, state.filter),
      hasCompliances: action.issuers.hasCompliances,
      isFetching: false,
    },
  });
}

function onIssuersFail(state, action) {
  return onNextState(state, {
    issuers: {
      isFetching: false,
      error: action.error,
    },
  });
}

function onRatesheetsRequest(state) {
  return onNextState(state, {
    ratesheets: {
      isFetching: true,
    },
  });
}

function onRatesheetsSuccess(state, action) {
  const list = action.ratesheets.list.map((ratesheetByInstrumentCode) => {
    const normalizedRates = removeNonAvailableRates(ratesheetByInstrumentCode.ratesheet);
    const ratesheetsByType = getRatesheetByIssuerType(normalizedRates);

    return { ...ratesheetByInstrumentCode, ratesheet: ratesheetsByType };
  });

  return onNextState(state, {
    ratesheets: {
      list,
      isFetching: false,
    },
  });
}

function removeNonAvailableRates(ratesheets) {
  return ratesheets.map((ratesheet) => {
    if (ratesheet.available) {
      return ratesheet;
    }

    const emptyRates = {
      '1M': 0,
      '2M': 0,
      '3M': 0,
      '4M': 0,
      '5M': 0,
      '6M': 0,
      '7M': 0,
      '8M': 0,
      '9M': 0,
      '10M': 0,
      '11M': 0,
      '12M': 0,
    };

    return { ...ratesheet, rates: emptyRates };
  });
}

function getRatesheetByIssuerType(ratesheets) {
  const ratesheetsByIssuerType = ratesheets.map((ratesheet) => {
    const isDemo = !!ratesheet.issuer.name.match(/^DEMO /);

    return {
      ...ratesheet,
      isDemo,
    };
  });

  return ratesheetsByIssuerType;
}

function onRatesheetsFail(state, action) {
  return onNextState(state, {
    ratesheets: {
      isFetching: false,
      error: action.error,
    },
  });
}

function onMeRequest(state) {
  return onNextState(state, {
    me: {
      isFetching: true,
    },
  });
}

function onMeSuccess(state, action) {
  return onNextState(state, {
    me: {
      ...action.me,
      isFetching: false,
    },
  });
}

function onMeFail(state, action) {
  return onNextState(state, {
    ratesheets: {
      isFetching: false,
      error: action.error,
    },
  });
}

function issuersPresenter({ issuers, hasCompliances }) {
  issuers.sort(
    ({ rfqsCount: leftIssuerRfqsCount }, { rfqsCount: rightIssuerRfqsCount }) =>
      rightIssuerRfqsCount - leftIssuerRfqsCount,
  );

  return issuers.reduce((presentedIssuers, issuer) => {
    const presentedIssuer = clone(issuer);

    if (hasCompliances) {
      presentedIssuer.minimalAmountAvailable = getMinimalAmounts(issuer);
      presentedIssuer.ignoreCompliances = [
        issuer.short.maxLimitPct,
        issuer.shortCreditRating.maxLimitPct,
        issuer.long.maxLimitPct,
        issuer.longCreditRating.maxLimitPct,
      ].every((pct) => pct === 100);
    }

    /* eslint-disable no-param-reassign */
    const issuerIsMajorBank = ['ANZ', 'CBA', 'NAB', 'WBC'].includes(issuer.code);
    const issuerHasAtLeastOneRfq = issuer.rfqsCount > 0;
    const frequentListHasAvailableSlot =
      !presentedIssuers[tiers.frequent] || presentedIssuers[tiers.frequent].length < frequentIssuersListLimit;

    if (issuerIsMajorBank || (issuerHasAtLeastOneRfq && frequentListHasAvailableSlot)) {
      presentedIssuers[tiers.frequent] = (presentedIssuers[tiers.frequent] || []).concat(presentedIssuer);
    }

    presentedIssuers[issuer.tier] = presentedIssuers[issuer.tier] || [];
    presentedIssuers[issuer.tier].push(presentedIssuer);
    /* eslint-disable no-param-reassign */

    return presentedIssuers;
  }, {});
}

function getMinimalAmounts(issuer) {
  return {
    short:
      issuer.short.maxMoneyLimit !== null
        ? issuer.short.amountAvailable
        : Math.min(issuer.short.amountAvailable, issuer.shortCreditRating.amountAvailable),
    long:
      issuer.long.maxMoneyLimit !== null
        ? issuer.long.amountAvailable
        : Math.min(issuer.long.amountAvailable, issuer.longCreditRating.amountAvailable),
  };
}

function onReinvestSuccess(state) {
  return onNextState(state, {
    isFetching: false,
    isSaved: true,
  });
}

function onReset() {
  return {
    ...INITIAL_STATE,
  };
}

function onResetSavingState(state) {
  const nextState = onNextState(state);

  return {
    ...INITIAL_STATE,
    issuers: nextState.issuers,
    holding: nextState.holding,
  };
}

function setOfferDetails(state, { offerDetails }) {
  return onNextState(state, {
    offerDetails,
  });
}

function setInitialValues(state, { initialValues }) {
  return onNextState(state, {
    initialValues,
  });
}

function updateRfqIssuerSort(state, action) {
  const issuersList = state.issuers.list;
  const ratesheetIssuers = state.ratesheets.list;
  const filter = action.newFilter;

  return onNextState(state, {
    issuers: {
      ...state.issuers,
      list: sortIssuersListBy(issuersList, filter),
    },
    ratesheets: {
      ...state.ratesheets,
      list: sortRatesheetIssuersListBy(ratesheetIssuers, filter),
    },
    filter,
  });
}

function sortIssuersListBy(issuersList, filter) {
  const sort = filter && filter.sort;
  const [order, property] = sort.startsWith('-') ? ['-', sort.slice(1)] : ['', sort];
  const sortByProperty = sortBy.bind(null, property);

  const sortedIssuersList = Object.keys(issuersList)
    .map((tier) => {
      const issuers = issuersList[tier].sort(sortByProperty);
      const exposedRatingIssuers = exposeRatingParameters(issuers);

      const orderedIssuers = order ? exposedRatingIssuers.reverse() : exposedRatingIssuers;

      return { list: orderedIssuers, tier };
    })
    .reduce((accumulator, issuers) => {
      accumulator[issuers.tier] = issuers.list; // eslint-disable-line no-param-reassign

      return accumulator;
    }, {});

  return sortedIssuersList;
}

function sortRatesheetIssuersListBy(ratesheetIssuers, filter) {
  const sort = filter && filter.sort;
  const [order, property] = sort.startsWith('-') ? ['-', sort.slice(1)] : ['', sort];

  const exposedRatesheets = exposeRatesheetsParameters(ratesheetIssuers);
  const sortByProperty = sortBy.bind(null, property);
  const issuers = exposedRatesheets.sort(sortByProperty);
  const sortedIssuersList = order ? issuers : issuers.reverse();

  return sortedIssuersList;
}

function exposeRatingParameters(issuers) {
  if (!issuers.every((issuer) => issuer.minimalAmountAvailable !== undefined)) {
    return issuers;
  }

  const exposedIssuersList = issuers.map((issuer) => ({
    ...issuer,
    shortAmountAvailable: issuer.minimalAmountAvailable.short,
    longAmountAvailable: issuer.minimalAmountAvailable.long,
  }));

  return exposedIssuersList;
}

function exposeRatesheetsParameters(ratesheets) {
  const exposedRatesheets = ratesheets.map((ratesheet) => ({
    ...ratesheet,
    ...ratesheet.issuer,
    ...ratesheet.rates,
  }));

  return exposedRatesheets;
}

export default reducer(actions, INITIAL_STATE);
