import * as tradesApiClient from '../api/holding';

export const FETCH_TRADES_REQUEST = 'FETCH_TRADES_REQUEST';
export const FETCH_TRADES_SUCCESS = 'FETCH_TRADES_SUCCESS ';
export const FETCH_TRADES_FAILURE = 'FETCH_TRADES_FAILURE ';

export function fetchTrades(filter) {
  return async (dispatch) => {
    dispatch({ type: FETCH_TRADES_REQUEST });
    try {
      const { trades } = await tradesApiClient.list(filter);
      dispatch({ type: FETCH_TRADES_SUCCESS, payload: trades });
    } catch (error) {
      dispatch({
        type: FETCH_TRADES_FAILURE,
        error: error.response || error,
      });
    }
  };
}
function shouldFetchTrades({ trades }) {
  if (!trades) {
    return true;
  }

  if (trades.isFetching) {
    return false;
  }

  return trades.didInvalidate;
}

export function fetchTradesIfNeeded(filter) {
  return (dispatch, getState) => {
    if (shouldFetchTrades(getState())) {
      dispatch(fetchTrades(filter));
    }
  };
}
