import axios from 'axios';
import LogRocket from 'logrocket';

export const buildGetMeUrlKey = ({ token }) => ['/users/me', token];

export const getMe = async (url, token) => {
  const headers = getHeaders(token);

  const { data } = await axios({
    method: 'get',
    url,
    headers,
  });

  data &&
    LogRocket &&
    LogRocket.identify(data.email, {
      host: document.location.hostname,
    });

  return data;
};

function getHeaders(token) {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.authorization = token;
  }

  return headers;
}

export default async function me(params, token) {
  const headers = getHeaders((params && params.token) || token);

  const { data } = await axios({
    method: 'get',
    url: '/users/me',
    headers,
  });

  data &&
    LogRocket &&
    LogRocket.identify(data.email, {
      host: document.location.hostname,
    });

  return data;
}
