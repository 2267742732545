import { toLocaleCurrency } from '../../../parse-float';
import { Bonds } from '../../../trades';
import { localizeDate } from '../../../date';

export function loadSellables({ sellables }, { field, value }, intl) {
  if (!sellables) {
    return null;
  }

  const options = sellables ? sellables.filter((sellable) => sellable[field] === value) : [];

  return options
    .filter((option) => optionHasPositiveQuantity(option))
    .map((option) => makeSellableOption(option, intl));
}

export function loadSellableCDs({ sellables }, { field, value }, intl) {
  const options = sellables ? sellables.filter((sellable) => sellable[field] === value) : [];

  return options.filter((option) => option.principal > 0).map((option) => makeCdOption(option, intl));
}

export function loadUpdatableCash({ sellables }, { field, value }, intl) {
  const options = sellables ? sellables.filter((sellable) => sellable[field] === value) : [];

  return options.map((option) => makeCashOption(option, intl));
}

function makeSellableOption(option, intl) {
  const details = option.instrumentDetails;
  const amount = details.quantity * Bonds.price;
  const label = intl.formatMessage(
    { id: 'presenterSellableOption' },
    {
      isin: details.isin,
      issuerCode: option.issuer.code,
      maturity: localizeDate(option.maturityDate, intl.locale),
      total: toLocaleCurrency(intl.locale, amount),
    },
  );

  return (
    details && {
      value: JSON.stringify({
        id: option.rootTradeId,
        amount,
        label,
      }),
      label,
    }
  );
}

function makeCdOption(option, intl) {
  return {
    value: JSON.stringify({
      id: option.rootTradeId,
      purchaseDate: option.purchaseDate,
      amount: option.principal,
    }),
    label: intl.formatMessage(
      { id: 'presenterSellableCDOption' },
      {
        issuerCode: option.issuer.code,
        maturity: localizeDate(option.maturityDate, intl.locale),
        total: toLocaleCurrency(intl.locale, option.principal),
      },
    ),
  };
}

function makeCashOption(option, intl) {
  return {
    value: JSON.stringify({
      id: option.rootTradeId,
      amount: option.principal,
    }),
    label: intl.formatMessage(
      { id: 'presenterCashOption' },
      {
        issuerCode: option.issuer.code,
        reference: option.reference,
        total: toLocaleCurrency(intl.locale, option.principal),
      },
    ),
  };
}

function optionHasPositiveQuantity(option) {
  return option.instrumentDetails && option.instrumentDetails.quantity > 0;
}
