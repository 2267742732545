import './table.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Table as TableComponent } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import { userTenantSettings } from '../../../api/user-tenant-settings';
import { EmptyContent } from '../EmptyContent';
import Filter from '../filter';
import { Row } from '../Flex/Flex';
import { SkeletonTable } from '../Skeletons';
import Tooltip from '../tooltip';
import { TableSettingsDrawer } from './TableSettingsDrawer/TableSettingsDrawer';

export const ConfigurableTable = ({
  titleId,
  title,
  titleComponent,
  badge,
  filter,
  isFetching,
  noRecords,
  tableSettingsId,
  recordsPresenter: {
    actions,
    ariaLabel,
    columns = [],
    onSortColumnOrder,
    highlightedColumn,
    data = [],
    options,
    headerPresenter = renderTableHeader,
  },
  noRecordsMessageId = 'noRecords',
  hasError,
}) => {
  const [tableSettings, setTableSettings] = useState([]);
  const [isTableSettingsOpen, setIsTableSettingsOpen] = useState(false);
  const [filteredColumns, setFilteredColumns] = useState(filterDynamicColumns(columns));

  useEffect(() => {
    const localStorageColumns = userTenantSettings.getTableSettings(tableSettingsId, columns);
    setTableSettings(getTableColumns(columns, localStorageColumns));
  }, [columns, setTableSettings, tableSettingsId]);

  useEffect(() => {
    if (tableSettings.length > 0) {
      setFilteredColumns(filterDynamicColumns(tableSettings));
      userTenantSettings.saveTableSettings(tableSettingsId, tableSettings);
    }
  }, [tableSettings, tableSettingsId]);

  const renderRecords = () => {
    if (hasError) {
      return <EmptyContent messageId="anErrorHasOccurredPleaseTryAgain" />;
    }

    if (!isFetching && noRecords) {
      return <EmptyContent messageId={noRecordsMessageId} data-testid="no-records-message" />;
    }

    return (
      <SkeletonTable isLoading={isFetching}>
        <div className="records">
          <TableComponent aria-label={ariaLabel} responsive striped hover>
            <thead>
              <tr>{headerPresenter(filteredColumns, onSortColumnOrder, highlightedColumn, data)}</tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={`{${row.id}-${index}`} onDoubleClick={() => actions.onDoubleClick(row)}>
                  {filteredColumns.map(({ key }, filteredIndex) => {
                    const column = columns.find(({ key: columnKey }) => columnKey === key);
                    const columnKey = `${row.id}-${index}-${filteredIndex}`;

                    return column && column.presenter ? (
                      <React.Fragment key={columnKey}>{column.presenter(row, actions, options)}</React.Fragment>
                    ) : (
                      <td key={columnKey}>{row[key]}</td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </TableComponent>
        </div>
      </SkeletonTable>
    );
  };

  return (
    <div className="results-presenter">
      <div className="results panel panel-filled">
        {(titleId || title) && (
          <Row contentBetween>
            <Row className="title-container">
              <h4 className="result-title">{titleId ? <FormattedMessage id={titleId} /> : <span>{title}</span>}</h4>
              {badge}
            </Row>
            {titleComponent && <div className="pull-right">{titleComponent}</div>}
          </Row>
        )}
        <Row className="table-options">
          <div className="table-options-filter">{(filter && filter.component) || <Filter {...filter} />}</div>
          {tableSettingsId && (
            <div className="table-options-actions">
              <Tooltip id="customTable">
                <Button onClick={() => setIsTableSettingsOpen(true)}>
                  <FaBars />
                </Button>
              </Tooltip>
            </div>
          )}
        </Row>
        {renderRecords()}
      </div>
      <TableSettingsDrawer
        isOpen={isTableSettingsOpen}
        setIsTableSettingsOpen={setIsTableSettingsOpen}
        tableSettings={tableSettings}
        onSave={setTableSettings}
      />
    </div>
  );
};

ConfigurableTable.propTypes = {
  isFetching: PropTypes.bool,
  noRecords: PropTypes.bool,
  hasError: PropTypes.bool,
  title: PropTypes.string,
  titleId: PropTypes.string,
  titleComponent: PropTypes.node,
  badge: PropTypes.node,
  tableSettingsId: PropTypes.string,
  recordsPresenter: PropTypes.shape({
    actions: PropTypes.shape(),
    columns: PropTypes.arrayOf(PropTypes.shape),
    data: PropTypes.arrayOf(PropTypes.shape),
    ariaLabel: PropTypes.string,
    headerPresenter: PropTypes.func,
    highlightedColumn: PropTypes.string,
    onSortColumnOrder: PropTypes.func,
    presenter: PropTypes.func,
    options: PropTypes.shape(),
  }),
  noRecordsMessageId: PropTypes.string,
  filter: PropTypes.shape({
    filter: PropTypes.shape(),
    onDropdownFilterChange: PropTypes.func,
    availableFilters: PropTypes.shape(),
    onResetFilter: PropTypes.func,
    component: PropTypes.node,
  }),
};

export const renderTableHeader = (columns, onSortColumnOrder, highlightedColumn) =>
  columns.map(({ sort = '', key, label, values, width }) => {
    const isColumnSelected = highlightedColumn && sort && highlightedColumn.includes(sort);

    return (
      <th key={key} width={width} aria-label={label}>
        <span
          className={classNames({ 'column-link': sort }, { selected: isColumnSelected })}
          onClick={sort ? () => onSortColumnOrder({ sort }) : null}
          aria-hidden="true"
        >
          {isColumnSelected && (
            <i
              className={`glyphicon glyphicon-menu-${
                highlightedColumn && highlightedColumn.includes('-') ? 'down' : 'up'
              }`}
            />
          )}
          {label && <FormattedMessage id={label} values={values} />}
        </span>
      </th>
    );
  });

const filterDynamicColumns = (columns) => columns.filter((column) => !!column.show);

const getTableColumns = (recordsPresenterColumns, localStorageColumns) => {
  if (!localStorageColumns || !localStorageColumns.length) return recordsPresenterColumns;

  const recordsPresenterLabels = recordsPresenterColumns.map((column) => column.label);
  const localStoragePresenterLabels = localStorageColumns.map((column) => column.label);

  if (recordsPresenterLabels.length !== localStoragePresenterLabels.length) return recordsPresenterColumns;

  const localStorageContainsAllColumns = recordsPresenterLabels.every((label) =>
    localStoragePresenterLabels.includes(label),
  );

  return localStorageContainsAllColumns ? localStorageColumns : recordsPresenterColumns;
};
