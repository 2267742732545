export function request(data, params, action = this) {
  return async (dispatch, getState) => {
    const {
      actionsTypes: { REQUEST, SUCCESS, FAIL },
    } = action;
    const state = getState();

    dispatch({ type: REQUEST, ...params });

    try {
      if (action.validateData) {
        action.validateData(data, params, state);
      }

      dispatch({
        type: SUCCESS,
        ...(await action.requestAction(data, params, state)),
      });
    } catch (error) {
      dispatch({
        type: FAIL,
        error: error.response || error,
        ...params,
      });
    }
  };
}

export default function(actionName, requestAction, validateData) {
  return {
    actionsTypes: {
      REQUEST: `${actionName}_REQUEST`,
      SUCCESS: `${actionName}_SUCCESS`,
      FAIL: `${actionName}_FAIL`,
    },
    actionName,
    request,
    requestAction,
    validateData,
  };
}
