import '../default-table.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Table = ({ rows }) => (
  <div className="table-responsive table-container">
    <table className="table table-striped">
      <thead>
        <tr>
          <th />
          <th>
            <FormattedMessage id="dashboard.status" />
          </th>
          <th>
            <FormattedMessage id="dashboard.type" />
          </th>
          <th>
            <FormattedMessage id="operation" />
          </th>
          <th>
            <FormattedMessage id="dashboard.customer" />
          </th>
          <th>
            <FormattedMessage id="by" />
          </th>
          <th>
            <FormattedMessage id="currencyShort" />
          </th>
          <th>
            <FormattedMessage id="dashboard.amount" />
          </th>
          <th>
            <FormattedMessage id="dashboard.settlementDate" />
          </th>
          <th />
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  </div>
);

Table.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default Table;
