import axios from 'axios';

export async function listTenantsByIssuerTrades(params, { headers } = {}) {
  const { data } = await axios({
    method: 'get',
    url: '/issuer-trades/tenants',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    params,
  });

  return data;
}
