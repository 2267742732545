import BaseFields from '../base/fields';
import { Bonds } from '../../../trades';

export const fields = (currencySymbol) => [
  {
    name: 'operation',
    componentType: 'hidden',
  },
  {
    name: 'isin',
    label: 'holdingBonds',
    componentType: 'async-select',
    jsonField: 'id',
  },
  {
    name: 'quantityToKeep',
    label: 'customHoldingPrincipalToKeep',
    componentType: 'currency',
    step: 1000,
    reduceOperation: 'divide',
    reduceBy: Bonds.faceValue * Bonds.issuePrice,
    intlValues: { currencySymbol },
  },
  {
    name: 'sellingDate',
    label: 'holdingSellingDate',
    componentType: 'date',
  },
  {
    ...BaseFields.percentage,
    name: 'cleanPrice',
    label: 'holdingCleanPrice',
  },
  {
    ...BaseFields.percentage,
    name: 'grossPrice',
    label: 'holdingGrossPrice',
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
