import * as Yup from 'yup';

import {
  minParcel,
  thereafterDenominations,
} from '../../../rfq-nominal-values';

export const buildBuyBondCreateRfqValidationSchema = ({ intl }) => {
  const requiredMessage = intl.formatMessage({ id: 'required' });

  return Yup.object().shape({
    isin: Yup.string().required(requiredMessage),
    quantityToBuy: Yup.number()
      .required(requiredMessage)
      .transform((_value, originalValue) => Number(originalValue.replace(/,/g, '')))
      .test(
        'minParcel',
        intl.formatMessage({ id: 'min-validation' }, { min: minParcel }),
        (value) => value >= minParcel,
      )
      .test(
        'thereafterDenominations',
        intl.formatMessage({ id: 'multiple-validation' }, { value: thereafterDenominations }),
        (value) => value % thereafterDenominations === 0,
      ),
    issuerCodes: Yup.array(Yup.string()).min(1, requiredMessage).required(requiredMessage),
    settlementDate: Yup.string().required(requiredMessage),
  });
};
