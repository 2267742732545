export const fixedBondTypeDescription = 'Fixed Bond';

export const isBond = (instrumentCode) => instrumentCode === 'BOND';

export const getInstrumentCodeDescription = (instrumentCode) =>
  isBond(instrumentCode) ? fixedBondTypeDescription : instrumentCode;

export const getInstrumentCouponOrMargin = ({ instrumentCode, coupon, frnIssueMargin }) =>
  isBond(instrumentCode) ? coupon : frnIssueMargin;

export const getIsinYieldOrMarginDescription = (instrumentCode) =>
  isBond(instrumentCode) ? 'Coupon (%)' : 'Coupon Margin (bp)';
