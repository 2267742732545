import React from 'react';

import { licences } from '../../components/common';
import { LicencedFeature } from '../../components/licenced-feature/licenced-feature';

export const LicencedTradeHistory = () => {
  const imageUrls = [1, 2, 3].map((num) => `/incoming-trade-history-${num}.png`);

  return (
    <LicencedFeature
      messageId="incomingRfqHistoryFeatureMessage"
      titleId="incomingRfqHistoryFeatureTitle"
      licenceName={licences.rfqReporting}
      imageUrls={imageUrls}
    />
  );
};
