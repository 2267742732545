import React from 'react';
import { Column, Row } from 'react-display-flex';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import {
  DateWithTimeZone,
  difference,
  enAuShortDateFormat,
  formatDate,
  formatToShortDate,
  isSameDay,
} from '../../../date';
import { renderHeaders } from '../../common/records';
import Tooltip from '../../common/tooltip';

export const columns = {
  issuer: { label: 'rfqAdi' },
  receivedAt: { label: 'receivedAt' },
};

export const commonHeaderPresenter = (presenterColumns, onSortColumnOrder, highlightedColumn, quotes) => {
  const commonHeaders = renderHeaders(presenterColumns, onSortColumnOrder, highlightedColumn, quotes);
  const [{ maturities }] = quotes;

  const quotesMaturityHeaders = maturities.map((maturity) => (
    <th className="text-center" key={maturity.code}>
      <Column>
        {renderDescriptiveTenorPeriod(maturity)}
        <Row alignItemsCenter justifyContentCenter>
          <DateWithTimeZone value={maturity.date} />
          {maturity.originalDate && !isSameDay(maturity.date, maturity.originalDate) && (
            <Tooltip id={maturity.code} tooltipComponent={renderCustomMaturityDateTooltip(maturity)}>
              <FaExclamationTriangle aria-label="custom tenor date" />
            </Tooltip>
          )}
        </Row>
      </Column>
    </th>
  ));

  return commonHeaders.concat(quotesMaturityHeaders);
};

const renderDescriptiveTenorPeriod = ({ code }) => {
  const periodIdAndValues = getPeriodIdAndValues(code);

  return periodIdAndValues ? <FormattedMessage id={periodIdAndValues.id} values={periodIdAndValues.values} /> : null;
};

export const getPeriodIdAndValues = (maturityCode) => {
  const maturityCodeContent = /(\d+)(M|m|Y|y)/g.exec(maturityCode);

  return maturityCodeContent ? buildIdAndValuesFromMaturityCode(maturityCodeContent) : null;
};

const buildIdAndValuesFromMaturityCode = (maturityCode) => {
  const [, count, period] = maturityCode;

  return {
    id: period === 'M' ? 'rfqMonthCode' : 'rfqYearCode',
    values: { count },
  };
};

export const renderCustomMaturityDateTooltip = ({ code, date, originalDate, rfqEndDate }) => (
  <Column>
    <Row>Custom Maturity Date ({renderDescriptiveTenorPeriod({ code })})</Row>
    {originalDate && (
      <Row>
        Tenor {code} ({difference(originalDate, formatToShortDate(rfqEndDate))} days):{' '}
        {formatDate(originalDate, enAuShortDateFormat)}
      </Row>
    )}
    <Row>
      Current ({difference(date, formatToShortDate(rfqEndDate))} days): {formatDate(date, enAuShortDateFormat)}
    </Row>
  </Column>
);
