import axios from 'axios';

export const confirmRfq = async ({ uuid, note, reference, bankReference }) => {
  const { data } = await axios({
    method: 'put',
    url: `/rfqs/${uuid}/quotes/confirm`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      note,
      reference,
      bankReference,
    },
  });

  return {
    uuid,
    ...data,
  };
};

export default confirmRfq;
