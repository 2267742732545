import buildForm from '../../common/form/form';
import { fields } from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

export default function buyBondsForm(onSave, currencySymbol) {
  return {
    title: 'addBuyBondsOption',
    value: 'buyBonds',
    className: 'buy-bonds-form',
    form: buildForm({
      fields: fields(currencySymbol),
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
