import './toggle.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Toggle = ({ isToggled, onToggle, toggledTextId, notToggledTextId }) => (
  <div className={getClasses(isToggled)} onClick={onToggle}>
    <FormattedMessage id={isToggled ? toggledTextId : notToggledTextId} />
    <div className="toggle-caret" />
  </div>
);

function getClasses(isToggled) {
  return classNames('toggle-button', { 'is-toggled': isToggled });
}

Toggle.propTypes = {
  isToggled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  toggledTextId: PropTypes.string,
  notToggledTextId: PropTypes.string,
};

Toggle.defaultProps = {
  toggledTextId: 'confirmed',
  notToggledTextId: 'unconfirmed',
};

export default Toggle;
