import axios from 'axios';

import { fetchCounterpartyCompliances } from '../compliances/counterparty';
import fetchCreditRatings from '../compliances/credit';

export const getRfqIssuers = async (params) => {
  const { data: issuers } = await axios({
    method: 'get',
    url: '/issuers',
    headers: { 'Content-Type': 'application/json' },
    params,
  });

  const localCurrencyIssuers = issuers.list.filter((issuer) => issuer.currencies.includes(params.currency));

  try {
    const issuersWithCounterpartyRatings = await includeCounterpartyRatings(localCurrencyIssuers);
    const issuersWithCreditRatings = await includeCreditRatings(issuersWithCounterpartyRatings);
    const list = await includeTiers(issuersWithCreditRatings);

    return { list, hasCompliances: true };
  } catch (error) {
    const list = await includeTiers(localCurrencyIssuers);

    return { list, hasCompliances: false };
  }
};

export default getRfqIssuers;

async function includeCounterpartyRatings(list) {
  const counterpartyResult = await fetchCounterpartyCompliances();

  return list.map((originalIssuer) => {
    const counterpartyIssuer = counterpartyResult.list.find(
      (issuer) => issuer.bankingGroupId === originalIssuer.bankingGroupId,
    );

    if (!counterpartyIssuer) {
      return originalIssuer;
    }

    return {
      ...originalIssuer,
      long: counterpartyIssuer.long || 0,
      short: counterpartyIssuer.short || 0,
    };
  });
}

async function includeCreditRatings(issuers) {
  const creditRatings = await fetchCreditRatings();
  const hasShortTerm = !creditRatings.longTermComplianceOnly;

  const creditRatingIssuers = issuers.map((issuer) => {
    const longCreditRating =
      creditRatings.long.ratings.find(
        (creditRating) => creditRating.rating === removeTrailingSymbol(issuer.longRating),
      ) || 0;
    const shortCreditRating =
      (hasShortTerm &&
        creditRatings.short.ratings.find(
          (creditRating) => creditRating.rating === removeTrailingSymbol(issuer.shortRating),
        )) ||
      0;

    return {
      ...issuer,
      longCreditRating,
      shortCreditRating,
    };
  });

  return creditRatingIssuers;
}

function removeTrailingSymbol(rating) {
  return rating ? rating.trim().replace(/[+-]+$/, '') : rating;
}

async function includeTiers(issuers) {
  const {
    data: { list: tiers },
  } = await axios({
    method: 'get',
    url: '/issuers/tiers',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const getTierByIssuer = (issuer) => tiers.find((tier) => tier.issuerId === issuer.id);

  return issuers.filter(getTierByIssuer).map((issuer) => ({
    ...issuer,
    tier: getTierByIssuer(issuer).tier,
  }));
}
