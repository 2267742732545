const fields = {
  allocation: {
    name: 'allocationCode',
    label: 'holdingAllocationCode',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.allocationCodes,
    allowCreate: true,
    isClearable: false,
  },
  purchaseDate: {
    name: 'purchaseDate',
    label: 'holdingPurchaseDate',
    componentType: 'date',
    disableWeekends: false,
    disableHolidays: false,
  },
  issuer: {
    name: 'issuerId',
    label: 'holdingIssuer',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.issuers,
  },
  percentage: {
    componentType: 'percentage',
    prefix: '',
    decimalPrecision: 4,
    min: 0,
  },
  coupon: {
    componentType: 'percentage',
    prefix: '',
    decimalPrecision: 4,
    name: 'coupon',
    label: 'holdingCoupon',
    max: 100,
    min: -100,
  },
  reference: {
    name: 'reference',
    label: 'holdingReference',
    componentType: 'text',
  },
  bankReference: {
    name: 'bankReference',
    label: 'holdingBankReference',
    componentType: 'text',
  },
  note: {
    name: 'note',
    label: 'holdingNote',
    componentType: 'text',
    componentClass: 'textarea',
    rows: '3',
  },
};

export default fields;
