import './filter.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { areAnyHoldingFiltersApplied } from '../../actions/helper/filter';
import { maturityValidationCondition } from '../../actions/helper/validation';
import { Money } from '../../containers/money';
import { InputSearch, Tooltip, ValueHighlight } from '../common';
import { buildDropdownFilters, renderSelect } from '../common/dropdown-filter';
import { getImperiumSelectValue } from '../common/Select/Select';

const Filter = ({
  summary,
  filter,
  onSearchChange,
  onDropdownFilterChange,
  shouldShowLoadingOnType,
  onSearchClear,
  availableFilters,
  onResetFilter,
  onSearchError,
  validateOnType,
  intl,
}) => (
  <div className="holding-results-filter">
    {renderDropdownFilters(
      filter,
      availableFilters,
      onDropdownFilterChange,
      onResetFilter,
      onSearchClear,
      shouldShowLoadingOnType,
      onSearchChange,
      onSearchError,
      validateOnType,
      intl,
      summary.general && summary.general.isFetching,
    )}
    {renderSummaryRow(summary)}
  </div>
);

Filter.propTypes = {
  summary: PropTypes.shape().isRequired,
  filter: PropTypes.shape(),
  availableFilters: PropTypes.shape().isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onDropdownFilterChange: PropTypes.func.isRequired,
  shouldShowLoadingOnType: PropTypes.func,
  onSearchClear: PropTypes.func,
  onResetFilter: PropTypes.func,
  onSearchError: PropTypes.func,
  validateOnType: PropTypes.func,
};

const renderSummaryRow = ({ isFetching, sum, count = 0, way = 0, wam = 0 }) => (
  <div className="filter-value-row">
    <ValueHighlight label="filterResultsValue" isFetching={isFetching}>
      <Money value={sum} short />
      <span className="investments-count">
        (
        <FormattedMessage tagName="span" id="holding.filter-investments-number-short" values={{ length: count }} />)
      </span>
    </ValueHighlight>

    <ValueHighlight label="way" isFetching={isFetching}>
      <FormattedMessage tagName="span" id="holding.filter-investments-number-short" values={{ length: way }} />
    </ValueHighlight>

    <ValueHighlight label="wam" isFetching={isFetching}>
      <FormattedMessage tagName="span" id="holding.filter-investments-number-short" values={{ length: wam }} />
    </ValueHighlight>
  </div>
);

const renderDropdownFilters = (
  filter,
  availableFilters,
  onDropdownFilterChange,
  onResetFilter,
  onSearchClear,
  shouldShowLoadingOnType,
  onSearchChange,
  onSearchError,
  validateOnType,
  intl,
  isLoading,
) => {
  const maturityOptions = buildMaturityOptions(filter, intl);
  const maturityValue = filter && filter.maturity >= 0 && filter.maturity < 365 * 5 ? filter.maturity : 'all';

  return (
    <div className="dropdown-filters-container">
      {buildDropdownFilters({ filter, availableFilters, onDropdownFilterChange, intl, isLoading }).map(
        (dropDownFilter, key) => (
          <div key={key} className="select-filter-container">
            {dropDownFilter}
          </div>
        ),
      )}
      <div className="select-filter-container">
        {renderSelect({
          name: 'maturity',
          label: 'filterResultsMaturing',
          onDropdownFilterChange,
          options: maturityOptions,
          value: getImperiumSelectValue({ options: maturityOptions, value: maturityValue }),
        })}
      </div>
      <div>
        <InputSearch
          shouldShowLoadingOnType={shouldShowLoadingOnType}
          validateOnType={validateOnType}
          isLoading={false}
          defaultValue={filter && filter.searchText}
          onChange={(event) => onFilterSearchChange(event, onSearchChange)}
          onClear={onSearchClear}
          labelId="issuer"
        />
      </div>
      <div>
        <Tooltip id="cleanFilterTooltip">
          <Button
            disabled={!areAnyHoldingFiltersApplied(filter)}
            className="reset-filter-button"
            onClick={onResetFilter}
          >
            <FormattedMessage id="reset" />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

function onFilterSearchChange(event, onSearchChange) {
  onSearchChange(event.target.value);
}

const buildMaturityOptions = (filter, intl) => {
  const maturityOptions = ['All', 0, 1, 5, 10, 30, 365];
  if (
    filter &&
    filter.maturity &&
    !maturityValidationCondition(filter.maturity) &&
    !maturityOptions.includes(filter.maturity)
  ) {
    maturityOptions.push(filter.maturity);
    maturityOptions.sort((x, y) => x > y);
  }

  const allMaturityOption = {
    label: intl.formatMessage({ id: 'all' }),
    value: 'all',
  };

  return [allMaturityOption].concat(
    maturityOptions.slice(1).map((day) => ({
      label: intl.formatMessage({ id: 'filter.maturing' }, { days: day }),
      value: day,
    })),
  );
};

export default injectIntl(Filter);
