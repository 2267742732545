import Valida from 'valida';
import BaseSchema from '../base/schema';
import { positiveValue, maxFloat } from '../../../parse-float';
import { maturityDateValidator, dateInsideIntervalValidator } from '../../../actions/helper/validation';

export default {
  ...BaseSchema,
  coupon: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue },
    { validator: maxFloat },
  ],
  callableDate: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.date },
    {
      validator: dateInsideIntervalValidator,
      beforeDateField: 'maturityDate',
      afterDateField: 'purchaseDate',
    },
  ],
  maturityDate: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.date },
    { validator: maturityDateValidator },
  ],
  interestCompoundCode: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  interestPaidCode: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
};
