import './radio-group.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { RadioItem } from './RadioItem';

export const RadioGroup = ({ selectedItem, items, onChange, disabled }) => (
  <div className={classNames('radio-group', { 'is-disabled': disabled })}>
    {items.map(({ value, labelId }) => (
      <RadioItem
        key={value}
        className={selectedItem === value ? 'active' : undefined}
        value={value}
        labelId={labelId}
        onChange={onChange}
      />
    ))}
  </div>
);

RadioGroup.propTypes = {
  selectedItem: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      labelId: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};
