import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from '../actions/upload';


export const INITIAL_STATE = {
  isCreating: false,
  isFetching: false,
  upload: null,
  error: null,
};

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case UPLOAD_FILE_REQUEST:
    return {
      ...state,
      isCreating: true,
      error: null,
    };
  case UPLOAD_FILE_SUCCESS:
    return {
      ...state,
      isCreating: false,
      error: null,
      upload: action.upload,
    };
  case UPLOAD_FILE_FAIL:
    return {
      ...state,
      isCreating: false,
      error: action.error,
    };
  case UPDATE_REQUEST:
    return {
      ...state,
      isFetching: true,
      error: null,
    };
  case UPDATE_SUCCESS:
    return {
      ...state,
      isFetching: false,
      error: null,
      upload: action.upload,
    };
  case UPDATE_FAIL:
    return {
      ...state,
      isFetching: false,
      error: action.error,
    };
  default:
    return state;
  }
}
