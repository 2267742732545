/* eslint-disable react/prop-types */
import './validated-field.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputMask from 'react-maskedinput';
import { Field } from 'redux-form';

import { normalizeNumber } from '../../parse-float';
import autoFocusComponent from '../hoc/auto-focus-component';
import CurrencyInput from './currency-input';
import { DatePicker } from './DatePicker/DatePicker';
import { PercentageInput } from './percentage-input';
import { Select } from './Select/Select';

const ControlledFormControl = autoFocusComponent(FormControl);

const ValidatedField = ({ intl, ...props }) => {
  const nextProps = { field: props, intl };

  return <Field name={props.name} component={renderValidationField} {...nextProps} />;
};

ValidatedField.propTypes = {
  name: PropTypes.string.isRequired,
  componentType: PropTypes.string.isRequired,
  label: PropTypes.string,
  mask: PropTypes.string,
  options: PropTypes.array,
};

const renderer = {
  text: renderInputText,
  date: renderDate,
  select: renderSelect,
  'async-select': renderAsyncSelect,
  mask: renderMaskedInput,
  currency: renderCurrencyInput,
  number: renderNumberInput,
  percentage: renderPercentageInput,
  hidden: renderHiddenInput,
};

const renderValidationField = (props) => {
  const { field, intl } = props;
  const { componentType, ...fieldProperties } = field;

  delete fieldProperties.intlValues;

  if (componentType === 'hidden') {
    return renderer.hidden(props, fieldProperties, intl);
  }

  return (
    <div className="validated-input">
      <FormGroup validationState={getValidationState(props.meta)}>
        {renderLabel(field)}
        {renderer[componentType](props, fieldProperties, intl)}
        {renderErrorBlock(props)}
      </FormGroup>
    </div>
  );
};

const getValidationState = (meta) => (hasBeenTouched(meta) && meta.error ? 'error' : null);

const hasBeenTouched = ({ touched, dirty }) => touched || dirty;

const renderErrorBlock = ({ meta }) => (
  <HelpBlock>{hasBeenTouched(meta) && meta.error && <FormattedMessage id={meta.error} {...meta.error} />}</HelpBlock>
);

function renderInputText(props, field) {
  return <ControlledFormControl type="text" {...field} {...props.input} />;
}

function renderDate(props) {
  const {
    input: { onBlur, ...input },
    field: { disableWeekends = true, disableHolidays = true },
  } = props;

  return (
    <DatePicker
      hasError={props.meta.dirty && props.meta.error && props.meta.error.length > 0}
      disableWeekends={disableWeekends}
      disableHolidays={disableHolidays}
      className="form-control"
      onBlur={(event) => {
        if (!event?.target.value) {
          onBlur(undefined);
        }
      }}
      {...input}
    />
  );
}

function renderSelect(props, { options, autofocus }) {
  return renderSelectElement(props, buildOptions(options, props.intl), {
    isClearable: false,
    autofocus,
  });
}

function renderAsyncSelect(props, { options }) {
  return renderSelectElement(props, options, {
    isLoading: options === null || !options?.length,
    isClearable: false,
  });
}

function renderSelectElement(props, options, override) {
  const {
    input: { value, onChange },
    field,
    name,
  } = props;
  const { allowCreate } = field;

  const selectProps = {
    name,
    options,
    onChange,
    allowCreate,
    value,
    ...override,
  };

  return <Select {...selectProps} />;
}

function renderMaskedInput(props, { mask }) {
  return <InputMask className="form-control" {...props.input} mask={mask} />;
}

function renderCurrencyInput(props, field) {
  return <CurrencyInput {...props.input} {...field} />;
}

function renderPercentageInput(props, field) {
  return <PercentageInput {...props.input} {...field} />;
}

function renderNumberInput(props, field) {
  const {
    input: { value, onChange, onBlur },
  } = props;
  const normalizedValue = normalizeNumber(value, field);

  return <FormControl onChange={onChange} onBlur={() => onBlur(normalizedValue)} value={normalizedValue} {...field} />;
}

function renderHiddenInput(props) {
  const {
    input: { value, name },
  } = props;

  return <input type="hidden" value={value} name={name} />;
}

const buildOptions = (options, intl) =>
  options.map((option) => ({
    label: intl.formatMessage({ id: option.label }),
    value: option.code.toLowerCase(),
  }));

const renderLabel = ({ label, intlValues }) =>
  label && (
    <label>
      <FormattedMessage id={label} values={intlValues || {}} />
    </label>
  );

export default injectIntl(ValidatedField);
