import axios from 'axios';

export default async function (cash) {
  const { data } = await axios({
    method: 'put',
    url: `/trades/cash/${cash.cashId}/balance`,
    data: cash,
  });

  return data;
}
