import './app.scss';
import 'bootstrap-sass/assets/javascripts/bootstrap';
import 'rc-collapse/assets/index.css';
import 'rc-drawer/assets/index.css';
import 'react-toggle/style.css';

import React, { Component, Suspense } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import * as sessionActions from '../../actions/session';
import {
  getLatestTermDepositRatesheetUpdatedAt,
  getRootPath,
  hasLicences,
  isAdmin,
} from '../../actions/session-selector';
import { licences } from '../../api/licences';
import { hasTenantCapitalValueEnabled } from '../../components/common/Protect/user-rules';
import errorsContent from '../../components/error/errors-content';
import { withLocation, withNavigate, withSearchParams } from '../../components/hoc/with-router-properties';
import { Content, Header, Navigation, Notification } from '../../components/template';
import { sameDayAsToday } from '../../date';
import { logoutAction } from '../../ducks/authentication/logout';
import { routes } from '../../routes';
import userStore from '../../user-store';
import { MessageType, showToastMessage } from '../toast/toast';
import { AppContextProvider } from './AppContext';
import { ErrorBoundary } from './ErrorBoundary';
import { getCapitalValueMenuItem, getMenuItems } from './menu-items';
import { setAppTitle } from './set-app-title';

const mapStateToProps = (state) => ({
  session: state.session,
  rootPath: getRootPath(state),
  tenant: state.tenant,
  login: state.login,
  latestTermDepositRatesheetUpdatedAt: getLatestTermDepositRatesheetUpdatedAt(state),
  hasAutoQuotingLicence: hasLicences(licences.autoQuoting)(state),
  canUpdateRatesheet: isAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    session: bindActionCreators(sessionActions, dispatch),
    logout: bindActionCreators(logoutAction, dispatch),
  },
});

export class App extends Component {
  constructor(props) {
    super(props);

    this.headerItems = [
      { label: 'changePassword', onClick: this.onChangePassword },
      { label: 'logout', onClick: this.onLogout },
    ];

    this.state = {
      passwordExpiredChecked: false,
      ratesheetOutOfDateChecked: false,
    };
  }

  componentDidMount() {
    const { actions, searchParams } = this.props;

    if (!this.isPublicRoute()) {
      actions.session.fetchSessionIfNeeded(Object.fromEntries([...searchParams]), userStore.getToken());
    }
  }

  componentDidUpdate(previousProps) {
    this.handleNextPropsFilter(previousProps.session, this.props.session);
    this.showPasswordChangeToastIfNeeded();
    this.updateAppTitle(previousProps);
  }

  showPasswordChangeToastIfNeeded() {
    const passwordExpireWarningDays = 7;
    const { session, intl } = this.props;

    const { passwordExpiredChecked } = this.state;
    if (passwordExpiredChecked) return;

    const passwordExpiresDays = session && session.user && session.user.passwordExpiresDays;
    if (passwordExpiresDays && passwordExpiresDays <= passwordExpireWarningDays) {
      const messageId = passwordExpiresDays > 0 ? 'passwordWillExpire' : 'passwordIsExpired';
      const passwordChangeText = intl.formatMessage(
        { id: messageId },
        { days: passwordExpiresDays, a: (chunks) => `<a href="/admin/user/change-password">${chunks}</a>` },
      );
      showToastMessage(passwordChangeText, MessageType.WARNING, { timeOut: 30 * 1000 });
      this.setState({ passwordExpiredChecked: true });
    }
  }

  updateAppTitle = ({ session: prevSession }) => {
    const { session: currentSession } = this.props;

    if ((prevSession.user && currentSession.user) || currentSession.user === null) {
      return;
    }

    const { user } = currentSession;

    const currentUserTenantByDomain = user.tenants?.find(({ domain }) => domain === this.props.tenant);

    if (!currentUserTenantByDomain) {
      return;
    }

    setAppTitle({
      title: currentUserTenantByDomain.name || currentUserTenantByDomain.domain,
    });
  };

  onLogout = async (event) => {
    event.preventDefault();

    await this.props.actions.logout();

    // using window location to send the user to the login page clears the redux state
    window.location.href = routes.public.login;
  };

  onChangePassword = () => {
    this.props.navigate(routes.user.changePassword);
  };

  onUpdateRatesheet = () => {
    this.hideRatesheetOutOfDate();
    this.props.navigate(routes.ratesheets.update);
  };

  onMenuItemSelect = (tab) => {
    this.props.navigate(tab);
    document.body.classList.toggle('nav-toggle');
  };

  onNotificationDismiss = () => {
    this.props.actions.session.cleanNotification();
  };

  getValidQuery = (params) => {
    const unwantedParams = ['query'];

    const validParams = {
      ...params,
    };

    unwantedParams.forEach((property) => {
      delete validParams[property];
    });

    return validParams;
  };

  getActiveKey = () => {
    const { location } = this.props;

    return location?.pathname.length > 1 ? location.pathname : '/';
  };

  updateLocation(params) {
    const { setSearchParams } = this.props;

    const search = this.getValidQuery(params);

    setSearchParams(search);

    this.props.actions.session.resetLocationQuery();
  }

  buildDefaultMessages() {
    const { intl } = this.props;

    return {
      success: intl.formatMessage({ id: 'savedSuccessfully' }),
      danger: intl.formatMessage({ id: 'anErrorHasOccurred' }),
    };
  }

  handleNextPropsFilter({ query: previousQuery }, { query: nextPropsQuery }) {
    if (!nextPropsQuery || isEqual(previousQuery, nextPropsQuery)) {
      return;
    }

    this.updateLocation(nextPropsQuery);
  }

  renderNotification() {
    const { session } = this.props;
    const defaultMessages = this.buildDefaultMessages();

    return (
      session &&
      session.notificationId && (
        <Notification
          bsStyle={session.bsStyle}
          isVisible={session.notificationId.length > 0}
          onNotificationDismiss={this.onNotificationDismiss}
        >
          <FormattedMessage
            defaultMessage={defaultMessages[session.bsStyle]}
            id={session.notificationId}
            values={session.notificationValues}
          />
        </Notification>
      )
    );
  }

  buildMenuItemsAccordingToUser = () => {
    const {
      session: { user },
      rootPath,
    } = this.props;

    const navItems = getMenuItems(rootPath);

    if (!hasTenantCapitalValueEnabled(user)) {
      return navItems;
    }

    const adminItems = navItems.find(({ name }) => name === 'Menu');

    adminItems.items.push(getCapitalValueMenuItem());

    return navItems;
  };

  hideRatesheetOutOfDate = () => this.setState({ ratesheetOutOfDateChecked: true });

  shouldShowUpdateRatesheetNotification = ({ location }) =>
    !['/admin/user/change-password', '/ratesheets/update'].includes(location.pathname);

  showUpdateRatesheetNotification = ({ intl, messageId }) => {
    const updateRatesheetMessage = intl.formatMessage({ id: messageId });
    showToastMessage(updateRatesheetMessage, MessageType.WARNING, { timeOut: 30 * 1000 });
    this.hideRatesheetOutOfDate();
  };

  renderContentByUser() {
    const { session, latestTermDepositRatesheetUpdatedAt, location, intl } = this.props;
    const { user } = session;
    const navItems = this.buildMenuItemsAccordingToUser(user);

    return (
      <AppContextProvider>
        <div>
          <Header items={this.headerItems} user={user} />
          {this.renderNotification()}
          {this.props.hasAutoQuotingLicence &&
            this.shouldShowUpdateRatesheetNotification({ user, location }) &&
            !this.state.ratesheetOutOfDateChecked &&
            !sameDayAsToday(latestTermDepositRatesheetUpdatedAt) &&
            this.showUpdateRatesheetNotification({ intl, messageId: 'staleTermDepositRates' })}
          <Navigation
            activeKey={this.getActiveKey()}
            items={navItems}
            onMenuItemSelect={this.onMenuItemSelect}
            user={user}
            onLogout={(event) => this.onLogout(event)}
          />
          <Suspense
            fallback={
              <div className="fixed-initial-loader-container ">
                <Loader type="ball-pulse" />
              </div>
            }
          >
            <Content>
              <Outlet />
            </Content>
          </Suspense>
        </div>
      </AppContextProvider>
    );
  }

  renderContentWithoutUser() {
    if (this.isPublicRoute()) {
      return <Outlet />;
    }

    return this.renderLoader();
  }

  renderContentWithError = (error) => errorsContent[error.status];

  renderLoader = () => (
    <div className="initial-loader-container">
      <Loader type="ball-pulse" />
    </div>
  );

  isPublicRoute() {
    return false;
  }

  render() {
    const { session } = this.props;
    const { user } = session;

    return (
      <ErrorBoundary>
        <div id="main">{user ? this.renderContentByUser() : this.renderContentWithoutUser()}</div>
      </ErrorBoundary>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withLocation,
  withNavigate,
  withSearchParams,
)(App);

App.propTypes = {
  actions: PropTypes.shape().isRequired,
  canUpdateRatesheet: PropTypes.bool,
  hasAutoQuotingLicence: PropTypes.bool,
  latestTermDepositRatesheetUpdatedAt: PropTypes.string,
  session: PropTypes.shape().isRequired,
  rootPath: PropTypes.string,
};
