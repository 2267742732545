import './row.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { tradeSources } from '../../../../api/holding/codes';
import { Money } from '../../../../containers/money';
import { invertOperationForCounterparty } from '../../../../containers/rfqs/buy-sell-bond-rfqs/invert-operation-for-counterparty';
import { difference, newDate } from '../../../../date';
import { getFormattedNumberOrEmpty, rateFormat } from '../../../../format-numbers';

const shortDateFormat = 'DD/MM/YYYY';

const buildSourceLabelId = (tradeSource, onBehalf) => {
  if (tradeSource === tradeSources.addTrade && onBehalf) {
    return 'new-funds';
  }

  return tradeSource;
};

const calculateTermDays = ({ tenor, settlementDate }) => {
  if (!tenor || !settlementDate) return '-';

  return difference(tenor, settlementDate, 'days');
};

const Row = ({
  uuid,
  source,
  onBehalf,
  customer,
  date,
  settlementDate,
  status,
  cancelOption,
  currency,
  isAutoQuote,
  principal,
  assetClass,
  tenor,
  coupon,
  bankCoupon,
  basisPointFee,
  winningCoupon,
  operation,
  quotedBy,
  nextBestCoupon,
  margin,
  viewQuotesAction,
  canViewQuotes,
  isin,
  includeEmbeddedFee,
}) => {
  const presenterStatus = status === 'cancelled' && cancelOption ? cancelOption : status;
  const statusId = `dashboard.status.${presenterStatus}`;
  const getMarginValue = (value) => {
    const formattedValue = getFormattedNumberOrEmpty({ value });

    if (formattedValue > 0) {
      return `+${formattedValue}`;
    }

    return formattedValue;
  };

  const hasQuoted = coupon !== null;

  const quoteSent = bankCoupon || coupon;

  return (
    <tr className="incoming-report-row-container">
      <td>
        <FormattedMessage id={buildSourceLabelId(source, onBehalf)} />
      </td>
      <td>{customer}</td>
      <td>{assetClass}</td>
      <td className="capitalize">{invertOperationForCounterparty(operation)}</td>
      <td>{newDate(date).format(shortDateFormat)}</td>
      <td>{newDate(settlementDate).format(shortDateFormat)}</td>
      <td>{tenor ? newDate(tenor).format(shortDateFormat) : '-'}</td>
      <td>{calculateTermDays({ tenor, settlementDate })}</td>
      <td className={`status-${status}`}>{status ? <FormattedMessage id={statusId} /> : '-'}</td>
      <td>{currency}</td>
      <td>{isin || '-'}</td>
      <td>
        <Money customCurrency={currency} value={principal} />
      </td>
      <td>{isAutoQuote ? '(Auto-quoted)' : quotedBy || '-'}</td>
      <td>{getFormattedNumberOrEmpty({ assetClass, value: coupon, format: rateFormat })}</td>
      {includeEmbeddedFee && <td>{getFormattedNumberOrEmpty({ assetClass, value: quoteSent, format: rateFormat })}</td>}
      {includeEmbeddedFee && <td>{basisPointFee || '-'}</td>}
      <td>{getFormattedNumberOrEmpty({ assetClass, value: winningCoupon, format: rateFormat })}</td>
      <td>{getFormattedNumberOrEmpty({ assetClass, value: nextBestCoupon, format: rateFormat })}</td>
      <td className={classNames({ marginClassName: hasQuoted })}>{getMarginValue(hasQuoted ? margin : null)}</td>
      <td className="action-cell">{canViewQuotes && renderActionButton(viewQuotesAction, uuid)}</td>
    </tr>
  );
};

const renderActionButton = (viewQuotesAction, uuid) => (
  <Button onClick={() => viewQuotesAction(uuid)}>
    <FormattedMessage id="reports.rfqsHistory.viewQuotesButton" />
  </Button>
);

Row.propTypes = {
  uuid: PropTypes.string,
  source: PropTypes.string.isRequired,
  onBehalf: PropTypes.bool,
  customer: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  settlementDate: PropTypes.string.isRequired,
  status: PropTypes.string,
  cancelOption: PropTypes.string,
  isAutoQuote: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  currency: PropTypes.string.isRequired,
  principal: PropTypes.number.isRequired,
  assetClass: PropTypes.string.isRequired,
  tenor: PropTypes.string,
  coupon: PropTypes.number,
  bankCoupon: PropTypes.number,
  basisPointFee: PropTypes.number,
  winningCoupon: PropTypes.number,
  nextBestCoupon: PropTypes.number,
  quotedBy: PropTypes.string,
  margin: PropTypes.number,
  canViewQuotes: PropTypes.bool.isRequired,
  viewQuotesAction: PropTypes.func.isRequired,
  isin: PropTypes.string,
  operation: PropTypes.string,
  includeEmbeddedFee: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  cancelOption: null,
  tenor: null,
  coupon: null,
  winningCoupon: null,
  nextBestCoupon: null,
  quotedBy: null,
  margin: null,
};

export default Row;
