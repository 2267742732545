import '../../buy-bond-create-rfq.scss';

import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Column, Row } from 'react-display-flex';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';

import { createSellBondsRfq } from '../../../../../api/holding/rfq/sell-bonds';
import rooms from '../../../../../api/socket/rooms';
import { socketEvents } from '../../../../../api/socket/setup-socket';
import {
  ConfirmationPopover,
  DatePickerField,
  FormikCurrencyInput,
  ValidatedFormikField,
} from '../../../../../components/common';
import { FormikResetFormHandler } from '../../../../../components/common/formik/FormikResetFormHandler';
import includeSocket from '../../../../../components/hoc/include-socket';
import {
  addBusinessDays,
  DateWithTimeZone,
  formatToShortDate,
  formatToShortDateWithoutTz,
  newDate,
} from '../../../../../date';
import { sellBondRfqCreateRfqExecutionOnlyClicked, track } from '../../../../../event-tracker';
import {
  convertStringWithDelimiterToInt,
  emptyCharacter,
  toCompactCurrency,
  toNumberFormat,
} from '../../../../../format-numbers';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../toast/toast';
import { fromValueToNominalUnitQuantity } from '../../../rfq-nominal-values';
import {
  getInstrumentCodeDescription,
  getInstrumentCouponOrMargin,
  getIsinYieldOrMarginDescription,
} from '../../bond-frn-descriptions';
import { IsinsFormikField } from '../../IsinsFormikField';
import { RfqBankSelectionHasAustraclear } from '../../RfqBankSelection/RfqBankSelectionHasAustraclear';
import { buildCreateRfqExecutionOnlyValidationSchema } from './create-rfq-execution-only-schema';

export const buildCreateRfqExecutionOnlyInitialValues = () => ({
  quantityToSell: '',
  safeCustodyCode: '',
  issuerCodes: [],
  settlementDate: formatToShortDate(addBusinessDays({ tPlusDays: 2 })),
  isin: undefined,
  hasAustraclear: false,
});

export const SellBondCreateRfqExecutionOnlyComponent = ({
  emit,
  initialValues,
  onCreateRfqSuccess,
  currency,
  intl,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedIsin, setSelectedIsin] = useState(null);

  const onSubmit = async ({ quantityToSell, issuerCodes, isin, settlementDate, hasAustraclear }) => {
    setLoading(true);

    const payload = {
      executionOnly: true,
      issuerCodes,
      isin,
      settlementDate: formatToShortDateWithoutTz(settlementDate),
      hasAustraclear,
      quantityToSell: fromValueToNominalUnitQuantity({ value: quantityToSell }),
    };

    try {
      const { notifyDomains: tenants } = await createSellBondsRfq({ payload });

      emit(socketEvents.rfqUpdated, {
        tenants,
        room: rooms.rfq,
      });

      track(sellBondRfqCreateRfqExecutionOnlyClicked);

      showToastMessage(intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);

      onCreateRfqSuccess && onCreateRfqSuccess();
    } catch (error) {
      showResponseErrorMessage({ intl, error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={buildCreateRfqExecutionOnlyValidationSchema({ intl })}
      onSubmit={onSubmit}
    >
      {({ touched, errors, values, handleSubmit, resetForm }) => (
        <Form className="buy-sell-bonds-create-rfq-form">
          <FormikResetFormHandler
            resetForm={() => {
              resetForm();
              setSelectedIsin(null);
            }}
            dependencies={initialValues}
          />
          <Column className="form-group-item">
            <IsinsFormikField onChange={setSelectedIsin} />
          </Column>
          <dl className="highlight">
            <div>
              <FormattedMessage tagName="dt" id="holdingIssuer" />
              <dd>{selectedIsin ? selectedIsin.issuerName : emptyCharacter}</dd>
            </div>
            <div>
              <FormattedMessage tagName="dt" id="type" />
              <dd>{selectedIsin ? getInstrumentCodeDescription(selectedIsin.instrumentCode) : emptyCharacter}</dd>
            </div>
            <div>
              <FormattedMessage tagName="dt" id="holdingIsins" />
              <dd>{selectedIsin ? selectedIsin.isin : emptyCharacter}</dd>
            </div>
            <div>
              <FormattedMessage tagName="dt" id="holdingMaturityDate" />
              <dd>{selectedIsin ? <DateWithTimeZone value={selectedIsin.maturity} /> : emptyCharacter}</dd>
            </div>

            {selectedIsin && (
              <div>
                <dt>{getIsinYieldOrMarginDescription(selectedIsin.instrumentCode)}</dt>
                <dd>
                  {toNumberFormat({
                    value: getInstrumentCouponOrMargin(selectedIsin),
                  })}
                </dd>
              </div>
            )}
          </dl>
          <Row className="form-group-item">
            <ValidatedFormikField
              id="quantityToSell"
              name="quantityToSell"
              labelId="faceValueToSell"
              labelParams={{ currency }}
              data-testid="quantityToSell"
              value={values.quantityToSell}
              component={FormikCurrencyInput}
              touched={touched}
              errors={errors}
            />
            <ValidatedFormikField
              id="settlementDate"
              name="settlementDate"
              labelId="standardSettlementDate"
              data-testid="settlementDate"
              value={values.settlementDate}
              component={(props) => (
                <DatePickerField
                  {...props}
                  roundedInputStyle
                  options={{
                    startDate: newDate().toDate(),
                  }}
                />
              )}
              touched={touched}
              errors={errors}
            />
          </Row>
          <RfqBankSelectionHasAustraclear
            austraclearLabelId="safeCustodyWithAustraclear"
            defaultSelectedIssuers={values.issuerCodes.length ? values.issuerCodes : initialValues.issuerCodes}
          />
          <Column>
            <ConfirmationPopover
              className="btn-solid-primary"
              onConfirm={handleSubmit}
              isConfirmingFromOutside={loading}
              confirmationMessageId="sellBondCreateRfqConfirmation"
              confirmationMessageValues={{
                value: toCompactCurrency({
                  value: convertStringWithDelimiterToInt(values.quantityToSell),
                }),
                issuerCodes: values.issuerCodes,
                issuerCodesLength: values.issuerCodes.length,
              }}
              buttonLabelId="buyBondCreateRfq"
              popoverClass="light drawer-popover"
            />
          </Column>
        </Form>
      )}
    </Formik>
  );
};

SellBondCreateRfqExecutionOnlyComponent.propTypes = {
  onCreateRfqSuccess: PropTypes.func,
  currency: PropTypes.string.isRequired,
  initialValues: PropTypes.shape(),
  emit: PropTypes.func,
};

SellBondCreateRfqExecutionOnlyComponent.defaultProps = {
  initialValues: buildCreateRfqExecutionOnlyInitialValues(),
};

export const SellBondCreateRfqExecutionOnly = compose(
  includeSocket({ rooms: [rooms.rfq] }),
  injectIntl,
)(SellBondCreateRfqExecutionOnlyComponent);
