import axios from 'axios';

export async function list(params) {
  const { data } = await axios({
    method: 'get',
    url: '/trades',
    headers: { 'Content-Type': 'application/json' },
    params,
  });

  return data;
}
