export const roles = {
  bank: { weight: 0 },
  viewer: { weight: 1 },
  finance: { weight: 2 },
  admin: { weight: 3 },
  business: { weight: 4 },
  superUser: { weight: 5 },
};

export const roleWeight = (role = {}) => (roles[role] ? roles[role].weight : (role && role.weight) || 0);
