import './pie-chart.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactFlot from 'react-flot';

import pallete from '../../theme/helpers/pallete.json';

const PieChart = ({ id, series }) => {
  const flotOptions = {
    series: {
      pie: {
        innerRadius: 0.3,
        show: true,
        radius: 0.75,
        label: {
          formatter: formatLabel,
          radius: 1,
          show: true,
        },
        stroke: {
          width: 0.5,
          color: pallete['very-dark-grey'],
        },
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div id={`${id}-chart-wrapper`} className="pie-chart">
      <ReactFlot id={`${id}-chart`} options={flotOptions} data={series} />
    </div>
  );
};

PieChart.propTypes = {
  id: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
};

function formatLabel(label, series) {
  return `<div>${label}<br/>${series.percent.toFixed(2)}%</div>`;
}

export default PieChart;
