import axios from 'axios';

export default async function(ratesheets) {
  const { data } = await axios({
    method: 'put',
    url: '/ratesheets',
    data: ratesheets,
  });

  return data;
}
