import './item-selector.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Column } from '..';
import Item from './Item';

export default class ItemSelector extends PureComponent {
  selectAll = 'selectAll';

  unselectAll = 'unselectAll';

  onChange = (value) => {
    this.props.onChange(value);
  };

  onSelectAllClick = () => {
    const { selectedItems, items } = this.props;
    const isEverythingSelected = selectedItems.length === items.length;

    this.props.onSelectAllClick(isEverythingSelected);
  };

  renderSelectAllItem = () => {
    const { selectedItems, items, title, name } = this.props;
    const isEverythingSelected = selectedItems.length === items.length;

    return (
      <Item
        id={`${isEverythingSelected ? this.unselectAll : this.selectAll}`}
        className="item-selector__item--select-all"
        key={this.selectAll}
        value={this.selectAll}
        onChange={this.onSelectAllClick}
        checked={isEverythingSelected}
        title={title}
        name={name}
      />
    );
  };

  render() {
    const { selectedItems, items } = this.props;

    return (
      <div className="item-selector">
        {this.renderSelectAllItem()}
        <Column className="item-selector-body">
          {items.map(({ id, value }) => (
            <Item id={id} key={id} value={value} onChange={this.onChange} checked={selectedItems.includes(value)} />
          ))}
        </Column>
      </div>
    );
  }
}

ItemSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onChange: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
};
