import React from 'react';

import { Col } from 'react-bootstrap';

import { Money } from '../../containers/money';
import { buildDropdownFilters, Row, ValueHighlight } from '../common';

export function elements({ availableFilters, filter, isFetching: isLoading, onDropdownFilterChange, intl }) {
  return buildDropdownFilters({ filter, availableFilters, onDropdownFilterChange, intl, isLoading }).map(
    (dropDownFilter, index) => (
      <Col key={index} xs={2} className="select-filter-container">
        {dropDownFilter}
      </Col>
    ),
  );
}

export function renderSummary({ isFetching, summary }) {
  return (
    <Row className="counter-results" alignItemsCenter contentEnd>
      <ValueHighlight label="filterResultsValue" isFetching={isFetching}>
        <Money value={summary.sum} short />
      </ValueHighlight>
    </Row>
  );
}
