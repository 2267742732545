import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { isAdmin as isAdminSelector } from '../../../actions/session-selector';
import { Column, SummaryItem } from '../../../components/common';
import { formatDate } from '../../../date';
import { Money } from '../../money';

const TotalizerComponent = ({ holding, isAdmin, changeView }) =>
  holding.summary.capitalBasis ? (
    <Column className="summary-item">
      <button type="button" disabled={!isAdmin} onClick={changeView} className="action-button">
        <SummaryItem
          highlight={isAdmin}
          top={<FormattedMessage id="capitalValue" />}
          middle={<Money value={holding.summary.capitalBasis.value} short fraction={2} />}
          bottom={
            <FormattedMessage
              id="portfolio.asAtTotalizer"
              tagName="span"
              values={{ date: formatDate(holding.summary.capitalBasis.asOfDate, 'DD/MM/YYYY') }}
            />
          }
          isLoading={holding.isFetching}
        />
      </button>
    </Column>
  ) : null;

TotalizerComponent.propTypes = {
  holding: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool,
  changeView: PropTypes.func,
};

const mapStateToProps = (state) => ({
  holding: state.holding,
  isAdmin: isAdminSelector(state),
});

export const CapitalValueTotalizer = connect(mapStateToProps)(TotalizerComponent);
