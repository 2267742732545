import axios from 'axios';
import { formatToShortDate, newDate } from '../../../date';

export const buildListOutgoingSecuritiesRfqsUrl = ({ from, to } = { from: newDate() }) => [
  '/rfqs/securities',
  from && formatToShortDate(from),
  to && formatToShortDate(to),
];

export const listOutgoingSecuritiesRfqs = async (url, from, to) => {
  const params = {};

  if (from) {
    params.from = from;
  }

  if (to) {
    params.to = to;
  }

  const { data } = await axios({
    method: 'get',
    url,
    params,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
};
