import './incoming-rfqs.scss';

import React, { Component } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as enterQuotesActions from '../../actions/holding/rfq/enter-quotes';
import * as enterQuotesPayloadActions from '../../actions/holding/rfq/enter-quotes-payload';
import * as ratesheetActions from '../../actions/ratesheets';
import { getLatestTermDepositRatesheetUpdatedAt, hasLicences, isAdmin } from '../../actions/session-selector';
import rooms from '../../api/socket/rooms';
import capitalize from '../../capitalize';
import {
  ConfirmationPopover,
  EmptyContent,
  Header,
  Loadable,
  PanelCollapsible,
  ResultsPresenter,
  Row as FlexRow,
  SkeletonTable,
  SummaryHighlight,
  Tooltip,
} from '../../components/common';
import { licences as availableLicences } from '../../components/common/Protect';
import errorHandler from '../../components/hoc/error-handler';
import includeLocalReducer from '../../components/hoc/include-local-reducer';
import includeSocket from '../../components/hoc/include-socket';
import withConfirmDialog from '../../components/hoc/with-confirm-dialog';
import { withPreventNavigation } from '../../components/hoc/with-prevent-navigation';
import { withParams, withSearchParams } from '../../components/hoc/with-router-properties';
import * as recordsPresenter from '../../components/holding/enter-quotes/records-presenter';
import {
  DateWithTimeZone,
  difference,
  enAuShortDateFormat,
  formatDate,
  formatToShortDate,
  newDate,
  sameDayAsToday,
} from '../../date';
import { getBankRatesheet } from '../../ducks/ratesheet/selectors';
import { eventAutoFillClicked, track } from '../../event-tracker';
import { timeProps } from '../../format-date';
import { percentual } from '../../format-numbers';
import { getRfqStatus, rfqStatuses } from '../../reducers/holding/rfq/enter-quotes';
import enterQuotesPayloadReducer from '../../reducers/holding/rfq/enter-quotes-payload';
import { UpdateRatesheetDialog } from '../dashboard/BankDashboard/UpdateRatesheetDialog';
import { getMoneySymbol } from '../money';
import { MessageType, showToastMessage } from '../toast/toast';

const mapStateToProps = (state) => ({
  enterQuotes: state.enterQuotes,
  tenant: state.tenant,
  hasDashboardLicence: hasLicences(availableLicences.dashboard)(state),
  latestTermDepositRatesheetUpdatedAt: getLatestTermDepositRatesheetUpdatedAt(state),
  userIsAdmin: isAdmin(state),
  bankRatesheet: getBankRatesheet(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ratesheets: bindActionCreators(ratesheetActions, dispatch),
    enterQuotes: bindActionCreators(enterQuotesActions, dispatch),
  },
});

class IncomingRfqsComponent extends Component {
  state = {
    showUpdateRatesheetDialog: false,
    ratesheetUpdatedByDialog: undefined,
  };

  componentDidMount() {
    const params = this.buildParams();
    this.props.actions.enterQuotes.fetchRfqs(params);
    this.props.on('server:rfq-updated', () => {
      this.props.actions.enterQuotes.fetchRfqs(params);
    });
    this.props.actions.ratesheets.fetchRatesheets();
  }

  componentDidUpdate({ enterQuotes }) {
    const { actions, enterQuotes: currentEnterQuotes } = this.props;

    if (currentEnterQuotes.error && !enterQuotes.error) {
      showToastMessage(
        this.props.intl.formatMessage({ id: `rfqError-${currentEnterQuotes.error.data.error}` }),
        MessageType.ERROR,
      );
      actions.enterQuotes.reset();

      return;
    }

    if (currentEnterQuotes.isSaved && !enterQuotes.isSaved) {
      showToastMessage(this.props.intl.formatMessage({ id: 'rfqQuotesSavedSuccessfully' }), MessageType.SUCCESS);
      actions.enterQuotes.reset();
      actions.enterQuotes.fetchRfqs(this.buildParams());
    }
  }

  onQuoteChange(uuid, maturityCode, coupon) {
    this.props.localActions.updateQuote({
      coupon,
      maturityCode,
      uuid,
    });

    coupon ? this.props.setPreventNavigation(true) : this.props.setPreventNavigation(false);
  }

  onRfqConfirmation = (uuid) => {
    const rfqByUuid = this.props.enterQuotes.rfqs.list.find((rfq) => rfq.uuid === uuid);

    if (this.isRfqClosed(rfqByUuid)) {
      showToastMessage(this.props.intl.formatMessage({ id: 'rfqError-closed-rfq' }), MessageType.ERROR);
      this.resetConfirmationState(uuid);

      return;
    }

    const tenants = [rfqByUuid.domain, this.props.tenant];
    const { searchParams } = this.props;

    const safeData = this.getMaturitiesFromLocalReducer(uuid);

    this.props.actions.enterQuotes.createQuotes(safeData, {
      uuid,
      token: searchParams.get('token'),
      tenants,
      tenant: this.props.tenant,
    });
    this.resetConfirmationState(uuid);
  };

  shouldHighlightSettlementDate = (rfq) => new Date(rfq.settlementDate) > new Date();

  onRfqButtonClickConfirmation(uuid, rfq) {
    const maturitiesFilled = this.getMaturitiesFromLocalReducer(uuid).filter(({ coupon }) => coupon !== null);
    const data = rfq.maturities.map(this.buildMaturityRecord({ rfq }));

    const dialogProperties = {
      titleId: 'sendQuotes',
      content: (
        <React.Fragment>
          {maturitiesFilled.length !== rfq.maturities.length && (
            <div>
              <FormattedMessage id="rfqMaturitiesNotFilledContent" />
            </div>
          )}
          <ResultsPresenter
            recordsPresenter={{
              data,
              options: { isAnyMaturityAlreadyQuoted: true, readOnly: true },
              ...recordsPresenter,
            }}
          />
          {this.shouldHighlightSettlementDate(rfq) && (
            <div>
              <FormattedMessage
                id="rfqAttention"
                values={{
                  settlementDate: formatDate(rfq.settlementDate, enAuShortDateFormat),
                }}
              />
            </div>
          )}
        </React.Fragment>
      ),
    };

    this.props.onConfirmClick(() => this.onRfqConfirmation(uuid));
    this.props.toggleConfirmDialog(dialogProperties);
  }

  getStatus(rfq, isAnyMaturityAlreadyQuoted, maturityWon) {
    const { maturities } = rfq;
    if (this.isRfqCancelled(rfq.rfqStatus)) {
      const presenterStatus = rfq.cancelOption ? rfq.cancelOption : rfq.rfqStatus;
      const statusId = `dashboard.status.${presenterStatus}`;

      return [<FormattedMessage key="rfq-cancelled" id={statusId} />];
    }

    if (this.isRfqClosed(rfq)) {
      return [
        <FormattedMessage key="rfq-closed" id="closed" />,
        maturityWon ? (
          <span key="rfq-won">
            {' '}
            (<FormattedMessage id="won" />)
          </span>
        ) : (
          <span key="rfq-lost">
            {' '}
            (<FormattedMessage id="lost" />)
          </span>
        ),
      ];
    }

    const autoQuotedId = maturities.some(({ isAutoQuote }) => isAutoQuote) && 'autoQuoted';

    return [
      <FormattedMessage key="rfq-open" id="open" />,
      isAnyMaturityAlreadyQuoted ? (
        <span key="rfq-quote-sent">
          {' '}
          (
          <FormattedMessage
            key="rfq-quote-sent"
            id={autoQuotedId || 'rfqHeaderQuoteSentSuccessfully'}
            values={{ count: maturities.length }}
          />
          )
        </span>
      ) : (
        <span key="rfq-waiting">
          {' '}
          (
          <FormattedMessage id="rfqWaitingQuotes" values={{ count: maturities.length }} />)
        </span>
      ),
    ];
  }

  resetConfirmationState(uuid) {
    this.props.localActions.reset(uuid);
    this.props.setPreventNavigation(false);
    this.props.hideConfirmDialog();
  }

  buildParams() {
    const { searchParams, params } = this.props;

    return {
      uuid: params.uuid,
      token: searchParams.get('token'),
      tenant: this.props.tenant,
    };
  }

  getMaturitiesFromLocalReducer(uuid) {
    const filledMaturities = this.props.localReducer.rfqs[uuid] ? this.props.localReducer.rfqs[uuid].maturities : {};
    const rfqMaturities = this.props.enterQuotes.rfqs.list.find((rfq) => rfq.uuid === uuid);

    return rfqMaturities.maturities.map(({ code: maturityCode, bankQuote }) => {
      const filledCoupon = ['', undefined].includes(filledMaturities[maturityCode])
        ? null
        : filledMaturities[maturityCode];

      return {
        maturityCode,
        coupon: maturityCode in filledMaturities ? filledCoupon : bankQuote,
      };
    });
  }

  isRfqClosed = ({ startDate, endDate, rfqStatus }) => {
    const isOpenByDate = newDate().isBetween(newDate(startDate), newDate(endDate));

    return !isOpenByDate || rfqStatus === 'closed' || rfqStatus === 'cancelled';
  };

  isRfqCancelled = (rfqStatus) => rfqStatus === 'cancelled';

  findMaturityWon = ({ maturities }) =>
    maturities.find(({ quoteStatus }) => quoteStatus === 'accepted' || quoteStatus === 'confirmed');

  isRfqQuoted = ({ maturities }) => maturities.find(({ quoteUpdatedAt }) => quoteUpdatedAt !== null);

  buildMaturityRecord =
    ({ isRfqClosed, isRfqCancelled, timezone, rfq }) =>
    (maturity) => {
      const filledMaturities =
        this.props.localReducer.rfqs[rfq.uuid] && this.props.localReducer.rfqs[rfq.uuid].maturities;

      const quote =
        filledMaturities && maturity.code in filledMaturities ? filledMaturities[maturity.code] : maturity.bankQuote;

      return {
        ...maturity,
        quote,
        isRfqClosed,
        isRfqCancelled,
        timezone,
        warnOriginalDateDifference: this.isOriginalDateDifferenceGreaterThanMaxAllowed(maturity),
        difference: difference(maturity.date, formatToShortDate(rfq.endDate)),
      };
    };

  isOriginalDateDifferenceGreaterThanMaxAllowed = ({ date, originalDate }) =>
    originalDate && Math.abs(difference(date, originalDate)) > recordsPresenter.daysToWarnMaturityDateDifference;

  fillRatesByRatesheet = ({ rfq: { uuid, maturities }, ratesheet: { rates } }) => {
    track(eventAutoFillClicked);

    maturities
      .filter((maturity) => !this.isOriginalDateDifferenceGreaterThanMaxAllowed(maturity))
      .forEach(({ code }) => {
        const { rate } = rates.find(({ tenor }) => tenor === code);

        this.onQuoteChange(uuid, code, rate);
      });
  };

  isAutoFillEnabled = ({ settlementDate, maturities }) => {
    const { hasDashboardLicence, tenant } = this.props;

    return (
      hasDashboardLicence &&
      tenant !== 'global' &&
      sameDayAsToday(settlementDate) &&
      !maturities.find(({ code }) => code === 'CUSTOM')
    );
  };

  getAutoFillRfqRatesheet = ({ rfq }) => {
    const { segmentCode, instrumentCode, settlementDate, maturities, rfqStatus } = rfq;
    const { bankRatesheet, latestTermDepositRatesheetUpdatedAt } = this.props;

    if (
      rfqStatus !== 'open' ||
      !latestTermDepositRatesheetUpdatedAt ||
      !this.isAutoFillEnabled({ settlementDate, maturities })
    ) {
      return null;
    }

    const rfqRatesheetBySegmentCode =
      bankRatesheet[instrumentCode] &&
      bankRatesheet[instrumentCode].find(
        ({ available, segmentCode: ratesheetSegmentCode }) => ratesheetSegmentCode === segmentCode && available,
      );

    if (!rfqRatesheetBySegmentCode) {
      return null;
    }

    return rfqRatesheetBySegmentCode;
  };

  isAnyCouponFilled = ({ uuid }) => this.getMaturitiesFromLocalReducer(uuid).some(({ coupon }) => coupon !== null);

  renderAutoFillSection = ({ ratesheet, rfq }) => {
    const { latestTermDepositRatesheetUpdatedAt, userIsAdmin } = this.props;

    const updatedAt = moment(this.state.ratesheetUpdatedByDialog || latestTermDepositRatesheetUpdatedAt);

    return (
      <FlexRow role="region" aria-label="auto-fill section" className="auto-fill" contentEnd alignItemsCenter>
        {!sameDayAsToday(updatedAt) && (
          <Tooltip id="staleRatesheet">
            <i role="img" aria-label="stale ratesheet" className="icon fa fa-exclamation-triangle" />
          </Tooltip>
        )}
        <FormattedMessage
          id="ratesheetLastUpdatedAtWithValues"
          values={{
            value: `${updatedAt.fromNow()} at ${updatedAt.format('LT')}`,
          }}
        />
        {this.isAnyCouponFilled(rfq) ? (
          <ConfirmationPopover
            buttonLabelId="autoFill"
            confirmationMessageId="autoFillAreYouSure"
            type="button"
            onConfirm={() => this.fillRatesByRatesheet({ rfq, ratesheet })}
          />
        ) : (
          <Button onClick={() => this.fillRatesByRatesheet({ rfq, ratesheet })}>
            <FormattedMessage id="autoFill" />
          </Button>
        )}
        {userIsAdmin && (
          <Button onClick={this.showUpdateRatesheetDialog}>
            <FormattedMessage id="updateRatesheet" />
          </Button>
        )}
      </FlexRow>
    );
  };

  onRatesheetUpdated = () =>
    this.setState({
      showUpdateRatesheetDialog: false,
      ratesheetUpdatedByDialog: new Date(),
    });

  showUpdateRatesheetDialog = () => this.setState({ showUpdateRatesheetDialog: true });

  onUpdateRatesheetDialogHide = () => this.setState({ showUpdateRatesheetDialog: false });

  renderRfqs(enterQuotes) {
    const { rfqs } = enterQuotes;

    if (!rfqs || !rfqs.list.length) {
      return <EmptyContent messageId="rfqNoRecords" />;
    }

    const enableQuoteEditing = this.props.tenant !== 'global' && this.props.hasDashboardLicence;

    return rfqs.list.map((rfq) => {
      const { maturities, expanded, uuid, isCreatingQuotes = false, timezone } = rfq;
      const rfqByUuid = this.props.localReducer.rfqs[uuid];
      const isRfqClosed = this.isRfqClosed(rfq);
      const isRfqCancelled = this.isRfqCancelled(rfq);
      const maturityWon = this.findMaturityWon(rfq);
      const isAnyMaturityAlreadyQuoted = this.isRfqQuoted(rfq);
      const autoFillRfqRatesheet = this.getAutoFillRfqRatesheet({ rfq });
      const data = maturities.map(
        this.buildMaturityRecord({
          isRfqClosed,
          isRfqCancelled,
          timezone,
          rfq,
          autoFillRfqRatesheet,
        }),
      );

      const payload = {
        isFetching: isCreatingQuotes,
        noRecords: !maturities.length,
        recordsPresenter: {
          actions: {
            onQuoteChange: this.onQuoteChange.bind(this, uuid),
          },
          data,
          options: {
            enableQuoteEditing,
            isAnyMaturityAlreadyQuoted,
            autoFillRfqRatesheet,
          },
          ...recordsPresenter,
        },
      };
      const header = this.renderPanelHeader(rfq, isAnyMaturityAlreadyQuoted, maturityWon);
      const footer = this.renderPanelFooter(rfq, rfqByUuid, isAnyMaturityAlreadyQuoted, enableQuoteEditing);
      const rfqStatus = getRfqStatus(rfq);

      return (
        <Row key={rfq.uuid} className="rfq-list" role="region" aria-label="rfq-enter-quotes-panel">
          <Col xs={12}>
            <PanelCollapsible
              className={classNames({
                'with-border blue': rfqStatus === rfqStatuses.pending,
                'with-border orange': rfqStatus === rfqStatuses.sent,
                'with-border green': rfqStatus === rfqStatuses.won,
                'with-border red': rfqStatus === rfqStatuses.lost,
                'with-border white': rfqStatus === rfqStatuses.cancelled,
              })}
              header={header}
              footer={footer}
              expanded={expanded}
            >
              {autoFillRfqRatesheet &&
                this.renderAutoFillSection({
                  ratesheet: autoFillRfqRatesheet,
                  rfq,
                })}
              <ResultsPresenter {...payload} />
            </PanelCollapsible>
          </Col>
        </Row>
      );
    });
  }

  renderPanelFooter(rfq, rfqByUuid, isAnyMaturityAlreadyQuoted, enableQuoteEditing) {
    return (
      <Row className="footer-row">
        <div className="pull-right">
          {this.renderFooterContent(rfq, rfqByUuid, isAnyMaturityAlreadyQuoted, enableQuoteEditing)}
        </div>
      </Row>
    );
  }

  renderFooterContent(rfq, rfqByUuid, isAnyMaturityAlreadyQuoted, enableQuoteEditing) {
    const { uuid, maturities, isCreatingQuotes, timezone } = rfq;

    const closedRfq = this.isRfqClosed(rfq);

    if ((closedRfq && isAnyMaturityAlreadyQuoted) || (isAnyMaturityAlreadyQuoted && !enableQuoteEditing)) {
      const [firstMaturity] = maturities;

      return (
        <p className="weight-600 light-primary-color">
          <FormattedMessage id="rfqQuoteSentSuccessfully" values={{ count: maturities.length }} />
          <FormattedMessage id="at" />
          <DateWithTimeZone value={firstMaturity.quoteUpdatedAt} timeZone={timezone} {...timeProps} />
        </p>
      );
    }

    if (closedRfq) {
      return null;
    }

    return (
      <div>
        <Button
          className="btn-solid-primary"
          disabled={this.isSendButtonDisabled(rfqByUuid)}
          onClick={() => this.onRfqButtonClickConfirmation(uuid, rfq)}
        >
          <Loadable isLoading={isCreatingQuotes} size="sm">
            <FormattedMessage id="send" />
          </Loadable>
        </Button>
      </div>
    );
  }

  isSendButtonDisabled = (rfqByUuid) =>
    rfqByUuid && Object.keys(rfqByUuid.maturities).find((maturityCode) => rfqByUuid.maturities[maturityCode] === '-');

  renderPanelHeader(rfq, isAnyMaturityAlreadyQuoted, maturityWon) {
    const shouldHighlightSettlementDate = this.shouldHighlightSettlementDate(rfq);

    return (
      <div className="panel-header">
        <div className="highlight-panel">
          <SummaryHighlight
            top={<FormattedMessage id="principal" />}
            middle={
              <div className="text-ellipsis">
                <FormattedNumber {...percentual} value={rfq.principal} />
              </div>
            }
          />
          <SummaryHighlight
            top={<FormattedMessage id="holdingInstrumentCode" />}
            middle={
              <span>
                <b>{rfq.instrumentCode}</b>
              </span>
            }
          />
          <div
            className={classNames('summary-settlement-date', {
              highlight: shouldHighlightSettlementDate,
            })}
          >
            {shouldHighlightSettlementDate && <FaExclamationTriangle />}
            <SummaryHighlight
              top={<FormattedMessage id="settlementDate" />}
              middle={<DateWithTimeZone value={rfq.settlementDate} />}
            />
          </div>
          <SummaryHighlight
            top={<FormattedMessage id="customer" />}
            middle={
              <span>
                <b>{rfq.requestedBy}</b>
              </span>
            }
          />
        </div>
        <div className="secondary-panel">
          <SummaryHighlight
            top={<FormattedMessage id="currency" />}
            middle={<div className="text-ellipsis">{getMoneySymbol({ currency: rfq.currency, long: true })}</div>}
          />
          <SummaryHighlight
            top={<FormattedMessage id="interestPaid" />}
            middle={
              <div className="text-ellipsis">
                <FormattedMessage id={`interestDescription.${rfq.interestPaidCode}`} />
              </div>
            }
          />
          <SummaryHighlight
            top={<FormattedMessage id="holdingAllocationCode" />}
            middle={capitalize(rfq.allocationCode)}
          />
          <SummaryHighlight
            top={<FormattedMessage id="createdAt" />}
            middle={<DateWithTimeZone value={rfq.requestedAt} {...timeProps} />}
          />
          <SummaryHighlight
            top={<FormattedMessage id="status" />}
            middle={<div className="text-ellipsis">{this.getStatus(rfq, isAnyMaturityAlreadyQuoted, maturityWon)}</div>}
          />
        </div>
      </div>
    );
  }

  render() {
    const { enterQuotes, hideHeader, userIsAdmin } = this.props;

    return (
      <div className="enter-quotes">
        {!hideHeader && <Header titleId="rfqEnterQuotes" />}
        {userIsAdmin && (
          <UpdateRatesheetDialog
            onRatesheetUpdated={this.onRatesheetUpdated}
            show={this.state.showUpdateRatesheetDialog}
            onHide={this.onUpdateRatesheetDialogHide}
          />
        )}
        <SkeletonTable isLoading={enterQuotes.isFetching}>{this.renderRfqs(enterQuotes)}</SkeletonTable>
      </div>
    );
  }
}

IncomingRfqsComponent.propTypes = {
  actions: PropTypes.shape().isRequired,
  bankRatesheet: PropTypes.shape(),
  enterQuotes: PropTypes.shape().isRequired,
  hasDashboardLicence: PropTypes.bool,
  hideHeader: PropTypes.bool,
  latestTermDepositRatesheetUpdatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  licences: PropTypes.arrayOf(PropTypes.string),
  localActions: PropTypes.shape(),
  localReducer: PropTypes.shape(),
  on: PropTypes.func,
  onConfirmClick: PropTypes.func,
  setIsDirty: PropTypes.func,
  tenant: PropTypes.string.isRequired,
  toggleConfirmDialog: PropTypes.func,
  hideConfirmDialog: PropTypes.func,
  userIsAdmin: PropTypes.bool,
};

export const IncomingRfqs = compose(
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.enterQuotes.error,
  })),
  includeLocalReducer(enterQuotesPayloadReducer, enterQuotesPayloadActions),
  withConfirmDialog(),
  withPreventNavigation(),
  withSearchParams,
  withParams,
  includeSocket({ rooms: [rooms.rfq] }),
  injectIntl,
)(IncomingRfqsComponent);
