export const colors = {
  themePrimaryColor: 'primary-color',
  themeNavColor: 'nav-color',
  themeNavColorContrastColor: 'nav-contrast-color',
  themeHeaderColor: 'header-color',
  themeHeaderContrastColor: 'header-contrast-color',
  themeBackgroundMainColor: 'background-main-color',
  themeBackgroundMainContrastColor: 'background-main-contrast-color',
  themeBackgroundLightColor: 'background-light-color',
  themeBackgroundLightContrastColor: 'background-light-contrast-color',
  themeBackgroundDarkColor: 'background-dark-color',
  themeBackgroundDarkContrastColor: 'background-dark-contrast-color',
  themePaperColor: 'paper-color',
  themePaperContrastColor: 'paper-contrast-color',
  themePrimaryTextColor: 'primary-text-color',
  themePrimaryContrastColor: 'primary-contrast-color',
  themeSecondaryTextColor: 'secondary-text-color',
};
