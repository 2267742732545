import './not-interested-input.scss';

import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, InputGroup } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';

import Tooltip from '../tooltip';

export const NotInterestedInput = ({ children, className, onNotInterestedEnabled }) => {
  const [notInterested, setNotInterested] = useState(false);

  const onNotInterestedClick = () => {
    const notInterestedUpdated = !notInterested;
    notInterestedUpdated && onNotInterestedEnabled();

    setNotInterested(notInterestedUpdated);
  };

  return (
    <InputGroup className={`input-group-button ${className}`}>
      {children({ notInterested, setNotInterested })}
      <InputGroup.Button>
        <Tooltip id={!notInterested ? 'markNotInterested' : 'notInterested'}>
          <Button onClick={onNotInterestedClick} className={classNames({ active: notInterested })} tabIndex="-1">
            <FaTimesCircle />
          </Button>
        </Tooltip>
      </InputGroup.Button>
    </InputGroup>
  );
};

NotInterestedInput.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onNotInterestedEnabled: PropTypes.func,
};
