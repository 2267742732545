import './dropdown.scss';

import React from 'react';

import PropTypes from 'prop-types';

import arrowDownIcon from '../../../assets/icons/common/arrow_down_icon.png';
import arrowUpIcon from '../../../assets/icons/common/arrow_up_icon.png';
import { renderSelect } from '../dropdown-filter';
import { Column } from '../Flex/Flex';

const arrowRenderer = ({ isOpen }) => (
  <img alt="Arrow icon" src={isOpen ? arrowUpIcon : arrowDownIcon} className="arrow-icon" />
);

export const Dropdown = ({ name, labelId, options, onChange, ...rest }) => (
  <Column data-theme="white" className="select-dropdown-container">
    {renderSelect({
      name,
      label: labelId,
      isSearchable: true,
      arrowRenderer,
      onDropdownFilterChange: (_, value) => onChange(value),
      options,
      className: 'filter-item item',
      ...rest,
    })}
  </Column>
);

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
};
