import buildForm from '../../common/form/form';

import { fields } from './fields';
import schema from './schema';
import buildInitialValues from './initial-values';

export default function addCPForm(onSave, currencySymbol) {
  return {
    title: 'addCPOption',
    value: 'addCP',
    className: 'add-cp-form',
    form: buildForm({
      fields: fields(currencySymbol),
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
