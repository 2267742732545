import './index.scss';

import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as maturityActions from '../../actions/compliances/maturity';
import * as sessionActions from '../../actions/session';
import { ChartsPresenter, ResultsPresenter } from '../../components/common';
import errorHandler from '../../components/hoc/error-handler';
import { withSearchParams } from '../../components/hoc/with-router-properties';
import * as chartsPresenter from '../../components/maturity/chart-payload';
import * as filterPresenter from '../../components/maturity/filter-presenter';
import * as recordsPresenter from '../../components/maturity/records-presenter';
import { getMoneySymbol } from '../money';

function mapStateToProps(state) {
  return {
    maturity: state.maturity,
    currency: state.session.user.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      maturity: bindActionCreators(maturityActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
    },
  };
}

export class Maturity extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    maturity: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.updateFilterByLocationOnMount();
    this.fetchMaturityIfNeeded(this.props.maturity.filter || this.props.searchParamsEntries);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateFilteredData(nextProps);
    this.updateLocationQuery(nextProps);
  }

  onDropdownFilterChange = (type, value) => {
    this.props.actions.maturity.updateFilter({ [type]: value });
  };

  onResetFilter = () => {
    this.props.actions.maturity.resetFilter();
  };

  updateFilterByLocationOnMount() {
    if (!Object.keys(this.props.searchParamsEntries).length) {
      return;
    }

    this.props.actions.maturity.updateFilter(this.props.searchParamsEntries);
    this.props.actions.session.updateLocationQuery(this.props.searchParamsEntries);
  }

  updateLocationQuery(nextProps) {
    if (!this.isNextFilterDifferent(this.props, nextProps)) {
      return;
    }

    this.props.actions.session.updateLocationQuery(nextProps.maturity.filter);
  }

  isNextFilterDifferent = ({ maturity: { filter } }, { maturity: { filter: nextPropsFilter } }) => {
    return nextPropsFilter && !isEqual(filter, nextPropsFilter);
  };

  updateFilteredData(nextProps) {
    if (!nextProps.maturity.maturity || nextProps.maturity.isFetching || nextProps.maturity.filteredData) {
      return;
    }

    this.props.actions.maturity.updateFilteredData(nextProps.maturity);
  }

  fetchMaturityIfNeeded(params) {
    this.props.actions.maturity.fetchMaturityIfNeeded(params);
  }

  renderCharts = ({ maturity, isFetching }, currency) => {
    const { series, xTicks, tooltips } = chartsPresenter;
    const moneySymbol = getMoneySymbol({ currency, short: true });

    const payload = {
      charts: [
        {
          id: 'maturity-chart',
          titleId: 'maturityChartTitle',
          isFetching,
          data: maturity && maturity.maturityLimits,
          type: 'bar',
          series,
          xTicks,
          tooltips: tooltips(moneySymbol),
        },
      ],
      options: {
        panelSizes: {
          xs: 12,
        },
      },
    };

    return <ChartsPresenter {...payload} />;
  };

  renderRecords({ maturity, isFetching, filteredData, filter, availableFilters }) {
    const noRecords = !maturity || !(filteredData || maturity.maturityLimits).length;
    const data = maturity && (filteredData || maturity.maturityLimits);

    const payload = {
      isFetching,
      noRecords,
      recordsPresenter: {
        data,
        ...recordsPresenter,
      },
      filter: {
        filter,
        availableFilters,
        onDropdownFilterChange: this.onDropdownFilterChange,
        actions: {
          size: { xs: 2 },
          onResetFilter: this.onResetFilter,
        },
        ...filterPresenter,
      },
      titleId: 'maturity.resultsTitle',
    };

    return <ResultsPresenter {...payload} />;
  }

  render() {
    const { maturity, currency } = this.props;

    return (
      <div className="maturity-list">
        {this.renderCharts(maturity, currency)}
        <Col>{this.renderRecords(maturity)}</Col>
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  errorHandler((state) => ({
    error: state.maturity.error,
  })),
  withSearchParams,
)(Maturity);
