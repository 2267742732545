import './horizontal-bar-chart.scss';

import React from 'react';

import PropTypes from 'prop-types';

// require('react-flot/flot/jquery.flot.min');

const ReactFlot = require('react-flot');

const HorizontalBarChart = ({ id, state, series, yTicks }) => {
  const flotOptions = {
    bars: {
      align: 'center',
      fill: true,
      lineWidth: 0,
      show: true,
    },
    points: {
      fill: true,
    },
    xaxis: {
      min: 0,
      max: 100,
      tickLength: 0,
      show: false,
    },
    yaxis: {
      ticks: yTicks,
      show: false,
      toRight: true,
      labelPadding: 6,
      font: {
        color: states[state].labelColor,
        family: 'Roboto',
        size: '100%',
        style: 'normal',
        variant: 'normal',
        weight: 'normal',
      },
    },
    legend: {
      show: false,
    },
    grid: {
      hoverable: true, // IMPORTANT! this is needed for tooltip to work
      borderWidth: 0.0,
    },
  };

  const chartHeight = `${series.length * 45}px`;

  return (
    <div className="horizontal-bar-chart">
      <div id={`${id}-chart-wrapper`} key={id} className="chart">
        <ReactFlot
          id={`${id}-chart`}
          options={flotOptions}
          data={series}
          height={chartHeight}
          width={states[state].width}
        />
      </div>
    </div>
  );
};

const states = {
  default: { labelColor: 'white', width: '100%' },
  download: { labelColor: 'black', width: '800px' },
};

HorizontalBarChart.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  yTicks: PropTypes.array.isRequired,
};

export default HorizontalBarChart;
