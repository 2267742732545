import './bar-chart.scss';

import PropTypes from 'prop-types';
import React from 'react';
import ReactFlot from 'react-flot';

import pallete from '../../theme/helpers/pallete.json';
import ChartLegend from '../chart-legend';
import { calculateMaxYAxis } from '../common/compliances/chart-payload';

const BarChart = ({ id, state, series, xTicks, options, tooltips }) => {
  const flotOptions = {
    bars: {
      align: 'center',
      fill: true,
      lineWidth: 0,
      show: true,
    },
    points: {
      fill: true,
    },
    xaxis: {
      ticks: xTicks,
      labelWidth: 100,
      color: pallete['light-grey'],
      tickColor: pallete['dark-grey'],
    },
    yaxis: {
      min: 0,
      max: options && options.maxYAxis ? options.maxYAxis : calculateMaxYAxis(series),
      tickFormatter: (val) => `${val}%`,
      color: pallete['light-grey'],
      tickColor: pallete['dark-grey'],
    },
    tooltip: {
      content: (label, x, y, flotItem) => tooltips[flotItem.dataIndex],
      cssClass: 'bar-chart-tooltip',
      defaultTheme: false,
      shifts: {
        x: -60,
        y: 25,
      },
      show: true,
    },
    legend: {
      show: false,
    },
    grid: {
      hoverable: true, // IMPORTANT! this is needed for tooltip to work
      borderWidth: 0.3,
    },
    colors: [...series.map((serie) => serie.color)],
  };

  return (
    <div id={`${id}-chart-wrapper`} key={id} className="chart">
      <ChartLegend data={series} />
      <ReactFlot id={`${id}-chart`} options={flotOptions} data={series} height="250px" width={states[state].width} />
    </div>
  );
};

const states = {
  default: { width: '100%' },
  download: { width: '800px' },
};

BarChart.propTypes = {
  id: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  xTicks: PropTypes.array.isRequired,
  options: PropTypes.shape({
    maxYAxis: PropTypes.number,
  }),
  tooltips: PropTypes.array.isRequired,
};

export default BarChart;
