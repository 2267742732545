import {
  acceptQuoteRequestActionPayload,
  ORDER_RFQ_BY_UUID,
  RESET_RFQ_QUOTE,
  RFQ_SELECT_QUOTE,
  rfqFetchActionPayload,
  rfqRequestConfirmPayload,
} from '../../../actions/holding/rfq/quotes';
import {
  cancelRfqActionPayload,
  RESET_RFQ,
} from '../../../actions/holding/rfq/rfq';
import reducer, { onNextState } from '../../reducer';

export const INITIAL_STATE = {
  error: null,
  isFetching: false,
  rfqs: null,
  details: null,
  isSaved: false,
  isSaving: false,
};

const actions = {
  [rfqFetchActionPayload.REQUEST]: onRequest,
  [rfqFetchActionPayload.SUCCESS]: onListSuccess,
  [rfqFetchActionPayload.FAIL]: onFail,
  [rfqRequestConfirmPayload.REQUEST]: onConfirmRequest,
  [rfqRequestConfirmPayload.SUCCESS]: onConfirmSuccess,
  [rfqRequestConfirmPayload.FAIL]: onConfirmFail,
  [acceptQuoteRequestActionPayload.REQUEST]: onAcceptRequest,
  [acceptQuoteRequestActionPayload.SUCCESS]: onAcceptSuccess,
  [acceptQuoteRequestActionPayload.FAIL]: onAcceptFail,
  [cancelRfqActionPayload.REQUEST]: onCancelRequest,
  [cancelRfqActionPayload.SUCCESS]: onCancelSuccess,
  [cancelRfqActionPayload.FAIL]: onCancelFail,
  [RFQ_SELECT_QUOTE]: onSelectQuote,
  [RESET_RFQ_QUOTE]: onReset,
  [RESET_RFQ]: onResetRfq,
  [ORDER_RFQ_BY_UUID]: orderRfqByUuid,
};

function onRequest(state, action) {
  return onNextState(state, {
    isFetching: !action.silentFetch,
  });
}

function onListSuccess(state, action) {
  return onNextState(state, {
    isFetching: false,
    rfqs: buildRfqsList(action),
  });
}

function buildRfqsList({ rfqs, uuid }) {
  const sortedRfqs = rfqs.sort(sortByMaturityPending).sort(sortByRfqStatus);
  processQuoteDetails(sortedRfqs);

  unshiftRfqByUuid(sortedRfqs, uuid);

  return sortedRfqs;
}

function processQuoteDetails(sortedRfqs) {
  sortedRfqs.map((rfq) => {
    const maturities = rfq.maturities.sort(sortByMaturityCode);
    const rfqWithIssuers = rfq.issuers.map((issuer) => ({
      ...issuer,
      maturities,
    }));

    const quotesByIssuer = buildRfqByIssuers(rfqWithIssuers, rfq.quotesDetails);

    quotesByIssuer.sort(sortByQuoteReceivedAt);

    const nominatedMaturity = quotesByIssuer.reduce((acc, quote) => {
      const nominated = quote.maturities.find((maturity) => maturity.nominated);

      if (nominated) {
        acc = nominated; // eslint-disable-line no-param-reassign
      }

      return acc;
    }, {});

    rfq.nominatedMaturity = nominatedMaturity; // eslint-disable-line no-param-reassign
    rfq.receivedQuotes = quotesByIssuer.filter((quote) => quote.maturities.find(({ coupon }) => coupon !== undefined)); // eslint-disable-line no-param-reassign
    rfq.waitingQuotes = quotesByIssuer.filter((quote) => !rfq.receivedQuotes.includes(quote)); // eslint-disable-line no-param-reassign

    delete rfq.quotesDetails; // eslint-disable-line no-param-reassign

    return rfq;
  });
}

function orderRfqByUuid(state, { uuid }) {
  const nextState = onNextState(state);
  if (nextState.rfqs) {
    unshiftRfqByUuid(nextState.rfqs, uuid);
  }

  return nextState;
}

function unshiftRfqByUuid(rfqs, uuid) {
  if (!uuid) {
    return;
  }

  const rfqByUuidIndex = rfqs.findIndex((rfq) => rfq.uuid === uuid);
  if (rfqByUuidIndex > -1) {
    const [rfqFromList] = rfqs.splice(rfqByUuidIndex, 1);
    rfqs.unshift(rfqFromList);
  }
}

export function sortByMaturityPending(previousRfq, nextRfq) {
  const findFilledMaturity = ({ status }) => status === 'approved';
  const sortByMaturity =
    previousRfq.maturities.find(findFilledMaturity) && !nextRfq.maturities.find(findFilledMaturity);

  return sortByMaturity ? 1 : -1;
}

export function sortByRfqStatus(previousRfq, nextRfq) {
  return previousRfq.rfqStatus === 'closed' && nextRfq.rfqStatus === 'open' ? 1 : -1;
}

function onFail(state, action) {
  return onNextState(state, {
    isFetching: false,
    error: action.error,
  });
}

function onSelectQuote(state, { quoteDetails, quoteByIssuer, uuid }) {
  const nextState = onNextState(state);
  const rfqDetails = nextState.rfqs.find((rfq) => rfq.uuid === uuid);

  rfqDetails.selectedQuote = {
    ...quoteDetails,
    adi: quoteByIssuer.shortName,
  };

  return nextState;
}

function onAcceptRequest(state, params) {
  const nextState = onNextState(state);
  nextState.isSaving = true;
  const rfqDetails = nextState.rfqs.find((rfq) => rfq.uuid === params.uuid);
  rfqDetails.isAccepting = nextState.isSaving;

  return nextState;
}

function onAcceptSuccess(state, params) {
  const nextState = onNextState(state);
  nextState.isSaving = false;
  const rfqDetails = nextState.rfqs.find((rfq) => rfq.uuid === params.uuid);
  rfqDetails.isAccepting = nextState.isSaving;
  nextState.isSaved = true;
  rfqDetails.isSaved = nextState.isSaved;
  rfqDetails.rfqStatus = 'closed';

  return nextState;
}

function onAcceptFail(state, action) {
  const nextState = onNextState(state);
  nextState.isSaving = false;
  const rfqDetails = nextState.rfqs.find((rfq) => rfq.uuid === action.uuid);
  rfqDetails.isAccepting = nextState.isSaving;
  nextState.isSaved = nextState.isSaving;
  nextState.isAccepting = nextState.isSaving;
  nextState.error = action.error;

  return nextState;
}

const maturityCodes = {
  '1M': 1,
  '2M': 2,
  '3M': 3,
  '4M': 4,
  '5M': 5,
  '6M': 6,
  '7M': 7,
  '8M': 8,
  '9M': 9,
  '10M': 10,
  '11M': 11,
  '12M': 12,
  '1Y': 13,
  '2Y': 14,
  '3Y': 15,
  '4Y': 16,
  '5Y': 17,
};

export function sortByMaturityCode(prev, next) {
  // eslint-disable-next-line
  return maturityCodes[prev.code] < maturityCodes[next.code]
    ? -1
    : maturityCodes[prev.code] > maturityCodes[next.code]
    ? 1
    : 0;
}

export function sortByQuoteReceivedAt(previous, next) {
  const [previousQuote] = previous.maturities;
  const [nextQuote] = next.maturities;
  // eslint-disable-next-line
  return previousQuote.receivedAt < nextQuote.receivedAt ? -1 : previousQuote.receivedAt > nextQuote.receivedAt ? 1 : 0;
}

const getBestQuotes = (quotes) => {
  const quotesByMaturity = quotes.reduce((quoteByMaturity, { coupon, maturityCode }) => {
    // eslint-disable-next-line no-param-reassign
    quoteByMaturity[maturityCode] = quoteByMaturity[maturityCode] || [];
    quoteByMaturity[maturityCode].push(coupon);

    return quoteByMaturity;
  }, {});

  return Object.keys(quotesByMaturity).reduce((bestQuotesByMaturity, maturityCode) => {
    const [bestCoupon] = quotesByMaturity[maturityCode].sort((previous, next) => previous - next).slice(-1);
    // eslint-disable-next-line no-param-reassign
    bestQuotesByMaturity[maturityCode] = bestCoupon || 0;

    return bestQuotesByMaturity;
  }, {});
};

const buildRfqByIssuers = (rfqByIssuers, quotes) => {
  const bestQuotes = getBestQuotes(quotes);

  return rfqByIssuers.map(includeMaturitiesWithCoupon.bind(null, quotes, bestQuotes));
};

function includeMaturitiesWithCoupon(quotes, bestQuotes, rfqByIssuer) {
  const maturities = rfqByIssuer.maturities.map((maturity) => {
    const quote = quotes.find(
      ({ issuerCode, maturityCode }) => issuerCode === rfqByIssuer.code && maturityCode === maturity.code,
    );
    const quoteByMaturity = quote ? buildCouponData(quote, bestQuotes) : null;

    return {
      ...maturity,
      ...quoteByMaturity,
    };
  });

  return {
    ...rfqByIssuer,
    maturities,
  };
}

const buildCouponData = (quote, bestQuotes) => ({
  ...quote,
  isBestQuote: bestQuotes[quote.maturityCode] === quote.coupon,
});

function onReset(state) {
  const nextState = onNextState(state);

  return {
    ...INITIAL_STATE,
    details: nextState.details,
    rfqs: nextState.rfqs,
  };
}

function onResetRfq() {
  return {
    ...INITIAL_STATE,
  };
}

function onConfirmRequest(state, action) {
  return onNextState(state, {
    confirmingFunds: action.confirmingFunds,
  });
}

function onConfirmSuccess(state, action) {
  const nextState = onNextState(state);
  nextState.confirmingFunds = null;

  const rfqDetails = nextState.rfqs.find((rfq) => rfq.uuid === action.quote.uuid);

  rfqDetails.rfqConfirmed = true;

  return nextState;
}

function onConfirmFail(state, { error }) {
  return onNextState(state, {
    confirmingFunds: null,
    error,
  });
}

function onCancelRequest(state) {
  return onNextState(state, {
    cancellingRfq: true,
  });
}

function onCancelSuccess(state, { uuid, cancelOption }) {
  const nextState = onNextState(state);

  if (nextState.rfqs) {
    const cancelledRfq = nextState.rfqs.find((rfq) => rfq.uuid === uuid);
    cancelledRfq.rfqStatus = 'cancelled';
    cancelledRfq.cancelOption = cancelOption;
    nextState.cancellingRfq = null;
  }

  return nextState;
}

function onCancelFail(state, { error }) {
  return onNextState(state, {
    cancellingRfq: null,
    error,
  });
}

export default reducer(actions, INITIAL_STATE);
