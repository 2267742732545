import './row.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { DateWithTimeZone } from '../../../date';
import { dateTimeProps } from '../../../format-date';
import { getFormattedNumberOrEmpty, rateFormat } from '../../../format-numbers';

const Row = ({ issuerName, receivedAt, isAutoQuote, creditRating, quotes }) => (
  <tr className="outgoing-quotes-row" key={issuerName}>
    <td>{issuerName}</td>
    <td>
      <DateWithTimeZone value={receivedAt} {...dateTimeProps} />
    </td>
    <td className="centralize-text">{creditRating}</td>
    {renderCouponCells(quotes)}
  </tr>
);

const renderCouponCells = (quotes) => quotes && quotes.map(getCell);

const getCell = (quote) => (
  <td className="centralize-text" key={quote.key}>
    <span className={classNames({ 'accepted-quote': quote.accepted, 'best-coupon': quote.isBestCoupon })}>
      {getFormattedNumberOrEmpty({ value: quote.coupon, format: rateFormat })}
    </span>
  </td>
);

Row.propTypes = {
  issuerName: PropTypes.string.isRequired,
  receivedAt: PropTypes.string.isRequired,
  creditRating: PropTypes.string.isRequired,
  isAutoQuote: PropTypes.bool,
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Row;
