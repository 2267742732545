import { roleWeight } from './roles';

export const userHasAccessByRole = ({ tenantRole, globalRole, requiredTo }) => {
  const tenantRoleWeight = roleWeight(tenantRole);
  const globalRoleWeight = roleWeight(globalRole);
  const userRoleWeight = Math.max(tenantRoleWeight, globalRoleWeight);
  const requiredRoleWeight = roleWeight(requiredTo);

  return userRoleWeight >= requiredRoleWeight;
};
