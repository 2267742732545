import axios from 'axios';

import { buildHeaders } from '../../index';

export default async function createQuotes(quotes, params) {
  const isGlobalContext = params.tenant === 'global';
  const requestParams = {
    routeType: isGlobalContext ? 'global' : 'tenant',
    url: isGlobalContext ? `/rfqs/${params.uuid}/quotes` : `/rfqs/incoming/${params.uuid}/quotes`,
  };

  const headers = buildHeaders({
    authorization: params.token,
  });
  const { data } = await axios({
    ...requestParams,
    method: 'put',
    headers,
    data: quotes,
  });

  return data;
}
