import './send-quote.scss';

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from 'rc-table';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Column, Row } from 'react-display-flex';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import Toggle from 'react-toggle';

import { getInstrumentCode } from '../../../../api/holding/instrument-codes';
import * as rfqApiClient from '../../../../api/holding/rfq';
import rooms from '../../../../api/socket/rooms';
import { ConfirmationPopover, SkeletonTable, Tooltip, tourElements } from '../../../../components/common';
import { socketEvents } from '../../../../components/hoc/include-socket';
import {
  DateWithTimeZone,
  difference as dateDifference,
  formatToShortDate,
  newDate,
  sameDayAsToday,
} from '../../../../date';
import { eventAutoFillClicked, track } from '../../../../event-tracker';
import { timeProps } from '../../../../format-date';
import { couponFormat, toNumberFormat } from '../../../../format-numbers';
import { sortByMaturityCode } from '../../../../reducers/holding/rfq/quotes';
import { Money } from '../../../money';
import { MessageType, showToastMessage } from '../../../toast/toast';
import { buildSendQuoteColumns, buildSendQuoteColumnsPreview } from './send-quote-presenter';

export const daysToShowMaturityDateWarning = 5;

export const SendQuoteComponent = ({
  hasDashboardLicence,
  tenant,
  onClose,
  autoFillRfqRatesheet,
  emit,
  isLoading,
  setMaturityCode,
  maturityCode,
  onUpdateRatesheetClick,
  isRfqPending,
  intl,
  rfq = {},
}) => {
  const [filledQuotes, setFilledQuotes] = useState({});
  const [autoFillEnabled, setAutoFillEnabled] = useState(false);

  const toggleControlId = 'auto-fill-toggle';

  useEffect(() => {
    const input = document.querySelector(getInputSelector(maturityCode));
    input && input.focus();
  }, [maturityCode]);

  useEffect(() => {
    const quotes =
      rfq.maturities &&
      rfq.maturities.reduce(
        (rfqMaturities, { code, bankQuote }) => ({
          ...rfqMaturities,
          [code]: bankQuote && toCouponFormat(bankQuote),
        }),
        {},
      );
    quotes && setFilledQuotes(quotes);
  }, [rfq.maturities]);

  useEffect(() => {
    if (rfq && autoFillRfqRatesheet && sameDayAsToday(autoFillRfqRatesheet.updatedAt) && isRfqPending) {
      fillRatesByRatesheet({ rfq, autoFillRfqRatesheet });
    }
  }, [rfq, autoFillRfqRatesheet]);

  const {
    uuid,
    requestedBy,
    principal,
    instrumentCode: internalInstrumentCode,
    currency,
    settlementDate,
    timezone,
    rfqStatus,
    quoteStatus,
    allocationCode,
    requestedAt,
    interestPaidCode,
    createdBy,
    createdByEmail,
    maturities = [],
  } = rfq;
  const instrumentCode = getInstrumentCode(internalInstrumentCode);
  const enableQuoteEditing = tenant !== 'global' && hasDashboardLicence;

  const onQuoteChange = ({ code, value }) => {
    setFilledQuotes({ ...filledQuotes, [code]: value });
    setAutoFillEnabled(false);
  };

  const onAutofillChange = () => {
    const isChecked = !autoFillEnabled;

    isChecked ? fillRatesByRatesheet({ rfq, autoFillRfqRatesheet }) : setAutoFillEnabled(isChecked);
  };

  const onRfqConfirmation = async () => {
    if (checkRfqClosed(rfq)) {
      showToastMessage(intl.formatMessage({ id: 'rfqError-closed-rfq' }), MessageType.ERROR);

      return;
    }

    const tenants = [rfq.domain, tenant];

    const payload = maturities.map(({ code }) => ({
      maturityCode: code,
      coupon: isEmptyOrUndefined(filledQuotes[code]) ? null : filledQuotes[code],
    }));

    try {
      await rfqApiClient.createQuotes(payload, {
        uuid,
        tenant,
      });

      showToastMessage(intl.formatMessage({ id: 'rfqQuotesSavedSuccessfully' }), MessageType.SUCCESS);
      emit(socketEvents.rfqUpdated, { tenants, room: rooms.rfq });

      setFilledQuotes({});
      onClose();
    } catch ({ response: { data } }) {
      showToastMessage(intl.formatMessage({ id: `rfqError-${data && data.error}` }), MessageType.ERROR);
    }
  };

  const isOriginalDateDifferenceGreaterThanMaxAllowed = ({ date, originalDate }) =>
    originalDate && Math.abs(dateDifference(date, originalDate)) > daysToShowMaturityDateWarning;

  const fillRatesByRatesheet = () => {
    track(eventAutoFillClicked);

    const maturitiesToBeFilled = maturitiesData
      .filter((maturity) => !isOriginalDateDifferenceGreaterThanMaxAllowed(maturity))
      .reduce((rfqMaturities, { code }) => {
        const autoFillTenor = autoFillRfqRatesheet.rates.find(({ tenor }) => tenor === code);

        return autoFillTenor ? { ...rfqMaturities, [code]: toCouponFormat(autoFillTenor.rate) } : rfqMaturities;
      }, {});

    setFilledQuotes({ ...filledQuotes, ...maturitiesToBeFilled });
    setAutoFillEnabled(true);
  };

  const getMaturitiesData = (maturityList) =>
    maturityList
      .map(
        buildMaturityRecord({
          isRfqClosed,
          isRfqCancelled,
          timezone,
          rfq,
          autoFillRfqRatesheet,
          filledQuotes,
        }),
      )
      .sort(sortByMaturityCode);

  const isRfqClosed = checkRfqClosed(rfq);
  const isRfqCancelled = rfqStatus === 'cancelled';
  const ratesheetUpdatedAt = autoFillRfqRatesheet && moment(autoFillRfqRatesheet.updatedAt);
  const maturitiesData = getMaturitiesData(maturities);

  const columnsPayload = {
    rfq,
    quoteStatus,
    enableQuoteEditing,
    daysToShowMaturityDateWarning,
    timezone,
    autoFillRfqRatesheet,
    onQuoteChange,
    isRfqClosed,
    isRfqQuoted,
    isQuoteEmpty,
    setMaturityCode,
    settlementDate,
  };
  const columns = buildSendQuoteColumns(columnsPayload);

  return (
    <Column className={classNames('send-quote', { 'is-loading': isLoading })} data-tour={tourElements.sendQuotesDrawer}>
      <Row className="history-data-header">
        <span>RESPOND HERE</span>
        <span>
          - <FormattedMessage id="tenors" values={{ length: maturitiesData.length }} />
        </span>
      </Row>
      <Column className="send-quote-header" data-tour={tourElements.basicInfos}>
        <dl>
          <Column>
            <dt>Counterparty</dt>
            <dd>{requestedBy}</dd>
          </Column>
          <Column>
            <dt>Interest Paid</dt>
            <dd>
              <FormattedMessage id={`interestDescription.${interestPaidCode}`} />
            </dd>
          </Column>
          <Column>
            <dt>Requested by</dt>
            <dd>
              <Tooltip
                id="created-by"
                tooltipComponent={<FormattedMessage id="rfqCreatedBy" values={{ createdBy, createdByEmail }} />}
              >
                <a target="new" title={createdBy} href={`mailto:${createdByEmail}`}>
                  {createdBy}
                </a>
              </Tooltip>
            </dd>
          </Column>
          <Column>
            <dt>Requested at</dt>
            <dd>
              <DateWithTimeZone value={requestedAt} {...timeProps} />
            </dd>
          </Column>
          <Column>
            <dt>Type</dt>
            <dd>{instrumentCode}</dd>
          </Column>
          <Column>
            <dt>Allocation</dt>
            <dd className="capitalize">{allocationCode && allocationCode.toLowerCase()}</dd>
          </Column>
          <Column>
            <dt>Settlement Date</dt>
            <dd>
              <DateWithTimeZone value={settlementDate} />
            </dd>
          </Column>
          <Column>
            <dt>Amount ({currency})</dt>
            <dd>
              <Money value={principal} />
            </dd>
          </Column>
        </dl>
      </Column>
      {autoFillRfqRatesheet && (
        <Row className="auto-fill-rates" data-tour={tourElements.autofill} alignItemsCenter justifyContentSpaceBetween>
          <Row alignItemsCenter>
            {!sameDayAsToday(ratesheetUpdatedAt) && (
              <Tooltip id="staleRatesheet">
                <FaExclamationTriangle aria-label="warning ratesheet is outdated" />
              </Tooltip>
            )}
            <Column>
              <FormattedMessage
                id="ratesheetLastUpdatedAtWithValues"
                values={{
                  value: `${ratesheetUpdatedAt.fromNow()} at ${ratesheetUpdatedAt.format('LT')}`,
                }}
              />
              <Button bsStyle="link" onClick={onUpdateRatesheetClick}>
                Update the Ratesheet
              </Button>
            </Column>
          </Row>
          <Row>
            <label className="auto-fill-label" htmlFor={toggleControlId}>
              <FormattedMessage id="autoFill" />
            </label>
            {!autoFillEnabled && isAnyCouponFilled({ maturities: maturitiesData, filledQuotes }) ? (
              <ConfirmationPopover
                buttonLabelId="autoFill"
                popoverClass="light"
                confirmationMessageId="autoFillAreYouSure"
                onConfirm={onAutofillChange}
                placement="left"
              >
                {({ onPopoverHandlerClick }) => (
                  <Toggle id={toggleControlId} checked={autoFillEnabled} onChange={onPopoverHandlerClick} />
                )}
              </ConfirmationPopover>
            ) : (
              <Toggle id={toggleControlId} checked={autoFillEnabled} onChange={onAutofillChange} />
            )}
          </Row>
        </Row>
      )}
      <Column>
        <SkeletonTable isLoading={isLoading}>
          <Table
            className="light-table"
            columns={columns}
            data={maturitiesData}
            rowKey="code"
            data-tour={tourElements.sendQuote}
          />
        </SkeletonTable>
      </Column>
      <Column flex={1}>
        <ConfirmationPopover
          className="btn-solid-primary"
          onConfirm={onRfqConfirmation}
          buttonLabelId="sendQuotes"
          disabled={checkRfqClosed(rfq)}
          popoverClass="light drawer-popover large quotes-confirmation"
          popoverContent={
            <React.Fragment>
              <h3>Confirmation</h3>
              <Table
                className="light-table"
                columns={buildSendQuoteColumnsPreview(columnsPayload)}
                data={maturitiesData}
                rowKey="code"
                scroll={{ y: '40vh' }}
              />
              {isThereQuotesBlank({ maturities: maturitiesData, filledQuotes }) && (
                <Row className="confirmation-warning" alignItemsCenter>
                  <FaExclamationTriangle size="2em" />
                  <Column>
                    <FormattedMessage tagName="span" id="rfqQuoteNotFilledTitle" />
                    <FormattedMessage tagName="span" id="rfqQuoteNotFilledSubtitle" />
                  </Column>
                </Row>
              )}
            </React.Fragment>
          }
        />
      </Column>
    </Column>
  );
};

const isOriginalDateDifferenceGreaterThanMaxAllowed = ({ date, originalDate }) =>
  originalDate && Math.abs(dateDifference(date, originalDate)) > daysToShowMaturityDateWarning;

const buildMaturityRecord =
  ({ isRfqClosed, filledQuotes, isRfqCancelled, timezone, rfq }) =>
  (maturity) => {
    const quote = filledQuotes && maturity.code in filledQuotes ? filledQuotes[maturity.code] : maturity.quote;
    const warnOriginalDateDifference = isOriginalDateDifferenceGreaterThanMaxAllowed(maturity);

    return {
      ...maturity,
      quote,
      isRfqClosed,
      isRfqCancelled,
      timezone,
      warnOriginalDateDifference,
      difference: dateDifference(maturity.date, formatToShortDate(rfq.endDate)),
      rfqEndDate: rfq.endDate,
    };
  };

const checkRfqClosed = ({ startDate, endDate, rfqStatus }) => {
  const isOpenByDate = newDate().isBetween(newDate(startDate), newDate(endDate));

  return !isOpenByDate || ['closed', 'cancelled'].includes(rfqStatus);
};

const isQuoteEmpty = (value) => [null, ''].includes(value);

const isAnyCouponFilled = ({ maturities, filledQuotes }) =>
  maturities.some(({ quote }) => quote !== null) || Object.entries(filledQuotes).length;

const isRfqQuoted = ({ maturities }) => maturities.find(({ quoteUpdatedAt }) => quoteUpdatedAt !== null);

const isThereQuotesBlank = ({ maturities, filledQuotes }) =>
  maturities.find(({ quote }) => isQuoteEmpty(quote)) || Object.entries(filledQuotes).find(([, quote]) => quote === '');

const getInputSelector = (code) => {
  const inputSelector = '.send-quote .input-group input.form-control';

  return code ? `${inputSelector}.maturity-${code}` : inputSelector;
};

const isEmptyOrUndefined = (value) => [undefined, ''].includes(value);

const toCouponFormat = (value) => toNumberFormat({ value, ...couponFormat });

SendQuoteComponent.propTypes = {
  rfq: PropTypes.shape().isRequired,
  tenant: PropTypes.string.isRequired,
  hasDashboardLicence: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setMaturityCode: PropTypes.func.isRequired,
  autoFillRfqRatesheet: PropTypes.shape(),
  emit: PropTypes.func,
  onUpdateRatesheetClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  maturityCode: PropTypes.string.isRequired,
  isRfqPending: PropTypes.bool.isRequired,
};

export const SendQuote = injectIntl(SendQuoteComponent);
