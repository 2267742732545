import { handleActions } from 'redux-actions';

import { nominateReducer } from './nominate';
import { signOffReducer } from './sign-off';

const initialState = {
  requestSent: false,
  requestFinished: null,
  uuid: '',
};

const combinedReducers = {
  ...nominateReducer,
  ...signOffReducer,
};

export const quotes = handleActions(combinedReducers, initialState);
