import '../default-top.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Summary = ({ summary }) => (
  <div className="summary-container">
    <p className="summary-title">
      <FormattedMessage id="dashboard.outgoingRfqs" />
    </p>
    <ul className="counters-container push-right">
      <li
        title={summary.pending.amount}
        className={`counter-item ${summary.pending.count ? 'highlighted-pending' : 'pending'}`}
      >
        {summary.pending.count}
      </li>
      <li title={summary.ongoing.amount} className="counter-item ongoing">
        {summary.ongoing.count}
      </li>
      <li title={summary.closed.amount} className="counter-item closed">
        {summary.closed.count}
      </li>
      <li title={summary.cancelled.amount} className="counter-item cancelled">
        {summary.cancelled.count}
      </li>
    </ul>
  </div>
);

Summary.propTypes = {
  summary: PropTypes.object,
};

export default Summary;
