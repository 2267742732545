import Valida from 'valida';
import { positiveValue, maxFloat } from '../../../parse-float';

export default {
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue, allowZero: true },
    { validator: maxFloat },
  ],
};
