import buildForm from '../../common/form/form';
import { cdFields } from './fields';
import schema from './schema';
import buildInitialValues from './initial-values';

export default function addCdRFQForm(onSave) {
  return {
    title: 'ratesheetsRFQOption',
    value: 'ratesheetsRFQ',
    className: 'add-new-rfq-form',
    form: buildForm({ fields: cdFields, schema, initialValues: buildInitialValues('cp') }),
    save: onSave,
  };
}
