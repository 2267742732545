import BaseFields from '../base/fields';
import { Bonds } from '../../../trades';
import * as holdingActions from '../../../actions/holding/save';

export const fields = (currencySymbol) => [
  {
    name: 'operation',
    componentType: 'hidden',
    parentObject: 'instrumentDetails',
  },
  {
    name: 'isin',
    label: 'holdingIsins',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.isins && dataset.isins.frtds,
    parentObject: 'instrumentDetails',
  },
  BaseFields.allocation,
  {
    ...BaseFields.percentage,
    name: 'cleanPrice',
    label: 'holdingCleanPrice',
    parentObject: 'instrumentDetails',
  },
  {
    ...BaseFields.percentage,
    name: 'grossPrice',
    label: 'holdingGrossPrice',
    parentObject: 'instrumentDetails',
  },
  BaseFields.purchaseDate,
  {
    name: 'quantity',
    label: 'customHoldingPrincipal',
    componentType: 'currency',
    step: 100000,
    reduceOperation: 'divide',
    reduceBy: Bonds.faceValue * Bonds.issuePrice,
    parentObject: 'instrumentDetails',
    intlValues: { currencySymbol },
  },
  {
    name: 'interestPaidCode',
    label: 'holdingPaid',
    componentType: 'select',
    options: holdingActions.codes.interests,
  },
  BaseFields.reference,
  BaseFields.bankReference,
  BaseFields.note,
];
