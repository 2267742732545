import './maturing-trades-transactions-chart.scss';

import React, { useEffect, useState } from 'react';

import { arrayOf, shape } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ResponsiveBar } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';

import { nivoChartTheme } from '../../../../theme/nivo-chart-settings';
import {
  buildMaturingTradesTransactionsChartData,
  emptyMaturingTargetChartData,
} from './build-maturing-trades-transactions-chart-data';
import {
  maturingTradesTransactionsChartColors,
  maturingTradesTransactionsChartDefs,
  maturingTradesTransactionsChartFill,
  maturingTradesTransactionsChartKeys,
} from './maturing-trades-transactions-chart-settings';

export const MaturingTradesTransactionsChart = injectIntl(({ issuerTrades, intl }) => {
  const [chartData, setChartData] = useState(emptyMaturingTargetChartData);

  useEffect(() => {
    setChartData(buildMaturingTradesTransactionsChartData({ issuerTrades }));
  }, [issuerTrades]);

  return (
    <section
      aria-label="maturing trades transactions chart container"
      className="maturing-trades-transactions-chart-container"
    >
      <FormattedMessage id="numberOfTransactions" tagName="h4" />
      <section aria-label="maturing trades transactions chart" className="maturing-trades-transactions-chart">
        {issuerTrades.length ? (
          <ResponsiveBar
            data={[chartData]}
            keys={maturingTradesTransactionsChartKeys}
            indexBy="key"
            layout="horizontal"
            margin={defaultMargin}
            borderColor="var(--grey-A200)"
            padding={0}
            borderWidth={2}
            colors={({ id }) => maturingTradesTransactionsChartColors[id]}
            enableLabel={false}
            axisLeft={null}
            axisBottom={null}
            defs={maturingTradesTransactionsChartDefs}
            fill={maturingTradesTransactionsChartFill}
            tooltip={({ id, value }) => (
              <BasicTooltip
                color={maturingTradesTransactionsChartColors[id]}
                enableChip
                id={`${intl.formatMessage({ id })}: ${value}`}
              />
            )}
            theme={nivoChartTheme}
          />
        ) : null}
      </section>
    </section>
  );
});

MaturingTradesTransactionsChart.propTypes = {
  issuerTrades: arrayOf(shape()),
};

const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
