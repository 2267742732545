import axios from 'axios';

export const acceptQuote = async ({ rfqEntityId, quoteEntityId }) =>
  axios({
    method: 'put',
    url: `/rfqs/sell-bonds/${rfqEntityId}/quotes/${quoteEntityId}/accept`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
