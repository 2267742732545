export function fetchIfNeeded(params, action = this) {
  return function(dispatch, getState) {
    if (action.should(params, getState())) {
      return dispatch(fetch(params, action));
    }
  };
}

export function fetch(params, action = this) {
  return async (dispatch, getState) => {
    await fetchAsync(dispatch, getState(), params, action);
  };
}

export async function fetchAsync(dispatch, state, params, action = this) {
  const {
    actionsTypes: { REQUEST, SUCCESS, FAIL },
  } = action;
  dispatch({ type: REQUEST, ...params, state });
  try {
    if (action.validateParams) {
      action.validateParams(params, state);
    }
    dispatch({
      type: SUCCESS,
      ...(await action.request(params, state)),
    });
  } catch (error) {
    const { response, message, stack } = error;

    dispatch({
      type: FAIL,
      response,
      message,
      stack,
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
      ...params,
    });
  }
}

export default function(actionName, should, request, validateParams) {
  return {
    actionsTypes: {
      REQUEST: `FETCH_${actionName}_REQUEST`,
      SUCCESS: `FETCH_${actionName}_SUCCESS`,
      FAIL: `FETCH_${actionName}_FAIL`,
    },
    fetchIfNeeded,
    fetchAsync,
    fetch,
    should,
    request,
    validateParams,
  };
}
