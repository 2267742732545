import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DateWithTimeZone } from '../../../../date';
import { fullCouponFormat } from '../../../../format-numbers';
import { ResponseItem } from './ResponseItem';
import { Column, Tooltip } from '../../../../components/common';
import { tradePropTypes } from './trade-prop-types';

export const ReinvestedOnBehalfResponse = ({ trade }) => (
  <Column>
    {trade && trade.concludedByUser && (
      <ResponseItem
        label={<FormattedMessage id="reinvestedBy" />}
        value={
          <Tooltip
            delayHide={2000}
            id="sentBy"
            tooltipComponent={trade.concludedByUser.email || trade.concludedByUser.fullName}
          >
            <span>{trade.concludedByUser.fullName}</span>
          </Tooltip>
        }
      />
    )}
    <ResponseItem
      label={<FormattedMessage id="holdingMaturityDate" />}
      value={<DateWithTimeZone value={trade.maturityDate} />}
    />
    <ResponseItem
      label={<FormattedMessage id="coupon" />}
      value={<FormattedNumber {...fullCouponFormat} value={trade.coupon} />}
    />
  </Column>
);

ReinvestedOnBehalfResponse.propTypes = { trade: tradePropTypes };
