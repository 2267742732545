import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import clone from 'lodash.clonedeep';
import Toggle from 'react-toggle';

import { ConfirmDialog, ErrorComponent, Row, Column } from '../../../components/common';
import { DialogHeader } from './DialogHeader';
import { Tenors } from '../../Offer/Tenors/Tenors';

const dialogProperties = {
  titleId: 'sendEmailToInvestor',
  confirmId: 'send',
  cancelId: 'cancel',
  hasCancel: true,
};

const renderRatesheet = ({ ratesheet, onTenorItemChange }) =>
  ratesheet && ratesheet.available ? (
    <Tenors disabled={!onTenorItemChange} ratesheet={ratesheet} onTenorItemChange={onTenorItemChange} />
  ) : (
    <ErrorComponent
      info="bankNoRatesheetAvailableInfo"
      infoValues={{ segment: ratesheet.segmentCode }}
      message="noRatesheetAvailable"
      showFooter={false}
    />
  );

const onSendOfferTenorItemChange = ({ originalRatesheet, customRatesheet, tenor, rate, setCustomRatesheet }) => {
  const nextRatesheet = clone(customRatesheet);

  const originalRate = originalRatesheet.rates.find((oldRate) => oldRate.tenor === tenor).rate;
  const nextRatesheetUpdatedIndex = customRatesheet.rates.findIndex((newRate) => newRate.tenor === tenor);

  const previousRate = nextRatesheet.rates[nextRatesheetUpdatedIndex].rate || originalRate;

  const changedRate = {
    tenor,
    rate,
    isCustom: originalRate !== rate,
    originalRate: previousRate,
  };

  nextRatesheet.rates[nextRatesheetUpdatedIndex] = changedRate;

  setCustomRatesheet(nextRatesheet);
};

export const SendOfferDialog = ({ show, isCreatingOffer, ratesheet, onConfirm, onCancel, tradeSelected }) => {
  const [customRatesheet, setCustomRatesheet] = useState(ratesheet);
  const [isCustomRatesheet, setIsCustomRatesheet] = useState(false);

  const onTenorItemChange = isCustomRatesheet
    ? ({ tenor, rate }) =>
        onSendOfferTenorItemChange({
          originalRatesheet: ratesheet,
          customRatesheet,
          tenor,
          rate,
          setCustomRatesheet,
        })
    : undefined;

  const isCustomRatesheetChanged = customRatesheet.rates.some((rate) => rate.isCustom);

  return (
    <ConfirmDialog
      dataTestId="send-offer-dialog"
      {...dialogProperties}
      confirmationMessageId={isCustomRatesheet ? 'sendOfferWithCustomRatesheet' : 'sendOfferNow'}
      titleValues={{ customerName: tradeSelected.customerTenant.name }}
      disabled={ratesheet.isFetching || !ratesheet.available || (isCustomRatesheet && !isCustomRatesheetChanged)}
      onCancel={onCancel}
      onHide={onCancel}
      onConfirm={() => onConfirm(customRatesheet)}
      show={show}
      isConfirming={isCreatingOffer}
      modalClassName={`send-offer-dialog bank-dashboard-dialog ${tradeSelected.instrumentCode.toLowerCase()}`}
      content={
        <React.Fragment>
          <DialogHeader tradeSelected={tradeSelected} />
          <Column className="send-offer-dialog-ratesheet">
            <Row alignItemsCenter>
              <Toggle
                data-testid="toggle-custom-ratesheet"
                checked={isCustomRatesheet}
                icons={false}
                onChange={({ target }) => {
                  const { checked } = target;

                  if (!checked) {
                    setCustomRatesheet(ratesheet);
                  }

                  setIsCustomRatesheet(checked);
                }}
              />
              {isCustomRatesheet ? (
                <FormattedMessage id="usingCustomRatesheet" />
              ) : (
                <FormattedMessage
                  id="sendRatesheetLastUpdatedAt"
                  values={{
                    value: (
                      <FormattedDate
                        value={ratesheet.updatedAt}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                      />
                    ),
                  }}
                />
              )}
            </Row>
            {renderRatesheet({
              ratesheet: customRatesheet,
              onTenorItemChange,
            })}
          </Column>
        </React.Fragment>
      }
    />
  );
};

SendOfferDialog.propTypes = {
  isCreatingOffer: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  ratesheet: PropTypes.shape({
    available: PropTypes.bool,
    isFetching: PropTypes.bool,
    updatedAt: PropTypes.string,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
  show: PropTypes.bool,
  tradeSelected: PropTypes.shape().isRequired,
};

SendOfferDialog.defaultProps = {
  show: false,
  isCreatingOffer: false,
};
