import React from 'react';
import { FormattedNumber } from 'react-intl';

export const columns = {
  code: { label: 'code' },
  amountInvested: { label: 'complianceAmountInvested' },
  investmentSharePct: { label: 'complianceInvestmentSharePct' },
  tradesCount: { label: 'tradesCount' },
};

export const presenter = (data, index) => (
  <tr key={index}>
    <td>{data.code.toUpperCase()}</td>
    <td>
      <FormattedNumber
        value={data.amountInvested}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    </td>
    <td>
      <FormattedNumber
        value={data.investmentSharePct}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    </td>
    <td>{data.tradesCount}</td>
  </tr>
);
