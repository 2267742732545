import { FETCH_SANDBOX_REQUEST, FETCH_SANDBOX_SUCCESS, FETCH_SANDBOX_FAILURE } from '../actions/sandbox';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: false,
  trades: [],
  rules: {
    counterparty: [],
    creditRating: [],
    maturity: [],
  },
  calculationSummary: {
    counterparty: true,
    creditRating: true,
    maturity: true,
  },
  issuers: [],
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SANDBOX_REQUEST:
      return { ...state, isFetching: true, didInvalidate: false };
    case FETCH_SANDBOX_SUCCESS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        trades: action.payload.trades,
        rules: action.payload.rules,
        issuers: action.payload.issuers,
        longTermComplianceOnly: action.payload.longTermComplianceOnly,
        calculationSummary: action.payload.calculationSummary,
      };
    case FETCH_SANDBOX_FAILURE:
      return { ...state, isFetching: false, didInvalidate: true, error: action.error };
    default:
      return state;
  }
};
