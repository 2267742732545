import * as rfqApiClient from '../../../api/holding/rfq';
import buildFetch from '../../helper/fetch';
import request from '../../helper/request';
import rooms from '../../../api/socket/rooms';

const LIST_ADI_RFQS = 'LIST_ADI_RFQS';
const ADI_RFQ_CONFIRM_QUOTES = 'ADI_RFQ_CONFIRM_QUOTES';
const RESET_ADIS_RFQ = 'RESET_ADIS_RFQ';

const adiRfqsFetchAction = buildFetch(LIST_ADI_RFQS,
  shouldFetchRfq,
  async (params) => ({
    rfqs: await rfqApiClient.adiList(params),
    uuid: params.uuid,
  }),
);

const sendQuotesRequestAction = request(ADI_RFQ_CONFIRM_QUOTES,
  async (quotes, params) => ({
    quotes: await rfqApiClient.createQuotes(quotes, params),
    uuid: params.uuid,
    socket: {
      event: 'client:rfq-updated',
      data: {
        tenants: params.tenants,
        room: rooms.rfq,
      },
    },
  }),
);

export const adiRfqsFetchActionPayload = adiRfqsFetchAction.actionsTypes;
export const sendQuotesRequestActionPayload = sendQuotesRequestAction.actionsTypes;

export function fetchRfqs (params) {
  return adiRfqsFetchAction.fetch(params);
}

export function fetchRfqsIfNeeded (params) {
  return adiRfqsFetchAction.fetchIfNeeded(params);
}

export function createQuotes (quotes, params) {
  return sendQuotesRequestAction.request(quotes, params);
}

export function reset () {
  return {
    type: RESET_ADIS_RFQ,
  };
}

function shouldFetchRfq (params, state) {
  if (state.enterQuotes.isFetching || state.enterQuotes.didInvalidate) {
    return false;
  }

  return !state.enterQuotes.rfqs;
}
