import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { DatePicker, PercentageInput } from '../../components/common';
import ConfirmDialog from '../../components/common/confirm-dialog';
import { buildDatepickerOptionsWithLimitedDays } from '../../components/common/datepicker-options';
import { getBusinessDate, getDateInTimeFrameFrom, today } from '../../date';

const dialogProperties = {
  confirmId: 'ok',
  centerLabels: true,
  cancelId: 'cancel',
  hasCancel: true,
  modalClassName: 'tenor-maturity-date-modal',
};

export const CustomMaturityAndCouponModal = ({ show, bid, onConfirm, onHide, onCancel, isInvestor }) => {
  const { selectedOffer, tenorFromRatesheet } = bid;

  const [customMaturityDate, setSelectedDate] = useState(null);
  const [customCoupon, setCustomCoupon] = useState(selectedOffer.rate);

  useEffect(() => setSelectedDate(selectedOffer.maturityDate), [selectedOffer.maturityDate]);
  useEffect(() => setCustomCoupon(selectedOffer.rate), [selectedOffer.rate]);

  const couponRef = useRef();

  useEffect(() => {
    couponRef.current && couponRef.current.focus();
  });

  const { time, unit } = selectedOffer;

  const tenorMaturityDate = getBusinessDate({
    date: getDateInTimeFrameFrom(today(), time, unit),
  }).toDate();

  return (
    <ConfirmDialog
      {...dialogProperties}
      titleId={isInvestor ? 'adjustMaturityDate' : 'adjustRate'}
      onCancel={onCancel}
      onHide={onHide}
      onConfirm={() => {
        onConfirm({
          customMaturityDate: customMaturityDate !== tenorFromRatesheet.maturityDate ? customMaturityDate : undefined,
          customCoupon: customCoupon !== tenorFromRatesheet.rate ? customCoupon : undefined,
        });
      }}
      show={show}
    >
      {
        <React.Fragment>
          <FormGroup>
            <label htmlFor="coupon">
              <FormattedMessage id="coupon" />
            </label>
            <PercentageInput
              inputRef={couponRef}
              autoFocus
              decimalPrecision={4}
              min={-100}
              max={100}
              value={customCoupon !== undefined ? customCoupon.toString() : undefined}
              onChange={(e) => {
                if (!e.target) {
                  return;
                }

                setCustomCoupon(e.target.value);
              }}
            />
          </FormGroup>
          <hr />
        </React.Fragment>
      }
      <DatePicker
        className="inline"
        onChange={(selectedDate) => setSelectedDate(selectedDate)}
        value={customMaturityDate || selectedOffer.maturityDate}
        inline
        {...buildDatepickerOptionsWithLimitedDays({ date: tenorMaturityDate })}
      />
      <hr />
      <FormattedMessage id="confirmTenorMaturityDate" />
    </ConfirmDialog>
  );
};

CustomMaturityAndCouponModal.propTypes = {
  bid: PropTypes.shape({
    accruedInterest: PropTypes.number,
    additionalFunds: PropTypes.number,
    interestPaid: PropTypes.string,
    principal: PropTypes.number,
    status: PropTypes.string,
    customCoupon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    customMaturityDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    selectedOffer: PropTypes.shape({
      time: PropTypes.string,
      unit: PropTypes.string,
      rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maturityDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
    tenorFromRatesheet: PropTypes.shape({
      rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      maturityDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    }),
  }).isRequired,
  show: PropTypes.bool,
  isInvestor: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

CustomMaturityAndCouponModal.defaultProps = {
  show: false,
  isInvestor: false,
};
