import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ConfirmDialog } from '../common';
import { useBlocker } from './use-blocker';

const defaultDialogProperties = {
  cancelId: 'confirmLeavingNegativeAnswer',
  confirmId: 'confirmLeavingPositiveAnswer',
  contentId: 'confirmLeavingBody',
  titleId: 'confirmLeavingTitle',
};

export const withPreventNavigation =
  (confirmationDialogProperties = defaultDialogProperties) =>
  (Component) =>
  (props) => {
    const navigate = useNavigate();

    const [nextLocation, setNextLocation] = useState(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [preventNavigation, setPreventNavigation] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const cancelNavigation = useCallback(() => {
      setShowConfirmationModal(false);
    }, []);

    const blocker = useCallback(
      (nextLocationTransaction) => {
        setShowConfirmationModal(true);
        setNextLocation(nextLocationTransaction);

        if (confirmedNavigation) {
          setPreventNavigation(false);
          nextLocationTransaction.retry();
        }
      },
      [confirmedNavigation],
    );

    const confirmNavigation = useCallback(() => {
      setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
      if (confirmedNavigation && nextLocation) {
        navigate(nextLocation.location.pathname);
      }
    }, [confirmedNavigation, nextLocation, navigate]);

    useBlocker(blocker, preventNavigation);

    return (
      <>
        <Component {...props} setPreventNavigation={setPreventNavigation} />
        <ConfirmDialog
          {...confirmationDialogProperties}
          show={showConfirmationModal}
          onCancel={cancelNavigation}
          onConfirm={confirmNavigation}
        />
      </>
    );
  };
