import { createActionThunk } from 'redux-thunk-actions';

import { create } from '../../api/capital-values';
import { actions } from './actions';

export const createCapitalValueAction = createActionThunk(actions.createCapitalValue, async (capitalValue) => {
  const { id } = await create(capitalValue);

  return { ...capitalValue, id };
});

export const createCapitalValueReducer = {
  [createCapitalValueAction.STARTED]: (state) => ({
    ...state,
    isFetching: true,
  }),
  [createCapitalValueAction.SUCCEEDED]: (state, { payload: capitalValueCreated }) => ({
    ...state,
    list: [...state.list, capitalValueCreated],
    isFetching: false,
  }),
  [createCapitalValueAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [createCapitalValueAction.ENDED]: (state) => ({
    ...state,
    isFetching: false,
    error: null,
    statusCode: null,
  }),
};
