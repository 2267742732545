export const licences = {
  sandbox: 'SANDBOX',
  dashboard: 'DASHBOARD',
  rfqReporting: 'RFQ_REPORTING',
  rfqReportingCsv: 'RFQ_REPORTING_CSV',
  portfolio: 'PORTFOLIO',
  reportingPortfolio: 'REPORTING_PORTFOLIO',
  issuerTrades: 'ISSUER_TRADES',
  buyBondRfq: 'BUY_BOND_RFQ',
  reportingPortfolioPerformance: 'REPORTING_PORTFOLIO_PERFORMANCE',
  unsolicitedRatesheet: 'UNSOLICITED_RATESHEET',
};
