import React from 'react';

import cx from 'classnames';
import toKebabCase from 'lodash.kebabcase';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { instrumentCodes } from '../../../api/holding/codes';
import capitalize from '../../../capitalize';
import { Row } from '../../../components/common';
import {
  DateWithTimeZone,
  endOfDay,
  isAfter,
  newDate,
  sameDayAsToday,
} from '../../../date';
import {
  accepted,
  addTradeOnBehalf,
  cancelledRfq,
  noRatesheet,
  oneDayRollover,
  read,
  redeemed,
  redeemedOnBehalf,
  reinvested,
  reinvestedOnBehalf,
  rejected,
  sent,
  unactioned,
} from '../../../ducks/issuer-trades/statuses';
import { fullCouponFormat } from '../../../format-numbers';
import { Money } from '../../money';

export const columns = (currencySymbol) => ({
  status: { label: 'status', sort: 'status' },
  instrumentCode: { label: 'dashboard.assetClass' },
  customer: { label: 'dashboard.customer', sort: 'customerTenant.name' },
  segment: { label: 'segmentCode' },
  purchaseDate: { label: 'holdingPurchaseDate', sort: 'purchaseDate' },
  maturityDate: { label: 'holdingMaturityDate', sort: 'maturityDate' },
  coupon: { label: 'holdingCoupon', sort: 'coupon' },
  principal: { label: 'customHoldingPrincipal', sort: 'principal', values: { currencySymbol } },
  paid: { label: 'holdingPaid', sort: 'interestPaidCode' },
  actions: { label: null },
});

const isOfferClosed = (status) =>
  [accepted, reinvested, rejected, redeemed, reinvestedOnBehalf, redeemedOnBehalf, cancelledRfq].includes(status);

const isOfferOpen = ({ status }) => [sent, read].includes(status);

const shouldDisableRedeemButton = ({ status, maturityDate, rfqInProgress }) => {
  if (!sameDayAsToday(newDate(maturityDate))) {
    return true;
  }

  return isOfferClosed(status) || (rfqInProgress && !!rfqInProgress.uuid);
};

const shouldDisableOneDayRolloverButton = ({ maturityDate, status, source, rfqId }) => {
  const allowedStatuses = [unactioned, noRatesheet, addTradeOnBehalf].includes(status);

  return !(
    !rfqId &&
    sameDayAsToday(newDate(maturityDate)) &&
    allowedStatuses &&
    source !== toKebabCase(oneDayRollover)
  );
};

const shouldDisableMaturingDropdownMenu = (data) => {
  const { maturityDate, maturingUntil, status } = data;

  if (isOfferOpen(data)) {
    return false;
  }

  if (isAfter(maturityDate, endOfDay(maturingUntil))) return true;

  if ([unactioned, oneDayRollover, addTradeOnBehalf].includes(status)) {
    return false;
  }

  return shouldDisableOneDayRolloverButton(data) && shouldDisableRedeemButton(data);
};

const renderMainActionButton = ({ actions, data: trade, isAdmin, maturingUntil }) => {
  if (trade.rfqInProgress) {
    return (
      <Button
        type="button"
        data-testid="main-action-button"
        onClick={() => actions.goToRfq({ uuid: trade.rfqInProgress.uuid })}
      >
        <FormattedMessage tagName="span" id="viewRfq" />
      </Button>
    );
  }

  if (!trade.hasRatesheet && [unactioned, sent, read, noRatesheet].includes(trade.status)) {
    return (
      isAdmin && (
        <Button
          className="update-ratesheet-button"
          type="button"
          data-testid="main-action-button"
          onClick={() => actions.openUpdateRatesheetDialog(trade)}
        >
          <FormattedMessage tagName="span" id="updateRatesheet" />
        </Button>
      )
    );
  }

  if (trade.status !== reinvestedOnBehalf && isAfter(trade.maturityDate, endOfDay(maturingUntil))) {
    return null;
  }

  const showSendOffer = [unactioned, addTradeOnBehalf, oneDayRollover].includes(trade.status);

  return (
    <Button
      key="main-action-button"
      type="button"
      data-testid="main-action-button"
      onClick={() => (showSendOffer ? actions.openOfferActionDialog(trade) : actions.openDetails(trade))}
    >
      <FormattedMessage tagName="span" id={showSendOffer ? 'sendOffer' : 'issuerTradeDetails'} />
    </Button>
  );
};

const renderColumnsByStatus = ({ trade }) => {
  const valuesByTradeStatus = trade.status === reinvestedOnBehalf ? trade.sourceTrade : trade;

  const { interestPaidCode, purchaseDate, principal, coupon, maturityDate } = valuesByTradeStatus;

  return (
    <React.Fragment>
      <td>
        <DateWithTimeZone value={purchaseDate} />
      </td>
      <td>{maturityDate ? <DateWithTimeZone value={maturityDate} /> : '-'}</td>
      <td>
        {coupon !== undefined && coupon !== null ? <FormattedNumber {...fullCouponFormat} value={coupon} /> : '-'}
      </td>
      <td>
        <Money value={principal} />
      </td>
      <td>
        <FormattedMessage tagName="span" id={`interestDescription.${interestPaidCode}`} />
      </td>
    </React.Fragment>
  );
};

export const presenter = (data, index, actions, { isAdmin, maturingUntil }) => {
  const reference = data.reference && `\nReference: ${data.reference}`;
  const title = `Trade ${data.id}\nIssuer ${data.issuerId}${reference || ''}`;

  return (
    <tr title={title} key={index} data-testid={`maturing-trade-${data.id}`}>
      <td>
        <span data-testid="status" className={cx('dashboard-status', toKebabCase(data.status))}>
          <FormattedMessage tagName="span" id={data.status} />
        </span>
      </td>
      <td>{findLabelForInstrumentCode(data.instrumentCode)}</td>
      <td>{data.customerTenant.name}</td>
      <td>{capitalize(data.customerTenant.segmentCode)}</td>
      {renderColumnsByStatus({ trade: data })}
      <td className="actions">
        <Row contentEnd>
          {renderMainActionButton({ actions, data, isAdmin, maturingUntil })}
          <DropdownButton
            pullRight
            noCaret
            id={`maturing-trade-dropdown-${data.id}`}
            data-testid="maturing-trade-dropdown-action"
            disabled={shouldDisableMaturingDropdownMenu({ ...data, maturingUntil })}
            title={<FaEllipsisV />}
          >
            <MenuItem
              disabled={!data.hasRatesheet || isOfferClosed(data.status)}
              onClick={() => actions.onReinvestClick(data)}
              data-testid="reinvest-button"
              eventkey={1}
            >
              <FormattedMessage tagName="span" id="reinvest" />
            </MenuItem>
            <MenuItem
              disabled={shouldDisableOneDayRolloverButton(data)}
              onClick={() => actions.onOneDayRolloverClick(data)}
              data-testid="one-day-rollover-button"
              eventKey={2}
            >
              <FormattedMessage tagName="span" id="oneDayRollover" />
            </MenuItem>
            <MenuItem
              disabled={shouldDisableRedeemButton(data)}
              onClick={() => actions.openRedeemActionDialog(data)}
              data-testid="redeem-button"
              eventKey={3}
            >
              <FormattedMessage tagName="span" id="redeem" />
            </MenuItem>
            <MenuItem
              disabled={!isOfferOpen(data)}
              onClick={() => actions.openCancelOngoingActionDialog(data)}
              data-testid="cancel-ongoing-offer-button"
              eventKey={4}
            >
              <FormattedMessage tagName="span" id="cancelOngoingOffer" />
            </MenuItem>
          </DropdownButton>
        </Row>
      </td>
    </tr>
  );
};

const findLabelForInstrumentCode = (code) => {
  const filteredCode = instrumentCodes.find((instrumentCode) => instrumentCode.code === code);

  return filteredCode ? filteredCode.label : code;
};
