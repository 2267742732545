import buildForm from '../../common/form/form';
import { loadSellables } from '../base/formatters';
import { fields } from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

export default function sellBondsForm(onSave, intl, currencySymbol) {
  const filter = { field: 'instrumentCode', value: 'BOND' };
  const formFields = fields(currencySymbol);
  const isins = formFields.find((field) => field.name === 'isin') || {};
  isins.loadOptions = (data) => loadSellables(data, filter, intl);

  return {
    title: 'addSellBondsOption',
    value: 'sellBonds',
    className: 'sell-bonds-form',
    form: buildForm({
      fields: formFields,
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
