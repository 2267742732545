import React, { Children } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getUser } from '../../../actions/session-selector';
import { Forbidden } from '../../error';
import { roleWeight } from './roles';

export const Protect = ({ children, requiredTo, bankOnly, licence, user, investorOnly, errorComponent }) => {
  if (children && user && hasAccess(user, requiredTo, bankOnly, licence, investorOnly)) {
    return Children.count(children) === 1 ? Children.only(children) : <div>{children}</div>;
  }

  return errorComponent || null;
};

export const RequireProtection = connect((state) => ({
  user: getUser(state),
}))(({ children, requiredTo, bankOnly = false, licence, user, errorComponent, investorOnly }) => {
  if (user && hasAccess(user, requiredTo, bankOnly, licence, investorOnly)) {
    return children;
  }

  return errorComponent || <Forbidden />;
});

RequireProtection.defaultProps = {
  bankOnly: false,
};

Protect.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  requiredTo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errorComponent: PropTypes.node,
  user: PropTypes.shape().isRequired,
  bankOnly: PropTypes.bool,
  licence: PropTypes.string,
};

export const hasAccess = (
  { tenantRole, globalRole, tenantIssuerId, isBank, licences: userLicences },
  requiredTo,
  bankOnly,
  licence,
  investorOnly,
) => {
  if (bankOnly && (!isBank || !tenantIssuerId)) {
    return false;
  }

  if (investorOnly && isBank) {
    return false;
  }

  const isSuperUser = globalRole === 'admin';
  if (isSuperUser) {
    return true;
  }

  const tenantRoleWeight = roleWeight(tenantRole);
  const globalRoleWeight = roleWeight(globalRole);
  const userRoleWeight = Math.max(tenantRoleWeight, globalRoleWeight);
  const requiredRoleWeight = roleWeight(requiredTo);
  const licencedUser = licence && userLicences ? userLicences.includes(licence) : true;

  return userRoleWeight >= requiredRoleWeight && licencedUser;
};

export default Protect;
