import './item-selector.scss';

import React, { PureComponent } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Row } from '../';

export default class Item extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    name: PropTypes.string,
    title: PropTypes.string,
  };

  onChange = () => {
    this.props.onChange(this.props.value);
  };

  render() {
    const { id, className, checked, name, title } = this.props;

    return (
      <div className={cx('item-selector__item', className)}>
        {title && <span className="item-selector__item--title">{title}</span>}
        <label onClick={this.onChange}>
          <FormattedMessage tagName="span" id={id} />
        </label>
        <Row className="checkbox-container" alignItemsCenter>
          {checked && <FormattedMessage tagName="span" id="selected" />}
          <Checkbox name={name || id} checked={checked} onChange={this.onChange} />
        </Row>
      </div>
    );
  }
}
