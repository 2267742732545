import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';

export const includeLocalReducer = (reducer, actions) => (Component) => (props) => {
  const [state, setState] = useState({ ...reducer(undefined, {}) });

  let stateCallback = React.useRef(null);

  const localDispatch = (action) => {
    setState((prevState) => reducer(prevState, action));
  };

  const localActions = React.useRef(bindActionCreators(actions, localDispatch));

  useEffect(() => {
    stateCallback.current && stateCallback?.current();
  }, [state, stateCallback]);

  const setStateCallback = (handler) => {
    stateCallback.current = handler;
  };

  return (
    <Component
      localReducer={state}
      localActions={localActions?.current}
      setStateCallback={setStateCallback}
      {...props}
    />
  );
};

export default includeLocalReducer;
