import classNames from 'classnames';
import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';

export const columns = {
  isCompliant: { label: 'compliant', className: 'is-compliant' },
  bankingGroup: { label: 'holdingBankingGroup' },
  term: { label: 'term' },
  rating: { label: 'rating' },
  amountInvested: { label: 'complianceAmountInvested' },
  investmentSharePct: { label: 'complianceInvestmentSharePct' },
  maxLimitPct: { label: 'complianceMaxLimitPct' },
  maxMoneyLimit: { label: 'bankingGroupMaxMoneyLimit' },
  amountAvailable: { label: 'complianceAmountAvailable' },
};

export const presenter = (bankinGroup, index) => {
  const compliantClass = {
    green: bankinGroup.isCompliant,
    red: !bankinGroup.isCompliant,
  };

  return (
    <tr key={index}>
      <td className={classNames('is-compliant', compliantClass)}>
        <Glyphicon glyph={bankinGroup.isCompliant ? 'ok' : 'remove'} />
      </td>
      <td>{bankinGroup.name}</td>
      <td>
        <FormattedMessage id={`term.${bankinGroup.term}`} />
      </td>
      <td>{String(bankinGroup.rating).toUpperCase() === 'Z' ? 'Unrated' : bankinGroup.rating}</td>
      <td className={classNames({ red: !bankinGroup.isCompliant })}>
        <FormattedNumber value={bankinGroup.amountInvested} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>
      <td className={classNames({ red: !bankinGroup.isCompliant })}>
        <FormattedNumber value={bankinGroup.investmentSharePct} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>
      <td>
        <FormattedNumber value={bankinGroup.maxLimitPct} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>
      <td>
        {bankinGroup.maxMoneyLimit !== null ? (
          <FormattedNumber value={bankinGroup.maxMoneyLimit} minimumFractionDigits={2} maximumFractionDigits={2} />
        ) : (
          '-'
        )}
      </td>
      <td className={classNames({ red: !bankinGroup.isCompliant })}>
        <FormattedNumber value={bankinGroup.amountAvailable} minimumFractionDigits={2} maximumFractionDigits={2} />
      </td>
    </tr>
  );
};
