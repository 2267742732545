import * as rfqApiClient from '../../../api/holding/rfq';
import { getOutgoingRfqsWithQuotes } from '../../../api/holding/rfq/get-outgoing-rfqs-with-quotes';
import rooms from '../../../api/socket/rooms';
import buildFetch from '../../helper/fetch';
import request from '../../helper/request';
import { RESET_RFQ } from './rfq';

const RFQ_LIST = 'RFQ_LIST';
const ACCEPT_RFQ_QUOTE = 'ACCEPT_RFQ_QUOTE';
const CONFIRM_RFQ_QUOTE = 'CONFIRM_RFQ_QUOTE';

export const RFQ_SELECT_QUOTE = 'RFQ_SELECT_QUOTE';
export const RESET_RFQ_QUOTE = 'RESET_RFQ_QUOTE';
export const ORDER_RFQ_BY_UUID = 'ORDER_RFQ_BY_UUID';

const rfqFetchAction = buildFetch(RFQ_LIST, shouldFetchRfq, async (params) => {
  const rfqs = await getOutgoingRfqsWithQuotes();

  return {
    rfqs,
    uuid: params && params.uuid,
  };
});

const acceptQuoteRequestAction = request(ACCEPT_RFQ_QUOTE, async (params) => ({
  acceptedQuote: await rfqApiClient.accept(params),
  ...params,
  socket: {
    event: 'client:rfq-updated',
    data: {
      tenants: params.tenants,
      room: rooms.rfq,
    },
  },
}));

const rfqRequestConfirmAction = request(CONFIRM_RFQ_QUOTE, async (quote, body) => ({
  quote: await rfqApiClient.confirm(quote, body),
  socket: {
    event: 'client:rfq-updated',
    data: {
      tenants: body.tenants,
      room: rooms.rfq,
    },
  },
}));

export const rfqFetchActionPayload = rfqFetchAction.actionsTypes;
export const acceptQuoteRequestActionPayload = acceptQuoteRequestAction.actionsTypes;
export const rfqRequestConfirmPayload = rfqRequestConfirmAction.actionsTypes;

export function fetchRfqIfNeeded(params) {
  return rfqFetchAction.fetchIfNeeded(params);
}

export function fetchRfqs(params) {
  return rfqFetchAction.fetch(params);
}

function shouldFetchRfq(params, state) {
  if (params && params.force) {
    return true;
  }

  if (state.quotes.isFetching || state.quotes.didInvalidate) {
    return false;
  }

  return !state.quotes.rfqs;
}

export function accept(params) {
  return acceptQuoteRequestAction.request(
    {
      quoteId: params.quoteId,
      uuid: params.uuid,
      tenants: params.tenants,
    },
    {
      uuid: params.uuid,
    },
  );
}

export function reset() {
  return {
    type: RESET_RFQ_QUOTE,
  };
}

export function resetRfqs() {
  return {
    type: RESET_RFQ,
  };
}

export function orderRfqByUuid(uuid) {
  return {
    type: ORDER_RFQ_BY_UUID,
    uuid,
  };
}

export function selectQuote({ quoteDetails, quoteByIssuer, uuid }) {
  return {
    type: RFQ_SELECT_QUOTE,
    quoteDetails,
    quoteByIssuer,
    uuid,
  };
}

export function confirmQuote(quote, tenants) {
  const { id, note, reference, bankReference } = quote;

  return rfqRequestConfirmAction.request(quote, { confirmingFunds: id, note, reference, bankReference, tenants });
}
