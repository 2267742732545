import axios from 'axios';

export const updateUser = async (user) => {
  const { id, ...data } = user;

  const { data: updatedUser } = await axios({
    method: 'put',
    url: `/users/${id}`,
    data,
  });

  return updatedUser;
};
