import { createActionThunk } from 'redux-thunk-actions';

import { downloadCombinedReport } from '../../api/reports/download-combined';
import { MessageType, showToastMessage } from '../../containers/toast/toast';
import { saveFile } from '../../save-file';
import { initialState } from './initial-state';

const actions = {
  downloadCombinedReport: 'DOWNLOAD_COMBINED_REPORT',
};

export const downloadCombinedReportAction = createActionThunk(actions.downloadCombinedReport, (params) =>
  downloadCombinedReport(params),
);

export const downloadCombinedReportReducer = {
  [downloadCombinedReportAction.STARTED]: (
    state,
    { payload: [{ from, to, tenantName, selectedReports, fileType }] },
  ) => ({
    ...state,
    downloading: true,
    from,
    to,
    fileType,
    tenantName,
    selectedReports,
  }),
  [downloadCombinedReportAction.SUCCEEDED]: (state, { payload }) => {
    const nextState = { ...initialState };

    if (payload.error || !payload.renderedReportBuffer) {
      showToastMessage(
        payload.error || 'There was an error creating the report. Please try again later.',
        MessageType.ERROR,
      );

      return nextState;
    }

    saveFile({
      content: payload.renderedReportBuffer.data,
      name: getFileName(state),
      fileType: state.fileType,
    });

    return nextState;
  },
  [downloadCombinedReportAction.FAILED]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [downloadCombinedReportAction.ENDED]: (state) => ({
    ...state,
    ...initialState,
  }),
};

const getFileName = ({ tenantName, from, to }) => `${tenantName} monthly report (${from} until ${to})`;
