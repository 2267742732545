import './range.scss';

import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DatePicker } from '../DatePicker/DatePicker';
import { Column, Row } from '../Flex/Flex';

export const Range = ({ disabled, from, to, errorMessageId, onChangeFrom, onChangeTo }) => {
  const toHasError = to && (from > to || !!errorMessageId);

  return (
    <Column className={cx('datepicker-range', { disabled })}>
      <Row>
        <Column className="datepicker-from">
          <FormattedMessage tagName="p" id="from" />
          <DatePicker selected={from} onChange={onChangeFrom} selectsStart startDate={from} endDate={to} />
        </Column>
        <Column className="datepicker-to">
          <FormattedMessage tagName="p" id="to" />
          <DatePicker selected={to} onChange={onChangeTo} selectsEnd startDate={from} endDate={to} minDate={from} />
        </Column>
      </Row>
      {toHasError ? (
        <p className="datepicker-range-error">
          <FormattedMessage id={errorMessageId || 'toShouldBeSmallerThanFrom'} />
        </p>
      ) : null}
    </Column>
  );
};

Range.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  errorMessageId: PropTypes.string,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  datePickerOptions: PropTypes.shape({
    from: PropTypes.shape(),
    to: PropTypes.shape(),
  }),
  roundedInputStyle: PropTypes.bool,
};

Range.defaultProps = {
  from: new Date(),
  to: undefined,
  errorMessageId: undefined,
  disabled: false,
  onChangeFrom: null,
  onChangeTo: null,
};
