import './toast.scss';

import * as toastr from 'toastr';

const defaultTimeout = 5000;
const defaultToastConfig = {
  closeButton: true,
  positionClass: 'toast-top-half-width',
  preventDuplicates: true,
  timeOut: defaultTimeout,
  extendedTimeOut: 0,
};

const messages = {
  error: (message, customConfig) => toastr.error(message, '', { ...defaultToastConfig, ...customConfig }),
  success: (message, customConfig) => toastr.success(message, '', { ...defaultToastConfig, ...customConfig }),
  warning: (message, customConfig) => toastr.warning(message, '', { ...defaultToastConfig, ...customConfig }),
  info: (message, customConfig) => toastr.info(message, '', { ...defaultToastConfig, ...customConfig }),
};

export const MessageType = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

export const showToastMessage = (message, messageType = MessageType.ERROR, customConfig = {}) =>
  messages[messageType](message, customConfig);

export const showResponseErrorMessageWithUpload = ({ intl, error, maxUploadSize }) => {
  if (error.response?.status === 413) {
    return showToastMessage(intl.formatMessage({ id: 'entityTooLarge' }, { maxUploadSize }));
  }

  showResponseErrorMessage({ intl, error });
};

export const showResponseErrorMessage = ({ intl, error }) => {
  if (error.response?.data) {
    const {
      response: { data },
    } = error;

    const errorMessageFallback = data.error ? intl.formatMessage({ id: data.error }) : JSON.stringify(data);

    showToastMessage(
      intl.formatMessage(
        { id: 'anErrorHasOcurredWithValues' },
        {
          errorMessage: data.error_message || errorMessageFallback,
        },
      ),
    );

    return;
  }

  showToastMessage(intl.formatMessage({ id: 'anErrorHasOccurredPleaseTryAgain' }));
};
