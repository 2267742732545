import axios from 'axios';

export const forgotPasswordApi = async ({ email, tenant }) =>
  axios({
    routeType: 'global',
    url: '/users/password',
    method: 'put',
    validateStatus: () => true,
    data: { operation: 'request-reset', email, tenant },
  });
