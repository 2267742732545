import buildForm from '../../common/form/form';
import fields from './fields';
import buildInitialValues from './initial-values';
import schema from './schema';

export default function addRatesheetReinvestmentForm(onSave) {
  return {
    title: 'ratesheetReinvestment',
    value: 'ratesheetReinvestment',
    className: 'ratesheet-reinvestment-form',
    form: buildForm({
      fields,
      schema,
      initialValues: buildInitialValues(),
      destroyOnUnmount: false,
      enableReinitialize: true,
    }),
    save: onSave,
  };
}
