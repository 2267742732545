import {
  LIST_ISSUERS_REQUEST,
  LIST_ISSUERS_SUCCESS,
  LIST_ISSUERS_FAILURE,
} from '../actions/issuers';

const INITIAL_STATE = {
  isFetching: false,
  didInvalidate: true,
  data: [],
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_ISSUERS_REQUEST:
      return { ...INITIAL_STATE, isFetching: true, didInvalidate: false };
    case LIST_ISSUERS_SUCCESS:
      return { ...INITIAL_STATE, isFetching: false, didInvalidate: false, data: action.payload };
    case LIST_ISSUERS_FAILURE:
      return { ...INITIAL_STATE, isFetching: false, didInvalidate: true, error: action.error };
    default:
      return state;
  }
};
