import React from 'react';
import { FormattedNumber } from 'react-intl';

import { findLabelForInstrumentCode } from '../../../api/holding/codes';
import capitalize from '../../../capitalize';
import { DateWithTimeZone } from '../../../date';
import { fullCouponFormat } from '../../../format-numbers';
import { getTradeRating } from '../../../get-trade-rating';
import { Money } from '../../money';

export const columns = (currencySymbol) => ({
  instrumentCode: { label: 'dashboard.assetClass', sort: 'instrumentCode' },
  principal: { label: 'customHoldingPrincipal', sort: 'principal', values: { currencySymbol } },
  coupon: { label: 'holdingCoupon' },
  paid: { label: 'holdingPaid' },
  purchaseDate: { label: 'holdingPurchaseDate', sort: 'purchaseDate' },
  maturityDate: { label: 'holdingMaturityDate', sort: 'maturityDate' },
  allocation: { label: 'holdingAllocation', sort: 'allocationCode' },
  issuer: { label: 'holdingIssuer', sort: 'issuer' },
  rating: { label: 'holdingRating' },
  redeemedBy: { label: 'redeemedBy', sort: 'redeemedBy' },
  redeemedAt: { label: 'redeemedAt', sort: 'redeemedAt' },
  redemptionType: { label: 'action', sort: 'redemptionType' },
  reference: { label: 'holdingReference' },
});

export const presenter = (data, index) => {
  const note = data.note && `\nNote: ${data.note}`;
  const title = `Trade ${data.id}${note || ''}`;
  const rating = getTradeRating(data);

  return (
    <tr title={title} key={index} data-testid={`maturing-trade-${data.id}`}>
      <td>{findLabelForInstrumentCode(data.instrumentCode)}</td>
      <td>
        <Money value={+data.principal} />
      </td>
      <td>
        {data.coupon !== undefined && data.coupon !== null ? (
          <FormattedNumber {...fullCouponFormat} value={data.coupon} />
        ) : (
          '-'
        )}
      </td>
      <td>{data.interestPaid}</td>
      <td>
        <DateWithTimeZone value={data.purchaseDate} />
      </td>
      <td>{data.maturityDate ? <DateWithTimeZone value={data.maturityDate} /> : '-'}</td>
      <td>{data.allocationCode.toUpperCase()}</td>
      <td>{data.issuer}</td>
      <td>{rating.toUpperCase() === 'Z' ? 'Unrated' : rating}</td>
      <td>{data.redeemedBy}</td>
      <td>{data.redeemedAt ? <DateWithTimeZone value={data.redeemedAt} /> : '-'}</td>
      <td>{capitalize(data.redemptionType.toUpperCase())}</td>
      <td>{data.reference}</td>
    </tr>
  );
};
