import { format } from 'd3-format';

import { buildValuesUnderLimit, buildValuesAboveLimit, buildLimits } from '../common/compliances/chart-payload';
import pallete from '../../theme/helpers/pallete.json';

const formater = format(',.2f');

export function series(data, intl) {
  return [
    {
      label: intl.formatMessage({ id: 'limits' }),
      data: buildLimits(data, 'maxLimitPct', 'minLimitPct'),
      bars: { show: true, fillColor: pallete['mid-grey'], barWidth: 0.6 },
      color: pallete['mid-grey'],
    },
    {
      label: intl.formatMessage({ id: 'compliant' }),
      data: buildValuesUnderLimit(data, 'maxLimitPct'),
      bars: { show: true, fillColor: pallete['light-orange'], barWidth: 0.4 },
      color: pallete['light-orange'],
    },
    {
      label: intl.formatMessage({ id: 'nonCompliant' }),
      data: buildValuesAboveLimit(data, 'maxLimitPct'),
      bars: { show: true, fillColor: pallete.error, barWidth: 0.4 },
      color: pallete.error,
    },
    {
      legend: { show: false },
      label: intl.formatMessage({ id: 'nonCompliant' }),
      data: buildValuesUnderLimit(data, 'minLimitPct'),
      bars: { show: true, fillColor: pallete.error, barWidth: 0.4 },
      color: pallete.error,
    },
  ];
}

export function xTicks(data) {
  let index = 0;
  return data.map((limit) => [index++, limit.label]);
}

export function tooltips(moneySymbol) {
  return (data) =>
    data.map((item) => {
      const offLimit = `<b>${item.offLimit > 0 ? 'Excess' : 'Deficit'}:</b> ${moneySymbol}${formater(
        item.offLimit,
      )}<br/>`;

      return `
      <b>${item.label}</b><br/>
      <b>Amount invested:</b> ${moneySymbol}${formater(item.amountInvested)}<br/>
      <b>Min limit:</b> ${item.minLimitPct}%<br/>
      <b>Max limit:</b> ${item.maxLimitPct}%<br/>
      ${item.offLimit ? offLimit : ''}
    `;
    });
}
