import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { DatePicker } from '../../DatePicker/DatePicker';

export class DatePickerField extends PureComponent {
  onDateChange = (date) => {
    const { form, field } = this.props;

    form.setFieldValue(field.name, date);
  };

  onBlur = (value) => {
    const {
      field: { name },
      form,
    } = this.props;

    if (!value) {
      form.setFieldValue(name, '');
    }

    form.setFieldTouched(name, true);
  };

  render() {
    const {
      field: { value, ...field },
      ...rest
    } = this.props;

    return (
      <DatePicker
        {...field}
        onChange={this.onDateChange}
        onBlur={this.onBlur}
        disableWeekends
        disableHolidays
        value={value}
        {...rest}
      />
    );
  }
}

DatePickerField.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  startDate: PropTypes.instanceOf(Date),
};

export default injectIntl(DatePickerField);
