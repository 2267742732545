import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import RCDrawer from 'rc-drawer';

import { Column, Row } from '../Flex/Flex';

import './drawer.scss';

export const Drawer = ({
  titleId,
  titleComponent,
  headerComponent,
  children,
  footer,
  onClose,
  placement,
  level,
  shouldCloseOnClickOutside,
  handler,
  contentAriaLabel,
  ...props
}) => (
  <RCDrawer
    onClose={shouldCloseOnClickOutside ? onClose : undefined}
    placement={placement}
    handler={handler}
    level={level}
    {...props}
  >
    <Column className="drawer-content-wrapper">
      <Row alignItemsCenter contentBetween className="drawer-content-wrapper-header">
        <Row alignItemsCenter contentStart>
          {onClose && (
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">×</span>
            </button>
          )}
          {titleId && (
            <h2>
              <FormattedMessage id={titleId} />
            </h2>
          )}
          {titleComponent}
        </Row>
        {headerComponent}
      </Row>
      <section aria-label={contentAriaLabel} className="drawer-content-wrapper-body">
        {children}
      </section>
      {footer && <div className="drawer-content-wrapper-footer">{footer}</div>}
    </Column>
  </RCDrawer>
);

Drawer.propTypes = {
  children: PropTypes.node,
  contentAriaLabel: PropTypes.string,
  footer: PropTypes.node,
  handler: PropTypes.bool,
  headerComponent: PropTypes.element,
  titleComponent: PropTypes.element,
  level: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  placement: PropTypes.string,
  shouldCloseOnClickOutside: PropTypes.bool,
  titleId: PropTypes.string,
};

Drawer.defaultProps = {
  handler: false,
  shouldCloseOnClickOutside: true,
  level: null,
  placement: 'right',
};
