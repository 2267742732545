import { formatMaskedDate, formatDate, today } from '../../date';
import calculateCounterparty from './counterparty-calculator';
import calculateCreditRating, { removeTrailingSymbol } from './credit-rating-calculator';
import calculateMaturity from './maturity-calculator';
import { isLongRating } from './rating-type';

export default function calculate({ trades, issuers, rules, asOf, longTermComplianceOnly }) {
  const shrunkIssuers = shrinkIssuers({ trades, issuers });
  const tradesWithIssuers = mergeTradesWithIssuers({ trades, issuers: shrunkIssuers });
  const shrunkCounterpartyRules = shrinkCounterpartyRules({ counterpartyRules: rules.counterparty });
  const availableTrades = tradesWithIssuers.filter((trade) => trade.available);

  const counterpartyResults = calculateCounterparty({
    trades: availableTrades,
    issuers: shrunkIssuers,
    counterpartyCompliances: shrunkCounterpartyRules,
    longTermComplianceOnly,
    asOf,
  });
  const creditRatingResults = calculateCreditRating({
    trades: availableTrades,
    creditRatingCompliances: rules.creditRating,
    asOf,
    longTermComplianceOnly,
  });
  const maturityResults = calculateMaturity({
    trades: availableTrades,
    maturityCompliances: rules.maturity,
    filterDate: asOf || today().format('YYYY-MM-DD'),
  });

  const emptyPortfolio = trades.length === 0;

  const summary = {
    counterparty: emptyPortfolio || counterpartyResults.isCompliant,
    creditRating: emptyPortfolio || creditRatingResults.isCompliant,
    maturity: emptyPortfolio || maturityResults.isCompliant,
  };

  const presentationDateFormat = 'DD/MM/YYYY';

  const calculatedTrades = tradesWithIssuers.map((trade) => {
    const counterparty = buildCounterpartyResult(trade, counterpartyResults, asOf, longTermComplianceOnly);
    const creditRating = buildCreditRatingResult(trade, creditRatingResults, asOf, longTermComplianceOnly);
    const maturity = buildMaturityResult(trade, maturityResults);
    const ratingType = longTermComplianceOnly || isLongRating(trade, asOf) ? 'Long' : 'Short';

    const calculationResults = {
      counterparty,
      creditRating,
      maturity,
    };

    return {
      ...trade,
      purchaseDate: formatDate(trade.purchaseDate, presentationDateFormat),
      maturityDate: formatDate(trade.maturityDate, presentationDateFormat),
      ratingType,
      result: calculationResults,
    };
  });

  return { trades: calculatedTrades, summary };
}

function buildCounterpartyResult(trade, counterpartyResults, asOf, longTermComplianceOnly) {
  const issuerResult = counterpartyResults.issuers.find((issuer) => issuer.code === trade.issuerCode);
  const longRating = longTermComplianceOnly || isLongRating(trade, asOf);

  return {
    amountInvested: longRating ? issuerResult.long.amountInvested : issuerResult.short.amountInvested,
    amountAvailable: longRating ? issuerResult.long.amountAvailable : issuerResult.short.amountAvailable,
    isCompliant: longRating ? issuerResult.long.isCompliant : issuerResult.short.isCompliant,
    rating: longRating ? issuerResult.long.rating : issuerResult.short.rating,
  };
}

function buildCreditRatingResult(trade, creditRatingResults, asOf, longTermComplianceOnly) {
  const longRating = longTermComplianceOnly || isLongRating(trade, asOf);
  const tradeCreditRating = longRating
    ? creditRatingResults.long.ratings.find(
        (longRatingResult) => longRatingResult.rating === removeTrailingSymbol(trade.longRating),
      )
    : creditRatingResults.short.ratings.find(
        (shortRatingResult) => shortRatingResult.rating === removeTrailingSymbol(trade.shortRating),
      );

  return tradeCreditRating;
}

function buildMaturityResult(trade, maturityResults) {
  const tradeResult =
    maturityResults.maturityLimits.find((result) => result.currentRuleTrades.includes(trade.id)) || {};

  return {
    label: tradeResult.label,
    amountInvested: tradeResult.amountInvested,
    amountAvailable: tradeResult.amountAvailable,
    isCompliant: tradeResult.isCompliant,
  };
}

function mergeTradesWithIssuers({ trades, issuers }) {
  const dateMask = 'DD/MM/YYYY';

  return trades.map((trade) => {
    const tradeIssuer = issuers.find((issuer) => issuer.id === trade.issuerId);
    return {
      ...trade,
      code: tradeIssuer.code,
      longRating: tradeIssuer.longRating,
      shortRating: tradeIssuer.shortRating,
      instrumentCode: trade.instrumentCode,
      principal: isNaN(+trade.principal) ? 0 : Number(trade.principal),
      issuerId: trade.issuerId,
      purchaseDate: formatMaskedDate(trade.purchaseDate, dateMask),
      maturityDate: formatMaskedDate(trade.maturityDate, dateMask),
      bankingGroupName: tradeIssuer.bankingGroupName,
    };
  });
}

function shrinkIssuers({ trades, issuers }) {
  return issuers.filter((issuer) => trades.find((trade) => trade.issuerId === issuer.id)).map((issuer) => ({
    id: issuer.id,
    longRating: issuer.longRating,
    shortRating: issuer.shortRating,
    code: issuer.code,
    bankingGroupId: issuer.bankingGroupId,
    bankingGroupName: issuer.bankingGroupName,
  }));
}

function shrinkCounterpartyRules({ counterpartyRules }) {
  return counterpartyRules.map((rule) => ({
    maxLimit: rule.maxLimit,
    rating: rule.rating,
    ratingType: rule.ratingType,
  }));
}
