import axios from 'axios';

export const acceptDefaultRatesheetToInvestorsUrl = '/unsolicited-ratesheets/investors/accept-default-ratesheet';

export async function acceptDefaultUnsolicitedRatesheet(data) {
  const result = await axios({
    method: 'post',
    url: acceptDefaultRatesheetToInvestorsUrl,
    data,
  });

  return result.data;
}
