import axios from 'axios';

import { buildHeaders } from '../..';

export const signOff = async ({ uuid, quoteId, hasComplianceBreach }) => {
  const headers = buildHeaders();

  const { data } = await axios({
    method: 'put',
    url: `/rfqs/${uuid}/quotes/${quoteId}/signoff`,
    headers,
    data: { hasComplianceBreach },
  });

  return data;
};
