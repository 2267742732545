import { createActionThunk } from 'redux-thunk-actions';

import { listTenantsByIssuerTrades } from '../../api/issuer-trades';
import { actions } from './actions';

export const listTenantsByIssuerTradesAction = createActionThunk(actions.getTenantsByIssuerTrades, (params) =>
  listTenantsByIssuerTrades(params),
);

export const listTenantsByIssuerTradesReducer = {
  [listTenantsByIssuerTradesAction.STARTED]: (state) => ({
    ...state,
    error: null,
    tenants: {
      ...state.tenants,
      isFetching: true,
    },
  }),
  [listTenantsByIssuerTradesAction.SUCCEEDED]: (state, { payload }) => ({
    ...state,
    tenants: {
      list: payload.map(({ id, name }) => ({ value: id, label: name })),
      isFetching: false,
    },
    error: null,
  }),
  [listTenantsByIssuerTradesAction.FAILED]: (state, { payload }) => ({
    ...state,
    tenants: {},
    error: payload,
    isFetchingCustomers: false,
  }),
  [listTenantsByIssuerTradesAction.ENDED]: (state) => ({
    ...state,
    tenants: {
      ...state.tenants,
      isFetching: false,
    },
    statusCode: null,
  }),
};
