const getBySelectorName = (selector) => `[data-tour="${selector}"]`;

const availableTabKeys = {
  holdings: 'holdings',
  rfqs: 'rfqs',
  compliance: 'compliance',
  reports: 'reports',
};

const portfolioElements = {
  asAt: 'as-at',
  actions: 'actions',
  primaryTabs: 'primary-tabs',
  rfqsTab: 'rfqs-tab',
  refreshButton: 'refresh-button',
  informationButton: 'information-button',
  hamburgerButton: 'hamburger-button',
};

const sendQuotesElements = {
  sendQuotesDrawer: 'send-quotes-drawer',
  basicInfos: 'basic-infos',
  autofill: 'autofill',
  sendQuote: 'send-quote',
  historyByTenor: 'history-by-tenor',
  lastTrades: 'history-by-date',
  sendQuotesReset: 'send-quotes-reset',
};

export const tourElements = {
  ...portfolioElements,
  ...sendQuotesElements,
};

const portfolioActionsGutterTop = '20px';

const portfolio = [
  {
    target: getBySelectorName(tourElements.asAt),
    content: 'portfolioAsAt',
  },
  {
    target: getBySelectorName(tourElements.actions),
    content: 'portfolioActions',
    styles: {
      beacon: {
        top: portfolioActionsGutterTop,
      },
    },
  },
  {
    target: getBySelectorName(tourElements.primaryTabs),
    content: 'portfolioPrimaryTabs',
  },
  {
    target: getBySelectorName(tourElements.rfqsTab),
    content: 'portfolioRfqsTab',
  },
  {
    target: getBySelectorName(tourElements.refreshButton),
    content: 'portfolioRefreshButton',
    requiredTab: availableTabKeys.holdings,
  },
  {
    target: getBySelectorName(tourElements.informationButton),
    content: 'portfolioInformationButton',
  },
  {
    target: getBySelectorName(tourElements.hamburgerButton),
    content: 'hamburgerButton',
  },
];

const sendQuotes = [
  {
    target: getBySelectorName(tourElements.sendQuotesDrawer),
    content: 'sendQuotesDrawer',
    disableOverlay: true,
    placement: 'right-start',
  },
  {
    target: getBySelectorName(tourElements.basicInfos),
    content: 'sendQuotesBasicInfos',
  },
  {
    target: getBySelectorName(tourElements.autofill),
    content: 'sendQuotesAutofill',
  },
  {
    target: getBySelectorName(tourElements.sendQuote),
    content: 'sendQuotesSendQuote',
  },
  {
    target: getBySelectorName(tourElements.lastTrades),
    content: 'sendQuotesHistoryByDate',
    placement: 'left-start',
  },
  {
    target: getBySelectorName(tourElements.historyByTenor),
    content: 'sendQuotesHistoryByTenor',
    placement: 'left-start',
  },
  {
    target: getBySelectorName(tourElements.sendQuotesReset),
    content: 'sendQuotesSendQuotesReset',
  },
];

export const tours = { portfolio, sendQuotes };
