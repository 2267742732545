import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Collapse, { Panel } from 'rc-collapse';
import { Alert, Button } from 'react-bootstrap';
import { Column } from 'react-display-flex';
import { FaChevronCircleDown, FaChevronCircleUp, FaExclamationCircle } from 'react-icons/fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getSettings } from '../../../../../../actions/session-selector';
import { issuerAcceptBespokeUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/issuer/accept-bespoke-unsolicited-ratesheet';
import { issuerAcceptDefaultUnsolicitedRatesheet } from '../../../../../../api/unsolicited-ratesheets/issuer/accept-default-unsolicited-ratesheet';
import { collapseMotion } from '../../../../../../components/common/css-motions';
import { isBeforeNow } from '../../../../../../date';
import { isTdRatesheetStale } from '../../../../../../ducks/ratesheet/selectors';
import { Tenors } from '../../../../../Tenors/Tenors';
import { MessageType, showResponseErrorMessage, showToastMessage } from '../../../../../toast/toast';
import { UpdateRatesheetButton } from '../../../SendQuotes/UpdateRatesheet';
import { UnsolicitedRatesheetBespokeType, UnsolicitedRatesheetDefaultType } from '../unsolicited-ratesheet-models';
import { UnsolicitedRatesheetOnBehalfPopover } from './UnsolicitedRatesheetOnBehalfPopover';

const expandIcon = ({ isActive }) => (isActive ? <FaChevronCircleUp /> : <FaChevronCircleDown />);

const panelKeys = {
  currentRatesheet: 'currentRatesheet',
};

export const UnsolicitedRatesheetDetailsRatesheetComponent = injectIntl(
  ({
    customerCurrentRatesheet,
    selectedCustomer,
    unsolicitedRatesheetMaxBankAmount,
    onAddOnBehalfConfirm,
    isLatestTermDepositRatesheetStale,
    onRatesheetUpdate,
    isUnsolicitedRatesheetLoading,
    selectedTenor,
    setSelectedTenor,
    onSendBespokeRatesheets,
    intl,
  }) => {
    const [tenorItemRef, setTenorItemRef] = useState(false);

    useEffect(() => {
      if (!tenorItemRef || !tenorItemRef.current) {
        return;
      }
    }, [tenorItemRef]);

    const onConfirm = async (body) => {
      const { rate, principal, allocationCode, tenor, interestPaid, additionalFunds } = body;

      try {
        const addOnBehalfAction =
          customerCurrentRatesheet.type === UnsolicitedRatesheetDefaultType
            ? issuerAcceptDefaultUnsolicitedRatesheet
            : issuerAcceptBespokeUnsolicitedRatesheet;

        await addOnBehalfAction({
          rate,
          principal,
          allocationCode,
          tenor,
          interestPaid,
          additionalFunds,
          investorTenantId: selectedCustomer.investorTenantId,
          unsolicitedRatesheetEntityId: customerCurrentRatesheet.unsolicitedRatesheetEntityId,
        });

        onAddOnBehalfConfirm();

        setSelectedTenor(undefined);

        showToastMessage(intl.formatMessage({ id: 'savedSuccessfully' }), MessageType.SUCCESS);
      } catch (error) {
        showResponseErrorMessage({ intl, error });
      }
    };

    if (
      customerCurrentRatesheet &&
      customerCurrentRatesheet.type === UnsolicitedRatesheetBespokeType &&
      (!customerCurrentRatesheet.ratesheet || isBeforeNow(customerCurrentRatesheet.ratesheet.expiresAt))
    ) {
      return (
        <Column>
          <Collapse openMotion={collapseMotion} expandIcon={expandIcon} defaultActiveKey={[panelKeys.currentRatesheet]}>
            <Panel
              key={panelKeys.currentRatesheet}
              header={<FormattedMessage id="unsolicitedRatesheetNoBespokePanelTitle" tagName="h3" />}
            >
              <Alert bsStyle="warning">
                <FaExclamationCircle />
                <FormattedMessage id="unsolicitedRatesheetNoBespoke" />
              </Alert>
              <Column flex>
                <Button
                  type="button"
                  onClick={() => onSendBespokeRatesheets({ investorTenantId: selectedCustomer.investorTenantId })}
                >
                  <FormattedMessage id="sendBespokeRatesheets" />
                </Button>
              </Column>
            </Panel>
          </Collapse>
        </Column>
      );
    }

    const isActOnBehalfEnabled =
      customerCurrentRatesheet &&
      ((customerCurrentRatesheet.type === UnsolicitedRatesheetDefaultType && !isLatestTermDepositRatesheetStale) ||
        customerCurrentRatesheet.type === UnsolicitedRatesheetBespokeType);

    return (
      <Column>
        {customerCurrentRatesheet && (
          <Collapse openMotion={collapseMotion} expandIcon={expandIcon} defaultActiveKey={[panelKeys.currentRatesheet]}>
            <Panel
              key={panelKeys.currentRatesheet}
              header={
                <FormattedMessage
                  id="currentRatesheet"
                  values={{
                    type: customerCurrentRatesheet.type,
                    updatedAt: new Date(customerCurrentRatesheet.ratesheet.updatedAt),
                    small: (msg) => <small>{msg}</small>,
                  }}
                  tagName="h3"
                />
              }
            >
              {customerCurrentRatesheet.type === UnsolicitedRatesheetDefaultType && (
                <React.Fragment>
                  {isLatestTermDepositRatesheetStale && (
                    <Alert bsStyle="warning">
                      <FaExclamationCircle />
                      <FormattedMessage id="unsolicitedRatesheetStale" />
                    </Alert>
                  )}
                  <Column>
                    <UpdateRatesheetButton tooltipPlacement="bottom" onUpdateRatesheetSuccess={onRatesheetUpdate} />
                  </Column>
                </React.Fragment>
              )}
              <Tenors
                readOnly={!isActOnBehalfEnabled || !!selectedTenor}
                status="highlight"
                ratesheet={customerCurrentRatesheet.ratesheet}
                selectedTenor={selectedTenor?.tenor}
                isLoading={isUnsolicitedRatesheetLoading}
                onClick={({ tenor, rate, ref }) => {
                  setTenorItemRef(ref);
                  setSelectedTenor({ tenor, rate });
                }}
              />
              {selectedTenor && selectedCustomer && (
                <UnsolicitedRatesheetOnBehalfPopover
                  customer={selectedCustomer}
                  onConfirm={onConfirm}
                  tenor={selectedTenor?.tenor}
                  rate={selectedTenor?.rate}
                  show={!!selectedTenor}
                  onHide={() => setSelectedTenor(undefined)}
                  targetRef={tenorItemRef}
                  unsolicitedRatesheetMaxBankAmount={unsolicitedRatesheetMaxBankAmount}
                />
              )}
            </Panel>
          </Collapse>
        )}
      </Column>
    );
  },
);

UnsolicitedRatesheetDetailsRatesheetComponent.propTypes = {
  onAddOnBehalfConfirm: PropTypes.func,
  onRatesheetUpdate: PropTypes.func,
  onSendBespokeRatesheets: PropTypes.func,
  isUnsolicitedRatesheetLoading: PropTypes.bool,
  selectedCustomer: PropTypes.shape(),
  customerCurrentRatesheet: PropTypes.shape({
    type: PropTypes.string,
    ratesheet: PropTypes.shape(),
  }),
};

UnsolicitedRatesheetDetailsRatesheetComponent.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => {
  const tenantSettings = getSettings(state);
  const isLatestTermDepositRatesheetStale = isTdRatesheetStale(state);

  return {
    unsolicitedRatesheetMaxBankAmount: tenantSettings && tenantSettings.unsolicitedRatesheetMaxBankAmount,
    isLatestTermDepositRatesheetStale,
  };
};

export const UnsolicitedRatesheetDetailsCurrentRatesheet = connect(mapStateToProps)(
  UnsolicitedRatesheetDetailsRatesheetComponent,
);
