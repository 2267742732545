import { handleActions } from 'redux-actions';

import { listUnsettledTradesReducer } from './list';

export { listUnsettledTradesAction } from './list';

const initialState = {
  isFetching: false,
  list: [],
  count: 0,
};

const combinedReducers = {
  ...listUnsettledTradesReducer,
};

export const unsettledTrades = handleActions(combinedReducers, initialState);
