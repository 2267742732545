import './outgoing-rfqs.scss';

import React, { Component } from 'react';

import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as holdingActions from '../../actions/holding/holding';
import * as maturingActions from '../../actions/holding/maturing';
import * as quotesActions from '../../actions/holding/rfq/quotes';
import * as issuersActions from '../../actions/issuers';
import * as portfolioActions from '../../actions/portfolio';
import * as sessionActions from '../../actions/session';
import { hasDefaultTPlusNDaysSelector } from '../../actions/session-selector';
import rooms from '../../api/socket/rooms';
import { socketEvents } from '../../api/socket/setup-socket';
import { PulseBlob, ResultsPresenter } from '../../components/common';
import includeSocket from '../../components/hoc/include-socket';
import withConfirmDialog from '../../components/hoc/with-confirm-dialog';
import { withNavigate } from '../../components/hoc/with-router-properties';
import { ConfirmRedemption } from '../../components/holding/maturing/confirm-redemption';
import * as newFundsPresenter from '../../components/holding/maturing/new-funds-records-presenter';
import * as recordsPresenter from '../../components/holding/maturing/records-presenter';
import SummaryValueHighlight from '../../components/holding/summary-value-highlight';
import { getOutstandingOffersAction } from '../../ducks/offers';
import { routes } from '../../routes';
import { Money } from '../money';
import { MessageType, showToastMessage } from '../toast/toast';

function mapStateToProps(state) {
  return {
    holding: state.holding,
    maturing: state.maturing,
    quotes: state.quotes,
    issuers: state.issuers,
    tenant: state.tenant,
    offers: state.offers,
    licences: state.session.user.licences,
    hasDefaultTPlusNDays: hasDefaultTPlusNDaysSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      holding: bindActionCreators(holdingActions, dispatch),
      maturing: bindActionCreators(maturingActions, dispatch),
      portfolio: bindActionCreators(portfolioActions, dispatch),
      quotes: bindActionCreators(quotesActions, dispatch),
      session: bindActionCreators(sessionActions, dispatch),
      issuers: bindActionCreators(issuersActions, dispatch),
      getOutstandingOffers: bindActionCreators(getOutstandingOffersAction, dispatch),
    },
  };
}

export class OutgoingRfqsComponent extends Component {
  componentDidMount() {
    this.props.actions.holding.fetchTradesSummaryIfNeeded();
    this.props.actions.holding.fetchTradesMaturingIfNeeded();
    this.props.actions.maturing.fetchIfNeeded();
    this.props.actions.quotes.fetchRfqIfNeeded();
    this.props.actions.issuers.fetchIssuers();
    this.props.actions.getOutstandingOffers();

    this.confirmRedeemDialog = {
      titleId: 'confirmRedeemTitle',
      contentId: 'confirmRedeemBody',
      cancelId: 'confirmRedeemNegativeAnswer',
      confirmId: 'confirmRedeemPositiveAnswer',
    };
    this.confirmRfqDialog = {
      titleId: 'confirmRfqTitle',
      contentId: 'confirmRfqBody',
      cancelId: 'confirmRfqNegativeAnswer',
      confirmId: 'confirmRfqPositiveAnswer',
    };
    this.confirmReinvestmentWithOffer = {
      titleId: 'confirmReinvestTitle',
      contentId: 'confirmRfqReinvestWithOffer',
      defaultCancelButton: 'defaultConfirmButton',
      confirmId: 'defaultConfirmButton',
    };

    this.props.on(socketEvents.rfqUpdated, () => {
      this.reloadMaturingTrades();
      this.props.actions.holding.fetchTradesMaturingIfNeeded({
        silentFetch: true,
      });
    });

    this.props.on(socketEvents.offersUpdated, () => {
      this.props.actions.getOutstandingOffers();
    });
  }

  componentDidUpdate(prevProps) {
    const {
      quotes: { error, confirmingFunds },
    } = this.props;

    if (!prevProps.quotes.error && error) {
      this.showErrorMessageWhenAcceptingQuote(this.props);
    }

    if (!confirmingFunds && prevProps.quotes.confirmingFunds) {
      this.reloadMaturingTrades();
    }
  }

  onConfirm() {
    this.props.toggleConfirmDialog();
  }

  onRedeemConfirm(trade, tenants) {
    this.props.actions.maturing.confirmRedeem(trade, tenants);
    this.props.actions.portfolio.refreshPortfolio({ refresh: true });
  }

  getTradesWithReinvestmentData({ trades, quotes, offers }) {
    const reinvestments =
      trades &&
      trades
        .map((trade) => this.buildRecordPresented({ trade, quotes, offers }))
        .filter(({ rfqConfirmed }) => !rfqConfirmed);

    return lodash.orderBy(reinvestments, 'id', 'desc');
  }

  buildRecordPresented({ trade, quotes, offers }) {
    const { rfqs, confirmingFunds } = quotes;
    const rfqForTrade = rfqs ? rfqs.find((rfq) => rfq.tradeId === trade.id && rfq.rfqStatus !== 'cancelled') : {};
    const offer = offers && offers.find(({ tradeId }) => tradeId === trade.id);

    return {
      ...rfqForTrade,
      ...trade,
      offer,
      additionalFunds: this.calculateAdditionalFunds(trade, rfqForTrade),
      isConfirming: trade.id === confirmingFunds,
    };
  }

  reloadMaturingTrades() {
    this.props.actions.portfolio.refreshPortfolio({
      refresh: true,
      silentFetch: true,
    });
    this.props.actions.maturing.fetchIfNeeded({ silentFetch: true });
  }

  changeView(view) {
    this.props.navigate(view);
  }

  reinvest = (trade) => {
    if (!trade.offer) {
      this.changeView(routes.holdings.rfq.replace(':id', trade.id));

      return;
    }

    const { onConfirmClick, toggleConfirmDialog } = this.props;
    const { confirmReinvestmentWithOffer } = this;

    onConfirmClick(() => {
      this.changeView(routes.holdings.rfq.replace(':id', trade.id));
      toggleConfirmDialog({
        ...confirmReinvestmentWithOffer,
        content: null,
      });
    });

    toggleConfirmDialog({
      ...confirmReinvestmentWithOffer,
    });
  };

  redeem = async (trade) => {
    const { actions, onConfirmClick, toggleConfirmDialog } = this.props;
    const { confirmRedeemDialog } = this;

    onConfirmClick(async () => {
      const tradeIssuer = this.props.issuers.data.find((issuer) => trade.issuer && trade.issuer.code === issuer.code);
      const tenants = tradeIssuer && tradeIssuer.domain && [tradeIssuer.domain];

      this.props.updateConfirmationProperties({
        disabled: true,
        isConfirming: true,
      });

      await actions.maturing.redeem(trade, tenants);

      await this.confirmReinvestment(trade);

      toggleConfirmDialog({
        ...confirmRedeemDialog,
        disabled: undefined,
        isConfirming: false,
        content: null,
      });
    });

    toggleConfirmDialog({
      ...confirmRedeemDialog,
      content: <ConfirmRedemption firstParagraph={trade.offer ? 'confirmRedeemBodyWithOffer' : 'confirmRedeemBody'} />,
      contentId: null,
    });
  };

  viewOffer = ({ offer }) => {
    this.props.navigate(routes.offers.details.replace(':id', offer.id));
  };

  confirmReinvestment = async (confirmedTrade) => {
    const issuersCodes = (confirmedTrade.issuers && confirmedTrade.issuers.map((issuer) => issuer.code)) || [];

    if (confirmedTrade.issuer && !issuersCodes.includes(confirmedTrade.issuer.code)) {
      issuersCodes.push(confirmedTrade.issuer.code);
    }

    const tenants = this.props.issuers.data
      .filter((issuer) => issuersCodes.includes(issuer.code))
      .map((issuer) => issuer.domain);
    tenants.push(this.props.tenant);

    return this.onRedeemConfirm(confirmedTrade, tenants);
  };

  viewReceivedQuotes = ({ uuid }) => {
    this.props.navigate(routes.holdings.quotesDetails.replace(':uuid', uuid));
    this.props.actions.quotes.orderRfqByUuid(uuid);
  };

  showErrorMessageWhenAcceptingQuote = ({ quotes }) => {
    const errorMessage = quotes.error.data && quotes.error.data.error_message;
    const quoteNotAccepted = errorMessage === 'accepted-quote-not-found';
    const message = quoteNotAccepted ? 'rfqQuoteNotAcceptedError' : 'rfqConfirmError';

    showToastMessage(this.props.intl.formatMessage({ id: message }), MessageType.ERROR);
  };

  calculateAdditionalFunds = (trade, rfq) =>
    trade && rfq && (rfq.principal > trade.principal || rfq.principal < trade.principal)
      ? rfq.principal - trade.principal
      : 0;

  hasQuotesAvailable = (rfqs) => {
    const openedRfqs = rfqs.filter(
      ({ rfqStatus, rfqConfirmed }) => rfqStatus === 'open' || (rfqStatus === 'closed' && rfqConfirmed === false),
    );

    return openedRfqs.reduce((acc, rfq) => acc + rfq.quotesReceived, 0) > 0;
  };

  renderOverallInvestments(general) {
    const generalCountSpan = (
      <FormattedMessage id="portfolio.filter-investments-number" values={{ length: general.count }} />
    );

    return general.count === 0 ? (
      generalCountSpan
    ) : (
      <Badge
        className="btn-solid-primary default-border-radius"
        onClick={() => this.changeView(routes.portfolioRoot.holdings)}
      >
        {generalCountSpan}
      </Badge>
    );
  }

  renderMaturingInvestments() {
    const { maturing: maturingState, quotes: quotesState, offers } = this.props;
    const { hasDefaultTPlusNDays } = this.props;
    const { maturing, isFetching } = maturingState;
    const { isFetching: isFetchingQuotes } = quotesState;
    const { columns, ...maturingInvestmentsPresenter } = recordsPresenter;

    if (!hasDefaultTPlusNDays) {
      delete columns.settlementDate;
    }

    const data = maturing?.trades?.length
      ? this.getTradesWithReinvestmentData({
          trades: maturing.trades,
          quotes: quotesState,
          offers: offers.list,
        })
      : null;

    const isLoading = isFetchingQuotes || isFetching;
    const noRecords = !data || !data.length;

    const payload = {
      isFetching: isLoading,
      noRecords,
      recordsPresenter: {
        data,
        ...maturingInvestmentsPresenter,
        columns,
        options: {
          hasDefaultTPlusNDays,
        },
        actions: {
          reinvest: this.reinvest,
          redeem: this.redeem,
          viewReceivedQuotes: this.viewReceivedQuotes,
          viewOffer: this.viewOffer,
        },
      },
      titleId: 'maturingTitle',
      noRecordsMessageId: 'emptyDefaultMessage',
      badge: !isLoading && !noRecords && <PulseBlob />,
    };

    return <ResultsPresenter {...payload} />;
  }

  renderNewFunds() {
    const { quotes: quotesState } = this.props;
    const { hasDefaultTPlusNDays } = this.props;
    const { rfqs, isFetching } = quotesState;
    const { columns, ...fundsPresenterRest } = newFundsPresenter;

    if (!hasDefaultTPlusNDays) {
      delete columns.settlementDate;
    }

    let funds = rfqs && rfqs.filter((rfq) => !rfq.tradeId && rfq.rfqStatus !== 'cancelled');
    funds = lodash.orderBy(funds, 'id', 'desc');

    const payload = {
      isFetching,
      noRecords: !funds.length,
      recordsPresenter: {
        data: funds,
        ...fundsPresenterRest,
        columns,
        options: {
          hasDefaultTPlusNDays,
          confirmingFunds: quotesState.confirmingFunds,
        },
        actions: {
          reinvest: this.reinvest,
          redeem: this.redeem,
          viewReceivedQuotes: this.viewReceivedQuotes,
        },
      },
      titleId: 'newFundsTitle',
      noRecordsMessageId: 'emptyDefaultMessage',
      badge: !isFetching && this.hasQuotesAvailable(funds) && <PulseBlob />,
    };

    return <ResultsPresenter {...payload} />;
  }

  renderCurrency = () => (
    <SummaryValueHighlight top={<FormattedMessage tagName="span" id="currency" />} middle={<Money long />} />
  );

  render() {
    return (
      <div className="rfqs">
        {this.renderMaturingInvestments()}
        {this.renderNewFunds()}
      </div>
    );
  }
}

OutgoingRfqsComponent.propTypes = {
  actions: PropTypes.shape().isRequired,
  holding: PropTypes.shape().isRequired,
  maturing: PropTypes.shape().isRequired,
  licences: PropTypes.arrayOf(PropTypes.string).isRequired,
  on: PropTypes.func,
  onConfirmClick: PropTypes.func,
  offers: PropTypes.shape().isRequired,
  params: PropTypes.shape(),
  quotes: PropTypes.shape().isRequired,
  toggleConfirmDialog: PropTypes.func,
  updateConfirmationProperties: PropTypes.func,
  issuers: PropTypes.shape().isRequired,
  hasDefaultTPlusNDays: PropTypes.bool.isRequired,
  tenant: PropTypes.string.isRequired,
};

OutgoingRfqsComponent.defaultProps = {
  params: {},
};

export const OutgoingRfqs = compose(
  includeSocket({ rooms: [rooms.rfq] }),
  withConfirmDialog(),
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withNavigate,
)(OutgoingRfqsComponent);

export default OutgoingRfqs;
