import * as holdingActions from '../../../actions/holding/save';
import BaseFields from '../base/fields';

const fields = [
  {
    name: 'instrumentCode',
    label: 'holdingInstrumentCode',
    componentType: 'async-select',
    loadOptions: (dataset) => dataset.availableInstrumentCodes,
  },
  {
    name: 'principal',
    label: 'principal',
    componentType: 'currency',
  },
  {
    name: 'interestPaidCode',
    label: 'interestPaid',
    componentType: 'select',
    options: holdingActions.codes.interests,
  },
  BaseFields.allocation,
];

export default fields;
