import axios from 'axios';

export default async function (file) {
  const url = '/uploads';

  const data = new FormData();
  data.append('file', file);
  data.append('type', 'trade');

  const result = await axios({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

  return result.data;
}
