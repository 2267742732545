const isUnderMoneyAndPctLimits = ({ item, type }) => item.investmentSharePct <= item[type] && item.amountAvailable >= 0;

export function buildLimits(data, max, min) {
  return data.map((item, index) => {
    let maxLimitPct = item[max];
    if (item.maxMoneyLimit > 0) {
      const moneyLimitPct = (100 * item.maxMoneyLimit) / item.grandTotal;
      maxLimitPct = Math.min(moneyLimitPct, maxLimitPct);
    }
    return [index, maxLimitPct, item[min] || 0];
  });
}

export function buildValuesAboveMoneyAndPctLimits(data, type) {
  return data.map((item, index) => (!isUnderMoneyAndPctLimits({ item, type }) ? [index, item.investmentSharePct] : 0));
}

export function buildValuesUnderMoneyAndPctLimits(data, type) {
  return data.map((item, index) => (isUnderMoneyAndPctLimits({ item, type }) ? [index, item.investmentSharePct] : 0));
}

export function buildValuesAboveLimit(data, type) {
  return data.map((item, index) => (item.investmentSharePct > item[type] ? [index, item.investmentSharePct] : 0));
}

export function buildValuesUnderLimit(data, type) {
  return data.map((item, index) => (item.investmentSharePct <= item[type] ? [index, item.investmentSharePct] : 0));
}

export function buildValuesWithinLimits(data) {
  return [...buildValuesAboveLimit(data, 'maxLimitPct'), ...buildValuesUnderLimit(data, 'minLimitPct')];
}

export const buildDashedSymbol = (ctx, x, y) => {
  ctx.setLineDash([3, 9]);
  ctx.beginPath();
  ctx.moveTo(x - 40, y);
  ctx.lineTo(x + 40, y);
};

export const nearestBase10 = (num) => {
  const ceil = Math.ceil(num / 10);
  const mod = num % 10;
  return mod > 0 ? ceil * 10 : (ceil + 1) * 10;
};

export function calculateMaxYAxis(series) {
  const max = Math.max(...series.map((item) => Math.max(...item.data.map((value) => (value ? value[1] : 0)))));

  return nearestBase10(max);
}
