import axios from 'axios';

export const getOutgoingRfqsWithQuotesUrl = '/rfqs/quotes';

export async function getOutgoingRfqsWithQuotes(params) {
  const { data } = await axios({
    method: 'get',
    url: getOutgoingRfqsWithQuotesUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
}

export async function getOutgoingRfqsWithQuotesWithUrl(url) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
