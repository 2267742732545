import '../default-table.scss';
import './table.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '../../../date';
import Row from './row';

const Table = ({ rfqQuotes }) => {
  const quotesWithBestCoupon = getBestCouponForQuotes(rfqQuotes.list);

  return (
    <table className="incoming-quotes-table table-striped">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="dashboard.dialog.bankColumn" />
          </th>
          {getColumns(rfqQuotes.maturities)}
        </tr>
      </thead>
      <tbody>{getRows(quotesWithBestCoupon)}</tbody>
    </table>
  );
};

const shortDateFormat = 'DD/MM/YYYY';

const getColumns = (maturities) =>
  (maturities &&
    maturities.map((maturity) =>
      maturity.maturityCode !== 'CUSTOM' ? (
        <th key={maturity.maturityCode}>{maturity.maturityCode}</th>
      ) : (
        <th key={maturity.maturityDate}>{formatDate(maturity.maturityDate, shortDateFormat)}</th>
      ),
    )) ||
  [];

const getRows = (quotes) =>
  quotes && quotes.map((quote) => <Row isOwnAdi={quote.isOwnAdi} rating={quote.rating} quotes={quote.quotes} />);

const getBestCouponForQuotes = (rfqQuotes) => {
  if (!rfqQuotes || !rfqQuotes.length) {
    return [];
  }

  const bestCouponForMaturityIds = getBestCouponForMaturityIds(rfqQuotes);

  return rfqQuotes.map((rfqQuote) => ({
    ...rfqQuote,
    quotes: rfqQuote.quotes.map((quote) =>
      bestCouponForMaturityIds[quote.maturityId] === quote.coupon ? { ...quote, isBestCoupon: true } : quote,
    ),
  }));
};

const getBestCouponForMaturityIds = (rfqQuotes) => {
  const bestCouponForMaturityId = {};

  rfqQuotes.forEach((rfqQuote) =>
    rfqQuote.quotes.forEach((quote) => {
      if (!bestCouponForMaturityId[quote.maturityId]) {
        bestCouponForMaturityId[quote.maturityId] = quote.coupon;
      }

      if (quote.coupon > bestCouponForMaturityId[quote.maturityId]) {
        bestCouponForMaturityId[quote.maturityId] = quote.coupon;
      }
    }),
  );

  return bestCouponForMaturityId;
};

Table.propTypes = {
  rfqQuotes: PropTypes.shape().isRequired,
};

export default Table;
