import React, { Component } from 'react';

import ConfirmDialog, {
  defaultDialogProperties,
} from '../common/confirm-dialog';

export default function withConfirmDialog(confirmationDialogProperties = defaultDialogProperties) {
  return function (WrappedComponent) {
    return class extends Component {
      constructor(props) {
        super(props);
        this.defaultConfirmationProperties = confirmationDialogProperties;
        this.state = {
          show: false,
          ...this.defaultConfirmationProperties,
        };
      }

      toggleDialog = (dialogProperties) => {
        this.setState({
          show: !this.state.show,
          ...this.defaultConfirmationProperties,
          ...dialogProperties,
        });
      };

      updateConfirmationProperties = (dialogProperties) => {
        this.setState({
          ...dialogProperties,
        });
      };

      onConfirmClick = (onClickHandler) => {
        this.onConfirm = onClickHandler;
      };

      hideDialog = (dialogProperties) => {
        this.setState({
          show: false,
          ...dialogProperties,
        });
      };

      renderConfirmationDialog() {
        return (
          this.onConfirm && <ConfirmDialog {...this.state} onCancel={this.hideDialog} onConfirm={this.onConfirm} />
        );
      }

      render() {
        return (
          <div>
            {this.renderConfirmationDialog()}
            <WrappedComponent
              {...this.props}
              toggleConfirmDialog={this.toggleDialog}
              hideConfirmDialog={this.hideDialog}
              onConfirmClick={this.onConfirmClick}
              updateConfirmationProperties={this.updateConfirmationProperties}
            />
          </div>
        );
      }
    };
  };
}
