import buildInitialValues from '../base/initial-values';

export default function () {
  const initialValues = buildInitialValues();
  return {
    ...initialValues,
    coupon: '0',
    interestPaidCode: 'm',
  };
}
