import './outgoing-quotes-dialog.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Loadable } from '../../common';

import Table from './table';

const OutgoingQuotesDialog = ({ onClose, show, rfqQuotes, isLoading }) => (
  <div className="static-modal">
    <Modal show={show} className="outgoing-quotes-dialog">
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="dashboard.dialog.outgoingRfqQuotes" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loadable isLoading={isLoading}>
          {rfqQuotes.list && rfqQuotes.list.length ? (
            <Table rfqQuotes={rfqQuotes} />
          ) : (
            <FormattedMessage id="noQuotesForThisRfq" />
          )}
        </Loadable>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} className="btn-solid-primary btn-save">
          <FormattedMessage id="close" />
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

OutgoingQuotesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  rfqQuotes: PropTypes.shape(),
};

export default OutgoingQuotesDialog;
