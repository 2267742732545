import axios from 'axios';

import { buildHeaders } from '../../index';

const isGlobalTenant = (tenant) => tenant === 'global';

export const buildRfqsListUrl = ({ tenant }) => (isGlobalTenant(tenant) ? '/adis/rfqs' : '/rfqs/incoming');

export const listRfqs = ({ tenant, params }) => async (url) => {
  const { data } = await axios({
    method: 'get',
    routeType: isGlobalTenant(tenant) ? 'global' : 'tenant',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
};

export default async function(params) {
  const isGlobalContext = params.tenant === 'global';
  const requestParams = {
    routeType: isGlobalContext ? 'global' : 'tenant',
    url: isGlobalContext ? '/adis/rfqs' : '/rfqs/incoming',
  };

  const headers = buildHeaders({ authorization: params.token });

  const { data } = await axios({
    ...requestParams,
    method: 'get',
    headers,
    params,
  });

  return data;
}
