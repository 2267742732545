import pallete from '../../theme/helpers/pallete.json';

export function series (data) {
  const result = [...data]
    .sort((x, y) => x.investmentSharePct > y.investmentSharePct)
    .map((item, index) => { // eslint-disable-line arrow-body-style
      return {
        data: [
          [item.investmentSharePct, index],
        ],
        color: pallete['light-orange'],
        bars: {
          align: 'center',
          fill: 1,
          lineWidth: 0,
          show: true,
          barWidth: 0.8,
          horizontal: true,
        },
        value: item.investmentSharePct,
      };
    });

  return result;
}

export function yTicks (data) {
  return [...data]
    .sort((x, y) => x.investmentSharePct > y.investmentSharePct)
    .map((item, index) =>
      [index, `${item.code.toUpperCase()} - ${item.investmentSharePct.toFixed(2)}%`]
    );
}
