export default function buildApiUrl(baseUrl, apiUrl) {
  return replaceSubdomainToTenant(apiUrl, getTenantFromBaseUrl(baseUrl));
}

export function getGlobalUrl(baseUrl, apiUrl) {
  return apiUrl.replace('%subdomain%', 'global');
}

export function replaceSubdomainToTenant(apiUrl, tenant) {
  return apiUrl.replace('%subdomain%', tenant);
}

export function getTenantFromBaseUrl(baseUrl) {
  const [tenant] = baseUrl.split('.');

  return tenant;
}
