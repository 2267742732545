import axios from 'axios';

export const fetchCounterpartyCompliances = async (params) => {
  const { data } = await axios({
    method: 'get',
    url: '/compliances/issuers',
    headers: { 'Content-Type': 'application/json' },
    params,
  });

  return data;
};

export default fetchCounterpartyCompliances;
