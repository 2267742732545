import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getInstrumentCode } from '../../api/holding/instrument-codes';
import capitalize from '../../capitalize';
import { Row, SummaryHighlight } from '../../components/common';
import { DateWithTimeZone } from '../../date';
import { percentual } from '../../format-numbers';

export const BankHeader = ({ tradeSelected }) => (
  <Row className="header" alignItemsCenter contentBetween>
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="dashboard.assetClass" />}
      middle={<div>{getInstrumentCode(tradeSelected.instrumentCode)}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="dashboard.customer" />}
      middle={<div>{tradeSelected.customerTenant.name}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="segment" />}
      middle={<div>{capitalize(tradeSelected.customerTenant.segmentCode)}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="holdingMaturityDate" />}
      middle={<DateWithTimeZone value={tradeSelected.maturityDate} />}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="coupon" />}
      middle={<FormattedNumber {...percentual} value={tradeSelected.coupon} />}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="holdingAllocationCode" />}
      middle={<div>{tradeSelected.allocationCode}</div>}
    />
  </Row>
);

BankHeader.propTypes = {
  tradeSelected: PropTypes.shape({
    customerTenant: PropTypes.shape({ name: PropTypes.string, segmentCode: PropTypes.string }),
    maturityDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    coupon: PropTypes.number,
    allocationCode: PropTypes.string,
    instrumentCode: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
};

BankHeader.defaultProps = {
  isLoading: false,
};
