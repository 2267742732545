import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import useSWR from 'swr';

import {
  buildListOutgoingSecuritiesRfqsUrl,
  listOutgoingSecuritiesRfqs,
} from '../../api/holding/rfq/list-outgoing-securities-rfqs';
import { PulseBlob } from '../../components/common';
import { hasOpenOutgoingSecuritiesRfq } from '../rfqs/buy-sell-bond-rfqs/outgoing-securities-rfqs/has-open-outgoing-securities-rfq';

const isRfqOpened = (status, confirmed) => status === 'open' || (status === 'closed' && confirmed === false);

export const RfqTabBadgeComponent = ({ holding, quotes }) => {
  const { data } = useSWR(buildListOutgoingSecuritiesRfqsUrl(), listOutgoingSecuritiesRfqs);

  const { maturing } = holding;
  const quotesRfqs = quotes.rfqs || [];

  const openedRfqs = quotesRfqs.filter(({ rfqStatus, rfqConfirmed }) => isRfqOpened(rfqStatus, rfqConfirmed));
  const quotesReceivedCount = openedRfqs.reduce((acc, rfq) => acc + rfq.quotesReceived, 0);

  const hasRfqsAction = maturing.sum > 0 || quotesReceivedCount > 0 || hasOpenOutgoingSecuritiesRfq(data);

  return (
    <React.Fragment>
      <FormattedMessage tagName="span" id="rfqs" />
      {hasRfqsAction && <PulseBlob />}
    </React.Fragment>
  );
};

RfqTabBadgeComponent.propTypes = {
  holding: PropTypes.shape(),
  quotes: PropTypes.shape(),
};

export const RfqTabBadge = connect(({ holding, quotes }) => ({
  holding,
  quotes,
}))(RfqTabBadgeComponent);
