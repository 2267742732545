import './incoming-page.scss';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as rfqsHistoryActions from '../../actions/reports/incoming-rfqs/rfqs-history';
import rooms from '../../api/socket/rooms';
import { getTenantSocket, socketEvents } from '../../api/socket/setup-socket';
import {
  Column,
  Header,
  licences,
  Protect,
  roles,
} from '../../components/common';
import { formatToShortDateWithoutTz, today } from '../../date';
import { ContainerTab } from '../home/Tabs/ContainerTab';
import { IncomingRfqs } from '../rfqs/incoming-rfqs';
import { LicencedTradeHistory } from './LicencedTradeHistory';
import { BlotterRfqsTab } from './tabs/BlotterRfqsTab';

const currentKey = 'current';
const historyKey = 'history';

const IncomingComponent = ({ session: { user }, incomingRfqsReport, enterQuotes, actions }) => {
  const tabs = [
    {
      isLoading: enterQuotes.isFetching,
      key: currentKey,
      href: currentKey,
      component: <FormattedMessage tagName="span" id="current" />,
      requiredTo: roles.finance,
      user,
    },
    {
      isLoading: incomingRfqsReport.isFetching,
      key: historyKey,
      href: historyKey,
      component: <FormattedMessage tagName="span" id="history" />,
      requiredTo: roles.finance,
      user,
    },
  ];

  const fromDate = formatToShortDateWithoutTz(today().add(-1, 'month'));
  const toDate = formatToShortDateWithoutTz(today());

  useEffect(() => {
    actions.rfqsHistory.fetchRfqsHistory({ fromDate, toDate });
  }, []);

  const socket = getTenantSocket();

  useEffect(() => {
    socket.emit(`client:${socketEvents.enterRoom}`, { rooms: [rooms.rfq] });
    socket.on(socketEvents.rfqUpdated, () => {
      actions.rfqsHistory.fetchRfqsHistory({ fromDate, toDate });
    });

    return () => {
      socket.emit(`client:${socketEvents.leaveRoom}`, { rooms: [rooms.rfq] });
      socket.off();
    };
  }, []);

  return (
    <Column className="incoming-page">
      <Header titleId="rfqEnterQuotes" />
      <ContainerTab tabKey="incoming-rfqs" tabs={tabs}>
        <Tab.Pane key={currentKey} eventKey={currentKey}>
          <IncomingRfqs hideHeader />
        </Tab.Pane>
        <Tab.Pane key={historyKey} eventKey={historyKey} mountOnEnter>
          <Protect user={user} licence={licences.rfqReporting} errorComponent={<LicencedTradeHistory />}>
            <BlotterRfqsTab />
          </Protect>
        </Tab.Pane>
      </ContainerTab>
    </Column>
  );
};

IncomingComponent.propTypes = {
  session: PropTypes.shape().isRequired,
  incomingRfqsReport: PropTypes.shape().isRequired,
  enterQuotes: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
  incomingRfqsReport: state.incomingRfqsReport,
  enterQuotes: state.enterQuotes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    rfqsHistory: bindActionCreators(rfqsHistoryActions, dispatch),
  },
});

export const IncomingPage = connect(mapStateToProps, mapDispatchToProps)(IncomingComponent);
