import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getInstrumentCode } from '../../api/holding/instrument-codes';
import { Row, SummaryHighlight } from '../../components/common';
import { DateWithTimeZone } from '../../date';
import { percentual } from '../../format-numbers';

export const InvestorHeader = ({ offerDetails, isLoading }) => (
  <Row className={cx('header', { 'is-loading': isLoading })} alignItemsCenter contentBetween>
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="dashboard.assetClass" />}
      middle={<div>{getInstrumentCode(offerDetails.instrumentCode)}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="bank" />}
      middle={<div>{offerDetails.bank && offerDetails.bank.issuer}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="sentBy" />}
      middle={<div>{offerDetails.bank && offerDetails.bank.sentBy}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="holdingMaturityDate" />}
      middle={<div>{offerDetails.maturityDate && <DateWithTimeZone value={offerDetails.maturityDate} />}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="coupon" />}
      middle={<div>{offerDetails.coupon && <FormattedNumber {...percentual} value={offerDetails.coupon} />}</div>}
    />
    <SummaryHighlight
      top={<FormattedMessage tagName="span" id="holdingAllocationCode" />}
      middle={<div>{offerDetails.allocationCode}</div>}
    />
  </Row>
);

InvestorHeader.propTypes = {
  offerDetails: PropTypes.shape({
    bank: PropTypes.shape({ sentBy: PropTypes.string, issuer: PropTypes.string }),
    maturityDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    coupon: PropTypes.number,
    allocationCode: PropTypes.string,
    instrumentCode: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

InvestorHeader.defaultProps = {
  offerDetails: {},
  isLoading: false,
};
