import { maturityFetchPayload } from '../../actions/compliances/maturity';
import { pagination } from '../common/pagination';
import { UPDATE_PORTFOLIO_SELECTED_DATE } from '../../actions/portfolio';

export const INITIAL_STATE = {
  hasInitiated: false,
  isFetching: false,
  didInvalidate: false,
  error: null,
  maturity: null,
  pagination,
  filter: null,
  availableFilters: {
    isCompliant: ['All', 'true', 'false'],
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case maturityFetchPayload.REQUEST: {
      const silentFetch = action.state && action.state.portfolio && action.state.portfolio.silentFetch;

      return {
        ...state,
        hasInitiated: true,
        isFetching: !silentFetch,
        error: null,
      };
    }
    case maturityFetchPayload.SUCCESS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        maturity: action.maturity,
        error: null,
      };
    case maturityFetchPayload.FAIL:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case maturityFetchPayload.UPDATE_FILTER:
      return {
        ...state,
        filteredData: null,
        filter: {
          ...action.newFilter,
        },
      };
    case maturityFetchPayload.UPDATE_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.filteredData,
      };
    case UPDATE_PORTFOLIO_SELECTED_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
        error: null,
      };
    default:
      return state;
  }
}
