import './portfolio-reports.scss';

import React, { Component } from 'react';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { hasLicences } from '../../../actions/session-selector';
import { sendFeedbackEmail } from '../../../api/feedback/send-feedback-email';
import {
  Column,
  ItemSelector,
  Loadable,
  RadioGroup,
  Range,
  Row,
} from '../../../components/common';
import { licences } from '../../../components/common/Protect';
import { newDate } from '../../../date';
import { downloadCombinedReportAction as downloadCombinedReport } from '../../../ducks/reports/download';
import {
  MessageType,
  showResponseErrorMessage,
  showToastMessage,
} from '../../toast/toast';

const mapStateToProps = (state) => ({
  combinedReports: state.combinedReportsDuck,
  session: state.session,
  currentTenantDomain: state.tenant,
  hasPortfolioReportLicence: hasLicences(licences.reportingPortfolio)(state),
});

const mapDispatchToProps = {
  downloadCombinedReport,
};

const fileTypes = [
  { value: 'pdf', labelId: 'pdf' },
  { value: 'excel', labelId: 'excel' },
];
export class PortfolioReports extends Component {
  state = {
    selectedReports: {
      standard: [],
      transactions: [],
      extras: [],
    },
    selectedMonthlyReportRange: {
      from: moment().add(-1, 'month').startOf('month').toDate(),
      to: moment().add(-1, 'month').endOf('month').toDate(),
    },
    fileType: 'pdf',
    isSendingMessage: false,
  };

  renderMonthlyReportSelector() {
    const { combinedReports, hasPortfolioReportLicence } = this.props;
    const {
      selectedMonthlyReportRange: { from, to },
    } = this.state;

    const newDateTo = newDate(to);
    const newDateFrom = newDate(from);

    const differenceBetweenFromAndToErrorMessageId =
      newDateTo.diff(newDateFrom, 'y', true) > 1 ? 'shouldNotBeGreaterThanAYear' : '';

    return (
      <div
        className={cx('monthly-report-selector', {
          'is-disabled': !hasPortfolioReportLicence,
        })}
      >
        <Range
          from={from}
          to={to}
          errorMessageId={differenceBetweenFromAndToErrorMessageId}
          onChangeFrom={this.onChangeFrom}
          onChangeTo={this.onChangeTo}
          disabled={!hasPortfolioReportLicence || combinedReports.downloading}
          minDate={new Date()}
          maxDate={new Date()}
        />
        <Column className="format-selector">
          <div className="format">
            <FormattedMessage id="format" className="format" />
          </div>
          <RadioGroup
            selectedItem={this.state.fileType}
            items={fileTypes}
            disabled={!hasPortfolioReportLicence}
            onChange={(value) => this.setState({ fileType: value })}
          />
        </Column>
      </div>
    );
  }

  onChangeFrom = (date) => {
    const { selectedMonthlyReportRange } = this.state;

    this.setState({
      selectedMonthlyReportRange: {
        ...selectedMonthlyReportRange,
        from: moment(date).toDate(),
      },
    });
  };

  onChangeTo = (date) => {
    const { selectedMonthlyReportRange } = this.state;

    this.setState({
      selectedMonthlyReportRange: {
        ...selectedMonthlyReportRange,
        to: moment(date).toDate(),
      },
    });
  };

  onStandardSelectAll = (isEverythingSelected) => {
    this.onSelectAll('standard', isEverythingSelected);
  };

  onTransactionsSelectAll = (isEverythingSelected) => {
    this.onSelectAll('transactions', isEverythingSelected);
  };

  onExtrasSelectAll = (isEverythingSelected) => {
    this.onSelectAll('extras', isEverythingSelected);
  };

  onStandardReportChange = (value) => {
    this.onReportChange('standard', value);
  };

  onTransactionsReportChange = (value) => {
    this.onReportChange('transactions', value);
  };

  onExtrasReportChange = (value) => {
    this.onReportChange('extras', value);
  };

  onReportChange = (reportsProperty, value) => {
    this.setState((state) => ({
      selectedReports: {
        ...state.selectedReports,
        [reportsProperty]: this.getNextSelectedItems(value, state.selectedReports[reportsProperty]),
      },
    }));
  };

  onSelectAll = (reportsProperty, isEverythingSelected) => {
    this.setState((state) => ({
      selectedReports: {
        ...state.selectedReports,
        [reportsProperty]: isEverythingSelected
          ? []
          : [...this.props.combinedReports.availableReports[reportsProperty]],
      },
    }));
  };

  getNextSelectedItems = (value, selected) => {
    const nextSelected = [...selected];

    nextSelected.includes(value)
      ? nextSelected.splice(
          nextSelected.findIndex((currentValue) => currentValue === value),
          1,
        )
      : nextSelected.push(value);

    return nextSelected;
  };

  downloadCombinedReport = () => {
    const { selectedReports, selectedMonthlyReportRange, fileType } = this.state;
    const { from, to } = selectedMonthlyReportRange;
    const tenantName = this.props.session.user.tenants.find(
      (tenant) => tenant.domain === this.props.currentTenantDomain,
    ).name;

    const reports = Object.keys(selectedReports).reduce(
      (combinedReports, reportKey) => combinedReports.concat(selectedReports[reportKey]),
      [],
    );

    const shortDateFormat = 'YYYY-MM-DD';

    this.props.downloadCombinedReport({
      from: moment(from).format(shortDateFormat),
      to: moment(to).format(shortDateFormat),
      tenantName,
      domain: this.props.currentTenantDomain,
      email: this.props.session.user.email,
      selectedReports: reports,
      fileType,
    });
  };

  isRangeInvalid = () => {
    const {
      selectedMonthlyReportRange: { from, to },
    } = this.state;

    const newDateTo = newDate(to);
    const newDateFrom = newDate(from);

    return to < from || newDateTo.diff(newDateFrom, 'y', true) > 1;
  };

  shouldMonthlyDownloadBeDisabled = () => {
    const {
      selectedReports: { standard, transactions, extras },
    } = this.state;

    const isRangeInvalid = this.isRangeInvalid();

    return (
      isRangeInvalid ||
      this.props.combinedReports.downloading ||
      !(standard.length || transactions.length || extras.length)
    );
  };

  sendLicenceEnquiry = async () => {
    this.setState({ isSendingMessage: true });

    try {
      const message = `ATT COMMERCIAL: Customer ${window.location.origin} is interested on licence ${licences.reportingPortfolio}`;
      await sendFeedbackEmail(message);

      showToastMessage(this.props.intl.formatMessage({ id: 'messageSent' }), MessageType.SUCCESS);
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    } finally {
      this.setState({ isSendingMessage: false });
    }
  };

  renderFeatureUpSell = () => (
    <div className="feature-upsell-container">
      <span className="feature-upsell-message">
        <FormattedMessage id="portfolioReportsAvailableForCustomers" />
      </span>
      <button
        type="button"
        disabled={this.state.isSendingMessage}
        className="primary-button"
        onClick={this.sendLicenceEnquiry}
      >
        <Loadable isLoading={this.state.isSendingMessage} size="sm">
          <FormattedMessage id="contactUs" />
        </Loadable>
      </button>
    </div>
  );

  renderDownloadMonthlyReportButton = () => {
    if (!this.props.hasPortfolioReportLicence) return this.renderFeatureUpSell();

    return (
      <button
        type="button"
        disabled={this.shouldMonthlyDownloadBeDisabled()}
        className="btn-download-reports primary-button"
        onClick={this.downloadCombinedReport}
      >
        <Loadable isLoading={this.props.combinedReports.downloading} size="sm">
          <FormattedMessage id="download" />
        </Loadable>
      </button>
    );
  };

  renderReportsSelectors = () => {
    const { intl } = this.props;
    const { selectedReports } = this.state;
    const { combinedReports } = this.props;
    const { standard, transactions, extras } = combinedReports.availableReports;

    return (
      <Row className="selectors-container" contentBetween>
        <ItemSelector
          name="standard"
          selectedItems={selectedReports.standard}
          items={standard.map((reportKey) => ({
            id: `reports.${reportKey}`,
            value: reportKey,
          }))}
          onChange={this.onStandardReportChange}
          onSelectAllClick={this.onStandardSelectAll}
          title={intl.formatMessage({ id: 'reports.standard' })}
        />
        <ItemSelector
          name="transactions"
          selectedItems={selectedReports.transactions}
          items={transactions.map((reportKey) => ({
            id: `reports.${reportKey}`,
            value: reportKey,
          }))}
          onChange={this.onTransactionsReportChange}
          onSelectAllClick={this.onTransactionsSelectAll}
          title={intl.formatMessage({ id: 'reports.transactions' })}
        />
        <ItemSelector
          name="extras"
          selectedItems={selectedReports.extras}
          items={extras.map((reportKey) => ({
            id: `reports.${reportKey}`,
            value: reportKey,
          }))}
          onChange={this.onExtrasReportChange}
          onSelectAllClick={this.onExtrasSelectAll}
          title={intl.formatMessage({ id: 'reports.extras' })}
        />
      </Row>
    );
  };

  render() {
    const { combinedReports } = this.props;

    return (
      <div
        className={cx('reports-by-period-container', {
          disabled: combinedReports.downloading,
        })}
      >
        <Row alignSelfStart className="reports-tab-container-header">
          <FormattedMessage id="selectPeriod" />
        </Row>
        <Row className="datepicker-container" alignItemsCenter contentBetween>
          <div className="month-picker">{this.renderMonthlyReportSelector()}</div>
        </Row>
        <Row className="reports-tab-container-header" alignItemsCenter contentBetween>
          <FormattedMessage id="customizeReport" />
          <div className="download-button-wrapper">{this.renderDownloadMonthlyReportButton()}</div>
        </Row>
        {this.renderReportsSelectors()}
      </div>
    );
  }
}

PortfolioReports.propTypes = {
  combinedReports: PropTypes.shape().isRequired,
  currentTenantDomain: PropTypes.string.isRequired,
  session: PropTypes.shape().isRequired,
  downloadCombinedReport: PropTypes.func.isRequired,
  hasPortfolioReportLicence: PropTypes.bool,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(PortfolioReports);
