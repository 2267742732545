import './add-trade.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { change, getFormValues } from 'redux-form';
import { mutate } from 'swr';

import {
  fetchTradesMaturingIfNeeded,
  fetchTradesSummaryIfNeeded,
  refreshHolding,
} from '../../actions/holding/holding';
import * as maturingActions from '../../actions/holding/maturing';
import * as quotesActions from '../../actions/holding/rfq/quotes';
import * as holdingActions from '../../actions/holding/save';
import { refreshPortfolio } from '../../actions/portfolio';
import * as sessionActions from '../../actions/session';
import { getTradesMaturingSummaryUrl } from '../../api/holding/maturing-summary';
import rooms from '../../api/socket/rooms';
import logoGrey from '../../assets/icons/common/logo_grey.png';
import { Column, Dropdown } from '../../components/common';
import { Protect, roles } from '../../components/common/Protect';
import { Forbidden } from '../../components/error/403-forbidden';
import includeSocket, {
  socketEvents,
} from '../../components/hoc/include-socket';
import withAppContext from '../../components/hoc/with-app-context';
import withConfirmDialog from '../../components/hoc/with-confirm-dialog';
import addCPForm from '../../components/holding/add-cp';
import addECDForm from '../../components/holding/add-ecd';
import buyBondsForm from '../../components/holding/bonds';
import buyFRNForm from '../../components/holding/buy-frn';
import buyFRTDForm from '../../components/holding/buy-frtd';
import addCashForm from '../../components/holding/cash';
import sellBondsForm from '../../components/holding/sell-bonds';
import sellCPForm from '../../components/holding/sell-cp';
import sellECDForm from '../../components/holding/sell-ecd';
import sellFrnForm from '../../components/holding/sell-frn';
import { addTermAnnuityForm } from '../../components/holding/term-annuity';
import addTermDepositForm from '../../components/holding/term-deposits';
import updateCashForm from '../../components/holding/update-cash';
import { isAfterToday } from '../../date';
import { listUnsettledTradesAction } from '../../ducks/unsettled/list';
import parseFloat from '../../parse-float';
import { getMoneySymbol } from '../money';
import { MessageType, showToastMessage } from '../toast/toast';

export const addTradeFormByInstrumentCode = {
  TD: 'addTd',
  CD: 'addECD',
  CP: 'addCP',
  BOND: 'buyBonds',
  FRN: 'buyFrn',
};

const buildInitialState = () => ({
  currentForm: null,
});

export class AddTradePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = buildInitialState();
    this.createForms();
  }

  static getDerivedStateFromProps(props, state) {
    if (state.currentForm && state.currentForm?.value && !props.appContext.isAddTradeOpen) {
      return buildInitialState();
    }

    return state;
  }

  componentDidMount() {
    this.props.actions.holding.fetchIssuersIfNeeded();
    this.props.actions.holding.fetchTermDepositIssuersIfNeeded();
    this.props.actions.holding.fetchIsinsIfNeeded();
    this.props.actions.holding.fetchSellableHoldingsIfNeeded();
    this.props.actions.fetchSummary();
  }

  componentDidUpdate() {
    const { actions, holding, intl, formValues } = this.props;

    this.handleInitialAddTradeValues();

    if (holding && holding.error) {
      showToastMessage(intl.formatMessage({ id: 'internalError.info' }));
      actions.holding.reset();

      return;
    }

    if (holding && !holding.isSaved) {
      return;
    }

    actions.refreshPortfolio({ refresh: true });
    actions.fetchSummary();
    actions.fetchTradesMaturingIfNeeded();
    actions.maturing.fetchIfNeeded();

    mutate(getTradesMaturingSummaryUrl);

    if (isAfterToday(formValues.purchaseDate)) {
      actions.listUnsettledTradesAction();
    }

    showToastMessage(intl.formatMessage({ id: 'tradeSavedSuccessfully' }), MessageType.SUCCESS);

    actions.holding.reset();
    actions.holding.fetchSellableHoldingsIfNeeded();
    actions.quotes.fetchRfqs();
  }

  componentWillUnmount() {
    this.props.actions.holding.reset();
  }

  handleInitialAddTradeValues = () => {
    const { actions, appContext } = this.props;

    if (
      !this.state.currentForm?.value &&
      appContext.initialAddTradeValues &&
      appContext.initialAddTradeValues.currentForm
    ) {
      const { currentForm, ...initialTradeDetails } = appContext.initialAddTradeValues;

      this.switchForm(currentForm, () => {
        actions.initializeAddTradeForm(initialTradeDetails);
        appContext.setInitialAddTradeValues(null);
      });
    }
  };

  onSave = (data) => {
    const { currentForm } = this.state;

    this.notifySocketServer(data);

    currentForm.save(data);
  };

  notifySocketServer = (data) => {
    const selectedIssuer = this.props.saveHolding.issuers.find(({ value: issuerId }) => issuerId === data.issuerId);

    if (!selectedIssuer || !selectedIssuer.domain) {
      return;
    }

    [socketEvents.offersUpdated, socketEvents.rfqUpdated].forEach((event) =>
      this.props.emit(event, {
        tenants: [selectedIssuer.domain],
        room: rooms.offers,
      }),
    );
  };

  onBeforeSell = (data, actionName) => {
    const { formValues, onConfirmClick, toggleConfirmDialog } = this.props;

    const { isin, quantityToKeep } = formValues;

    if (!isin || !quantityToKeep) {
      return;
    }

    const parsedIsin = JSON.parse(isin.value);
    const isinAmount = parsedIsin && parsedIsin.amount;
    const keep = parseFloat(quantityToKeep);
    const sell = parseFloat(isinAmount) - keep;

    onConfirmClick(() => {
      const saveFunction = this.props.actions.holding[actionName];
      saveFunction(data);
      this.props.toggleConfirmDialog();
      this.props.actions.holding.reset();
    });

    toggleConfirmDialog({
      contentValues: { sell, keep, isin: parsedIsin.label },
    });

    this.props.setIsAddTradeOpen(false);
  };

  createForms = () => {
    const {
      actions: { holding },
      intl,
    } = this.props;

    const {
      user: { currency },
    } = this.props.session;

    const currencySymbol = getMoneySymbol({ currency, short: true });

    this.forms = {
      addTd: addTermDepositForm(holding.addTermDeposit, currencySymbol),
      addCash: addCashForm(holding.addCash, currencySymbol),
      updateCash: updateCashForm(holding.updateCash, currencySymbol),
      buyBonds: buyBondsForm(holding.buyBonds, currencySymbol),
      sellBonds: sellBondsForm((data) => this.onBeforeSell(data, 'sellBonds'), intl, currencySymbol),
      buyFrn: buyFRNForm(holding.buyFrn, currencySymbol),
      sellFrn: sellFrnForm((data) => this.onBeforeSell(data, 'sellFrn'), intl, currencySymbol),
      addCP: addCPForm(holding.addCp, currencySymbol),
      sellCp: sellCPForm(holding.sellCp, intl),
      addECD: addECDForm(holding.addEcd, currencySymbol),
      sellEcd: sellECDForm(holding.sellEcd, intl),
      buyFrtd: buyFRTDForm(holding.buyFrtd, currencySymbol),
      addTermAnnuity: addTermAnnuityForm({ onSave: holding.addTermAnnuity, currencySymbol }),
    };
  };

  shouldResetForm = () => {
    const { holding } = this.props;

    return holding && holding.isSaved;
  };

  switchForm = (currentForm, onSwitchFormSuccess) => {
    this.setState({ currentForm: this.forms[currentForm] }, onSwitchFormSuccess);
  };

  renderCurrentForm = () => {
    if (!this.state.currentForm || !this.state.currentForm.form) {
      return (
        <Column className="empty-form" alignItemsCenter contentCenter>
          <Column alignItemsCenter>
            <img alt="logo" src={logoGrey} />
            <FormattedMessage id="selectAnAction" />
          </Column>
        </Column>
      );
    }

    const { form: Form } = this.state.currentForm;
    const {
      holding,
      saveHolding,
      session: { user },
    } = this.props;

    return (
      <Form
        form="add-trade"
        isSaving={saveHolding.isSaving}
        onSubmit={this.onSave}
        shouldResetForm={this.shouldResetForm()}
        data={holding}
        customClass={this.state.currentForm.className}
        user={user}
      />
    );
  };

  renderFormSwitcher = () => {
    const { currentForm } = this.state;
    const { intl } = this.props;

    const forms = Object.keys(this.forms).map((key) => ({
      value: this.forms[key].value,
      label: intl.formatMessage({ id: this.forms[key].title }),
    }));

    return (
      <form className="form-switcher">
        <Dropdown
          labelId="holdingsOperationTitle"
          name="select-instrument"
          value={forms.find(({ value }) => value === currentForm?.value) || null}
          onChange={(value) => this.switchForm(value)}
          options={forms}
        />
      </form>
    );
  };

  render() {
    const { user } = this.props.session;

    return (
      <Protect user={user} requiredTo={roles.finance} errorComponent={<Forbidden />}>
        <div className="add-trade">
          {this.renderFormSwitcher()}
          {this.renderCurrentForm()}
        </div>
      </Protect>
    );
  }
}

AddTradePage.propTypes = {
  appContext: PropTypes.shape(),
  actions: PropTypes.shape().isRequired,
  holding: PropTypes.shape().isRequired,
  saveHolding: PropTypes.shape().isRequired,
  formValues: PropTypes.shape(),
  onConfirmClick: PropTypes.func,
  isAddTradeOpen: PropTypes.bool,
  setIsAddTradeOpen: PropTypes.func,
  session: PropTypes.shape().isRequired,
  emit: PropTypes.func,
  toggleConfirmDialog: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues('add-trade')(state),
  holding: state.saveHolding,
  session: state.session,
  saveHolding: state.saveHolding,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    listUnsettledTradesAction: bindActionCreators(listUnsettledTradesAction, dispatch),
    fetchSummary: bindActionCreators(fetchTradesSummaryIfNeeded, dispatch),
    holding: bindActionCreators(holdingActions, dispatch),
    maturing: bindActionCreators(maturingActions, dispatch),
    refreshHolding: bindActionCreators(refreshHolding, dispatch),
    fetchTradesMaturingIfNeeded: bindActionCreators(fetchTradesMaturingIfNeeded, dispatch),
    refreshPortfolio: bindActionCreators(refreshPortfolio, dispatch),
    session: bindActionCreators(sessionActions, dispatch),
    quotes: bindActionCreators(quotesActions, dispatch),
    initializeAddTradeForm: (data) => {
      Object.keys(data).forEach((field) => dispatch(change('add-trade', field, data[field], true)));
    },
  },
});

export const AddTrade = compose(
  withConfirmDialog({
    titleId: 'confirmSavingTitle',
    contentId: 'confirmSaveSellBonds',
    cancelId: 'confirmSavingNegativeAnswer',
    confirmId: 'confirmSavingPositiveAnswer',
  }),
  withAppContext(),
  connect(mapStateToProps, mapDispatchToProps),
  includeSocket({ rooms: [rooms.trades] }),
  injectIntl,
)(AddTradePage);
