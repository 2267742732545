import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { getTenantSocket } from '../../api/socket/setup-socket';

export { socketEvents } from '../../api/socket/setup-socket';

export default function includeSocket({ rooms }) {
  return function (WrappedComponent) {
    return class extends Component {
      static propTypes = {
        tenant: PropTypes.string,
      };

      socket = getTenantSocket();

      componentDidMount() {
        this.socket.emit('client:enter-room', { rooms });
      }

      on = (event, action) => this.socket.on(event.includes('server:') ? event : `server:${event}`, action);

      off = (event, action) => this.socket.off(event.includes('server:') ? event : `server:${event}`, action);

      emit = (event, action) => this.socket.emit(event.includes('client:') ? event : `client:${event}`, action);

      render() {
        return <WrappedComponent on={this.on} off={this.off} emit={this.emit} {...this.props} />;
      }
    };
  };
}
