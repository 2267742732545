import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FormControl, FormGroup } from 'react-bootstrap';
import ConfirmDialog from '../common/confirm-dialog';

export const FeedbackDialog = ({ show, onConfirm, onCancel, isLoading }) => {
  const [feedbackText, setFeedbackText] = useState(null);

  const onCloseClick = () => {
    setFeedbackText(null);
    onCancel();
  };

  const onConfirmClick = () => {
    setFeedbackText(null);
    onConfirm(feedbackText);
  };

  return (
    <ConfirmDialog
      isConfirming={isLoading}
      titleId="feedbackTitle"
      confirmId="save"
      cancelId="cancel"
      hasCancel
      show={show}
      onCancel={onCloseClick}
      onHide={onCloseClick}
      onConfirm={() => onConfirmClick(feedbackText)}
      disabled={!feedbackText}
    >
      <React.Fragment>
        <FormGroup>
          <FormattedMessage id="feedbackText" />
        </FormGroup>
        <FormGroup>
          <FormControl
            id="feedback"
            type="textarea"
            componentClass="textarea"
            rows="7"
            onChange={(e) => setFeedbackText(e.target.value)}
            value={feedbackText}
            autoFocus
          />
        </FormGroup>
      </React.Fragment>
    </ConfirmDialog>
  );
};

FeedbackDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
