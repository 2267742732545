import PropTypes from 'prop-types';
import React from 'react';

import SummaryHighlight from '../common/summary-highlight';

const SummaryValueHighlight = ({ middle, ...rest }) => (
  <SummaryHighlight {...rest} middle={<p className="big-label weight-600 white-color">{middle}</p>} />
);

SummaryValueHighlight.propTypes = {
  top: PropTypes.node,
  middle: PropTypes.node,
  bottom: PropTypes.node,
  isFetching: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default SummaryValueHighlight;
