import './maturing-today-summary-item.scss';

import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Drawer, ResultsPresenter, SummaryItem } from '../';
import { fetchTradesMaturing } from '../../../actions/holding/holding';
import {
  confirmRedeem,
  fetchIfNeeded as fetchMaturingIfNeeded,
  redeem,
} from '../../../actions/holding/maturing';
import {
  fetchRfqIfNeeded,
  orderRfqByUuid,
} from '../../../actions/holding/rfq/quotes';
import { fetchIssuersIfNeeded } from '../../../actions/issuers';
import {
  getTradesMaturingSummary,
  getTradesMaturingSummaryUrl,
} from '../../../api/holding/maturing-summary';
import { Money } from '../../../containers/money';
import { routes } from '../../../routes';
import * as recordsPresenter from './maturing-today-summary-records-presenter';

export const MaturingTodaySummaryItemComponent = ({
  currency,
  setInitialValuesInRfq,
  dispatchFetchRfqIfNeeded,
  dispatchFetchIssuersIfNeeded,
  dispatchOrderRfqByUuid,
  dispatchRedeem,
  dispatchConfirmRedeem,
  dispatchFetchMaturingIfNeeded,
  dispatchFetchTradesMaturing,
  quotes,
  issuers,
  tenant,
  maturing,
  intl,
}) => {
  const navigate = useNavigate();

  const { data: maturingSummary, mutate: mutateTradesMaturingSummary } = useSWR(
    getTradesMaturingSummaryUrl,
    getTradesMaturingSummary,
  );
  const [isMaturingTradesDrawerOpen, setIsMaturingTradesDrawerOpen] = useState();

  const hasMaturingToday = maturingSummary?.maturingTodayCount > 0;

  useEffect(dispatchFetchRfqIfNeeded, [dispatchFetchRfqIfNeeded]);
  useEffect(dispatchFetchIssuersIfNeeded, [dispatchFetchIssuersIfNeeded]);

  useEffect(dispatchFetchMaturingIfNeeded, [dispatchFetchMaturingIfNeeded]);

  const maturingInvestments = hasMaturingToday
    ? quotes?.rfqs &&
      maturing?.maturing?.trades?.map((trade) => ({
        ...trade,
        ...(quotes.rfqs && quotes.rfqs.find((rfq) => rfq.tradeId === trade.id && rfq.rfqStatus !== 'cancelled')),
      }))
    : [];

  const isLoading = quotes.isFetchingQuotes || maturing.isFetching;
  const noRecords = !maturingInvestments || !maturingInvestments.length;

  const viewReceivedQuotes = ({ uuid }) => {
    navigate(routes.holdings.quotesDetails.replace(':uuid', uuid));
    dispatchOrderRfqByUuid(uuid);
  };

  const confirmRedeem = async (trade) => {
    const issuersCodes = (trade.issuers && trade.issuers.map((issuer) => issuer.code)) || [];

    if (trade.issuer && !issuersCodes.includes(trade.issuer.code)) {
      issuersCodes.push(trade.issuer.code);
    }

    const tenants = issuers.data.filter((issuer) => issuersCodes.includes(issuer.code)).map((issuer) => issuer.domain);

    tenants.push(tenant);

    return dispatchConfirmRedeem(trade, tenants);
  };

  const redeem = async (trade) => {
    const tradeIssuer = issuers.data.find((issuer) => trade.issuer && trade.issuer.code === issuer.code);

    const tenants = tradeIssuer && tradeIssuer.domain && [tradeIssuer.domain];

    await dispatchRedeem(trade, tenants);

    await confirmRedeem(trade, tenants);

    await dispatchFetchTradesMaturing();

    await dispatchFetchMaturingIfNeeded();

    mutateTradesMaturingSummary();
  };

  const payload = {
    isFetching: isLoading,
    noRecords,
    recordsPresenter: {
      data: maturingInvestments,
      ...recordsPresenter,
      actions: {
        viewReceivedQuotes,
        redeem,
      },
    },
    noRecordsMessageId: 'emptyDefaultMessage',
  };

  return (
    <React.Fragment>
      <div
        className={classNames({ 'maturing-today-summary-item': hasMaturingToday })}
        onClick={() => hasMaturingToday && setIsMaturingTradesDrawerOpen(() => !isMaturingTradesDrawerOpen)}
      >
        <SummaryItem
          top={<FormattedMessage id="maturingToday" />}
          middle={
            <Money
              value={maturingSummary?.maturingTodaySum}
              short
              fraction={maturingSummary?.maturingTodayCount > 0 ? 2 : 0}
            />
          }
          bottom={
            hasMaturingToday && (
              <Row alignItemsCenter>
                <span className="highlight">
                  <FormattedMessage
                    tagName="span"
                    id="portfolio.filter-investments-number"
                    values={{ length: maturingSummary.maturingTodayCount }}
                  />
                </span>
              </Row>
            )
          }
          highlight={hasMaturingToday}
          isLoading={!maturingSummary}
        />
      </div>
      <Drawer
        open={isMaturingTradesDrawerOpen}
        className="maturing-today-summary-item-drawer"
        width="50vw"
        titleId="maturingTitle"
        onClose={() => setIsMaturingTradesDrawerOpen(false)}
      >
        <ResultsPresenter {...payload} />
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  quotes: state.quotes,
  maturing: state.maturing,
  issuers: state.issuers,
  tenant: state.tenant,
});

const mapDispatchToProps = {
  dispatchFetchRfqIfNeeded: fetchRfqIfNeeded,
  dispatchFetchIssuersIfNeeded: fetchIssuersIfNeeded,
  dispatchRedeem: redeem,
  dispatchConfirmRedeem: confirmRedeem,
  dispatchFetchMaturingIfNeeded: fetchMaturingIfNeeded,
  dispatchFetchTradesMaturing: fetchTradesMaturing,
  dispatchOrderRfqByUuid: orderRfqByUuid,
};

export const MaturingTodaySummaryItem = connect(mapStateToProps, mapDispatchToProps)(MaturingTodaySummaryItemComponent);
