import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { isBank } from '../../../../actions/session-selector';
import { CollationOfRatesheets } from './investor/CollationOfRatesheets/CollationOfRatesheets';
import { UnsolicitedRatesheetCustomers } from './issuer/UnsolicitedRatesheetCustomers/UnsolicitedRatesheetCustomers';

const mapStateToProps = (state) => ({
  isBank: isBank(state),
});

export const UnsolicitedRatesheetComponent = ({ isBank }) =>
  isBank ? <UnsolicitedRatesheetCustomers /> : <CollationOfRatesheets />;

UnsolicitedRatesheetComponent.propTypes = {
  isBank: PropTypes.bool.isRequired,
};

export const UnsolicitedRatesheet = compose(connect(mapStateToProps))(UnsolicitedRatesheetComponent);
