import ratingTypes from './rating-types';
import { isLongRating } from './rating-type';

export default function({ trades, creditRatingCompliances, asOf, longTermComplianceOnly }) {
  const totalInvestment = calculateAmountInvested(trades);
  const shortTermResult = initializeRatingTypeResult(ratingTypes.short, creditRatingCompliances, totalInvestment);
  const longTermResult = initializeRatingTypeResult(ratingTypes.long, creditRatingCompliances, totalInvestment);

  for (const trade of trades) {
    if (longTermComplianceOnly || isLongRating(trade, asOf)) {
      longTermResult.tradesCount++;
      longTermResult.amountInvested += trade.principal;
      const rating = longTermResult.ratings.find((item) => item.rating === removeTrailingSymbol(trade.longRating));
      rating.tradesCount++;
      rating.amountInvested += trade.principal;
    } else {
      shortTermResult.tradesCount++;
      shortTermResult.amountInvested += trade.principal;
      const rating = shortTermResult.ratings.find((item) => item.rating === removeTrailingSymbol(trade.shortRating));
      rating.tradesCount++;
      rating.amountInvested += trade.principal;
    }
  }

  return {
    amountInvested: totalInvestment,
    tradesCount: trades.length,
    short: shortTermResult,
    long: longTermResult,
    get isCompliant() {
      return this.short.isCompliant && this.long.isCompliant;
    },
  };
}

function calculateInvestmentSharePct(amountInvested, totalInvestments) {
  if (totalInvestments === 0) {
    return 0;
  }

  return (amountInvested / totalInvestments) * 100;
}

export function removeTrailingSymbol(rating) {
  if (!rating) return rating;

  const doNotRemove = ['A-1+']; // the symbols listed here will NOT have the +/- signs removed
  if (doNotRemove.includes(rating)) return rating;

  return rating.trim().replace(/[+-]+$/, '');
}

function calculateAmountAvailable(amountInvested, limitPct, totalInvestment) {
  const maxAllowed = (limitPct / 100.0) * totalInvestment;

  return maxAllowed - amountInvested;
}

function initializeRatingTypeResult(ratingType, creditRatingCompliances, totalInvestments) {
  const result = {
    get isCompliant() {
      return this.ratings.every((rating) => rating.isCompliant);
    },
    get investmentSharePct() {
      return calculateInvestmentSharePct(this.amountInvested, totalInvestments);
    },
    tradesCount: 0,
    amountInvested: 0,
    ratings: [],
  };

  const compliancesForRatingType = creditRatingCompliances.filter((item) => item.ratingType === ratingType);
  for (const row of compliancesForRatingType) {
    const rating = {
      rating: row.rating,
      tradesCount: 0,
      amountInvested: 0,
      maxLimitPct: row.maxLimit,
      get investmentSharePct() {
        return calculateInvestmentSharePct(this.amountInvested, totalInvestments);
      },
      get amountAvailable() {
        return calculateAmountAvailable(this.amountInvested, this.maxLimitPct, totalInvestments);
      },
      get isCompliant() {
        return this.amountAvailable >= 0;
      },
    };
    result.ratings.push(rating);
  }

  return result;
}

function calculateAmountInvested(trades) {
  return +trades.reduce((total, trade) => total + trade.principal, 0.0);
}
