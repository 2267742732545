import Valida from 'valida';

import {
  maxFloat,
  maximumValueFromJSON,
  positiveValue,
} from '../../../parse-float';
import BaseSchema from '../base/schema';

const schema = {
  coupon: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  operationDate: {
    ...BaseSchema.purchaseDate,
  },
  cashId: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
  principal: [
    { validator: Valida.Validator.required },
    { validator: Valida.Validator.empty },
    { validator: positiveValue, allowZero: true },
    { validator: maxFloat },
    { validator: maximumValueFromJSON, formField: 'cashHolding', fieldKey: 'amount' },
  ],
};

export default schema;
