import axios from 'axios';

export async function getRedemptions({ csv, ...params } = {}) {
  const axiosDefaultAccept = axios.defaults.headers.common.Accept;

  Object.assign(axios.defaults.headers.common, { Accept: csv ? 'text/csv' : 'application/json' });

  const { data } = await axios({
    method: 'get',
    url: '/trades/redemptions',
    params,
  });

  Object.assign(axios.defaults.headers.common, { Accept: axiosDefaultAccept });

  return data;
}
