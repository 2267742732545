import '../../components/common/form/form.scss';
import './forgot-and-reset.scss';

import React from 'react';

import { Form, Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import * as Yup from 'yup';

import { resetPassword } from '../../api/password/reset-password';
import imperiumLogo from '../../assets/imperium-logo-light.svg';
import { ValidatedFormikField } from '../../components/common';
import { PasswordField } from '../../components/common/formik/fields/PasswordField';
import { withSearchParams } from '../../components/hoc/with-router-properties';
import passwordComplexityTest from '../../validation/password-complexity';
import { MessageType, showToastMessage } from '../toast/toast';

export const ResetPasswordComponent = (props) => {
  const format = (messageId) => props.intl.formatMessage({ id: messageId });

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .matches(passwordComplexityTest, format('minimumPasswordComplexityRequired'))
      .required(format('newPasswordRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], format('passwordsDoNotMatch'))
      .required(format('confirmPasswordIsRequired')),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const token = props.searchParamsEntries?.token;

    if (!token) {
      showToastMessage(format('invalidToken'), MessageType.ERROR);

      return;
    }

    try {
      const { data } = await resetPassword({
        password: values.newPassword,
        token,
      });
      showToastMessage(format('passwordChanged'), MessageType.SUCCESS, {
        timeOut: 30000,
      });

      resetForm();

      const url = getRedirectUrl(data.tenant);
      url && window.location.replace(url);
    } catch (error) {
      const messageId = getErrorType(error.response.data);
      showToastMessage(format(messageId), MessageType.ERROR);
    }

    setSubmitting(false);
  };

  const getRedirectUrl = (tenantName) => {
    if (!tenantName) {
      return false;
    }

    const [, subdomain] = window.location.origin.split('.');
    let clusterPrefix = '';

    if (subdomain !== 'imperium') {
      clusterPrefix = `.${subdomain}`;
    }

    return `https://${tenantName}${clusterPrefix}.imperium.markets/login`;
  };

  const getErrorType = (data) => {
    if (data.error && data.error.token) {
      return 'invalidToken';
    }

    if (data.error_message === 'new-password-repeated') {
      return 'repeatedPassword';
    }

    return 'anErrorHasOccurred';
  };

  return (
    <div className="password-container">
      <img alt="Imperium Markets" src={imperiumLogo} />
      <div className="form-container">
        <h3>
          <FormattedMessage id="resetPassword" />
        </h3>
        <Formik
          initialValues={{ newPassword: '', confirmPassword: '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, touched, errors }) => (
            <Form>
              <fieldset disabled={isSubmitting}>
                <ValidatedFormikField
                  id="newPassword"
                  name="newPassword"
                  labelId="newPassword"
                  className="form-control"
                  data-testid="newPassword"
                  value={values.newPassword || ''}
                  component={PasswordField}
                  touched={touched}
                  errors={errors}
                />
                <ValidatedFormikField
                  id="confirmPassword"
                  name="confirmPassword"
                  labelId="confirmPassword"
                  className="form-control"
                  data-testid="confirmPassword"
                  value={values.confirmPassword || ''}
                  component={PasswordField}
                  touched={touched}
                  errors={errors}
                />
                <div className="actions-container">
                  <button type="submit" className="btn btn-default btn-solid-primary" disabled={isSubmitting}>
                    <FormattedMessage id="send" />
                  </button>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const ResetPassword = compose(injectIntl, withSearchParams)(ResetPasswordComponent);

export default ResetPassword;
