import * as ratesheetsApiClient from '../api/ratesheets';

export const FETCH_RATESHEETS_REQUEST = 'FETCH_RATESHEETS_REQUEST';
export const FETCH_RATESHEETS_SUCCESS = 'FETCH_RATESHEETS_SUCCESS';
export const FETCH_RATESHEETS_FAILURE = 'FETCH_RATESHEETS_FAILURE';
export const UPDATE_RATESHEETS_REQUEST = 'UPDATE_RATESHEETS_REQUEST';
export const UPDATE_RATESHEETS_SUCCESS = 'UPDATE_RATESHEETS_SUCCESS';
export const UPDATE_RATESHEETS_FAILURE = 'UPDATE_RATESHEETS_FAILURE';
export const RESET_UPDATE_DATA = 'UPDATE_RATESHEETS_FAILURE';

export function fetchRatesheets(tenantIssuerId) {
  return async (dispatch) => {
    dispatch({ type: FETCH_RATESHEETS_REQUEST });
    try {
      const ratesheets = await ratesheetsApiClient.listMe();

      dispatch({ type: FETCH_RATESHEETS_SUCCESS, payload: ratesheets, tenantIssuerId });
    } catch (error) {
      dispatch({
        type: FETCH_RATESHEETS_FAILURE,
        error: error.response || error,
      });
    }
  };
}

function shouldFetchRatesheets({ ratesheets }) {
  if (ratesheets.isFetching) {
    return false;
  }

  if (!ratesheets.data) {
    return true;
  }

  return ratesheets.didInvalidate;
}

export function fetchRatesheetsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchRatesheets(getState())) {
      dispatch(fetchRatesheets());
    }
  };
}

export function updateRatesheets(ratesheets) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RATESHEETS_REQUEST });
    try {
      const result = await ratesheetsApiClient.update(ratesheets);
      dispatch({ type: UPDATE_RATESHEETS_SUCCESS, result });
    } catch (error) {
      dispatch({
        type: UPDATE_RATESHEETS_FAILURE,
        error: error.response || error,
      });
    }
  };
}

export function resetUpdate() {
  return async (dispatch) => dispatch({ type: RESET_UPDATE_DATA });
}
