import './panel-collapsible.scss';

import React, { Component } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Glyphicon, Panel } from 'react-bootstrap';

import { EmptyContent } from './EmptyContent';
import { Row } from './Flex/Flex';
import { Loadable } from './loadable';

export default class PanelCollapsible extends Component {
  state = {
    expanded: this.props.expanded || false,
  };

  onChevronClick = () => {
    if (this.props.isFetching || this.props.isFetchingContent) {
      return;
    }

    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  renderChevron = () => {
    if (!this.props.children) {
      return null;
    }

    const { expanded } = this.state;

    return <Glyphicon onClick={this.onChevronClick} glyph={`chevron-${expanded ? 'up' : 'down'}`} />;
  };

  renderHeader = () => (
    <Panel.Toggle>
      <Row
        alignItemsCenter
        className={classNames({
          'justify-content-center': this.props.isFetching || this.props.noRecords,
          'justify-content-end': !this.props.header,
        })}
      >
        {this.renderHeaderContent()}
      </Row>
    </Panel.Toggle>
  );

  renderHeaderContent = () => {
    if (this.props.isFetching) {
      return <Loadable isLoading />;
    }

    if (this.props.noRecords) {
      return <EmptyContent messageId="noRecords" style={{ backgroundColor: 'transparent' }} />;
    }

    const { header } = this.props;

    if (!header) {
      return this.renderChevron();
    }

    return (
      <Row contentBetween style={{ flex: 1 }}>
        {header}
        {this.renderChevron()}
      </Row>
    );
  };

  renderFooter = () => {
    const { footer } = this.props;

    return footer ? <div className="footer">{footer}</div> : null;
  };

  onToggle = (expanded) => {
    this.setState({ expanded });
  };

  onEntering = () => {
    const { id, onEntering } = this.props;

    onEntering && onEntering(id);
  };

  render() {
    const { className, children, disabled, id, isFetchingContent } = this.props;
    const { expanded } = this.state;

    return (
      <Panel
        id={id}
        className={classNames('panel-collapsible', className, {
          'is-loading': isFetchingContent,
          'is-disabled': disabled,
        })}
        defaultExpanded={expanded}
        onToggle={this.onToggle}
      >
        <Panel.Heading>{this.renderHeader()}</Panel.Heading>
        <Panel.Collapse onEntering={this.onEntering}>
          <Panel.Body>
            {children}
            {this.renderFooter()}
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

PanelCollapsible.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  footer: PropTypes.node,
  header: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFetching: PropTypes.bool,
  isFetchingContent: PropTypes.bool,
  noRecords: PropTypes.bool,
  onEntering: PropTypes.func,
};
