export default function toFloat(value) {
  if (!value) {
    return NaN;
  }

  if (typeof value === 'number') {
    return value;
  }

  return parseFloat(value.replace(/\$/gi, '').replace(/,/gi, ''));
}

export const normalizeFraction = (value, delimiter, fraction = 2) =>
  value.includes(delimiter) && value.split(delimiter)[1].length > 2
    ? value.substring(0, value.indexOf(delimiter) + fraction + 1)
    : value;

export const normalizeCurrency = (value) => normalizeFraction(value.toString(), '.');

export const normalizeNumber = (value, { max, min }) => {
  if (!value) {
    return '';
  }

  const number = Number(value);
  const delimiter = '.';

  if (number > max) {
    return value.includes(delimiter) ? max : value.substring(0, value.length - 1);
  }

  if (number < min) {
    return min;
  }

  return normalizeFraction(number.toString(), delimiter);
};

export function maxFloat(ctx, options, value, cb) {
  if (!value) {
    return cb();
  }

  if (toFloat(value) > Number.MAX_SAFE_INTEGER) {
    return cb(null, { validator: 'numeric-overflow' });
  }

  return cb();
}

export function minimumValue(ctx, options, value, cb) {
  const { min } = options;

  if (!value || !min) {
    return cb();
  }

  const parsedValue = toFloat(value);
  if (parsedValue < min) {
    return cb(null, {
      validator: { id: 'min-validation', values: { min } },
    });
  }

  return cb();
}

export function rangeValue(ctx, options, value, cb) {
  const { min, max } = options;

  if (!value || !min || !max) {
    return cb();
  }

  const parsedValue = toFloat(value);
  if (parsedValue < min) {
    return cb(null, {
      validator: { id: 'min-validation', values: { min } },
    });
  }
  if (parsedValue > max) {
    return cb(null, {
      validator: { id: 'max-validation', values: { max } },
    });
  }

  return cb();
}

export function maximumValueFromJSON(ctx, options, value, cb) {
  const { formField, fieldKey } = options;

  if (!value || !formField || !fieldKey || !ctx.obj) {
    return cb();
  }

  try {
    const parsedValue = toFloat(value);
    const target = JSON.parse(ctx.obj[formField]);
    const max = target[fieldKey] ? toFloat(target[fieldKey]) : 0;

    if (max === 0) {
      return cb(null, { validator: 'not-sellable' });
    }

    if (parsedValue > max) {
      return cb(null, {
        validator: { id: 'max-validation', values: { max } },
      });
    }
  } catch (e) {
    return cb();
  }

  return cb();
}

export function positiveValue(ctx, options, value, cb) {
  if (!value) {
    return cb();
  }

  if (options.allowZero && toFloat(value) < 0) {
    return cb(null, { validator: 'non-negative-validation' });
  }

  if (toFloat(value) <= 0 && !options.allowZero) {
    return cb(null, { validator: 'non-negative-validation' });
  }

  return cb();
}

export function toLocaleCurrency(locale, number) {
  return number.toLocaleString(locale, { style: 'currency', currency: 'AUD' });
}
