import clone from 'lodash.clonedeep';
import Valida from 'valida';

import { maxFloat, minimumValue, positiveValue, rangeValue } from '../../../parse-float';
import BaseSchema from './schema';

export default (function() {
  const baseSchemaWithoutIssuer = clone(BaseSchema);
  delete baseSchemaWithoutIssuer.issuerId;

  return {
    ...baseSchemaWithoutIssuer,
    isin: [{ validator: Valida.Validator.required }, { validator: Valida.Validator.empty }],
    cleanPrice: [
      { validator: Valida.Validator.required },
      { validator: Valida.Validator.empty },
      { validator: rangeValue, min: 70, max: 150 },
    ],
    grossPrice: [
      { validator: Valida.Validator.required },
      { validator: Valida.Validator.empty },
      { validator: rangeValue, min: 70, max: 150 },
    ],
    quantity: [
      { validator: Valida.Validator.required },
      { validator: Valida.Validator.empty },
      { validator: positiveValue },
      { validator: maxFloat },
      { validator: minimumValue, min: 1000 },
    ],
  };
})();
