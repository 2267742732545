import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getRedemptions } from '../../../api/holding/get-redemptions';
import {
  Column,
  Loadable,
  Range,
  ResultsPresenter,
  Row,
} from '../../../components/common';
import { formatToShortDateWithoutTz, newDate, today } from '../../../date';
import { downloadCsv } from '../../../download-csv';
import { useAxios } from '../../../hooks/use-axios';
import { getMoneySymbol } from '../../money';
import { showResponseErrorMessage } from '../../toast/toast';
import { columns, presenter } from './presenter';

const RedemptionsReportComponent = ({ currency }, intl) => {
  const [reportRange, setReportRange] = useState({
    from: newDate().subtract(1, 'year').toDate(),
    to: today().toDate(),
  });

  const [sortColumn, setSortColumn] = useState('redeemedAt');
  const [sortDirectionDesc, setSortDirectionDesc] = useState(true);

  const applySort = (redemptions) => {
    if (!redemptions || !redemptions.length) return redemptions;

    const sorted = redemptions.sort((row1, row2) => (row1[sortColumn] < row2[sortColumn] ? -1 : 1));

    return sortDirectionDesc ? sorted.reverse() : sorted;
  };

  const buildParams = () => ({
    from: formatToShortDateWithoutTz(reportRange.from),
    to: formatToShortDateWithoutTz(reportRange.to),
  });

  const { data: redemptions, refresh, error } = useAxios(() => getRedemptions(buildParams()));

  const isFetchingRedemptions = !!redemptions.isLoading;

  const moneySymbol = getMoneySymbol({ currency, short: true });

  const onSortColumnOrder = ({ sort }) => {
    if (sort === sortColumn) {
      setSortDirectionDesc(!sortDirectionDesc);
    } else {
      setSortColumn(sort);
      setSortDirectionDesc(false);
    }
  };

  const presenterData = {
    isFetching: isFetchingRedemptions,
    noRecords: redemptions && !redemptions.length,
    recordsPresenter: {
      data: applySort(redemptions),
      presenter,
      columns: columns(moneySymbol),
      onSortColumnOrder,
    },
    hasError: !!error,
  };

  const onChangeFrom = (date) => {
    setReportRange({
      ...reportRange,
      from: date && newDate(date).toDate(),
    });
  };

  const onChangeTo = (date) => {
    setReportRange({
      ...reportRange,
      to: date && newDate(date).toDate(),
    });
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const onDownloadClick = async () => {
    const { from, to } = buildParams();

    setIsDownloading(true);

    try {
      const csv = await getRedemptions({ from, to, csv: true });
      downloadCsv({ csv, fileName: `Redemptions_${from}_${to}.csv` });
    } catch (e) {
      showResponseErrorMessage({ intl, error: e });
    } finally {
      setIsDownloading(false);
    }
  };

  const isRangeInvalid = !reportRange.from || !reportRange.to;

  return (
    <Column className="reports-tab-container">
      <Row className="reports-tab-container-header">
        <FormattedMessage id="selectPeriod" />
      </Row>
      <Row className="datepicker-container" alignItemsCenter contentBetween>
        <Row alignItemsCenter>
          <div className="month-picker">
            <div className="monthly-report-selector">
              <Range
                from={reportRange.from}
                to={reportRange.to}
                onChangeFrom={onChangeFrom}
                onChangeTo={onChangeTo}
                minDate={new Date()}
                maxDate={new Date()}
              />
            </div>
          </div>
          <button
            type="button"
            disabled={isFetchingRedemptions || isRangeInvalid}
            className="btn btn-default"
            onClick={refresh}
          >
            <FormattedMessage id="filter" />
          </button>
        </Row>
        <button
          type="button"
          disabled={isDownloading || isRangeInvalid}
          className="btn btn-default"
          onClick={onDownloadClick}
        >
          <Loadable isLoading={isDownloading} size="sm">
            <FormattedMessage id="download" />
          </Loadable>
        </button>
      </Row>
      <Row className="reports-tab-container-body" alignItemsEnd>
        <ResultsPresenter {...presenterData} />
      </Row>
    </Column>
  );
};

RedemptionsReportComponent.propTypes = {
  currency: PropTypes.string,
  fetchHoldingCsv: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currency: state.session.user.currency,
});

export const RedemptionsReport = connect(mapStateToProps)(RedemptionsReportComponent);
