import { defaultChartGradientOpacity } from '../../theme/nivo-chart-settings';

export const buildLinearGradient = ({ id, color }) => ({
  id,
  type: 'linearGradient',
  colors: [
    { offset: 0, color, opacity: defaultChartGradientOpacity },
    { offset: 100, color },
  ],
});

export const buildChartDefsByColors = ({ colors }) =>
  Object.keys(colors).map((key) => buildLinearGradient({ id: key, color: colors[key] }));

export const buildChartFillsByColors = ({ colors }) =>
  Object.keys(colors).map((key) => ({
    match: { id: key },
    id: key,
  }));
