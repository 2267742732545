import FileSaver from 'file-saver';
import clone from 'lodash.clonedeep';
import Valida from 'valida';

import * as holdingApiClient from '../../api/holding';
import { columns } from '../../components/holding/records-presenter';
import { getDefaultDates } from '../../date';
import fetch from '../helper/fetch';
import { buildFilter, validateAvailableFilters } from '../helper/filter';
import { asAtValidator, maturityValidator } from '../helper/validation';

const HOLDING = 'HOLDING';
const HOLDING_CSV = 'HOLDING_CSV';
export const DEFAULT_SORT_COLUMN = 'maturityDate';
export const FETCH_TRADES_MATURING = 'TRADES_MATURING';
export const FETCH_TRADES_SUMMARY = 'TRADES_SUMMARY';
export const FETCH_TRADES_SUMMARY_FILTER = 'TRADES_SUMMARY_FILTER';
export const RESET_SUMMARY_FILTER = 'RESET_SUMMARY_FILTER';
export const UPDATE_HOLDING_FILTER = 'UPDATE_HOLDING_FILTER';
export const UPDATE_HOLDING_SELECTED_DATE = 'UPDATE_HOLDING_SELECTED_DATE';
export const UPDATE_TRADE = 'UPDATE_TRADE';

const holdingFetchAction = fetch(HOLDING, shouldFetchHolding, fetchHoldings, validateHoldingParams);

const holdingCsvDownloadAction = fetch(
  HOLDING_CSV,
  () => true,
  async (params) => {
    const csv = await holdingApiClient.csv(params);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, getFileName(params), true);
  },
  validateHoldingParams,
);

const getFileName = (params) => {
  const suffix = `${new Date().toISOString()}.csv`;

  if (params.today) return `Todays_Trades_${suffix}`;

  if (params.grouped) return `Grouped_Trades_${suffix}`;

  return `All_Trades_${suffix}`;
};

const tradesSummaryFetchAction = fetch(
  FETCH_TRADES_SUMMARY,
  shouldFetchTradesSummary,
  async (params) => ({
    summary: await holdingApiClient.summary({
      query: getDefaultDates(params && params.date),
      ...params,
    }),
    params,
  }),
  validateHoldingParams,
);

const tradesSummaryFilterFetchAction = fetch(
  FETCH_TRADES_SUMMARY_FILTER,
  shouldFetchTradesSummaryFilter,
  fetchSummaryFilter,
  validateHoldingParams,
);

const tradesMaturingFetchAction = fetch(
  FETCH_TRADES_MATURING,
  shouldFetchTradesMaturing,
  async () => ({
    maturing: await holdingApiClient.maturing(),
  }),
  validateHoldingParams,
);

export const holdingFetchPayload = holdingFetchAction.actionsTypes;
export const holdingDownloadCsvPayload = holdingCsvDownloadAction.actionsTypes;
export const tradesSummaryFetchPayload = tradesSummaryFetchAction.actionsTypes;
export const tradesSummaryFilterFetchPayload = tradesSummaryFilterFetchAction.actionsTypes;
export const tradesMaturingFetchPayload = tradesMaturingFetchAction.actionsTypes;

export function prepareHoldingParams(params, requestParams) {
  const holdingParams = clone({
    query: getDefaultDates(params && params.date),
    ...requestParams,
    ...params,
  });

  if (Object.keys(holdingParams).includes('maturity')) {
    delete holdingParams.query.asOf;
  }

  return holdingParams;
}

async function fetchHoldings(params) {
  const requestParams = prepareHoldingParams(params, { sort: DEFAULT_SORT_COLUMN });

  const holding = await holdingApiClient.list(requestParams);

  const trades =
    holding &&
    holding.trades &&
    holding.trades.map((trade) => {
      const presentationTrade = clone(trade);

      if (['MGFUND', 'CASH'].includes(trade.instrumentCode)) {
        delete presentationTrade.maturityDate;
      }

      return presentationTrade;
    });

  return {
    holding: { trades },
    params,
  };
}

async function fetchSummaryFilter(params) {
  const requestParams = prepareHoldingParams(params);

  return {
    summary: await holdingApiClient.summary(requestParams),
    params: requestParams,
  };
}

export function fetchHoldingIfNeeded(params) {
  return holdingFetchAction.fetchIfNeeded(params);
}

function shouldFetchHolding(params = {}, state) {
  if (params.force) {
    return true;
  }

  if (state.holding.isFetching || state.holding.didInvalidate) {
    return false;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  return !state.holding.holding;
}

export function fetchHolding(params) {
  return holdingFetchAction.fetch(params);
}

export function fetchHoldingCsv(params) {
  return holdingCsvDownloadAction.fetch({
    ...params,
  });
}

const sortValidator = (sortColumns) =>
  Object.keys(sortColumns)
    .filter((key) => sortColumns[key].sort)
    .reduce((sortItems, key) => sortItems.concat([sortColumns[key].sort, `-${sortColumns[key].sort}`]), []);

function validateHoldingParams(params, { holding: { availableFilters } }) {
  if (!params) {
    return;
  }

  const schema = {
    year: [{ sanitizer: Valida.Sanitizer.toInt }, { validator: Valida.Validator.integer }],
    date: [{ validator: Valida.Validator.date }, { validator: asAtValidator }],
    maturity: [
      { sanitizer: Valida.Sanitizer.toInt },
      { validator: Valida.Validator.integer },
      { validator: maturityValidator },
    ],
    searchText: [
      { sanitizer: Valida.Sanitizer.string },
      { sanitizer: Valida.Sanitizer.trim },
      { validator: Valida.Validator.regex, pattern: "^[A-zÀ-ÿ0-9&'() .-]*$", modifiers: 'ig' },
    ],
    sort: [{ validator: Valida.Validator.enum, items: sortValidator(columns()) }],
  };

  validateAvailableFilters(params, availableFilters, schema);
}

export function fetchTradesSummaryIfNeeded(params) {
  return tradesSummaryFetchAction.fetchIfNeeded(params);
}

export function fetchTradesSummaryFilterIfNeeded(params) {
  return tradesSummaryFilterFetchAction.fetchIfNeeded(params);
}

function shouldFetchTradesSummary(_, state) {
  const { summary } = state.holding;

  if (summary.general.isFetching) {
    return false;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  if (summary.commonAllocation) {
    return false;
  }

  return !summary.general.isFetching;
}

function shouldFetchTradesSummaryFilter(_, state) {
  const {
    summary: { filter },
  } = state.holding;

  if (filter && filter.isFetching) {
    return false;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  return filter !== null;
}

export function fetchTradesMaturing(params) {
  return tradesMaturingFetchAction.fetch(params);
}

export function fetchTradesMaturingIfNeeded(params) {
  return tradesMaturingFetchAction.fetchIfNeeded(params);
}

function shouldFetchTradesMaturing(_, state) {
  const { maturing } = state.holding;

  if (!maturing.isFetching) {
    return true;
  }

  if (state.portfolio.refresh) {
    return true;
  }

  return false;
}

export function updateSelectedDate(baseDate) {
  return async (dispatch, getState) => {
    const state = getState();
    const filter = {
      query: getDefaultDates(baseDate),
      date: baseDate,
    };

    dispatch({ type: UPDATE_HOLDING_SELECTED_DATE, date: baseDate });

    tradesSummaryFetchAction.fetchAsync(dispatch, state, filter);

    updateFilter(filter, dispatch, state);

    fetchHoldingData(dispatch, state, filter);
  };
}

export function updateSortColumnAsync(filter) {
  return async (dispatch, getState) => {
    const actualState = getState();
    const {
      holding: { filter: stateFilter },
    } = actualState;

    if (shouldChangeSortOrder(stateFilter, filter)) {
      filter.sort = `-${filter.sort}`; // eslint-disable-line no-param-reassign
    }

    return updateFilter(filter, dispatch, actualState);
  };
}

const shouldChangeSortOrder = (currentFilter = {}, newFilter) =>
  newFilter && (!currentFilter.sort || currentFilter.sort === newFilter.sort);

export function updateFilterAsync(filter) {
  return async (dispatch, getState) => updateFilter(filter, dispatch, getState());
}

export function updateFilter(filter, dispatch, state) {
  const newFilter = buildFilter(filter, state.holding.filter);

  dispatch({ type: UPDATE_HOLDING_FILTER, newFilter });

  validateHoldingParams(newFilter, state);
}

export function resetFilter() {
  return async (dispatch, getState) => {
    const state = getState();

    const newFilter = {};

    if (state.holding.filter.date) {
      newFilter.date = state.holding.filter.date;
    }

    if (state.holding.filter.sort) {
      newFilter.sort = state.holding.filter.sort;
    }

    dispatch({ type: UPDATE_HOLDING_FILTER, newFilter });

    tradesSummaryFilterFetchAction.fetchIfNeeded(dispatch, state, newFilter);
  };
}

export function refreshHolding() {
  return async (dispatch, getState) => {
    const state = getState();
    fetchHoldingData(dispatch, state, state.holding.filter);
  };
}

function fetchHoldingData(dispatch, state, newFilter) {
  holdingFetchAction.fetchAsync(dispatch, state, newFilter);

  if (newFilter && Object.keys(newFilter).length > 0) {
    tradesSummaryFilterFetchAction.fetchIfNeeded(dispatch, state, newFilter);

    return;
  }

  resetSummaryFilterIfNeeded(dispatch, state);
}

function resetSummaryFilterIfNeeded(dispatch, { holding }) {
  if (!holding.summary.filter) {
    return;
  }

  dispatch({ type: RESET_SUMMARY_FILTER });
}

export function updateTrade(holding) {
  return (dispatch) => dispatch({ type: UPDATE_TRADE, holding });
}
