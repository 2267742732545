import './need-to-confirm.scss';

import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const NeedToConfirm = ({ values, firstParagraph, secondParagraph }) => (
  <div className="need-to-confirm">
    <div className="message">
      <FormattedMessage tagName="span" id={firstParagraph} values={values} />
      {secondParagraph && <FormattedMessage tagName="span" id={secondParagraph} values={values} />}
    </div>
  </div>
);

NeedToConfirm.propTypes = {
  values: PropTypes.object,
  firstParagraph: PropTypes.string,
  secondParagraph: PropTypes.string,
};

NeedToConfirm.defaultProps = {
  firstParagraph: 'needToConfirmFirstParagraph',
};

export default NeedToConfirm;
