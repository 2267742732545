import axios from 'axios';

export default async function (complianceParams, params) {
  const { data } = await axios({
    method: 'get',
    url: `/compliances/${complianceParams.complianceType}/groups`,
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  return data;
}
