import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { Col, Row } from 'react-bootstrap';

const GroupedButtons = ({ numbersByRow, length, onClick, className, startCount = 0, containerClassName, isActive }) => {
  const buttonGroupRows = [];
  let remainingLength = length;

  while (remainingLength > 0) {
    const buttonsLength = remainingLength > numbersByRow ? numbersByRow : remainingLength;
    const columnSize = 12 / numbersByRow;
    const buttons = Array.from({ length: buttonsLength }, (item, index) => {
      const number = numbersByRow * buttonGroupRows.length + startCount + index;
      return (
        <Col key={`grouped-col-${index}`} xs={columnSize}>
          <label
            className={classNames(className, { 'btn-solid-primary': isActive(number) })}
            onClick={() => onClick(number)}
          >
            <span>{number}</span>
          </label>
        </Col>
      );
    });

    remainingLength = remainingLength - numbersByRow;

    buttonGroupRows.push(<Row key={`grouped-row-${buttonGroupRows.length}`}>{buttons}</Row>);
  }

  return <div className={containerClassName}>{buttonGroupRows}</div>;
};

GroupedButtons.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  length: PropTypes.number.isRequired,
  numbersByRow: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  startCount: PropTypes.number,
  isActive: PropTypes.func.isRequired,
};

export default GroupedButtons;
