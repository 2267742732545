import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SkeletonTable } from '../../../common';
import './table.scss';

const Table = ({ rows, isLoading, hasBondOrFrnRfqs }) => (
  <SkeletonTable isLoading={isLoading}>
    <div className="table-responsive table-container">
      <table className={classNames('table table-striped', { 'has-securities': hasBondOrFrnRfqs })}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="reports.rfqsHistory.source" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.assetClass" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.operation" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.date" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.settlementDate" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.status" />
            </th>
            <th>
              <FormattedMessage
                id={hasBondOrFrnRfqs ? 'reports.rfqsHistory.faceValue' : 'reports.rfqsHistory.principal'}
              />
            </th>
            <th className="securities-column">
              <FormattedMessage id="reports.rfqsHistory.capitalValue" />
            </th>
            <th className="securities-column">
              <FormattedMessage id="reports.rfqsHistory.grossValue" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.winningIssuer" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.tenor" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.maturityDate" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.winningCoupon" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.createdBy" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.nominatedBy" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.signedOffBy" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.acceptedBy" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.confirmedBy" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.cancelledBy" />
            </th>
            <th>
              <FormattedMessage id="reports.rfqsHistory.details" />
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  </SkeletonTable>
);

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasBondOrFrnRfqs: PropTypes.bool,
};

export default Table;
