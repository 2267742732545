export const initialState = {
  downloading: false,
  from: undefined,
  to: undefined,
  tenantName: undefined,
  availableReports: {
    standard: [
      'portfolioValuation',
      'portfolioByAsset',
      'counterpartyCompliance',
      'creditQualityCompliance',
      'maturityCompliance',
    ],
    transactions: ['tradesForPeriod', 'unrealisedGainsLosses', 'realisedGainsLosses', 'interestInPeriod'],
    extras: [
      'portfolioComparison',
      'maturityCashFlows',
      'historicalBalance',
      'allocation',
      'instrument',
      'fossilFuels',
    ],
  },
};
