import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default function autoFocusComponent(WrappedComponent) {
  return class extends Component {
    static propTypes = {
      autofocus: PropTypes.bool,
    };

    componentDidMount() {
      if (this.props.autofocus) {
        ReactDOM.findDOMNode(this.input).focus();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.autofocus && this.hasValue(prevProps) && !this.hasValue(this.props)) {
        ReactDOM.findDOMNode(this.input).focus();
      }
    }

    hasValue({ value }) {
      if (typeof value === 'number') {
        return value >= 0;
      }

      return !!value;
    }

    render() {
      return (
        <WrappedComponent
          ref={(input) => {
            this.input = input;
          }}
          {...this.props}
        />
      );
    }
  };
}
