import axios from 'axios';
import reactGa from 'react-ga';

import { fileTypes } from '../../save-file';

export const downloadCombinedReport = async ({ from, to, domain, email, selectedReports, fileType }) => {
  const username = email && email.replace('@', ' _at_ ');
  reactGa.event({
    category: 'REPORT_DOWNLOAD',
    action: 'PORTFOLIO',
    label: `${domain}/${username}`,
  });

  const { data } = await axios({
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Accept: fileTypes[fileType].mimeType,
    },
    url: '/reports/combined',
    validateStatus: () => true,
    params: { from, to, selectedReports },
  });

  return data;
};
