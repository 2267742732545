import './records-presenter.scss';

import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { DateWithTimeZone, momentWithTimezone, weekdays } from '../../../date';
import { couponFormat } from '../../../format-numbers';
import { Tooltip } from '../../common';
import { PercentageInput } from '../../common/percentage-input';
import { getPeriodIdAndValues } from '../quotes';

export const daysToWarnMaturityDateDifference = 5;

export const columns = {
  tenor: { label: 'tenor' },
  maturity: { label: 'holdingMaturityDate' },
  difference: { label: 'daysOfInvestment' },
  coupon: { label: 'holdingCoupon' },
};

const isEmpty = (value) => [null, ''].includes(value);

export const presenter = (data, index, actions, options) => {
  const { code, date, difference, isRfqClosed, quote, timezone, warnOriginalDateDifference } = data;
  const { readOnly, autoFillRfqRatesheet, isAnyMaturityAlreadyQuoted, enableQuoteEditing } = options;
  const periodLabel = getPeriodIdAndValues(code);

  return (
    <tr key={index}>
      <td>
        {periodLabel ? (
          <FormattedMessage id={periodLabel.id} values={periodLabel.values} />
        ) : (
          <FormattedMessage id="inDate" />
        )}
      </td>
      <td>
        <DateWithTimeZone timezone={timezone} value={date} />
        &nbsp;(
        <span>{weekdays[momentWithTimezone(date, timezone).weekday()]}</span>)
      </td>
      <td className="in-days-column">
        <FormattedMessage id="inDays" values={{ days: difference }} />
        {code === 'CUSTOM' && (
          <Tooltip id="warnCustomDate" tooltipComponent={<FormattedMessage id="warnCustomDateAutoFillDisabled" />}>
            <i
              role="img"
              aria-label="warning auto-fill not available for custom dates"
              className="icon fa fa-exclamation-triangle"
            />
          </Tooltip>
        )}
        {warnOriginalDateDifference && (
          <Tooltip
            id="warnOriginalDateDifference"
            tooltipComponent={
              <FormattedMessage
                id="warnOriginalDateDifference"
                values={{
                  days: daysToWarnMaturityDateDifference,
                  autoFillMessage: autoFillRfqRatesheet && 'Auto-fill will not be applied on this tenor.',
                }}
              />
            }
          >
            <i
              role="img"
              aria-label="warning maturity date greater than original date"
              className="icon fa fa-exclamation-triangle"
            />
          </Tooltip>
        )}
      </td>
      <td className="quote-column">
        {isRfqClosed || (isAnyMaturityAlreadyQuoted && !enableQuoteEditing) ? (
          renderQuote(data)
        ) : (
          <PercentageInput
            onChange={(event) => {
              if (!event.target) {
                return;
              }

              actions.onQuoteChange(code, event.target.value);
            }}
            decimalPrecision={4}
            min={-100}
            max={100}
            value={quote !== null && quote !== undefined ? quote.toString() : ''}
          />
        )}
        {!isRfqClosed && !readOnly && !isEmpty(quote) && +quote === 0 && (
          <div>
            <i className="fa fa-exclamation-triangle" />
            <FormattedMessage id="rfqNotInterestedAlert" />
          </div>
        )}
      </td>
    </tr>
  );
};

const renderQuote = ({ quote, quoteStatus }) => (
  <label className={classnames('btn btn-table', quoteStatus)}>
    {!isEmpty(quote) ? <FormattedNumber {...couponFormat} value={quote} /> : 'Not interested'}
  </label>
);
