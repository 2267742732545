import React from 'react';
import { node } from 'prop-types';

import { ErrorComponent } from '../../components/common';

const buildErrorDetails = ({ error, errorInfo }) =>
  `Error: ${error && error.toString()}\nStack trace: ${(errorInfo && errorInfo.componentStack) ||
    JSON.stringify(errorInfo, null, 2)}`;

export class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({
      errorInfo,
      error,
    });
  }

  render() {
    const { errorInfo, error } = this.state;

    if (errorInfo) {
      const errorComponentInfo = buildErrorDetails({ error, errorInfo });

      return <ErrorComponent message="sorryThereWasAnError" errorInfo={errorComponentInfo} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node,
};
