import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import allocation from './allocation';
import assets from './assets';
import counterparty from './compliances/counterparty';
import credit from './compliances/credit';
import { dashboard } from '../ducks/dashboard';
import error from './error';
import holding from './holding';
import locale from './locale';
import maturity from './compliances/maturity';
import portfolio from './portfolio';
import session from './session';
import upload from './upload';
import trades from './trades';
import ratesheets from './ratesheets';
import incomingRfqsReport from './reports/incoming-rfqs';
import outgoingRfqsReport from './reports/outgoing-rfqs';
import sandbox from './sandbox';
import sandboxSummary from './sandbox-summary';
import issuers from './issuers';
import { capitalValues } from '../ducks/capital-values';
import { combinedReports as combinedReportsDuck } from '../ducks/reports';
import { issuerTrades } from '../ducks/issuer-trades';
import { login } from '../ducks/authentication/index';
import { quotes as quotesDuck } from '../ducks/rfq/quotes/index';
import { offers } from '../ducks/offers';
import { unsettledTrades } from '../ducks/unsettled/index';

export default combineReducers({
  ...holding,
  allocation,
  assets,
  baseUrl: (customValue) => customValue || null,
  browserLocale: (customValue) => customValue || null,
  capitalValues,
  combinedReportsDuck,
  counterparty,
  credit,
  dashboard,
  error,
  form: formReducer,
  incomingRfqsReport,
  issuers,
  issuerTrades,
  locale,
  login,
  maturity,
  maxRfqIssuerLimit: (customValue) => customValue || null,
  rootPath: (customValue) => customValue || null,
  outgoingRfqsReport,
  portfolio,
  offers,
  quotesDuck,
  ratesheets,
  sandbox,
  sandboxSummary,
  segmentCode: (customValue) => customValue || null,
  session,
  tenant: (customValue) => customValue || null,
  token: (customValue) => customValue || null,
  trades,
  unsettledTrades,
  upload,
});
