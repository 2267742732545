import './collation-of-ratesheets.scss';

import React from 'react';

import { Row } from 'react-display-flex';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';

import { tradeSources } from '../../../../../../api/holding/codes';
import {
  buildFilteredTradesUrl,
  getFilteredTrades,
} from '../../../../../../api/holding/filtered-trades';
import { SummaryItem } from '../../../../../../components/common';
import { MaturingTodaySummaryItem } from '../../../../../../components/common/MaturingTodaySummaryItem/MaturingTodaySummaryItem';
import { Money } from '../../../../../money';

export const CollationOfRatesheetsHeader = ({ currency, navigate, setInitialValuesInRfq, intl }) => {
  const { data: defaultUnsolicitedTrades } = useSWR(
    buildFilteredTradesUrl({ source: tradeSources.defaultUnsolicitedRatesheet }),
    getFilteredTrades,
  );
  const { data: bespokeUnsolicitedTrades } = useSWR(
    buildFilteredTradesUrl({ source: tradeSources.bespokeUnsolicitedRatesheet }),
    getFilteredTrades,
  );

  const defaultUnsolicitedCreatedToday = defaultUnsolicitedTrades?.reduce((sum, { principal }) => sum + principal, 0);
  const bespokeUnsolicitedCreatedToday = bespokeUnsolicitedTrades?.reduce((sum, { principal }) => sum + principal, 0);

  const unsolicitedCreatedToday = defaultUnsolicitedCreatedToday + bespokeUnsolicitedCreatedToday;

  return (
    <Row>
      <MaturingTodaySummaryItem />
      <SummaryItem
        top={<FormattedMessage id="defaultUnsolicitedRatesheetCreatedToday" />}
        middle={<Money value={unsolicitedCreatedToday} short />}
        bottom={
          <Row alignItemsCenter>
            <span className="highlight">
              <FormattedMessage
                tagName="span"
                id="portfolio.filter-investments-number"
                values={{ length: (defaultUnsolicitedTrades?.length || 0) + (bespokeUnsolicitedTrades?.length || 0) }}
              />
            </span>
          </Row>
        }
        isLoading={!defaultUnsolicitedTrades || !bespokeUnsolicitedTrades}
      />
    </Row>
  );
};
