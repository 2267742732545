import axios from 'axios';

export const buildBuyBondsIncomingRfqsDetailsUrl = ({ rfqUUID }) => `/rfqs/buy-bonds/incoming/${rfqUUID}`;

export async function listBuyBondsIncomingRfqsDetails(url) {
  const { data } = await axios({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return data;
}
