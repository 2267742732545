import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ResponseItem } from './ResponseItem';
import { Column, Tooltip } from '../../../../components/common';
import { tradePropTypes } from './trade-prop-types';

export const RedeemedResponse = ({ trade }) => (
  <Column>
    {trade && trade.maturityActionConfirmed && trade.concludedByUser ? (
      <ResponseItem
        label={<FormattedMessage id="redeemedBy" />}
        value={
          <Tooltip
            delayHide={2000}
            id="sentBy"
            tooltipComponent={trade.concludedByUser.email || trade.concludedByUser.fullName}
          >
            <span>{trade.concludedByUser.fullName}</span>
          </Tooltip>
        }
      />
    ) : (
      <ResponseItem
        label={<FormattedMessage id="status" />}
        value={<FormattedMessage id="pendingConfirmationByInvestor" />}
      />
    )}
  </Column>
);

RedeemedResponse.propTypes = { trade: tradePropTypes };
