/* eslint no-param-reassign: 0 */

import axios from 'axios';
import platform from 'platform';
import qs from 'qs';

import buildApiUrl, { getGlobalUrl } from '../config/build-api-url';
import { routes } from '../routes';
import userStore from '../user-store';

const REGEX_MICROSOFT = /Microsoft/i;

const exceptions = ['/api/client/intl.json'];

export default function setupAxios(apiUrl, baseUrl) {
  const paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'brackets' });
  axios.interceptors.request.use((config) => {
    const prefix = config.routeType === 'global' ? getGlobalUrl(baseUrl, apiUrl) : buildApiUrl(baseUrl, apiUrl);

    if (config.url.includes(prefix)) {
      return config;
    }

    if (!exceptions.includes(config.url)) {
      config.url = `${prefix}${config.url}`;

      const cookieToken = userStore.hasToken() && userStore.getToken();

      if (!config.headers.authorization && cookieToken) {
        config.headers.authorization = cookieToken;
      }
    }
    config.timeout = 200000;

    if (platform.name === 'IE' || REGEX_MICROSOFT.test(platform.name)) {
      if (!config.params) {
        config.params = {};
      }
      config.params.t = new Date().getTime();
    }

    config.paramsSerializer = paramsSerializer;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        userStore.removeToken();

        const encodedRedirectUrl = encodeURIComponent(`${window.location.pathname}${window.location.search}`);

        window.location.href = `${routes.public.login}?redirectUrl=${encodedRedirectUrl}`;
      }

      return Promise.reject(error);
    },
  );
}
