import './index.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Loader } from 'react-loaders';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Protect, roles } from '../../components/common/Protect';
import { Forbidden } from '../../components/error/403-forbidden';
import { withParams } from '../../components/hoc/with-router-properties';
import userStore from '../../user-store';

const mapStateToProps = (state) => ({ session: state.session, state });

export class ToolsPage extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  componentDidMount() {
    document.body.classList.add('no-scroll');
  }

  componentWillUnmount() {
    document.body.classList.remove('no-scroll');
  }

  renderLoader = () => {
    return <Loader type="ball-pulse" />;
  };

  renderPage = () => {
    const { iframeSrc } = this.props.params || {};
    const { user } = this.props.session;
    const src = `/static-html/${iframeSrc}`;

    return (
      <Protect user={user} requiredTo={roles.business} errorComponent={<Forbidden />}>
        <div className="iframe-container">
          <iframe title="tools" allowFullScreen src={src} />
        </div>
      </Protect>
    );
  };

  render() {
    return !userStore.hasToken() ? this.renderLoader() : this.renderPage();
  }
}

export default compose(connect(mapStateToProps), withParams)(ToolsPage);
