import axios from 'axios';

export default async function (id) {
  const url = `/uploads/${id}`;

  const result = await axios({
    method: 'get',
    url,
  });

  return result.data;
}
