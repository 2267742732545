import axios from 'axios';

export default async function fetchIncomingRfqsReport(params = {}) {
  const accept = params.csv ? 'text/csv' : 'application/json';

  const { data } = await axios({
    method: 'get',
    url: '/dashboard/reports/rfqs/incoming',
    headers: { accept },
    params,
  });

  return data;
}
