import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Column, Row } from '../../components/common';
import { isAcceptedOrReinvested } from '../../ducks/issuer-trades/list';
import {
  accepted,
  reinvested,
  rejected,
} from '../../ducks/issuer-trades/statuses';
import { numeric } from '../../format-numbers';
import { Tenors } from './Tenors/Tenors';

const offerDetailStatusesMessageId = {
  [accepted]: 'selectedRate',
  [reinvested]: 'selectedRate',
  [rejected]: 'offerReceived',
};

export const SelectTenor = ({ ratesheet, isLoading, status, ...props }) => (
  <Column className="select-tenor">
    <Row contentBetween>
      <FormattedMessage tagName="span" id={offerDetailStatusesMessageId[status] || 'selectARate'} />
      {!isAcceptedOrReinvested(status) && (
        <FormattedMessage
          id="updatedAt"
          tagName="span"
          values={{
            value: (
              <span className={cx('updated-at', { 'is-loading': isLoading })}>
                {ratesheet && (
                  <FormattedDate
                    value={ratesheet.updatedAt}
                    day={numeric}
                    month={numeric}
                    year={numeric}
                    hour={numeric}
                    minute={numeric}
                  />
                )}
              </span>
            ),
          }}
        />
      )}
    </Row>
    <Tenors ratesheet={ratesheet} isLoading={isLoading} status={status} {...props} />
  </Column>
);

SelectTenor.propTypes = {
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  ratesheet: PropTypes.shape({
    updatedAt: PropTypes.string,
    rates: PropTypes.arrayOf(
      PropTypes.shape({
        rate: PropTypes.number,
        tenor: PropTypes.string,
      }),
    ),
  }),
  selectedTenor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

SelectTenor.defaultProps = {
  selectedTenor: undefined,
  status: undefined,
  ratesheet: undefined,
  isLoading: false,
};
