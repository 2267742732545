import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DatePicker } from '../../common';
import ConfirmDialog from '../../common/confirm-dialog';

const dialogProperties = {
  titleId: 'confirmSettlementDateTitle',
  confirmId: 'ok',
  centerLabels: true,
  cancelId: 'cancel',
  hasCancel: true,
  modalClassName: 'settlement-modal',
};

export const SettlementDateModal = ({ show, date, onConfirm, onCancel }) => {
  const [selectedDate, setSelectedDate] = useState(date);

  return (
    <ConfirmDialog
      {...dialogProperties}
      onCancel={onCancel}
      onConfirm={() => onConfirm(selectedDate)}
      show={show}
      content={
        <React.Fragment>
          <FormattedMessage id="confirmSettlementDate" />
          <hr />
          <DatePicker
            onChange={(newDate) => setSelectedDate(newDate)}
            selected={selectedDate}
            inline
            disableWeekends
            disableHolidays
          />
        </React.Fragment>
      }
    />
  );
};

SettlementDateModal.propTypes = {
  date: PropTypes.instanceOf(Date),
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SettlementDateModal.defaultProps = {
  date: new Date(),
  show: false,
};
