import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import { safeCustodyOptions } from '../../api/holding/codes';
import { isBondOrFrn } from '../../api/holding/instrument-codes';
import { Select } from '../../components/common';
import ConfirmDialog from '../../components/common/confirm-dialog';
import { FileUploader } from '../../components/common/FileUploader';
import { downloadFile } from '../../download-file';
import { DialogHeader } from '../dashboard/BankDashboard/DialogHeader';
import { showResponseErrorMessage } from '../toast/toast';
import { BondAndFrnHeader } from './BondAndFrnHeader';

export class TradeNoteDialogComponent extends Component {
  state = {
    note: null,
    reference: null,
    bankReference: null,
    maturityDate: null,
    coupon: null,
    principal: null,
    isLoading: true,
    safeCustodyCode: null,
    document: undefined,
    documentReferenceKey: null,
  };

  constructor(props) {
    super(props);
    this.referenceRef = React.createRef();
  }

  async componentDidMount() {
    const {
      note,
      reference,
      bankReference,
      maturityDate,
      coupon,
      principal,
      safeCustodyCode,
      isinPrice,
      documentReferenceKey,
      filename,
    } = await this.getTradeDetails(this.props.trade.id);

    this.setState(
      {
        note,
        reference,
        bankReference,
        maturityDate,
        coupon,
        principal,
        isLoading: false,
        safeCustodyCode: safeCustodyCode ? safeCustodyOptions.find(({ value }) => value === safeCustodyCode) : null,
        isinPrice,
        documentReferenceKey,
        filename,
      },
      () => this.referenceRef.focus(),
    );
  }

  getTradeDetails = async () => {
    const { trade } = this.props;

    const { data } = await axios({
      method: 'get',
      url: `/trades/${trade.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (data.documentReferenceKey) {
      const { headers } = await axios({
        method: 'get',
        url: `/trades/${trade.id}/documents/${data.documentReferenceKey}`,
      });

      const [, filename] = headers['content-disposition'].split('filename=');

      data.filename = filename;
    }

    return data;
  };

  download = async () => {
    const { trade } = this.props;
    const { documentReferenceKey } = this.state;

    this.setState({ isDownloading: true });

    try {
      const { data, headers } = await axios({
        headers: {
          Accept: 'application/octet-stream',
        },
        method: 'get',
        url: `/trades/${trade.id}/documents/${documentReferenceKey}`,
      });

      const [, filename] = headers['content-disposition'].split('filename=');

      downloadFile({ filename, data });
    } catch (error) {
      showResponseErrorMessage({ intl: this.props.intl, error });
    } finally {
      this.setState({ isDownloading: false });
    }
  };

  render() {
    const { show, onConfirm, onCancel, trade, onConfirmDeletion } = this.props;
    const {
      note,
      reference,
      bankReference,
      maturityDate,
      coupon,
      principal,
      isLoading,
      isConfirming,
      safeCustodyCode,
      isinPrice,
      document,
      documentReferenceKey,
      filename,
      isDownloading,
    } = this.state;

    return (
      <ConfirmDialog
        modalClassName={classNames('update-trade-dialog', { 'is-loading': isLoading || isConfirming })}
        titleId="updateNoteAndReference"
        confirmId="save"
        cancelId="cancel"
        hasCancel
        show={show}
        onCancel={onCancel}
        backdrop={false}
        onHide={onCancel}
        isConfirming={isConfirming}
        onConfirm={async () => {
          try {
            this.setState({ isConfirming: true });

            await onConfirm({
              tradeId: trade.id,
              note,
              reference,
              bankReference,
              safeCustodyCode: safeCustodyCode?.value,
              document,
            });
          } finally {
            this.setState({ isConfirming: false });
          }
        }}
      >
        <React.Fragment>
          <FormGroup>
            <DialogHeader tradeSelected={{ instrumentCode: trade.instrumentCode, maturityDate, coupon, principal }} />
            {isBondOrFrn(trade.instrumentCode) && isinPrice && (
              <BondAndFrnHeader priceDate={isinPrice.priceDate} yieldOrTradeMargin={isinPrice.yieldOrTradeMargin} />
            )}
          </FormGroup>
          <FormGroup>
            <label htmlFor="reference">
              <FormattedMessage id="holdingReference" />
            </label>
            <FormControl
              id="reference"
              type="text"
              inputRef={(ref) => {
                this.referenceRef = ref;
              }}
              value={reference || ''}
              onChange={(e) => this.setState({ reference: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="bankReference">
              <FormattedMessage id="holdingBankReference" />
            </label>
            <FormControl
              id="bankReference"
              type="text"
              value={bankReference || ''}
              onChange={(e) => this.setState({ bankReference: e.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="document">
              <FormattedMessage id="attachBankConfirmation" />
            </label>
            <FileUploader
              isLoading={isLoading || isConfirming}
              isDownloading={isDownloading}
              fileReferenceKey={documentReferenceKey}
              filename={filename}
              onDownloadClick={this.download}
              onChange={(document) => this.setState({ document })}
              onConfirmDeletion={() => onConfirmDeletion({ tradeId: trade.id })}
            />
          </FormGroup>
          {['BOND', 'FRN'].includes(trade.instrumentCode) && (
            <FormGroup>
              <label htmlFor="safeCustodyCode">
                <FormattedMessage id="safeCustody" />
              </label>
              <Select
                id="safeCustodyCode"
                value={safeCustodyCode}
                onChange={(value) => {
                  this.setState({ safeCustodyCode: value });
                }}
                options={safeCustodyOptions}
              />
            </FormGroup>
          )}
          <FormGroup>
            <label htmlFor="note">
              <FormattedMessage id="holdingNote" />
            </label>
            <FormControl
              id="note"
              type="textarea"
              componentClass="textarea"
              rows="3"
              onChange={(e) => this.setState({ note: e.target.value })}
              value={note || ''}
            />
          </FormGroup>
        </React.Fragment>
      </ConfirmDialog>
    );
  }
}

TradeNoteDialogComponent.propTypes = {
  trade: PropTypes.shape({
    id: PropTypes.number.isRequired,
    instrumentCode: PropTypes.string.isRequired,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirmDeletion: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export const TradeNoteDialog = injectIntl(TradeNoteDialogComponent);
