import axios from 'axios'

export default async function fetchOutgoingRfqsReport({ csv, fromDate, toDate } = {}) {
  const accept = csv ? 'text/csv' : 'application/json'

  const { data } = await axios({
    method: 'get',
    url: '/dashboard/reports/rfqs/outgoing',
    headers: { accept },
    params: { csv, fromDate, toDate },
  })

  return data
}
