import axios from 'axios';

export default async function (params) {
  const { data } = await axios({
    method: 'get',
    url: '/issuers',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });

  if (params && params.instrumentCode) {
    const list = await filterIssuersByTiersInstrumentCode(params.instrumentCode, data);
    return { ...data, list };
  }

  return data;
}


async function filterIssuersByTiersInstrumentCode (instrumentCode, { list: issuers }) {
  const { data: { list: tiers } } = await axios({
    method: 'get',
    url: `/issuers/tiers?query[instrumentCode]=${instrumentCode}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const getTierByIssuer = issuer => tiers.find(tier => tier.issuerId === issuer.id);

  return issuers.filter(getTierByIssuer);
}
