import PropTypes from 'prop-types';
import React from 'react';
import { Loader } from 'react-loaders';

import './loadable.scss';

export const Loadable = (props) => {
  const { children, skeleton, isLoading } = props;

  if (!isLoading) {
    return children;
  }

  return skeleton ? <div className="loader loader-skeleton" /> : <Loader {...props} />;
};

export const types = {
  ballPulse: 'ball-pulse',
  ballScaleRipple: 'ball-scale-ripple',
  lineScalePulseOut: 'line-scale-pulse-out',
};

Loadable.defaultProps = {
  type: types.ballPulse,
};

Loadable.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  ...Loader.propTypes,
};

Loadable.types = types;

export default Loadable;
