import React from 'react';
import { shape } from 'prop-types';

import { PercentageInput } from '../../percentage-input';

export const FormikPercentageField = ({ field, form: { setFieldValue, setFieldTouched }, ...props }) => (
  <PercentageInput
    {...field}
    {...props}
    onChange={(e) => {
      if (!e.target) {
        return;
      }

      setFieldValue(field.name, e.target.value);
    }}
    onBlur={() => setFieldTouched(field.name, true)}
  />
);

FormikPercentageField.propTypes = {
  field: shape().isRequired,
  form: shape().isRequired,
};
