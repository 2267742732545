import * as holdingActions from '../../../actions/holding/save';

export default [
  {
    name: 'principal',
    label: 'rfqAdditionalFunds',
    componentType: 'currency',
  },
  {
    name: 'interestPaidCode',
    label: 'interestPaid',
    componentType: 'select',
    options: holdingActions.codes.interests,
  },
  {
    name: 'allocationCode',
    label: 'holdingAllocationCode',
    componentType: 'text',
    disabled: 'true',
  },
];
